import { FormattedMessage } from "react-intl";
import { Box, Typography } from "@mui/material";
import gears from "assets/img/threeGears.svg";
import styled from "@mui/styled-engine";

const Header = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  img {
    max-width: 48px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;

    img {
      max-width: 120px;
    }
  }
`;
const HeaderTitle = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.5rem;
  }
`;
const HeaderSubText = styled(Typography)`
  font-size: 1rem;
`;
const TitleContainer = styled(Box)`
  margin-left: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: 32px;
    text-align: left;
  }
`;
type SettingsHeaderProps = {
  "data-qatag": string;
  largeScreen: boolean;
};
export function SettingsHeader({ largeScreen }: SettingsHeaderProps) {
  return (
    <Header data-qatag="settings.header">
      <Box
        data-qatag="settings.header.imgContainer"
        sx={{ display: "flex", gap: "16px" }}
      >
        <img
          data-qatag="settings.screen.header.image"
          id="settings.screen.header.image"
          src={gears}
          alt="Gears"
        />
        {!largeScreen ? (
          <HeaderTitle data-qatag="settings.screen.header.title.HeaderTitle">
            <FormattedMessage
              data-qatag="settings.screen.header.title"
              id="settings.screen.header.title"
              defaultMessage="Device Updates"
            />
          </HeaderTitle>
        ) : null}
      </Box>
      <TitleContainer
        data-qatag="settings.screen.header.title_container"
        id="settings.screen.header.title_container"
      >
        {largeScreen ? (
          <HeaderTitle data-qatag="settings.screen.header.title.HeaderTitle">
            <FormattedMessage
              data-qatag="settings.screen.header.title"
              id="settings.screen.header.title"
              defaultMessage="Settings"
            />
          </HeaderTitle>
        ) : null}
        <HeaderSubText data-qatag="settings.HeaderSubText">
          <FormattedMessage
            id="settings.HeaderSubText.text"
            data-qatag="settings.HeaderSubText.text"
            defaultMessage="<span>Change how <b>Driver Support One</b> works.</span> See what version you are on."
            values={{
              span: (chunks) => (
                <>
                  <span data-qatag="span">{chunks}</span>
                  <br data-qatag="line-break" />
                </>
              ),
              b: (chunks) => <strong data-qatag="settings.HeaderSubText.text.bold">{chunks}</strong>,
            }}
          />
        </HeaderSubText>
      </TitleContainer>
    </Header>
  );
}
