import { Box, Typography } from "@mui/material";
import { DriverInstallStatus, DriverUpdateStatus, IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Downloader } from "./Downloader";
import queuedIcon from "assets/icon/icn-queued-state.svg";
import failedIcon from "assets/icon/icn-failed-state.svg";
import styled from "@mui/styled-engine";

//#region Component Styles
const StyledAppButton = styled(AppButton)`
  align-items: center;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  margin: 0;
  width: 180px;
`;
const ImageAndTextContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  flex-direction: row;
  margin-right: ${(props) => props.theme.spacing(3)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-right: ${(props) => props.theme.spacing(0)};
  }
`;

const StateImage = styled("img")`
  margin-right: ${(props) => props.theme.spacing(1)};
`;
//#region Component Props
export interface IUpdaterProps {
  statusModel: IDriverUpdateModel;
  isAgentLocal: boolean;
  onUpdate: () => void;
  onDownload: () => void;
  onDownloadCancel: () => void;
  onDownloadRetry: () => void;
}

export const Updater: React.FC<IUpdaterProps> = (props) => {
  switch (props.statusModel.updateStatus) {
    case DriverUpdateStatus.ReadyToDownload:
      return (
        <StyledAppButton
          ariaLabel="Download"
          buttonType="button"
          isDisabled={!props.isAgentLocal}
          data-qatag="driver.update_renderer.downloadButton"
          id="driver.update_renderer.downloadButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            props.onDownload();
          }}
        >
          <Typography
            data-qatag="driver.update_renderer.downloadButton.content.text"
            id="driver.update_renderer.downloadButton.content.text"
            variant="h6"
          >
            <FormattedMessage
              data-qatag="driver.update_renderer.downloadButton.content"
              id="driver.update_renderer.downloadButton.content"
              defaultMessage="Download"
            />
          </Typography>
        </StyledAppButton>
      );
    case DriverUpdateStatus.ReadyToInstall:
      return (
        <StyledAppButton
          ariaLabel="Update"
          buttonType="button"
          isDisabled={!props.isAgentLocal}
          data-qatag="driver.update_renderer.updateButton"
          id="driver.update_renderer.updateButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            props.onUpdate();
          }}
        >
          <Typography
            data-qatag="driver.update_renderer.updateButton.content.text"
            id="driver.update_renderer.updateButton.content.text"
            variant="h6"
          >
            <FormattedMessage
              data-qatag="driver.update_renderer.updateButton.content"
              id="driver.update_renderer.updateButton.content"
              defaultMessage="Update"
            />
          </Typography>
        </StyledAppButton>
      );
    case DriverUpdateStatus.Downloading:
      return (
        <Downloader
          data-qatag="driver.update_renderer.downloader"
          driverUpdateModel={props.statusModel}
          onCancel={props.onDownloadCancel}
          onRetry={props.onDownloadRetry}
        />
      );
    case DriverUpdateStatus.Failed:
      return (
        <ImageAndTextContainer
          data-qatag="driver.update_renderer.failed"
          id="driver.update_renderer.failed"
        >
          <StateImage
            data-qatag="driver.update_renderer.failed.image"
            id="driver.update_renderer.failed.image"
            src={failedIcon}
            alt="update failed"
          />

          <Typography
            data-qatag="driver.update_renderer.failed.text"
            id="driver.update_renderer.failed.text"
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: 600 }}
          >
            <FormattedMessage
              data-qatag="driver.update_renderer.failed"
              id="driver.update_renderer.failed"
              defaultMessage="Update Failed"
            />
          </Typography>
        </ImageAndTextContainer>
      );
    case DriverUpdateStatus.Installing: {
      const status = props.statusModel.installRecord?.installStatus;
      if (status != null) {
        return (
          <p
            data-qatag="driver.update_renderer.installing.install_status"
            id="driver.update_renderer.installing.install_status"
          >
            {DriverInstallStatus[status]}
          </p>
        );
      }
      return (
        <Typography
          data-qatag="driver.update_renderer.installing.unknown"
          id="driver.update_renderer.installing.unknown"
        >
          <FormattedMessage
            data-qatag="driver.update_renderer.unknownStatus.label"
            id="driver.update_renderer.unknownStatus.label"
            defaultMessage="Unknown Install Status"
          />
        </Typography>
      );
    }
    case DriverUpdateStatus.Queued:
      return (
        <ImageAndTextContainer
          data-qatag="driver.update_renderer.queued"
          id="driver.update_renderer.queued"
        >
          <StateImage
            data-qatag="driver.update_renderer.queued.image"
            id="driver.update_renderer.queued.image"
            src={queuedIcon}
            alt="update queued"
          />
          <Typography
            data-qatag="driver.update_renderer.queued.label.text"
            id="driver.update_renderer.queued.label.text"
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: 600 }}
          >
            <FormattedMessage
              data-qatag="driver.update_renderer.queued.label"
              id="driver.update_renderer.queued.label"
              defaultMessage="Queued"
            />
          </Typography>
        </ImageAndTextContainer>
      );
    default:
      return <></>;
  }
};
//#endregion Component
