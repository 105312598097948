import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import deviceUpdates from "assets/img/status/deviceUpdatesImage.svg";
import fileCleaning from "assets/img/status/fileCleaningImage.svg";
import deceptorBlocking from "assets/img/status/deceptorBlockingImage.svg";
import appOptimization from "assets/img/status/appOptimizationImage.svg";
import windowsEnhancements from "assets/img/status/windowsEnhancementsImage.svg";
import dnsProtection from "assets/img/status/dnsProtectionImage.svg";
import greenCheck from "assets/img/greenCheck.svg";
import styled from "@mui/styled-engine";

export interface IScreenStages {
  progress: number;
}

// Number of stages.
// IMPORTANT: Update it if a new stage is added/removed.
const NUM_STAGES = 6;

const Container = styled("div")`
  position: relative;
  width: 330px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  margin-top: 30px;
  padding: 25px;
  padding-left: 0;
  align-items: flex-start;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    width: 680px;
    padding-left: 25px;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 920px;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    width: 1040px;
  }
`;

const Background = styled("img")`
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(8deg, #00aafc 0%, #00557e 100%);
  z-index: -1;
`;

const ScanStages: React.FC<IScreenStages> = ({ progress }) => {
  const [stagesProgress, setStagesProgress] = useState(Array<boolean>(NUM_STAGES).fill(false));

  const { formatMessage } = useIntl();

  useEffect(() => {
    //the first image is at 0% and so is always lit up
    //leaving N-1 remaining to fill as progress increases
    const stagesToCount = NUM_STAGES - 1;
    setStagesProgress([
      progress >= 0,
      progress >= 1 / stagesToCount,
      progress >= 2 / stagesToCount,
      progress >= 3 / stagesToCount,
      progress >= 4 / stagesToCount,
      progress >= 5 / stagesToCount,
    ]);
  }, [progress]);

  return (
    <Container data-qatag="stagesContainer">
      <Background data-qatag="stagesContainer.background" />
      <ScanStage
        data-qatag="scanStage0"
        complete={stagesProgress[0]}
        image={deviceUpdates}
        label={formatMessage({
          id: "scanStages.stages.deviceUpdates",
          defaultMessage: "Device Updates",
        })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={1}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage1"
        complete={stagesProgress[1]}
        image={fileCleaning}
        label={formatMessage({
          id: "scanStages.stages.fileCleaning",
          defaultMessage: "File Cleaning",
        })}
      />
      <ScanStageSeparator
        data-qatag="2canStageSeparator"
        portion={2}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage2"
        complete={stagesProgress[2]}
        image={deceptorBlocking}
        label={formatMessage({
          id: "scanStages.stages.deceptor",
          defaultMessage: "Deceptor Blocking",
        })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={3}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage2"
        complete={stagesProgress[3]}
        image={appOptimization}
        label={formatMessage({
          id: "scanStages.stages.appOptimization",
          defaultMessage: "App Optimization",
        })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={4}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage4"
        complete={stagesProgress[4]}
        image={windowsEnhancements}
        label={formatMessage({
          id: "scanStages.stages.windowsEnhancements",
          defaultMessage: "Windows Enhancements",
        })}
      />
      <ScanStageSeparator
        data-qatag="scanStageSeparator"
        portion={5}
        progress={progress}
      />
      <ScanStage
        data-qatag="scanStage5"
        complete={stagesProgress[5]}
        image={dnsProtection}
        label={formatMessage({
          id: "scanStages.stages.dnsProtections",
          defaultMessage: "DNS Protection",
        })}
      />
    </Container>
  );
};

export default ScanStages;

export interface IScanStage {
  image: string;
  complete: boolean;
  label: string;
}

const Stage = styled("div")`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 320px;
  margin-left: 20px;
  align-items: center;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: column;
    width: 60px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 80px;
  }
`;

const StageImage = styled("img")`
  width: 45px;
  height: 45px;

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 64px;
    height: 64px;
  }
`;

const GreenCheck = styled("img")`
  position: absolute;
  width: 21px;
  height: 21px;
  left: 30px;
  top: -5px;

  ${(props) => props.theme.breakpoints.up("lg")} {
    left: 45px;
  }
`;

const StageLabel = styled(Typography)`
  color: #ffffff;
  font-weight: 600;
  margin-left: 33px;
  font-size: 1rem;
  line-height: 1.125rem;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-left: 0;
  }
`;

export const ScanStage: React.FC<IScanStage> = ({ image, complete, label }) => (
  <Stage
    data-qatag="stage"
    style={{ opacity: complete ? 1 : 0.5 }}
  >
    <StageImage
      data-qatag="stageImage"
      src={image}
      alt={label}
    />
    {complete && (
      <GreenCheck
        data-qatag="greenCheck"
        src={greenCheck}
        alt="Complete"
      />
    )}
    <StageLabel
      data-qatag="stageLabel"
      variant="body1"
    >
      {label}
    </StageLabel>
  </Stage>
);

interface IScanStageSeparator {
  /**
   * Represents the portion of the whole progress bar.
   * Value must be `n > 0 && n < NUM_STAGES`.
   */
  portion: number;
  progress: number;
}

const SeparatorContainer = styled("div")`
  position: relative;
  height: 2px;
  background-color: #e9e9f09e;
  border-radius: 4px;
  transform: rotate(90deg);
  width: 30px;
  margin-bottom: 20px;
  margin-left: 28px;
  margin-top: 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    transform: unset;
    margin: -40px 0 0;
    width: 60px;
  }
`;

const SeparatorProgress = styled("div")`
  position: absolute;
  height: 2px;
  border-radius: 4px;
  background-color: #ffffff;
  transition: 0.5s width;
`;

const ScanStageSeparator: React.FC<IScanStageSeparator> = ({ portion, progress }) => {
  if (portion < 1 && portion >= NUM_STAGES) {
    throw new Error("'portion' must be greater than 0 and less than 'NUM_STAGES'.");
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [relativeProgress, setRelativeProgress] = useState(progress * containerWidth);

  // Recompute container width on window resize.
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }

    const onResize = () => {
      if (containerRef && containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    if (containerRef && containerRef.current) {
      window.addEventListener("resize", onResize);
    }

    return () => window.removeEventListener("resize", onResize);
  }, []);

  // Compute progress for each portion.
  useEffect(() => {
    const numPortions = NUM_STAGES - 1;
    const limits = [0, 0];
    if (portion === 1) {
      limits[1] = portion / numPortions;
    } else {
      limits[0] = (portion - 1) / numPortions;
      limits[1] = portion / numPortions;
    }

    const portionSize = limits[1] - limits[0];

    if (progress <= limits[0]) {
      setRelativeProgress(0);
    } else if (progress >= limits[1]) {
      setRelativeProgress(containerWidth);
    } else {
      setRelativeProgress(((portionSize - (limits[1] - progress)) / portionSize) * containerWidth);
    }
  }, [portion, progress, containerWidth]);

  return (
    <SeparatorContainer
      data-qatag="separatorContainer"
      ref={containerRef}
    >
      <SeparatorProgress
        data-qatag="separatorProgress"
        style={{ width: relativeProgress }}
      ></SeparatorProgress>
    </SeparatorContainer>
  );
};
