import { selectAgentInfo } from "app/Agent/AgentSlice";
import { IFileScanCleanupPayload } from "model/messaging/payloads/IFileScanCleanupPayload";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "session/SessionSlice";
import { humanFileSize } from "utils/formatFileSize";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import {
  selectFileCleanupScan,
  selectFileCleanupResults,
  selectFileCleaningDetails,
  selectLast30DaysReport,
  selectStatus,
  selectFileCleanupScanOptions,
  fetchFileCleanupScan,
  fetchLast30DaysReport,
  fetchScanOptions,
  fetchFileCleanupResults,
  openRecycleBin,
  doFileCleanup,
  startFileCleanupScan,
  IFileCleanupOptions,
  selectDoingScan,
  selectDoingFileCleanup,
  setScanOptions,
  fetchFileCleanupDetails,
} from "./FileCleaningSlice";
import { FileCleaning, IFileCleaningProps } from "./FileCleaning";
import { useNavigate } from "react-router";
import useAmplitude from "hooks/useAmplitude";

export function FileCleaningContainer() {
  const { track } = useAmplitude();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const scan = useSelector(selectFileCleanupScan);
  const results = useSelector(selectFileCleanupResults);
  const details = useSelector(selectFileCleaningDetails);
  const last30DaysReport = useSelector(selectLast30DaysReport);
  const thunkStatus = useSelector(selectStatus);
  const options = useSelector(selectFileCleanupScanOptions);
  const isDoingScan = useSelector(selectDoingScan);
  const isDoingCleanup = useSelector(selectDoingFileCleanup);
  const uuid = useSelector(selectCurrentUuid);
  const currentUser = useSelector(selectCurrentUser);
  const agentInfo = useSelector(selectAgentInfo);
  const [userCleaned, setUserCleaned] = useState<boolean>(false);
  const cleanupCompleted = userCleaned && !isDoingCleanup;

  useEffect(() => {
    dispatch(fetchFileCleanupScan());
    dispatch(fetchLast30DaysReport());
    dispatch(fetchScanOptions());
    dispatch(fetchFileCleanupResults());
    dispatch(fetchFileCleanupDetails());
  }, [dispatch]);

  const doCleanupHandler = (cleanupSize: number) => {
    const payload: IFileScanCleanupPayload = {
      CleanUpApplicationTemp: options?.tempFilesEnabled ?? false,
      CleanUpDownloads: options?.internetFilesEnabled ?? false,
      CleanUpGoogleFiles: options?.internetFilesEnabled ?? false,
      CleanUpEdgeFiles: options?.internetFilesEnabled ?? false,
      CleanUpCachedThumbnails: options?.tempFilesEnabled ?? false,
      CleanUpTempInternetFiles: options?.internetFilesEnabled ?? false,
      EmptyRecycleBin: options?.recyclingEnabled ?? false,
      DisableHibernation: (options?.tempFilesEnabled ?? false) && !scan?.IsLaptop,
      CleanUpWindowsTemp: options?.tempFilesEnabled ?? false,
      CloseOpenApps: false,
      AppsToClose: [],
    };

    track("File Cleanup Requested", {
      UUID: uuid,
      "Cleanup Size": humanFileSize(cleanupSize, true),
      "Registration Key": currentUser?.registrationKey ?? "null",
      "Agent Version": agentInfo?.AppVersion,
      Channel: agentInfo?.RegState.Channel,
    });
    dispatch(doFileCleanup(payload));
    setUserCleaned(true);
  };

  const doScanHandler = () => {
    dispatch(startFileCleanupScan());
  };

  const openRecycleBinHandler = () => {
    dispatch(openRecycleBin());
  };

  const updateOptionsHandler = (options: IFileCleanupOptions) => {
    dispatch(setScanOptions(options));
  };

  const backButtonHandler = () => {
    nav("/status");
  };

  const props: IFileCleaningProps = {
    scan,
    results,
    details,
    last30DaysReport,
    options,
    thunkStatus,
    isDoingCleanup,
    isDoingScan,
    cleanupCompleted,
    doCleanupHandler,
    doScanHandler,
    openRecycleBinHandler,
    updateOptionsHandler,
    backButtonHandler,
  };

  return (
    <FileCleaning
      data-qatag="FileCleaning"
      {...props}
    />
  );
}
