import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import backgroundHeader from "assets/img/banner-blue-master.svg";
import headerImage from "assets/img/threeGears.svg";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { FormattedMessage } from "react-intl";
import { DnsProtectionModal } from "./DnsProtectionModal";
import { MalwareSection } from "./MalwareSection";
import { PageBoostSection } from "./PageBoostSection";

const Container = styled("div")`
  position: relative;
  width: 100%;
`;

const Background = styled("div")`
  position: absolute;
  x: 0;
  y: 0;
  width: 100%;
  background-image: linear-gradient(#efefef, #ffffff);
  z-index: -1;
`;

const BackgroundImage = styled("div")`
  width: 100%;
  height: 120px;
  background-image: url(${backgroundHeader});
  background-size: cover;
`;

const Content = styled("div")`
  max-width: 100%;
  max-width: 1075px;
  margin: 34px auto 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 750px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    max-width: 960px;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    max-width: 1075px;
  }
`;

const Header = styled("div")`
  position: relative;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e6e6e6;

  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 8px;
    border: none;
  }
`;

const HeaderImage = styled("img")`
  width: 40px;
  height: 29px;
  position: absolute;

  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    width: 120px;
    height: 88px;
    margin-right: 20px;
    margin-left: 24px;
  }
`;

const HeaderText = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 137px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    margin-right: 300px;
  }
`;

const HeaderTitle = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
  line-height: 2rem;
  margin-left: 52px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 0;
    font-size: 1.5rem;
  }
`;

const HeaderDescription = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 6px;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  background-color: white;
  border-radius: 15px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    margin-top: 20px;
    padding: 40px 20px;
  }
`;

const Divider = styled("div")`
  display: inline-box;
  width: 300px;
  height: 0;
  margin: 18px auto;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 125px 15px;
    width: 0;
    height: 130px;
  }
`;

export type DnsProtectionProps = {
  settings: IUserSettingModel[] | null;
  enablePageboostSwitch: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  onDnsSwitchClick: (isOn: boolean) => Promise<void>;
  onPageBoostSwitchClick: (isOn: boolean) => Promise<void>;
  onWebStoreClick: () => void;
};

export const DnsProtection: React.FC<DnsProtectionProps> = ({
  closeModal,
  enablePageboostSwitch,
  isModalOpen,
  onDnsSwitchClick,
  onPageBoostSwitchClick,
  settings,
}) => {
  const protectionEnabled: boolean | null =
    settings?.find((x) => x.SettingType === UserSettingTypes.EnableDnsProtector)?.SettingValue ?? null;
  const chromeEnabled: boolean =
    settings?.find((x) => x.SettingType === UserSettingTypes.ForceInstallChromeExtension)?.SettingValue ?? false;
  const edgeEnabled: boolean =
    settings?.find((x) => x.SettingType === UserSettingTypes.ForceInstallEdgeExtension)?.SettingValue ?? false;
  const pageboostEnabled: boolean | null = (chromeEnabled || edgeEnabled) ?? null;
  return (
    <>
      <DnsProtectionModal
        isOpen={isModalOpen}
        data-qatag="dnsProtection.modal"
        isDisabled={!pageboostEnabled}
        onClose={closeModal}
      />
      <Container data-qatag="container">
        <Background data-qatag="Background">
          <BackgroundImage data-qatag="BackgroundImage" />
        </Background>
        <Content data-qatag="content">
          <Header data-qatag="dnsProtection.header">
            <HeaderImage
              data-qatag="HeaderImage"
              src={headerImage}
              alt="title icon"
            />
            <HeaderText data-qatag="HeaderText">
              <HeaderTitle
                data-qatag="HeaderTitle"
                variant="h4"
              >
                <FormattedMessage
                  data-qatag="dnsProtection.view.header.title"
                  id="dnsProtection.view.header.title"
                  description="DNS Protection title"
                  defaultMessage="Web Protection"
                />
              </HeaderTitle>
              <HeaderDescription
                data-qatag="HeaderDescription"
                variant="body2"
              >
                <FormattedMessage
                  data-qatag="dnsProtection.view.header.subtitle"
                  id="dnsProtection.view.header.subtitle"
                  defaultMessage="This service supplements your current security solution by preventing access to harmful websites and more."
                />
              </HeaderDescription>
            </HeaderText>
          </Header>
          <Body data-qatag="body">
            <MalwareSection
              data-qatag="MalwareSection"
              isOn={protectionEnabled}
              onSwitchClick={onDnsSwitchClick}
            />
            <Divider data-qatag="divider" />
            <PageBoostSection
              data-qatag="PageBoostSection"
              isSwitchEnabled={enablePageboostSwitch}
              isOn={pageboostEnabled}
              onSwitchClick={onPageBoostSwitchClick}
            />
          </Body>
        </Content>
      </Container>
    </>
  );
};
