import { Box, Typography, styled } from "@mui/material";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import { defaultImageDevice } from "features/driverInstall/DeviceList/Device/Device";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  height: 280px;
  width: 660px;
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 20px;
`;

const StyledImage = styled("img")`
  margin-right: 20px;
  max-width: 80px;
  max-height: 80px;
`;

const StyledAppButton = styled(AppButton)`
  font-weight: bold;
  margin-top: 20px;
  width: 280px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const ButtonIcon = styled(SystemUpdateAltIcon)`
  margin-left: 5px;
`;

export type PNPModalProps = {
  device: IDeviceInfo;
  onInstall: () => void;
};

export const PNPModal: React.FC<PNPModalProps> = (props: PNPModalProps) => {
  const image = props.device.deviceImageURI
    ? props.device.deviceImageURI
    : props.device.logoBase64 || defaultImageDevice;
  return (
    <Container data-qatag="driver.modal.pnp">
      <TextContainer data-qatag="driver.modal.pnp.textContainer">
        <StyledImage
          data-qatag="driver.modal.pnp.image"
          src={image}
          alt="device image"
        />
        <Typography
          variant="h4"
          data-qatag="driver.modal.typography"
        >
          <FormattedMessage
            data-qatag="driver.modal.pnp.message"
            id="driver.modal.pnp.message"
            defaultMessage="Install your {deviceName}"
            values={{ deviceName: props.device.friendlyName }}
          />
        </Typography>
      </TextContainer>
      <StyledAppButton
        ariaLabel="install"
        buttonType="button"
        data-qatag="driver.modal.pnp.button"
        buttonStyle={AppButtonStyles.Green}
        onClick={props.onInstall}
      >
        <FormattedMessage
          data-qatag="driver.modal.pnp.button.message"
          id="driver.modal.pnp.button"
          defaultMessage="Install"
        />
        <ButtonIcon data-qatag="driver.modal.pnp.button.icon" />
      </StyledAppButton>
    </Container>
  );
};
