import { Box, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import noResults from "assets/img/driver/icn-no-results.svg";

export const NoSearchResults: React.FC = () => {
  return (
    <Box
      data-qatag="driver.device_list.search.no_results.root"
      id="driver.device_list.search.no_results.root"
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        justifyContent: "center",
        maxHeight: "400px",
      }}
    >
      <img
        data-qatag="driver.device_list.search.no_results.image"
        id="driver.device_list.search.no_results.image"
        src={noResults}
        alt="no search results"
        style={{ marginBottom: "16px", maxWidth: "85px" }}
      />
      <Typography
        data-qatag="driver.device_list.search.no_results.text"
        id="driver.device_list.search.no_results.text"
        variant="h5"
        sx={{ color: "#0084A3" }}
      >
        <FormattedMessage
          data-qatag="driver.device_list.search.hno_results"
          id="driver.device_list.search.no_results"
          defaultMessage="No results were found."
        />
      </Typography>
      <Typography
        data-qatag="driver.device_list.search.no_results.description.text"
        id="driver.device_list.search.no_results.description.text"
        variant="body2"
        color="primary"
      >
        <FormattedMessage
          data-qatag="driver.device_list.search.no_results.description"
          id="driver.device_list.search.no_results.description"
          defaultMessage="Your search did not return any results."
        />
      </Typography>
    </Box>
  );
};
