import { Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import deviceImage from "assets/img/monitorIcon.svg";
import pendingIcon from "assets/img/family/status-pending-w-resend-stamp.svg";
import { FormattedMessage } from "react-intl";
import IFamilyMember from "model/user/IFamilyMember";
import { MachineInfoComponent } from "./Cells/MachineInfo";
import { StatusInfo } from "./Cells/StatusInfo";
import { RemoveButton } from "./RemoveButton";

const RowDeviceImage = styled("img")`
  margin-right: 1.375rem;
`;

const RowDeviceImagePending = styled(RowDeviceImage)`
  padding-left: 0.5rem;
`;

const TableRow = styled("tr")`
  border-top: 0.063rem solid #e5e5e5;
  border-bottom: 0.063rem solid #e5e5e5;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
    position: relative;
  }
`;

const DataCell = styled("td")`
  padding: 1.875rem 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 16px;
    padding-left: 20px;
    height: 72px;
  }
`;

const InfoColumn = styled(DataCell)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    background-color: rgba(204, 204, 204, 0.1);
  }
`;

const CellContent = styled("div")`
  align-items: center;
  display: flex;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-right: 5rem;
  }
`;

const InfoCellContent = styled(CellContent)`
  margin-right: 5rem;
`;

const InfoColumnImageContainer = styled("div")`
  height: 2.5rem;
  margin-right: 2.5rem;
  position: relative;
  width: 2.5rem;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-right: 0.75rem;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-right: 0.875rem;
    width: 3.125rem;
  }
`;

const InfoColumnImage = styled("img")`
  border-radius: 50%;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 2rem;
  }
`;

const OnlineIndicator = styled("div")`
  background: #2ecc5f 0% 0% no-repeat padding-box;
  border-radius: 1rem;
  height: 0.75rem;
  left: 1.75rem;
  opacity: 1;
  position: absolute;
  top: -0.25rem;
  transform: matrix(0, -1, 1, 0, 0, 0);
  width: 0.75rem;
`;

const RowSubText = styled(Typography)`
  color: #757e95;
  display: block;
  font: italic normal normal 0.875rem/1.188rem "Open Sans";
  font-style: italic;
  letter-spacing: 0;
  text-decoration: underline;
`;

const DeviceColumn = styled(DataCell)`
  & :first-child::before {
    ${({ theme }) => theme.breakpoints.down("md")} {
      content: "Computer";
      color: #535a6a;
      font-weight: 600;
      font-size: 1rem;
      line-height: 2.25rem;
      margin-right: 2rem;
      width: 4.75rem;
    }
  }
`;

const DeviceText = styled("div")`
  margin-top: 0.25rem;
`;

const DeleteColumn = styled(DataCell)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-left: 0.5rem !important;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    background-color: rgba(198, 198, 198, 0.29);
    border-radius: 0.75rem 0 0 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 5rem;
  }
`;

const CTAColumn = styled(DataCell)`
  text-align: right;

  ${({ theme }) => theme.breakpoints.down("md")} {
    text-align: center;
  }
`;

export interface IFamilyMemberTableRowProps {
  currentUuid: string | null; // It can be null if the agent is offline
  familyMember: IFamilyMember;
  isLastAdmin: boolean;
  localAgentUuid: string | null;
  isLastUser: boolean;
  handleDelete: (familyMember: IFamilyMember) => void;
  handleFamilyMemberRemoteConnect: (familyMember: IFamilyMember) => void;
  handleResendEmail: (familyMember: IFamilyMember) => void;
  handleDownload: () => void;
}

export const FamilyMemberTableRow: React.FC<IFamilyMemberTableRowProps> = ({
  currentUuid,
  familyMember,
  isLastAdmin,
  localAgentUuid,
  isLastUser,
  handleDelete,
  handleFamilyMemberRemoteConnect,
  handleResendEmail,
  handleDownload,
}) => {
  const { email, fullName, image, isAdmin, isOnline, isLicenseActivated, licenseUUID, machineDisplayName } =
    familyMember;
  const DeviceOrPendingIconImg = () => {
    if (isLicenseActivated) {
      return (
        <RowDeviceImage
          data-qatag="familyMemberTableRowDeviceImage"
          src={deviceImage}
          alt={"device icon"}
        />
      );
    }

    return (
      <RowDeviceImagePending
        data-qatag="familyMemberTableRowDeviceImage"
        src={pendingIcon}
        alt={"pending icon"}
      />
    );
  };
  const isSameAgent = currentUuid === licenseUUID;
  const isLocalAgent = licenseUUID !== null && localAgentUuid !== null && licenseUUID === localAgentUuid;
  const canViewRemotely = isLicenseActivated && isOnline && !isSameAgent;

  const CallToAction = () => {
    if (canViewRemotely || !isLicenseActivated) {
      let buttonText;
      if (!isLicenseActivated) {
        if (isLastUser) {
          buttonText = (
            <FormattedMessage
              data-qatag="familyMemberTableRowDetailsButtonDownloadMessage"
              id="familyDetailRow.download"
              defaultMessage="Download"
            />
          );
        } else {
          buttonText = (
            <FormattedMessage
              data-qatag="familyMemberTableRowDetailsButtonResendEmailMessage"
              id="familyDetailRow.ResendEmail"
              defaultMessage="Resend Email"
            />
          );
        }
      } else if (isLocalAgent) {
        buttonText = (
          <FormattedMessage
            data-qatag="familyMemberTableRowDetailsButtonViewThisPCMessage"
            id="familyDetailRow.ViewThisPC"
            defaultMessage="View This PC"
          />
        );
      } else {
        buttonText = (
          <FormattedMessage
            data-qatag="familyMemberTableRowDetailsButtonViewRemotelyMessage"
            id="familyDetailRow.ViewRemotely"
            defaultMessage="View Remotely"
          />
        );
      }
      return (
        <AppButton
          ariaLabel={buttonText.props.defaultMessage[0].value}
          buttonType="button"
          data-qatag="familyMemberTableRowDetailsButton"
          buttonStyle={AppButtonStyles.LinkBlue}
          onClick={() => {
            if (!isLicenseActivated && isLastUser) {
              handleDownload();
              return;
            }

            if (canViewRemotely) {
              handleFamilyMemberRemoteConnect(familyMember);
              return;
            }

            handleResendEmail(familyMember);
          }}
        >
          <Typography
            data-qatag="familyMemberTableRowDetailsTypography"
            variant="body2"
            sx={{ fontSize: "1rem", fontWeight: "600" }}
          >
            {buttonText}
          </Typography>
        </AppButton>
      );
    }

    return <></>;
  };

  return (
    <TableRow data-qatag="familyMemberTableRow">
      <InfoColumn data-qatag="familyMemberTableRowInfo">
        <InfoCellContent data-qatag="familyMemberTableRowCellContent">
          <InfoColumnImageContainer data-qatag="familyMemberTableRowInfoImageContainer">
            <InfoColumnImage
              data-qatag="familyMemberTableRowInfoImage"
              src={image}
              alt="familyMember"
            />
            {isOnline ? (
              <OnlineIndicator data-qatag="familyMemberTableRowOnlineIndicator" />
            ) : (
              <div data-qatag="familyMemberTableRowOnlineIndicator" />
            )}
          </InfoColumnImageContainer>
          <div data-qatag="familyMemberTableRowNameContainer">
            {fullName}
            <RowSubText
              data-qatag="familyMemberTableRowSubText"
              variant="body1"
            >
              <FormattedMessage
                id="familyMemberTableRowSubText.email"
                data-qatag="familyMemberTableRowSubText.email"
                defaultMessage={"{email}"}
                values={{ email }}
              />
            </RowSubText>
          </div>
        </InfoCellContent>
      </InfoColumn>
      <DeviceColumn data-qatag="familyMemberTableRowDevice">
        <CellContent data-qatag="familyMemberTableRowCellContent">
          <DeviceOrPendingIconImg data-qatag="familyMemberTableRowDeviceIcon" />
          <DeviceText data-qatag="familyMemberTableRowDeviceText">
            <MachineInfoComponent
              data-qatag="familyMemberTableRowDeviceInfo"
              isLicenseActivated={isLicenseActivated}
              machineDisplayName={machineDisplayName}
            />
          </DeviceText>
        </CellContent>
      </DeviceColumn>
      <DataCell data-qatag="familyMemberTableRowAction">
        <StatusInfo
          data-qatag="familyMemberTableRowStatusInfo"
          isLicenseActivated={isLicenseActivated}
          isOnline={isOnline}
        />
      </DataCell>
      <DeleteColumn data-qatag="familyMemberTableRowDelete">
        <CellContent data-qatag="familyMemberTableRowCellContent">
          <RemoveButton
            data-qatag="removeFamilyMemberButton"
            isAdmin={isAdmin ?? false}
            isLastAdmin={isLastAdmin}
            isLicenseActivated={isLicenseActivated}
            handleDelete={() => handleDelete(familyMember)}
          />
        </CellContent>
      </DeleteColumn>
      <CTAColumn data-qatag="familyMemberTableRowAction">
        <CallToAction data-qatag="cta" />
      </CTAColumn>
    </TableRow>
  );
};
