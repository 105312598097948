import { SelectionBox } from "./Sections/SelectionBox";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import MachinePickerHeader from "./Sections/Header";
import defaultLaptop from "assets/img/laptop-img.png";
import { Alert, AlertIcon, AppButton, AppButtonStyles, Spinner } from "dsoneweb.designsystem";
import { Layout } from "components/Layout/Layout";
import { ISystemInfoInfo } from "model/scan/SystemInfo";
import { IntelligenceTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { Dispatch, SetStateAction } from "react";
import { IManufacturers, IModel, IProductLine } from "./types";
import { ReducerStatus } from "model/IReducerState";
import styled from "@mui/styled-engine";
import { IntelligenceType } from "./Sections/IntelligenceType";

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SelectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-evenly;
  padding: 0 24px;
  margin-bottom: 48px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    height: 290px;
    max-width: 672px;
    padding: 0;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    gap: 32px;
    max-width: 1010px;
  }
`;
const ScanNowButton = styled(AppButton)`
  margin: 12px 0 32px 0;
  width: 260px;
`;
const EmailButton = styled(AppButton)`
  text-decoration: underline;
`;
export type MachinePickerProps = {
  hasError: boolean;
  intelligenceSelected: IntelligenceTypes;
  isIntelligenceOEM: boolean;
  machineBrand: IManufacturers[];
  motherboards: IManufacturers[];
  motherboardFamily: IManufacturers[];
  motherboardModel: IModel[];
  noIntelligence: boolean;
  onResetUpdate: () => void;
  onScanCustomModelClicked: () => void;
  onScanNowClicked: () => void;
  productLine: IProductLine[];
  productModel: IModel[];
  selectedMachineBrand: string | number | undefined;
  selectedProductLine: string | number | undefined;
  selectedProductModel: string | number | undefined;
  setIntelligenceSelected: Dispatch<SetStateAction<IntelligenceTypes>>;
  setSelectedMachineBrand: Dispatch<SetStateAction<undefined | string | number>>;
  setSelectedProductLine: Dispatch<SetStateAction<undefined | string | number>>;
  setSelectedProductModel: Dispatch<SetStateAction<undefined | string | number>>;
  statusDependency: ReducerStatus;
  statusUpdateMachine: ReducerStatus;
  systemInfo: ISystemInfoInfo;
};
export function MachinePicker({
  hasError,
  intelligenceSelected,
  isIntelligenceOEM,
  machineBrand,
  motherboards,
  motherboardFamily,
  motherboardModel,
  noIntelligence,
  onResetUpdate,
  onScanCustomModelClicked,
  onScanNowClicked,
  productLine,
  productModel,
  selectedMachineBrand,
  selectedProductLine,
  selectedProductModel,
  setIntelligenceSelected,
  setSelectedMachineBrand,
  setSelectedProductLine,
  setSelectedProductModel,
  statusDependency,
  statusUpdateMachine,
  systemInfo,
}: MachinePickerProps) {
  const prefix = "machine.picker.form";
  const { formatMessage } = useIntl();

  return (
    <>
      <Layout.DetachedHeader data-qatag="layout.detachedHeader">
        <MachinePickerHeader
          data-qatag="header"
          computerModel={systemInfo.pcModel ?? "Custom Machine"}
          headerImage={systemInfo.pcImageUrl || defaultLaptop}
        />
      </Layout.DetachedHeader>

      <Layout.Content data-qatag="formContainer">
        <Container data-qatag="content">
          <IntelligenceType
            data-qatag="IntelligenceType"
            intelligenceSelected={intelligenceSelected}
            setIntelligenceSelected={setIntelligenceSelected}
          />
          {!noIntelligence ? (
            <SelectionContainer data-qatag="machinePicker.SelectionContainer">
              <SelectionBox
                data-qatag="machineBrand.SelectionBox"
                showErrorState={hasError}
                onChange={(brand) => {
                  setSelectedMachineBrand(brand);
                  setSelectedProductLine("");
                  setSelectedProductModel("");
                }}
                options={(isIntelligenceOEM ? machineBrand : motherboards)?.map((brand) => ({
                  value: brand?.key?.toString(),
                  label: brand.name,
                }))}
                selectionSectionTitle="Machine Brand"
                value={selectedMachineBrand}
              />
              <SelectionBox
                data-qatag="productLine.SelectionBox"
                showErrorState={hasError}
                onChange={(line) => {
                  setSelectedProductLine(line);
                  setSelectedProductModel("");
                }}
                options={
                  selectedMachineBrand
                    ? (isIntelligenceOEM ? productLine : motherboardFamily).map((line) => ({
                        label: line?.name,
                        value: line?.key?.toString(),
                      }))
                    : []
                }
                selectionSectionTitle="Product Line"
                value={selectedProductLine}
              />
              <SelectionBox
                data-qatag="productModel.SelectionBox"
                showErrorState={hasError}
                onChange={(model) => {
                  setSelectedProductModel(model);
                }}
                options={
                  selectedProductLine
                    ? (isIntelligenceOEM ? productModel : motherboardModel).map((line) => ({
                        label: line?.name,
                        value: line?.key?.toString(),
                      }))
                    : []
                }
                selectionSectionTitle="Product Model"
                value={selectedProductModel}
              />
            </SelectionContainer>
          ) : null}

          <Box
            data-qatag="emailContainer"
            sx={{ display: "flex", gap: "8px" }}
          >
            <Typography
              data-qatag="emailText"
              align="center"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="emailLabel"
                id={`${prefix}.cant.find`}
                defaultMessage="Can't find your computer in the boxes above?"
              />
            </Typography>
            <EmailButton
              data-qatag="emailButton"
              ariaLabel={"Scan custom model"}
              buttonStyle={AppButtonStyles.LinkBlue}
              buttonType={"button"}
              onClick={onScanCustomModelClicked}
            >
              <FormattedMessage
                data-qatag="emailText"
                id={`${prefix}.sendInfo`}
                defaultMessage="Scan custom model"
              />
            </EmailButton>
          </Box>

          <ScanNowButton
            ariaLabel="scan now"
            buttonType="button"
            data-qatag="customButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={onScanNowClicked}
          >
            <Typography
              data-qatag="scanText"
              align="center"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="scanTextLabel"
                id={`${prefix}.scan.now`}
                defaultMessage="Scan Now"
              />
            </Typography>
          </ScanNowButton>
        </Container>
      </Layout.Content>

      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title="Something went wrong"
        text="Unable to update machine intelligence"
        buttonText="Continue"
        approveHandler={onResetUpdate}
        isOpen={ReducerStatus[statusUpdateMachine] === ReducerStatus.Failed}
        closeHandler={onResetUpdate}
      />
      <Spinner
        data-qatag="loadingSpinner"
        isActive={
          ReducerStatus[statusUpdateMachine] === ReducerStatus.Loading ||
          ReducerStatus[statusDependency] === ReducerStatus.Loading
        }
        headerText={formatMessage({
          id: `MachinePickerLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `MachinePickerLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    </>
  );
}
