import { useAppDispatch } from "app/redux/store";
import { Spinner } from "dsoneweb.designsystem";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  checkLicense,
  eConnectionStatus,
  getLicense,
  selectAgentLicense,
  selectCheckLicenseStatus,
  selectGetLicenseStatus,
  selectSignalRHubConnectionStatus,
} from "./AgentSlice";
import { useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";

type Props = {
  children?: React.ReactNode;
};

export const FromCartLicenseWrapper = ({ children }: Props) => {
  const { formatMessage } = useIntl();
  const appDispatch = useAppDispatch();
  const agentLicense = useSelector(selectAgentLicense);
  const getLicenseStatus = useSelector(selectGetLicenseStatus);
  const checkLicenseStatus = useSelector(selectCheckLicenseStatus);
  const connectionStatus = useSelector(selectSignalRHubConnectionStatus);
  const fromCart = localStorage.getItem("fromcart") === "true";
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    //do nothing if no param or agent isn't connected
    if (!fromCart || connectionStatus !== eConnectionStatus.Connected) {
      return;
    }

    //clean up once license is valid
    if (agentLicense?.IsValid) {
      setIsLoading(false);
      localStorage.removeItem("fromcart");
      return;
    }

    //fetch license if necessary
    if (agentLicense == null && getLicenseStatus === ReducerStatus.Idle) {
      setIsLoading(true);
      appDispatch(getLicense());
    }

    //update license if invalid
    if (agentLicense != null && agentLicense.IsValid === false && checkLicenseStatus === ReducerStatus.Idle) {
      appDispatch(checkLicense());
      //license will be updated once the LICENSE_UPDATED message is received,
      //at which point it should be valid
    }
  }, [fromCart, agentLicense, getLicenseStatus, checkLicenseStatus, connectionStatus]);

  if (isLoading) {
    return (
      <Spinner
        data-qatag="FromCartLicenseWrapperSpinner"
        isActive
        headerText={formatMessage({
          id: "FromCartLicenseWrapperHeaderText",
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: "FromCartLicenseWrapperText",
          defaultMessage: "Software is Loading...",
        })}
      />
    );
  }

  return <>{children}</>;
};
