import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { MenuItemDescriptor } from "../NavBar";
import styled from "@mui/styled-engine";

const StyledNavLink = styled(NavLink)`
  align-items: center;
  display: flex;
  color: #535353;
  font-size: 1.125rem;
  font-weight: 500;
  height: 56px;
  margin-bottom: 1px;
  position: relative;
  text-align: center;
  text-decoration: none;

  span {
    font-size: 1rem;
    line-height: 1.25;

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 1.125rem;
    }
  }

  img {
    width: 18px;
  }

  &.active {
    &.bottom {
      border-bottom: 2px solid #3fa9f5;
      margin-top: 2px;
    }

    &.left {
      box-shadow: inset 2px 0px 0px 0px #3fa9f5;
    }
  }

  &.left {
    height: 40px;
    margin: ${({ theme }) => theme.spacing(2, 0)};
    padding: ${({ theme }) => theme.spacing(0, 2.5)};
    width: 100%;

    &:hover {
      box-shadow: inset 2px 0px 0px 0px #3fa9f5;
    }
  }

  &.bottom {
    :hover {
      margin-top: 2px;
      border-bottom: 2px solid #3fa9f5;
    }
  }

  &.none {
    margin: 0;
    text-align: left;
    width: 100%;
  }
`;

const MenuItemContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  white-space: nowrap;
`;

export type IndicatorLocation = "left" | "none" | "bottom";

export type MenuItemProps = {
  descriptor: MenuItemDescriptor;
  fullWidth?: boolean;
  indicatorLocation?: IndicatorLocation;
  isDropdownItem: boolean;
  isTabletOrSmaller?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  descriptor,
  fullWidth = false,
  indicatorLocation,
  isDropdownItem,
  isTabletOrSmaller,
}) => {
  return (
    <StyledNavLink
      data-qatag="nav_menu_link_label"
      className={!isTabletOrSmaller && isDropdownItem ? "none" : indicatorLocation}
      to={descriptor.to}
    >
      {({ isActive }) => {
        const icon = isActive ? descriptor.icon : descriptor.unSelectedIcon;

        return (
          <MenuItemContainer data-qatag="menuItemContainer">
            <img
              data-qatag="menuItemIcon"
              src={icon}
              alt=""
            />
            <Typography
              data-qatag="nav_menu_text_descriptor_label"
              component={"span"}
              variant={fullWidth ? "h6" : "body2"}
            >
              {descriptor.label}
            </Typography>
          </MenuItemContainer>
        );
      }}
    </StyledNavLink>
  );
};
