import { useAppDispatch } from "app/redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScanScreen, { ScanScreenProps } from "./ScanScreen";
import { resetScanState, selectProgress, startAllScans } from "./ScanSlice";
import { useEffect } from "react";
import { setLastScanTime } from "app/Agent/AgentSlice";

export const ScanScreenContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const progress = useSelector(selectProgress) || 0;

  useEffect(() => {
    dispatch(startAllScans());

    let time = new Date().toISOString();
    //remove suffix so it doesn't get timezone adjusted
    if (time.endsWith("Z")) {
      time = time.substring(0, time.length - 1);
    }
    dispatch(setLastScanTime(time));
  }, [dispatch]);

  useEffect(() => {
    if (progress >= 1) {
      dispatch(resetScanState());
      navigate("/status", { replace: true });
    }
  }, [navigate, progress]);

  const props: ScanScreenProps = {
    progress: progress,
  };

  return (
    <ScanScreen
      data-qatag="ScanScreen"
      {...props}
    />
  );
};
