import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import logo from "assets/img/logo.png";
import IUser from "model/user/IUser";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { EmailTooltip } from "./components/EmailTooltip";
import { MenuItemList } from "./components/MenuItemList";
import { TabletNavbar } from "./TabletNavbar";

export type MenuItemEntry = MenuItemDescriptor | "divider";

export type NavBarProps = {
  dropdownItems: MenuItemEntry[];
  isAdmin: boolean;
  logoRedirectPath?: string;
  menuItems: MenuItemEntry[];
  user: IUser | null;
};

export type MenuItemDescriptor = {
  icon?: string;
  unSelectedIcon?: string;
  to: string;
  label: string;
  badge?: JSX.Element;
  additionalLocationMatches?: string[];
};

// #region styled-components
const NavbarContainer = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1320px;
`;

const LogoContainer = styled(NavLink)`
  align-items: center;
  display: flex;
  max-height: 60px;
  padding: ${({ theme }) => theme.spacing(0, 2)};

  img {
    width: 184px;

    ${({ theme }) => theme.breakpoints.up("md")} {
      width: 251px;
    }
  }
`;

const MenuItemContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2.5)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  position: relative;
`;

const UserContainer = styled("div")`
  align-items: center;
  color: #535353;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacing(0.5)};
  justify-content: space-around;
  margin-bottom: 1px;
`;

const UserName = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25;
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
`;

const DropdownContainer = styled(Box)`
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -4px 5px 8px #43566420;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2.5, 3)};
  position: absolute;
  right: 0;
  top: 64px;
  z-index: 10;
`;

const VerticalDivider = styled("div")`
  border-left: 1px solid #929396;
  height: 24px;
`;

export const CustomDivider = styled("div")`
  border-bottom: 1px solid #e6e6e6;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
  width: 155px;
`;
// #endregion styled-components

export const NavBar: React.FC<NavBarProps> = ({ user, menuItems, dropdownItems, logoRedirectPath = "/status" }) => {
  // replace with the hooks call in dsoneweb.designsystem when its properly exposed
  const showHeaderNavItems = useMediaQuery("(min-width: 900px)");
  const [drawerState, setDrawerState] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const adminEmail = user?.email || "";

  return (
    <NavbarContainer data-qatag="navBar">
      <LogoContainer
        data-qatag="navBar.home.button"
        id="navBar.home.button"
        to={logoRedirectPath}
      >
        <img
          data-qatag="navBarLogo"
          src={logo}
          alt="navBar Logo"
        />
      </LogoContainer>
      {!showHeaderNavItems ? (
        <TabletNavbar
          data-qatag="tabletNavbar"
          adminEmail={adminEmail}
          drawerState={drawerState}
          dropdownItems={dropdownItems}
          menuItems={menuItems}
          setDrawerState={setDrawerState}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          user={user}
        />
      ) : (
        <MenuItemContainer data-qatag="navbarActions">
          <MenuItemList
            data-qatag="main-menu-item-list"
            items={menuItems}
            isMobileOrTablet={false}
            isDropdownItem={false}
          />
          <VerticalDivider data-qatag="familyDivider" />
          <UserContainer
            data-qatag="userContainer"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <UserName
              data-qatag="userName"
              className="user-name"
            >
              {`${user?.firstName} ${user?.lastName}`}
            </UserName>
            {dropdownItems.length > 0 ? (
              showDropdown ? (
                <ExpandLessIcon data-qatag="expand-less" />
              ) : (
                <ExpandMoreIcon data-qatag="expand-more" />
              )
            ) : null}
          </UserContainer>

          {dropdownItems.length > 0 && showDropdown ? (
            <DropdownContainer
              data-qatag="dropdownContainer"
              onClick={() => setShowDropdown(false)}
            >
              <EmailTooltip
                data-qatag="EmailTooltip"
                fullEmail={adminEmail}
                maxLength={12}
                isTabletOrMobile={false}
              />
              <CustomDivider data-qatag="dropdownDivider" />
              <MenuItemList
                data-qatag="dropdown-menu-item-list"
                items={dropdownItems}
                isMobileOrTablet={false}
                isDropdownItem={true}
              />
            </DropdownContainer>
          ) : null}
        </MenuItemContainer>
      )}
    </NavbarContainer>
  );
};
