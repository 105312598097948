import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "api/axiosBaseQuery";
import { ApiResponse } from "api/types";
import { Subscription, SubscriptionPayload } from "model/subscription/Subscription";

// types
// ------------------------------------
type GetUpdatePreviewRequestArgs = {
  checkoutModelID: string;
};

type GetUpdatePreviewResponse = {
  Amount: number;
  AmountWithoutTax: number;
  TaxAmount: number;
  NextBillingChargeAmount: number;
  UpdateSeatCount: number;
};

type PutUpgradeSubscriptionRequestArgs = {
  checkoutModelID: string;
};

type PutUpgradeSubscriptionResponse = unknown;

//Retrieve REACT_APP_PRODUCTID from .env file
declare const productId: number;

const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/subscription/",
  }),
  tagTypes: ["License", "Subscription"],
  endpoints(build) {
    return {
      getSubscription: build.query<Subscription, void>({
        query: () => {
          return {
            url: ``,
            method: "get",
          };
        },
        providesTags: ["Subscription"],
        transformResponse: (response: SubscriptionPayload) => response?.data,
      }),
      getSubscriptionStatus: build.query({
        query: () => {
          return {
            url: `status`,
            method: "get",
          };
        },
      }),
      getUpdatePreview: build.query<GetUpdatePreviewResponse, GetUpdatePreviewRequestArgs>({
        query: ({ checkoutModelID }) => {
          return {
            url: "update/preview",
            method: "put",
            data: {
              ProductID: productId,
              CheckoutModelID: checkoutModelID,
            },
          };
        },
        transformResponse: (response: ApiResponse<GetUpdatePreviewResponse>) => response.data,
      }),
      upgradeSubscription: build.mutation<
        ApiResponse<PutUpgradeSubscriptionResponse>,
        PutUpgradeSubscriptionRequestArgs
      >({
        query: ({ checkoutModelID }) => {
          return {
            url: "update",
            method: "put",
            data: {
              productID: productId,
              checkoutModelID: checkoutModelID,
            },
          };
        },
        transformResponse: (response) => response.data,
        invalidatesTags: ["License", "Subscription"],
      }),
      cancelSubscription: build.mutation<void, void>({
        query: () => {
          return {
            url: `suspend`,
            method: "put",
          };
        },
        invalidatesTags: ["License", "Subscription"],
      }),
      resumeSubscription: build.mutation<void, void>({
        query: () => {
          return {
            url: `resume`,
            method: "put",
          };
        },
        invalidatesTags: ["License", "Subscription"],
      }),
    };
  },
});

export const {
  useGetUpdatePreviewQuery,
  useGetSubscriptionQuery,
  useGetSubscriptionStatusQuery,
  useUpgradeSubscriptionMutation,
  useCancelSubscriptionMutation,
  useResumeSubscriptionMutation,
} = subscriptionApi;

export { subscriptionApi };
