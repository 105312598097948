import { useCallback, useEffect, useMemo } from "react";
import { Box, Container, Typography } from "@mui/material";
import acceptedCardsImg from "assets/img/accepted-credit-cards.png";
import { Layout } from "components/Layout/Layout";
import billIcon from "assets/icon/icn-payment-method.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { UpdatePaymentForm } from "./UpdatePaymentForm";
import {
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  usePostNewPaymentMethodMutation,
} from "api/BillingApi/BillingApi";
import { Alert, AlertIcon, Spinner } from "dsoneweb.designsystem";
import { IPaymentMethod } from "model/billing/BillingSummaryModel";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { useResumeSubscriptionMutation } from "api/SubscriptionApi/SubscriptionApi";
import { GetMostRecentSubscriptionStatusChange } from "api/LicenseApi/LicenseApiHelpers";
import styled from "@mui/styled-engine";

const PaymentMethodContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
  max-width: 600px;
`;
const FormHeader = styled("div")`
  align-items: center;
  align-self: flex-start;
  display: flex;
  gap: 16px;
  justify-content: center;
`;
const FormHeaderText = styled(Typography)`
  color: #535a6a;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.3125rem;
  }
`;
const CreditCardImg = styled("img")`
  margin: 0;
  height: 16px;
  width: 103px;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 28px;
    width: 178px;
  }
`;
const TitleContainer = styled(Box)`
  display: flex;
  gap: 20px;
  margin: 16px 0;
`;
const Title = styled(Typography)`
  color: #535353;
  font-size: 1.125rem;
  font-weight: 600;
`;

export function UpdatePaymentMethod() {
  const navigate = useNavigate();

  // variable declarations
  const { formatMessage } = useIntl();
  const [isErrorSavingPayment, setIsErrorSavingPayment] = useState(false);

  //Retrieve licenses data
  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
    refetch: refetchLicensesData,
  } = useGetOrganizationLicensesQuery();

  const mostRecentSubscriptionStatusChange = GetMostRecentSubscriptionStatusChange(licensesData);

  // Resume subscription
  const [
    resumeSubscription,
    {
      isError: errorResumingSubscription,
      isLoading: isResumingSubscription,
      isSuccess: isResumingSuccess,
      reset: resetResumingSubscription,
    },
  ] = useResumeSubscriptionMutation();

  const isGenericError = errorResumingSubscription || licensesDataError !== undefined;

  // side effect to redirect on success
  useEffect(() => {
    if (isResumingSuccess) {
      // reset the state to enter again to this page
      refetchLicensesData();
      resetResumingSubscription();
      navigate("/subscription/resumed");
    }
  }, [isResumingSuccess, navigate, refetchLicensesData, resetResumingSubscription]);

  // RTK Queries
  const { data: paymentMethods, isFetching: isFetchingPaymentMethod } = useGetPaymentMethodsQuery();
  const [deletePaymentMethod, { isLoading: isDeletingPaymentMethod }] = useDeletePaymentMethodMutation();
  const [postNewPaymentMethod, { isLoading: isAddingPaymentMethod }] = usePostNewPaymentMethodMutation();

  // Hooks
  const currentPaymentMethodId = useMemo(() => {
    if (paymentMethods && paymentMethods.length) {
      return paymentMethods.find((method) => method.isDefault)?.zID;
    }
  }, [paymentMethods]);

  const handleSubmit = useCallback(
    async (values: IPaymentMethod) => {
      try {
        const response = await postNewPaymentMethod(values).unwrap();

        if (response.succeeded) {
          if (currentPaymentMethodId) {
            await deletePaymentMethod(currentPaymentMethodId);
          }

          // Flag on the URL for a payment declined and resume services
          const currentUrl = window.location.href;

          if (currentUrl.includes("/payment-declined")) {
            //Verify if the user is on a payment declined status

            const isCancelled = mostRecentSubscriptionStatusChange?.isCancellation || false;

            if (isCancelled && resumeSubscription) {
              resumeSubscription();
            } else {
              // User is not on a payment declined status and the parameter is there for some reason and continue with normal flow
              navigate("/payment-method/update/success");
            }
          } else {
            navigate("/payment-method/update/success");
          }
        } else {
          setIsErrorSavingPayment(true);
        }
      } catch (error) {
        setIsErrorSavingPayment(true);
      }
    },
    [
      currentPaymentMethodId,
      deletePaymentMethod,
      licensesData?.subscriptionStatusChanges,
      navigate,
      postNewPaymentMethod,
      resumeSubscription,
    ]
  );

  const handleCloseErrorAlert = () => {
    navigate("/myaccount");
  };
  const isLoading =
    isFetchingPaymentMethod ||
    isAddingPaymentMethod ||
    isDeletingPaymentMethod ||
    isResumingSubscription ||
    isLicensesDataLoading;

  if (isLoading && !isErrorSavingPayment) {
    return (
      <Spinner
        data-qatag="loadingModal"
        isActive={true}
        text="Loading..."
      />
    );
  }

  return (
    <Layout
      data-qatag="layout"
      qatag="paymentMethod"
    >
      <PaymentMethodContainer data-qatag="paymentMethod.container">
        {isErrorSavingPayment ? (
          <Alert
            data-qatag="errorMessage"
            icon={AlertIcon.Warning}
            title="Something went wrong"
            text={formatMessage({
              id: "alert.error.text",
              defaultMessage: "There was an error updating your payment method",
            })}
            buttonText={formatMessage({
              id: "alert.error.button.text",
              defaultMessage: "Back to My Account",
            })}
            modalMinHeight="35%"
            approveHandler={handleCloseErrorAlert}
            isOpen={true}
            closeHandler={handleCloseErrorAlert}
          />
        ) : (
          <>
            <FormHeader data-qatag="paymentMethod.formHeader">
              <FormHeaderText data-qatag="paymentMethod.formHeader.text">
                <FormattedMessage
                  data-qatag="Payment.FormCreditCard"
                  id="Payment.FormCreditCard"
                  defaultMessage="Credit or Debit Card"
                />
              </FormHeaderText>
              <CreditCardImg
                data-qatag="paymentMethod.formHeader.img"
                src={acceptedCardsImg}
                alt="Accepted cards brands"
              />
            </FormHeader>
            <TitleContainer data-qatag="paymentMethod.title">
              <img
                data-qatag="paymentMethod.title.icon"
                src={billIcon}
                alt="credit card icon"
              />
              <Title data-qatag="paymentMethod.title">
                <FormattedMessage
                  id="paymentMethod.title.text"
                  data-qatag="paymentMethod.title.text"
                  defaultMessage="Update Payment Method"
                />
              </Title>
            </TitleContainer>
            <UpdatePaymentForm
              data-qatag="paymentMethod.form"
              onSubmit={handleSubmit}
            />
          </>
        )}

        <Alert
          data-qatag="alertCTASelectPaymentMethod"
          modalMinHeight="388px"
          icon={AlertIcon.Warning}
          title={formatMessage({
            id: "isGenericError.title",
            defaultMessage: "Something went wrong",
          })}
          text={formatMessage({
            id: "isGenericError.subtitle",
            defaultMessage: "Please retry again.",
          })}
          buttonText={formatMessage({
            id: "isGenericError.continue",
            defaultMessage: "Close",
          })}
          approveHandler={resetResumingSubscription}
          isOpen={isGenericError}
          closeHandler={resetResumingSubscription}
        />
      </PaymentMethodContainer>
    </Layout>
  );
}
