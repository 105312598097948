import { Box, Typography } from "@mui/material";
import titleIcon from "assets/img/driver/icn-install-problem.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

//#region Component Styles
const ModalRoot = styled(Box)`
  width: 496px;
  height: 427px;
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(8)};
  padding-right: ${(props) => props.theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 330px;
    height: 427px;
    padding-left: ${(props) => props.theme.spacing(5)};
    padding-right: ${(props) => props.theme.spacing(5)};
    padding-top: ${(props) => props.theme.spacing(3)};
  }
`;

const ActionButton = styled(AppButton)`
  width: 255px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionContainer = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(3)};
  margin-bottom: ${(props) => props.theme.spacing(7)};
`;

//#endregion Component Styles

//#region Component Props
export interface IInstallFailedProps {
  onContinue: () => void;
}
//#endregion Component Props

//#region Component
export const InstallFailed: React.FC<IInstallFailedProps> = (props) => {
  return (
    <ModalRoot
      data-qatag="driver.modal.install.failed"
      id="driver.modal.install.failed"
    >
      <img
        data-qatag="driver.modal.install.failed.icon"
        id="driver.modal.install.failed.icon"
        src={titleIcon}
        alt=""
      />
      <FormattedMessage
        data-qatag="driver.modal.install.failed.title"
        id="driver.modal.install.failed.title"
        defaultMessage="There was a problem {br} during the install..."
        values={{
          br: (
            <br
              data-qatag="driver.modal.install.failed.break"
              id="driver.modal.install.failed.break"
            />
          ),
        }}
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.failed.title.text"
            id="driver.modal.install.failed.title.text"
            variant="h4"
            color="textPrimary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <DescriptionContainer
        data-qatag="driver.modal.install.failed.description.container"
        id="driver.modal.install.failed.description.container"
      >
        <FormattedMessage
          data-qatag="driver.modal.install.failed.description"
          id="driver.modal.install.failed.description"
          defaultMessage="An error report has been sent to our engineering team and will be looked at shortly"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.failed.description.text"
              id="driver.modal.install.failed.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </DescriptionContainer>
      <ActionButton
        ariaLabel="Continue"
        buttonType="button"
        data-qatag="driver.modal.install.failed.continueButton"
        id="driver.modal.install.failed.continueButton"
        buttonStyle={AppButtonStyles.Green}
        onClick={props.onContinue}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.failed.continueButton.content"
          id="driver.modal.install.failed.continueButton.content"
          defaultMessage="Continue"
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.failed.continueButton.content.text"
              id="driver.modal.install.failed.continueButton.content.text"
              variant="body2"
              align="center"
              style={{ fontWeight: 600 }}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </ActionButton>
    </ModalRoot>
  );
};
//#endregion Component
