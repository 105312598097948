import IAllocatedLicense from "./IAllocatedLicense";
import IActivatedLicense from "./IActivatedLicense";
import IProvisionedLicense from "./IProvisionedLicense";
import { ISubscriptionStatusChange } from "model/license/ISubscriptionStatusChange";

/* eslint-disable no-unused-vars */
export enum FeatureFlags {
  AllFeatures = "AllFeatures",
  AppOptimization = "AppOptimization",
  Drivers = "Drivers",
  PageBoost = "PageBoost",
  SoftwareProtection = "SoftwareProtection",
  SystemCleanup = "SystemCleanup",
  WebProtection = "WebProtection",
  WindowsEnhancements = "WindowsEnhancements",
}
/* eslint-enable no-unused-vars */

export interface ILicenseTier {
  id: number;
  name: string;
  description: string;
  productRatePlanID: number;
  seatCount: number;
  timestamp: string;
  featureFlags: string;
}

export interface ILicenseData {
  provisionedLicenses: IProvisionedLicense[];
  allocatedLicenses: IAllocatedLicense[];
  activatedLicenses: IActivatedLicense[];
  availableSeats: number;
  subscriptionStatusChanges: ISubscriptionStatusChange[];
  seatsUsed: number;
  totalSeatCount: number;
  licenseTier: ILicenseTier | null;
}
