import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { useGetSubscriptionQuery } from "api/SubscriptionApi/SubscriptionApi";
import { isFeatureArrayIncludedInLicense } from "core/Helpers";
import { Spinner } from "dsoneweb.designsystem";
import { Navigate } from "react-router";

//wrapping components with this will perform checks to determine the state of a customer's account
//and redirect if necessary based on that state
type Props = {
  children?: React.ReactNode;
  returnRoute?: string;
  requiredLicenseFlags: string[];
};

const LicensedFeatureWrapper = ({ children, requiredLicenseFlags, returnRoute }: Props) => {
  //Retrieve licenses data
  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
  } = useGetOrganizationLicensesQuery();

  const licenseFeatureFlags = licensesData?.licenseTier?.featureFlags;

  //test if the license has the required feature flags
  const licenseHasFeature = isFeatureArrayIncludedInLicense(requiredLicenseFlags, licenseFeatureFlags);

  //If their license does not include the feature retrieve subscription data
  const {
    data: subscription,
    isFetching: isFetchingSubscription,
    error: fetchingSubscriptionError,
  } = useGetSubscriptionQuery(undefined, { skip: licenseHasFeature });

  const isSubscriptionActive = subscription?.subscription.status === "Active";

  const isLoading = isFetchingSubscription || isLicensesDataLoading;

  const isError = fetchingSubscriptionError || licensesDataError;

  if (isLoading) {
    return (
      <Spinner
        data-qatag="loadingModal"
        isActive={true}
        text="Loading..."
      />
    );
  }

  if (isError) {
    throw new Error("LicensedFeatureWrapper: Error retrieving subscription or license data");
  }

  //user does not have the feature but their subscription is not active
  //they need to resume before they can upgrade, show resume screen
  if (!licenseHasFeature && !isSubscriptionActive) {
    return (
      <Navigate
        data-qatag="resumeRequiredNavigate"
        to="/resume-required"
        state={returnRoute}
      />
    );
  }

  //user does not have the feature and their subscription is active navigate to upsell
  if (!licenseHasFeature && isSubscriptionActive) {
    return (
      <Navigate
        data-qatag="requireUpgradeNavigate"
        to="/upsell-promotion"
        state={returnRoute}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default LicensedFeatureWrapper;
