export type SupportedBrowser = "firefox" | "edge" | "chrome";

export function getIsWindows(): boolean {
  const userAgent = navigator.userAgent;
  return userAgent.indexOf("Windows") !== -1;
}

export function getCurrentBrowser(): SupportedBrowser {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.indexOf("firefox") > -1) {
    return "firefox";
  }
  if (userAgent.indexOf("edg") > -1) {
    return "edge";
  }
  return "chrome";
}

export function getChromeVersion(): number | null {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : null;
}

export function isChromeVersion115OrHigher(): boolean {
  const userAgent = getChromeVersion();
  return userAgent != null && userAgent >= 115;
}
