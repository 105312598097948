import { ReactElement } from "react";
import { Typography, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import styled from "@mui/styled-engine";

export interface IMachinePickerHeader {
  computerModel: string | ReactElement;
  headerImage: string;
}
const Container = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 36px;
  width: 100%;
`;
const StyledImage = styled("img")`
  max-height: 136px;
`;
function MachinePickerHeader({ computerModel, headerImage }: IMachinePickerHeader) {
  return (
    <Container data-qatag="container">
      <StyledImage
        data-qatag="icon"
        src={headerImage}
        alt="Laptop"
      />
      <div data-qatag="message">
        <Box data-qatag="messageContainer">
          <Typography
            data-qatag="textMessage"
            variant="h6"
            color="primary"
          >
            <FormattedMessage
              data-qatag="machine.picker.driver.support"
              id="machine.picker.driver.support"
              defaultMessage="Driver Support One has successfully identified your computer as a"
            />
          </Typography>
        </Box>
        <Box data-qatag="computerModelContainer">
          <Typography
            data-qatag="computerModelText"
            variant="h4"
            color="textPrimary"
          >
            {computerModel}
          </Typography>
        </Box>
      </div>
    </Container>
  );
}

export default MachinePickerHeader;
