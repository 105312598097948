import { Box, Typography } from "@mui/material";
import SeatIllustration from "assets/img/illustration-seats.svg";
import styles from "./EmptyState.module.css";
import styled from "@mui/styled-engine";

export interface IEmptyState {
  /* Error Message */
  error: string;
  /* Suggestion for the error */
  suggestion: string | React.ReactNode;
}

//#region component styles

const SuggestionMessage = styled(Box)`
  color: ${({ theme }) => theme.palette.text.disabled};
  margin: 16px auto 0;
  max-width: 440px;
  text-align: center;
`;

//#endregion component styles

const EmptyState: React.FC<IEmptyState> = ({ error, suggestion }) => {
  return (
    <div
      data-qatag="container"
      className={styles.container}
    >
      <Box
        data-qatag="SeatIllustrationContainer"
        mt={1}
        textAlign={"center"}
      >
        <img
          data-qatag="icon"
          className={styles.icon}
          src={SeatIllustration}
          alt="Empty state"
        />
      </Box>
      <Box
        data-qatag="ErrorContainer"
        mt={3}
      >
        <Typography
          data-qatag="ErrorLabel"
          align="center"
          variant="h3"
        >
          {error}
        </Typography>
      </Box>
      <SuggestionMessage data-qatag="errorDescription">{suggestion}</SuggestionMessage>
    </div>
  );
};

export default EmptyState;
