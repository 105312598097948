import { Box, Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import { CustomizedSelect } from "components/Select/Select";
import { IntelligenceTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

const IntelligenceContainer = styled(Box)`
  align-items: center;
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
  padding-bottom: 32px;
  width: 100%;
`;

export type IntelligenceTypeProps = {
  intelligenceSelected: IntelligenceTypes;
  setIntelligenceSelected: (intelligenceType: IntelligenceTypes) => void;
};
export function IntelligenceType({ intelligenceSelected, setIntelligenceSelected }: IntelligenceTypeProps) {
  const { formatMessage } = useIntl();
  const messages = defineMessages({
    universalMatch: {
      id: "machinePicker.messages.universalMatch",
      defaultMessage: "Universal Match Intelligence (Only recommend universal drivers)",
      description: "select message for universal match intelligence",
    },
    oemMatch: {
      id: "machinePicker.messages.oemMatch",
      defaultMessage: "OEM Match Intelligence (My computer is a Dell, HP, etc. - Prioritize OEM Drivers)",
      description: "select message for OEM match intelligence",
    },
    motherboardMatch: {
      id: "machinePicker.messages.motherboardMatch",
      defaultMessage: "Motherboard Match Intelligence (My computer is customer built - Prioritize motherboard drivers)",
      description: "select message for motherboard match intelligence",
    },
  });
  const intelligenceOptions = [
    {
      value: IntelligenceTypes.OEM,
      label: formatMessage(messages.oemMatch),
    },
    {
      value: IntelligenceTypes.MB,
      label: formatMessage(messages.motherboardMatch),
    },
    {
      value: IntelligenceTypes.None,
      label: formatMessage(messages.universalMatch),
    },
  ];
  return (
    <IntelligenceContainer data-qatag="IntelligenceContainer">
      <Typography
        data-qatag="textContainer"
        align="center"
        variant="h6"
        color="primary"
      >
        <FormattedMessage
          data-qatag="title"
          id={`machine.picker.form.title`}
          defaultMessage="However, a more specific match may be available. Please use the form below to select your system."
        />
      </Typography>
      <Box data-qatag="intelligenceSelectContainer">
        <Typography
          data-qatag="intelligenceTypeText"
          align={"center"}
          variant="h6"
          color="primary"
          sx={{ fontWeight: "600" }}
        >
          <FormattedMessage
            data-qatag="intelligenceType"
            id={`machine.picker.form.intelligence.type`}
            defaultMessage="Intelligence Type:"
          />
        </Typography>
        <CustomizedSelect
          data-qatag="intelligenceSelect"
          currentValue={intelligenceSelected}
          options={intelligenceOptions}
          onChangeHandler={(intelligenceSelect) =>
            setIntelligenceSelected(IntelligenceTypes[intelligenceSelect as IntelligenceTypes])
          }
        />
      </Box>
    </IntelligenceContainer>
  );
}
