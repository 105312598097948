import { Box, Typography } from "@mui/material";
import fileExplorerIcon from "assets/icon/icn-file-explorer.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

const StyledContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  height: 380px;
  width: 330px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 660px;
  }
`;

const StyledAppButton = styled(AppButton)`
  font-weight: bold;
  margin-top: 12px;
  width: 280px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 32px;
  }
`;
export interface IInstallUserInteractionProps {
  onFinish: () => void;
}

export const InstallUserInteraction: React.FC<IInstallUserInteractionProps> = (props) => {
  return (
    <StyledContainer
      data-qatag="driver.modal.install.user_interaction"
      id="driver.modal.install.user_interaction"
    >
      <img
        data-qatag="driver.modal.install.user_interaction.icon"
        id="driver.modal.install.user_interaction.icon"
        src={fileExplorerIcon}
        alt=""
      />

      <Typography
        data-qatag="driver.modal.install.user_interaction.title.text"
        id="driver.modal.install.user_interaction.title.text"
        variant="h5"
        color="textPrimary"
        align="center"
      >
        <FormattedMessage
          data-qatag="driver.modal.install.user_interaction.title"
          id="driver.modal.install.user_interaction.title"
          defaultMessage="Please look in File Explorer"
        />
      </Typography>

      <Typography
        data-qatag="driver.modal.install.user_interaction.description.text"
        id="driver.modal.install.user_interaction.description.text"
        variant="body2"
        color="primary"
        align="center"
        sx={{ maxWidth: { xs: "280px", md: "538px" } }}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.user_interaction.description"
          id="driver.modal.install.user_interaction.description"
          defaultMessage="(it may be behind this application) to find a file similarly named setup.exe or readme.txt. Open the file manually for additional instructions"
        />
      </Typography>

      <StyledAppButton
        ariaLabel="finish"
        buttonType="button"
        data-qatag="driver.modal.install.user_interaction.finishButton"
        id="driver.modal.install.user_interaction.finishButton"
        buttonStyle={AppButtonStyles.Green}
        onClick={props.onFinish}
      >
        <FormattedMessage
          data-qatag="driver.modal.install.user_interaction.finishButton.content"
          id="driver.modal.install.user_interaction.finishButton.content"
          defaultMessage="Finish"
        />
      </StyledAppButton>
    </StyledContainer>
  );
};
//#endregion Component
