import { FormattedMessage } from "react-intl";

export type IFixedFormattedMessageProps = {
  id: string;
  "data-qatag": string;
  defaultMessage: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
};

// temporary work-around (see https://github.com/yahoo/babel-plugin-react-intl/issues/119)
export function FixedFormattedMessage(props: IFixedFormattedMessageProps) {
  // eslint-disable-next-line solveiq/data-qatag
  return <FormattedMessage {...props} />;
}
