import { Box, Container, Icon, IconButton, useMediaQuery, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";
import EditIcon from "@mui/icons-material/EditOutlined";
import { LinkBlueAppButton } from "./AppButtons";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

const HeaderContainer = styled(Box)`
  align-items: center;
  display: flex;
  margin: 12px 0;
  padding: 0;

  div:only-child {
    // for the cancel subscription section
    margin: 0 auto;

    ${(props) => props.theme.breakpoints.up("md")} {
      margin: 24px 0 24px auto;
    }
  }
`;
const HeaderTitleContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-grow: 2;
  padding: 0;
`;
const HeaderTitle = styled(Typography)`
  color: #535353;
  font-size: 1.125rem;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.375rem;
  }
`;
const EditIconButton = styled(IconButton)`
  color: #007dba;
  background: #fafafa;
  border-radius: 50%;
  margin-right: 16px;
  max-height: 32px;
  max-width: 32px;
`;
const LinkContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
`;

type FixedFormattedMessage = {
  id: string;
  "data-qatag": string;
  defaultMessage: string;
};

// temporary work-around (see https://github.com/yahoo/babel-plugin-react-intl/issues/119)
function FormattedMessageFixed(props: FixedFormattedMessage) {
  // eslint-disable-next-line solveiq/data-qatag
  return <FormattedMessage {...props} />;
}

export interface IHeaderProps {
  hideActionButton?: boolean;
  hideTextLinkOnMobile: boolean;
  icon?: string;
  linkPath: string;
  linkText: string;
  showEditIcon?: boolean;
  title?: string;
  tag: string;
}
/**
 * Reusable component for headers in MyAccount
 * @method Header
 * @returns {JSX.Element}
 */
export function Header({
  hideActionButton,
  hideTextLinkOnMobile,
  icon,
  linkPath,
  linkText,
  showEditIcon,
  title = "",
  tag,
}: IHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const handleLinkButtonClick = (path: string) => {
    navigate(path);
  };
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  const showLinkButton = isNotMobile || (!isNotMobile && !hideTextLinkOnMobile);

  return (
    <HeaderContainer data-qatag="myAccount.header">
      {title ? (
        <HeaderTitleContainer data-qatag="myAccount.headerTitleContainer">
          {icon ? (
            <Icon
              data-qatag="myAccount.headerIcon"
              sx={{ marginBottom: 1, marginRight: 1 }}
            >
              <img
                alt=""
                data-qatag="myAccount.headerIcon.icon"
                src={icon}
              />
            </Icon>
          ) : null}

          <HeaderTitle data-qatag="myAccount.headerTitle">
            <FormattedMessageFixed
              id={`${tag}.headerTitle.message`}
              data-qatag="myAccount.headerTitle.message"
              defaultMessage={title}
            />
          </HeaderTitle>
        </HeaderTitleContainer>
      ) : null}

      {!hideActionButton ? (
        <LinkContainer data-qatag="myAccount.headerLinkContainer">
          {showEditIcon ? (
            <EditIconButton
              data-qatag="myAccount.headerLink.EditIconButton"
              onClick={() => handleLinkButtonClick(linkPath)}
            >
              <EditIcon data-qatag="myAccount.headerLink.EditIcon" />
            </EditIconButton>
          ) : null}
          {showLinkButton ? (
            <LinkBlueAppButton
              ariaLabel={linkText}
              data-qatag="myAccount.headerLink.button"
              linkPath={linkPath}
              linkText={linkText}
              tag={`${tag}.headerLink`}
              underline={false}
              buttonType="button"
            />
          ) : null}
        </LinkContainer>
      ) : null}
    </HeaderContainer>
  );
}
