import { useEffect } from "react";
import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { Layout } from "components/Layout/Layout";
import { KEY_FALLBACK_UUID } from "./constants";

declare const supportPageUrl: string;

const StyledIframe = styled("iframe")`
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  height: calc(100vh - 8rem);
  width: 100%;

  @media (min-height: 600px) {
    height: calc(100vh - 14rem);
  }
`;

const fallbackUUID = uuidv4();

function Support() {
  const currentUUID = useSelector(selectCurrentUuid);
  const urlParams = new URLSearchParams(window.location.search);
  const deviceDescriptionQuery = urlParams.get("deviceDescription");
  const supportPageFrameUrl = new URL(supportPageUrl);

  supportPageFrameUrl.searchParams.append("uuid", currentUUID || fallbackUUID);

  if (deviceDescriptionQuery) {
    supportPageFrameUrl.searchParams.append("deviceDescription", deviceDescriptionQuery);
  }

  useEffect(() => {
    if (!currentUUID) {
      // save fallback uuid on local storage
      localStorage.setItem(KEY_FALLBACK_UUID, fallbackUUID);
    }
  }, [currentUUID]);

  return (
    <Layout
      data-qatag="layout"
      qatag="layout"
      useTemplating={true}
    >
      <Layout.Content
        data-qatag="Support.layoutContent"
        minHeight="none"
      >
        <StyledIframe
          data-qatag="Support.iframe"
          src={supportPageFrameUrl.toString()}
          title="Printer Install Assistant"
        />
      </Layout.Content>
    </Layout>
  );
}

export default Support;
