import { Typography } from "@mui/material";
import { OnOffSwitch } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";
import privateImage from "assets/img/dns/icn-DNS-private.svg";
import preventImage from "assets/img/dns/icn-DNS-prevent.svg";
import infoImage from "assets/img/info-icon.png";
import quad9Logo from "assets/img/dns/quad9 - logo.png";
import { useState } from "react";
import styled from "@mui/styled-engine";

export type MalwareSectionProps = {
  isOn?: boolean | null;
  onSwitchClick: (isOn: boolean) => Promise<void>;
};

const Container = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  margin-top: 20px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0;
  }
`;

const Title = styled(Typography)`
  font-size: 1.313rem;
  line-height: 1.5rem;
  color: #007dba;

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 1.125rem;
    margin-top: 36px;
  }
`;

const StyledSwitch = styled(OnOffSwitch)`
  margin-top: 36px;
`;

const NullSwitch = styled("div")`
  display: block;
  height: 21px;
  margin-top: 36px;
`;

const ImagesContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: space-between;
  margin-top: 70px;
`;

const ImageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: space-between;
  max-width: 150px;
`;

const Image = styled("img")`
  width: 82px;
  height: 82px;
  margin: 0 auto;
`;

const ImageText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #007dba;
  letter-spacing: 0px;
  font-weight: 600;
`;

const Divider = styled("div")`
  width: 80%;
  height: 0;
  margin: 40px auto;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
`;

const Explanation = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 15px;
  padding-left: 40px;
`;

const Emphasis = styled("span")`
  font-weight: 600;
  display: inline;
`;

const Logo = styled("img")`
  width: 72px;
  height: 50px;
`;

const Quad9Container = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 32px;
  position: relative;
`;

const HoverContainer = styled("div")`
  position: absolute;
  width: 330px;
  top: -460px;
  left: -55px;
  box-shadow: 0px 4px 12px #00000026;
  border: 1px solid #00000000;
  background: #ffffff;
  border-radius: 14px;
  padding: 21px 31px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    width: 645px;
    top: -230px;
    align-items: flex-start;
  }
`;

const HoverText = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 25px;
  text-align: left;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0;
    margin-left: 19px;
  }
`;

const InfoImage = styled("img")`
  width: 14px;
  height: 14px;
  margin-right: 14px;
`;

const PoweredByText = styled(Typography)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-right: 15px;
`;

export const MalwareSection: React.FC<MalwareSectionProps> = ({ isOn, onSwitchClick }) => {
  const [showQuad9Hover, setShowQuad9Hover] = useState<boolean>(false);

  return (
    <Container data-qatag="Container">
      <Title
        data-qatag="title"
        variant="h4"
      >
        <FormattedMessage
          data-qatag="dnsProtection.MalwareSection.title"
          id="dnsProtection.MalwareSection.title"
          defaultMessage="Block Known Malware Sites"
        />
      </Title>
      {isOn === null ? (
        <NullSwitch data-qatag="NullSwitch" />
      ) : (
        <StyledSwitch
          data-qatag="pageboostSwitch"
          onClick={(e: React.MouseEvent<HTMLElement>, isOn: boolean) => {
            onSwitchClick(isOn);
          }}
          isDisabled={false}
          isOnInitially={isOn}
        />
      )}
      <ImagesContainer data-qatag="ImagesContainer">
        <ImageContainer data-qatag="ImageContainer">
          <Image
            data-qatag="image"
            src={privateImage}
            alt="privacy icon"
          />
          <ImageText
            data-qatag="ImageText"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="dnsProtection.MalwareSection.privacy"
              id="dnsProtection.MalwareSection.privacy"
              defaultMessage="Keep your web browsing private"
            />
          </ImageText>
        </ImageContainer>
        <ImageContainer data-qatag="ImageContainer">
          <Image
            data-qatag="image"
            src={preventImage}
            alt="prevent icon"
          />
          <ImageText
            data-qatag="ImageText"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="dnsProtection.MalwareSection.prevent"
              id="dnsProtection.MalwareSection.prevent"
              defaultMessage="Prevent access to malicious websites"
            />
          </ImageText>
        </ImageContainer>
      </ImagesContainer>
      <Divider data-qatag="divider" />
      <Title
        data-qatag="title"
        variant="h4"
      >
        <FormattedMessage
          data-qatag="dnsProtection.MalwareSection.HowItWorks"
          id="dnsProtection.MalwareSection.HowItWorks"
          defaultMessage="How It Works"
        />
      </Title>
      <Explanation
        data-qatag="explanation"
        variant="body2"
      >
        <FormattedMessage
          data-qatag="dnsProtection.MalwareSection.Explanation"
          id="dnsProtection.MalwareSection.Explanation"
          defaultMessage="{emphasis} combines over 20 threat intelligence providers to prevent you from accessing sites known to include malware or other threats. No other antivirus solution provides this!"
          values={{
            emphasis: (
              <FormattedMessage
                data-qatag="dnsProtection.MalwareSection.quad9"
                id="dnsProtection.MalwareSection.quad9"
                defaultMessage="Quad9"
              >
                {(text) => <Emphasis data-qatag="Emphasis">{text}</Emphasis>}
              </FormattedMessage>
            ),
          }}
        />
      </Explanation>
      <Quad9Container
        data-qatag="quad9Container"
        onMouseEnter={() => setShowQuad9Hover(true)}
        onMouseLeave={() => setShowQuad9Hover(false)}
      >
        {showQuad9Hover && (
          <HoverContainer data-qatag="quad9HoverContainer">
            <Logo
              data-qatag="quad9Image"
              src={quad9Logo}
              alt="quad9 logo"
            />
            <HoverText data-qatag="quad9HoverText">
              <FormattedMessage
                data-qatag="dnsProtection.MalwareSection.hoverText"
                id="dnsProtection.MalwareSection.hoverText"
                defaultMessage="{emphasis} blocks against known malicious domains, preventing your computers and IoT devices from connecting to malware or phishing sites with the help of over 20 different threat intelligence partners. {emphasis} systems are also distributed worldwide in more than 145 locations in 88 nations which means faster DNS than almost any other. Finally, {emphasis} collects no personally identifiable information improving your privacy over any other DNS service."
                values={{
                  emphasis: (
                    <FormattedMessage
                      data-qatag="dnsProtection.MalwareSection.quad9"
                      id="dnsProtection.MalwareSection.quad9"
                      defaultMessage="Quad9"
                    >
                      {(text) => <Emphasis data-qatag="Emphasis">{text}</Emphasis>}
                    </FormattedMessage>
                  ),
                }}
              />
            </HoverText>
          </HoverContainer>
        )}
        <InfoImage
          data-qatag="infoImage"
          src={infoImage}
          alt="info icon"
        />
        <PoweredByText data-qatag="poweredByText">
          <FormattedMessage
            data-qatag="dnsProtection/MalwareSection.poweredBy"
            id="dnsProtection/MalwareSection.poweredBy"
            defaultMessage="Powered By:"
          />
        </PoweredByText>
        <Logo
          data-qatag="quad9Image"
          src={quad9Logo}
          alt="quad9 logo"
        />
      </Quad9Container>
    </Container>
  );
};
