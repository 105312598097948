import { ExpiredLicenseAlert } from "./ExpiredLicenseAlert";

export type ExpiredAlertProps = {
  isAdmin: boolean;
  closeHandler: () => void;
  onResumeSubscription: () => void;
};

export function ExpiredLicenseAlertContainer({ isAdmin, closeHandler, onResumeSubscription }: ExpiredAlertProps) {
  const closeAlert = () => {
    closeHandler();
  };

  const handleResumeSubscription = () => {
    onResumeSubscription();
  };

  return (
    <ExpiredLicenseAlert
      data-qatag="ExpiredLicenseAlert"
      isAdmin={isAdmin}
      onApprove={isAdmin ? handleResumeSubscription : closeAlert}
      onClose={closeAlert}
    />
  );
}
