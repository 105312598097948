import { SectionContainer } from "../components/StyledComponents";
import { Header } from "../components/MyAccountHeader";
import PersonIcon from "assets/icon/icn-admin.svg";
import { LabelValuePair } from "../components/LabelValuePair";
import IUser from "model/user/IUser";

export interface IPersonalInformationSectionProps {
  defaultMessage?: string;
  user?: IUser;
}

export const PersonalInformationSection = ({ user }: IPersonalInformationSectionProps) => {
  const testTagPrefix = `myAccount.personalInfo`;

  return (
    <SectionContainer data-qatag="myAccount.personalInfo">
      <Header
        data-qatag="myAccount.personalInfo.header"
        hideTextLinkOnMobile={true}
        icon={PersonIcon}
        linkPath="/updateinformation"
        linkText="Update"
        showEditIcon={true}
        tag={testTagPrefix}
        title="Personal Information"
      />
      <LabelValuePair
        data-qatag="myAccount.personalInfo.userName"
        label={"Name:"}
        tag={`${testTagPrefix}.userName`}
        value={`${user?.firstName} ${user?.lastName}`}
      />
      <LabelValuePair
        data-qatag="myAccount.personalInfo.userEmail"
        label={"Email:"}
        tag={`${testTagPrefix}.userEmail`}
        value={`${user?.email}`}
      />
    </SectionContainer>
  );
};
