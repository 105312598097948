import SuccessUpdate from "features/SubscriptionManagement/SuccessUpdate/SuccessUpdate";
import { FormattedMessage } from "react-intl";

function UpdatedInformationSuccess() {
  return (
    <SuccessUpdate
      data-qatag="UpdatedInformationSuccess"
      icon="information"
      subtitle={
        <FormattedMessage
          data-qatag="UpdatedInformationSuccess.title"
          id="UpdatedInformationSuccess.title"
          defaultMessage="Your Information has been updated!"
        />
      }
      title={
        <FormattedMessage
          data-qatag="UpdatedInformationSuccess.subtitle"
          id="UpdatedInformationSuccess.subtitle"
          defaultMessage="Your information has been successfully updated."
        />
      }
    />
  );
}

export default UpdatedInformationSuccess;
