import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import gears from "assets/img/threeGears.svg";
import styled from "@mui/styled-engine";

export type HeaderProps = {
  children?: React.ReactNode;
};

//#region component styles

const Container = styled(Box)`
  width: 100%;
  background-color: white;
  border-radius: 14px;
`;

const Content = styled("div")`
  width: 100%;
  padding: 12px 0px;
  display: flex;
`;

const Icon = styled("img")`
  width: 40px;
  height: 28px;
  margin-right: 16px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 120px;
    height: 88px;
    margin: 0px 32px 0px 0px;
  }
`;

const TitleText = styled("div")`
  color: ${({ theme }) => theme.palette.secondary.light};
  font: normal normal 600 1.125rem Roboto;
  letter-spacing: 0.18px;
  color: #007dba;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 600 1.3125rem Roboto;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font: normal normal 500 1.5rem Roboto;
  }
`;

const IndicatorBox = styled(Box)`
  margin: 8px 0px 8px -48px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 8px 0px 8px 0px;
  }
`;

//#endregion component styles

function Header({ children }: HeaderProps) {
  return (
    <Container data-qatag="container">
      <Content data-qatag="content">
        <Icon
          data-qatag="icon"
          src={gears}
          alt="wire-frame"
        />
        <Box
          data-qatag="titleTextBox"
          width="100%"
        >
          <TitleText data-qatag="titleText">
            <>
              <FormattedMessage
                data-qatag="titleText"
                id="header.app.optimization.title"
                defaultMessage="Software Protection"
              />
              {children}
            </>
          </TitleText>
          <IndicatorBox data-qatag="indicatorBox">
            <FormattedMessage
              data-qatag="body.app.optimization.description"
              id="body.app.optimization.description"
              defaultMessage="This service continuously prevents deceptive apps from stealing your data or impacting productivity."
            />
          </IndicatorBox>
        </Box>
      </Content>
    </Container>
  );
}

export default Header;
