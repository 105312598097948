import { Layout } from "components/Layout/Layout";
import { Box } from "@mui/material";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";
import maliciousProtectionIcon from "assets/img/icn-malicious-protection.svg";
import privacyProtectionIcon from "assets/img/icn-privacy-protection.svg";
import optimizationServicesIcon from "assets/img/icn-Optimization-Services.svg";
import onDemandIcon from "assets/img/icn-On-Demand.svg";
import { Spinner, Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

export type UpSellPromotionProps = {
  additionalDevicesCount: number;
  price?: string;
  monthlyPrice?: number;
  todayCharge?: number;
  rateMonthly?: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isPremium?: boolean;
  handleSkip: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleUpgradeSubscription: any; // I'm sorry for the dishonor but I give up with this type callback to mutation
  handleResetUpgradeStatus: () => void;
};

//#region component styles

const Title = styled(Box)`
  text-align: center;
  font: normal normal 500 1.125rem/1.5rem Roboto;
  letter-spacing: 0px;
  color: #0285e1;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 28px;
    font: normal normal 500 1.5rem/2rem Roboto;
  }
`;

const TitleNewLine = styled("span")`
  display: block;

  ${(props) => props.theme.breakpoints.up("lg")} {
    display: inline;
  }
`;

const Subtitle = styled(Box)`
  text-align: left;
  margin-top: 12px;
  font: normal normal 400 1rem Roboto;
  letter-spacing: 0.4px;
  color: #535a6a;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 600px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 748px;
  }
`;

const FeaturesContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 220px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: column;
    margin-top: 20px;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 20px;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }
`;

const Feature = styled(Box)`
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: 25%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 32px;
    flex-basis: 45%;
    & > span {
      width: 136px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: 20%;
  }
`;

const Divider = styled("div")<{ hide?: boolean }>`
  background-color: #e5e5e5;
  height: 1px;
  width: 220px;
  margin: 0 8px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 88px;
    width: 1px;
    margin: 8px 0;
    display: ${({ hide }) => (hide ? "none" : "inherit")};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 40px;
    display: inherit;
  }
`;

const SuiteIcon = styled("img")`
  height: 60px;
  width: 60px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 80px;
    width: 80px;
  }
`;
const SuiteLabel = styled("span")`
  font: normal normal normal 0.875rem/1.375rem Roboto;
  letter-spacing: 0px;
  color: #4a4a4a;
`;

const PolicyLink = styled("a")`
  color: #0285e1;
  text-decoration: underline;
`;

const StyledButton = styled(AppButton)`
  margin-top: 64px;
  width: 370px;
  height: 56px;
  font: normal normal 600 1rem Roboto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 44px;
    font: normal normal 600 1.125rem Roboto;
  }
  &:hover {
    opacity: 0.9;
  }
`;

const ButtonLink = styled(AppButton)`
  font: normal normal normal 1rem/1.5rem Roboto;
  letter-spacing: 0.16px;
  text-decoration: underline;
`;

const CardFooter = styled("div")`
  margin-top: 48px;
  color: #4b4b4b;
  font: normal normal normal 0.625rem/0.875rem Roboto;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal normal 0.75rem/1.125rem Roboto;
    max-width: 712px;
  }
`;

const ContentFooter = styled("div")`
  color: #555555;
  font: normal normal 400 0.625rem/0.9375rem Open Sans;
  letter-spacing: 0px;
  margin-top: 36px;
  max-width: 752px;
  text-align: center;
  margin-top: 36px;
`;

//#endregion component styles

function UpSellPromotion({
  additionalDevicesCount,
  price,
  monthlyPrice,
  todayCharge,
  rateMonthly,
  handleSkip,
  handleUpgradeSubscription,
  handleResetUpgradeStatus,
  isLoading,
  isError,
  isSuccess,
  isPremium = false,
}: UpSellPromotionProps) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const redirectToMyAccount = () => {
    navigate("/myaccount", { replace: true });
  };

  return (
    <Layout
      data-qatag="Layout"
      qatag="billingHistory"
      useTemplating={true}
    >
      <Layout.Content data-qatag="Layout.Content">
        <Title data-qatag="upsell-promotion.title.container">
          <FormattedMessage
            data-qatag="UpsellPromotion.CardTitleMsg"
            id="UpsellPromotion.Title"
            defaultMessage="Add Internet Safety & Privacy <nl>Suite + Protect {devicesCount} Additional Devices</nl>"
            values={{
              devicesCount: additionalDevicesCount,
              nl: (chunks) => <TitleNewLine data-qatag="UpsellPromotion.CardTitleBreakLine">{chunks}</TitleNewLine>,
            }}
          />
        </Title>
        <Subtitle data-qatag="upsell-promotion.subtitle.container">
          <FormattedMessage
            data-qatag="UpsellPromotion.CardDescriptionMsg"
            id="UpsellPromotion.Description"
            defaultMessage={`
              We know that keeping all the Windows devices in your home updated
              and more secure is important to you. For an additional {price}
              add up-to {devicesCount} additional devices making sure they run as fast as
              possible while also protecting you from malware and common online threats.
            `}
            values={{
              devicesCount: additionalDevicesCount,
              price: `$${price}/mo`,
            }}
          />
        </Subtitle>
        <FeaturesContainer data-qatag="upsell-promotion.features.container">
          <Feature data-qatag="UpsellPromotion.FeatureMaliciousWebsite">
            <SuiteIcon
              data-qatag="UpsellPromotion.SuiteIconMaliciousWebsite"
              src={maliciousProtectionIcon}
              alt="Malicious Website"
            />
            <SuiteLabel data-qatag="UpsellPromotion.SuiteLabelMaliciousWebsite">
              <FormattedMessage
                data-qatag="UpsellPromotion.SuiteLabelMaliciousWebsiteMsg"
                id="UpsellPromotion.SuiteMaliciousWebsite"
                defaultMessage="Protection from Malicious Websites"
              />
            </SuiteLabel>
          </Feature>
          <Divider data-qatag="UpsellPromotion.SuiteDivider1" />
          <Feature data-qatag="UpsellPromotion.FeaturePrivacyProtection">
            <SuiteIcon
              data-qatag="UpsellPromotion.SuiteIconPrivacyProtection"
              src={privacyProtectionIcon}
              alt="Privacy Protection"
            />
            <SuiteLabel data-qatag="UpsellPromotion.SuiteLabelPrivacyProtection">
              <FormattedMessage
                data-qatag="UpsellPromotion.FeaturePrivacyProtectionMsg"
                id="UpsellPromotion.SuitePrivacyProtection"
                defaultMessage="Privacy Protection & Online Ad Blocking"
              />
            </SuiteLabel>
          </Feature>
          <Divider
            data-qatag="UpsellPromotion.SuiteDivider2"
            hide={true}
          />
          <Feature data-qatag="UpsellPromotion.FeatureAppOptimization">
            <SuiteIcon
              data-qatag="UpsellPromotion.SuiteIconAppOptimization"
              src={optimizationServicesIcon}
              alt="Optimization Services"
            />
            <SuiteLabel data-qatag="UpsellPromotion.SuiteLabelAppOptimization">
              <FormattedMessage
                data-qatag="UpsellPromotion.FeatureAppOptimizationMsg"
                id="UpsellPromotion.SuiteOptimizationServices"
                defaultMessage="Robust PC & App Optimization Services"
              />
            </SuiteLabel>
          </Feature>
          <Divider data-qatag="UpsellPromotion.SuiteDivider3" />
          <Feature data-qatag="UpsellPromotion.FeatureOnDemandAssistance">
            <SuiteIcon
              data-qatag="UpsellPromotion.SuiteIconOnDemandAssistance"
              src={onDemandIcon}
              alt="On-Demand Assistance"
            />
            <SuiteLabel data-qatag="UpsellPromotion.SuiteLabelOnDemandAssistance">
              <FormattedMessage
                data-qatag="UpsellPromotion.FeatureOnDemandAssistanceMsg"
                id="UpsellPromotion.SuiteOnDemandAssistance"
                defaultMessage="Unlimited On-Demand Expert Assistance"
              />
            </SuiteLabel>
          </Feature>
        </FeaturesContainer>

        <StyledButton
          ariaLabel="Yes, upgrade my account"
          buttonType={"button"}
          data-qatag="UpsellPromotion.Button"
          buttonStyle={AppButtonStyles.Green}
          onClick={handleUpgradeSubscription}
        >
          <FormattedMessage
            data-qatag="UpsellPromotion.ButtonMsg"
            id="UpsellPromotion.Button"
            defaultMessage="Yes, Upgrade My Account"
          />
        </StyledButton>
        <Box
          mt={3}
          data-qatag="UpsellPromotion.ButtonLink-container"
        >
          <ButtonLink
            ariaLabel="No thanks"
            buttonType={"button"}
            data-qatag="UpsellPromotion.ButtonLink"
            buttonStyle={AppButtonStyles.LinkGray}
            onClick={handleSkip}
          >
            <FormattedMessage
              data-qatag="UpsellPromotion.ButtonLinkMsg"
              id="UpsellPromotion.SkipButton"
              defaultMessage="No Thanks"
            />
          </ButtonLink>
        </Box>
        <CardFooter data-qatag="UpsellPromotion.CardFooter">
          <FormattedMessage
            data-qatag="UpsellPromotion.CardFooterMsg"
            id="UpsellPromotion.CardFooter"
            defaultMessage={`
            *By clicking YES above you are agreeing to a {todayCharge} pro-rated charge today and increasing your subscription fee to <b>{price} a month going forward.</b> Continued used of the above services is contingent upon your payment of the subscription.
            `}
            values={{
              b: (chunks) => <b data-qatag="bold">{chunks}</b>,
              todayCharge: <span data-qatag="todayCharge">${todayCharge}</span>,
              price: <span data-qatag="monthlyPrice">${monthlyPrice}</span>,
            }}
          />
        </CardFooter>
        <ContentFooter data-qatag="UpsellPromotion.ContentFooter">
          <FormattedMessage
            data-qatag="UpsellPromotion.ContentFooterMsg"
            id="Cart.Terms"
            defaultMessage={`
            Sales tax charge will be applied, based on resident state requirements. By submitting this order you agree to the <terms>Terms & Conditions</terms> and <privacy>Privacy Policy</privacy> of Driver Support and confirm that you have read and understood as a customer.
            You also confirm that you are signing up for a subscription product that will be billed in regular intervals at a rate of {price}/month. Continued use of the purchased product is contingent upon your payment of the subscription.
            Details about the price and frequency of the billing were shown in the shopping cart above.
          `}
            values={{
              price: `$${rateMonthly}`,
              privacy: (chunks) => (
                <PolicyLink
                  data-qatag="UpsellPromotion.ContentFooterPrivacyLink"
                  href="https://www.driversupport.com/privacypolicy/"
                  target="_blank"
                >
                  {chunks}
                </PolicyLink>
              ),
              terms: (chunks) => (
                <PolicyLink
                  data-qatag="UpsellPromotion.ContentFooterTermsLink"
                  href="https://www.driversupport.com/termsandconditions/"
                  target="_blank"
                >
                  {chunks}
                </PolicyLink>
              ),
            }}
          />
        </ContentFooter>
        {/* Loading Modal */}
        <Spinner
          data-qatag="loadingModal"
          isActive={isLoading}
          text="Loading..."
        />
        {/* Alert error */}
        <Alert
          data-qatag="alertError"
          icon={AlertIcon.Warning}
          title={formatMessage({
            id: "upsellPromotion.errorButton.title",
            defaultMessage: "Unable to Upgrade Subscription",
          })}
          text={formatMessage({
            id: "upsellPromotion.errorButton.subtitle",
            defaultMessage:
              "Please review your account information and try again, or contact customer support for further assistance.",
          })}
          buttonText={formatMessage({
            id: "upsellPromotion.errorButton.close",
            defaultMessage: "Close",
          })}
          approveHandler={handleResetUpgradeStatus}
          isOpen={isError}
          closeHandler={handleResetUpgradeStatus}
        />
        {/* Alert error */}
        <Alert
          data-qatag="alertError"
          icon={AlertIcon.Warning}
          title={formatMessage({
            id: "upsellPromotion.errorButton2.title",
            defaultMessage: "Oops! Something Went Wrong",
          })}
          text={formatMessage({
            id: "upsellPromotion.errorButton2.subtitle",
            defaultMessage:
              "Sorry, it looks like you're already a premium user and cannot upgrade any further. You will be redirected to the My Account page.",
          })}
          buttonText={formatMessage({
            id: "upsellPromotion.errorButton2.continue",
            defaultMessage: "Continue",
          })}
          approveHandler={redirectToMyAccount}
          closeHandler={redirectToMyAccount}
          isOpen={isPremium}
        />
        {/* Alert success */}
        <Alert
          data-qatag="alertSuccess"
          icon={AlertIcon.Success}
          title={formatMessage({
            id: "upsellPromotion.successButton.title",
            defaultMessage: "Congratulations! You've Upgraded to Premium Subscription",
          })}
          text={formatMessage({
            id: "upsellPromotion.successButton.subtitle",
            defaultMessage:
              "You now have access to exclusive features and benefits. We appreciate your continued support and hope you enjoy your upgraded experience with us.",
          })}
          buttonText={formatMessage({
            id: "upsellPromotion.successButton.close",
            defaultMessage: "Close",
          })}
          approveHandler={redirectToMyAccount}
          isOpen={isSuccess}
          closeHandler={redirectToMyAccount}
        />
      </Layout.Content>
    </Layout>
  );
}

export default UpSellPromotion;
