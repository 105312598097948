import { Layout } from "components/Layout/Layout";
import IUser from "model/user/IUser";
import { PersonalInformationSection } from "./Sections/PersonalInformation";
import { SubscriptionInfoSection } from "./Sections/SubscriptionInfo";
import { CancelSubscriptionSection } from "./Sections/CancelSubscription";
import { PaymentMethodSection } from "./Sections/PaymentMethod";
import { SubscriptionSummary, SubscriptionData } from "model/subscription/Subscription";
import { useMemo } from "react";
import { IPaymentMethod } from "model/billing/BillingSummaryModel";
import { Spinner, Alert, AlertIcon, AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { QueryErrorResponse } from "api/types";
import styled from "@mui/styled-engine";

const Container = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const PageTitle = styled(Typography)`
  align-self: flex-start;
  color: #535353;
  font-size: 1.3125rem;
  font-weight: 600;
  margin-top: 12px;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    margin-top: 24px;
  }
`;

const LogoutTypography = styled(Typography)`
  margin-top: 10px;
  font-weight: 200;
  font-size: 0.8331rem;
`;

export type MyAccountProps = {
  fetchingPaymentMethodError?: QueryErrorResponse;
  fetchingSubscriptionError?: QueryErrorResponse;
  isAdmin: boolean;
  isFetchingData: boolean;
  isPlanActive: boolean;
  isUpgrading: boolean;
  savedPaymentMethods?: IPaymentMethod[];
  subscription?: SubscriptionData;
  subscriptionSummary?: SubscriptionSummary;
  user?: IUser;
  resumeSubscription?: () => void;
  isLoading: boolean;
  resetResumingSubscription: () => void;
  isErrorResumingSubscription: boolean;
  handleRedirectToSelectPaymentMethod: () => void;
  isPaymentMethodDeclined: boolean;
  isRefund: boolean;
  offerUpgrade: boolean;
  onLogoutClicked: () => void;
  onCancelServiceClicked: () => void;
};

export function MyAccount({
  fetchingPaymentMethodError,
  fetchingSubscriptionError,
  isAdmin,
  isFetchingData,
  isPlanActive,
  isUpgrading,
  savedPaymentMethods,
  subscription,
  subscriptionSummary,
  user,
  resumeSubscription,
  isLoading,
  resetResumingSubscription,
  isErrorResumingSubscription,
  isPaymentMethodDeclined,
  isRefund,
  offerUpgrade,
  handleRedirectToSelectPaymentMethod,
  onLogoutClicked,
  onCancelServiceClicked,
}: MyAccountProps) {
  const { formatMessage } = useIntl();
  const defaultPaymentMethod = useMemo(() => {
    if (savedPaymentMethods && savedPaymentMethods.length) {
      return savedPaymentMethods.find((method) => method.isDefault);
    }
  }, [savedPaymentMethods]);

  if (isFetchingData) {
    return (
      <Spinner
        data-qatag="loadingModal"
        isActive={true}
        text={formatMessage({
          id: "loadingModal.title",
          defaultMessage: "Loading...",
        })}
      />
    );
  }

  const handleResumeSubscription = () => {
    if (resumeSubscription) resumeSubscription();
  };

  return (
    <Container data-qatag="myAccount.container">
      <Layout
        data-qatag="layout"
        qatag={"myAccount"}
      >
        <PageTitle
          variant="h2"
          data-qatag="myAccount.pageTitle"
        >
          <FormattedMessage
            data-qatag="myAccount.pageTitle.text"
            id="myAccount.pageTitle.text"
            defaultMessage="My Account"
          />
        </PageTitle>
        <PersonalInformationSection
          data-qatag="PersonalInformationSection"
          user={user}
        />
        {isAdmin ? (
          <>
            <SubscriptionInfoSection
              data-qatag="SubscriptionInfoSection"
              fetchingSubscriptionError={fetchingSubscriptionError}
              subscriptionData={subscription}
              subscriptionSummary={subscriptionSummary}
              resumeSubscription={handleResumeSubscription}
              isPaymentMethodDeclined={isPaymentMethodDeclined}
              isUpgrading={isUpgrading}
              handleRedirectToSelectPaymentMethod={handleRedirectToSelectPaymentMethod}
              isRefund={isRefund}
              offerUpgrade={offerUpgrade}
            />
            <PaymentMethodSection
              data-qatag="PaymentMethodSection"
              fetchingPaymentMethodError={fetchingPaymentMethodError}
              savedPaymentMethod={defaultPaymentMethod}
            />
            {isPlanActive ? (
              <CancelSubscriptionSection
                onClickHandler={onCancelServiceClicked}
                data-qatag="CancelSubscriptionSection"
              />
            ) : null}
          </>
        ) : null}
        {/* Loading alert */}
        {isLoading ? (
          <Spinner
            data-qatag="loadingModal"
            isActive={isLoading}
            text={formatMessage({
              id: "loadingModal.title",
              defaultMessage: "Resuming subscription takes up to 2 minutes...",
            })}
          />
        ) : null}
        {/* Alert Error resuming subscription CTA to select payment method  */}
        <Alert
          data-qatag="alertCTASelectPaymentMethod"
          modalMinHeight="388px"
          icon={AlertIcon.Warning}
          title={formatMessage({
            id: "resumeSubscription.errorCTAButton.title",
            defaultMessage: "Something went wrong",
          })}
          text={formatMessage({
            id: "resumeSubscription.errorCTAButton.subtitle",
            defaultMessage: "Please retry again.",
          })}
          buttonText={formatMessage({
            id: "resumeSubscription.errorCTAButton.continue",
            defaultMessage: "Close",
          })}
          approveHandler={resetResumingSubscription}
          isOpen={isErrorResumingSubscription}
          closeHandler={resetResumingSubscription}
        />
      </Layout>
      <AppButton
        ariaLabel="logout"
        buttonType="button"
        data-qatag="logoutButton"
        buttonStyle={AppButtonStyles.LinkGray}
        onClick={onLogoutClicked}
      >
        <LogoutTypography
          data-qatag="logoutLabel"
          variant="body2"
        >
          <FormattedMessage
            data-qatag="logoutMessage"
            id={`settings.view.footer.logout`}
            defaultMessage="Logout"
          />
        </LogoutTypography>
      </AppButton>
    </Container>
  );
}
