import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { RootState } from "app/redux/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { IRebootPayload } from "model/messaging/payloads/IRebootPayload";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";

export const rebootComputer = createAsyncThunk<
  void,
  IRebootPayload,
  {
    state: RootState;
  }
>("system/rebootComputer", async (payload, thunkApi) => {
  try {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DO_SYSTEM_REBOOT;
    message.Payload = payload;
    return srhub.SendAsync(message);
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to reboot PC : ${error}`);
  }
});

const initialState: IReducerState<unknown> = {
  data: {},
  status: {
    [rebootComputer.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const SystemSlice = createSlice({
  name: "System",
  initialState,
  reducers: {
    resetSystemState: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rebootComputer.pending, (state) => {
      state.status[rebootComputer.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(rebootComputer.rejected, (state, action) => {
      state.status[rebootComputer.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(rebootComputer.fulfilled, (state) => {
      state.status[rebootComputer.typePrefix] = ReducerStatus.Succeeded;
    });
  },
});

// actions
export const { resetSystemState } = SystemSlice.actions;

export default SystemSlice.reducer;
