import React from "react";
import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import IUser from "model/user/IUser";
import iconClose from "assets/img/icn-close.png";
import { MenuItemEntry } from "./NavBar";
import { EmailTooltip } from "./components/EmailTooltip";
import { MenuItemList } from "./components/MenuItemList";
import { FormattedMessage } from "react-intl";
import { Menu as MenuIcon } from "@mui/icons-material";
import styled from "@mui/styled-engine";

type TabletNavbarProps = {
  adminEmail: string;
  drawerState: boolean;
  dropdownItems: MenuItemEntry[];
  menuItems: MenuItemEntry[];
  setDrawerState: (drawerState: boolean) => void;
  showDropdown: boolean;
  setShowDropdown: (drawerState: boolean) => void;
  user: IUser | null;
};

// #region styled-components
const UserContainer = styled("div")`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 0.5, 3, 2.5)};
`;

const UserName = styled(Typography)`
  color: #535353;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 10px 0 0;
  margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  max-width: 20rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseIcon = styled("img")`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;

const MenuToggleButton = styled(Button)`
  background: #d9d9d9;
  color: #535a6a;
  height: 40px;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  text-transform: none;

  svg {
    height: 24px;
    margin-left: ${({ theme }) => theme.spacing(0.5)};
    width: 24px;
  }

  :hover {
    background: #d9d9d9;
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(0, 2)};
`;
// #endregion styled-components

export function TabletNavbar({
  adminEmail,
  drawerState,
  dropdownItems,
  menuItems,
  setDrawerState,
  showDropdown,
  setShowDropdown,
  user,
}: TabletNavbarProps) {
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  return (
    <Box data-qatag="navMenuWrapper">
      <MenuToggleButton
        data-qatag="toggleDrawerButton"
        onClick={toggleDrawer(true)}
      >
        <Typography
          data-qatag="menu"
          sx={{ textTransform: "none" }}
        >
          <FormattedMessage
            id="menu"
            data-qatag="menu"
            defaultMessage={"Menu"}
          />
        </Typography>
        <MenuIcon data-qatag="menu" />
      </MenuToggleButton>
      <Drawer
        data-qatag="navMenuDrawer"
        anchor="right"
        open={drawerState}
        onClose={toggleDrawer(false)}
      >
        <UserContainer
          data-qatag="userContainer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <Box
            data-qatag="containerName"
            display="flex"
            flexDirection="column"
          >
            <UserName
              data-qatag="userName"
              variant="body1"
            >
              {`${user?.firstName} ${user?.lastName}`}
            </UserName>
            <Box
              data-qatag="separator"
              height="4px"
            />
            <EmailTooltip
              data-qatag="EmailTooltip"
              fullEmail={adminEmail}
              maxLength={18}
              isTabletOrMobile={true}
            />
          </Box>
          <CloseIcon
            data-qatag="close"
            src={iconClose}
            alt="close"
            onClick={toggleDrawer(false)}
          />
        </UserContainer>
        <StyledDivider data-qatag="dividerComponent" />
        <div
          data-qatag="mobileWrapper"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{ width: "100%" }}
        >
          <MenuItemList
            data-qatag="MainMenuItems"
            items={menuItems}
            isMobileOrTablet={true}
            isDropdownItem={false}
          />
          <StyledDivider data-qatag="MenuItemDivider" />
          <MenuItemList
            data-qatag="DropdownMenuItems"
            items={dropdownItems}
            isMobileOrTablet={true}
            isDropdownItem={true}
          />
          <StyledDivider data-qatag="MenuItemDivider" />
        </div>
      </Drawer>
    </Box>
  );
}
