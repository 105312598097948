import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { allScansCompleted } from "features/scan/ScanSlice";
import { updateMachineIntelligence } from "features/MachinePicker/MachinePickerSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, store } from "app/redux/store";
import RESTGatewayAPI from "api/gatewayAPI";
import { IAgentInfo, IRegState } from "model/scan/AgentInfo";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { IServiceMessageGeneric } from "model/messaging/messages/IServiceMessageGeneric";
import { reportApplicationError, selectCurrentUser } from "session/SessionSlice";
import { IMachineIntelligence } from "model/messaging/messages/scanMessages";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { EnsureSignalRConnection, GetAgentInfo, GetAgentRegstate, GetIsAgentOnline } from "./AgentSliceHelpers";
import IAgentUserLicense from "model/user/IAgentUserLicense";
import { getIsWindows } from "utils/browserUtils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
declare const signupUrl: string;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
declare const redirectToSignup: boolean;
declare const productId: number;

export interface IAgentInitState {
  isOnline: boolean;
  machineIntelligence: IMachineIntelligence;
  agentInfo: IAgentInfo;
  uuid: string;
}

export const initAgentCommunication = createAsyncThunk<IAgentInitState, string, { state: RootState }>(
  "agent/initAgentCommunication",
  async (uuid, thunkApi) => {
    if (uuid == null) {
      return thunkApi.rejectWithValue("Unable to initialize communication with no UUID");
    }

    //reset state
    store.dispatch(resetAgentState);

    const returnAgentState = {
      agentInfo: {},
      isOnline: false,
      machineIntelligence: {},
      uuid: uuid,
    } as IAgentInitState;

    try {
      try {
        returnAgentState.isOnline = await GetIsAgentOnline(uuid as string);
      } catch (error) {
        return thunkApi.rejectWithValue(`Unable to fetch agent online status : ${error}`);
      }

      if (!returnAgentState.isOnline) {
        return thunkApi.rejectWithValue("agent not online");
      }

      try {
        const response = await GetAgentInfo(uuid as string);
        returnAgentState.agentInfo = response.Payload;
      } catch (error) {
        return thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
      }

      // request machine intelligence
      try {
        EnsureSignalRConnection(uuid as string);
        const hub = getSignalRHub();
        const srhub = hub.getInstance();

        const message: IServiceMessage = new ServiceMessage();
        message.MessageType = WSMessageType.GET_MACHINE_INTELLIGENCE;

        const response = (await srhub.SendAsync(message)) as IServiceMessageGeneric<IMachineIntelligence>;

        returnAgentState.machineIntelligence = response.Payload;
      } catch (error) {
        thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
      }
    } catch (e: unknown) {
      thunkApi.dispatch(reportApplicationError(e as Error));
      return thunkApi.rejectWithValue(
        "An error occurred while trying to initialize the agent: " + (e as Error).message
      );
    }
    return returnAgentState;
  }
);

export const getAgentDownloadUrl = createAsyncThunk<string, void, { state: RootState }>(
  "agent/getAgentDownloadUrl",
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const email = selectCurrentUser(state)?.email;

    try {
      const url = `/api/autoinstall/${email}/${productId}`;

      const response = await RESTGatewayAPI.get(url);
      const apiResponse: string = response.data;

      return apiResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch agent download url : ${error}`);
    }
  }
);

export const getLocalAgentState = createAsyncThunk<IRegState, void, { state: RootState }>(
  "agent/getLocalAgentState",
  async (_, thunkApi) => {
    try {
      const response = await GetAgentRegstate();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to get local agent state : ${error}`);
    }
  }
);

export const getLatestAgentVersion = createAsyncThunk<string, void, { state: RootState }>(
  "agent/getLatestAgentVersion",
  async (_, thunkApi) => {
    try {
      const url = `api/core/currentAgentVersion`;

      const response = await RESTGatewayAPI.get(url);
      const apiResponse: string = response.data;

      return apiResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch agent download url : ${error}`);
    }
  }
);

export const doStatusCheck = createAsyncThunk<IAgentInfo, void, { state: RootState }>(
  "agent/doStatusCheck",
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const uuid = selectCurrentUuid(state);
    if (uuid == null) {
      //thunkApi.rejectWithValue("Unable to resolve uuid from state.");
      throw new Error("Unable to resolve uuid from state");
    }

    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();

      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.DO_STATUS_CHECK;

      const response = await srhub.SendAsync(message);

      return response.Payload;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
    }
  }
);

export const doClientUpdate = createAsyncThunk<IAgentInfo, void, { state: RootState }>(
  "agent/doClientUpdate",
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const uuid = selectCurrentUuid(state);
    if (uuid == null) {
      //thunkApi.rejectWithValue("Unable to resolve uuid from state.");
      throw new Error("Unable to resolve uuid from state");
    }

    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();

      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.DO_CLIENT_UPDATE;

      const response = await srhub.SendAsync(message);

      return response.Payload;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch agent info: ${error}`);
    }
  }
);

export const getLicense = createAsyncThunk<IAgentUserLicense, void, { state: RootState }>(
  "agent/getLicense",
  async (_, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.GET_LICENSE;
    const response = await srhub.SendAsync(message);
    const data: IAgentUserLicense = response.Payload;
    return data;
  }
);

export const checkLicense = createAsyncThunk<void, void, { state: RootState }>(
  "agent/checkLicense",
  async (_, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.CHECK_LICENSE;
    const response = await srhub.SendAsync(message);
    const data = response.Payload;
    return data;
  }
);

export enum eConnectionStatus {
  // eslint-disable-next-line no-unused-vars
  Disconnected,
  // eslint-disable-next-line no-unused-vars
  Connecting,
  // eslint-disable-next-line no-unused-vars
  Connected,
}

interface IAgentState {
  downloadUrl: string;
  // agent info message that was sent by the currently connectd agent
  agentInfo: IAgentInfo | null;
  optimizationsFinalized: boolean;
  rebootRequired: boolean;
  agentOnlineRequestStatus: ReducerStatus;
  isAgentOnline: boolean;
  agentOnlineRequestError: string;
  connectionStatus: eConnectionStatus;
  machineIntelligence: IMachineIntelligence;
  hasAgentScanStartTime: boolean;
  //For now this is only ever set by GET_AGENT_INIT and the scan completion message.
  //If we decide we want to send users to the scan screen from the agent scanning while the UI is running we'll need to wire this up
  isAgentScanning: boolean;
  lastScanTime: string;
  isForceDisconnected: boolean;
  latestVersion: string;
  uuid: string | null;
  localAgentRegState: IRegState | null;
  agentLicense: IAgentUserLicense | null;
}

const EmptyAgentState: IAgentState = {
  downloadUrl: "",
  agentInfo: null,
  rebootRequired: false,
  optimizationsFinalized: false,
  agentOnlineRequestStatus: ReducerStatus.Idle,
  isAgentOnline: false,
  agentOnlineRequestError: "",
  connectionStatus: eConnectionStatus.Disconnected,
  machineIntelligence: {} as IMachineIntelligence,
  hasAgentScanStartTime: false,
  isAgentScanning: false,
  lastScanTime: "",
  isForceDisconnected: false,
  latestVersion: "",
  uuid: null,
  localAgentRegState: null,
  agentLicense: null,
};

const initialState: IReducerState<IAgentState> = {
  data: EmptyAgentState,
  status: {
    [getAgentDownloadUrl.typePrefix]: ReducerStatus.Idle,
    [getLatestAgentVersion.typePrefix]: ReducerStatus.Idle,
    [getLocalAgentState.typePrefix]: ReducerStatus.Idle,
    [doStatusCheck.typePrefix]: ReducerStatus.Idle,
    [getLicense.typePrefix]: ReducerStatus.Idle,
    [checkLicense.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    doForceDisconnect: (state) => {
      state.data.isForceDisconnected = true;
    },
    resetAgentState: (state) => {
      state.data = EmptyAgentState;
    },
    setLastScanTime: (state, action: PayloadAction<string>) => {
      state.data.lastScanTime = action.payload;
    },
    updateAgentLicense: (state, action: PayloadAction<IAgentUserLicense>) => {
      state.data.agentLicense = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initAgentCommunication.pending, (state) => {
      state.status[initAgentCommunication.typePrefix] = ReducerStatus.Loading;
      state.data.connectionStatus = eConnectionStatus.Connecting;
    });
    builder.addCase(initAgentCommunication.rejected, (state, action) => {
      state.status[initAgentCommunication.typePrefix] = ReducerStatus.Failed;
      state.data.connectionStatus = eConnectionStatus.Disconnected;
      // state.error = action.payload as string;
      //throw new Error(action.payload as string);
    });
    builder.addCase(initAgentCommunication.fulfilled, (state, action: PayloadAction<IAgentInitState>) => {
      state.status[initAgentCommunication.typePrefix] = ReducerStatus.Succeeded;
      state.data.connectionStatus = eConnectionStatus.Connected;
      state.data.agentInfo = action.payload.agentInfo;
      state.data.machineIntelligence = action.payload.machineIntelligence;
      state.data.isAgentOnline = action.payload.isOnline;
      state.data.hasAgentScanStartTime = action.payload.agentInfo.RegState.Current.AllScansInfo.LastStartTime != null;
      state.data.isAgentScanning = action.payload.agentInfo.RegState.Current.AllScansActive;
      state.data.lastScanTime = action.payload.agentInfo.RegState.Current.AllScansInfo.LastStartTime;
      state.data.rebootRequired = action.payload.agentInfo.RebootRequired;
      state.data.optimizationsFinalized =
        action?.payload?.agentInfo?.InstallState?.Calculated?.OptimizationsAreFinalized || false;
      state.data.uuid = action.payload.uuid;
    });

    builder.addCase(getAgentDownloadUrl.pending, (state) => {
      state.status[getAgentDownloadUrl.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(getAgentDownloadUrl.rejected, (state, action) => {
      state.status[getAgentDownloadUrl.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(getAgentDownloadUrl.fulfilled, (state, action) => {
      state.status[getAgentDownloadUrl.typePrefix] = ReducerStatus.Succeeded;
      state.data.downloadUrl = action.payload;
    });
    builder.addCase(allScansCompleted.fulfilled, (state) => {
      state.data.hasAgentScanStartTime = true;
      state.data.isAgentScanning = false;
    });
    builder.addCase(updateMachineIntelligence.fulfilled, (state, action) => {
      if (action.payload.Payload?.MachineIntelligence) {
        state.data.machineIntelligence = action.payload.Payload.MachineIntelligence;
      }
    });
    builder.addCase(getLatestAgentVersion.pending, (state) => {
      state.status[getLatestAgentVersion.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(getLatestAgentVersion.rejected, (state) => {
      state.status[getLatestAgentVersion.typePrefix] = ReducerStatus.Failed;
      state.data.latestVersion = "";
    });
    builder.addCase(getLatestAgentVersion.fulfilled, (state, action) => {
      state.status[getLatestAgentVersion.typePrefix] = ReducerStatus.Succeeded;
      state.data.latestVersion = action.payload;
    });

    builder.addCase(getLocalAgentState.pending, (state) => {
      state.status[getLocalAgentState.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(getLocalAgentState.rejected, (state) => {
      state.status[getLocalAgentState.typePrefix] = ReducerStatus.Failed;
    });
    builder.addCase(getLocalAgentState.fulfilled, (state, action) => {
      state.status[getLocalAgentState.typePrefix] = ReducerStatus.Succeeded;
      state.data.localAgentRegState = action.payload;
      state.data.lastScanTime = action.payload.Current.AllScansInfo.LastStartTime;
    });
    builder.addCase(getLicense.pending, (state) => {
      state.status[getLicense.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(getLicense.rejected, (state) => {
      state.status[getLicense.typePrefix] = ReducerStatus.Failed;
    });
    builder.addCase(getLicense.fulfilled, (state, action) => {
      state.status[getLicense.typePrefix] = ReducerStatus.Succeeded;
      state.data.agentLicense = action.payload;
    });
    builder.addCase(checkLicense.pending, (state) => {
      state.status[checkLicense.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(checkLicense.rejected, (state) => {
      state.status[checkLicense.typePrefix] = ReducerStatus.Failed;
    });
    builder.addCase(checkLicense.fulfilled, (state, action) => {
      state.status[checkLicense.typePrefix] = ReducerStatus.Succeeded;
    });
  },
});

export const { resetAgentState, doForceDisconnect, setLastScanTime, updateAgentLicense } = agentSlice.actions;

export default agentSlice.reducer;

export const selectDownloadUrl = (state: RootState) => state.agent.data.downloadUrl;

export const selectDownloadUrlStatus = (state: RootState) => state.agent.status[getAgentDownloadUrl.typePrefix];

export const selectAgentInitStatus = (state: RootState) => state.agent.status[initAgentCommunication.typePrefix];

export const selectHasAgentScanStartTime = (state: RootState) => state.agent.data.hasAgentScanStartTime;

export const selectIsAgentScanning = (state: RootState) => state.agent.data.isAgentScanning;

export const selectAgentInfo = (state: RootState) => state.agent.data.agentInfo;

export const selectAppVersion = (state: RootState) => state.agent.data?.agentInfo?.AppVersion ?? "";

export const selectLastScanTime = (state: RootState) => state.agent.data.lastScanTime;

export const selectSignalRHubConnectionStatus = (state: RootState) => state.agent.data.connectionStatus;

export const selectAgentOnlineStatus = (state: RootState) => state.agent.data.isAgentOnline;

export const selectAgentOnlineRequestStatus = (state: RootState) => state.agent.data.agentOnlineRequestStatus;

export const selectIsSignalRConnectionLocalAgent = (state: RootState) => {
  return state.application.data.currentUuid === state.agent.data.localAgentRegState?.UUID;
};

export const selectHasMachineIntelligence = (state: RootState) => {
  const mi = state.agent.data.machineIntelligence;
  return !(mi == null || (mi.IntelligenceType !== "None" && mi.MatchType !== "Model"));
};

export const selectMachineIntelligence = (state: RootState) => state.agent.data.machineIntelligence;

export const selectOptimizationsFinalized = (state: RootState) => state.agent.data.optimizationsFinalized;

export const selectRebootRequired = (state: RootState) => state.agent.data.rebootRequired;

export const selectIsForceDisconnected = (state: RootState) => state.agent.data.isForceDisconnected;

export const selectLatestVersion = (state: RootState) => state.agent.data.latestVersion;

export const selectGetLatestVersionStatus = (state: RootState) => state.agent.status[getLatestAgentVersion.typePrefix];

export const selectGetLocalAgentStateStatus = (state: RootState) => state.agent.status[getLocalAgentState.typePrefix];

export const selectHasLocalAgent = (state: RootState) => state.agent.data.localAgentRegState != null;

export const selectLocalAgentUuid = (state: RootState) => state.agent.data.localAgentRegState?.UUID;

export const selectLocalAgentHasValidLicense = (state: RootState) => {
  const licenseState = state.agent.data.localAgentRegState?.SvcLicenseState;
  return licenseState === "ExistingActivation" || licenseState === "ActivationCreated";
};

export const selectIsLocalAgentUnlicensed = (state: RootState) => {
  const licenseState = state.agent.data.localAgentRegState?.SvcLicenseState;
  return licenseState === "";
};

export const selectIsLocalPCEligibleForLicensing = (state: RootState) => {
  //if the local PC either does not have an agent or the agent is unlicensed and it is a windows PC
  //it is eligible to be added
  return (!selectHasLocalAgent(state) || selectIsLocalAgentUnlicensed(state)) && getIsWindows();
};

export const selectAgentLicense = (state: RootState) => state.agent.data.agentLicense;

export const selectGetLicenseStatus = (state: RootState) => state.agent.status[getLicense.typePrefix];

export const selectCheckLicenseStatus = (state: RootState) => state.agent.status[checkLicense.typePrefix];

export const selectDoStatusCheckStatus = (state: RootState) => state.agent.status[doStatusCheck.typePrefix];
