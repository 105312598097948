import { ILicenseData } from "model/license/ILicenseData";
import IProvisionedLicense from "model/license/IProvisionedLicense";
import { SubscriptionStatusChangeType } from "model/license/ISubscriptionStatusChange";
import IUser from "model/user/IUser";

export function GetTotalLicenseCount(licenseData: ILicenseData | undefined) {
  if (!licenseData) return null;
  return (
    licenseData.provisionedLicenses.reduce((val: number, obj: IProvisionedLicense) => val + obj.licenseCount, 0) ?? 0
  );
}

export function GetIsLicenseExpired(licenseData: ILicenseData | undefined) {
  if (!licenseData) return false;
  const hasDunningLicenseExpiration = licenseData.provisionedLicenses?.find(
    ({ enabled, dunningLicenseExpiration }) => enabled && dunningLicenseExpiration
  );
  const dateExpired = hasDunningLicenseExpiration
    ? new Date(hasDunningLicenseExpiration.dunningLicenseExpiration).getTime()
    : null;

  const resumes = licenseData.subscriptionStatusChanges.filter(
    (x) => x.statusChangeType === SubscriptionStatusChangeType.ResumeSubscription
  );
  const lastResume = resumes.sort((a, b) => {
    if (a.timestamp < b.timestamp) return -1;
    if (a.timestamp > b.timestamp) return 1;
    return 0;
  })[0];
  const dateResumed = lastResume ? new Date(lastResume.timestamp).getTime() : null;

  if (dateResumed && dateExpired && dateResumed > dateExpired) return false;

  return dateExpired ? new Date().getTime() > dateExpired : false;
}

export function GetMostRecentSubscriptionStatusChange(licenseData: ILicenseData | undefined) {
  //this array is pre sorted when we get the data so just return the first record
  return licenseData?.subscriptionStatusChanges?.[0];
}

export function GetIsPaymentDeclined(licenseData: ILicenseData | undefined) {
  if (!licenseData) return false;
  const mostRecentChange = GetMostRecentSubscriptionStatusChange(licenseData);
  if (mostRecentChange) {
    return mostRecentChange?.isCancellation && mostRecentChange?.sourceType === "PaymentDeclined";
  } else {
    return false;
  }
}

export function GetIsRefunded(licenseData: ILicenseData | undefined) {
  if (!licenseData) return false;
  const mostRecentChange = GetMostRecentSubscriptionStatusChange(licenseData);
  if (mostRecentChange) {
    return mostRecentChange?.isCancellation && mostRecentChange?.sourceType === "Refund";
  } else {
    return false;
  }
}

export function GetUserHasAllocatedLicenses(
  licenseData: ILicenseData | undefined,
  currentUser: IUser | undefined | null
) {
  if (!licenseData || !currentUser) return false;
  return licenseData.allocatedLicenses.some((license) => license.enabled && license.userID === currentUser?.id);
}
