import styled from "@mui/styled-engine";
import { FormattedMessage } from "react-intl";

const StyledTh = styled("th")`
  padding-left: 24px;

  &:last-of-type {
    padding-left: 0;
    padding-right: 24px;
    text-align: right;
  }
`;

const StyledTHead = styled("thead")`
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

export function HeaderRow() {
  return (
    <StyledTHead data-qatag="familyMemberTableThead">
      <tr data-qatag="familyMemberTableTr">
        <th data-qatag="familyMemberTableTh">
          <FormattedMessage
            id="familyMemberTableMemberMessage"
            data-qatag="familyMemberTableMemberMessage"
            defaultMessage="Member"
          />
        </th>
        <StyledTh data-qatag="familyMemberTableTh">
          <FormattedMessage
            id="familyMemberTableDeviceMessage"
            data-qatag="familyMemberTableDeviceMessage"
            defaultMessage="Device"
          />
        </StyledTh>
        <StyledTh data-qatag="familyMemberTableTh">
          <FormattedMessage
            id="family.member.table.status"
            data-qatag="familyMemberTableStatusMessage"
            defaultMessage="Status"
          />
        </StyledTh>
        <th data-qatag="familyMemberTableTh">
          <FormattedMessage
            data-qatag="familyMemberTableRemoveMessage"
            id="family.member.table.remove"
            defaultMessage="Remove"
          />
        </th>
        <StyledTh data-qatag="familyMemberTableTh">
          <FormattedMessage
            data-qatag="familyMemberTableActionsMessage"
            id={`family.member.table.actions`}
            defaultMessage="Actions"
          />
        </StyledTh>
      </tr>
    </StyledTHead>
  );
}
