import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import {
  fetchUserSettings,
  selectSettings,
  selectSettingsFetchStatus,
  updateUserSetting,
} from "features/settings/SettingsSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DnsProtection, DnsProtectionProps } from "./DnsProtection";
import { ReducerStatus } from "model/IReducerState";
import { Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import { isEdge } from "react-device-detect";

declare const chromePageboostUrl: string;
declare const edgePageboostUrl: string;

export const DnsProtectionContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const settingsStatus = useSelector(selectSettingsFetchStatus);
  const { formatMessage } = useIntl();
  const [enablePageboostSwitch, setEnablePageboostSwitch] = useState<boolean>(true);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const protectionEnabled = settings?.find((x) => x.SettingType === UserSettingTypes.EnableDnsProtector)?.SettingValue;

  const pageboostEnabled = settings?.find(
    (x) => x.SettingType === UserSettingTypes.ForceInstallChromeExtension
  )?.SettingValue;

  const props: DnsProtectionProps = {
    settings: settings,
    enablePageboostSwitch: enablePageboostSwitch,
    onDnsSwitchClick: async (isOn) => {
      dispatch(
        updateUserSetting({
          UpdatedSettings: [
            {
              SettingType: UserSettingTypes.EnableDnsProtector,
              SettingTypeName: UserSettingTypes[UserSettingTypes.EnableDnsProtector],
              NewSettingValue: isOn,
              CurrentSettingValue: protectionEnabled,
            },
          ],
        })
      );
    },
    isModalOpen,
    closeModal,
    onPageBoostSwitchClick: async (isOn) => {
      setEnablePageboostSwitch(false);
      setTimeout(() => {
        setEnablePageboostSwitch(true);
      }, 2000);
      dispatch(
        updateUserSetting({
          UpdatedSettings: [
            {
              SettingType: UserSettingTypes.EnableChromeExtension,
              SettingTypeName: UserSettingTypes[UserSettingTypes.EnableChromeExtension],
              NewSettingValue: isOn,
              CurrentSettingValue: pageboostEnabled,
            },
            {
              SettingType: UserSettingTypes.ForceInstallChromeExtension,
              SettingTypeName: UserSettingTypes[UserSettingTypes.ForceInstallChromeExtension],
              NewSettingValue: isOn,
              CurrentSettingValue: pageboostEnabled,
            },
            {
              SettingType: UserSettingTypes.EnableEdgeExtension,
              SettingTypeName: UserSettingTypes[UserSettingTypes.EnableEdgeExtension],
              NewSettingValue: isOn,
              CurrentSettingValue: pageboostEnabled,
            },
            {
              SettingType: UserSettingTypes.ForceInstallEdgeExtension,
              SettingTypeName: UserSettingTypes[UserSettingTypes.ForceInstallEdgeExtension],
              NewSettingValue: isOn,
              CurrentSettingValue: pageboostEnabled,
            },
          ],
        })
      );
      openModal();
    },
    onWebStoreClick: () => {
      const url = isEdge ? edgePageboostUrl : chromePageboostUrl;
      window.open(url, "_blank");
    },
  };

  if (settingsStatus !== ReducerStatus.Succeeded) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive
        text={formatMessage({
          id: `agentCommunicationLoader`,
          defaultMessage: "Loading...",
        })}
      />
    );
  }
  return (
    <DnsProtection
      data-qatag="DnsProtection"
      {...props}
    />
  );
};
