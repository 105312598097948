import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setCurrentUuid } from "app/redux/applicationSlice";
import { store } from "app/redux/store";

export function InitializeSentry(dsn: string, release: string, environment: string, traceSampleRate: number) {
  Sentry.init({
    dsn: dsn,
    release: release,
    integrations: [new Integrations.BrowserTracing()],
    environment: environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: traceSampleRate,
  });
}

//for now this is just for UUID but we can extend this with any QS params we want to persist in store
export function InitializePersistQsParams() {
  PersistInitialRoute();
  const urlParams = GetLowerCaseParams(new URLSearchParams(window.location.search));

  const uuid = urlParams.get("uuid") || "";
  if (uuid !== "") {
    store.dispatch(setCurrentUuid(uuid));
  }

  //save this since going through auth clears querystring
  const deviceId = urlParams.get("latestdriverguid") || "";
  if (deviceId !== "") {
    localStorage.setItem("latestdriverguid", deviceId);
  }

  const fromCart = urlParams.get("fromcart") === "true";
  if (fromCart) {
    localStorage.setItem("fromcart", "true");
  }
}

function PersistInitialRoute() {
  const pathName = window.location.pathname;
  if (pathName !== "/callback") {
    localStorage.setItem("initialRoute", pathName);
  }
}

function GetLowerCaseParams(params: URLSearchParams) {
  const lowerCaseParams = new URLSearchParams();
  for (const [name, value] of params) {
    lowerCaseParams.append(name.toLowerCase(), value);
  }
  return lowerCaseParams;
}
