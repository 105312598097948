import { Icon, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Circle as CircleIcon } from "@mui/icons-material";
import pendingIcon from "assets/img/family/status-pending-w-resend-stamp.svg";
import styled from "@mui/styled-engine";

const StatusCellContainer = styled("div")`
  align-items: center;
  display: flex;
`;

const StyledCircleIcon = styled(CircleIcon)`
  padding-bottom: 8px;
  margin-right: 8px;
  max-width: 16px;
`;

const OnlineCircle = styled(StyledCircleIcon)`
  color: #2ecc5f;
`;
const OfflineCircle = styled(StyledCircleIcon)`
  color: #cc4040;
`;
const StyledImg = styled("img")`
  margin-right: 12px;
`;
const StyledType = styled(Typography)`
  font-size: 1rem;
`;
export interface IStatusInfoProps {
  isLicenseActivated: boolean;
  isOnline: boolean | undefined;
}

export function StatusInfo({ isLicenseActivated, isOnline }: IStatusInfoProps) {
  if (isLicenseActivated) {
    return (
      <StatusCellContainer data-qatag="familyMemberTableRowOnlineIndicator">
        <Icon data-qatag="familyMemberTableRowOnlineIndicator.Icon">
          {isOnline ? (
            <OnlineCircle data-qatag="familyMemberTableRowOnlineIndicator.Online" />
          ) : (
            <OfflineCircle data-qatag="familyMemberTableRowOnlineIndicator.Offline" />
          )}
        </Icon>
        <StyledType data-qatag="familyMemberTableRowOnlineIndicator.Typography">
          <FormattedMessage
            id="familyMemberTableRow.statusText"
            data-qatag="familyMemberTableRow.statusText"
            defaultMessage="{status}"
            values={{ status: isOnline ? "Online" : "Offline" }}
          />
        </StyledType>
      </StatusCellContainer>
    );
  }

  return (
    <StatusCellContainer data-qatag="familyMemberTableRowPendingInstall">
      <StyledImg
        data-qatag="familyMemberTableRowStatusImage"
        src={pendingIcon}
        alt=""
      />
      <StyledType data-qatag="familyMemberTableRowOnlineIndicator.Typography">
        <FormattedMessage
          id="familyMemberTableRow.pendingInstallText"
          data-qatag="familyMemberTableRow.pendingInstallText"
          defaultMessage="Pending Install"
        />
      </StyledType>
    </StatusCellContainer>
  );
}
