import deleteIcon from "assets/icon/icn-remove-user.svg";
import adminIcon from "assets/icon/icn-admin.svg";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import styled from "@mui/styled-engine";

const RemovalMessageContainer = styled(Typography)`
  color: #757e95;
  display: block;
  font-style: italic;
`;

const AdminButton = styled("button")`
  background: transparent;
  border: none;
`;

const DeleteButton = styled("button")`
  background: transparent;
  border: none;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

export type RemoveButtonProps = {
  isAdmin: boolean;
  handleDelete: () => void;
  isLicenseActivated: boolean;
  isLastAdmin: boolean;
};

export function RemoveButton({ isAdmin, handleDelete, isLicenseActivated, isLastAdmin }: RemoveButtonProps) {
  const Button = isAdmin && isLastAdmin ? AdminButton : DeleteButton;
  return (
    <Button
      data-qatag="familyMemberTableRowDeleteButton"
      onClick={() => handleDelete()}
    >
      <img
        data-qatag="familyMemberTableRowDeleteImage"
        src={isAdmin && isLastAdmin ? adminIcon : deleteIcon}
        alt="adminOrDeleteIcon"
      />
      <RemovalMessageContainer
        data-qatag="familyMemberTableRowSubText"
        variant="body1"
      >
        {GetRemovalMessage(isAdmin, isLastAdmin, isLicenseActivated)}
      </RemovalMessageContainer>
    </Button>
  );
}

function GetRemovalMessage(isAdmin: boolean, isLastAdmin: boolean, isLicenseActivated: boolean): JSX.Element {
  if (isAdmin && isLastAdmin) {
    return (
      <FormattedMessage
        id="familyMemberTableRowAdminMessage"
        data-qatag="familyMemberTableRowAdminMessage"
        defaultMessage="Admin"
      />
    );
  }
  if (isAdmin && isLicenseActivated) {
    return (
      <FormattedMessage
        id="familyMemberTableRowRemoveMessage"
        data-qatag="familyMemberTableRowRemoveMessage"
        defaultMessage="Uninstall"
      />
    );
  }
  return (
    <FormattedMessage
      id="familyMemberTableRowRemoveMessage"
      data-qatag="familyMemberTableRowRemoveMessage"
      defaultMessage="Remove"
    />
  );
}
