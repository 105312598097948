import { styled, TableCell, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { isFeatureIncludedInLicense } from "core/Helpers";
import { humanFileSize } from "utils/formatFileSize";

import { serviceItemsMap, Services, ServiceSummaryItem } from "../SummaryContainer";

type ServiceItemRowProps = {
  featureFlags: string;
  isLicenseExpired: boolean;
  serviceSummaryItem: ServiceSummaryItem;
};

// #region styled-components
const NameTableCell = styled(TableCell)`
  padding-left: 0;
  vertical-align: middle;
`;

const ValueTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.warning.main};
  font-weight: 600;
  padding-right: 0;
  vertical-align: middle;

  &.is-licensed {
    color: ${({ theme }) => theme.palette.success.main};
  }
`;

const StyledTableCellIcon = styled("img")`
  margin-right: 0.5rem;
  vertical-align: middle;
  width: 24px;
`;
// #endregion styled-components

export function ServiceItemRow({ featureFlags, isLicenseExpired, serviceSummaryItem }: ServiceItemRowProps) {
  const { formatMessage } = useIntl();
  const { icon, id: serviceId, name, value } = serviceSummaryItem;
  const isLicensed =
    !isLicenseExpired && isFeatureIncludedInLicense(serviceItemsMap[serviceId as Services], featureFlags);
  const [valueMessage, setValueMessage] = useState<string>("");

  useEffect(() => {
    const licensedMessage = formatMessage({ id: "summary.serviceLicensed", defaultMessage: "Licensed" });
    const notLicensedMessage = formatMessage({ id: "summary.serviceNotLicensed", defaultMessage: "Not Licensed" });

    if (!isLicensed) {
      setValueMessage(notLicensedMessage);

      return;
    }

    switch (serviceId) {
      case Services.AppsOptimized:
        setValueMessage(
          value
            ? formatMessage(
                {
                  id: "summary.appsOptimizedLicensed",
                  defaultMessage: "{count, number}",
                },
                { count: value || 0 }
              )
            : licensedMessage
        );

        break;
      case Services.DeceptiveApps:
        setValueMessage(
          value
            ? formatMessage(
                {
                  id: "summary.deceptiveAppsBlockedLicensed",
                  defaultMessage: "{count, number}",
                },
                { count: value || 0 }
              )
            : licensedMessage
        );

        break;
      case Services.DeviceDrivers:
        setValueMessage(
          value
            ? formatMessage(
                {
                  id: "summary.driversLicensed",
                  defaultMessage: "{count, number}",
                },
                { count: value || 0 }
              )
            : licensedMessage
        );

        break;
      case Services.FilesCleanedSize:
        setValueMessage(
          value
            ? formatMessage(
                {
                  id: "summary.filesCleanedSizeLicensed",
                  defaultMessage: "{count}",
                },
                { count: humanFileSize(value || 0) }
              )
            : licensedMessage
        );

        break;
      case Services.TimesOptimized:
        setValueMessage(
          value
            ? formatMessage(
                {
                  id: "summary.timesOptimizedLicensed",
                  defaultMessage: "{count, number}",
                },
                { count: value || 0 }
              )
            : licensedMessage
        );

        break;
      case Services.WebProtection:
      case Services.WindowsOptimizations:
        setValueMessage(licensedMessage);

        break;
      default:
        setValueMessage(value ? value.toString() : "");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags, isLicensed, serviceId, value]);

  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <TableRow
      data-qatag={`premiumServiceTable.cardContent.tableRow.${serviceId}`}
      key={serviceId}
    >
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
      <NameTableCell data-qatag={`premiumServiceTable.cardContent.tableCell.${serviceId}.name`}>
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <StyledTableCellIcon
          data-qatag={`premiumServiceTable.cardContent.${serviceId}.icon`}
          alt=""
          src={icon}
        />
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <Typography
          data-qatag={`premiumServiceTable.cardContent.${serviceId}`}
          component={"span"}
          sx={{ fontWeight: 600 }}
          variant="body1"
        >
          {name}
        </Typography>
      </NameTableCell>
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
      <ValueTableCell
        data-qatag={`premiumServiceTable.cardContent.tableCell.${serviceId}.value`}
        align="right"
        className={isLicensed ? "is-licensed" : ""}
      >
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <Typography
          data-qatag={`premiumServiceTable.cardContent.${serviceId}.value`}
          sx={{ fontWeight: 600 }}
          variant="body1"
        >
          {valueMessage}
        </Typography>
      </ValueTableCell>
    </TableRow>
  );
}
