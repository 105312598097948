import { ArcElement, Chart as ChartJS } from "chart.js";

/**
 * This "plugin" exists merely to get the activated licenses segment (in the
 * `/summary` view) to overlap the available licenses segment. Only the first
 * segment overlaps. This could be extended to overlap all segments, but that
 * would require more complex calculations.
 *
 * @module chartJsOverlappingSegment
 */
const chartJsOverlappingSegment = {
  id: "overlapping-segments",
  afterDatasetsDraw: (chart: ChartJS<"doughnut", number[], unknown> /*, args, options */) => {
    // eslint-disable-next-line no-unused-vars
    const { ctx, data } = chart;

    // If there are no licensed PCs or available seats, do not draw the overlapping circles
    if (data.datasets[0].data[0] < 1 || data.datasets[0].data[1] === 0) {
      return;
    }

    const dataPoint = chart.getDatasetMeta(0).data[0] as ArcElement;

    if (!dataPoint) {
      return;
    }

    const { endAngle, innerRadius, outerRadius, x, y } = dataPoint;
    const radius = (outerRadius - innerRadius) / 2;
    const angle = Math.PI / 180;
    const coordinates = [];

    // Set coordinates for the overlapping circles
    for (let i = -0.04; i <= 0.03; i += 0.01) {
      const xCoordinate = (innerRadius + radius) * Math.cos(endAngle + Math.PI + i) * -1;
      const yCoordinate = (innerRadius + radius) * Math.sin(endAngle + i);

      coordinates.push({ x: xCoordinate, y: yCoordinate });
    }

    ctx.save();
    ctx.fillStyle = "#2e7d32";
    ctx.translate(x, y);
    ctx.beginPath();

    // Draw the overlapping circles
    coordinates.forEach(({ x, y }) => {
      ctx.arc(x, y, radius, 0, angle * 360, false);
    });

    ctx.fill();
    ctx.restore();
  },
};

export default chartJsOverlappingSegment;
