import { styled } from "@mui/material";
import warningIcon from "assets/img/icn-category-disabled-warning.svg";
import successIcon from "assets/img/icn-sucess-for-modals.svg";
import { AppButton, AppButtonStyles, Modal } from "dsoneweb.designsystem";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

export type DnsProtectionModalProps = {
  isDisabled: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const StyleModal = styled(Modal)`
  width: 31.25rem;
  height: 23rem;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
`;

const BaseText = styled("div")`
  color: #535353;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0.01rem;
  text-align: center;
`;

const Icon = styled("img")`
  height: 3.125rem;
  width: 3.125rem;
`;

const Description = styled(BaseText)``;

const Note = styled(BaseText)`
  width: 19.25rem;
`;

const Title = styled(BaseText)`
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.875rem;
`;

const StyledButton = styled(AppButton)`
  width: 17.5rem;
  height: 2.75rem;
  margin: 1.375rem 0;
`;

function DnsProtectionModalIcon({ isDisabled }: { isDisabled: boolean }) {
  return isDisabled ? (
    <Icon
      data-qatag="DnsProtectionModalIcon"
      src={warningIcon}
    />
  ) : (
    <Icon
      data-qatag="DnsProtectionModalIcon"
      src={successIcon}
    />
  );
}

export function DnsProtectionModal({
  isDisabled = false,
  isOpen = false,
  onClose = () => {},
}: DnsProtectionModalProps) {
  const { title, description } = useMemo(() => {
    if (isDisabled) {
      return {
        description: (
          <FormattedMessage
            data-qatag="pageBoostModal.descriptionDisabledAndReadyMsg"
            id="pageBoostModal.descriptionDisabledAndReadyMsg"
            defaultMessage="You are back to default browser ads & trackers."
          />
        ),
        title: (
          <FormattedMessage
            data-qatag="pageBoostModal.titleDisabledAndReadyMsg"
            id="pageBoostModal.titleDisabledAndReadyMsg"
            defaultMessage="Ads & Trackers blocking disabled.*"
          />
        ),
      };
    } else {
      return {
        description: (
          <FormattedMessage
            data-qatag="pageBoostModal.descriptionEnabledAndReadyMsg"
            id="pageBoostModal.descriptionEnabledAndReadyMsg"
            defaultMessage="Enjoy a private and ad-free browsing experience."
          />
        ),
        title: (
          <FormattedMessage
            data-qatag="pageBoostModal.titleEnabledAndReadyMsg"
            id="pageBoostModal.titleEnabledAndReadyMsg"
            defaultMessage="Ads & Trackers blocking activated.*"
          />
        ),
      };
    }
  }, [isDisabled]);

  return (
    <StyleModal
      data-qatag="DnsProtectionModal"
      open={isOpen}
      onClose={onClose}
    >
      <Container data-qatag="DnsProtectionModalContainer">
        <DnsProtectionModalIcon
          data-qatag="DnsProtectionModalIconComponent"
          isDisabled={isDisabled}
        />

        <Title data-qatag="DnsProtectionModalTitle">{title}</Title>

        <Description data-qatag="DnsProtectionModalDescription">{description}</Description>

        <StyledButton
          ariaLabel="I Understand"
          buttonType="button"
          data-qatag="pageBoostModal.button"
          buttonStyle={AppButtonStyles.Green}
          onClick={onClose}
        >
          <FormattedMessage
            data-qatag="pageBoostModal.buttonMsg"
            id="pageBoostModal.buttonMsg"
            defaultMessage="I Understand"
          />
        </StyledButton>

        <Note data-qatag="pageBoostModal.notes">
          <FormattedMessage
            data-qatag="pageBoostModal.notesMsg"
            id="pageBoostModal.notesMsg"
            defaultMessage="* Takes up to 15 min to take effect. Reboot for immediate effect."
          />
        </Note>
      </Container>
    </StyleModal>
  );
}
