import { Divider } from "@mui/material";
import { MenuItem } from "./MenuItem";
import { CustomDivider, MenuItemDescriptor, MenuItemEntry } from "../NavBar";

export type MenuItemListProps = {
  items: MenuItemEntry[];
  isMobileOrTablet: boolean;
  isDropdownItem: boolean;
};

export function MenuItemList({ items, isDropdownItem, isMobileOrTablet }: MenuItemListProps) {
  return (
    <>
      {items.map((item, i) => {
        const isDivider = item === "divider";

        if (isDivider && isMobileOrTablet) {
          return null;
        } else if (isDivider && isDropdownItem) {
          return (
            <CustomDivider
              data-qatag="dropdownDivider"
              key={i}
            />
          );
        } else if (isDivider) {
          return (
            <Divider
              data-qatag="menuItemDivider"
              key="divider"
              orientation="vertical"
              style={{
                marginRight: "12px",
              }}
            />
          );
        } else {
          const desc = item as MenuItemDescriptor;
          const menuItemKey = `menuItem${desc.to}${desc.label}`;

          return (
            <MenuItem
              data-qatag="menuItem"
              fullWidth={true}
              isDropdownItem={isDropdownItem}
              isTabletOrSmaller={isMobileOrTablet}
              indicatorLocation={isMobileOrTablet ? "left" : "bottom"}
              key={menuItemKey}
              descriptor={desc}
            />
          );
        }
      })}
    </>
  );
}
