import { Box, Container, Icon, Typography } from "@mui/material";
import styled from "@mui/styled-engine";

export const CenteredHeaderText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    height: 72px;
  }
`;
export const SubText = styled(Typography)`
  font-size: 1rem;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 70px;
  }
`;

export const SectionContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-of-type {
    border-bottom: none;
    border-top: none;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 32px;
    width: 50%;

    &:first-of-type {
      border-bottom: none;
      border-top: none;
    }
  }
`;

export const NameAndEmailBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px auto;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 200px;
  }
`;
export const Flexbox = styled(Box)`
  align-items: center;
  display: flex;
`;

export const NameText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
`;
export const EmailText = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
`;
export const BaseIconContainer = styled(Icon)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const BlueIconContainer = styled(BaseIconContainer)`
  color: #007dba;
  height: 24px;
  margin: 8px;
  width: 24px;
`;
