import { useState } from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import Modal, { MetricProps } from "../Modal/Modal";
import headerIcon from "assets/icon/icn-gaming-default.svg";
import styled from "@mui/styled-engine";

export type SoftwareBoxProps = {
  /* Software title */
  title: string;
  /* Software description */
  description?: string;
  /* Image to be displayed */
  image: string | null;
  /* Metrics */
  metrics: MetricProps[];
};

//#region component styles

const Container = styled(Box)`
  width: 280px;
  height: 272px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  margin: auto;
`;

const Content = styled(Box)`
  margin: 32px auto 40px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledIcon = styled("img")`
  width: 64px;
  height: 64px;
`;

const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const CustomButton = styled(AppButton)`
  width: 180px;
  outline: none;
  height: 44px;
`;

const ButtonText = styled("span")`
  line-height: 1;
  text-align: center;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0.16px;
  color: #ffffff;
`;

const TitleContainer = styled(Box)`
  width: 100%;
  text-align: center;
  margin-top: 24px;
  text-align: center;
  font: normal normal 500 18px Roboto;
  color: #535a6a;
`;

const WireFrameContainer = styled(Box)`
  width: 100%;
  text-align: center;
  margin-top: 32px;
`;

//#endregion component styles

function SoftwareBox({ title, description = " ", metrics, image }: SoftwareBoxProps) {
  // Specif scenario when the Download URI image is an ftp
  const ftpRegex = /^ftp:\/\//;

  const [openModal, setOpenModal] = useState(false);

  return (
    <Container data-qatag="container">
      <Content data-qatag="content">
        <WireFrameContainer
          data-qatag="wireFrameContainer"
          width={1}
          textAlign="center"
          mt={4}
        >
          <StyledIcon
            data-qatag="icon"
            src={image && !ftpRegex.test(image) ? image : headerIcon}
            alt="wire-frame"
          />
        </WireFrameContainer>

        <TitleContainer data-qatag="titleContainer">{title}</TitleContainer>
        <ButtonContainer data-qatag="customButtonBox">
          <CustomButton
            ariaLabel="view details"
            data-qatag="customButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={() => {
              setOpenModal((prevState) => !prevState);
            }}
            buttonType="button"
          >
            <ButtonText data-qatag="buttonText">
              <FormattedMessage
                data-qatag="FormattedMessage"
                id="software.box.view.details"
                defaultMessage="View Details"
              />
            </ButtonText>
          </CustomButton>
        </ButtonContainer>
      </Content>
      <Modal
        data-qatag="modalInformation"
        title={title}
        image={image && !ftpRegex.test(image) ? image : headerIcon}
        subtitle={description}
        metrics={metrics}
        handleOnClose={() => {
          setOpenModal((prevState) => !prevState);
        }}
        isOpen={openModal}
      />
    </Container>
  );
}

export default SoftwareBox;
