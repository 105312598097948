import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";

declare const gatewayUrl: string;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
declare const productId: number;

const RESTGatewayAPI = () => {
  const instance = axios.create({
    baseURL: gatewayUrl,
  });

  axiosRetry(instance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: isNetworkOrIdempotentRequestError,
  });
  return instance;
};

export default RESTGatewayAPI();
