/* eslint-disable no-unused-vars */
export enum SubscriptionStatusChangeSourceType {
  None = "None",
  Portal = "Portal",
  Refund = "Refund",
  PaymentDeclined = "PaymentDeclined",
  DAM = "DAM",
  SupportAgent = "SupportAgent",
}

export enum SubscriptionStatusChangeType {
  Cancellation = "Cancellation",
  NewProduct = "NewProduct",
  OwnerTransfer = "OwnerTransfer",
  RemoveProduct = "RemoveProduct",
  Renewal = "Renewal",
  UpdateProduct = "UpdateProduct",
  TermsAndConditions = "TermsAndConditions",
  SuspendSubscription = "SuspendSubscription",
  ResumeSubscription = "ResumeSubscription",
  DiscountSubscription = "DiscountSubscription",
  MigrateSubscription = "MigrateSubscription",
}

export interface ISubscriptionStatusChange {
  chargeBack: boolean;
  isCancellation: boolean;
  orderDetailsID: number;
  scourceTypeDescription: string;
  sourceType: SubscriptionStatusChangeSourceType;
  statusChangeDescription: string;
  statusChangeType: SubscriptionStatusChangeType;
  timestamp: string;
  zuoraAmendmentCreatedDate?: string;
  zuoraAmendmentEffectiveDate?: string;
  zuoraAmendmentId: string;
  zuoraOriginalSubscriptionId: string;
  zuoraSubscriptionId: string;
  insertedBy: string;
  registrationKey: string;
  licenseTierID?: number;
}

export default ISubscriptionStatusChange;
