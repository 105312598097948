import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "dsoneweb.designsystem";
import { selectLastScanTime } from "app/Agent/AgentSlice";
import { formatLastScanTime } from "core/Helpers";
import { getSystemInfoScan, selectGetSystemInfoScanStatus, selectSystemInfoScan } from "features/scan/ScanSlice";
import { selectCurrentUser, selectIsAdmin } from "session/SessionSlice";
import { StatusScreen } from "./StatusScreen";
import { ReducerStatus } from "model/IReducerState";
import { selectFamilyMemberSelected } from "features/Family/FamilyDetail/FamilyDetailSlice";

export enum eFeatureTileTypes {
  // eslint-disable-next-line no-unused-vars
  Drivers = "Drivers",
  // eslint-disable-next-line no-unused-vars
  SystemCleanup = "SystemCleanup",
  // eslint-disable-next-line no-unused-vars
  SoftwareProtection = "SoftwareProtection",
  // eslint-disable-next-line no-unused-vars
  AppOptimization = "AppOptimization",
  // eslint-disable-next-line no-unused-vars
  WindowsEnhancements = "WindowsEnhancements",
  // eslint-disable-next-line no-unused-vars
  WebProtection = "WebProtection",
}

// component
// ---------------------------------------------
export function StatusScreenContainer() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // device overview selectors
  const isAdmin = useSelector(selectIsAdmin);
  const lastScanTime = useSelector(selectLastScanTime);
  const systemInfo = useSelector(selectSystemInfoScan);
  const user = useSelector(selectCurrentUser);
  const systemScanStatus = useSelector(selectGetSystemInfoScanStatus);
  const familyMemberSelected = useSelector(selectFamilyMemberSelected);

  useEffect(() => {
    dispatch(getSystemInfoScan());
  }, [dispatch]);

  // throw? error boundary?
  if (!user || !systemInfo) {
    throw new Error("Error loading data for subscription status screen");
  }

  const isFetching = systemScanStatus === ReducerStatus.Loading;

  if (isFetching) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive
        headerText={formatMessage({
          id: `StatusLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `StatusLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    );
  }

  return (
    <StatusScreen
      data-qatag="StatusScreen"
      currentUser={familyMemberSelected || user}
      isAdmin={isAdmin}
      lastScanTime={lastScanTime ? formatLastScanTime(lastScanTime) : null}
      systemInfo={systemInfo}
    />
  );
}
