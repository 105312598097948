import { RootState } from "app/redux/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "dsoneweb.designsystem";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import {
  fetchUserSettings,
  selectScheduledScanOptions,
  selectSettings,
  selectSettingsObj,
  updateUserSetting,
} from "./SettingsSlice";
import {
  IScheduledScanOptionsMessage,
  IUserSettingInstance,
  IUserSettingModel,
} from "model/messaging/messages/AppSettingsMessages";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { selectAppVersion } from "app/Agent/AgentSlice";
import { Settings } from "./Settings";
import { ILicenseData } from "model/license/ILicenseData";
import { ISystemInfoInfo } from "model/scan/SystemInfo";
import { getSystemInfoScan, selectSystemInfoScan, selectSystemInfoScanStatus } from "features/scan/ScanSlice";
import { useGetFamilyQuery } from "api/FamilyApi/FamilyApi";
import IFamilyMember from "model/user/IFamilyMember";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import IActivatedLicense from "model/license/IActivatedLicense";
import useLicenseCheck from "./useLicenseCheck";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";

declare const guiVersionNumber: string;
export interface ISettingsContainerProps {
  settingsSelector?: (state: RootState) => IUserSettingModel[] | null;
  settingsObjSelector?: (state: RootState) => { [key: string]: IUserSettingModel } | null;
  uuidSelector?: (state: RootState) => string;
  appVersionSelector?: (state: RootState) => string;
  licenseDataSelector?: (state: RootState) => ILicenseData;
  licensesStatusSelector?: (state: RootState) => IThunkStatus;
  scheduledScanOptionsSelector?: (state: RootState) => IScheduledScanOptionsMessage;
  systemInfoSelector?: (state: RootState) => ISystemInfoInfo;
  systemInfoStatusSelector?: (state: RootState) => ReducerStatus;
}
export type SettingsToUpdateType = {
  newValue: boolean | string | number;
  userSettingObj: IUserSettingModel;
};

export const SettingsContainer: React.FC<ISettingsContainerProps> = ({
  settingsSelector = selectSettings,
  settingsObjSelector = selectSettingsObj,
  uuidSelector = selectCurrentUuid,
  appVersionSelector = selectAppVersion,
  systemInfoSelector = (state) => selectSystemInfoScan(state),
  scheduledScanOptionsSelector = (state) => selectScheduledScanOptions(state),
  systemInfoStatusSelector = (state) => selectSystemInfoScanStatus(state),
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector(settingsSelector);
  const settingsObj = useSelector(settingsObjSelector);
  const uuid = useSelector(uuidSelector);
  const agentVersion = useSelector(appVersionSelector);
  const systemInfo = useSelector(systemInfoSelector);
  const systemInfoStatus = useSelector(systemInfoStatusSelector);
  const scheduledScanOptions = useSelector(scheduledScanOptionsSelector);
  const privacyPolicyPath = "https://www.driversupport.com/privacypolicy/";
  const termsPath = "https://www.driversupport.com/termsandconditions/";
  const uninstallPath = "https://www.driversupport.com/uninstall/";

  const { data: licenseData } = useGetOrganizationLicensesQuery();
  const { data: users } = useGetFamilyQuery(licenseData, {
    skip: !licenseData,
  });

  const licensedToUserID = licenseData?.activatedLicenses.find(
    (license: IActivatedLicense) => license.uuid === uuid
  )?.userID;

  const { formatMessage } = useIntl();
  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);
  useEffect(() => {
    if (systemInfoStatus === ReducerStatus.Idle) {
      dispatch(getSystemInfoScan());
    }
  }, [dispatch, systemInfoStatus]);

  const [showScheduledScanDialog, setShowScheduledScanDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    type: "success",
    message: "",
  });
  const licensedTo = users?.find((user: IFamilyMember) => user.id === licensedToUserID)?.email;

  const onPrivacyPolicyClicked = () => {
    window.open(privacyPolicyPath);
  };
  const onTermsAndConditionsClicked = () => {
    window.open(termsPath);
  };
  const onUninstallInstructionsClicked = () => {
    window.open(uninstallPath);
  };

  const onUuidClicked = () => {
    navigator.clipboard.writeText(uuid as string).then(
      () => {
        setShowSnackbar(true);
        setSnackbarData({
          type: "success",
          message: formatMessage({
            id: "copy.success",
            defaultMessage: "Copied to clipboard successfully",
          }),
        });
      },
      () => {
        setShowSnackbar(true);
        setSnackbarData({
          type: "error",
          message: formatMessage({
            id: "copy.fail",
            defaultMessage: "Failed to copy to clipboard",
          }),
        });
      }
    );
  };
  const updateSettings = async (settingsToUpdate: SettingsToUpdateType[]) => {
    const UpdatedSettings: IUserSettingInstance[] = [];

    settingsToUpdate.forEach(({ newValue, userSettingObj }) => {
      const { SettingType, SettingTypeName, SettingValue } = userSettingObj;

      UpdatedSettings.push({
        SettingType,
        SettingTypeName,
        NewSettingValue: newValue,
        CurrentSettingValue: SettingValue,
      });
    });

    return await dispatch(
      updateUserSetting({
        UpdatedSettings,
      })
    );
  };

  const onScanNowClicked = () => {
    navigate("/scan");
  };
  const onChangeMachineClicked = () => {
    navigate("/machinepicker");
  };
  const onLogoutClicked = () => {
    navigate("/logout");
  };

  const { licenseCheckStatus, onLicenseCheckClicked } = useLicenseCheck();

  if (!settings || !settingsObj || !uuid || !licenseData || !systemInfo || !scheduledScanOptions || !users) {
    return (
      <Spinner
        data-qatag="spinner"
        isActive={true}
      />
    );
  }

  return (
    <Settings
      data-qatag="settings"
      agentVersion={agentVersion}
      guiVersionNumber={guiVersionNumber}
      licenseCheckStatus={licenseCheckStatus}
      licensedTo={licensedTo}
      onChangeMachineClicked={onChangeMachineClicked}
      onLicenseCheckClicked={onLicenseCheckClicked}
      onLogoutClicked={onLogoutClicked}
      onPrivacyPolicyClicked={onPrivacyPolicyClicked}
      onScanNowClicked={onScanNowClicked}
      onTermsAndConditionsClicked={onTermsAndConditionsClicked}
      onUninstallInstructionsClicked={onUninstallInstructionsClicked}
      onUuidClicked={onUuidClicked}
      operatingSystem={systemInfo.operatingSystem}
      scheduledScanOptions={scheduledScanOptions}
      setShowScheduledScanDialog={setShowScheduledScanDialog}
      setShowSnackbar={setShowSnackbar}
      settings={settings}
      settingsObj={settingsObj}
      showScheduledScanDialog={showScheduledScanDialog}
      showSnackbar={showSnackbar}
      snackbarData={snackbarData}
      updateSettings={updateSettings}
      uuid={uuid}
    />
  );
};
