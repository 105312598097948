import styles from "./UpdatingAlert.module.css";
import { Modal, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import checkImage from "assets/img/icn-green-check.svg";

export type UpdatingAlertProps = {
  /* Controls to open the alert */
  isOpen: boolean;
};

export function UpdatingAlert({ isOpen }: UpdatingAlertProps) {
  return (
    <Modal
      data-qatag="updatingAlertModal"
      open={isOpen}
    >
      <div
        data-qatag="container"
        className={styles.container}
      >
        <div
          data-qatag="content"
          className={styles.content}
        >
          <img
            data-qatag="image"
            className={styles.icon}
            src={checkImage}
            alt="green check"
          />
          <FormattedMessage
            data-qatag="updatingAlert.title"
            id="updatingAlert.title"
            defaultMessage="Thanks for updating!"
          >
            {(text) => (
              <Typography
                data-qatag="titleText"
                variant="h3"
                className={styles.title}
                color="primary"
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
          <FormattedMessage
            data-qatag="updatingAlert.description"
            id="updatingAlert.description"
            defaultMessage="Driver Support One Web should reload momentarily."
          >
            {(text) => (
              <Typography
                data-qatag="descriptionText"
                variant="body1"
                className={styles.description}
                color="primary"
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </div>
      </div>
    </Modal>
  );
}

export default UpdatingAlert;
