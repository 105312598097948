import axios from "axios";

export const getDefaultCountry = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data.country_code || "US"; // Default to 'US' if no country code is found
  } catch (error) {
    return "US"; // Default to 'US' on error
  }
};
