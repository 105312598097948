import { Dialog } from "@mui/material";
import * as Sentry from "@sentry/react";
import { DriverInstallStatus } from "model/driver/DriverUpdateModel";
import {
  driverInstallComplete,
  forceInstallWait,
  resetDriverInstall,
  selectCurrentInstallingDeviceId,
  selectDriverUpdateMap,
} from "../DriverSlice";
import { useDispatch, useSelector } from "react-redux";
import { InstallComplete } from "../modals/InstallComplete";
import { InstallFailed } from "../modals/InstallFailed";
import { InstallInProgress } from "../modals/InstallInProgress";
import { InstallTimedOut } from "../modals/InstallTimedOut";
import { InstallUserInteraction } from "../modals/InstallUserInteraction";
import { InstallVerifying } from "../modals/installVerifying";
import React, { useEffect } from "react";
import { UnknownInstaller } from "../modals/UnknownInstaller";
import { InstallLowDiskSpace } from "../modals/InstallLowDiskSpace";
import { forceInstallComplete } from "../Thunks";
import { selectDeviceById } from "features/scan/ScanSlice";
import { selectCurrentUser } from "session/SessionSlice";
import { selectAgentInfo } from "app/Agent/AgentSlice";
import { reportFullStoryEvent } from "ui.common";
import { useNavigate } from "react-router";
import useAmplitude from "hooks/useAmplitude";
import styled from "@mui/styled-engine";

const InstallModalRenderer: React.FC<{ deviceId: string }> = ({ deviceId }) => {
  const { track } = useAmplitude();
  const map = useSelector(selectDriverUpdateMap);
  const device = useSelector(selectDeviceById(deviceId));
  const currentUser = useSelector(selectCurrentUser);
  const agentInfo = useSelector(selectAgentInfo);
  const dispatch = useDispatch();
  const entry = map.find((e) => e.devices.includes(deviceId));
  const navigate = useNavigate();

  const status = entry?.installRecord?.installStatus;
  //const status = DriverInstallStatus.LowDiskSpace as DriverInstallStatus;

  // if (status == null || entry == null) {
  //   throw new Error(
  //     "Could not find install status for currently installing driver: " +
  //       driverId
  //   );
  // }

  useEffect(() => {
    if (!device || !currentUser) {
      return;
    }

    const reportValues = {
      "Device Name": device.friendlyName,
      "Installed Driver Version": device.installedDriverVersion,
      "Latest Driver Version": device.latestDriverVersion,
      "Registration Key": currentUser.registrationKey,
      "Agent Version": agentInfo?.AppVersion,
      Channel: agentInfo?.RegState.Channel,
    };
    switch (status) {
      case DriverInstallStatus.Complete:
        try {
          track("Driver Install Complete", reportValues);
          reportFullStoryEvent("Driver Install Complete", reportValues);
        } catch (ex) {
          Sentry.captureException(ex, { level: "error" });
        }
        break;
      case DriverInstallStatus.Error:
        try {
          track("Driver Install Complete", reportValues);
          reportFullStoryEvent("Driver Install Failed", reportValues);
        } catch (ex) {
          Sentry.captureException(ex, { level: "error" });
        }
        break;
      default:
        break;
    }
  }, [device, currentUser, agentInfo, status, track]);

  //return appropriate element
  switch (status) {
    case DriverInstallStatus.Complete:
      return (
        <InstallComplete
          data-qatag="driver.modal.complete"
          onContinue={() => {
            dispatch(driverInstallComplete(deviceId));
          }}
        />
      );
    case DriverInstallStatus.Error:
      return (
        <InstallFailed
          data-qatag="driver.modal.error"
          onContinue={() => {
            dispatch(resetDriverInstall);
          }}
        />
      );
    case DriverInstallStatus.InProgress:
      return <InstallInProgress data-qatag="driver.modal.inprogress" />;
    case DriverInstallStatus.Interrupted:
      return <p data-qatag="driver.modal.interrupted">interrupted</p>;
    case DriverInstallStatus.TimedOut:
      return (
        <InstallTimedOut
          data-qatag="driver.modal.timedout"
          onWait={() => dispatch(forceInstallWait(deviceId))}
          onForceInstall={() => dispatch(forceInstallComplete(deviceId))}
        />
      );
    case DriverInstallStatus.Unknown:
      return <p data-qatag="unknown">unknown</p>;
    case DriverInstallStatus.UnknownInstaller:
      return <UnknownInstaller data-qatag="driver.modal.unknownInstaller" />;
    case DriverInstallStatus.UserInteraction:
      return (
        <InstallUserInteraction
          data-qatag="driver.modal.userinterfaction"
          onFinish={() => dispatch(forceInstallComplete(deviceId))}
        />
      );
    case DriverInstallStatus.Verifying:
      return <InstallVerifying data-qatag="driver.modal.unknownInstaller" />;
    case DriverInstallStatus.LowDiskSpace:
      return (
        <InstallLowDiskSpace
          data-qatag="driver.modal.lowdiskspace"
          onCancel={() => {
            dispatch(resetDriverInstall);
          }}
          onRequestSystemClean={() => {
            dispatch(resetDriverInstall);
            navigate("/filecleaning");
          }}
        />
      );
    default:
      return null;
  }
};

const InstallDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 6px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0px;
  }
`;

export const InstallConductor: React.FC = () => {
  const deviceId = useSelector(selectCurrentInstallingDeviceId);

  return (
    <InstallDialog
      data-qatag="driver.modal.host"
      open={deviceId != null}
      maxWidth="md"
    >
      {deviceId != null && (
        <InstallModalRenderer
          data-qatag="driver.modal.renderer"
          deviceId={deviceId}
        />
      )}
    </InstallDialog>
  );
};
