import { MenuItem } from "@mui/material";

/**
 * Luhn algorithm implementation
 * Reference: https://en.wikipedia.org/wiki/Luhn_algorithm
 */
export function luhnCheck(input: string): boolean {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let len = input.length;
  let bit = 1;
  let sum = 0;
  let val;

  while (len) {
    val = parseInt(input.charAt(--len), 10);
    // eslint-disable-next-line no-cond-assign
    sum += (bit ^= 1) ? arr[val] : val;
  }
  if (sum > 0) {
    return sum % 10 === 0;
  }
  return false;
}

/**
 * Adds a leading zero to have a double digit format.
 * @param value number to check if needs a leading zero.
 */
function zeroFill(value: number): string {
  const textValue = String(value);

  if (textValue.length === 1) {
    return "0" + value;
  }

  return textValue;
}

/**
 * Generates an option for each month.
 */
export function generateMonthOptions(): React.ReactElement[] {
  const options: React.ReactElement[] = [];

  for (let index = 1; index <= 12; index++) {
    const month = zeroFill(index);
    options.push(
      <MenuItem
        data-qatag="menu-item"
        key={month}
        value={month}
      >
        {month}
      </MenuItem>
    );
  }

  return options;
}

/**
 * Generates the specified amount of options counting from the current year.
 * @param years amount of years to generate.
 * @returns array of options.
 */
export function generateYearOptions(years: number): React.ReactElement[] {
  const currentYear = new Date().getFullYear();
  const options: React.ReactElement[] = [];

  for (let index = 0; index < years; index++) {
    const nextYear = currentYear + index;
    options.push(
      <MenuItem
        data-qatag="menu-item"
        key={nextYear}
        value={nextYear}
      >
        {nextYear}
      </MenuItem>
    );
  }

  return options;
}

/**
 * Generates the specified amount of options counting from the countries.
 * @param countries to generate.
 * @returns array of options.
 */

type Country = {
  name: string;
  code: string;
};

export function generateCountry(years: Country[]) {
  return years.map((country) => (
    <MenuItem
      data-qatag="menu-item"
      key={country.code}
      value={country.code}
    >
      {country.name}
    </MenuItem>
  ));
}
