import { Box, Divider, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

const Container = styled(Box)`
  align-items: center;
  background: #002c47;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  height: 70px;
  gap: 16px;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;
const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`;
const StyledDivider = styled(Divider)`
  background-color: #535a6a;
  border: none;
  width: 2px;
`;
const StyledTypography = styled(Typography)`
  color: white;
  font-size: 1.125rem;
`;
const StyledButtonType = styled(StyledTypography)`
  text-decoration: underline;
`;

export type RemoteConnectionBarProps = {
  userName: string;
  machineName: string;
  isRemoteConnection: boolean;
  isSignalRConnected: boolean;
};
export function RemoteConnectionBar({
  userName,
  machineName,
  isRemoteConnection,
  isSignalRConnected,
}: RemoteConnectionBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const onSwitchPC = () => {
    navigate("/family");
  };
  const showForRoute = shouldShowForRoute(location.pathname);
  const shouldShow = isRemoteConnection && isSignalRConnected && showForRoute;
  const pcName = machineName || "Custom PC";

  if (shouldShow) {
    return (
      <Container data-qatag="RemoteConnectionBar">
        <StyledBox data-qatag="RemoteConnectionBar.box">
          <InfoOutlinedIcon
            data-qatag="RemoteConnectionBar.infoIcon"
            sx={{ color: "white" }}
          />
          <StyledTypography data-qatag="RemoteConnectionBar.typography">
            <FormattedMessage
              id="remotely-viewing"
              data-qatag="remotely-viewing"
              defaultMessage="Remotely Viewing"
            />
            <span data-qatag="RemoteConnectionBar.familyMemberName">{` ${userName}'s - ${pcName}`}</span>
          </StyledTypography>
        </StyledBox>
        <StyledDivider
          data-qatag="RemoteConnectionBar.divider"
          orientation="vertical"
          flexItem
        />
        <StyledBox data-qatag="RemoteConnectionBar.box2">
          <DesktopWindowsOutlinedIcon
            data-qatag="RemoteConnectionBar.DesktopWindowsOutlinedIcon"
            sx={{ color: "white" }}
          />
          <AppButton
            data-qatag="switchPc.button"
            ariaLabel={"Switch PC"}
            buttonStyle={AppButtonStyles.LinkWhite}
            buttonType="button"
            onClick={onSwitchPC}
          >
            <StyledButtonType data-qatag="RemoteConnectionBar.typography">
              <FormattedMessage
                id="remotely-viewing"
                data-qatag="remotely-viewing"
                defaultMessage="Switch PC"
              />
            </StyledButtonType>
          </AppButton>
        </StyledBox>
      </Container>
    );
  }

  return <></>;
}

function shouldShowForRoute(route: string): boolean {
  return (
    route === "/status" ||
    route === "/drivers" ||
    route === "/dnsprotection" ||
    route === "/filecleaning" ||
    route === "/deceptorprotection" ||
    route === "/optimizations" ||
    route === "/windowsenhancements"
  );
}
