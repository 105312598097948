/* eslint-disable no-unused-vars */
export enum ScanTypes {
  Default,
  Scheduled,
  PNP,
  Verify,
}

export function getScanTypeFromName(scanType: string): ScanTypes {
  switch (scanType) {
    case "Default":
      return ScanTypes.Default;
    case "Scheduled":
      return ScanTypes.Scheduled;
    case "PNP":
      return ScanTypes.PNP;
    case "Verify":
      return ScanTypes.Verify;
    default:
      return ScanTypes.Default;
  }
}

export enum DeviceDriverStatuses {
  None = 0,
  UpToDate = 1,
  OutOfDate = 2,
  Missing = 3,
}

export function getDeviceDriverStatusesFromName(driverStatus: string): DeviceDriverStatuses {
  switch (driverStatus) {
    case "None":
      return DeviceDriverStatuses.None;
    case "UpToDate":
      return DeviceDriverStatuses.UpToDate;
    case "OutOfDate":
      return DeviceDriverStatuses.OutOfDate;
    case "Missing":
      return DeviceDriverStatuses.Missing;
    default:
      return DeviceDriverStatuses.None;
  }
}

export enum SystemOptimizationCategory {
  Startup,
  Internet,
  Windows,
}

export enum DeviceCategory {
  Input = 1,
  Keyboard = 2,
  Display = 3,
  Media = 4,
  Mouse = 5,
  Network = 6,
  Printer = 7,
  Sound = 8,
  Storage = 9,
  USB = 10,
  Other = 11,
}

export function getCategoryFromName(category: string): DeviceCategory {
  switch (category) {
    case "Input":
      return DeviceCategory.Input;
    case "Keyboard":
      return DeviceCategory.Keyboard;
    case "Display":
      return DeviceCategory.Display;
    case "Media":
      return DeviceCategory.Media;
    case "Mouse":
      return DeviceCategory.Mouse;
    case "Network":
      return DeviceCategory.Network;
    case "Printer":
      return DeviceCategory.Printer;
    case "Sound":
      return DeviceCategory.Sound;
    case "Storage":
      return DeviceCategory.Storage;
    case "USB":
      return DeviceCategory.USB;
    default:
      return DeviceCategory.Other;
  }
}

export enum DeviceCategoryPriority {
  Lowest = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export enum SoftwareCategory {
  Standard = 1,
  Productivity = 2,
  Browsing = 4,
  Gaming = 8,
}

export enum TestProgresses {
  Unknown = 0,
  AgentOK = 2,
  AgentStartFailed = 4,
  AgentStartOK = 5,
  StartingTests = 10,
  ServerBusy = 12,
  UuidBlocked = 13,
  OptimizationRequested = 15,
  OptimizationNotRequested = 16,
  PreparingNetworkTest = 20,
  StartingNetworkTest = 30,
  UploadTestComplete = 40,
  DownloadTestComplete = 50,
  PreparingDiskTest = 60,
  StartingDiskTest = 70,
  DiskTestComplete = 80,
  TestsComplete = 90,
  ReportReady = 100,
  TestsCompleteDiskOkNetworkFail = 102,
  TestsCompleteDiskOnly = 104,
  ApiFailure = 110,
}
