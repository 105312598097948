import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useState } from "react";
import { Layout } from "components/Layout/Layout";
import { FamilyHeader } from "./Header";
import { FamilyMemberTable } from "./Table/Table";
import { FormattedMessage } from "react-intl";
import IUser from "model/user/IUser";
import IFamilyMember from "model/user/IFamilyMember";
import { QueryErrorResponse } from "api/types";
import { ExpiredLicenseDialogContainer } from "features/ExpiredLicenseDialog/ExpiredLicenseDialogContainer";

export interface IFamilyProps {
  currentUuid: string | null;
  localAgentUuid: string | null;
  familyMembers: IFamilyMember[] | undefined;
  fetchingFamilyError: QueryErrorResponse;
  handleDelete: (familyMember: IFamilyMember) => void;
  handleFamilyMemberRemoteConnect: (familyMember: IFamilyMember) => void;
  handleResendEmail: (familyMember: IFamilyMember) => void;
  handleDownload: () => void;
  isAdmin: boolean;
  isErrorDeletingFamilyMember: boolean | false;
  isLicenseExpired: boolean;
  loggedInUser: IUser | null;
  successfullyDeletedFamilyMember: boolean | false;
}

export function Family({
  currentUuid,
  localAgentUuid,
  familyMembers,
  fetchingFamilyError,
  handleDelete,
  handleFamilyMemberRemoteConnect,
  handleResendEmail,
  handleDownload,
  isAdmin,
  isErrorDeletingFamilyMember,
  isLicenseExpired,
  loggedInUser,
  successfullyDeletedFamilyMember,
}: IFamilyProps) {
  const prefix = "family.management";
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDeletedModalOpen, setIsDeletedModalOpen] = useState(false);
  const [isExpiredLicenseAlertOpen, setIsExpiredLicenseAlertOpen] = useState(false);

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const closeDeletedModal = () => {
    setIsDeletedModalOpen(false);
  };

  if (isErrorDeletingFamilyMember) {
    setIsErrorModalOpen(true);
  }

  if (successfullyDeletedFamilyMember) {
    setIsDeletedModalOpen(true);
  }

  return (
    <Layout
      data-qatag="family.management.layout"
      qatag={prefix}
    >
      <FamilyHeader
        data-qatag="family.management.header"
        isLicenseExpired={isLicenseExpired}
        setIsExpiredLicenseAlertOpen={setIsExpiredLicenseAlertOpen}
        userFirstName={loggedInUser?.firstName ?? ""}
      />
      {familyMembers ? (
        <FamilyMemberTable
          data-qatag="familyFamilyMemberTable"
          currentUuid={currentUuid}
          localAgentUuid={localAgentUuid}
          familyMembers={familyMembers}
          handleDelete={handleDelete}
          handleFamilyMemberRemoteConnect={handleFamilyMemberRemoteConnect}
          handleResendEmail={handleResendEmail}
          handleDownload={handleDownload}
        />
      ) : fetchingFamilyError ? (
        <>
          <FormattedMessage
            id="fetchFamilyErrorMessage"
            data-qatag="fetchFamilyErrorMessage"
            defaultMessage={"There was an error getting family members"}
          />
        </>
      ) : null}
      <Alert
        data-qatag="Alert.errorDeletingFamilyMember"
        icon={AlertIcon.Warning}
        title={"Something went wrong"}
        text={"Unable to delete family member at this time"}
        buttonText="Back To My Family"
        approveHandler={closeErrorModal}
        isOpen={isErrorModalOpen}
        closeHandler={closeErrorModal}
      />
      <Alert
        data-qatag="Alert.successDeletingFamilyMember"
        icon={AlertIcon.Success}
        title={"Successfully removed family member"}
        text={""}
        buttonText="Back To My Family"
        approveHandler={closeDeletedModal}
        isOpen={isDeletedModalOpen}
        closeHandler={closeDeletedModal}
      />
      {isExpiredLicenseAlertOpen && (
        <ExpiredLicenseDialogContainer
          data-qatag="expiredAlert"
          isAdmin={isAdmin}
          isOpen={isExpiredLicenseAlertOpen}
          closeHandler={() => setIsExpiredLicenseAlertOpen(false)}
        />
      )}
    </Layout>
  );
}
