import { selectIsLocalPCEligibleForLicensing } from "app/Agent/AgentSlice";
import { getMenu } from "main/MenuBuilder";
import { ILicenseData } from "model/license/ILicenseData";
import IUser from "model/user/IUser";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "session/SessionSlice";
import { NavBar } from "./NavBar";
import { GetUserHasAllocatedLicenses } from "api/LicenseApi/LicenseApiHelpers";

export type NavbarContainerProps = {
  isAdmin: boolean;
  isSignalRConnected: boolean;
  hideAll: boolean;
  licenses: ILicenseData;
};

export function NavbarContainer({ isAdmin, isSignalRConnected, hideAll, licenses }: NavbarContainerProps) {
  const user = useSelector(selectCurrentUser);

  const isLocalPCEligibleForLicensing = useSelector(selectIsLocalPCEligibleForLicensing);

  const showDownloadToThisPCButton = ShowDownloadToThisPC(licenses, user, isLocalPCEligibleForLicensing);

  const { menuItems, dropdownItems } = getMenu({
    isAdmin,
    isSignalRConnected,
    hideAll,
    showDownloadToThisPCButton,
  });

  return (
    <NavBar
      data-qatag="navbar"
      dropdownItems={dropdownItems}
      isAdmin={isAdmin}
      logoRedirectPath={"/"}
      menuItems={menuItems}
      user={user}
    />
  );
}

export const ShowDownloadToThisPC = (
  licenses: ILicenseData,
  currentUser: IUser | null,
  isLocalPCEligibleForLicensing: boolean
) => {
  // No current user just return false something is not right
  if (!currentUser) return false;

  // If the local PC is not eligible for licensing don't show the button
  if (!isLocalPCEligibleForLicensing) return false;

  // Is the current user an admin?
  const userIsAdmin = currentUser?.attributes?.toLowerCase()?.includes("admin");

  if (userIsAdmin) {
    // For admins if they have available seats show the button
    return licenses.availableSeats > 0 || GetUserHasAllocatedLicenses(licenses, currentUser);
  } else {
    // For non admins if they have an allocated license show the button
    return GetUserHasAllocatedLicenses(licenses, currentUser);
  }
};
