import { Typography } from "@mui/material";
import { OnOffSwitch } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";
import blockImage from "assets/img/dns/icn-block-ads.svg";
import styled from "@mui/styled-engine";
import extensionIcon from "assets/icon/icn-extension.svg";
import pinIcon from "assets/icon/pin-open.svg";
import logoIcon from "assets/icon/dsOne-favicon.svg";
import chromeStoreImage from "assets/img/dns/chromeStore.svg";
import microsoftStoreImage from "assets/img/dns/microsoftStore.svg";

declare const chromePageboostUrl: string;
declare const edgePageboostUrl: string;

export type PageBoostSectionProps = {
  isOn?: boolean | null;
  onSwitchClick: (isOn: boolean) => Promise<void>;
  isSwitchEnabled: boolean;
};

const Container = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  align-self: flex-start;
`;

const Title = styled(Typography)`
  font-size: 1.313rem;
  line-height: 1.5rem;
  color: #007dba;

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 1.125rem;
    margin-top: 36px;
  }
`;

const StyledSwitch = styled(OnOffSwitch)`
  margin-top: 36px;
`;

const NullSwitch = styled("div")`
  display: block;
  height: 21px;
  margin-top: 36px;
`;

const ImagesContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: space-between;
  margin-top: 15px;
`;

const ImageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: space-between;
  width: 170px;
`;

const Image = styled("img")`
  width: 82px;
  height: 82px;
  margin: 0 auto;
`;

const ImageText = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #007dba;
  letter-spacing: 0px;
  font-weight: 600;
`;

const Divider = styled("div")`
  width: 80%;
  height: 0;
  margin: 40px auto;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
`;

const Explanation = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 15px;
  padding-right: 40px;
`;

const Emphasis = styled("span")`
  font-weight: 600;
  display: inline;
`;

const InstallText = styled(Typography)`
  font-size: 0.875rem;
  width: 18rem;
  margin-top: 20px;
  font-weight: 600;
`;

const InstalledExplanation = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  align-items: flex-start;
  width: 100%;
  padding-right: 40px;
`;

const InstalledExplanationRow = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 14px;
`;

const ExtensionImage = styled("img")`
  margin-left: 14px;
  margin-right: 23px;
`;

const PinImage = styled("img")`
  margin-right: 12px;
`;

const LogoImage = styled("img")`
  margin-left: 12px;
  margin-right: 21px;
`;

const StoreButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  max-width: 360px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 380px;
  }

  button {
    border-radius: 12px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.18);

    &:hover {
      cursor: pointer;
    }
  }
`;

const ChromeButton = styled("button")`
  background-color: #ffffff;
  border: solid 1px #f2f2f2;
  padding: 1px 11px;
`;

const MicrosoftButton = styled("button")`
  background-color: #000000;
  border: none;
  padding: 1px 20px;
`;

export const PageBoostSection: React.FC<PageBoostSectionProps> = ({ isOn, onSwitchClick, isSwitchEnabled }) => {
  const onChromeClick = () => {
    localStorage.setItem("clickedExtension", "true");
    window.open(chromePageboostUrl, "_blank");
  };
  const onMicrosoftClick = () => {
    localStorage.setItem("clickedExtension", "true");
    window.open(edgePageboostUrl, "_blank");
  };
  return (
    <Container data-qatag="Container">
      <Title
        data-qatag="title"
        variant="h4"
      >
        <FormattedMessage
          data-qatag="dnsProtection.pageboostSection.title"
          id="dnsProtection.pageboostSection.title"
          defaultMessage="*Block Ads & Trackers"
        />
      </Title>
      {isOn === null ? (
        <NullSwitch data-qatag="NullSwitch" />
      ) : isOn ? (
        <StyledSwitch
          data-qatag="pageboostSwitch"
          onClick={(e: React.MouseEvent<HTMLElement>, isOn: boolean) => {
            onSwitchClick(isOn);
          }}
          isDisabled={!isSwitchEnabled}
          isOnInitially={isOn}
        />
      ) : null}
      <InstallText
        variant="body2"
        data-qatag="dnsProtection.pageboostSection.installText"
      >
        <FormattedMessage
          data-qatag="dnsProtection.pageboostSection.installTextMessage"
          id="dnsProtection.pageboostSection.installText"
          defaultMessage="Install by clicking below"
        />
      </InstallText>

      <StoreButtonContainer data-qatag="StoreButtonContainer">
        <ChromeButton
          data-qatag="chromeStoreButton"
          onClick={onChromeClick}
        >
          <img
            src={chromeStoreImage}
            data-qatag="chromeStoreImage"
          />
        </ChromeButton>
        <MicrosoftButton
          data-qatag="microsoftStoreButton"
          onClick={onMicrosoftClick}
        >
          <img
            src={microsoftStoreImage}
            data-qatag="microsoftStoreImage"
          />
        </MicrosoftButton>
      </StoreButtonContainer>

      <ImagesContainer data-qatag="ImagesContainer">
        <ImageContainer data-qatag="ImageContainer">
          <Image
            data-qatag="Image"
            src={blockImage}
            alt="block icon"
          />
          <ImageText
            data-qatag="ImageText"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="dnsProtection.pageboostSection.blockAds"
              id="dnsProtection.pageboostSection.blockAds"
              defaultMessage="Block annoying and dangerous ads"
            />
          </ImageText>
        </ImageContainer>
        <ImageContainer data-qatag="ImageContainer">
          <Image
            data-qatag="image"
            src={blockImage}
            alt="block icon"
          />
          <ImageText
            data-qatag="imageText"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="dnsProtection.pageboostSection.blockTrackers"
              id="dnsProtection.pageboostSection.blockTrackers"
              defaultMessage="Block unwanted trackers and privacy risks"
            />
          </ImageText>
        </ImageContainer>
      </ImagesContainer>
      <Divider data-qatag="Divider" />
      <Title
        data-qatag="Title"
        variant="h4"
      >
        <FormattedMessage
          data-qatag="dnsProtection.pageboostSection.HowItWorks"
          id="dnsProtection.pageboostSection.HowItWorks"
          defaultMessage="How It Works"
        />
      </Title>
      <Explanation
        data-qatag="Explanation"
        variant="body2"
      >
        <FormattedMessage
          data-qatag="dnsProtection.pageboostSection.Explanation"
          id="dnsProtection.pageboostSection.Explanation"
          defaultMessage="{emphasis} lives directly in your preferred browser to provide a better web surfing experience. By blocking unwanted ads and trackers, web pages load faster and are less distracting."
          values={{
            emphasis: (
              <FormattedMessage
                data-qatag="dnsProtection.pageboostSection.pageboost"
                id="dnsProtection.pageboostSection.pageboost"
                defaultMessage="Driver Support"
              >
                {(text) => <Emphasis data-qatag="Emphasis">{text}</Emphasis>}
              </FormattedMessage>
            ),
          }}
        />
      </Explanation>
      {isOn ? (
        <InstalledExplanation data-qatag="dnsProtection.pageboostSection.installedExplanation">
          <Typography
            data-qatag="dnsProtection.pageboostSection.installed"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="dnsProtection.pageboostSection.installedMessage"
              id="dnsProtection.pageboostSection.installed"
              defaultMessage="{emphasis} - See below to confirm installation"
              values={{
                emphasis: (
                  <FormattedMessage
                    data-qatag="dnsProtection.pageboostSection.installedEmphasis"
                    id="dnsProtection.pageboostSection.installedEmphasis"
                    defaultMessage="Installed in Browser Toolbar"
                  >
                    {(text) => <Emphasis data-qatag="Emphasis">{text}</Emphasis>}
                  </FormattedMessage>
                ),
              }}
            />
          </Typography>
          <InstalledExplanationRow data-qatag="dnsProtection.pageboostSection.installedExplanationRow1">
            <ExtensionImage
              src={extensionIcon}
              alt="extension icon"
              data-qatag="dnsProtection.pageboostSection.extensionIcon"
            />
            <Typography
              data-qatag="dnsProtection.pageboostSection.installedStep1"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="dnsProtection.pageboostSection.installedStep1Message"
                id="dnsProtection.pageboostSection.installedStep1"
                defaultMessage="Click the Extensions icon"
              />
            </Typography>
          </InstalledExplanationRow>
          <InstalledExplanationRow data-qatag="dnsProtection.pageboostSection.installedExplanationRow2">
            <PinImage
              src={pinIcon}
              alt="pin icon"
              data-qatag="dnsProtection.pageboostSection.pinIcon"
            />
            <Typography
              data-qatag="dnsProtection.pageboostSection.installedStep2"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="dnsProtection.pageboostSection.installedStep2Message"
                id="dnsProtection.pageboostSection.installedStep2"
                defaultMessage="Click 'Pin' or 'Show'"
              />
            </Typography>
          </InstalledExplanationRow>
          <InstalledExplanationRow data-qatag="dnsProtection.pageboostSection.installedExplanationRow3">
            <LogoImage
              src={logoIcon}
              alt="logo icon"
              data-qatag="dnsProtection.pageboostSection.logoIcon"
            />
            <Typography
              data-qatag="dnsProtection.pageboostSection.installedStep3"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="dnsProtection.pageboostSection.installedStep3Message"
                id="dnsProtection.pageboostSection.installedStep3"
                defaultMessage="Click icon"
              />
            </Typography>
          </InstalledExplanationRow>
        </InstalledExplanation>
      ) : null}
    </Container>
  );
};
