import { Box } from "@mui/material";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";
import { Layout } from "components/Layout/Layout";
import { FormattedMessage } from "react-intl";
import logo from "assets/img/logo.png";

export type ResumeRequiredProps = {
  handleResumeSubscription: () => void;
  handleDecline: () => void;
};

const Title = styled(Box)`
  text-align: center;
  font: normal normal 500 1.125rem/1.5rem Roboto;
  color: #0285e1;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 5px;
    font: normal normal 500 1.5rem/2rem Roboto;
  }
`;

const Subtitle = styled(Box)`
  text-align: left;
  margin-top: 12px;
  font: normal normal 400 1rem Roboto;
  letter-spacing: 0.4px;
  color: #535a6a;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 600px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 748px;
  }
`;

const StyledButton = styled(AppButton)`
  margin-top: 64px;
  width: 370px;
  height: 56px;
  font: normal normal 600 1rem Roboto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 44px;
    font: normal normal 600 1.125rem Roboto;
  }
  &:hover {
    opacity: 0.9;
  }
`;

const ButtonLink = styled(AppButton)`
  font: normal normal normal 1rem/1.5rem Roboto;
  letter-spacing: 0.16px;
  text-decoration: underline;
  color: #0285e1;
`;

export function ResumeRequired({ handleResumeSubscription, handleDecline }: ResumeRequiredProps) {
  return (
    <Layout
      data-qatag="Layout"
      qatag="billingHistory"
      useTemplating={true}
    >
      <Layout.Content data-qatag="Layout.Content">
        <img
          data-qatag="navBarLogo"
          src={logo}
          alt="navBar Logo"
        />
        <Title data-qatag="resumerequired.title.container">
          <FormattedMessage
            data-qatag="resumerequired.CardTitleMsg"
            id="resumerequired.Title"
            defaultMessage="Subscription Is Cancelled"
          />
        </Title>
        <Subtitle data-qatag="resumerequired.subtitle.container">
          <FormattedMessage
            data-qatag="resumerequired.CardDescriptionMsg"
            id="resumerequired.Description"
            defaultMessage="To access, click below and resume your subscription."
          />
        </Subtitle>
        <StyledButton
          ariaLabel="My Account"
          buttonType={"button"}
          data-qatag="resumerequired.Button"
          buttonStyle={AppButtonStyles.Green}
          onClick={handleResumeSubscription}
        >
          <FormattedMessage
            data-qatag="resumerequired.ButtonMsg"
            id="resumerequired.Button"
            defaultMessage="My Account"
          />
        </StyledButton>
        <Box
          mt={3}
          data-qatag="resumerequired.ButtonLink-container"
        >
          <ButtonLink
            ariaLabel="No thanks"
            buttonType={"button"}
            data-qatag="resumerequired.ButtonLink"
            buttonStyle={AppButtonStyles.LinkGray}
            onClick={handleDecline}
          >
            <FormattedMessage
              data-qatag="resumerequired.ButtonLinkMsg"
              id="resumerequired.SkipButton"
              defaultMessage="No thanks"
            />
          </ButtonLink>
        </Box>
      </Layout.Content>
    </Layout>
  );
}
