import RESTGatewayAPI from "api/gatewayAPI";
import type { AxiosRequestConfig, AxiosError } from "axios";
declare let gatewayUrl: string;

interface BaseQueryFn {
  url: string;
  method: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
}

export const axiosBaseQuery = ({ baseUrl }: { baseUrl?: string } = { baseUrl: "" }) => {
  return async ({ url, method, data, params, headers }: BaseQueryFn) => {
    try {
      const result = await RESTGatewayAPI({
        url: gatewayUrl + baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
};
