import RESTGatewayAPI from "api/gatewayAPI";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux/store";
import { ReducerStatus } from "model/IReducerState";
import { IHardwareScanMessage } from "model/messaging/messages/scanMessages";
import * as Sentry from "@sentry/react";
import { changeAgentConnection } from "app/redux/applicationSlice";
import IFamilyMember from "model/user/IFamilyMember";
interface IFamilyDetailState {
  data: {
    familyMembers: IFamilyMember[];
    familyMemberSelected: IFamilyMember | undefined;
    userId: string | undefined;
    hardwareScan: IHardwareScanMessage | undefined;
  };
  error: {
    dataFetchError: string | undefined;
  };
  status: {
    familyMemberRemoteConnectStatus: ReducerStatus;
    fetchFamilyMembersStatus: ReducerStatus;
  };
}

const initialState: IFamilyDetailState = {
  data: {
    familyMembers: [],
    familyMemberSelected: undefined,
    hardwareScan: undefined,
    userId: undefined,
  },
  error: {
    dataFetchError: undefined,
  },
  status: {
    familyMemberRemoteConnectStatus: ReducerStatus.Idle,
    fetchFamilyMembersStatus: ReducerStatus.Idle,
  },
};

export const fetchFamilyMemberDetails = createAsyncThunk(
  "users/fetchFamilyMembersStatus",
  async (familyMembers: IFamilyMember[], thunkApi) => {
    try {
      const familyDetails = await Promise.all(
        familyMembers.map(async (familyMember) => {
          const uuid = familyMember.licenseUUID;
          if (familyMember.licenseType === "Activated" && uuid) {
            const isOnlineResponse = await RESTGatewayAPI.get(`/api/OnlineStatus/${uuid}`);
            const machineScanResponse = await RESTGatewayAPI.get(`/api/scan/systeminfo/${uuid}`);

            let machineName = "";

            if (machineScanResponse?.data?.dataResult) {
              machineName = JSON.parse(machineScanResponse.data.payload).EventData.MachineIntelligence
                .MachineDisplayName;
            }

            return {
              ...familyMember,
              isOnline: isOnlineResponse?.data ?? false,
              machineDisplayName: machineName ?? "CustomPC",
            };
          }

          return {
            ...familyMember,
            isOnline: false,
            machineDisplayName: "",
          };
        })
      );

      return familyDetails;
    } catch (e) {
      Sentry.captureException(e, { level: "error" });
    }
  }
);

export const familyMemberRemoteConnect = createAsyncThunk<
  void,
  IFamilyMember,
  {
    state: RootState;
  }
>("userManagement/familyMemberRemoteConnect", async (familyMemberSelected, thunkApi) => {
  try {
    if (familyMemberSelected.licenseUUID == null) {
      return thunkApi.rejectWithValue("Unable to connect remotely : user id has no uuid present");
    }
    thunkApi.dispatch(setFamilyMemberSelected(familyMemberSelected));

    await thunkApi.dispatch(changeAgentConnection(familyMemberSelected.licenseUUID));
  } catch (error) {
    Sentry.captureException(error, { level: "error" });
  }
});

export const familyDetailSlice = createSlice({
  name: "familyDetail",
  initialState,
  reducers: {
    resetFamilyDetailsState: (state) => {
      state.data = initialState.data;
    },
    setFamilyMemberSelected: (state, action: PayloadAction<IFamilyMember>) => {
      state.data.familyMemberSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(familyMemberRemoteConnect.fulfilled, (state, action: PayloadAction) => {
      state.status.familyMemberRemoteConnectStatus = ReducerStatus.Succeeded;
    });
    builder.addCase(familyMemberRemoteConnect.pending, (state) => {
      state.status.familyMemberRemoteConnectStatus = ReducerStatus.Loading;
    });
    builder.addCase(familyMemberRemoteConnect.rejected, (state) => {
      state.status.familyMemberRemoteConnectStatus = ReducerStatus.Failed;
    });
    builder.addCase(fetchFamilyMemberDetails.pending, (state) => {
      state.status.fetchFamilyMembersStatus = ReducerStatus.Loading;
    });
    builder.addCase(fetchFamilyMemberDetails.fulfilled, (state, action) => {
      state.data.familyMembers = action.payload || [];
      state.status.fetchFamilyMembersStatus = ReducerStatus.Succeeded;
    });
  },
});

export const { resetFamilyDetailsState, setFamilyMemberSelected } = familyDetailSlice.actions;

export const familyMemberRemoteConnectStatus = (state: RootState) =>
  state.familyDetail.status.familyMemberRemoteConnectStatus;
export const selectFamilyMemberSelected = (state: RootState) => {
  return state.familyDetail.data.familyMemberSelected;
};
export const selectFetchFamilyMembersStatus = (state: RootState) => state.familyDetail.status.fetchFamilyMembersStatus;
export const selectFamilyMemberDetails = (state: RootState) => state.familyDetail.data.familyMembers;

export default familyDetailSlice.reducer;
