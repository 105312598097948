import { Divider, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Layout } from "components/Layout/Layout";
// models
import { ISystemInfoInfo } from "model/scan/SystemInfo";
import IUser from "model/user/IUser";
// assets
import addPcIcon from "assets/img/status/icn-choose-addPc.svg";
import defaultLaptop from "assets/icon/laptop-illustration.png";
import deviceIcon from "assets/img/status/deviceIcon.svg";
import osIcon from "assets/img/status/operatingSystemIcon.svg";
import ispIcon from "assets/img/status/ispIcon.svg";
import { FeatureStatusContainer } from "./FeatureStatus/FeatureStatusContainer";

const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export type StatusScreenProps = {
  children?: React.ReactNode;
  currentUser: IUser;
  isAdmin: boolean;
  lastScanTime: string | null;
  systemInfo: ISystemInfoInfo;
};

// #region styled-components
const Header = styled("header")`
  width: 100%;
`;

const Title = styled("h1")`
  font: normal normal 500 1.125rem/1.5rem Roboto;
  letter-spacing: 0;
  color: #535353;
  text-align: center;
  margin-bottom: 12px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 500 1.3125rem/1.5rem Roboto;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 24px;
  }
`;

const Overview = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;

const OverviewColumn = styled("div")<{ width: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: ${({ width }) => width};
  }
`;

const PcThumbnail = styled("img")`
  height: 110px;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 136px;
  }
`;

const InfoContainer = styled("div")`
  width: 228px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 100%;
  }
`;

const InfoItem = styled("div")`
  display: flex;
  gap: 14px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const InfoIcon = styled("img")`
  height: 24px;
  width: 24px;
`;

const InfoText = styled("span")`
  color: #535353;
  font: normal normal 500 1rem/1.375rem Roboto;
  letter-spacing: 0.27px;
`;

const InfoValue = styled("span")`
  font-weight: 400;
  letter-spacing: 0;
  display: block;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: inline;
  }
`;

const AddPcContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: column;
    gap: 8px;
  }
`;

const AddPcIcon = styled("img")`
  height: 44px;
  width: 44px;
`;

const LinkSpan = styled("span")`
  text-decoration: underline;
  font: normal normal 500 1rem/1rem Roboto;
  letter-spacing: 0.27px;
  color: #007dba;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: inline;
    flex-direction: unset;
  }
`;

const LineBreak = styled("span")`
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: inline;
  }
`;

const SectionDivider = styled("div")`
  background-color: #dddddd;
  margin-top: 30px;
  height: 1px;
  width: 100%;
`;

const Footer = styled("footer")`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

const LastScan = styled("span")`
  font: normal normal 500 1rem/1.4375rem Roboto;
  letter-spacing: 0;
  color: #535a6a;
`;
// #endregion styled-components

export function StatusScreen({ currentUser, isAdmin, lastScanTime, systemInfo }: StatusScreenProps) {
  return (
    <Layout
      data-qatag="StatusScreen.Layout"
      qatag="StatusScreen.Layout"
    >
      <Header data-qatag="StatusScreen.Header">
        <Title data-qatag="StatusScreen.Title">
          <FormattedMessage
            data-qatag="StatusScreen.TitleMsg"
            id="StatusScreen.TitleMsg"
            defaultMessage="{name}'s PC Overview"
            values={{
              name: capitalizeFirstLetter(currentUser.firstName),
            }}
          />
        </Title>
        <Overview data-qatag="StatusScreen.Overview">
          <OverviewColumn
            data-qatag="StatusScreen.OverviewPc"
            width="30%"
          >
            <PcThumbnail
              data-qatag="StatusScreen.OverviewPcImg"
              alt="User's Computer"
              src={systemInfo.pcImageUrl || defaultLaptop}
            />
          </OverviewColumn>

          <Divider data-qatag="StatusScreen.Header" />

          <OverviewColumn
            data-qatag="StatusScreen.OverviewInfo"
            width={isAdmin ? "40%" : "70%"}
          >
            <InfoContainer data-qatag="StatusScreen.InfoContainer">
              <InfoItem data-qatag="StatusScreen.OverviewDevice">
                <InfoIcon
                  data-qatag="StatusScreen.OverviewIconDevice"
                  alt="Computer Icon"
                  src={deviceIcon}
                />
                <InfoText data-qatag="StatusScreen.OverviewTextDevice">
                  <FormattedMessage
                    data-qatag="StatusScreen.OverviewDevice"
                    id="StatusScreen.OverviewDevice"
                    defaultMessage="Device: <value>{device}</value>"
                    values={{
                      device: systemInfo.pcModel,
                      value: (chunks) => <InfoValue data-qatag="StatusScreen.OverviewValueDevice">{chunks}</InfoValue>,
                    }}
                  />
                </InfoText>
              </InfoItem>

              <InfoItem data-qatag="StatusScreen.OverviewOS">
                <InfoIcon
                  data-qatag="StatusScreen.OverviewIconOS"
                  alt="Operating System Icon"
                  src={osIcon}
                />
                <InfoText data-qatag="StatusScreen.OverviewTextOS">
                  <FormattedMessage
                    data-qatag="StatusScreen.OverviewOS"
                    id="StatusScreen.OverviewOS"
                    defaultMessage="Operating System: <value>{os}</value>"
                    values={{
                      os: systemInfo.operatingSystem,
                      value: (chunks) => <InfoValue data-qatag="StatusScreen.OverviewValueOS">{chunks}</InfoValue>,
                    }}
                  />
                </InfoText>
              </InfoItem>

              <InfoItem data-qatag="StatusScreen.OverviewInternet">
                <InfoIcon
                  data-qatag="StatusScreen.OverviewIconInternet"
                  alt="Internet Icon"
                  src={ispIcon}
                />
                <InfoText data-qatag="StatusScreen.OverviewTextInternet">
                  <FormattedMessage
                    data-qatag="StatusScreen.OverviewInternet"
                    id="StatusScreen.OverviewInternet"
                    defaultMessage="Internet Service Provider: <value>{provider}</value>"
                    values={{
                      provider: systemInfo.isp,
                      value: (chunks) => (
                        <InfoValue data-qatag="StatusScreen.OverviewValueInternet">{chunks}</InfoValue>
                      ),
                    }}
                  />
                </InfoText>
              </InfoItem>
            </InfoContainer>
          </OverviewColumn>

          {isAdmin && (
            <>
              <Divider data-qatag="StatusScreen.Header" />

              <OverviewColumn
                data-qatag="StatusScreen.OverviewAdd"
                width="30%"
              >
                <Link
                  data-qatag="StatusScreen.FamilyLink"
                  to="/family"
                >
                  <AddPcContainer data-qatag="StatusScreen.AddPc">
                    <AddPcIcon
                      data-qatag="StatusScreen.AddPcIcon"
                      alt="Add Pc Icon"
                      src={addPcIcon}
                    />
                    <LinkSpan data-qatag="StatusScreen.FamilyLinkSpan">
                      <FormattedMessage
                        id="StatusScreen.FamilyLinkMsg"
                        data-qatag="StatusScreen.FamilyLinkMsg"
                        defaultMessage="Choose (or Add) <br>Another PC</br>"
                        values={{
                          br: (chunks) => <LineBreak data-qatag="LicenseLimit.lineBreak">{chunks}</LineBreak>,
                        }}
                      />
                    </LinkSpan>
                  </AddPcContainer>
                </Link>
              </OverviewColumn>
            </>
          )}
        </Overview>
      </Header>

      <SectionDivider data-qatag="StatusScreen.SectionDividerOverviewCards" />

      <FeatureStatusContainer data-qatag="FeatureStatusContainer" />

      {/* footer section */}
      {lastScanTime && (
        <Footer data-qatag="StatusScreen.Footer">
          <LastScan data-qatag="StatusScreen.LastScan">
            <FormattedMessage
              id="StatusScreen.LastScanMessage"
              data-qatag="StatusScreen.LastScanMessage"
              defaultMessage="Last Computer Scan: {lastScanTime}"
              values={{ lastScanTime }}
            />
          </LastScan>
        </Footer>
      )}
    </Layout>
  );
}
