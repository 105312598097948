import { selectAgentInitStatus, selectHasLocalAgent } from "app/Agent/AgentSlice";
import { useAppDispatch } from "app/redux/store";
import { useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { selectCurrentUser } from "session/SessionSlice";
import * as Sentry from "@sentry/react";
import { IAddFamilyMemberPayload, useGetFamilyQuery, usePostNewFamilyMemberMutation } from "api/FamilyApi/FamilyApi";
import { v4 as uuidv4 } from "uuid";
import { usePostUserOnboardingMutation } from "api/UserApi/UserApi";
import { AddPCAddFamily } from "./AddPCAddFamily";
import { useNavigate } from "react-router";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { useAddAdditionalPCMutation, useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { allocateLicenseAndDownload, selectAllocateAndDownloadStatus } from "model/organization/OrganizationSlice";

declare const productId: number;

function AddPCAddFamilyContainer() {
  const dispatch = useAppDispatch();
  const hasLocalAgent = useSelector(selectHasLocalAgent);
  const agentInitState = useSelector(selectAgentInitStatus);
  const allocateAndDownloadStatus = useSelector(selectAllocateAndDownloadStatus);
  const fetchingDownloadLinkError = allocateAndDownloadStatus === ReducerStatus.Failed;

  const loggedInUser = useSelector(selectCurrentUser);
  const currentUuid = useSelector(selectCurrentUuid);
  const agentInitComplete =
    currentUuid === null || agentInitState === ReducerStatus.Succeeded || agentInitState === ReducerStatus.Failed;
  const addNewPC = agentInitComplete && (hasLocalAgent as boolean);
  const navigate = useNavigate();

  // RTK Queries
  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
  } = useGetOrganizationLicensesQuery();

  const {
    data: familyMembers,
    isFetching: isFetchingFamily,
    error: fetchingFamilyError,
  } = useGetFamilyQuery(licensesData, {
    skip: !licensesData,
  });

  const [addFamilyMember, { isError: errorAddingNewFamilyMember, isLoading: isAddingNewFamilyMember }] =
    usePostNewFamilyMemberMutation();
  const [
    sendEmailForAddNewPc,
    { isError: errorSendingAddPcEmail, isLoading: isSendingAddPcEmail, isSuccess: successSendingAddPcEmail },
  ] = usePostUserOnboardingMutation();

  const [sendAddAdditionalPC, { isError: errorSendingAddingAdditionalPC, isLoading: isSendingAddingAdditionalPC }] =
    useAddAdditionalPCMutation();

  const hasAvailableLicenses = (licensesData?.availableSeats || 0) > 0;

  // Local Variables
  const hasError =
    errorAddingNewFamilyMember ||
    errorSendingAddPcEmail ||
    licensesDataError ||
    fetchingFamilyError ||
    errorSendingAddingAdditionalPC ||
    fetchingDownloadLinkError;
  const isLoading =
    (!agentInitComplete ||
      !loggedInUser ||
      isLicensesDataLoading ||
      isFetchingFamily ||
      isAddingNewFamilyMember ||
      isSendingAddingAdditionalPC ||
      isSendingAddPcEmail) &&
    !hasError;

  // Callback functions
  const handleAddFamilyMember = (name: string, email: string) => {
    const fullName = name.split(" ");

    const body: IAddFamilyMemberPayload = {
      Email: email,
      Uuid: uuidv4(),
      Name: name,
      firstName: fullName[0],
      lastName: fullName[1],
    };

    addFamilyMember(body)
      .unwrap()
      .then(() => {
        navigate(`/inviteSent?name=${name}&email=${email}&isNew=true`);
      })
      .catch((e) => {
        Sentry.captureException(new Error(`Failed to add family member`), {
          level: "error",
          extra: { exception: e, payload: body },
        });
      });
  };

  const handleAddPC = async () => {
    if (!loggedInUser) {
      Sentry.captureException("No logged in user found when adding a PC", {
        level: "error",
      });
      return;
    }

    const { email, firstName, lastName } = loggedInUser;

    await sendAddAdditionalPC({
      Email: email,
      ProductId: productId,
    });
    await sendEmailForAddNewPc(email).then(() => {
      navigate(`/inviteSent?name=${firstName} ${lastName}&email=${email}&isNew=true&sentToMyself=true`);
    });
  };

  async function handleDownloadAgent() {
    dispatch(allocateLicenseAndDownload());
  }

  const backToFamily = () => {
    navigate("/family");
  };

  return (
    <AddPCAddFamily
      data-qatag="AddPCAddFamily"
      addNewPC={addNewPC}
      backToFamily={backToFamily}
      errorAddingNewFamilyMember={errorAddingNewFamilyMember}
      errorSendingAddPcEmail={errorSendingAddPcEmail}
      familyMembers={familyMembers}
      fetchingFamilyError={fetchingFamilyError}
      handleAddFamilyMember={handleAddFamilyMember}
      handleAddPC={handleAddPC}
      handleDownloadAgent={handleDownloadAgent}
      isLoading={isLoading}
      loggedInUser={loggedInUser}
      showDownloadLinkError={fetchingDownloadLinkError}
      successSendingAddPcEmail={successSendingAddPcEmail}
      hasAvailableLicenses={hasAvailableLicenses}
    />
  );
}

export { AddPCAddFamilyContainer };
