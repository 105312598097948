/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Formik, Field } from "formik";
import { number, object, string } from "yup";
import { Box } from "@mui/material";
import { StyledSelect } from "core/components/input/StyledSelect";
import cvcImage from "assets/img/cc-security.png";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import React, { useEffect, useState } from "react";
import { StyledTextField } from "core/components/input/StyledTextField";
import { IPaymentMethod } from "model/billing/BillingSummaryModel";
import { generateMonthOptions, generateYearOptions, generateCountry } from "./helpers";
import CardField from "components/Form/CardField";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";
import { getCountries } from "utils/getCountries";
import { getDefaultCountry } from "utils/getDefaultCountry";

const validationSchema = object({
  Name: string()
    .trim()
    .required("Full name is required")
    .test("Full Name", "Full name is required (first and last)", (value) => (value ?? "").includes(" ")),
  CardNumber: string()
    .trim()
    .required("Card number is required")
    .test("min-length", "Minimum 15 characters required", (value) => !!(value && value.length >= 15))
    .test("credit-card", "Invalid credit card number", (value) => {
      const visaPattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
      const mastercardPattern = /^5[1-5][0-9]{14}$/;
      const discoverPattern = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
      const amexPattern = /^3[47][0-9]{13}$/;
      const cardNumber = value?.replace(/ /g, "") || "";

      return (
        visaPattern.test(cardNumber) ||
        mastercardPattern.test(cardNumber) ||
        discoverPattern.test(cardNumber) ||
        amexPattern.test(cardNumber)
      );
    }),
  SecurityCode: string()
    .trim()
    .min(3, "CVC is missing numbers")
    .max(4, "CVC has too many numbers")
    .matches(/^[0-9]{3,4}$/, "Must be only digits")
    .required("CVC is required"),
  ZipCode: string()
    .trim()
    .required("Postal Code is required")
    .when("Country", {
      is: "US",
      then: string().matches(/^\d{5}(-\d{4})?$/, "Invalid US postal code"),
      otherwise: string().when("Country", {
        is: "CA",
        then: string().test({
          name: "canadianPostalCode",
          message: "Invalid Canadian postal code",
          test: (value = "") => {
            const fullRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
            const fsaRegex = /^[A-Za-z]\d[A-Za-z]$/;
            return fullRegex.test(value) || fsaRegex.test(value);
          },
        }),
      }),
    }),
  ExpirationYear: number().required("Expiration year is required"),
  ExpirationMonth: number()
    .required("Expiration month is required")
    .when("ExpirationYear", {
      is: (value: number) => value === new Date().getUTCFullYear(),
      then: number().min(new Date().getUTCMonth() + 1, "Expiration past"), //January is 0
    }),
  Country: string().required("Country is required"),
});

//#region component styles

const ErrorText = styled(Box)`
  color: #b00020;
  font-weight: 400;
  margin-left: 14px;
`;

//#endregion component styles

export interface IPaymentFormProps {
  onSubmit: (values: IPaymentMethod) => void;
}

export const UpdatePaymentForm = ({ onSubmit }: IPaymentFormProps) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    Name: "",
    ExpirationMonth: (new Date().getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }),
    ExpirationYear: new Date().getFullYear().toString(),
    SecurityCode: "",
    ZipCode: "",
    CardNumber: "",
    Country: "US",
  });

  useEffect(() => {
    const fetchCountry = async () => {
      const country = await getDefaultCountry();
      setInitialValues((prevValues) => ({ ...prevValues, Country: country }));
    };
    fetchCountry();
  }, []);

  return (
    <Formik
      data-qatag="PaymentForm"
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true} // Enable reinitialization
      onSubmit={(values) => {
        // use the values that were validated by yup
        // this makes sure we pick up trimmed strings
        const trimmedValues = validationSchema.cast(values);
        const { Name, CardNumber, ExpirationMonth, ExpirationYear, SecurityCode, ZipCode, Country } = trimmedValues;

        if (Name && CardNumber && ExpirationMonth && ExpirationYear && SecurityCode && ZipCode && Country) {
          onSubmit({
            isDefault: true,
            creditCard: {
              cardCVC: SecurityCode,
              cardExpirationMonth: +ExpirationMonth,
              cardExpirationYear: +ExpirationYear,
              cardNumber: CardNumber.replace(/ /g, ""),
              creditCardType: "Visa", // TODO: CHANGE THIS!
            },
            paymentInfo: {
              name: Name,
              zipCode: ZipCode,
              country: Country,
            },
            payPalInfo: null,
          });
        }
      }}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {({ handleSubmit, isSubmitting, errors, touched, values, handleBlur, handleChange, setFieldValue }) => (
        <form
          data-qatag="ResumePaymentForm.form"
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: "104px",
          }}
        >
          <Box
            data-qatag="ResumePaymentForm.formContainer"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <StyledTextField
              name="Name"
              label="Name as it appears on card"
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={(touched.Name && errors.Name) ?? " "}
              error={touched.Name && Boolean(errors.Name)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              data-qatag="resume.cartForm.name"
            />
            <Box
              data-qatag="CardFieldContainer"
              mt="-12px"
              mb="16px"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Field
                data-qatag="field"
                component={StyledTextField}
                name="cardNumber"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("CardNumber", event.target.value);
                }}
                value={values.CardNumber || " "}
                error={touched.CardNumber && Boolean(errors.CardNumber)}
                errorlabel={"errors.CardNumber"}
                placeholder="**** **** **** ****"
                label="Card Number"
                InputProps={{
                  inputComponent: CardField,
                }}
              />
              {errors.CardNumber && touched.CardNumber && (
                <ErrorText data-qatag="cardError">{errors.CardNumber}</ErrorText>
              )}
            </Box>
            {/* use css grid here since there are some weird interactions between Mui Grid and full width Selects */}
            <Box
              data-qatag="ResumePaymentForm.form.selectContainer"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
              }}
            >
              <Box
                data-qatag="ResumePaymentForm.form.selectContainerBox"
                display="flex"
                flexDirection="row"
              >
                <StyledSelect
                  id="ExpirationMonth"
                  name="ExpirationMonth"
                  label="Month"
                  style={{ minWidth: 130, maxWidth: 140, marginRight: 10 }}
                  value={values.ExpirationMonth}
                  fullWidth
                  onChange={handleChange}
                  helperText={(touched.ExpirationMonth && errors.ExpirationMonth) ?? " "}
                  error={touched.ExpirationMonth && Boolean(errors.ExpirationMonth)}
                  data-qatag="resume.cartForm.cardExpirationMonth"
                >
                  {generateMonthOptions()}
                </StyledSelect>

                <StyledSelect
                  id="ExpirationYear"
                  name="ExpirationYear"
                  label="Year"
                  value={values.ExpirationYear}
                  style={{ minWidth: 130, maxWidth: 140 }}
                  onChange={handleChange}
                  fullWidth
                  helperText={(touched.ExpirationYear && errors.ExpirationYear) ?? " "}
                  error={touched.ExpirationYear && Boolean(errors.ExpirationYear)}
                  data-qatag="resume.cartForm.cardExpirationYear"
                >
                  {generateYearOptions(11)}
                </StyledSelect>
              </Box>
              <Box
                data-qatag="ResumePaymentForm.form.textFieldContainer"
                flexDirection="row"
              >
                <StyledTextField
                  name="SecurityCode"
                  label="CVC"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.SecurityCode}
                  inputProps={{ maxLength: 4 }}
                  InputLabelProps={{ shrink: true }}
                  style={{ minWidth: 130, maxWidth: 140, marginRight: 10 }}
                  helperText={(touched.SecurityCode && errors.SecurityCode) ?? " "}
                  error={touched.SecurityCode && Boolean(errors.SecurityCode)}
                  data-qatag="resume.cartForm.cardCvc"
                />
                <img
                  data-qatag="cvcImage"
                  src={cvcImage}
                  alt="cvc"
                />
              </Box>
            </Box>

            <StyledSelect
              id="Country"
              name="Country"
              label="Country"
              value={values.Country}
              onChange={handleChange}
              fullWidth
              helperText={(touched.Country && errors.Country) ?? " "}
              error={touched.ExpirationYear && Boolean(errors.ExpirationYear)}
              data-qatag="resume.cartForm.cardExpirationYear"
            >
              {generateCountry(getCountries())}
            </StyledSelect>
            <StyledTextField
              name="ZipCode"
              label="Postal Code"
              value={values.ZipCode}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{ shrink: true }}
              helperText={(touched.ZipCode && errors.ZipCode) ?? " "}
              error={touched.ZipCode && Boolean(errors.ZipCode)}
              data-qatag="resume.cartForm.zipCode"
            />

            <Box
              data-qatag="buttonContainer"
              display="flex"
              flexDirection="column"
              gap="32px"
            >
              <AppButton
                ariaLabel="Update payment method"
                buttonType="submit"
                data-qatag="resume.cartForm.resume"
                buttonStyle={AppButtonStyles.Green}
                onClick={() => {
                  /*submit handled by form, do not do anything here*/
                }}
                style={{ width: "290px", height: "56px", alignSelf: "center" }}
                qaTag="resumeCart.button.resume"
              >
                Update Payment Method
              </AppButton>
              <AppButton
                ariaLabel="Cancel"
                buttonType="reset"
                data-qatag="resume.cartForm.resume"
                buttonStyle={AppButtonStyles.LinkGray}
                onClick={() => navigate("/myaccount")}
                style={{
                  textDecoration: "underline",
                }}
                qaTag="resumeCart.button.resume"
              >
                Cancel
              </AppButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
