import { useNavigate } from "react-router-dom";
import { Link, Breadcrumbs } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import styled from "@mui/styled-engine";
export type BreadcrumbProps = {
  children: React.ReactNode;
  /* To custom the white arrow separator between URLs */
  separatorColor?: string;
};
export type NavigateNextIconStyledProps = {
  palette?: string;
};
export type LinkStyledProps = {
  color?: string;
};
export type LinkProps = {
  href: string;
  children: React.ReactNode;
  color?: string;
};
export type TextProps = {
  children: React.ReactNode;
  key?: string;
  color?: string;
};
//#region component styles
const TextStyled = styled("span")`
  color: ${({ color }) => color || "#FFF"};
  font: normal normal 600 1rem/1.3125rem Roboto;
`;
const LinkStyled = styled(Link)<LinkStyledProps>`
  color: ${({ color }) => color || "#FFF"};
  font: normal normal 400 1rem/1.3125rem Roboto;
`;
const NavigateNextIconStyled = styled(NavigateNext)<NavigateNextIconStyledProps>`
  color: ${({ palette }) => palette || "#FFF"};
`;
//#endregion component styles
export function Breadcrumb({ children, separatorColor }: BreadcrumbProps) {
  return (
    <Breadcrumbs
      data-qatag="Breadcrumbs"
      separator={
        <NavigateNextIconStyled
          data-qatag="NavigateNextIcon"
          fontSize="small"
          palette={separatorColor}
        />
      }
      aria-label="breadcrumbs"
    >
      {children}
    </Breadcrumbs>
  );
}
function BreadcrumbLink({ children, href, color }: LinkProps) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(href);
  };
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <LinkStyled
      data-qatag={`link-${href}`}
      underline="hover"
      href={href}
      onClick={handleClick}
      color={color}
    >
      {children}
    </LinkStyled>
  );
}
function BreadcrumbText({ children, key, color }: TextProps) {
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <TextStyled
      data-qatag={`text-${key}`}
      color={color}
    >
      {children}
    </TextStyled>
  );
}
Breadcrumb.Link = BreadcrumbLink;
Breadcrumb.Text = BreadcrumbText;
export default Breadcrumb;
