import { useDispatch, useSelector } from "react-redux";
import {
  getMachineBrand,
  getProductLine,
  getProductModel,
  fetchManufacturers,
  resetUpdate,
  getLoadingStatus,
  getUpdateStatus,
  fetchProductLine,
  fetchProductModel,
  getMotherboards,
  getMotherboardFamilies,
  getMotherboardModels,
  fetchMotherboards,
  fetchMotherboardFamilies,
  fetchMotherboardModel,
  updateMachineIntelligence,
  submitMachineIntelligence,
} from "./MachinePickerSlice";
import { fetchSystemInfoScan, selectSystemInfoScan } from "features/scan/ScanSlice";
import { ReducerStatus } from "model/IReducerState";
import { useNavigate } from "react-router";
import { Layout } from "components/Layout/Layout";
import { useEffect, useState } from "react";
import { IntelligenceTypes, MatchTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { MachinePicker } from "./MachinePicker";

export function MachinePickerContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const systemInfo = useSelector(selectSystemInfoScan);
  const statusDependency = useSelector(getLoadingStatus);
  const statusUpdateMachine = useSelector(getUpdateStatus);
  const machineBrand = useSelector(getMachineBrand);
  const motherboards = useSelector(getMotherboards);
  const productLine = useSelector(getProductLine);
  const motherboardFamily = useSelector(getMotherboardFamilies);
  const productModel = useSelector(getProductModel);
  const motherboardModel = useSelector(getMotherboardModels);

  const [intelligenceSelected, setIntelligenceSelected] = useState<IntelligenceTypes>(IntelligenceTypes.OEM);
  const [selectedMachineBrand, setSelectedMachineBrand] = useState<string | number | undefined>("");
  const [selectedProductLine, setSelectedProductLine] = useState<string | number | undefined>("");
  const [selectedProductModel, setSelectedProductModel] = useState<string | number | undefined>("");
  const [hasError, setHasError] = useState(false);

  const isIntelligenceOEM = intelligenceSelected === IntelligenceTypes.OEM;
  const isIntelligenceMB = intelligenceSelected === IntelligenceTypes.MB;
  const noIntelligence = intelligenceSelected === IntelligenceTypes.None;

  useEffect(() => {
    dispatch(fetchManufacturers());
    dispatch(fetchSystemInfoScan());
  }, [dispatch]);

  useEffect(() => {
    if (statusUpdateMachine === ReducerStatus.Succeeded) {
      dispatch(resetUpdate()); // To enable revisit the machine picker
      navigate("/scan");
    }
  }, [dispatch, navigate, statusUpdateMachine]);

  useEffect(() => {
    if (isIntelligenceOEM) {
      dispatch(fetchManufacturers());
    } else if (isIntelligenceMB) {
      dispatch(fetchMotherboards());
    }
    setSelectedProductLine("");
    setSelectedProductModel("");
  }, [dispatch, intelligenceSelected, isIntelligenceMB, isIntelligenceOEM]);

  // Clear all if the intelligence changes
  useEffect(() => {
    setSelectedMachineBrand("");
    setSelectedProductLine("");
    setSelectedProductModel("");
  }, [intelligenceSelected]);

  useEffect(() => {
    if (selectedMachineBrand) {
      if (isIntelligenceOEM) {
        dispatch(fetchProductLine(selectedMachineBrand?.toString()));
      } else if (isIntelligenceMB) {
        dispatch(fetchMotherboardFamilies(selectedMachineBrand?.toString()));
      }

      setSelectedProductModel("");
    }
  }, [dispatch, intelligenceSelected, isIntelligenceMB, isIntelligenceOEM, selectedMachineBrand]);

  // useEffect to retrieve Product Model
  useEffect(() => {
    if (selectedMachineBrand && selectedProductLine) {
      if (isIntelligenceOEM) {
        dispatch(
          fetchProductModel({
            manufacturerId: selectedMachineBrand?.toString(),
            familyId: selectedProductLine?.toString(),
          })
        );
      } else if (isIntelligenceMB) {
        dispatch(
          fetchMotherboardModel({
            motherboardId: selectedMachineBrand?.toString(),
            familyId: selectedProductLine?.toString(),
          })
        );
      }
    }
  }, [dispatch, intelligenceSelected, isIntelligenceMB, isIntelligenceOEM, selectedMachineBrand, selectedProductLine]);

  // Clear error state
  useEffect(() => {
    if (selectedMachineBrand && selectedProductLine && selectedProductModel) {
      setHasError(false);
    }
  }, [selectedMachineBrand, selectedProductLine, selectedProductModel]);

  const onScanNowClicked = () => {
    // Universal Intelligence
    if (intelligenceSelected === IntelligenceTypes.None) {
      dispatch(
        updateMachineIntelligence({
          intelligenceType: intelligenceSelected,
          manufacturerID: 0,
          manufacturer: "",
          familyID: 0,
          family: "",
          seriesIsLaptop: false,
          modelID: 0,
          model: "",
          modelIsLaptop: false,
          matchType: MatchTypes.None,
        })
      );
      return;
    }

    if (!selectedMachineBrand || !selectedProductLine || !selectedProductModel) {
      setHasError(true);
      return;
    }

    const manufacturer = (isIntelligenceOEM ? machineBrand : motherboards)?.filter(
      (brand) => brand.key === Number(selectedMachineBrand)
    )?.[0];

    const series = (isIntelligenceOEM ? productLine : motherboardFamily)?.filter(
      (family) => family.key === Number(selectedProductLine)
    )?.[0];

    const model = (isIntelligenceOEM ? productModel : motherboardModel)?.filter(
      (model) => model.key === Number(selectedProductModel)
    )?.[0];

    dispatch(
      updateMachineIntelligence({
        intelligenceType: intelligenceSelected,
        manufacturerID: +selectedMachineBrand,
        manufacturer: manufacturer?.name,
        familyID: +selectedProductLine,
        family: series?.displayName,
        seriesIsLaptop: series?.isLaptop,
        modelID: +selectedProductModel,
        model: model?.displayName,
        modelIsLaptop: model?.isLaptop,
        matchType: MatchTypes.Model,
      })
    );
  };

  const onScanCustomModelClicked = () => {
    const payload = {
      intelligenceType: IntelligenceTypes.None,
      matchType: MatchTypes.None,
      manufacturerID: 0,
      manufacturer: "",
      familyID: 0,
      family: "",
      seriesIsLaptop: false,
      modelID: 0,
      model: "",
      modelIsLaptop: false,
    };

    dispatch(submitMachineIntelligence());
    dispatch(updateMachineIntelligence(payload));
  };

  const onResetUpdate = () => {
    dispatch(resetUpdate());
  };

  return (
    <Layout
      qatag="machinePicker"
      data-qatag="layout"
      useTemplating={true}
    >
      <MachinePicker
        data-qatag="MachinePicker"
        hasError={hasError}
        intelligenceSelected={intelligenceSelected}
        isIntelligenceOEM={isIntelligenceOEM}
        machineBrand={machineBrand}
        motherboards={motherboards}
        motherboardFamily={motherboardFamily}
        motherboardModel={motherboardModel}
        noIntelligence={noIntelligence}
        onResetUpdate={onResetUpdate}
        onScanCustomModelClicked={onScanCustomModelClicked}
        onScanNowClicked={onScanNowClicked}
        productLine={productLine}
        productModel={productModel}
        selectedMachineBrand={selectedMachineBrand}
        selectedProductLine={selectedProductLine}
        selectedProductModel={selectedProductModel}
        setIntelligenceSelected={setIntelligenceSelected}
        setSelectedMachineBrand={setSelectedMachineBrand}
        setSelectedProductLine={setSelectedProductLine}
        setSelectedProductModel={setSelectedProductModel}
        statusDependency={statusDependency}
        statusUpdateMachine={statusUpdateMachine}
        systemInfo={systemInfo}
      />
    </Layout>
  );
}
