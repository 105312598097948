import { Alert, Box, styled } from "@mui/material";
import { Spinner } from "dsoneweb.designsystem";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useGetAgentMetricsQuery, useGetFamilyQuery } from "api/FamilyApi/FamilyApi";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { selectHasLocalAgent, selectIsLocalAgentUnlicensed } from "app/Agent/AgentSlice";
import AppOptimizationIcon from "assets/icon/icn-app-optimization.svg";
import DeceptorsIcon from "assets/img/deceptor/icn-deceptors.svg";
import DeviceDriverIcon from "assets/icon/icn-device-driver-alt.svg";
import FileCleanIcon from "assets/icon/icn-file-clean.svg";
import WebProtectionIcon from "assets/icon/icn-web-protection.svg";
import WindowsOptimizationIcon from "assets/icon/icn-windows-optimization.svg";
import {
  fetchFamilyMemberDetails,
  selectFamilyMemberDetails,
  selectFetchFamilyMembersStatus,
} from "features/Family/FamilyDetail/FamilyDetailSlice";
import { FeatureFlags } from "model/license/ILicenseData";
import { ReducerStatus } from "model/IReducerState";
import IUser from "model/user/IUser";
import { selectAvailableSeats, selectCurrentUser } from "session/SessionSlice";
import { getIsWindows } from "utils/browserUtils";

import { Summary } from "./Summary";
import IAgentMetrics from "model/organization/IAgentMetrics";
import { GetIsLicenseExpired } from "api/LicenseApi/LicenseApiHelpers";

export type ServiceSummaryItem = {
  icon: string;
  id: string;
  name: string;
  value: number | null;
};

/* eslint-disable no-unused-vars */
export enum Services {
  AppsOptimized = "appsOptimized",
  DeceptiveApps = "deceptiveAppsBlocked",
  DeviceDrivers = "deviceDriversUpdated",
  FilesCleanedSize = "filesCleanedSize",
  TimesOptimized = "timesOptimized",
  WebProtection = "webProtection",
  WindowsOptimizations = "windowsOptimizations",
}
/* eslint-enable no-unused-vars */

const ErrorAlert = styled(Alert)`
  margin: ${({ theme }) => theme.spacing(4)} 0;
`;

/**
 * Maps `ServiceSummaryItem` objects to `licenseTier.featureFlags` (per known
 * list of feature flags as of 8/30/2024).
 */
export const serviceItemsMap = {
  [Services.AppsOptimized]: FeatureFlags.AppOptimization,
  [Services.TimesOptimized]: FeatureFlags.AppOptimization,
  [Services.DeviceDrivers]: FeatureFlags.Drivers,
  [Services.DeceptiveApps]: FeatureFlags.SoftwareProtection,
  [Services.FilesCleanedSize]: FeatureFlags.SystemCleanup,
  [Services.WebProtection]: FeatureFlags.WebProtection,
  [Services.WindowsOptimizations]: FeatureFlags.WindowsEnhancements,
};

export function SummaryContainer() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const availableSeats = useSelector(selectAvailableSeats);
  const currentUser: IUser | null = useSelector(selectCurrentUser);
  const familyMemberDetails = useSelector(selectFamilyMemberDetails);
  const familyMemberDetailsStatus = useSelector(selectFetchFamilyMembersStatus);
  const hasLocalAgent = useSelector(selectHasLocalAgent);
  const isLocalAgentUnlicensed = useSelector(selectIsLocalAgentUnlicensed);
  const isWindows = getIsWindows();
  const [isLicenseExpired, setIsLicenseExpired] = useState<boolean>(false);
  const [isPremiumSubscription, setIsPremiumSubscription] = useState<boolean>(false);
  const [premiumServices, setPremiumServices] = useState<ServiceSummaryItem[]>([]);
  const [standardServices, setStandardServices] = useState<ServiceSummaryItem[]>([]);
  const [featureFlags, setFeatureFlags] = useState<string>("");

  const {
    data: orgLicenses,
    isLoading: isOrgLicensesLoading,
    error: isOrgLicenseError,
  } = useGetOrganizationLicensesQuery();

  const {
    data: familyMembers,
    isLoading: isFamilyLoading,
    error: isFamilyError,
  } = useGetFamilyQuery(orgLicenses, {
    skip: !orgLicenses,
  });

  const {
    data: agentMetrics,
    isLoading: isAgentMetricsLoading,
    error: isAgentMetricsError,
  } = useGetAgentMetricsQuery();

  /**
   * Organizes services into the standard and premium lists based on feature
   * flags (subscription type) and business rules.
   *
   * @function compileServicesList
   * @param {Boolean} isPremiumList
   * @param {String} featureFlags
   * @param {Map} serviceItems
   * @returns {ServiceSummaryItem[]}
   */
  function compileServicesList(
    isPremiumList: boolean,
    featureFlags: string,
    serviceItems: Map<string, ServiceSummaryItem>
  ): ServiceSummaryItem[] {
    const services: ServiceSummaryItem[] = [];

    serviceItems.forEach((service: ServiceSummaryItem, key: string) => {
      // Device drivers is always in standard list
      if (key === Services.DeviceDrivers) {
        if (!isPremiumList) {
          services.push(service);
        }

        return;
      }

      // If premium license or item is not specifically licensed (specified in feature flags), add to premium list...
      if (
        isPremiumList &&
        (featureFlags === FeatureFlags.AllFeatures || !featureFlags.includes(serviceItemsMap[key as Services]))
      ) {
        services.push(service);
      } else if (!isPremiumList && featureFlags.includes(serviceItemsMap[key as Services])) {
        // ...otherwise, if item is specifically licensed, add to the standard list.
        services.push(service);
      }
    });

    return services;
  }

  /**
   * Generates a `Map` of `ServiceSummaryItem` objects based on the agent
   * metrics. Note that the value messages are calculated in the
   * `ServiceItemRow` component.
   *
   * @param {IAgentMetrics} agentMetrics
   * @returns {Map<string, ServiceSummaryItem>}
   */
  function generateServiceSummaryItemsMap(agentMetrics: IAgentMetrics): Map<string, ServiceSummaryItem> {
    const serviceItems = new Map(); // Define as `Map` to maintain the order of service summary items

    serviceItems.set(Services.DeviceDrivers, {
      icon: DeviceDriverIcon,
      id: Services.DeviceDrivers,
      name: intl.formatMessage({ id: "summary.deviceDriversUpdated", defaultMessage: "Device Drivers Updated" }),
      value: agentMetrics.deviceDriversUpdated,
    });

    serviceItems.set(Services.WebProtection, {
      icon: WebProtectionIcon,
      id: Services.WebProtection,
      name: intl.formatMessage({ id: "summary.webProtection", defaultMessage: "Web Protection" }),
      value: null,
    });

    serviceItems.set(Services.FilesCleanedSize, {
      icon: FileCleanIcon,
      id: Services.FilesCleanedSize,
      name: intl.formatMessage({ id: "summary.filesCleanedSize", defaultMessage: "Files Cleaned Size" }),
      value: agentMetrics.filesCleanedSize,
    });

    serviceItems.set(Services.DeceptiveApps, {
      icon: DeceptorsIcon,
      id: Services.DeceptiveApps,
      name: intl.formatMessage({ id: "summary.deceptiveAppsBlocked", defaultMessage: "Deceptive Apps Blocked" }),
      value: agentMetrics.deceptiveAppsBlocked,
    });

    serviceItems.set(Services.AppsOptimized, {
      icon: AppOptimizationIcon,
      id: Services.AppsOptimized,
      name: intl.formatMessage({ id: "summary.appsOptimized", defaultMessage: "Apps Optimized" }),
      value: agentMetrics.appsOptimized,
    });

    serviceItems.set(Services.TimesOptimized, {
      icon: AppOptimizationIcon,
      id: Services.TimesOptimized,
      name: intl.formatMessage({ id: "summary.timesOptimized", defaultMessage: "Times Optimized" }),
      value: agentMetrics.timesOptimized,
    });

    serviceItems.set(Services.WindowsOptimizations, {
      icon: WindowsOptimizationIcon,
      id: Services.WindowsOptimizations,
      name: intl.formatMessage({ id: "summary.windowsOptimizations", defaultMessage: "Windows Optimizations" }),
      value: null,
    });

    return serviceItems;
  }

  // Updates `familyMembers` (loaded via RTK query) via `fetchFamilyMemberDetails` (in `FamilyDetailSlice`)
  useEffect(() => {
    if (familyMembers) {
      dispatch(fetchFamilyMemberDetails(familyMembers));
    }
  }, [dispatch, familyMembers]);

  /**
   * Generate the Premium and Standard service messages based on the agent
   * metrics and compile two collections of `ServiceSummaryItem` objects (for
   * Standard and Premium services).
   */
  useEffect(() => {
    if (agentMetrics && orgLicenses) {
      const featureFlags = orgLicenses?.licenseTier?.featureFlags || "";
      const serviceItems = generateServiceSummaryItemsMap(agentMetrics);

      setFeatureFlags(featureFlags);
      setIsLicenseExpired(GetIsLicenseExpired(orgLicenses));
      setIsPremiumSubscription(orgLicenses.licenseTier?.featureFlags === "AllFeatures" || false);
      setPremiumServices(compileServicesList(true, featureFlags, serviceItems));
      setStandardServices(compileServicesList(false, featureFlags, serviceItems));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentMetrics, orgLicenses]);

  if (
    familyMemberDetailsStatus === ReducerStatus.Loading ||
    isFamilyLoading ||
    isOrgLicensesLoading ||
    isAgentMetricsLoading
  ) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive={true}
        text={intl.formatMessage({
          id: "summary.loadingSpinner",
          defaultMessage: "Loading...",
        })}
      />
    );
  }

  if (familyMemberDetailsStatus === ReducerStatus.Failed || isFamilyError || isOrgLicenseError || isAgentMetricsError) {
    return (
      <Box data-qatag="muSummary.fetchDataErrorAlertContainer">
        <ErrorAlert
          severity="error"
          variant="filled"
          data-qatag="muSummary.fetchDataErrorAlert"
        >
          {/* eslint-disable-next-line solveiq/data-qatag */}
          <FormattedMessage
            id="summary.fetchDataErrorMessage"
            defaultMessage={"There was an error getting the Service Summary data."}
          />
        </ErrorAlert>
      </Box>
    );
  }

  return (
    <Summary
      data-qatag="Summary"
      availableSeats={availableSeats}
      currentUser={currentUser}
      familyMembers={familyMemberDetails}
      featureFlags={featureFlags}
      hasLocalAgent={hasLocalAgent}
      isLicenseExpired={isLicenseExpired}
      isLocalAgentUnlicensed={isLocalAgentUnlicensed}
      isPremiumSubscription={isPremiumSubscription}
      isWindows={isWindows}
      orgLicenses={orgLicenses}
      premiumServices={premiumServices}
      standardServices={standardServices}
    />
  );
}
