import { Header } from "../components/MyAccountHeader";
import { SectionContainer } from "../components/StyledComponents";
import AccountIcon from "assets/icon/icn-myAcct.svg";
import DsOneIcon from "assets/icon/dsOne-favicon.svg";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { CallToActionButton } from "../components/AppButtons";
import { SubscriptionData, SubscriptionSummary } from "model/subscription/Subscription";
import { useMemo } from "react";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { QueryErrorResponse } from "api/types";
import styled from "@mui/styled-engine";

//#region component styles
const RefundedMessage = styled("span")`
  font: italic 300 normal 0.75rem/1.25rem Roboto;
  margin-left: 0.25rem;
  opacity: 0.9;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 0.875rem;
  }
`;

const PlanDescContainer = styled("div")`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const PlanContentContainer = styled("div")`
  p + div {
    margin-top: 12px;
  }
`;
const PlanInfoContainer = styled("div")`
  align-items: flex-start;
  display: flex;
  margin-bottom: 16px;
`;
const PlanDescTypography = styled(Typography)`
  font-size: 1rem;
  /* margin-bottom: 12px; */

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.125rem;
  }
`;
const DSOneIconStyled = styled("img")`
  margin-right: 16px;
  margin-top: 4px;
`;
const DiscountSpan = styled("span")`
  color: #028f3a;
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
  margin-left: 8px;
`;
const NextChargeText = styled(Typography)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;

    span + span {
      margin-left: 6px;
    }
  }
`;
const StyledButton = styled(AppButton)`
  height: 44px;
  font: normal normal 400 1rem Roboto;

  &:hover {
    opacity: 0.9;
  }
`;

//#endregion component styles

const testTagPrefix = `myAccount.subscriptionState`;
const createFormattedDate = (nextChargeDateString: string | undefined) => {
  if (!nextChargeDateString) {
    return false;
  }
  const nextChargeDate = new Date(nextChargeDateString);
  return `${nextChargeDate.getMonth() + 1}-${nextChargeDate.getDate()}-${nextChargeDate.getFullYear()}`;
};

export interface ISubscriptionInfoSectionProps {
  fetchingSubscriptionError: QueryErrorResponse;
  subscriptionData?: SubscriptionData;
  subscriptionSummary?: SubscriptionSummary;
  resumeSubscription: () => void;
  isPaymentMethodDeclined: boolean;
  isRefund: boolean;
  isUpgrading: boolean;
  offerUpgrade: boolean;
  handleRedirectToSelectPaymentMethod: () => void;
}

export const SubscriptionInfoSection = ({
  fetchingSubscriptionError,
  subscriptionData,
  subscriptionSummary,
  resumeSubscription,
  isPaymentMethodDeclined,
  isRefund,
  isUpgrading,
  offerUpgrade,
  handleRedirectToSelectPaymentMethod,
}: ISubscriptionInfoSectionProps) => {
  const subscriptionStatus = subscriptionData?.status;
  const isPlanActive = subscriptionStatus === "Active";
  const isPlanActiveOrRefunded = isPlanActive || isRefund;
  const ratePlanType = subscriptionSummary?.zRatePlanName;
  const isPlanCanceledOrSuspended = subscriptionStatus === "Canceled" || subscriptionStatus === "Suspended";
  const nextChargeDateString = subscriptionSummary?.nextChargeDate;

  const formattedNextChargeDate: string | false = useMemo(() => {
    return createFormattedDate(nextChargeDateString);
  }, [nextChargeDateString]);

  function handleResumeSubscriptionWrapper() {
    // If the user is on the "Payment Declined" state user will be redirected to the payment method page
    if (isPaymentMethodDeclined) {
      handleRedirectToSelectPaymentMethod();
    } else {
      resumeSubscription();
    }
  }

  return (
    <SectionContainer data-qatag="myAccount.subscriptionState">
      <Header
        data-qatag="myAccount.subscriptionState.header"
        hideActionButton={!!fetchingSubscriptionError}
        hideTextLinkOnMobile={false}
        icon={AccountIcon}
        linkPath="/billing-history"
        linkText="View Invoices"
        showEditIcon={false}
        tag={testTagPrefix}
        title="Billing"
      />
      {fetchingSubscriptionError ? (
        <PlanDescContainer data-qatag="myAccount.subscriptionState.planDescriptionContainer">
          <FormattedMessage
            id="myAccount.paymentMethod.cardInfo.error"
            data-qatag="myAccount.paymentMethod.cardInfo.error"
            defaultMessage="There was an error fetching payment info"
          />
        </PlanDescContainer>
      ) : subscriptionData ? (
        <PlanDescContainer data-qatag="myAccount.subscriptionState.planDescriptionContainer">
          <PlanContentContainer data-qatag="myAccount.subscriptionState.planContentContainer">
            <PlanDescTypography data-qatag="myAccount.subscriptionState.planDescTypography">
              <FormattedMessage
                id={`${{ testTagPrefix }}.planDescriptionText`}
                data-qatag="myAccount.subscriptionState.plan.planDescriptionText"
                defaultMessage="{planType} Service Plan - <ps>{planState}</ps>"
                values={{
                  planType: ratePlanType,
                  planState: isPaymentMethodDeclined ? (
                    <FormattedMessage
                      id="myAccount.paymentMethod.paymentDeclined"
                      data-qatag="myAccount.paymentMethod.paymentDeclined"
                      defaultMessage="Payment Declined"
                    />
                  ) : isRefund ? (
                    <>
                      <FormattedMessage
                        id="myAccount.paymentMethod.Refunded"
                        data-qatag="myAccount.paymentMethod.Refunded"
                        defaultMessage="Refunded"
                      />
                      <RefundedMessage data-qatag="myAccount.alertMessage">
                        <FormattedMessage
                          id="myAccountRefundedMessage"
                          data-qatag="myAccount.refundedMessage"
                          defaultMessage="(You may purchase again using a different email)"
                        />
                      </RefundedMessage>
                    </>
                  ) : subscriptionStatus === "Suspended" ? (
                    <FormattedMessage
                      id="myAccount.paymentMethod.cancelled"
                      data-qatag="myAccount.paymentMethod.cancelled"
                      defaultMessage="Cancelled"
                    />
                  ) : (
                    subscriptionStatus
                  ),
                  ps: (chunks) => (
                    <strong data-qatag="myAccount.subscriptionState.bold.SubscriptionText">{chunks}</strong>
                  ),
                }}
              />
              {subscriptionSummary?.discountPercentage ? (
                <DiscountSpan data-qatag="myAccount.subscriptionState.discount.span">
                  <FormattedMessage
                    id={`${{ testTagPrefix }}.plan.discount`}
                    data-qatag="myAccount.subscriptionState.plan.plan.discount"
                    defaultMessage="({planDiscount}% Discount)"
                    values={{
                      planDiscount: subscriptionSummary.discountPercentage,
                    }}
                  />
                </DiscountSpan>
              ) : null}
            </PlanDescTypography>
            {isPlanActiveOrRefunded ? (
              <PlanInfoContainer data-qatag="myAccount.subscriptionState.planInfoContainer">
                <DSOneIconStyled
                  data-qatag="myAccount.subscriptionState.plan.plan.DSOneIcon"
                  src={DsOneIcon}
                  alt="DSOne icon"
                />
                <div data-qatag="myAccount.subscriptionState.plan.typography">
                  {isPlanActive ? (
                    <NextChargeText data-qatag="myAccount.subscriptionState.plan.plan.bill">
                      <FormattedMessage
                        id={`${{ testTagPrefix }}.plan.nextCharge`}
                        data-qatag="myAccount.subscriptionState.plan.plan.nextCharge"
                        defaultMessage="<span>Driver Support One - <b>{planPrice}</b></span>"
                        values={{
                          planPrice: `$${subscriptionSummary?.nextChargeAmount.toFixed(2) ?? "0.00"}`,
                          b: (chunks) => <strong data-qatag="myAccount.subscriptionState.bold.price">{chunks}</strong>,
                          span: (chunks) => <span data-qatag="myAccount.subscriptionState.price.span">{chunks}</span>,
                        }}
                      />
                      {formattedNextChargeDate ? (
                        <FormattedMessage
                          id={`${{ testTagPrefix }}.plan.nextChargeDate`}
                          data-qatag="myAccount.subscriptionState.plan.plan.nextChargeDate"
                          defaultMessage="<s>will be billed on <b>{nextBill}</b></s>"
                          values={{
                            nextBill: formattedNextChargeDate,
                            b: (chunks) => (
                              <strong data-qatag="myAccount.subscriptionState.bold.nextBill">{chunks}</strong>
                            ),
                            s: (chunks) => <span data-qatag="myAccount.subscriptionState.nextBill">{chunks}</span>,
                          }}
                        />
                      ) : null}
                    </NextChargeText>
                  ) : null}

                  <Typography
                    sx={{ fontSize: "1rem", fontWeight: "500" }}
                    data-qatag="myAccount.subscriptionState.plan.plan.id"
                  >
                    <FormattedMessage
                      id={`${{ testTagPrefix }}.plan.idText`}
                      data-qatag="myAccount.subscriptionState.plan.plan.idText"
                      defaultMessage="<b>Subscription - {planId}</b>"
                      values={{
                        planId: subscriptionData.subscriptionNumber,
                        b: (chunks) => (
                          <strong data-qatag="myAccount.subscriptionState.subscriptionNumber">{chunks}</strong>
                        ),
                      }}
                    />
                  </Typography>
                </div>
              </PlanInfoContainer>
            ) : null}
          </PlanContentContainer>
          {offerUpgrade && !isUpgrading ? (
            <>
              <CallToActionButton
                data-qatag="calToActionButton.UpgradeToPremium"
                linkPath="/upsell-promotion"
                linkText={"Upgrade to Premium"}
                tag={testTagPrefix}
                ariaLabel={"Upgrade to premium"}
                buttonType={"button"}
                additionalStyles={{ maxWidth: "260px", width: "100%" }}
              />
            </>
          ) : null}
          {isPlanCanceledOrSuspended && !isRefund ? (
            <>
              <StyledButton
                ariaLabel="Resume Service"
                buttonType={"button"}
                data-qatag="resumeSubscription.Button"
                buttonStyle={AppButtonStyles.Green}
                onClick={handleResumeSubscriptionWrapper}
              >
                <FormattedMessage
                  data-qatag="resumeSubscription.ButtonMsg"
                  id="resumeSubscription.Button"
                  defaultMessage="Resume Service"
                />
              </StyledButton>
            </>
          ) : null}
        </PlanDescContainer>
      ) : null}
    </SectionContainer>
  );
};
