import Cleave from "cleave.js/react";
import { Ref, forwardRef } from "react";

type CardInputProps = {
  inputRef?: Ref<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
};

const CardInput = forwardRef<HTMLInputElement, CardInputProps>((props, ref) => {
  const { inputRef = ref, ...rest } = props;
  return (
    <Cleave
      data-qatag="cardNumberBox"
      placeholder="Enter credit card number"
      options={{
        creditCard: true,
      }}
      htmlRef={inputRef as (i: HTMLInputElement) => void}
      {...rest}
    />
  );
});

export default CardInput;
