import { Box, Divider, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import appOptimization from "assets/icon/icn-app-optimization.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";
import styled from "@mui/styled-engine";

const TimeoutContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  height: 100%;
  min-height: 420px;
  max-height: 660px;
  padding: 32px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-height: 420px;
    max-width: 800px;
  }
`;
const ActionContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-top: 12px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;
const InnerActionContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 49%;
  }
`;
const StyledAppButton = styled(AppButton)`
  font-weight: 500;
  margin-top: 12px;
  width: 280px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 32px;
  }
`;
const StyledDivider = styled(Divider)`
  margin: 20px;
`;

export interface IInstallTimedOutProps {
  onWait: () => void;
  onForceInstall: () => void;
}

//#region Component
export const InstallTimedOut: React.FC<IInstallTimedOutProps> = (props) => {
  const theme = useTheme() as Theme;
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <TimeoutContainer
      data-qatag="driver.modal.install.timed_out"
      id="driver.modal.install.timed_out"
    >
      <img
        data-qatag="driver.modal.install.timed_out.icon"
        id="driver.modal.install.timed_out.icon"
        src={appOptimization}
        alt=""
      />

      <Typography
        data-qatag="driver.modal.install.timed_out.title.text"
        id="driver.modal.install.timed_out.title.text"
        variant="h5"
        color="textPrimary"
        align="center"
        sx={{ fontSize: { xs: "1.375rem", md: "1.5rem" } }}
      >
        <FixedFormattedMessage
          data-qatag="driver.modal.install.timed_out.title"
          id="driver.modal.install.timed_out.title"
          defaultMessage={
            largeScreen
              ? "It looks like your install is taking {br} longer than expected."
              : "It looks like your install is {br} taking longer than expected."
          }
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.timed_out.break"
                id="driver.modal.install.timed_out.break"
              />
            ),
          }}
        />
      </Typography>

      <Typography
        data-qatag="driver.modal.install.timed_out.description"
        id="driver.modal.install.timed_out.description"
        variant="body2"
        color="primary"
        align="center"
        sx={{ maxWidth: { xs: "250px", sm: "350px", md: "100%" } }}
      >
        <FixedFormattedMessage
          data-qatag="driver.modal.install.timed_out.description"
          id="driver.modal.install.timed_out.description"
          defaultMessage="An excessive amount of time has passed and the driver install may have already finished."
        />
      </Typography>

      <ActionContainer
        data-qatag="driver.modal.install.timed_out.action.done_container"
        id="driver.modal.install.timed_out.action.done_container"
      >
        <InnerActionContainer
          data-qatag="driver.modal.install.timed_out.action.done.button_container"
          id="driver.modal.install.timed_out.action.done.button_container"
        >
          <Typography
            data-qatag="driver.modal.install.timed_out.action.done_label.text"
            id="driver.modal.install.timed_out.action.done_label.text"
            variant="body2"
            color="primary"
            align="center"
          >
            <FormattedMessage
              data-qatag="driver.modal.install.timed_out.action.done_label"
              id="driver.modal.install.timed_out.action.done_label"
              defaultMessage="Is the installation {br}already finished?"
              values={{
                br: <br data-qatag="lineBreak" />,
              }}
            />
          </Typography>

          <StyledAppButton
            ariaLabel="I am done installing"
            buttonType="button"
            data-qatag="driver.modal.install.timed_out.action.done.button"
            id="driver.modal.install.timed_out.action.done.button"
            buttonStyle={AppButtonStyles.Green}
            onClick={props.onForceInstall}
          >
            <FormattedMessage
              data-qatag="driver.modal.install.timed_out.action.done.content"
              id="driver.modal.install.timed_out.action.done.content"
              defaultMessage="I Am Done Installing"
            />
          </StyledAppButton>
        </InnerActionContainer>

        <StyledDivider
          data-qatag="driver.modal.install.timed_out.divider"
          id="driver.modal.install.timed_out.divider"
          variant="middle"
          orientation={largeScreen ? "vertical" : "horizontal"}
          flexItem
        />

        <InnerActionContainer
          data-qatag="driver.modal.install.timed_out.action.wait.container"
          id="driver.modal.install.timed_out.action.wait.container"
        >
          <Typography
            data-qatag="driver.modal.install.timed_out.action.wait.label.text"
            id="driver.modal.install.timed_out.action.wait.label.text"
            variant="body2"
            color="primary"
            align="center"
          >
            <FormattedMessage
              data-qatag="driver.modal.install.timed_out.action.wait.label"
              id="driver.modal.install.timed_out.action.wait.label"
              defaultMessage="The driver is still installing, please {br} wait a few minutes longer."
              values={{
                br: (
                  <br
                    data-qatag="driver.modal.install.timed_out.action.wait.break3"
                    id="driver.modal.install.timed_out.action.wait.break3"
                  />
                ),
              }}
            />
          </Typography>
          <Box
            data-qatag="driver.modal.install.timed_out.action.wait.button_container"
            id="driver.modal.install.timed_out.action.wait.button_container"
          >
            <StyledAppButton
              ariaLabel="wait"
              buttonType="button"
              data-qatag="driver.modal.install.timed_out.action.wait.button"
              id="driver.modal.install.timed_out.action.wait.button"
              buttonStyle={AppButtonStyles.Green}
              onClick={props.onWait}
            >
              <FormattedMessage
                data-qatag="driver.modal.install.timed_out.action.wait.button.content"
                id="driver.modal.install.timed_out.action.wait.button.content"
                defaultMessage="I Am Still Installing"
              />
            </StyledAppButton>
          </Box>
        </InnerActionContainer>
      </ActionContainer>
    </TimeoutContainer>
  );
};
//#endregion Component
