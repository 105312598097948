import * as Sentry from "@sentry/react";
import { Spinner } from "dsoneweb.designsystem";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { selectLocalAgentUuid } from "app/Agent/AgentSlice";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { RootState } from "app/redux/store";
import { useDeleteFamilyMemberMutation, useGetFamilyQuery, useLazyResendInviteQuery } from "api/FamilyApi/FamilyApi";
import { useGetOrganizationLicensesQuery, useLazyReallocateLicenseQuery } from "api/LicenseApi/LicenseApi";
import { GetIsLicenseExpired } from "api/LicenseApi/LicenseApiHelpers";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import { ILicenseData } from "model/license/ILicenseData";
import IFamilyMember from "model/user/IFamilyMember";
import IUser from "model/user/IUser";
import { selectCurrentUser, selectIsAdmin } from "session/SessionSlice";

import { Family } from "./Family";
import {
  familyMemberRemoteConnect,
  fetchFamilyMemberDetails,
  selectFamilyMemberDetails,
  selectFetchFamilyMembersStatus,
} from "./FamilyDetail/FamilyDetailSlice";

export interface IFamilyProps {
  addingFamilyMemberSelector?: (state: RootState) => boolean;
  currentUserSelector?: (state: RootState) => IUser;
  fetchUsersStatusSelector?: (state: RootState) => ReducerStatus;
  licenseDataSelector?: (state: RootState) => ILicenseData;
  licensesStatusSelector?: (state: RootState) => IThunkStatus;
}

export const FamilyContainer: React.FC<IFamilyProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector(selectCurrentUser);
  const isAdmin = useSelector(selectIsAdmin);
  const familyMemberDetails = useSelector(selectFamilyMemberDetails);
  const familyMemberDetailsStatus = useSelector(selectFetchFamilyMembersStatus);
  const currentUuid = useSelector(selectCurrentUuid);
  const [reallocateLicense] = useLazyReallocateLicenseQuery();
  const [resendInvite] = useLazyResendInviteQuery();
  const [isResending, setIsResending] = useState<boolean>();
  const localAgentUuid = useSelector(selectLocalAgentUuid);

  const { data: licenseData } = useGetOrganizationLicensesQuery();
  const {
    data: familyMembers,
    isLoading: isFamilyLoading,
    error: fetchingFamilyError,
  } = useGetFamilyQuery(licenseData, {
    skip: !licenseData,
  });

  const isLicenseExpired = GetIsLicenseExpired(licenseData);

  useEffect(() => {
    if (familyMembers && !isFamilyLoading) {
      dispatch(fetchFamilyMemberDetails(familyMembers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyMembers, isFamilyLoading]);

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    deleteFamilyMember,
    {
      isError: isErrorDeletingFamilyMember,
      isLoading: isDeletingFamilyMember,
      isSuccess: successfullyDeletedFamilyMember,
    },
  ] = useDeleteFamilyMemberMutation();

  let isLastAdmin = false;

  const count = familyMembers?.reduce((prev: number, current: IFamilyMember) => {
    return prev + Number(current.isAdmin === true);
  }, 0);

  isLastAdmin = count <= 1;

  const handleDelete = (familyMember: IFamilyMember) => {
    const { isAdmin, isLicenseActivated, licenseToken, machineDisplayName } = familyMember;

    if (isAdmin && isLastAdmin) {
      //cant remove last admin
      return;
    }
    if (isAdmin && !licenseToken) {
      return Sentry.captureException(new Error("null license token found for family member"));
    }

    const params = {
      email: familyMember.email as string,
      fullName: familyMember.fullName as string,
      isAdmin: isAdmin ? "true" : "false",
      machineName: isLicenseActivated ? (machineDisplayName as string) : ("" as string),
      licenseToken: licenseToken as string,
    };
    navigate({
      pathname: "/confirmRemoveFamilyMember",
      search: `?${createSearchParams(params)}`,
    });
  };

  const handleDownload = () => {
    navigate("/download");
  };

  const handleResendEmail = (familyMember: IFamilyMember) => {
    const { licenseToken, userHasSomeActivatedLicense, email, fullName, firstName, lastName } = familyMember;

    if (fullName && email) {
      const params = {
        email,
        name: fullName,
      };
      const pathname = "/inviteSent";
      const search = `?${createSearchParams(params)}`;

      //resend for additional admin PC
      if (userHasSomeActivatedLicense && licenseToken) {
        setIsResending(true);
        reallocateLicense({ licenseToken, email })
          .unwrap()
          .then(() => {
            setIsResending(false);
            navigate({
              pathname,
              search,
            });
          });
      }
      //resend for family member
      else {
        setIsResending(true);
        resendInvite({ email, firstName, lastName })
          .unwrap()
          .then(() => {
            setIsResending(false);
            navigate({
              pathname,
              search,
            });
          });
      }
    }
  };

  const onFamilyMemberRemoteConnect = async (familyMember: IFamilyMember) => {
    await dispatch(familyMemberRemoteConnect(familyMember));

    navigate("/status");
  };

  const isLoading = isDeletingFamilyMember || familyMemberDetailsStatus === ReducerStatus.Loading || isResending;

  if (isLoading) {
    return (
      <Spinner
        data-qatag="loadingModal"
        isActive={true}
        text="Loading..."
      />
    );
  }

  return (
    <Family
      data-qatag="family.management"
      currentUuid={currentUuid}
      localAgentUuid={localAgentUuid || null}
      familyMembers={familyMemberDetails}
      fetchingFamilyError={fetchingFamilyError}
      handleDelete={handleDelete}
      handleDownload={handleDownload}
      handleFamilyMemberRemoteConnect={onFamilyMemberRemoteConnect}
      handleResendEmail={handleResendEmail}
      isAdmin={isAdmin}
      isErrorDeletingFamilyMember={isErrorDeletingFamilyMember}
      isLicenseExpired={isLicenseExpired}
      loggedInUser={loggedInUser}
      successfullyDeletedFamilyMember={successfullyDeletedFamilyMember}
    />
  );
};
