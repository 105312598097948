import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "api/axiosBaseQuery";
import { licenseApi } from "api/LicenseApi/LicenseApi";
import { RootState } from "app/redux/store";
import defaultImage from "assets/img/family/avatar-default.svg";
import { IActivatedLicense } from "model/license/IActivatedLicense";
import { IAllocatedLicense } from "model/license/IAllocatedLicense";
import { ILicenseData } from "model/license/ILicenseData";
import { MachineInfo, MachineInfoInitialState } from "model/messaging/messages/scanMessages";
import IAgentMetrics from "model/organization/IAgentMetrics";
import { IFamilyMember } from "model/user/IFamilyMember";
import { IUser } from "model/user/IUser";

declare const productId: number;

export interface IErrorResponse {
  status: number | undefined;
  data: unknown;
}
export interface IAddFamilyMemberPayload {
  Email: string;
  Uuid: string;
  Name: string;
  firstName: string;
  lastName: string;
}

export interface IResendInvitePayload {
  email: string;
  firstName: string;
  lastName: string;
}

const familyApi = createApi({
  reducerPath: "familyApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["AgentMetrics", "Family"],
  endpoints(build) {
    return {
      getFamily: build.query({
        query: () => {
          return {
            url: `/api/organization/users`,
            method: "get",
          };
        },
        providesTags: (result, error, user) => {
          return result
            ? result.map((family: IFamilyMember) => ({
                type: "Family",
                email: family.email,
              }))
            : [];
        },
        transformResponse: (response, meta, { activatedLicenses, allocatedLicenses }: ILicenseData) => {
          return sortFamilyMembers(response, activatedLicenses, allocatedLicenses);
        },
      }),

      getAgentMetrics: build.query<IAgentMetrics, void>({
        query: () => {
          return {
            url: `/api/organization/${productId}/agentMetrics/`,
            method: "get",
          };
        },
        keepUnusedDataFor: 0,
      }),

      resendInvite: build.query<undefined, IResendInvitePayload>({
        query: (data: IResendInvitePayload) => {
          return {
            url: `/api/User/addorganizationuser/${productId}`,
            method: "put",
            data,
          };
        },
      }),

      getOnlineStatus: build.query<boolean, string | undefined>({
        query: (uuid) => {
          return {
            url: `/api/OnlineStatus/${uuid}`,
            method: "get",
          };
        },
      }),

      getMachineInfo: build.query<MachineInfo, string | undefined>({
        query: (uuid) => {
          return {
            url: `/api/core/machineintelligence/persisted/${uuid}`,
            method: "get",
          };
        },
      }),

      postNewFamilyMember: build.mutation<IFamilyMember, IAddFamilyMemberPayload>({
        query: (data) => {
          return {
            url: `/api/User/addorganizationuser/${productId}`,
            method: "put",
            data,
          };
        },
        invalidatesTags: ["Family"],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(licenseApi.util.invalidateTags(["License"]));
        },
      }),

      deleteFamilyMember: build.mutation({
        query: (email: string) => {
          return {
            url: `/api/user/${email}/${productId}`,
            method: "delete",
          };
        },
        invalidatesTags: (result, error, email) => {
          return [{ type: "Family", email: email }];
        },
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(licenseApi.util.invalidateTags(["License"]));
        },
      }),
    };
  },
});

export const {
  useGetAgentMetricsQuery,
  useGetFamilyQuery,
  useGetMachineInfoQuery,
  useGetOnlineStatusQuery,
  useResendInviteQuery,
  useLazyResendInviteQuery,
  usePostNewFamilyMemberMutation,
  useDeleteFamilyMemberMutation,
} = familyApi;

export { familyApi };

export const selectAllFamilyMembers = (state: RootState) => {
  const licenseData = licenseApi.endpoints.getOrganizationLicenses.select();

  return familyApi.endpoints.getFamily.select(licenseData)(state)?.data ?? [];
};

export const sortFamilyMembers = (
  users: IUser[],
  activatedLicenses: IActivatedLicense[],
  allocatedLicenses: IAllocatedLicense[]
) => {
  const family: IFamilyMember[] = [];
  const userIds: number[] = [];
  const enabledUsers = users.filter(({ enabled }) => enabled);
  const licenses = [...activatedLicenses, ...allocatedLicenses].filter(({ enabled }) => enabled);

  licenses.forEach((license) => {
    const user = enabledUsers.find(({ id }) => id === license.userID);
    const { licenseType, timestamp, uuid, token: licenseToken } = license;

    if (user) {
      const { firstName, lastName, attributes, id } = user;
      const isLicenseActivated = licenseType === "Activated";

      userIds.push(id);
      family.push({
        ...user,
        fullName: `${firstName} ${lastName}`,
        isAdmin: attributes.toLowerCase().includes("admin"),
        isLicenseActivated,
        licenseDate: timestamp,
        licenseToken,
        licenseType,
        licenseUUID: uuid,
        image: defaultImage,
        machineInfo: MachineInfoInitialState,
        userHasSomeActivatedLicense: isLicenseActivated
          ? true
          : activatedLicenses.some((license) => license.userID === id),
      });
    }
  });

  const remainingUsers = enabledUsers.filter(({ id }) => !userIds.includes(id));

  if (remainingUsers.length) {
    remainingUsers.forEach((user) => {
      const { firstName, lastName, attributes, id } = user;

      family.push({
        ...user,
        fullName: `${firstName} ${lastName}`,
        isAdmin: attributes.toLowerCase().includes("admin"),
        isLicenseActivated: false,
        licenseDate: "",
        licenseType: "pending",
        licenseUUID: undefined,
        image: defaultImage,
        isOnline: false,
        machineInfo: MachineInfoInitialState,
        userHasSomeActivatedLicense: activatedLicenses.some((license) => license.userID === id),
      });
    });
  }

  /**
   * We want to return a sorted list of family members as follows:
   * 1. Active licenses should always be first,
   * provisioned and allocated come after, not in any particular order
   * 2. For active licenses, users will either be online or offline,
   * online users are listed first, followed by offline users,
   * then the remaining users either have allocated or provisioned licenses,
   * and their status will show "pending install"
   * 3. Admins will be listed first if they are online with an active license,
   * otherwise, they listed after online users
   */
  return family.sort((a: IFamilyMember, b: IFamilyMember) => {
    const aHasActiveLicense = a.isLicenseActivated;
    const bHasActiveLicense = b.isLicenseActivated;
    const aIsOnline = a.isOnline ?? false;
    const bIsOnline = b.isOnline ?? false;
    const aIsAdmin = a.isAdmin ?? false;
    const bIsAdmin = b.isAdmin ?? false;

    if (aHasActiveLicense > bHasActiveLicense) {
      return -1;
    }
    if (bHasActiveLicense > aHasActiveLicense) {
      return 1;
    }
    if (aIsOnline > bIsOnline) {
      return -1;
    }
    if (bIsOnline > aIsOnline) {
      return 1;
    }
    if (aIsAdmin > bIsAdmin) {
      return -1;
    }
    if (bIsAdmin > aIsAdmin) {
      return 1;
    }

    return 0;
  });
};
