import styled, { keyframes } from "@mui/styled-engine";

const SpinnerContainer = styled("div")`
  display: flex;
  gap: 24px;
  padding: 12px;
`;
const DiamondContainer = styled("div")`
  display: inline-block;
  transform: rotate(45deg);
`;
const SpinnerDiamond = styled("div")`
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: #0087ea;
  height: 12px;
  transform: rotate(-45deg);
  width: 12px;
`;
const spinnerEllipsis1 = keyframes`
  0% {
      transform: scale(0);
  }
  33% {
    transform: scale(0.33);
  }
  66% {
    transform: scale(0.66);
  }
  100% {
    transform: scale(1);
  }
`;
const spinnerEllipsis2 = keyframes`
  0% {
    transform: scale(0.33);
  }
  33% {
    transform: scale(0.66);
  }
  66% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const spinnerEllipsis3 = keyframes`
  0% {
    transform: scale(0.66);
  }
  33% {
    transform: scale(1);
  }
  66% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.33);
  }
`;
const SpinnerDiamond1 = styled(SpinnerDiamond)`
  animation: ${spinnerEllipsis1} 0.75s infinite;
`;
const SpinnerDiamond2 = styled(SpinnerDiamond)`
  animation: ${spinnerEllipsis2} 0.75s infinite;
`;
const SpinnerDiamond3 = styled(SpinnerDiamond)`
  animation: ${spinnerEllipsis3} 0.75s infinite;
`;

export function Spinner() {
  return (
    <SpinnerContainer
      data-qatag="spinner_container"
      id="spinner_container"
      className="spinner-ellipsis"
    >
      <DiamondContainer data-qatag="driver.spinner.DiamondContainer1">
        <SpinnerDiamond1 data-qatag="driver.spinner.SpinnerDiamond1" />
      </DiamondContainer>
      <DiamondContainer data-qatag="driver.spinner.DiamondContainer2">
        <SpinnerDiamond2 data-qatag="driver.spinner.SpinnerDiamond2" />
      </DiamondContainer>
      <DiamondContainer data-qatag="driver.spinner.DiamondContainer3">
        <SpinnerDiamond3 data-qatag="driver.spinner.SpinnerDiamond3" />
      </DiamondContainer>
    </SpinnerContainer>
  );
}
