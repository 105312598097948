import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { RootState } from "app/redux/store";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import {
  IUpdateUserSettingValueMessage,
  IUserSettingModel,
  IUserSettingsMessage,
  ProfileState,
} from "model/messaging/messages/AppSettingsMessages";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";

interface ISettingsState {
  settings: IUserSettingModel[] | null;
  settingsObj: { [key: string]: IUserSettingModel } | null;
  ScheduledScanOptions: {
    DayOfMonth: number;
    DayOfWeek: number;
    Enabled: boolean;
    Period: number;
    ScanHardware: number;
    ScanTime: string;
    ScanType: number;
  } | null;
}

export const fetchUserSettings = createAsyncThunk<IUserSettingsMessage | null, void, { state: RootState }>(
  "userSettings/fetchUserSettings",
  async (_, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.GET_USER_SETTINGS;
    const response = await srhub.SendAsync(message);
    return response.Payload as IUserSettingsMessage;
  }
);

export const updateUserSetting = createAsyncThunk<void, IUpdateUserSettingValueMessage, { state: RootState }>(
  "userSettings/updateUserSetting",
  async (data: IUpdateUserSettingValueMessage) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.UPDATE_USER_SETTING_VALUE;
    message.Payload = data;
    await srhub.SendAsync(message);
  }
);

const initialState: IReducerState<ISettingsState> = {
  data: {
    settingsObj: null,
    settings: null,
    ScheduledScanOptions: null,
  },
  status: {
    [fetchUserSettings.typePrefix]: ReducerStatus.Idle,
    [updateUserSetting.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettingsState: (state) => {
      state.data = initialState.data;
    },
    resetStatus: (state) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Idle;
      state.status[updateUserSetting.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserSettings.pending, (state) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchUserSettings.fulfilled, (state, action) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Succeeded;
      const settingsAsArray = InitUserSettings(action.payload);
      const settingsObj: { [key: string]: IUserSettingModel } = {};

      settingsAsArray?.forEach(({ SettingType, SettingTypeName, SettingValue }) => {
        settingsObj[SettingTypeName] = {
          SettingType,
          SettingTypeName,
          SettingValue,
        };
      });
      state.data.settings = settingsAsArray;
      state.data.settingsObj = settingsObj;
      state.data.ScheduledScanOptions = action?.payload?.ScheduledScanOptions ?? null;
    });
    builder.addCase(fetchUserSettings.rejected, (state) => {
      state.status[fetchUserSettings.typePrefix] = ReducerStatus.Failed;
    });
    builder.addCase(updateUserSetting.pending, (state) => {
      state.status[updateUserSetting.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(updateUserSetting.fulfilled, (state, action) => {
      state.status[updateUserSetting.typePrefix] = ReducerStatus.Succeeded;
      // this is a fire and forget operation so grab the new values from the incoming args
      // and assume we have successfully set those settings
      const incomingSettings = action.meta.arg.UpdatedSettings;
      const models = incomingSettings.map((obj) => BuildUserSettingModel(obj.SettingType, obj.NewSettingValue));

      models.forEach((m) => {
        const settingToUpdate = state.data.settings?.find((s) => s.SettingType === m.SettingType);
        // set the new value, if it exists, otherwise just ignore
        if (settingToUpdate) {
          settingToUpdate.SettingValue = m.SettingValue;
        }
      });
    });
    builder.addCase(updateUserSetting.rejected, (state) => {
      state.status[updateUserSetting.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { resetSettingsState, resetStatus } = settingsSlice.actions;

export const selectGamingProfileSettingValue = (state: RootState) => {
  return state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.GamingState;
  })?.SettingValue as ProfileState;
};

export const selectBrowsingProfileSettingValue = (state: RootState) => {
  return state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.BrowsingState;
  })?.SettingValue as ProfileState;
};

export const selectProductivityProfileSettingValue = (state: RootState) => {
  return state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.ProductivityState;
  })?.SettingValue as ProfileState;
};

export const selectBrowserExtensionSettingValue = (state: RootState) => {
  const chromeEnabled = state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.EnableChromeExtension;
  })?.SettingValue as boolean;
  const edgeEnabled = state.settings.data.settings?.find((setting) => {
    return setting.SettingType === UserSettingTypes.EnableEdgeExtension;
  })?.SettingValue as boolean;

  return chromeEnabled || edgeEnabled;
};

function InitUserSettings(userSettings: IUserSettingsMessage | null): IUserSettingModel[] | null {
  try {
    if (userSettings === null) return null;
    const settings: IUserSettingModel[] = [];

    settings.push(
      BuildUserSettingModel(UserSettingTypes.DownloadFolder, userSettings.DriverDownloadSettings.DownloadFolder)
    );
    settings.push(BuildUserSettingModel(UserSettingTypes.EnableAlerts, userSettings.NotificationSettings.EnableAlerts));
    settings.push(
      BuildUserSettingModel(UserSettingTypes.IncludeSystemDevices, userSettings.ScanOptions.IncludeSystemDevices)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.IncludeUnpluggedDevices, userSettings.ScanOptions.IncludeUnpluggedDevices)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.MaximumWeeklyAlerts, userSettings.NotificationSettings.MaximumWeeklyAlerts)
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.MaxSimultaneousDownloads,
        userSettings.DriverDownloadSettings.MaxSimultaneousDownloads
      )
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.UseWindowsUpdateScanner, userSettings.ScanOptions.UseWindowsUpdateScanner)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.ScheduledScanPeriod, userSettings.ScheduledScanOptions.Period)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.ScheduledScanMonth, userSettings.ScheduledScanOptions.DayOfMonth)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.ScheduledScanDay, userSettings.ScheduledScanOptions.DayOfWeek)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.ScheduledScanType, userSettings.ScheduledScanOptions.ScanType)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.ScheduledScanTime, userSettings.ScheduledScanOptions.ScanTime)
    );
    settings.push(
      BuildUserSettingModel(UserSettingTypes.EnableScheduledScans, userSettings.ScheduledScanOptions.Enabled)
    );
    settings.push(BuildUserSettingModel(UserSettingTypes.BrowsingState, userSettings.AOProfileSettings.BrowsingState));
    settings.push(BuildUserSettingModel(UserSettingTypes.GamingState, userSettings.AOProfileSettings.GamingState));
    settings.push(
      BuildUserSettingModel(UserSettingTypes.ProductivityState, userSettings.AOProfileSettings.ProductivityState)
    );
    settings.push(BuildUserSettingModel(UserSettingTypes.StartWithWindows, userSettings.StartWithWindows));
    settings.push(BuildUserSettingModel(UserSettingTypes.EnableDeceptorServices, userSettings.EnableDeceptorServices));
    settings.push(
      BuildUserSettingModel(UserSettingTypes.EnableDnsProtector, userSettings.DnsProtectorSettings.EnableDnsProtector)
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.EnableChromeExtension,
        userSettings.BrowserExtensionSettings.EnableChromeExtension
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ForceInstallChromeExtension,
        userSettings.BrowserExtensionSettings.ForceInstallChromeExtension
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.EnableEdgeExtension,
        userSettings.BrowserExtensionSettings.EnableEdgeExtension
      )
    );
    settings.push(
      BuildUserSettingModel(
        UserSettingTypes.ForceInstallEdgeExtension,
        userSettings.BrowserExtensionSettings.ForceInstallEdgeExtension
      )
    );
    settings.push(BuildUserSettingModel(UserSettingTypes.PreferNewUIWindow, userSettings.PreferNewUIWindow));
    return settings;
  } catch (ex) {
    //sentry
  }
  return null;
}

function BuildUserSettingModel(
  SettingType: UserSettingTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SettingValue: any
): IUserSettingModel {
  return {
    SettingType,
    SettingTypeName: UserSettingTypes[SettingType],
    SettingValue,
  };
}

export const selectSettings = (state: RootState) => {
  return state.settings.data.settings;
};

export const selectSettingsFetchStatus = (state: RootState) => {
  return state.settings.status[fetchUserSettings.typePrefix];
};
export const selectSettingsObj = (state: RootState) => {
  return state.settings.data.settingsObj;
};
export const selectScheduledScanOptions = (state: RootState) => {
  return state.settings.data.ScheduledScanOptions;
};

export const selectStatus = (state: RootState) => {
  return state.settings.status;
};

// export const selectUserProfileSettings = (state: RootState) => {
//   return state.settings.data.settings?.filter(
//     (setting) =>
//       setting.SettingType === UserSettingTypes.BrowsingState ||
//       setting.SettingType === UserSettingTypes.GamingState ||
//       setting.SettingType === UserSettingTypes.ProductivityState
//   );
// };

export const selectIsAppOptimizationActive = (state: RootState) => {
  if (state.settings.data.settings == null) {
    return false;
  }

  const browsingSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.BrowsingState
  );
  const productivitySetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.ProductivityState
  );
  const gamingSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.GamingState
  );

  if (browsingSetting == null || productivitySetting == null || gamingSetting == null) {
    return false;
  }

  // consider app optimizations active if all settings are true
  return (
    (browsingSetting.SettingValue as boolean) &&
    (productivitySetting.SettingValue as boolean) &&
    (gamingSetting.SettingValue as boolean)
  );
};

export const selectIsDnsProtectionActive = (state: RootState) => {
  if (state.settings.data.settings == null) {
    return false;
  }
  const deceptorSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.EnableDnsProtector
  );

  if (deceptorSetting == null) {
    return false;
  }

  return deceptorSetting.SettingValue as boolean;
};

export const selectIsDeceptorBlockingActive = (state: RootState) => {
  if (state.settings.data.settings == null) {
    return false;
  }
  const deceptorSetting = state.settings.data.settings.find(
    (setting) => setting.SettingType === UserSettingTypes.EnableDeceptorServices
  );

  if (deceptorSetting == null) {
    return false;
  }

  return deceptorSetting.SettingValue as boolean;
};

export default settingsSlice.reducer;
