import { useGetAutoInstallQuery } from "api/AutoInstallApi/AutoInstallApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectCurrentUser } from "session/SessionSlice";
import DownloadLander from "./DownloadLander";
import { DownloadError } from "features/downloadPage/DownloadError";
import { getCurrentBrowser, isChromeVersion115OrHigher } from "utils/browserUtils";

export function DownloadLanderContainer() {
  const currentUser = useSelector(selectCurrentUser);

  //get download url
  //skip if we dont have a user, licenses, or the user doesnt have an allocated license (as it will fail)
  const {
    data: downloadUrl,
    isLoading: isDownloadUrlLoading,
    isError: downloadUrlError,
  } = useGetAutoInstallQuery(currentUser?.email, {
    skip: !currentUser,
  });

  const navigate = useNavigate();

  const errorCallback = () => {
    navigate("/");
  };

  const isLoading = isDownloadUrlLoading;

  const hasError = downloadUrlError || !currentUser;

  if (hasError) {
    return (
      <DownloadError
        data-qatag="DownloadError"
        errorCallback={errorCallback}
      />
    );
  }

  const currentBrowser = getCurrentBrowser();

  const isChrome115OrHigher = isChromeVersion115OrHigher();

  return (
    <DownloadLander
      data-qatag="DownloadLander"
      downloadUrl={downloadUrl}
      currentBrowser={currentBrowser}
      isChrome115OrHigher={isChrome115OrHigher}
      isLoading={isLoading}
    />
  );
}
