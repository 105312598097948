import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Container, Typography } from "@mui/material";
import styled from "@mui/styled-engine";

const FooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
`;

const MainLinksContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

const HeavyTypography = styled(Typography)`
  font-weight: 600;
`;

// const LogoutTypography = styled(Typography)`
//   margin-top: 10px;
//   font-weight: 200;
//   font-size: 0.8331rem;
// `;

export type FooterProps = {
  onLogoutClicked: () => void;
  onPrivacyPolicyClicked: () => void;
  onTermsAndConditionsClicked: () => void;
  onUninstallInstructionsClicked: () => void;
};
export function Footer({
  onLogoutClicked,
  onPrivacyPolicyClicked,
  onTermsAndConditionsClicked,
  onUninstallInstructionsClicked,
}: FooterProps) {
  return (
    <FooterContainer data-qatag="settingsFooter">
      <MainLinksContainer data-qatag="settingsFooterMainLinks">
        <AppButton
          ariaLabel="privacy policy"
          buttonType="button"
          data-qatag="privacyPolicyButton"
          buttonStyle={AppButtonStyles.LinkBlue}
          onClick={onPrivacyPolicyClicked}
        >
          <HeavyTypography
            data-qatag="privacyPolicyButtonMessageContainer"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="privacyPolicyButtonMessage"
              id={`settings.view.body.privacyPolicyButton`}
              defaultMessage="Privacy Policy"
            />
          </HeavyTypography>
        </AppButton>
        <HeavyTypography
          data-qatag="dividerTypography"
          variant="body2"
        >
          <FormattedMessage
            data-qatag="divider"
            id={`settings.divider`}
            defaultMessage="|"
          />
        </HeavyTypography>
        <AppButton
          ariaLabel="uninstall instructions"
          buttonType="button"
          data-qatag="uninstallInstructionsButton"
          buttonStyle={AppButtonStyles.LinkBlue}
          onClick={onUninstallInstructionsClicked}
        >
          <HeavyTypography
            data-qatag="uninstallInstructionsButtonMessageContainer"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="uninstallInstructionsButtonMessage"
              id={`settings.view.body.uninstallInstructionsButton`}
              defaultMessage="Uninstall Instructions"
            />
          </HeavyTypography>
        </AppButton>
        <HeavyTypography
          data-qatag="dividerTypography"
          variant="body2"
        >
          <FormattedMessage
            data-qatag="divider"
            id={`settings.divider`}
            defaultMessage="|"
          />
        </HeavyTypography>
        <AppButton
          ariaLabel="terms and conditions"
          buttonType="button"
          data-qatag="termsAndConditionsButton"
          buttonStyle={AppButtonStyles.LinkBlue}
          onClick={onTermsAndConditionsClicked}
        >
          <HeavyTypography
            data-qatag="termsAndConditionsLabel"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="termsAndConditionsButtonMessage"
              id={`settings.view.body.termsAndConditionsButton`}
              defaultMessage="Terms and Conditions"
            />
          </HeavyTypography>
        </AppButton>
      </MainLinksContainer>
      {/* <AppButton
        ariaLabel="logout"
        buttonType="button"
        data-qatag="logoutButton"
        buttonStyle={AppButtonStyles.LinkGray}
        onClick={onLogoutClicked}
      >
        <LogoutTypography data-qatag="logoutLabel" variant="body2">
          <FormattedMessage
            data-qatag="logoutMessage"
            id={`settings.view.footer.logout`}
            defaultMessage="Logout"
          />
        </LogoutTypography>
      </AppButton> */}
    </FooterContainer>
  );
}
