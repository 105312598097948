import styled from "@mui/styled-engine";
import { StyledTextField } from "./StyledTextField";

const SelectAsTextField = styled(StyledTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: theme.typography.h1.body1,
    "& fieldset": {
      borderWidth: 2,
      borderColor: "#C1C7D0",
    },
  },
  "& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root.Mui-focused": {
    "& fieldset": {
      borderColor: "#0FB4D1",
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    marginRight: 0,
  },
}));

export const StyledSelect = ({
  id,
  name,
  label,
  helperText,
  children,
  ...rest
}: {
  id: string;
  name: string;
  label: string;
  helperText: string | boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}) => {
  return (
    <SelectAsTextField
      data-qatag="Select"
      label={label}
      name={name}
      select
      InputLabelProps={{ shrink: true }}
      helperText={helperText}
      {...rest}
    >
      {children}
    </SelectAsTextField>
  );
};
