import { FormattedMessage } from "react-intl";

interface MachineInfoProps {
  isLicenseActivated: boolean;
  machineDisplayName?: string;
}

export function MachineInfoComponent({ isLicenseActivated, machineDisplayName }: MachineInfoProps) {
  if (!isLicenseActivated) {
    return (
      <FormattedMessage
        id="familyMemberTableRow.pendingInstallText"
        data-qatag="familyMemberTableRow.pendingInstallText"
        defaultMessage="Pending Install"
      />
    );
  }

  if (machineDisplayName && machineDisplayName !== "CustomPC") {
    return <>{machineDisplayName}</>;
  }

  return (
    <>
      <FormattedMessage
        id="familyMemberTableRow.CustomPCText"
        data-qatag="familyMemberTableRow.CustomPCText"
        defaultMessage="Custom PC"
      />
    </>
  );
}
