import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { fetchUserSettings, selectSettings, updateUserSetting } from "features/settings/SettingsSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeceptorProtection } from "./DeceptorProtection";
import {
  selectDeceptorsBlocked,
  selectDeceptorsFound,
  selectAllowedSoftware,
  fetchAllowedSoftware,
  fetchDeceptorsBlocked,
  fetchDeceptorsFound,
  IDeceptorInfo,
} from "./DeceptorProtectionSlice";
import { useNavigate } from "react-router";
import { Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

export function DeceptorProtectionContainer() {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  //The status will help to show the functionality of the allow button
  const blocked = useSelector(selectDeceptorsBlocked);
  const found = useSelector(selectDeceptorsFound);
  const allowed = useSelector(selectAllowedSoftware);
  const settings = useSelector(selectSettings);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const protectionEnabled = settings?.find(
    (x) => x.SettingType === UserSettingTypes.EnableDeceptorServices
  )?.SettingValue;

  useEffect(() => {
    dispatch(fetchDeceptorsBlocked());
    dispatch(fetchDeceptorsFound());
    dispatch(fetchAllowedSoftware());
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const switchHandler = (isOn: boolean) => {
    setShowLoader(true);
    dispatch(
      updateUserSetting({
        UpdatedSettings: [
          {
            SettingType: UserSettingTypes.EnableDeceptorServices,
            SettingTypeName: UserSettingTypes[UserSettingTypes.EnableDeceptorServices],
            NewSettingValue: isOn,
            CurrentSettingValue: protectionEnabled,
          },
        ],
      })
    );
    // TODO: Remove this timeout and replace with a proper loader when the agent is capable of returning the correct response
    setTimeout(() => {
      setShowLoader(false);
      dispatch(fetchUserSettings());
    }, 4000);
  };

  const detailsClickHandler = (deceptor: IDeceptorInfo) => {
    navigate(`/deceptorprotection/deceptordetails?id=${encodeURI(deceptor.ListId)}`);
  };

  return (
    <>
      <DeceptorProtection
        data-qatag="DeceptorProtection"
        blocked={blocked}
        found={found}
        allowed={allowed}
        settings={settings}
        switchHandler={switchHandler}
        detailsClickHandler={detailsClickHandler}
      />
      {/* Spinner*/}
      <Spinner
        data-qatag="loadingSpinner"
        isActive={showLoader}
        text={formatMessage({
          id: `loadingSpinner`,
          defaultMessage: "Loading...",
        })}
      />
    </>
  );
}
