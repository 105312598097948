import { axiosBaseQuery } from "api/axiosBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ILicenseData } from "model/license/ILicenseData";
import { familyApi } from "api/FamilyApi/FamilyApi";

//#region API

//Retrieve REACT_APP_PRODUCTID from .env file
export declare const productId: number;

type AddAdditionalPCArgs = {
  ProductId: number;
  Email: string;
};

type DisableAdminLicenseArgs = {
  LicenseToken: string;
  ProductId?: number;
};

type AddAdditionalPCResponse = unknown;

type ReallocateLicensePayload = {
  licenseToken: string;
  email: string;
};

export const licenseApi = createApi({
  reducerPath: "licenseApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/License/",
  }),
  tagTypes: ["License"],
  endpoints(build) {
    return {
      getOrganizationLicenses: build.query<ILicenseData, void>({
        query: () => {
          return {
            url: `GetOrganizationLicenses/${productId}`,
            method: "get",
          };
        },
        providesTags: ["License"],
      }),
      addAdditionalPC: build.mutation<AddAdditionalPCResponse, AddAdditionalPCArgs>({
        query: (data) => {
          return {
            url: `AllocateLicense`,
            method: "post",
            data,
          };
        },
        invalidatesTags: ["License"],
      }),
      disableAdminLicense: build.mutation<void, DisableAdminLicenseArgs>({
        query: (data: DisableAdminLicenseArgs) => {
          data.ProductId = productId;
          return {
            url: `DisableAdminLicense`,
            method: "post",
            data,
          };
        },
        invalidatesTags: ["License"],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(familyApi.util.invalidateTags(["Family"]));
        },
      }),
      reallocateLicense: build.query<undefined, ReallocateLicensePayload>({
        query: (data) => {
          return {
            url: "reallocateLicense",
            method: "post",
            data,
          };
        },
      }),
    };
  },
});

export const {
  useGetOrganizationLicensesQuery,
  useAddAdditionalPCMutation,
  useDisableAdminLicenseMutation,
  useLazyReallocateLicenseQuery,
} = licenseApi;

//#endregion API
