import { Container, Typography } from "@mui/material";
import styled from "@mui/styled-engine";

export const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 100%;
  :not(:last-child) {
    border-bottom: 2px solid lightgray;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px 0;
  }
`;

export const BlueHeaderText = styled(Typography)`
  color: #007dba;
  margin-top: 32px;
  text-align: center;
`;

export const CenteredParagraphText = styled(Typography)`
  font-size: 1rem;
  margin: 20px;
  max-width: 500px;
  text-align: center;

  &:last-of-type {
    margin-bottom: 32px;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.125rem;
  }
`;
