import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux/store";
import RESTGatewayAPI from "api/gatewayAPI";
import { IManufacturers, IProductLine, IModel, IUpdateIntelligenceResponse } from "./types";
import { AgentServiceApi } from "api/AgentServiceApi";
import { IntelligenceTypes, MatchTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { selectCurrentUser } from "session/SessionSlice";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { ISupportCasePayload } from "model/messaging/payloads/ISupportCasePayload";
//import { selectMachineIntelligence } from "app/Agent/AgentSlice";
declare const productId: number;

interface MachinePickerState {
  /* Computer model */
  computerModel: string;
  /* Select Your Machine Brand */
  machineBrand: IManufacturers[];
  /* Select Your Product Line */
  productLine: IProductLine[];
  /* Select Your Product Model */
  productModel: IModel[];
  /* Select Your Motherboard */
  motherboards: IManufacturers[];
  /* Select your Motherboard Families */
  motherboardFamilies: IProductLine[];
  /* Select Your Product Model */
  motherboardModels: IModel[];
  /* Update machine Intelligence Response */
  //updateMachineIntelligence: IUpdateIntelligenceResponse;
}

export const fetchManufacturers = createAsyncThunk<
  IManufacturers[],
  void,
  {
    state: RootState;
  }
>("machineIntelligence/manufacturers", async (_, thunkApi) => {
  try {
    const url = "/api/core/machineintelligence/oemmanufacturers";

    const apiResponse = await RESTGatewayAPI.get(url);
    return apiResponse?.data?.manufacturers;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch manufactures : ${error}`);
  }
});

export const fetchProductLine = createAsyncThunk<
  IProductLine[],
  string,
  {
    state: RootState;
  }
>("machineIntelligence/productLine", async (manufacturerId, thunkApi) => {
  try {
    const url = `/api/core/machineintelligence/oemfamilies/${manufacturerId}`;

    const apiResponse = await RESTGatewayAPI.get(url);
    return apiResponse?.data?.families;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch manufactures : ${error}`);
  }
});

export const fetchProductModel = createAsyncThunk<
  IProductLine[],
  {
    manufacturerId: string;
    familyId: string;
  },
  {
    state: RootState;
  }
>("machineIntelligence/models", async ({ manufacturerId, familyId }, thunkApi) => {
  try {
    const url = `/api/core/machineintelligence/oemfamilymodels/${manufacturerId}/${familyId}`;

    const apiResponse = await RESTGatewayAPI.get(url);
    return apiResponse?.data?.models;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch manufactures : ${error}`);
  }
});

// Motherboard Intelligence

export const fetchMotherboards = createAsyncThunk<
  IManufacturers[],
  void,
  {
    state: RootState;
  }
>("machineIntelligence/motherboards", async (_, thunkApi) => {
  try {
    const url = "/api/core/machineintelligence/mbmanufacturers";

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data?.manufacturers;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch manufactures : ${error}`);
  }
});

export const fetchMotherboardFamilies = createAsyncThunk<
  IProductLine[],
  string,
  {
    state: RootState;
  }
>("machineIntelligence/motherboardFamily", async (motherboardId, thunkApi) => {
  try {
    const url = `/api/core/machineintelligence/mbfamilies/${motherboardId}`;

    const apiResponse = await RESTGatewayAPI.get(url);
    return apiResponse?.data?.families;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch families : ${error}`);
  }
});

export const fetchMotherboardModel = createAsyncThunk<
  IModel[],
  {
    motherboardId: string;
    familyId: string;
  },
  {
    state: RootState;
  }
>("machineIntelligence/motherboardModels", async ({ motherboardId, familyId }, thunkApi) => {
  try {
    const url = `/api/core/machineintelligence/mbfamilymodels/${motherboardId}/${familyId}`;

    const apiResponse = await RESTGatewayAPI.get(url);
    return apiResponse?.data?.models;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch motherboard models : ${error}`);
  }
});

export const updateMachineIntelligence = createAsyncThunk<
  IUpdateIntelligenceResponse,
  {
    intelligenceType: IntelligenceTypes;
    manufacturerID: number;
    manufacturer: string;
    familyID: number;
    family: string;
    seriesIsLaptop: boolean;
    modelID: number;
    model: string;
    modelIsLaptop: boolean;
    matchType: MatchTypes;
  },
  {
    state: RootState;
  }
>(
  "machineIntelligence/updateMachineIntelligence",
  async (
    {
      intelligenceType,
      manufacturerID,
      manufacturer,
      familyID,
      family,
      seriesIsLaptop = false,
      modelID,
      model,
      modelIsLaptop = false,
      matchType,
    },
    thunkApi
  ) => {
    try {
      const agentService = new AgentServiceApi();

      const apiResponse = await agentService.UpdateMachineIntelligence({
        ManufacturerID: manufacturerID,
        ManufacturerName: manufacturer,
        SeriesID: familyID,
        SeriesName: family,
        ModelID: modelID,
        ModelName: model,
        IntelligenceType: intelligenceType,
        ModelIsLaptop: modelIsLaptop,
        SeriesIsLaptop: seriesIsLaptop,
        MatchType: matchType,
      });
      return apiResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to update machine intelligence : ${error}`);
    }
  }
);

export const submitMachineIntelligence = createAsyncThunk<
  string,
  void,
  {
    state: RootState;
  }
>("machineIntelligence/submitMachineIntelligence", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const user = selectCurrentUser(state);

  //const currentMI = selectMachineIntelligence(state);
  const currentMI = state.agent.data.machineIntelligence;

  const description = JSON.stringify(currentMI);

  const url = `/api/salesforce/cases`;
  const payload = {
    Origin: "DSOne Web UI",
    Reason: "Machine Intelligence",
    Description: description,
    Priority: "Normal",
    Status: "New",
    Subject: "Driver Support One - Machine Info Not Found",
    Type: "Machine Intelligence",
    SuppliedEmail: user?.email,
    SuppliedName: `${user?.firstName} ${user?.lastName}`,
    Product_Name__c: "DSOneWeb",
    Product_Id__c: productId,
  } as ISupportCasePayload;

  try {
    const response = await (await RESTGatewayAPI.post(url, payload)).data;
    return response.id as string;
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: user?.email,
      submitPayload: payload,
      error: e,
    });
  }
});

const initialState: IReducerState<MachinePickerState> = {
  data: {
    computerModel: "",
    machineBrand: [],
    productLine: [],
    productModel: [],
    motherboards: [],
    motherboardFamilies: [],
    motherboardModels: [],
    // updateMachineIntelligence: {},
  },
  status: {
    // Dependency components individually
    [fetchManufacturers.typePrefix]: ReducerStatus.Idle,
    [fetchProductLine.typePrefix]: ReducerStatus.Idle,
    [fetchProductModel.typePrefix]: ReducerStatus.Idle,
    [fetchMotherboards.typePrefix]: ReducerStatus.Idle,
    [fetchMotherboardFamilies.typePrefix]: ReducerStatus.Idle,
    [fetchMotherboardModel.typePrefix]: ReducerStatus.Idle,
    // Any dependency is loading | global to avoid request individually each dependency
    dependencyLoading: ReducerStatus.Idle,
    // Process to update the machine Intelligence
    [updateMachineIntelligence.typePrefix]: ReducerStatus.Idle,
    [submitMachineIntelligence.typePrefix]: ReducerStatus.Idle,
  },
  error: "",
};

const machinePickerSlice = createSlice({
  name: "familyMembers",
  initialState,
  reducers: {
    resetMachinePickerState: (state) => {
      state.data = initialState.data;
    },
    setComputerModel(state, action: PayloadAction<string>) {
      state.data.computerModel = action.payload;
    },
    resetUpdate(state) {
      state.status[updateMachineIntelligence.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    // Manufacturers
    builder.addCase(fetchManufacturers.pending, (state) => {
      state.status[fetchManufacturers.typePrefix] = ReducerStatus.Loading;
      state.status.dependencyLoading = ReducerStatus.Loading;
    });
    builder.addCase(fetchManufacturers.fulfilled, (state, action) => {
      state.data.machineBrand = action.payload;
      state.status[fetchManufacturers.typePrefix] = ReducerStatus.Succeeded;
      state.status.dependencyLoading = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchManufacturers.rejected, (state) => {
      state.status[fetchManufacturers.typePrefix] = ReducerStatus.Failed;
      state.status.dependencyLoading = ReducerStatus.Failed;
    });
    // ProductLine
    builder.addCase(fetchProductLine.pending, (state) => {
      state.status[fetchProductLine.typePrefix] = ReducerStatus.Loading;
      state.status.dependencyLoading = ReducerStatus.Loading;
    });
    builder.addCase(fetchProductLine.fulfilled, (state, action) => {
      state.data.productLine = action.payload;
      state.status[fetchProductLine.typePrefix] = ReducerStatus.Succeeded;
      state.status.dependencyLoading = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchProductLine.rejected, (state) => {
      state.status[fetchProductLine.typePrefix] = ReducerStatus.Failed;
      state.status.dependencyLoading = ReducerStatus.Failed;
    });
    // ProductModel
    builder.addCase(fetchProductModel.pending, (state) => {
      state.status[fetchProductModel.typePrefix] = ReducerStatus.Loading;
      state.status.dependencyLoading = ReducerStatus.Loading;
    });
    builder.addCase(fetchProductModel.fulfilled, (state, action) => {
      state.data.productModel = action.payload;
      state.status[fetchProductModel.typePrefix] = ReducerStatus.Succeeded;
      state.status.dependencyLoading = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchProductModel.rejected, (state) => {
      state.status[fetchProductModel.typePrefix] = ReducerStatus.Failed;
      state.status.dependencyLoading = ReducerStatus.Failed;
    });
    // Motherboards
    builder.addCase(fetchMotherboards.pending, (state) => {
      state.status[fetchMotherboards.typePrefix] = ReducerStatus.Loading;
      state.status.dependencyLoading = ReducerStatus.Loading;
    });
    builder.addCase(fetchMotherboards.fulfilled, (state, action) => {
      state.data.motherboards = action.payload;
      state.status[fetchMotherboards.typePrefix] = ReducerStatus.Succeeded;
      state.status.dependencyLoading = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchMotherboards.rejected, (state) => {
      state.status[fetchMotherboards.typePrefix] = ReducerStatus.Failed;
      state.status.dependencyLoading = ReducerStatus.Failed;
    });
    // Motherboard Families
    builder.addCase(fetchMotherboardFamilies.pending, (state) => {
      state.status[fetchMotherboardFamilies.typePrefix] = ReducerStatus.Loading;
      state.status.dependencyLoading = ReducerStatus.Loading;
    });
    builder.addCase(fetchMotherboardFamilies.fulfilled, (state, action) => {
      state.data.motherboardFamilies = action.payload;
      state.status[fetchMotherboardFamilies.typePrefix] = ReducerStatus.Succeeded;
      state.status.dependencyLoading = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchMotherboardFamilies.rejected, (state) => {
      state.status[fetchMotherboardFamilies.typePrefix] = ReducerStatus.Failed;
      state.status.dependencyLoading = ReducerStatus.Failed;
    });
    // Motherboard Model
    builder.addCase(fetchMotherboardModel.pending, (state) => {
      state.status[fetchMotherboardModel.typePrefix] = ReducerStatus.Loading;
      state.status.dependencyLoading = ReducerStatus.Loading;
    });
    builder.addCase(fetchMotherboardModel.fulfilled, (state, action) => {
      state.data.motherboardModels = action.payload;
      state.status[fetchMotherboardModel.typePrefix] = ReducerStatus.Succeeded;
      state.status.dependencyLoading = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchMotherboardModel.rejected, (state) => {
      state.status[fetchMotherboardModel.typePrefix] = ReducerStatus.Failed;
      state.status.dependencyLoading = ReducerStatus.Failed;
    });
    // Update Machine Intelligence
    builder.addCase(updateMachineIntelligence.pending, (state) => {
      state.status[updateMachineIntelligence.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(updateMachineIntelligence.fulfilled, (state, action) => {
      // state.data.updateMachineIntelligence = action.payload;
      state.status[updateMachineIntelligence.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(updateMachineIntelligence.rejected, (state) => {
      state.status[updateMachineIntelligence.typePrefix] = ReducerStatus.Failed;
    });
    //submit machine intelligence
    builder.addCase(submitMachineIntelligence.pending, (state) => {
      state.status[submitMachineIntelligence.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(submitMachineIntelligence.fulfilled, (state) => {
      state.status[submitMachineIntelligence.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(submitMachineIntelligence.rejected, (state) => {
      state.status[submitMachineIntelligence.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const getComputerModel = (state: RootState) => state.machinePicker.data.computerModel;
export const getMachineBrand = (state: RootState) => state.machinePicker.data.machineBrand;
export const getProductLine = (state: RootState) => state.machinePicker.data.productLine;
export const getProductModel = (state: RootState) => state.machinePicker.data.productModel;
// Motherboards Intelligence
export const getMotherboards = (state: RootState) => state.machinePicker.data.motherboards;
export const getMotherboardFamilies = (state: RootState) => state.machinePicker.data.motherboardFamilies;
export const getMotherboardModels = (state: RootState) => state.machinePicker.data.motherboardModels;
// export const getIntelligenceResponse = (state: RootState) =>
//   state.machinePicker.data.updateMachineIntelligence;

// Status for loading global
export const getLoadingStatus = (state: RootState) => state.machinePicker.status.dependencyLoading;
export const getUpdateStatus = (state: RootState) => state.machinePicker.status[updateMachineIntelligence.typePrefix];

export const { resetMachinePickerState, setComputerModel, resetUpdate } = machinePickerSlice.actions;
export default machinePickerSlice.reducer;
