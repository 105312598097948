/* eslint-disable no-unused-vars */
export enum UserSettingTypes {
  //The numbers in this enumeration MATTER.  If you are adding anything to this, add it at the end and assign it the next unused number.
  // Scan Options
  UseWindowsUpdateScanner = 0,
  IncludeSystemDevices = 1,
  IncludeUnpluggedDevices = 2,

  // Schedule Scan Options
  ScheduledScanPeriod = 3,
  ScheduledScanDay = 4,
  ScheduledScanMonth = 5,
  ScheduledScanTime = 6,
  ScheduledScanType = 7,
  ScheduledScanCategories = 8,
  EnableScheduledScans = 9,

  // Driver Download Settings
  DownloadFolder = 10,
  MaxSimultaneousDownloads = 11,

  // Notification Settings
  EnableAlerts = 12,
  MaximumWeeklyAlerts = 13,

  // User Profiles
  BrowsingState = 14,
  ProductivityState = 15,
  GamingState = 16,

  // Windows startup options
  StartWithWindows = 17,

  EnableDeceptorServices = 18,
  OnlyDHCPEnabled = 19,
  EnableDnsProtector = 20,

  //PageBoost options
  EnableChromeExtension = 21,
  ForceInstallChromeExtension = 22,
  EnableEdgeExtension = 23,
  ForceInstallEdgeExtension = 24,

  PreferNewUIWindow = 25,
}
