import { axiosBaseQuery } from "api/axiosBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IPaymentMethod } from "model/billing/BillingSummaryModel";

//Retrieve REACT_APP_PRODUCTID from .env file
export declare const productId: number;

export interface IBillingPayloadResponse {
  data: IPaymentMethod;
  error: {
    localErrorCode: string;
    message: string;
    localErrorLocation: string;
    remoteServiceName: null | string;
    remoteErrorCode: null | string;
    remoteErrorString: null | string;
  } | null;
  succeeded: boolean;
}

export const billingApi = createApi({
  reducerPath: "billingApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/Billing/",
  }),
  tagTypes: ["PaymentMethod"],
  endpoints(build) {
    return {
      getBillingSummary: build.query({
        query: () => {
          return {
            url: `summary/${productId}`,
            method: "get",
          };
        },
        transformResponse: (response) => response.data,
      }),
      getPaymentMethods: build.query<IPaymentMethod[], void>({
        query: () => {
          return {
            url: `paymentmethod`,
            method: "get",
          };
        },
        providesTags: (result, error, user) => {
          return result
            ? result.map((payment: IPaymentMethod) => ({
                type: "PaymentMethod",
                zID: payment.zID,
              }))
            : [];
        },
        transformResponse: (response) => response.data,
      }),
      getPaymentMethodById: build.query({
        query: (id: string) => {
          return {
            url: `paymentmethod/${id}`,
            method: "get",
          };
        },
        transformResponse: (response) => response.data,
      }),
      postNewPaymentMethod: build.mutation<IBillingPayloadResponse, Omit<IBillingPayloadResponse, string>>({
        query: (data: IPaymentMethod) => {
          return {
            url: `paymentmethod`,
            method: "post",
            data,
          };
        },
      }),
      deletePaymentMethod: build.mutation<IPaymentMethod, Omit<IPaymentMethod, string>>({
        query: (id: string) => {
          return {
            url: `paymentmethod/${id}`,
            method: "delete",
          };
        },
        invalidatesTags: ["PaymentMethod"],
      }),
    };
  },
});

export const {
  useGetBillingSummaryQuery,
  useGetPaymentMethodsQuery,
  useGetPaymentMethodByIdQuery,
  usePostNewPaymentMethodMutation,
  useDeletePaymentMethodMutation,
} = billingApi;
