import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import { Modal } from "dsoneweb.designsystem";
import headerImage from "assets/img/fileCleaning/icn-cache-cleanup.svg";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { ICleanUpFileMessage } from "model/messaging/messages/FileCleanupMessages";
import { humanFileSize } from "utils/formatFileSize";
import styled from "@mui/styled-engine";

export interface IFileListDialogProps {
  isOpen: boolean;
  title: string;
  files: ICleanUpFileMessage[];
  closeDialogHandler: () => void;
}

const Dialog = styled(Modal)`
  border-radius: 8px;
  padding: 14px 24px 24px;
  max-width: 660px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 14px 8px 24px;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
`;

const HeaderImage = styled("img")`
  width: 84px;
  height: 73px;
  margin-bottom: 9px;
`;

const FileList = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 38px;
  max-height: 300px;
  overflow: auto;
  margin-top: 15px;
`;

const FileListEntry = styled("div")`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  text-align: start;

  &:nth-child(odd) {
    background-color: #f8f8f8;
    border-radius: 12px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 15px 13px;
  }
`;

const BackButton = styled(AppButton)`
  width: 240px;
  height: 44px;
  margin-bottom: 24px;
  font-size: 1rem;
  line-height: 17px;
`;

const ButtonText = styled(Typography)`
  font-size: 1rem;
  line-height: 100%;
`;

const FileName = styled(Typography)`
  color: #535a6a;
  max-width: 75%;
  word-wrap: break-word;

  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 85%;
  }
`;

const FileSize = styled(Typography)`
  color: #0a9a1a;
  font-weight: bold;
  font-size: 1rem;
  width: 90px;
  text-align: right;
  white-space: nowrap;
`;

export const FileListDialog: React.FC<IFileListDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.fileListDialog";

  return (
    <Dialog
      data-qatag="fileListDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
    >
      <Content data-qatag="fileListDialogContent">
        <HeaderImage
          data-qatag="headerImage"
          src={headerImage}
          alt="Cleanup File List header image"
        />
        <Typography
          data-qatag="fileListDialogTitleTypography"
          variant="h4"
        >
          {props.title}
        </Typography>
        <Box
          data-qatag="fileListDialogDescriptionBox"
          mt={1}
          mb={2}
        >
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage: "The following will be cleaned",
          })}
        </Box>
        <FileList data-qatag="fileList">
          {props.files.map((file, index) => {
            return (
              // eslint-disable-next-line solveiq/no-empty-data-qatag
              <FileListEntry
                data-qatag={"fileListEntry_" + index.toString()}
                key={`file-list-entry-${file.FileName}-${file.Length}-${index}`}
              >
                {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                <FileName data-qatag={"fileListEntry_" + index.toString()}>{file.FilePathName}</FileName>
                <FileSize data-qatag="FileSize">{humanFileSize(file.Length)}</FileSize>
              </FileListEntry>
            );
          })}
        </FileList>
        <BackButton
          ariaLabel="back to analysis"
          buttonType="button"
          data-qatag="cleanButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={props.closeDialogHandler}
        >
          <ButtonText
            data-qatag="buttonText"
            variant="body2"
          >
            {formatMessage({
              id: `${prefix}.buttonText`,
              defaultMessage: "Back To Analysis",
            })}
          </ButtonText>
        </BackButton>
      </Content>
    </Dialog>
  );
};
