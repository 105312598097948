import { Spinner } from "dsoneweb.designsystem";
import {
  fetchSystemOptimizationScan,
  fetchSystemOptimizationScanStatus,
  selectAllOptimizations,
  selectSystemIssueCount,
} from "features/scan/ScanSlice";
import { ReducerStatus } from "model/IReducerState";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WindowsEnhancements, { IWindowsEnhancementsProps } from "./WindowsEnhancements";

export function WindowsEnhancementsContainer() {
  const dispatch = useDispatch();
  const optimizations = useSelector(selectAllOptimizations);
  const issueCount = useSelector(selectSystemIssueCount);
  const fetchStatus = useSelector(fetchSystemOptimizationScanStatus);

  useEffect(() => {
    dispatch(fetchSystemOptimizationScan());
  }, [dispatch]);

  const props: IWindowsEnhancementsProps = { issueCount, optimizations };
  return (
    <>
      <Spinner
        data-qatag="loadingModal"
        text="Loading..."
        isActive={fetchStatus === ReducerStatus.Loading}
      />
      <WindowsEnhancements
        data-qatag="WindowsEnhancements"
        {...props}
      />
    </>
  );
}
