import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import { Modal } from "dsoneweb.designsystem";
import headerImage from "assets/img/fileCleaning/icn-pc-cleanup.svg";
import styled from "@mui/styled-engine";

export interface ICleaningUpDialogProps {
  isOpen: boolean;
  closeDialogHandler: () => void;
}

const Container = styled(Modal)`
  border-radius: 8px;
  width: 330px;
  height: 240px;

  > div > button {
    display: none;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 427px;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const Image = styled("img")`
  width: 93px;
  height: 66px;
  margin-bottom: 12px;
`;

const TextContainer = styled(Box)`
  display: flex;
  align-items: flex-end;
`;

const Text = styled(Typography)`
  color: #535a6a;
  font-size: 1.313rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.21px;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    letter-spacing: -0.24px;
  }
`;

const Ellipsis = styled("span")`
  display: inline-block;
  width: 1em;
`;

export const CleaningUpDialog: React.FC<ICleaningUpDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.cleaningUpDialog";

  const [ellipsis, setEllipsis] = useState<string>("");

  useEffect(() => {
    const updateEllipsis = () => {
      let newVal = ellipsis + ".";

      if (newVal.length > 3) {
        newVal = "";
      }

      setEllipsis(newVal);
    };

    const interval = setInterval(updateEllipsis, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ellipsis, setEllipsis]);

  return (
    <Container
      data-qatag="cleaningUpDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
    >
      <Content data-qatag="cleaningUpDialogContent">
        <Image
          data-qatag="headerImage"
          src={headerImage}
          alt="cleaning up icon"
        />
        <TextContainer data-qatag="cleaningUpDialogTitleBox">
          <Text
            data-qatag="customText"
            variant="h4"
          >
            {formatMessage({
              id: `${prefix}.title`,
              defaultMessage: "Cleaning Up Your PC",
            })}
          </Text>
          <Ellipsis data-qatag="ellipsis">{ellipsis}</Ellipsis>
        </TextContainer>
      </Content>
    </Container>
  );
};
