import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import emptyImage from "assets/img/deceptor/icn-no-deceptors-found.png";
import { IDeceptorInfo } from "./DeceptorProtectionSlice";
import styled from "@mui/styled-engine";

export interface IDeceptorTabProps {
  itemImage: string;
  description: JSX.Element;
  deceptors: IDeceptorInfo[];
  showLastBlocked: boolean;
  emptyTitle: string;
  emptyDescription: string;
  onDetailsClick: (deceptor: IDeceptorInfo) => void;
}

//#region component styles

const TabContainer = styled(Box)`
  height: 100%;
  width: 100%;
  margin-top: 20px;
`;

const TitleMessage = styled(Box)`
  max-width: 324px;
  align-self: center;
  flex-basis: 100%;
  text-align: center;
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
  margin: 0 auto;
`;

const DeceptorsContainer = styled(Box)`
  width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 24px auto 0;
    max-width: 852px;
    justify-content: flex-start;
    gap: 20px;
  }
`;

const DeceptorEntryContainer = styled("div")`
  max-width: 258px;
  height: 240px;
  flex-basis: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: 50%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: 33.33%;
  }
`;

const DeceptorEntryImage = styled("img")`
  flex-basis: 100%;
  margin-top: 38px;
  width: 44px;
  height: 44px;
`;

const DeceptorEntryTitle = styled("div")`
  flex-basis: 100%;
  margin-top: 12px;
  width: 100%;
  text-align: center;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0px;
  color: #535a6a;
`;

const StyledAppButton = styled(AppButton)`
  flex-basis: 100%;
  margin: 20px auto 48px;
  max-width: 180px;
  height: 44px;
`;

const EmptyTabContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 44px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 80px;
  }
`;

const EmptyImage = styled("img")`
  width: 84px;
  height: 80px;
`;

const EmptyTitle = styled("div")`
  font: normal normal 500 1.125rem Roboto;
  color: #0c83bd;
  margin-top: 16px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 500 1.3125rem Roboto;
  }
`;

const EmptyDescription = styled("div")`
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
  margin-top: 8px;
`;

//#endregion component styles

export function DeceptorTab({
  itemImage,
  description,
  deceptors,
  showLastBlocked,
  emptyTitle,
  emptyDescription,
  onDetailsClick,
}: IDeceptorTabProps) {
  return (
    <TabContainer
      data-qatag="tabContainer"
      mb={6}
      justifyContent="space-between"
      flexWrap="wrap"
    >
      {deceptors.length ? (
        <>
          <TitleMessage data-qatag="DescriptionMessage">{description}</TitleMessage>
          <DeceptorsContainer data-qatag="DeceptorsContainer">
            {deceptors.map((deceptor: IDeceptorInfo, index: number) => (
              <DeceptorEntryContainer
                data-qatag="deceptorEntryContainer"
                key={index}
              >
                <DeceptorEntryImage
                  data-qatag="deceptorEntryImage"
                  src={itemImage}
                  alt={deceptor.Name}
                />
                <DeceptorEntryTitle data-qatag="deceptorEntryTitle">{deceptor.Name}</DeceptorEntryTitle>
                <StyledAppButton
                  ariaLabel="View details"
                  buttonType="button"
                  data-qatag="deceptorsBlockedEntryDetailsButton"
                  buttonStyle={AppButtonStyles.Green}
                  onClick={() => {
                    onDetailsClick(deceptor);
                  }}
                >
                  <FormattedMessage
                    data-qatag="deceptorsBlockedEntryDetailsButtonMessage"
                    id="deceptorProtection.view.viewDetails"
                    defaultMessage="View Details"
                  />
                </StyledAppButton>
              </DeceptorEntryContainer>
            ))}
          </DeceptorsContainer>
        </>
      ) : (
        <EmptyTabContent data-qatag="emptyTabContent">
          <EmptyImage
            data-qatag="emptyImage"
            src={emptyImage}
            alt={emptyTitle}
          />
          <EmptyTitle data-qatag="emptyTitle">{emptyTitle}</EmptyTitle>
          <EmptyDescription data-qatag="emptyDescription">{emptyDescription}</EmptyDescription>
        </EmptyTabContent>
      )}
    </TabContainer>
  );
}
