import { SectionContainer } from "../components/StyledComponents";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const StyledButton = styled(AppButton)`
  align-self: flex-end;

  &:hover {
    text-decoration: underline;
  }
`;

export interface ICancelSubscriptionSectionProps {
  onClickHandler: () => void;
}

export const CancelSubscriptionSection = ({ onClickHandler }: ICancelSubscriptionSectionProps) => {
  const testTagPrefix = `myAccount.cancelService`;

  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <SectionContainer data-qatag={testTagPrefix}>
      <StyledButton
        ariaLabel={"Cancel Service"}
        data-qatag="cancelServiceButton"
        buttonStyle={AppButtonStyles.LinkBlue}
        buttonType={"button"}
        onClick={onClickHandler}
      >
        <FormattedMessage
          data-qatag="cancelServiceMessage"
          id={`myAccount.cancelService`}
          defaultMessage={"Cancel Service"}
        />
      </StyledButton>
    </SectionContainer>
  );
};
