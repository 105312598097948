import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IReducerState } from "model/IReducerState";
import { RootState, store } from "./store";
import { initAgentCommunication } from "app/Agent/AgentSlice";

declare const productId: number;

export const changeAgentConnection = createAsyncThunk<void, string, { state: RootState }>(
  "agent/changeAgentConnection",
  async (uuid, thunkApi) => {
    store.dispatch(setCurrentUuid(uuid));

    await store.dispatch(initAgentCommunication(uuid));
  }
);

export interface IApplicationState {
  currentUuid: string | null;
  isRemoteConnection: boolean;
  productId: number;
}

const initialState: IReducerState<IApplicationState> = {
  data: {
    currentUuid: null,
    isRemoteConnection: false,
    productId: productId,
  },
  status: {},
  error: undefined,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setCurrentUuid: (state, action: PayloadAction<string>) => {
      state.data.currentUuid = action.payload;
    },
    setIsRemoteConnection: (state, action: PayloadAction<boolean>) => {
      state.data.isRemoteConnection = action.payload;
    },
  },
});

export const selectProductId = (state: RootState) => state.application.data.productId;

export const selectCurrentUuid = (state: RootState) => state.application.data.currentUuid;

export const selectIsRemoteConnection = (state: RootState) => state.application.data.isRemoteConnection;

export const { setCurrentUuid, setIsRemoteConnection } = applicationSlice.actions;

export default applicationSlice.reducer;
