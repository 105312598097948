import styled from "@mui/styled-engine";

const InvisOverlay = styled("div")`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const onClick = () => {
  window.location.reload();
};

export function TransparentDisconnect() {
  return (
    <InvisOverlay
      onClick={onClick}
      data-qatag="TransparentDisconnect"
    />
  );
}
