import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Divider, styled } from "@mui/material";
import { FormattedMessage } from "react-intl";

export type ExpiredLicenseAlertProps = {
  isAdmin: boolean;
  onApprove: () => void;
  onClose: () => void;
};

// #region styled-components
const NotificationContainer = styled(Box)`
  align-items: center;
  background-color: #002e44;
  color: rgba(255, 255, 255, 0.87);
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const StyledDivider = styled(Divider)`
  border-color: rgba(255, 255, 255, 0.87);
  margin: ${({ theme }) => theme.spacing(0, 3)};
  height: 18px;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.warning.light};
  font-size: 0.875rem;
  min-width: 0;
  padding: 0;
  text-decoration: underline;
`;
// #endregion styled-components

export function ExpiredLicenseAlert({ isAdmin, onApprove, onClose }: ExpiredLicenseAlertProps) {
  return (
    <NotificationContainer data-qatag="NotificationContainer">
      <ErrorOutlineIcon
        data-qatag="licenseAlert.icon"
        fontSize="small"
        sx={{ color: "warning.light", marginRight: 1 }}
      />
      {/* eslint-disable-next-line solveiq/data-qatag */}
      <FormattedMessage
        id="licenseExpired.message"
        defaultMessage="License Expired"
      />

      {isAdmin ? (
        <>
          {/* eslint-disable-next-line solveiq/data-qatag */}
          <StyledDivider orientation="vertical" />
          <StyledButton
            data-qatag="licenseAlert.renewLicenseButton"
            onClick={onApprove}
            variant="text"
          >
            {/* eslint-disable-next-line solveiq/data-qatag */}
            <FormattedMessage
              id="resume.service"
              defaultMessage="Renew License"
            />
          </StyledButton>
        </>
      ) : null}
    </NotificationContainer>
  );
}

export default ExpiredLicenseAlert;
