import { IServiceMessage, SignalRPeerMessageHub, WSMessageType } from "ui.common";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { IUpdateMachineIntelligencePayload } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";

export class AgentServiceApi {
  protected hub: SignalRPeerMessageHub;

  constructor() {
    const hub = getSignalRHub();
    this.hub = hub.getInstance();
  }

  public async UpdateMachineIntelligence(payload: IUpdateMachineIntelligencePayload) {
    const message: IServiceMessage = {
      MessageToken: "",
      MessageType: WSMessageType.UPDATE_MACHINE_INTELLIGENCE,
      Payload: payload,
    };

    return await this.hub.SendAsync(message);
  }
}
