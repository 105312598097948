import { FormattedMessage } from "react-intl";
import { Box, IconButton, Dialog, Divider } from "@mui/material";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import defaultGaming from "assets/icon/icn-gaming-default.svg";
import { Close as CloseIcon } from "@mui/icons-material";
import styled from "@mui/styled-engine";

export type MetricProps = {
  title: string;
  metric: string;
};

export type ModalProps = {
  /* Software Title */
  title: string;
  /* Description of the software */
  subtitle: string;
  /* Metrics objects to present */
  metrics: MetricProps[];
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
  /* Image to be displayed */
  image: string | null;
};

//#region component styles

const TitleIcon = styled("img")`
  width: 108px;
  height: 108px;
`;

const Container = styled("div")`
  border-radius: 14px;
  height: 680px;
  background-color: white;
  outline: 0;
`;

const Content = styled("div")`
  margin: 24px 16px 56px 16px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 24px 80px 56px 80px;
  }
`;

const CloseIconContainer = styled("div")`
  margin: 20px 0 24px 0;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
`;

const CloseIconStyled = styled(IconButton)`
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    left: 64px;
  }
`;

const CustomButton = styled(AppButton)`
  width: 184px;
  outline: none;
  height: 40px;
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 588px;
    width: 100%;
    height: 560px;
    margin: 12px;
    border-radius: 8px;
    overflow-y: hidden;
  }
`;

const ButtonText = styled("div")`
  line-height: 1px;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0.16px;
`;

const TitleText = styled("div")`
  font: normal normal 500 1rem Roboto;
  color: #535a6a;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.3125rem;
  }
`;

const MetricText = styled(Box)`
  color: ${({ theme }) => theme.palette.secondary.light};
  width: 120px;
  text-align: right;
  font: normal normal 500 1rem Roboto;
  flex-basis: 50%;
`;

const Metric = styled(Box)`
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MetricDescription = styled(Box)`
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0px;
  color: #535a6a;
  flex-basis: 50%;
`;

const LocationLabel = styled(Box)`
  color: ${({ theme }) => theme.palette.secondary.light};
  width: 100%;
  text-align: center;
  font: normal normal 500 1rem Roboto;
`;

const LocationText = styled("span")`
  word-wrap: break-word;
  margin: auto;
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
  margin-top: 8px;
  width: 100%;
  text-align: center;
`;

const LocationMetric = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

//#endregion component styles

function Modal({ title, subtitle, metrics = [], isOpen = true, handleOnClose, image }: ModalProps) {
  return (
    <StyledDialog
      data-qatag="modalDetails"
      open={isOpen}
      onClose={handleOnClose}
      fullWidth
    >
      <Container data-qatag="container">
        <Content data-qatag="content">
          {/* Close Icon */}
          <CloseIconContainer data-qatag="closeIconContainer">
            <CloseIconStyled
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
            >
              <CloseIcon data-qatag="CloseIcon" />
            </CloseIconStyled>
          </CloseIconContainer>

          {/* Title */}
          <Box
            data-qatag="TitleBox"
            textAlign="center"
          >
            <TitleIcon
              data-qatag="titleIcon"
              src={image || defaultGaming}
              alt="App Icon"
            />
            <Box
              data-qatag="titleTextBox"
              mt={2}
            >
              <TitleText data-qatag="titleText">{title}</TitleText>
              <MetricDescription
                data-qatag="metricDescription"
                mt={1}
              >
                {subtitle}
              </MetricDescription>
            </Box>
          </Box>

          {/* Metrics */}
          <Box
            data-qatag="MetricsBox"
            display="flex"
            mt={2}
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {metrics?.slice(1)?.map(({ title, metric }) => (
              // eslint-disable-next-line solveiq/no-empty-data-qatag
              <Metric
                data-qatag={`metric-${title}`}
                key={title}
              >
                <Box
                  data-qatag="titleBox"
                  display="flex"
                  justifyContent="center"
                  flexBasis="100%"
                  mt={2}
                >
                  <MetricText data-qatag="metricText">{title}</MetricText>
                  <MetricDescription
                    data-qatag="metricDescription"
                    ml={3}
                  >
                    {metric ?? ""}
                  </MetricDescription>
                </Box>
              </Metric>
            ))}
            {/* Divider */}
            <Box
              data-qatag="dividerBottom"
              width="100%"
              mt={5}
            >
              <Divider data-qatag="divider" />
            </Box>
          </Box>
          <LocationMetric
            data-qatag="LocationMetric"
            key={title}
            mt={2}
          >
            <LocationLabel
              data-qatag="LocationLabel"
              textAlign="center"
            >
              {metrics?.[0]?.title}
            </LocationLabel>

            <LocationText data-qatag="locationText">{metrics?.[0]?.metric}</LocationText>
          </LocationMetric>

          <Box
            data-qatag="closeButtonBox"
            width={1}
            textAlign="center"
            mt={2.5}
          >
            <CustomButton
              ariaLabel="Close"
              buttonType="button"
              data-qatag="closeButton"
              buttonStyle={AppButtonStyles.Green}
              onClick={handleOnClose}
            >
              <ButtonText data-qatag="buttonText">
                <FormattedMessage
                  data-qatag="closeText"
                  id="software.optimization.modal.close"
                  defaultMessage="Close"
                />
              </ButtonText>
            </CustomButton>
          </Box>
        </Content>
      </Container>
    </StyledDialog>
  );
}

export default Modal;
