import { LogLevel } from "@microsoft/signalr";
import { SignalRPeerMessageHub } from "ui.common";

declare let gatewayUrl: string;
declare let useSecureMessageHub: boolean;
declare const REACT_ENV: string;

export class SignalRHub {
  #signalRHub: SignalRPeerMessageHub | null;

  constructor() {
    this.#signalRHub = null;
  }

  public initSignalRHub(accessToken: string) {
    let logLevel = LogLevel.None;

    if (REACT_ENV.toLocaleLowerCase() !== "prod") {
      logLevel = LogLevel.Trace;
    }

    if (!this.#signalRHub) {
      const url = useSecureMessageHub ? `${gatewayUrl}/securehub` : `${gatewayUrl}/webuihub`;
      this.#signalRHub = new SignalRPeerMessageHub(url, "", logLevel, true, accessToken);
    }
  }

  public getInstance(): SignalRPeerMessageHub {
    if (!this.#signalRHub) {
      throw Error("SignalRPeerMessageHub does not exist");
    }

    return this.#signalRHub;
  }
}

const myAwesomeImNotGoingToDoThisAgainThing = new SignalRHub();

export const getSignalRHub = () => myAwesomeImNotGoingToDoThisAgainThing;
