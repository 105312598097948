import { Table, TableBody } from "@mui/material";
import { ServiceSummaryItem } from "../SummaryContainer";
import { ServiceItemRow } from "../ServiceItemRow/ServiceItemRow";

type PremiumServiceTableProps = {
  featureFlags: string;
  isLicenseExpired: boolean;
  premiumServices: ServiceSummaryItem[];
};

export function PremiumServiceTable({ featureFlags, isLicenseExpired, premiumServices }: PremiumServiceTableProps) {
  return (
    <Table data-qatag="premiumServiceTable.cardContent.table">
      <TableBody data-qatag="premiumServiceTable.cardContent.tableBody">
        {premiumServices.map((service) => (
          // eslint-disable-next-line solveiq/no-empty-data-qatag
          <ServiceItemRow
            data-qatag={`ServiceItemRow-${service.id}`}
            featureFlags={featureFlags}
            isLicenseExpired={isLicenseExpired}
            key={service.id}
            serviceSummaryItem={service}
          />
        ))}
      </TableBody>
    </Table>
  );
}
