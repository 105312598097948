import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { IconButton, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import styled from "@mui/styled-engine";
import { useState } from "react";

const StyledSelect = styled(Select)`
  max-height: 38px;
  overflow: hidden;
  padding: 0;

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 8px 16px;
  }

  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid #c1c7d0;
    }
  }
`;

export type Option = {
  label: string;
  value: string;
};

export type CustomizedSelectProps = {
  "data-qatag": string;
  currentValue: unknown;
  options: Option[];
  onChangeHandler: (value: string) => void;
};

export function CustomizedSelect({ currentValue, options, onChangeHandler }: CustomizedSelectProps) {
  const [open, setOpen] = useState(false);

  const CustomArrowIcon = () => {
    return (
      <IconButton
        data-qatag="SelectIconButton"
        disableRipple={true}
        size="small"
        sx={{ borderRadius: 0 }}
        onClick={() => setOpen(true)}
      >
        {open ? <ExpandLess data-qatag="ExpandLessIcon" /> : <ExpandMore data-qatag="ExpandMoreIcon" />}
      </IconButton>
    );
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChangeHandler(event.target.value as string);
  };

  return (
    <StyledSelect
      data-qatag="notificationsPerDay"
      id="notificationsPerDay"
      IconComponent={CustomArrowIcon}
      labelId="notificationsPerDay-label"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onChange={handleChange}
      value={currentValue}
    >
      {options.map(({ label, value }: Option, i) => (
        <MenuItem
          data-qatag="menu-item"
          key={i}
          selected={currentValue === value}
          value={value}
        >
          {label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
