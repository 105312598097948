import { Box, Typography } from "@mui/material";
import installingDriver from "assets/icon/icn-installing-driver.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../components/Spinner";

//#region Component
export const InstallInProgress: React.FC = () => {
  return (
    <Box
      data-qatag="driver.modal.install.in_progress"
      id="driver.modal.install.in_progress"
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        justifyContent: "center",
        height: "300px",
        width: "550px",
      }}
    >
      <img
        data-qatag="driver.modal.install.in_progress.icon"
        id="driver.modal.install.in_progress.icon"
        src={installingDriver}
        alt=""
        style={{ marginTop: "12px", maxWidth: "87px" }}
      />

      <FormattedMessage
        data-qatag="driver.modal.install.in_progress.title"
        id="driver.modal.install.in_progress.title"
        defaultMessage="Installing your driver..."
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.in_progress.title.text"
            id="driver.modal.install.in_progress.title.text"
            variant="h5"
            color="textPrimary"
            align="center"
            sx={{ fontSize: "1.5rem" }}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.in_progress.description_container"
        id="driver.modal.install.in_progress.description_container"
      >
        <FormattedMessage
          data-qatag="driver.modal.install.in_progress.description"
          id="driver.modal.install.in_progress.description"
          defaultMessage="The driver installation may take several minutes {br} to complete. Please wait..."
          values={{
            br: (
              <br
                data-qatag="driver.modal.install.in_progress.break"
                id="driver.modal.install.in_progress.break"
              />
            ),
          }}
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.in_progress.description.text"
              id="driver.modal.install.in_progress.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Spinner data-qatag="driver.modal.install.in_progress.spinner" />
    </Box>
  );
};
//#endregion Component
