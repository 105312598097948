import { useEffect, useState } from "react";
import {
  allowSoftware,
  blockSoftware,
  fetchAllowedSoftware,
  fetchDeceptorsBlocked,
  fetchDeceptorsFound,
  fetchHistoricalToMap,
  IDeceptorInfo,
  selectDeceptor,
  selectDeceptorsBlocked,
  selectHistoricalSoftware,
  selectStatus,
} from "./DeceptorProtectionSlice";
import { RootState } from "app/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { useNavigate } from "react-router";
import useQuery from "hooks/useQuery";
import { DeceptorDetails } from "./DeceptorDetails";

export function DeceptorDetailsContainer() {
  const dispatch = useDispatch();
  const query: URLSearchParams = useQuery();
  const id = query?.get("id") || "";
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [redirectPath, setRedirectPath] = useState("/deceptorprotection");

  const deceptor = useSelector((state: RootState) => selectDeceptor(state, id)) as IDeceptorInfo | null;
  const blocked = useSelector(selectDeceptorsBlocked);

  const historical = useSelector(selectHistoricalSoftware);

  const isAllowApp = deceptor?.IsAllowed;

  useEffect(() => {
    if (deceptor?.IsAllowed !== undefined) {
      setRedirectPath(`/deceptorprotection${deceptor?.IsAllowed ? "" : "?tab=allowed"}`);
    }
  }, [deceptor?.IsAllowed]);

  useEffect(() => {
    dispatch(fetchHistoricalToMap());
    dispatch(fetchDeceptorsBlocked());
    dispatch(fetchDeceptorsFound());
    dispatch(fetchAllowedSoftware());
  }, [dispatch]);

  const handleAllowOrBlock = async () => {
    if (!deceptor) return;
    setShowLoader(true);
    if (isAllowApp) {
      await Promise.all([dispatch(blockSoftware(deceptor))]);
    } else {
      await Promise.all([dispatch(allowSoftware(deceptor))]);
    }
    dispatch(fetchAllowedSoftware());
    dispatch(fetchDeceptorsBlocked());
    dispatch(fetchDeceptorsFound());

    //TODO: Remove this timeout when the allowSoftware allows to know the status
    setTimeout(() => {
      navigate(redirectPath);
    }, 1000);
  };

  const deceptorStatus = useSelector(selectStatus);
  const isLoading =
    showLoader ||
    deceptorStatus[fetchDeceptorsBlocked.typePrefix] === ReducerStatus.Loading ||
    deceptorStatus[fetchAllowedSoftware.typePrefix] === ReducerStatus.Loading ||
    deceptorStatus[fetchHistoricalToMap.typePrefix] === ReducerStatus.Loading;

  //Handle when user type an id that doesn't exist
  if (
    deceptor === null &&
    deceptorStatus[fetchHistoricalToMap.typePrefix] === ReducerStatus.Succeeded &&
    deceptorStatus[fetchDeceptorsBlocked.typePrefix] === ReducerStatus.Succeeded &&
    deceptorStatus[fetchAllowedSoftware.typePrefix] === ReducerStatus.Succeeded
  ) {
    navigate(redirectPath);
  }

  return (
    <DeceptorDetails
      data-qatag="DeceptorDetails"
      deceptor={deceptor}
      handleAllowOrBlock={handleAllowOrBlock}
      isLoading={isLoading}
      historical={historical}
      blocked={blocked}
    />
  );
}
