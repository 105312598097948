import { useNavigate } from "react-router";
import DownloadPage from "./DownloadPage";

export function DownloadPageContainer() {
  const navigate = useNavigate();

  const dlCallback = () => {
    navigate("/downloadlander");
  };

  const styleHack = {
    backgroundColor: "#007da0",
    width: "100%",
    marginTop: "-25px",
  };

  return (
    <div
      data-qatag="downloadPageContainer"
      style={styleHack}
    >
      <DownloadPage
        data-qatag="DownloadView"
        downloadHandler={dlCallback}
      />
    </div>
  );
}
