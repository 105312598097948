export function getDate(date: string, mask?: "m/dd/yy"): string {
  const d = new Date(date);
  return d.toLocaleDateString("m/dd/yy");
}

export function getDateAndTime(date: string): string {
  const d = new Date(date);
  return d.toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function isoToFormatted(date: string, format: string): string {
  const dateToFormat = new Date(date);
  const month_asDigit = dateToFormat.getMonth() + 1;
  const month_asTwoDigits = month_asDigit < 10 ? `0${month_asDigit}` : month_asDigit;
  const month_long = dateToFormat.toLocaleString("default", { month: "long" });
  const day = dateToFormat.getDate();
  const yearL = dateToFormat.getFullYear();

  switch (format) {
    case "MMM Do, YYYY": {
      return `${month_long} ${day}, ${yearL}`;
    }
    case "MM/DD/YYYY":
    default: {
      return `${month_asTwoDigits}/${day}/${yearL}`;
    }
  }
}
