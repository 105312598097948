import React from "react";
import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

export interface IInstallLowDiskSpaceProps {
  onRequestSystemClean: () => void;
  onCancel: () => void;
}
export const InstallLowDiskSpace: React.FC<IInstallLowDiskSpaceProps> = ({ onCancel, onRequestSystemClean }) => {
  const { formatMessage } = useIntl();

  return (
    <Alert
      data-qatag="installFailed.lowDiskSpaceAlert"
      title={formatMessage({
        id: "insufficientSpaceTitle",
        defaultMessage: "Could not complete your driver download due to insufficient space",
      })}
      icon={AlertIcon.Warning}
      text={formatMessage({
        id: "makeSomeSpaceText",
        defaultMessage: "Please create some space on your hard drive and try again.",
      })}
      approveHandler={onRequestSystemClean}
      buttonText={formatMessage({
        id: "tryASystemCleanButtonText",
        defaultMessage: "Try A System Clean",
      })}
      isOpen={true}
      closeHandler={onCancel}
      cancelButtonText={formatMessage({
        id: "noThanks",
        defaultMessage: "No, Thanks",
      })}
      cancelHandler={onCancel}
    />
  );
};
