import { eFeatureTileTypes } from "../StatusScreenContainer";
import deviceUpdatesImage from "assets/img/status/deviceUpdatesImage.svg";
import fileCleaningImage from "assets/img/status/fileCleaningImage.svg";
import deceptorBlockingImage from "assets/img/status/deceptorBlockingImage.svg";
import appOptimizationImage from "assets/img/status/appOptimizationImage.svg";
import windowsEnhancementsImage from "assets/img/status/windowsEnhancementsImage.svg";
import dnsProtectionImage from "assets/img/status/dnsProtectionImage.svg";
import { isFeatureIncludedInLicense } from "core/Helpers";

export interface IFeatureTileData {
  isEnabled: boolean;
  needsAttention: boolean;
  tileType: eFeatureTileTypes;
  title: string;
  enabledCTA: string;
  disabledCTA: string;
  imageSrc: string;
}

class FeatureTileData implements IFeatureTileData {
  isEnabled: boolean;
  needsAttention: boolean;
  tileType: eFeatureTileTypes;
  title: string;
  enabledCTA: string;
  disabledCTA: string;
  imageSrc: string;

  constructor(
    tileType: eFeatureTileTypes,
    isEnabled: boolean,
    needsAttention: boolean,
    title: string,
    enabledCTA: string,
    disabledCTA: string,
    imageSrc: string
  ) {
    this.tileType = tileType;
    this.isEnabled = isEnabled;
    this.needsAttention = needsAttention;
    this.title = title;
    this.enabledCTA = enabledCTA;
    this.disabledCTA = disabledCTA;
    this.imageSrc = imageSrc;
  }
}

export type FeatureTileBuilderProps = {
  isAppOptimizationActive: boolean;
  licenseFeatures: string;
  outOfDateDriverCount: number;
  isDeceptorBlockingActive: boolean;
  isFileCleanupNeeded: boolean;
  browserExtensionEnabled: boolean;
  isDnsProtectionActive: boolean;
};

export function BuildFeatureTiles(props: FeatureTileBuilderProps): IFeatureTileData[] {
  const tiles: IFeatureTileData[] = [];
  //Device Updates
  tiles.push(
    new FeatureTileData(
      eFeatureTileTypes.Drivers,
      isFeatureIncludedInLicense("Drivers", props.licenseFeatures),
      props.outOfDateDriverCount > 0,
      "Device Updates",
      "Update",
      "Enable",
      deviceUpdatesImage
    )
  );
  //Web Protection
  const webEnabled =
    isFeatureIncludedInLicense("WebProtection", props.licenseFeatures) &&
    props.isDnsProtectionActive &&
    (props.browserExtensionEnabled || localStorage.getItem("clickedExtension") === "true");
  const webNeedsAttention =
    !isFeatureIncludedInLicense("WebProtection", props.licenseFeatures) ||
    !(props.browserExtensionEnabled || localStorage.getItem("clickedExtension") === "true") ||
    !props.isDnsProtectionActive;
  tiles.push(
    new FeatureTileData(
      eFeatureTileTypes.WebProtection,
      webEnabled,
      webNeedsAttention,
      "Web Protection",
      "View Protection",
      "Change Protection",
      dnsProtectionImage
    )
  );
  //System Cleanup
  tiles.push(
    new FeatureTileData(
      eFeatureTileTypes.SystemCleanup,
      isFeatureIncludedInLicense("SystemCleanUp", props.licenseFeatures),
      props.isFileCleanupNeeded,
      "System Cleanup",
      "Clean",
      "Enable",
      fileCleaningImage
    )
  );
  //Software Protection
  tiles.push(
    new FeatureTileData(
      eFeatureTileTypes.SoftwareProtection,
      isFeatureIncludedInLicense("SoftwareProtection", props.licenseFeatures) && props.isDeceptorBlockingActive,
      !isFeatureIncludedInLicense("SoftwareProtection", props.licenseFeatures) || !props.isDeceptorBlockingActive,
      "Software Protection",
      "View Blocking",
      "Enable",
      deceptorBlockingImage
    )
  );
  //App Optimization
  tiles.push(
    new FeatureTileData(
      eFeatureTileTypes.AppOptimization,
      isFeatureIncludedInLicense("AppOptimization", props.licenseFeatures) && props.isAppOptimizationActive,
      !isFeatureIncludedInLicense("AppOptimization", props.licenseFeatures) || !props.isAppOptimizationActive,
      "App Optimization",
      "View Optimizations",
      "Enable",
      appOptimizationImage
    )
  );
  //Windows Enhancements
  tiles.push(
    new FeatureTileData(
      eFeatureTileTypes.WindowsEnhancements,
      isFeatureIncludedInLicense("WindowsEnhancements", props.licenseFeatures),
      !isFeatureIncludedInLicense("WindowsEnhancements", props.licenseFeatures),
      "Windows Enhancements",
      "View Enhancements",
      "Enable",
      windowsEnhancementsImage
    )
  );

  return tiles;
}
