import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import ScanStages from "./ScanStages";
import { ProgressBar, useInterval } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled("header")`
  margin-top: 20px;
  margin-bottom: 33px;
  text-align: center;
`;

const Title = styled(Typography)`
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 400;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.375rem;
  }
`;

const Percentage = styled("span")`
  color: #007dba;
  font-weight: 600;
`;

const Subtitle = styled(Typography)``;

const ProgressBarContainer = styled("div")`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px auto;
  text-align: center;
  max-width: 90%;
`;

const Stat = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const StatLabel = styled(Typography)`
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  margin-right: 5px;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1rem;
    line-height: 1.313rem;
  }
`;

const StatValue = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.5rem;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1rem;
    line-height: 1.313rem;
  }
`;

export type ScanScreenProps = {
  progress: number;
};

const ScanScreen: React.FC<ScanScreenProps> = ({ progress }) => {
  const [truncatedProgress, setTruncatedProgress] = useState(0);
  const [secondsCount, setSecondsCount] = useState(0);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");

  const { formatMessage } = useIntl();

  useInterval(() => {
    setSecondsCount((prevState) => prevState + 1);
    setElapsedTime(getElapsedTime(secondsCount));
  }, 1000);

  useEffect(() => {
    setTruncatedProgress(truncate(progress));
  }, [progress]);

  return (
    <Container data-qatag="container">
      <Header data-qatag="header">
        <Title
          data-qatag="title"
          variant="h2"
        >
          {formatMessage({
            id: "scanningScreen.view.header.title",
            defaultMessage: "Scanning",
          })}
          ... <Percentage data-qatag="percentage">{getProcessPercentage(truncatedProgress)}%</Percentage>
        </Title>
        <Box
          data-qatag="subtitleContainer"
          mt={2}
        >
          <Subtitle
            data-qatag="subtitle"
            variant="body2"
          >
            <FormattedMessage
              id="scanningScreen.view.header.subtitle"
              data-qatag="scanningScreen.view.header.subtitle"
              defaultMessage="Once this scan is finished you will be guided through the protection and optimization features."
            />
          </Subtitle>
        </Box>
      </Header>

      <ProgressBarContainer data-qatag="progressBarContainer">
        <ProgressBar
          data-qatag="progressBar"
          fullWidth
          value={truncatedProgress}
        />
      </ProgressBarContainer>

      <ScanStages
        data-qatag="scanStages"
        progress={truncatedProgress}
      />

      <StatsContainer data-qatag="statsContainer">
        <Stat data-qatag="time">
          <StatLabel data-qatag="timeLabel">
            <FormattedMessage
              data-qatag="scanningScreen.view.stats.time.label"
              id="scanningScreen.view.stats.time.label"
              defaultMessage="Time Elapsed: "
            />
          </StatLabel>
          <StatValue
            data-qatag="timeValue"
            data-testid="elapsedTime"
          >
            {elapsedTime}
          </StatValue>
        </Stat>
      </StatsContainer>
    </Container>
  );
};

export default ScanScreen;

/**
 * Returns an elapsed time in the format HH:MM:SS.
 * @param elapsed seconds
 */
export function getElapsedTime(elapsed: number): string {
  const hours = Math.floor(elapsed / 3600);
  const minutes = Math.floor((elapsed - hours * 3600) / 60);
  const seconds = elapsed - hours * 3600 - minutes * 60;

  return `${zeroFill(hours)}:${zeroFill(minutes)}:${zeroFill(seconds)}`;
}

/**
 * Adds a leading zero to have a double digit format.
 * @param value number to check if needs a leading zero.
 */
function zeroFill(value: number): string {
  const textValue = String(value);
  if (textValue.length === 1) {
    return "0" + value;
  }
  return textValue;
}

/**
 * Receives a value between 0.0 and 1.0 and
 * returns the percentage between 0% - 100%.
 * @param value number in range 0.0 - 1.0.
 */
function getProcessPercentage(value: number): number {
  return Math.round(value * 100);
}

/**
 * Truncates any value not between 0.0 and 1.0.
 * @param value number to truncate.
 */
function truncate(value: number): number {
  if (value < 0) {
    value = 0;
  } else if (value > 1) {
    value = 1;
  }
  return value;
}
