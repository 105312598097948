import { Box, ToggleButtonGroup, ToggleButton, Typography } from "@mui/material";
import { Tune as TuneIcon } from "@mui/icons-material";
import { debounce, sortByDeviceClass } from "core/Helpers";
import Fuse from "fuse.js";
import { IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { SearchTextField } from "dsoneweb.designsystem";
import { DeviceContainer } from "./Device/DeviceContainer";
import { NoSearchResults } from "../components/NoSearchResults";
import { FormattedMessage } from "react-intl";
import styled from "@mui/styled-engine";

const FilterSearchMenu = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-start;
    flex-direction: row;
  }
`;
const FilterContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: unset;
  }
`;
const StyledToggleButton = styled(ToggleButton)`
  border: 3px solid #ebecf2;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  height: 44px;
  text-transform: unset;
  width: 100px;

  &.Mui-selected {
    background-color: #ebecf2;
  }
`;

export interface IDeviceListProps {
  allDevices: IDeviceInfo[];
  devicesWithUpdates: IDeviceInfo[];
  /** Properties of the DeviceInfo model to use when searching for a device */
  keys: Array<keyof IDeviceInfo>;
  /** Hides/Shows the toggle filter */
  isDeviceFilterVisible: boolean;
  statusMap: IDriverUpdateModel[];
}
//#endregion Component Props

//#region Component
export const DeviceList: React.FC<IDeviceListProps> = ({
  allDevices,
  devicesWithUpdates,
  keys,
  isDeviceFilterVisible,
  statusMap,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isFiltering, setIsFiltering] = useState(true);
  const [filteredDevices, setFilteredDevices] = useState<React.ReactElement[]>(
    devicesToSortedEntries(isFiltering ? devicesWithUpdates : allDevices, statusMap)
  );
  useEffect(() => {
    if (devicesWithUpdates.length === 0) {
      setIsFiltering(false);
    }
  }, [devicesWithUpdates.length]);

  const onDeviceFilterToggle = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    setIsFiltering(value === "issues");
  };

  // Hide filters when searching if filters are set to be ignored
  const hideFiltersOnSearch = searchText.trim().length === 0;

  // Builds a new Fuse object to filter devices
  const devicesToFilter = useMemo(() => {
    const options = { ignoreLocation: true, includeScore: true, keys };
    if (isFiltering) {
      return new Fuse(devicesWithUpdates, options);
    }
    return new Fuse(allDevices, options);
  }, [keys, isFiltering, allDevices, devicesWithUpdates]);

  // Memoized debounced function to set filtered devices
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetFilteredDevices = useCallback(
    debounce((values: React.ReactElement[]) => {
      setFilteredDevices(values);
    }, 300),
    [setFilteredDevices]
  );

  useEffect(() => {
    // User is not searching for devices
    if (searchText.trim().length === 0) {
      if (isFiltering) {
        memoizedSetFilteredDevices(devicesToSortedEntries(devicesWithUpdates, statusMap));
      } else {
        memoizedSetFilteredDevices(devicesToSortedEntries(allDevices, statusMap));
      }
    }
    // User is searching for devices
    else {
      memoizedSetFilteredDevices(
        devicesToFilter
          .search(searchText)
          .filter((item) => (item.score || 1) < 0.1)
          .map((element) => element.item)
          // Place first elements with updates available
          .sort((a, b) => {
            if (a.hasUpdateAvailable === b.hasUpdateAvailable) {
              return 0;
            } else if (a.hasUpdateAvailable) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((d, index) => (
            <DeviceContainer
              data-qatag="device_list.device_entry"
              key={`${d.deviceID}_${index}`}
              device={d}
              statusMap={statusMap}
            />
          ))
      );
    }
  }, [searchText, isFiltering, allDevices, devicesWithUpdates, memoizedSetFilteredDevices, devicesToFilter, statusMap]);

  const onSearchType = (searchValue: string) => {
    setSearchText(searchValue);
    setIsFiltering(false);
  };

  const isToggleFilterVisible = isDeviceFilterVisible && hideFiltersOnSearch;

  return (
    <>
      <FilterSearchMenu
        data-qatag="driver.device_list.filter_root"
        id="driver.device_list.filter_root"
      >
        {isDeviceFilterVisible ? (
          <FilterContainer data-qatag="driver.device_list.filterContainer">
            <TuneIcon data-qatag="driver.device_list.filterIcon" />
            <Typography data-qatag="driver.device_list.filterDevicesTypography">
              <FormattedMessage
                data-qatag="driver.screen.filterDevices.text"
                id="driver.screen.filterDevices.text"
                defaultMessage="Filter Devices"
              />
            </Typography>
            <ToggleButtonGroup
              data-qatag="toggle-button-group"
              disabled={!isToggleFilterVisible}
              exclusive
              onChange={onDeviceFilterToggle}
              value={isFiltering ? "issues" : "all"}
              sx={{ borderRadius: "8px" }}
            >
              <StyledToggleButton
                data-qatag="ToggleButton.issues"
                value={"issues"}
                aria-label="issues"
              >
                <FormattedMessage
                  data-qatag="driver.screen.ToggleButton.issues.text"
                  id="driver.screen.ToggleButton.issues.text"
                  defaultMessage="Issues"
                />
              </StyledToggleButton>
              <StyledToggleButton
                data-qatag="ToggleButton.all"
                value={"all"}
                aria-label="all"
              >
                <FormattedMessage
                  data-qatag="driver.screen.all.text"
                  id="driver.screen.all.text"
                  defaultMessage="All"
                />
              </StyledToggleButton>
            </ToggleButtonGroup>
          </FilterContainer>
        ) : null}

        <SearchTextField
          data-qatag="driver.searchbar.searchfield"
          placeholder={"Search Device"}
          onChange={onSearchType}
          displayDivider={false}
          value={searchText}
        />
      </FilterSearchMenu>

      {filteredDevices.length > 0 ? (
        filteredDevices
      ) : (
        <NoSearchResults data-qatag="driver.device_list.no_search_results" />
      )}
    </>
  );
};

/**
 * Sorts devices by class and transforms each to a Device component
 * @param devices List of devices
 */
const devicesToSortedEntries = (devices: IDeviceInfo[], statusMap: IDriverUpdateModel[]) =>
  [...devices].sort(sortByDeviceClass).map((d, index) => (
    <DeviceContainer
      data-qatag="driver.device_list.device_entry"
      key={`${d.deviceID}_${index}`}
      device={d}
      statusMap={statusMap}
    />
  ));
