import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MonitorIcon from "@mui/icons-material/Monitor";
import { Alert, Box, Button, styled, Table, TableBody, TableCell, TableFooter, TableRow } from "@mui/material";
import IFamilyMember from "model/user/IFamilyMember";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

type ProtectedPCsTableProps = {
  familyMembers: IFamilyMember[];
};

// #region styled-components
const Container = styled(Table)`
  tbody tr:last-of-type td {
    border: 0;
  }
`;

const IconTableCell = styled(TableCell)`
  padding-right: 0;
  vertical-align: top;
  width: 28px;
`;

const OnlineStatusTableCell = styled(TableCell)`
  padding-right: 0;
  text-align: right;
  vertical-align: top;

  span {
    display: inline-flex;
    align-items: center;
  }
`;

const SystemInfo = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const StyledCircleIcon = styled(CircleIcon)`
  font-size: 18px;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const PendingInstallAlert = styled(Alert)`
  border-radius: 8px;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: 600;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  text-align: center;

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    padding: 0;
  }
`;

const ShowAllTableCell = styled(TableCell)`
  border: 0;
  text-align: center;
`;

const ShowAllPcsButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  text-transform: none;
`;
// #endregion styled-components

export function ProtectedPCsTable({ familyMembers }: ProtectedPCsTableProps) {
  const initialRecordsViewCount = 3;
  const [showAllPCs, setShowAllPCs] = useState<boolean>(false);

  return (
    <Container data-qatag="protectedPCsTable.protectedPCs.cardContent.table">
      <TableBody data-qatag="protectedPCsTable.protectedPCs.cardContent.tableBody">
        {familyMembers?.map((member, index) => (
          <TableRow
            key={member.id}
            data-qatag="protectedPCsTable.protectedPCs.tableRow"
            style={{
              display: showAllPCs || index < initialRecordsViewCount ? "table-row" : "none",
            }}
          >
            <IconTableCell data-qatag="protectedPCsTable.protectedPCs.activatedIcon">
              {member.isLicenseActivated ? (
                <MonitorIcon
                  data-qatag="protectedPCsTable.monitorIcon"
                  sx={{ color: "secondary.dark" }}
                />
              ) : (
                <AccessTimeIcon
                  data-qatag="protectedPCsTable.accessTimeIcon"
                  color="warning"
                />
              )}
            </IconTableCell>
            <TableCell data-qatag="protectedPCsTable.protectedPCs.systemInfoCell">
              <SystemInfo data-qatag="protectedPCsTable.protectedPCs.systemInfo">
                {member.isLicenseActivated ? (
                  // eslint-disable-next-line solveiq/data-qatag
                  <strong>
                    {member.machineDisplayName || (
                      // eslint-disable-next-line solveiq/data-qatag
                      <FormattedMessage
                        id="protectedPCsTable.protectedPCs.customPC"
                        defaultMessage={"Custom PC"}
                      />
                    )}
                  </strong>
                ) : (
                  // eslint-disable-next-line solveiq/data-qatag
                  <span>{member.fullName}</span>
                )}
                <>{member.email}</>
              </SystemInfo>
            </TableCell>
            <OnlineStatusTableCell data-qatag="protectedPCsTable.protectedPCs.onlineStatusCell">
              {member.isLicenseActivated ? (
                // eslint-disable-next-line solveiq/data-qatag
                <span>
                  <StyledCircleIcon
                    data-qatag="protectedPCsTable.protectedPCs.onlineStatusIcon"
                    sx={{
                      color: member.isOnline ? "success.main" : "error.main",
                    }}
                  />
                  {member.isOnline ? (
                    // eslint-disable-next-line solveiq/data-qatag
                    <FormattedMessage
                      id="protectedPCsTable.protectedPCs.online"
                      defaultMessage="Online"
                    />
                  ) : (
                    // eslint-disable-next-line solveiq/data-qatag
                    <FormattedMessage
                      id="protectedPCsTable.protectedPCs.offline"
                      defaultMessage="Offline"
                    />
                  )}
                </span>
              ) : (
                <PendingInstallAlert
                  severity="warning"
                  data-qatag="protectedPCsTable.protectedPCs.pendingInstall"
                >
                  {/* eslint-disable-next-line solveiq/data-qatag */}
                  <FormattedMessage
                    id="protectedPCsTable.protectedPCs.pendingInstall"
                    defaultMessage="Pending Install"
                  />
                </PendingInstallAlert>
              )}
            </OnlineStatusTableCell>
          </TableRow>
        ))}
      </TableBody>
      {/* Only show the toggle if there are more rows than the default view */}
      {familyMembers.length > initialRecordsViewCount && (
        <TableFooter data-qatag="protectedPCsTable.tableFooter">
          <TableRow data-qatag="protectedPCsTable.tableRow">
            <ShowAllTableCell
              colSpan={3}
              data-qatag="protectedPCsTable.protectedPCs.footerCell"
            >
              <ShowAllPcsButton
                data-qatag="protectedPCsTable.showAllButton"
                onClick={() => setShowAllPCs(!showAllPCs)}
              >
                {showAllPCs ? (
                  <>
                    {/* eslint-disable-next-line solveiq/data-qatag */}
                    <FormattedMessage
                      id="protectedPCsTable.showFewerPcs"
                      defaultMessage="Show Fewer PCs"
                    />
                    <ExpandLessIcon data-qatag="protectedPCsTable.expandMoreIcon" />
                  </>
                ) : (
                  <>
                    {/* eslint-disable-next-line solveiq/data-qatag */}
                    <FormattedMessage
                      id="protectedPCsTable.showAllPcs"
                      defaultMessage="Show All PCs"
                    />
                    <ExpandMoreIcon data-qatag="protectedPCsTable.expandLessIcon" />
                  </>
                )}
              </ShowAllPcsButton>
            </ShowAllTableCell>
          </TableRow>
        </TableFooter>
      )}
    </Container>
  );
}
