import { Container, Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import { FormattedMessage } from "react-intl";

type FixedFormattedMessage = {
  id: string;
  "data-qatag": string;
  defaultMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
};

// temporary work-around (see https://github.com/yahoo/babel-plugin-react-intl/issues/119)
function FormattedMessageFixed(props: FixedFormattedMessage) {
  // eslint-disable-next-line solveiq/data-qatag
  return <FormattedMessage {...props} />;
}

const BaseLabelValuePair = styled(Container)`
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px;
  }
`;

const LabelValuePairContainer = styled(BaseLabelValuePair)`
  background: #fafafa;

  :nth-child(odd) {
    background: transparent;
  }
`;
const LabelValuePairContainerInverted = styled(BaseLabelValuePair)`
  background: transparent;

  :nth-child(odd) {
    background: #fafafa;
  }
`;

export interface ILabelValueProps {
  children?: React.ReactNode;
  invertBgColors?: boolean;
  label: string;
  tag: string;
  value: string;
}
/**
 * Reusable component for headers in MyAccount
 * @method LabelValuePair
 * @returns {JSX.Element}
 */
export function LabelValuePair({ children, invertBgColors, label, tag, value }: ILabelValueProps): JSX.Element {
  if (invertBgColors) {
    return (
      <LabelValuePairContainerInverted data-qatag="LabelValuePairContainerInverted">
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <Typography
          data-qatag={`${tag}.labelValuePair.labelTypography`}
          variant="body2"
          sx={{ fontWeight: "700" }}
        >
          {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
          <FormattedMessageFixed
            id={`${tag}.label`}
            data-qatag={`${tag}.label`}
            defaultMessage={label}
          />
        </Typography>
        {children ? (
          <>{children}</>
        ) : (
          /* eslint-disable-next-line solveiq/no-empty-data-qatag */
          <Typography
            data-qatag={`${tag}.labelValuePair.valueTypography`}
            variant="body2"
            sx={{ fontWeight: "500" }}
          >
            {value}
          </Typography>
        )}
      </LabelValuePairContainerInverted>
    );
  }

  return (
    <LabelValuePairContainer data-qatag="LabelValuePairContainer">
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
      <Typography
        data-qatag={`${tag}.labelValuePair.labelTypography`}
        variant="body2"
        sx={{ fontWeight: "700" }}
      >
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <FormattedMessageFixed
          id={`${tag}.label`}
          data-qatag={`${tag}.label`}
          defaultMessage={label}
        />
      </Typography>
      {children ? (
        <>{children}</>
      ) : (
        /* eslint-disable-next-line solveiq/no-empty-data-qatag */
        <Typography
          data-qatag={`${tag}.labelValuePair.valueTypography`}
          variant="body2"
          sx={{ fontWeight: "500" }}
        >
          {value}
        </Typography>
      )}
    </LabelValuePairContainer>
  );
}
