import ResumedSubscription from "./ResumedSubscription";
import { useGetSubscriptionQuery } from "api/SubscriptionApi/SubscriptionApi";
import { Spinner, Alert, AlertIcon } from "dsoneweb.designsystem";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

function ResumedSubscriptionContainer() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate("/status");
  }, [navigate]);

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    error: isSubscriptionError,
  } = useGetSubscriptionQuery();

  return (
    <>
      <ResumedSubscription
        data-qatag="ResumedSubscription"
        name={subscription?.subscription?.accountName}
      />
      {/* Loading alert */}
      {isLoadingSubscription ? (
        <Spinner
          data-qatag="resumedSubscriptionSpinner"
          isActive={isLoadingSubscription}
          text={formatMessage({
            id: "resumedSubscriptionSpinner.title",
            defaultMessage: "Loading...",
          })}
        />
      ) : null}
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "resumedSubscriptionSpinner.errorButton.title",
          defaultMessage: "Oops! Something Went Wrong",
        })}
        text={formatMessage({
          id: "resumedSubscriptionSpinner.errorButton.subtitle",
          defaultMessage: "We Apologize for the Inconvenience. You will be redirected to the My Account page.",
        })}
        buttonText={formatMessage({
          id: "resumedSubscriptionSpinner.errorButton.continue",
          defaultMessage: "Continue",
        })}
        approveHandler={handleGoBack}
        isOpen={isSubscriptionError !== undefined}
        closeHandler={handleGoBack}
      />
    </>
  );
}

export default ResumedSubscriptionContainer;
