import { TextField } from "@mui/material";
import styled from "@mui/styled-engine";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#0FB4D1",
  },
  "& .MuiOutlinedInput-root": {
    //fontSize: theme.typography.body2.fontSize,
    "& fieldset": {
      borderWidth: 2,
      borderColor: "#C1C7D0",
    },
    "& input:valid:hover + fieldset": {
      borderColor: "#0FB4D1",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#0FB4D1",
    },
    "& input:error + fieldset": {
      //borderColor: "#E31D2B",
      borderColor: theme.palette.error.main,
    },
  },
}));
