import ResumedSubscription from "./PremiumUpgrade";
import { useGetSubscriptionQuery } from "api/SubscriptionApi/SubscriptionApi";
import { useCallback } from "react";
import { useNavigate } from "react-router";

type ResumedSubscriptionContainerProps = {
  recurringCharge: number;
  total: number;
};

function PremiumSubscriptionContainer({ recurringCharge, total }: ResumedSubscriptionContainerProps) {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate("/status");
  }, [navigate]);

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    error: isSubscriptionError,
  } = useGetSubscriptionQuery();

  return (
    <ResumedSubscription
      data-qatag="ResumedSubscription"
      name={subscription?.subscription?.accountName}
      recurringCharge={recurringCharge}
      total={total}
      handleRedirect={handleGoBack}
      isLoadingSubscription={isLoadingSubscription}
      isSubscriptionError={isSubscriptionError !== undefined}
    />
  );
}

export default PremiumSubscriptionContainer;
