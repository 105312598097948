import LoginFromPCModal from "./LoginFromPCModal";
import { useNavigate } from "react-router";

export type LoginFromPCModalProps = {
  isAdmin: boolean;
  isWindows: boolean;
};

export function LoginFromPCModalContainer({ isAdmin, isWindows }: LoginFromPCModalProps) {
  const navigate = useNavigate();

  const closeAlert = () => {
    navigate("/logout");
  };

  if (!isAdmin && !isWindows)
    return (
      <LoginFromPCModal
        data-qatag="LoginFromPCModal"
        onClose={closeAlert}
      />
    );

  return null;
}

export default LoginFromPCModalContainer;
