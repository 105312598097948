import { Box, List, ListItemButton, Paper, Typography } from "@mui/material";
import { Circle as CircleIcon } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import styled from "@mui/styled-engine";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    max-height: 300px;
  }
`;
const TitleContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;
const Title = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
  font-weight: 600;
`;
const StyledPaper = styled(Paper)`
  overflow-y: auto;
  height: 100%;

  &.invalid {
    border: 1px solid #f1582d;
  }
`;
const ColoredCircle = styled(CircleIcon)`
  height: 10px;
  width: 10px;
`;
const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
`;
const StyledListItem = styled(ListItemButton)`
  &.active {
    background: #007dba;

    p {
      color: white;
    }
  }
  p {
    color: #535a6a;
    font-size: 1rem;
  }
`;
export interface OptionInterface {
  value?: string | number;
  label?: string | number;
}
export type SelectionBoxProps = {
  options: OptionInterface[];
  onChange: (e?: string | number) => void;
  selectionSectionTitle: string;
  showErrorState: boolean;
  value?: string | number;
};
export function SelectionBox({ options, onChange, selectionSectionTitle, showErrorState, value }: SelectionBoxProps) {
  const iconColor = value ? "#1FC700" : "#F1582D";

  return (
    <Container data-qatag="SelectionBox.container">
      <TitleContainer data-qatag="SelectionBox.title.container">
        <ColoredCircle
          data-qatag="SelectionBox.icon"
          sx={{ color: iconColor }}
        />
        <Title data-qatag="SelectionBox.title">
          <FormattedMessage
            data-qatag="SelectionBox.title.text"
            id="SelectionBox.title.text"
            defaultMessage="Select Your {title}"
            values={{
              title: <>{selectionSectionTitle}</>,
            }}
          />
        </Title>
      </TitleContainer>
      <StyledPaper
        data-qatag="SelectionBox.paper"
        elevation={3}
        className={showErrorState && !value ? "invalid" : ""}
      >
        <StyledList data-qatag="SelectionBox.list">
          {options
            ? options.map((option: OptionInterface, i: number) => (
                <StyledListItem
                  data-qatag="SelectionBox.optionContainer"
                  key={i}
                  onClick={() => onChange(option.value)}
                  className={option.value === value ? "active" : ""}
                >
                  <Typography data-qatag="SelectionBox.option">{option.label}</Typography>
                </StyledListItem>
              ))
            : null}
        </StyledList>
      </StyledPaper>
    </Container>
  );
}
