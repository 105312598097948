import { axiosBaseQuery } from "api/axiosBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

//Retrieve REACT_APP_PRODUCTID from .env file
declare const productId: number;

const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/User/",
  }),
  endpoints(build) {
    return {
      postUserOnboarding: build.mutation({
        query: (email: string | undefined) => {
          return {
            url: `${email}/onboarding/${productId}`,
            method: "post",
          };
        },
      }),
      getUserNeedsToSetPassword: build.query<boolean, string>({
        query: (email: string) => {
          return {
            url: `${email}/needstosetpassword`,
            method: "get",
          };
        },
      }),
    };
  },
});

export const { usePostUserOnboardingMutation, useGetUserNeedsToSetPasswordQuery } = userApi;
export { userApi };
