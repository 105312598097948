import React from "react";
import ReactDOM from "react-dom/client";
import { AppProvider } from "Providers";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);

root.render(
  <React.StrictMode data-qatag="strictMode">
    <AppProvider data-qatag="provider" />
  </React.StrictMode>
);
