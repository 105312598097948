import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { selectAgentInitStatus } from "./AgentSlice";
import { Spinner } from "dsoneweb.designsystem";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { useIntl } from "react-intl";
import { selectAccessToken } from "session/SessionSlice";
import { SignalRHubConnector } from "app/SignalRHub/signalRHubConnector";

type Props = {
  children?: React.ReactNode;
};

const AgentCommunicationWrapper = ({ children }: Props) => {
  let _signalRConnector: SignalRHubConnector | null = null;
  const { formatMessage } = useIntl();
  const currentUuid = useSelector(selectCurrentUuid);
  const agentInitState = useSelector(selectAgentInitStatus);
  const agentInitComplete = agentInitState === ReducerStatus.Succeeded || agentInitState === ReducerStatus.Failed;
  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    //create signalRHubConnector if needed - this should only run once
    if (_signalRConnector === null && accessToken) {
      const hub = getSignalRHub();
      hub.initSignalRHub(accessToken);
      const instance = hub.getInstance();

      // eslint-disable-next-line react-hooks/exhaustive-deps
      _signalRConnector = new SignalRHubConnector(instance);
    }
  }, []);

  if (currentUuid != null && !agentInitComplete) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive
        headerText={formatMessage({
          id: `AgentCommunicationLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `AgentCommunicationLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    );
  }

  return <>{children}</>;
};

export default AgentCommunicationWrapper;
