import { ReactNode } from "react";
import bg_xl from "assets/img/errorHeader_1200px.svg";
import bg_l from "assets/img/errorHeader_1024px.svg";
import bg_m from "assets/img/errorHeader_768px.svg";
import bg_s from "assets/img/errorHeader_362px.svg";
import { Box } from "@mui/material";
import styled from "@mui/styled-engine";

const Host = styled(Box)`
  height: 100%;
  width: 100%;
  display: "flex";
  align-items: "center";
  justify-content: "center";
`;

const BackgroundImageContainer = styled(Box)`
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 362px;
    height: 760px;
    background-image: url(${bg_s});
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 768px;
    height: 1024px;
    background-image: url(${bg_m});
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 1024px;
    height: 920px;
    background-image: url(${bg_l});
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 1200px;
    height: 920px;
    background-image: url(${bg_xl});
  }
`;

const ContentContainer = styled(Box)`
  display: "flex";
  align-items: "center";
  justify-content: "center";
`;

export interface IErrorHostProps {
  content: ReactNode;
}

export const ErrorHost: React.FC<IErrorHostProps> = ({ content }) => {
  return (
    <Host
      data-qatag="errorHost"
      id="errorHost"
    >
      <BackgroundImageContainer
        data-qatag="errorHost.backgroundImageContainer"
        id="errorHost.backgroundImageContainer"
      >
        <ContentContainer
          data-qatag="errorHost.contentContainer"
          id="errorHost.contentContainer"
        >
          {content}
        </ContentContainer>
      </BackgroundImageContainer>
    </Host>
  );
};
