import { Tabs, Tab, useMediaQuery, Select, MenuItem, FormControl, SelectChangeEvent, useTheme } from "@mui/material";
import styled from "@mui/styled-engine";

const TabWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTabs = styled(Tabs)`
  & .MuiTab-root {
    border: 1px solid transparent;
    font: normal normal 400 1rem Roboto;
    color: #535a6a;
    text-transform: capitalize;
  }
  & .MuiTabs-indicator {
    border-bottom: 2px solid #007dba;
  }
  & .Mui-selected {
    font-weight: 500;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background: #007dba;
    color: white;
  }
  &.Mui-selected {
    background: #007dba !important;
    color: white;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin-top: 20px;
  width: 100%;
`;

export type TabComponentProps = {
  tabs: string[];
  value: number;
  setValue: (value: number) => void;
};

function TabComponent({ tabs, value, setValue }: TabComponentProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setValue(event.target.value as number);
  };

  return (
    <TabWrapper data-qatag="TabWrapper">
      {isMobile ? (
        <StyledFormControl data-qatag="FormControl">
          <Select
            fullWidth={true}
            data-qatag="Select"
            labelId="tab-select-label"
            id="tab-select"
            value={value}
            onChange={handleSelectChange}
          >
            {tabs.map((tab, index) => (
              // eslint-disable-next-line solveiq/no-empty-data-qatag
              <StyledMenuItem
                data-qatag={tab}
                key={index}
                value={index}
              >
                {tab}
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>
      ) : (
        <StyledTabs
          data-qatag="StyledTabs"
          value={value}
          onChange={handleChange}
        >
          {tabs.map((tab, index) => (
            // eslint-disable-next-line solveiq/no-empty-data-qatag
            <Tab
              data-qatag={tab}
              label={tab}
              key={tab}
            />
          ))}
        </StyledTabs>
      )}
    </TabWrapper>
  );
}

export default TabComponent;
