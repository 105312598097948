import { useAppDispatch } from "app/redux/store";
import React, { useEffect } from "react";
import { getLocalAgentState, selectGetLocalAgentStateStatus } from "./AgentSlice";
import { useSelector } from "react-redux";
import { ReducerStatus } from "model/IReducerState";
import { Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

type Props = {
  children?: React.ReactNode;
};

const LocalAgentStatusWrapper = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const localAgentStateStatus = useSelector(selectGetLocalAgentStateStatus);

  useEffect(() => {
    if (localAgentStateStatus === ReducerStatus.Idle) {
      dispatch(getLocalAgentState());
    }
  }, []);

  const isLoading = localAgentStateStatus === ReducerStatus.Idle || localAgentStateStatus === ReducerStatus.Loading;

  if (isLoading) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive
        headerText={formatMessage({
          id: `LocalAgentLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `LocalAgentLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    );
  } else {
    return <>{children}</>;
  }
};

export default LocalAgentStatusWrapper;
