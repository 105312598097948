import { selectDeviceById } from "features/scan/ScanSlice";
import { selectCurrentUser } from "session/SessionSlice";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeContactModal, selectCurrentContactDeviceId } from "../DriverSlice";
import { submitContactForm } from "../Thunks";
import { IContactSubmitDetails } from "./DriverContactForm";
import { DriverContactView } from "./DriverContactView";
import { DriverContactSent } from "./DriverContactSent";

//#region Component

export const DriverContactModal: React.FC = () => {
  const deviceId = useSelector(selectCurrentContactDeviceId);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const deviceInfo = useSelector(selectDeviceById(deviceId));
  const [showSentModal, setShowSentModal] = useState<boolean>(false);

  if (deviceInfo == null) {
    return null;
  }

  let fullName = "";
  let email = "";
  if (user != null) {
    fullName = user.firstName + " " + user.lastName;
    email = user.email;
  }

  const onSubmit = async (details: IContactSubmitDetails) => {
    setShowSentModal(true);
    dispatch(submitContactForm(details));
  };

  const closeModalHandler = () => {
    dispatch(closeContactModal());
  };

  return (
    <>
      {showSentModal ? (
        <DriverContactSent
          data-qatag="DriverContactSent"
          closeHandler={closeModalHandler}
        />
      ) : null}
      <DriverContactView
        data-qatag="driver.contact.view"
        device={deviceInfo}
        initialName={fullName}
        initialEmail={email}
        onClose={() => {
          dispatch(closeContactModal());
        }}
        onSubmit={onSubmit}
      />
    </>
  );
};

//#endregion Component
