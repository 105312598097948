/* eslint-disable no-unused-vars */
export interface IUpdateMachineIntelligencePayload {
  ManufacturerID: number;
  ManufacturerName: string;
  SeriesID: number;
  SeriesName: string;
  SeriesIsLaptop: boolean;
  ModelID: number;
  ModelName: string;
  ModelIsLaptop: boolean;
  MatchType: MatchTypes;
  IntelligenceType: IntelligenceTypes;
}

export enum IntelligenceTypes {
  None = "None",
  OEM = "OEM",
  MB = "MB",
  Override = "Override",
}

export enum MatchTypes {
  None = "None",
  Model = "Model",
  FamilyOrSeries = "FamilyOrSeries",
  Manufacturer = "Manufacturer",
}
