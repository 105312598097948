import { Box, Paper, Typography } from "@mui/material";
import errorImage from "assets/img/illustration - Oops Error.svg";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styles from "./ErrorFallback.module.css";

export type ErrorFallbackProps = {
  error?: Error;
  children?: React.ReactNode;
  resetError: () => void;
};

export function ErrorFallback(props: ErrorFallbackProps) {
  return (
    <Paper
      data-qatag="errorFallback.root"
      className={styles.errorFallback}
    >
      <img
        data-qatag="errorFallback.image"
        id="errorFallback.image"
        src={errorImage}
        className={styles.image}
        alt=""
      />
      <FormattedMessage
        data-qatag="error.title"
        id="error.title"
        defaultMessage="Oops"
      >
        {(text) => (
          <Typography
            data-qatag="error.title.text"
            id="error.title.text"
            className={styles.title}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <FormattedMessage
        data-qatag="error.description"
        id="error.description"
        defaultMessage="Something went wrong.{br}We're currently working on it!"
        values={{
          br: (
            <br
              data-qatag="error.description.break"
              id="error.description.break"
            />
          ),
        }}
      >
        {(text) => (
          <Typography
            data-qatag="error.title.text"
            id="error.title.text"
            variant="h6"
            color="primary"
            align="center"
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="error.buttonContainer"
        id="error.buttonContainer"
        className={styles.buttonContainer}
      >
        <AppButton
          ariaLabel="Reload"
          data-qatag="error.reloadButton"
          onClick={props.resetError}
          buttonStyle={AppButtonStyles.Clear}
          className={styles.reloadButton}
          buttonType="button"
        >
          <FormattedMessage
            data-qatag="error.reloadButton.text"
            id="error.reloadButton.text"
            defaultMessage="Reload"
          >
            {(text) => (
              <Typography
                data-qatag="error.reloadButton.text.value"
                id="error.reloadButton.text.value"
                variant="body2"
                className={styles.buttonText}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </AppButton>
      </Box>
    </Paper>
  );
}
