import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Box, Icon, MenuItem, Select, Typography } from "@mui/material";
import { ExpandLess, ExpandMore, Settings } from "@mui/icons-material";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { LabelValuePair } from "features/SubscriptionManagement/MyAccount/components/LabelValuePair";
import { SettingsToUpdateType } from "../SettingsContainer";
import styled from "@mui/styled-engine";

const GeneralSettingsContainer = styled(Box)`
  width: 100%;
`;
const HeaderContainer = styled(Box)`
  align-self: flex-start;
  display: flex;
  margin: 20px 0;
  padding-left: 20px;
  gap: 12px;
`;
const HeaderText = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
  font-weight: 600;
`;
const LinkBlueAppButton = styled(AppButton)`
  text-decoration: underline;
`;
const StyledSelect = styled(Select)`
  max-height: 38px;
  padding: 0;

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 8px 16px;
  }

  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid #c1c7d0;
    }
  }
`;

const DropdownIcon = styled(Icon)`
  cursor: pointer;
`;

export type GeneralSettingsProps = {
  onChangeMachineClicked: () => void;
  onScanNowClicked: () => void;
  setShowScheduledScanDialog: React.Dispatch<React.SetStateAction<boolean>>;
  settingsObj: { [key: string]: IUserSettingModel };
  showScheduledScanDialog: boolean;
  updateSettings: (settingsToUpdate: SettingsToUpdateType[]) => void;
};
export function GeneralSettings({
  onChangeMachineClicked,
  onScanNowClicked,
  setShowScheduledScanDialog,
  settingsObj,
  showScheduledScanDialog,
  updateSettings,
}: GeneralSettingsProps) {
  const [open, setOpen] = useState(false);
  const [notificationsPerWeek, setNotificationsPerWeek] = useState(settingsObj.MaximumWeeklyAlerts.SettingValue);
  const notificationOptions = [5, 4, 3, 2, 1];
  const CustomArrowIcon = () => {
    return (
      <DropdownIcon
        data-qatag="icon"
        sx={{ borderLeft: "1px solid #C1C7D0" }}
        onClick={() => setOpen(!open)}
      >
        {open ? (
          <ExpandLess
            data-qatag="ExpandLessIcon"
            sx={{ stroke: "#ffffff", strokeWidth: 1 }}
          />
        ) : (
          <ExpandMore
            data-qatag="ExpandMoreIcon"
            sx={{ stroke: "#ffffff", strokeWidth: 1 }}
          />
        )}
      </DropdownIcon>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNotificationsUpdated = (event: any) => {
    const value = event.target.value;
    setNotificationsPerWeek(value);

    updateSettings([
      {
        newValue: value,
        userSettingObj: settingsObj.MaximumWeeklyAlerts,
      },
    ]);
  };
  return (
    <GeneralSettingsContainer data-qatag="generalSettingsContainer.box">
      <HeaderContainer data-qatag="headerContainer">
        <Settings
          data-qatag="headerContainer.icon"
          sx={{ color: "#007dba" }}
        />
        <HeaderText data-qatag="settings.text.header">
          <FormattedMessage
            id="settings.text.settings"
            data-qatag="settings.text.settings"
            defaultMessage="Settings"
          />
        </HeaderText>
      </HeaderContainer>
      <LabelValuePair
        data-qatag="settings.onDemandScan"
        label={"On Demand Scan"}
        tag="settings.onDemandScan"
        value="Rescan Now"
      >
        <LinkBlueAppButton
          data-qatag="RescanNow.button"
          ariaLabel="Rescan Now"
          buttonStyle={AppButtonStyles.LinkBlue}
          buttonType="button"
          onClick={onScanNowClicked}
        >
          <FormattedMessage
            id="AppButton.button.text.RescanNow"
            data-qatag="AppButton.button.text.RescanNow"
            defaultMessage="Rescan Now"
          />
        </LinkBlueAppButton>
      </LabelValuePair>
      <LabelValuePair
        data-qatag="settings.scheduledScans"
        label={"Scheduled Scans"}
        tag="settings.scheduledScans"
        value="Options"
      >
        <LinkBlueAppButton
          data-qatag="Options.button"
          ariaLabel="Options"
          buttonStyle={AppButtonStyles.LinkBlue}
          buttonType="button"
          onClick={() => setShowScheduledScanDialog(true)}
        >
          <FormattedMessage
            id="AppButton.button.text.options"
            data-qatag="AppButton.button.text.options"
            defaultMessage="Options"
          />
        </LinkBlueAppButton>
      </LabelValuePair>
      <LabelValuePair
        data-qatag="settings.pcModelDetection"
        label={"PC Model Detection"}
        tag="settings.pcModelDetection"
        value="Change Detection"
      >
        <LinkBlueAppButton
          data-qatag="ChangeDetection.button"
          ariaLabel="Change Detection"
          buttonStyle={AppButtonStyles.LinkBlue}
          buttonType="button"
          onClick={onChangeMachineClicked}
        >
          <FormattedMessage
            id="AppButton.button.text.ChangeDetection"
            data-qatag="AppButton.button.text.ChangeDetection"
            defaultMessage="Change Detection"
          />
        </LinkBlueAppButton>
      </LabelValuePair>
      <LabelValuePair
        data-qatag="settings.notificationsPerDay"
        label={"Notifications Per Day"}
        tag="settings.notificationsPerDay"
        value="Notifications Per Day"
      >
        <StyledSelect
          data-qatag="notificationsPerDay"
          id="notificationsPerDay"
          IconComponent={CustomArrowIcon}
          labelId="notificationsPerDay-label"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onChange={onNotificationsUpdated}
          value={notificationsPerWeek}
          open={open}
        >
          {notificationOptions.map((option, i) => (
            <MenuItem
              data-qatag="notificationOptions.option"
              key={i}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
      </LabelValuePair>
    </GeneralSettingsContainer>
  );
}
