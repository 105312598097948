import { Layout } from "components/Layout/Layout";
import IUser from "model/user/IUser";
import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";
import { Container } from "@mui/material";
import AddPcIcon from "assets/icon/icn-addpc-w-bg.svg";
import { AddPCSection } from "./AddPC/AddPCSection";
import { CenteredHeaderText } from "./SharedStyledComponents";
import { AddFamilySection } from "./AddFamily/AddFamilySection";
import { Alert, AlertIcon, Spinner } from "dsoneweb.designsystem";
import IFamilyMember from "model/user/IFamilyMember";
import { QueryErrorResponse } from "api/types";
import styled from "@mui/styled-engine";
import { Navigate } from "react-router";

const IconContainer = styled("div")`
  height: 66px;
  margin: 20px;
  width: 66px;
`;
const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    font-size: 1.5rem;
  }
`;

export interface IAddPCAddFamilyProps {
  addNewPC: boolean;
  backToFamily: () => void;
  errorAddingNewFamilyMember: boolean;
  errorSendingAddPcEmail: boolean;
  familyMembers: IFamilyMember[];
  fetchingFamilyError: QueryErrorResponse;
  handleAddFamilyMember: (name: string, email: string) => void;
  handleAddPC: () => void;
  handleDownloadAgent: () => void;
  isLoading: boolean;
  loggedInUser: IUser | null;
  showDownloadLinkError: boolean;
  successSendingAddPcEmail: boolean;
  hasAvailableLicenses: boolean;
}

function AddPCAddFamily({
  addNewPC,
  backToFamily,
  errorAddingNewFamilyMember,
  errorSendingAddPcEmail,
  familyMembers,
  fetchingFamilyError,
  handleAddFamilyMember,
  handleAddPC,
  handleDownloadAgent,
  isLoading,
  loggedInUser,
  showDownloadLinkError,
  successSendingAddPcEmail,
  hasAvailableLicenses,
}: IAddPCAddFamilyProps) {
  if (isLoading) {
    return (
      <Spinner
        data-qatag="AddPCAddFamilyContainer.spinner"
        isActive={true}
        text="Loading..."
      />
    );
  }

  const showAlertModal =
    errorAddingNewFamilyMember || errorSendingAddPcEmail || showDownloadLinkError || successSendingAddPcEmail;
  let modalIcon = AlertIcon.Warning;
  let modalTitle = "Something went wrong";
  let modalText = "Unable to add family member at this time";

  if (successSendingAddPcEmail) {
    modalIcon = AlertIcon.Success;
    modalTitle = "Success";
    modalText = "Email sent successfully";
  }

  if (showDownloadLinkError) {
    modalText = "Unable to download at this time";
  }

  if (errorSendingAddPcEmail) {
    modalText = "Unable to send download link at this time";
  }

  return (
    <>
      {/*if they are out of licenses redirect to license limit page*/}
      {!hasAvailableLicenses && (
        <Navigate
          data-qatag="licenseLimitRedirect"
          replace={true}
          to="/licenselimit"
        />
      )}
      <Layout
        data-qatag="layout.AddPCAddFamilyContainer"
        qatag="AddPCAddFamilyContainer"
      >
        <IconContainer data-qatag="AddPCAddFamilyContainer.IconContainer">
          <img
            alt="add PC icon"
            src={AddPcIcon}
            data-qatag="AddPCAddFamilyContainer.icon"
          />
        </IconContainer>
        <CenteredHeaderText data-qatag="AddPCAddFamilyContainer.headerTextContainer">
          <FixedFormattedMessage
            id={"AddPCAddFamilyContainer.headerText"}
            data-qatag="AddPCAddFamilyContainer.headerText"
            defaultMessage={
              addNewPC
                ? "Who is the primary user of the PC you will be adding?"
                : "Who is the primary user of this PC you are adding?"
            }
          />
        </CenteredHeaderText>
        <ContentContainer data-qatag="AddPCAddFamilyContainer.contentContainer">
          {isLoading ? (
            <Spinner
              data-qatag="AddPCAddFamilyContainer.spinner"
              isActive={true}
              text="Loading..."
            />
          ) : showAlertModal ? (
            <Alert
              approveHandler={backToFamily}
              buttonText="Back To My Family"
              closeHandler={backToFamily}
              data-qatag="alertModal"
              icon={modalIcon}
              isOpen={showAlertModal}
              text={modalText}
              title={modalTitle}
            />
          ) : familyMembers && loggedInUser ? (
            <>
              <AddPCSection
                data-qatag="AddPCAddFamilyContainer.addPCSection"
                addNewPC={addNewPC}
                loggedInUser={loggedInUser}
                handleAddPC={handleAddPC}
                handleDownloadAgent={handleDownloadAgent}
              />
              <AddFamilySection
                data-qatag="AddPCAddFamilyContainer.addFamilySection"
                familyMembers={familyMembers}
                onAddFamilyMember={handleAddFamilyMember}
              />
            </>
          ) : fetchingFamilyError ? (
            <>
              <FixedFormattedMessage
                id="fetchFamilyErrorMessage"
                data-qatag="fetchFamilyErrorMessage"
                defaultMessage={"There was an error getting family members"}
              />
            </>
          ) : null}
        </ContentContainer>
      </Layout>
    </>
  );
}

export { AddPCAddFamily };
