import { useNavigate } from "react-router";
import { ExpiredLicenseDialog } from "./ExpiredLicenseDialog";
import { useLocation } from "react-router-dom";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { useGetFamilyQuery } from "api/FamilyApi/FamilyApi";
import { useEffect, useState } from "react";
import IFamilyMember from "model/user/IFamilyMember";
import { useIntl } from "react-intl";

export type ExpiredLicenseDialogProps = {
  isAdmin: boolean;
  isOpen: boolean;
  closeHandler: () => void;
};

export function ExpiredLicenseDialogContainer({ isAdmin, isOpen, closeHandler }: ExpiredLicenseDialogProps) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [adminName, setAdminName] = useState<string>("");

  // We only need to look up (and set) the admin name if the user is not an admin
  const { data: licenseData } = useGetOrganizationLicensesQuery(undefined, { skip: isAdmin });
  const { data: familyMembers, isLoading: isFamilyLoading } = useGetFamilyQuery(licenseData, {
    skip: isAdmin || !licenseData,
  });

  useEffect(() => {
    if (!isAdmin && !isFamilyLoading && familyMembers) {
      const adminFamilyMember = familyMembers.find((x: IFamilyMember) => x.isAdmin);

      setAdminName(
        adminFamilyMember?.fullName ??
          formatMessage({ id: "features.status.expiredAlert.defaultAdminName", defaultMessage: "Admin" })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyMembers, isAdmin, isFamilyLoading]);

  const closeAlert = () => {
    closeHandler();
  };

  // Custom hook to check the current URL
  const useIsPaymentMethodRoute = () => {
    const location = useLocation();
    // this will include /paymentmethod/ and /paymentmethod/declined
    return location.pathname.startsWith("/paymentmethod");
  };

  const handleResumeSubscription = () => {
    closeAlert();
    navigate("/myaccount");
  };

  const isPaymentMethodRoute = useIsPaymentMethodRoute();

  if (isPaymentMethodRoute) {
    return null;
  }

  return (
    <ExpiredLicenseDialog
      data-qatag="ExpiredLicenseDialog"
      isAdmin={isAdmin}
      adminName={adminName}
      isOpen={isOpen}
      onApprove={isAdmin ? handleResumeSubscription : closeAlert}
      onClose={closeAlert}
      onCancel={closeAlert}
    />
  );
}
