import { useSelector } from "react-redux";
import { NewPCDetected } from "./NewPCDetected";
import { selectCurrentUser } from "session/SessionSlice";
import { useAddAdditionalPCMutation, useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { GetUserHasAllocatedLicenses } from "api/LicenseApi/LicenseApiHelpers";
import { Navigate, useNavigate } from "react-router";
import { Loading } from "main/Loading";
import * as Sentry from "@sentry/react";
import { useState } from "react";

declare const productId: number;

export function NewPCDetectedContainer() {
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = currentUser?.attributes?.toLowerCase()?.includes("admin") ?? false;
  const [hasLicensingError, setHasLicensingError] = useState(false);
  const [isProvisioningLicense, setIsProvisioningLicense] = useState(false);

  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
  } = useGetOrganizationLicensesQuery();

  const [sendAddAdditionalPC] = useAddAdditionalPCMutation();

  const hasAllocatedLicense = GetUserHasAllocatedLicenses(licensesData, currentUser);

  const downloadCallback = async () => {
    if (isAdmin && !hasAllocatedLicense) {
      setIsProvisioningLicense(true);
      await sendAddAdditionalPC({
        Email: currentUser?.email ?? "",
        ProductId: productId,
      })
        .unwrap()
        .catch((ex) => {
          Sentry.captureException(new Error(`Failed to allocate license`), {
            level: "error",
            extra: { exception: ex, user: currentUser },
          });
          setHasLicensingError(true);
        });
      setIsProvisioningLicense(false);
    }
    navigate("/downloadlander");
  };

  const declineCallback = () => {
    navigate("/family");
  };

  const isLoading = isLicensesDataLoading || isProvisioningLicense;
  const hasError = licensesDataError || hasLicensingError;

  if (isLoading) {
    return <Loading data-qatag="newPCDetectedLoading" />;
  }

  if (hasError) {
    return (
      <Navigate
        data-qatag="cannotLicensePCNavigate"
        to="/cannotlicensepc"
      />
    );
  }

  //family member with no allocated license send to cannotlicensepc page
  if (!isAdmin && !hasAllocatedLicense) {
    return (
      <Navigate
        data-qatag="cannotLicensePCNavigate"
        to="/cannotlicensepc"
      />
    );
  }
  return (
    <NewPCDetected
      data-qatag="NewPCDetected"
      downloadHandler={downloadCallback}
      cancelHandler={declineCallback}
      allowCancel={isAdmin}
    />
  );
}
