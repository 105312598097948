import { useLocation, useNavigate } from "react-router";
import { ResumeRequired } from "./ResumeRequired";

export function ResumeRequiredContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const returnPath = location.state?.returnPath;

  const handleResume = () => {
    navigate("/myaccount");
  };
  const handleDecline = () => {
    if (returnPath) {
      navigate(returnPath);
    } else {
      navigate("/");
    }
  };
  return (
    <ResumeRequired
      data-qatag="resumeRequiredContainer"
      handleResumeSubscription={handleResume}
      handleDecline={handleDecline}
    />
  );
}
