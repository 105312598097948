import { Box, Dialog } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { InstallConductor } from "./components/InstallConductor";
import { DeviceList } from "./DeviceList/DeviceList";
import { DriverContactModal } from "./modals/DriverContactModal";
import { Layout } from "components/Layout/Layout";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import styled from "@mui/styled-engine";
import { IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import { PNPModal } from "./modals/PNPModal";
import { Header } from "features/driverInstall/components/SharedStyledComponents";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import AddIcon from "@mui/icons-material/Add";

const AddPrinterButton = styled(AppButton)`
  align-items: center;
  display: inline-flex;
  height: 44px;
  justify-content: center;
  margin: 0;
  width: 280px;
  margin-top: 12px;
`;

const RescanButton = styled(AppButton)`
  height: 44px;
  width: 280px;
`;

const Title = styled("div")`
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: bold;
  color: #007dba;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type DriverInstallContainerProps = {
  allDevices: IDeviceInfo[];
  contactModalDeviceId: string | null;
  outOfDateDriverCount: number;
  devicesWithUpdates: IDeviceInfo[];
  isFilterVisible: boolean;
  statusMap: IDriverUpdateModel[];
  pnpDevice: IDeviceInfo | null;
  onAddPrinter: () => void;
  onPnpInstallClick: () => void;
  onPnpDialogClose: () => void;
  onRescan: () => void;
};

//#region Component
export function DeviceUpdates({
  allDevices,
  contactModalDeviceId,
  outOfDateDriverCount,
  devicesWithUpdates,
  isFilterVisible,
  statusMap,
  pnpDevice,
  onAddPrinter,
  onPnpInstallClick,
  onPnpDialogClose,
  onRescan,
}: DriverInstallContainerProps) {
  const renderAddPrinterButton = () => (
    <AddPrinterButton
      data-qatag="addPrinter"
      buttonStyle={AppButtonStyles.Green}
      buttonType="button"
      onClick={onAddPrinter}
      ariaLabel="Add Printer"
    >
      <AddIcon data-qatag="addPrinterPlusIcon" />
      <FormattedMessage
        data-qatag="driver.screen.header.subtitle.addPrinter"
        id="driver.screen.header.subtitle.addPrinter"
        defaultMessage="Add Printer"
      />
    </AddPrinterButton>
  );

  return (
    <Layout
      data-qatag="layout"
      qatag="layout"
      useTemplating={true}
    >
      <Box
        data-qatag="driver.screen"
        id="driver.screen"
      >
        <InstallConductor data-qatag="driver.screen.install_conductor" />
        {pnpDevice ? (
          <Dialog
            data-qatag="driver.screen.pnpDialog"
            id="driver.screen.pnpModal"
            open={pnpDevice != null}
            onClose={onPnpDialogClose}
            maxWidth="md"
          >
            <PNPModal
              data-qatag="driver.screen.pnpModal"
              device={pnpDevice}
              onInstall={onPnpInstallClick}
            />
          </Dialog>
        ) : null}
        <Dialog
          data-qatag="driver.screen.contact_host"
          id="driver.screen.contact_host"
          open={contactModalDeviceId != null}
          maxWidth="md"
        >
          {contactModalDeviceId != null && <DriverContactModal data-qatag="driver.screen.contact_modal" />}
        </Dialog>
        <Layout.DetachedFixedHeader data-qatag="driver.detachedHeader">
          <Header data-qatag="driver.header">
            <Title data-qatag="driver.screen.header.title.HeaderTitle">
              <FormattedMessage
                data-qatag="driver.screen.header.title"
                id="driver.screen.header.title"
                defaultMessage="Device Updates"
              />
              {renderAddPrinterButton()}
            </Title>
          </Header>
        </Layout.DetachedFixedHeader>
        <Layout.Content
          data-qatag="driver.layoutContent"
          hasFixedHeader
        >
          <DeviceList
            data-qatag="driver.screen.driver_list"
            allDevices={allDevices}
            devicesWithUpdates={devicesWithUpdates}
            isDeviceFilterVisible={isFilterVisible}
            keys={["friendlyName", "categoryName", "class", "description", "driverProvider"]}
            statusMap={statusMap}
          />
          {allDevices.length === 0 ? (
            <RescanButton
              data-qatag="rescanButton"
              buttonStyle={AppButtonStyles.Green}
              buttonType="button"
              onClick={onRescan}
              ariaLabel="Rescan"
            >
              <FormattedMessage
                data-qatag="driver.screen.rescan"
                id="driver.screen.rescan"
                defaultMessage="Rescan Now"
              />
            </RescanButton>
          ) : null}
        </Layout.Content>
      </Box>
    </Layout>
  );
}
