import { axiosBaseQuery } from "api/axiosBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

//Retrieve REACT_APP_PRODUCTID from .env file
declare const productId: number;

const autoInstallApi = createApi({
  reducerPath: "autoInstallApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/AutoInstall/",
  }),
  endpoints(build) {
    return {
      getAutoInstall: build.query({
        query: (email: string | undefined) => {
          return {
            url: `${email}/${productId}`,
            method: "get",
          };
        },
      }),
    };
  },
});

export const { useGetAutoInstallQuery } = autoInstallApi;
export { autoInstallApi };
