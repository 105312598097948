import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import { Modal } from "dsoneweb.designsystem";
import headerImage from "assets/img/icn-green-check.svg";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

export interface IAnalysisCategory {
  name: string;
  size: string;
  files: number;
  onClick: () => void;
}

export interface IAnalysisCompleteDialogProps {
  isOpen: boolean;
  categories: IAnalysisCategory[];
  closeDialogHandler: () => void;
  cleanHandler: () => void;
}

const Dialog = styled(Modal)`
  border-radius: 8px;
  padding: 14px 49px 47px;
  width: 660px;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: unset;
    padding: 14px 10px 24px;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
`;

const HeaderImage = styled("img")`
  width: 50px;
  height: 50px;
  margin-bottom: 13px;
`;

const Analysis = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 38px;
  margin-top: 35px;
`;

const Category = styled("div")`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  text-align: left;
  flex-basis: 33%;
  cursor: pointer;

  :nth-child(odd) {
    background-color: #f8f8f8;
    border-radius: 12px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 15px 13px;
  }
`;

const CategoryTitle = styled(Typography)`
  color: #007dba;
  text-decoration: underline;
  flex-basis: 33%;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-basis: 45%;
  }
`;

const CategorySize = styled(Typography)`
  flex-basis: 33%;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-basis: 25%;
  }
`;

const CategoryFiles = styled(Typography)`
  color: #0a9a1a;
  flex-basis: 33%;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-basis: 30%;
  }
`;

const CleanButton = styled(AppButton)`
  width: 240px;
  height: 44px;
  margin-bottom: 24px;
`;

const CancelButtonText = styled(Typography)`
  text-decoration: underline;
`;

export const AnalysisCompleteDialog: React.FC<IAnalysisCompleteDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.analysisCompleteDialog";

  const onCategoryClicked = (category: IAnalysisCategory) => {
    category.onClick();
  };

  return (
    <Dialog
      data-qatag="analysisCompleteDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
    >
      <Content data-qatag="analysisCompleteDialogContent">
        <HeaderImage
          data-qatag="headerImage"
          src={headerImage}
          alt="checkmark"
        />
        <Typography
          data-qatag="analysisCompleteDialogTitleTypography"
          align="center"
          variant="h4"
        >
          {formatMessage({
            id: `${prefix}.title`,
            defaultMessage: "Analysis Complete",
          })}
        </Typography>
        <Box
          data-qatag="AnalysisCompleteDialogDescriptionBox"
          mt={1}
          mb={2}
          textAlign="center"
        >
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage: "The following locations will be cleaned.",
          })}
        </Box>
        <Analysis data-qatag="analysis">
          {props.categories.map((category, index) => {
            return (
              // eslint-disable-next-line solveiq/no-empty-data-qatag
              <Category
                data-qatag={`analysisCategory_${index}`}
                key={`analysis-category-${category.name}`}
                onClick={() => onCategoryClicked(category)}
              >
                {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                <CategoryTitle
                  data-qatag={`categoryText_${index}`}
                  variant="body2"
                  align="left"
                >
                  {category.name}
                </CategoryTitle>
                {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                <CategorySize
                  data-qatag={`categoryText_${index}`}
                  variant="body2"
                  align="right"
                >
                  {category.size}
                </CategorySize>
                {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                <CategoryFiles
                  data-qatag={`categoryText_${index}`}
                  variant="body2"
                  align="right"
                >
                  {category.files}{" "}
                  {formatMessage({
                    id: `${prefix}.files`,
                    defaultMessage: "files",
                  })}
                </CategoryFiles>
              </Category>
            );
          })}
        </Analysis>
        <CleanButton
          data-qatag="cleanButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={props.cleanHandler}
          ariaLabel="clean up now"
          buttonType="button"
        >
          <Typography
            data-qatag="analysisCompleteDialogCleanTypography"
            variant="body2"
          >
            {formatMessage({
              id: `${prefix}.clean`,
              defaultMessage: "Clean Up Now",
            })}
          </Typography>
        </CleanButton>
        <AppButton
          data-qatag="skipButton"
          buttonStyle={AppButtonStyles.LinkBlue}
          onClick={props.closeDialogHandler}
          ariaLabel="cancel"
          buttonType="button"
        >
          <CancelButtonText
            data-qatag="analysisCompleteDialogCancelTypography"
            variant="body2"
          >
            {formatMessage({
              id: `${prefix}.cancel`,
              defaultMessage: "Cancel",
            })}
          </CancelButtonText>
        </AppButton>
      </Content>
    </Dialog>
  );
};
