import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FormHelperText, Icon, MenuItem, Select } from "@mui/material";
import { FixedFormattedMessage } from "./FixedFormattedMessage";
import { useState } from "react";
import { FieldProps } from "formik";
import styled from "@mui/styled-engine";

const StyledSelect = styled(Select)`
  background: white;
  font-size: 1rem;
  width: 100%;

  .MuiIcon-root {
    margin-right: 16px;
  }

  &.Mui-error {
    border-color: #be2222;

    .MuiInputBase-input {
      color: #be2222;
    }
    svg {
      color: #be2222;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1rem;
  height: 48px;

  &.Mui-selected:not(.Mui-disabled) {
    background: #347cb5;
    color: white;
  }
`;
const StyledFormHelperText = styled(FormHelperText)`
  font-size: 0.875rem;
  margin-top: 8px;
  text-align: center;
  &.error {
    color: #be2222;
    font-weight: 600;
  }
`;

const DropdownIcon = styled(Icon)`
  cursor: pointer;
`;

export interface OptionInterface {
  value?: string;
  label?: string;
}

export interface ISelect {
  helperText?: string;
  fieldProps: FieldProps;
  labelId: string;
  options: OptionInterface[];
  showChooseOption?: boolean;
  tag: string;
}

export function SelectDropdown({ helperText, fieldProps, labelId, options, showChooseOption, tag }: ISelect) {
  const { field, form, meta } = fieldProps;
  const { name } = field;
  const { setFieldValue } = form;
  const { error, value } = meta;
  const [open, setOpen] = useState(false);

  const CustomArrowIcon = () => {
    return (
      <DropdownIcon
        data-qatag="icon"
        onClick={() => setOpen(!open)}
      >
        {open ? <ExpandLessIcon data-qatag="ExpandLessIcon" /> : <ExpandMoreIcon data-qatag="ExpandMoreIcon" />}
      </DropdownIcon>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    const innerValue = event?.target?.value || "";

    if (innerValue) {
      setFieldValue(name, innerValue, true);
    }
  };

  return (
    <>
      <StyledSelect
        data-qatag="SelectDropdown.form.select"
        displayEmpty={showChooseOption}
        error={!!error}
        labelId={labelId}
        IconComponent={CustomArrowIcon}
        name={name}
        onChange={handleChange}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={value}
      >
        {showChooseOption ? (
          <StyledMenuItem
            data-qatag="SelectDropdown.menuItem"
            value=""
            disabled={true}
          >
            <FixedFormattedMessage
              id={`SelectDropdown.selectOption.chooseOne`}
              data-qatag="SelectDropdown.selectOption.chooseOne"
              defaultMessage="Choose an option"
            />
          </StyledMenuItem>
        ) : null}
        {options
          ? options.map(({ label, value: optionValue }) => (
              // eslint-disable-next-line solveiq/no-empty-data-qatag
              <StyledMenuItem
                data-qatag={`SelectDropdown.selectOption.${label}`}
                key={`option_${label}`}
                selected={false}
                value={optionValue}
              >
                {/*eslint-disable-next-line solveiq/no-empty-data-qatag*/}
                <FixedFormattedMessage
                  id={`SelectDropdown.selectOption.${label}Text`}
                  data-qatag={`SelectDropdown.selectOption.${label}Text`}
                  defaultMessage={label}
                />
              </StyledMenuItem>
            ))
          : null}
      </StyledSelect>
      {error ? (
        <StyledFormHelperText
          data-qatag="SelectDropdown.selectOption.errorText"
          className="error"
        >
          <FixedFormattedMessage
            id="SelectDropdown.selectOption.errorTextMessage"
            data-qatag="SelectDropdown.selectOption.errorTextMessage"
            defaultMessage={error}
          />
        </StyledFormHelperText>
      ) : null}
      {helperText && helperText !== "" ? (
        <StyledFormHelperText data-qatag="SelectDropdown.selectOption.helperText">
          <FixedFormattedMessage
            id="SelectDropdown.selectOption.helperTextMessage"
            data-qatag="SelectDropdown.selectOption.helperTextMessage"
            defaultMessage={helperText}
          />
        </StyledFormHelperText>
      ) : null}
    </>
  );
}
