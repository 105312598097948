import { IJitSoftware } from "model/interfaces/IJitTrigger";
import { IResourceFile } from "model/interfaces/IResourceFile";
import { ISystemOptimizationScanDocument } from "model/scan/SystemOptimizationScanDocument";
import { IFileCleanUpScanDocumentMessage } from "./FileCleanupMessages";
import { IServiceMessagePayload } from "./ServiceMessagePayload";

export interface AllScansCompleteMessage extends IServiceMessagePayload {
  ScanReason: string;
  HardwareScan: IHardwareScanMessage;
  PNPScans: IHardwareScanMessage;
  SystemScan: ISystemOptimizationScanDocument;
  JitTriggers: IJitSoftware[];
  CleanupScan: IFileCleanUpScanDocumentMessage;
}

export interface IHardwareScanMessage {
  Devices: IDeviceMessage[];
  RegistrationKey: string;
  UUID: string;
  ScanType: string;
  DeviceScanSummary: IDeviceScanSummaryMessage;
  OperatingSystemDisplayName: string;
  OperatingSystem: string;
}

export interface IScanProgressMessage {
  FailureReason: string;
  PercentComplete: number;
  ScanFailed: boolean;
  ScanTimeout: boolean;
}

export interface IDeviceScanSummaryMessage {
  OutOfDateCount: number;
  UnavailableMissingCount: number;
  UpToDateCount: number;
  TotalCount: number;
  SystemDeviceCount: number;
}

export interface IDeviceMessage {
  Description: string;
  Manufacturer: string;
  DeviceKey: string;
  Class: string;
  ClassName: string;
  ClassGUID: string;
  DriverDate: string;
  DriverVersion: string;
  DriverDescription: string;
  DriverProvider: string;
  IsSystem: boolean;
  HasFriendlyName: boolean;
  HardwareIDs: string[];
  CompatibleIDs?: string[];
  InstanceID: string;
  IsUnplugged: boolean;
  DriverUpdateType: string;
  DeviceID: string;
  SupportMetaData?: string;
  ResourceFiles: IResourceFile[];
  WUDriverUpdateValues?: string[];
  DriverUpdates?: IDriverUpdateMessage;
  CategoryDisplayName: string;
  Category: string;
  DriverStatus: string;
  HasUpdateAvailable: boolean;
  ClassRank: number;
  IsPnp: boolean;
  LocalIP: string;
}

export interface IDriverUpdateMessage {
  DriverProvider: string;
  RecommendedDriverGUID: string;
  RecommendedDriverVersion: string;
  RecommendedDriverDate: string;
  Severity: string;
  Class: string;
  Description: string;
  RecommendedDriverPackageFile: IRecommendedDriverPackageFileMessage;
}

export interface IRecommendedDriverPackageFileMessage {
  FileID: number;
  FileName: string;
  FileSize: number; // bytes
  FileType: number;
  FileDate: string;
  FileVersion: string;
  Hash: string;
  Mirror: string;
  Mirror2: string;
  Provider: string;
}

export interface IMachineIntelligence {
  FamilyData: IMachineIntelligenceDataMessage;
  IntelligenceType: string;
  IsVM: boolean;
  MachineDisplayName: string;
  ManufacturerData: IMachineIntelligenceDataMessage;
  MatchID: number;
  MatchType: string;
  MBManufacturer: string;
  MBModel: string;
  MBSeries: string;
  ModelData: IMachineIntelligenceDataMessage;
  Popularity: number;
  PopularityStrength: number;
  SmartInstantiation: boolean;
  WMIManufactrerModel?: string;
  WMIManufacturerName: string;
}

export type MachineInfo = {
  familyData: IMachineIntelligenceDataMessage;
  intelligenceType: string;
  isVM: boolean;
  machineDisplayName: string;
  manufacturerData: IMachineIntelligenceDataMessage;
  matchID: number;
  matchType: string;
  mbManufacturer: string;
  mbModel: string;
  mbSeries: string;
  modelData: IMachineIntelligenceDataMessage;
  popularity: number;
  popularityStrength: number;
  smartInstantiation: boolean;
  wmiManufactrerModel?: string;
  wmiManufacturerName: string;
};

export const MachineInfoInitialState = {
  familyData: {
    Key: 0,
    Value: "",
    IsLaptop: false,
    LogoID: "",
    LogoUrl: "",
  },
  intelligenceType: "",
  isVM: false,
  machineDisplayName: "",
  manufacturerData: {
    Key: 0,
    Value: "",
    IsLaptop: false,
    LogoID: "",
    LogoUrl: "",
  },
  matchID: 0,
  matchType: "",
  mbManufacturer: "",
  mbModel: "",
  mbSeries: "",
  modelData: {
    Key: 0,
    Value: "",
    IsLaptop: false,
    LogoID: "",
    LogoUrl: "",
  },
  popularity: 0,
  popularityStrength: 0,
  smartInstantiation: false,
  wmiManufactrerModel: undefined,
  wmiManufacturerName: "",
};

export interface IMachineIntelligenceDataMessage {
  Key: number;
  Value: string;
  IsLaptop: boolean;
  LogoID: string;
  LogoUrl: string;
}

export interface IHardwareScanPayloadMessage {
  HardwareScanDocument: IHardwareScanMessage;
  ScanType: string;
  ScanTypeName: string;
}

export interface IHardwareScanPayload {
  DeviceScanSummary: IDeviceScanSummaryMessage;
  Devices: IDeviceMessage[];
  MachineIntelligence: IMachineIntelligence;
  OperatingSystem: string;
  OperatingSystemDisplayName: string;
  ProductId: number;
  ScanType: string;
}

// TODO: ISystemInfoPayload needs to be improved with more fields
export interface ISystemInfoPayload {
  EventData: ISystemInfoScanMessage;
}

export interface ISystemInfoScanMessage {
  MachineIntelligence: IMachineIntelligence;
  DriveInfo: IDriveInfoMessage[];
  MemoryStatus: IMemoryStatusMessage;
  LastNetworkScan: INetworkScanDocumentMessage;
  GeoData: IGeoDataMessage;
  NetworkScanActive: boolean;
  OperatingSystem: IOperatingSystemMessage;
}

export interface IDriveInfoMessage {
  AvailableFreeSpace: number;
  DriveFormat: string;
  DriveType: string;
  Name: string;
  TotalFreeSpace: number;
  TotalSize: number;
  VolumeLabel: string;
}

export interface IMemoryStatusMessage {
  dwSize: number;
  MemoryLoad: number;
  TotalPhys: number;
  AvailPhys: number;
  TotalPageFile: number;
  AvailPageFile: number;
  TotalVirtual: number;
  AvailVirtual: number;
  AvailExtendedVirtual: number;
}

export interface INetworkScanDocumentMessage {
  ScanSucceeded: boolean;
  ScanError: string;
  CompletedDownloadTest: boolean;
  CompletedPingTest: boolean;
  DownloadError: string;
  PingError: string;
  MegabitsPerSecond: number;
  RTT: number;
  MTU: number;
  MSS: number;
  IPAddress: string;
  UploadMegabitsPerSecond: number;
  ScanInfo: IScanInfo;
}

export interface IScanInfo {
  Duration: string;
  LocalId: string;
  ScanReason: string;
  StartTime: string;
  StopTime: string;
}
export interface IGeoDataMessage {
  LocationInfo: IGeoLocationMessage;
  asn_Network: string;
  asn_Number: number;
  City: string;
  Continent: string;
  Country: string;
  IP: string;
  IspName: string;
  IspOrganization: string;
  IspLogo: IResourceFile;
  RegisteredCountry: string;
  RepresentedCountry: string;
  StateProvince: string;
  StateProvinceIsoCode: string;
}

export interface IGeoLocationMessage {
  AccuracyRadiusKM: number;
  Latitude: number;
  Longtitude: number;
  MetroCode: number;
  TimeZone: string;
}

export interface IOperatingSystemMessage {
  Abbreviation: string;
  DisplayName: string;
  Edition: string;
  FriendlyName: string;
  InstallDate: string;
  Is64Bit: boolean;
  MajorVersion: number;
  MinorVersion: number;
  OperatingSystemEnum: number;
  Platform: number;
  ProductType: number;
  ShortName: string;
  Version: IOperatingSystemVersionMessage;
}

export interface IOperatingSystemVersionMessage {
  Build: number;
  Major: number;
  MajorRevision: number;
  Minor: number;
  MinorRevision: number;
  Revision: number;
}
