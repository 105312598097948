import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { GetIsPaymentDeclined } from "api/LicenseApi/LicenseApiHelpers";
import { useGetSubscriptionQuery, useResumeSubscriptionMutation } from "api/SubscriptionApi/SubscriptionApi";
import { Alert, AlertIcon, Spinner } from "dsoneweb.designsystem";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { selectIsAdmin } from "session/SessionSlice";

export function DoResumeSubscription() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectIsAdmin);

  if (!isAdmin) {
    //non admins cannot resume subscriptions - send them back to status and replace history
    navigate("/status", { replace: true });
  }

  //#region RTK Queries
  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
  } = useGetOrganizationLicensesQuery();

  const {
    data: subscription,
    isFetching: isFetchingSubscription,
    error: fetchingSubscriptionError,
  } = useGetSubscriptionQuery();

  const [
    resumeSubscription,
    { isError: errorResumingSubscription, isLoading: isResumingSubscription, isSuccess: isResumingSuccess },
  ] = useResumeSubscriptionMutation();
  //#endregion

  const returnToMyAccount = () => {
    navigate("/myaccount", { replace: true });
  };

  //if their subscription is already active, redirect to my account, as we have nothing else to do here
  const isPlanActive = subscription?.subscription.status === "Active";
  if (isPlanActive) {
    returnToMyAccount();
  }

  //if their payment method is declined they'll need to update it.  redirect to payment decline flow
  const isPaymentDeclined = GetIsPaymentDeclined(licensesData);
  if (isPaymentDeclined) {
    navigate("/paymentmethod/payment-declined", { replace: true });
  }

  const isLoading = isFetchingSubscription || isLicensesDataLoading;

  useEffect(() => {
    //if we're done loading and they meet the criteria go ahead and kick off the resume
    if (!isLoading && !isPaymentDeclined && !isPlanActive) {
      resumeSubscription();
    }
  }, [isLoading, isPaymentDeclined, isPlanActive]);

  const hasErrors = fetchingSubscriptionError || licensesDataError;

  if (errorResumingSubscription) {
    navigate("/paymentmethod/payment-declined");
  }

  return (
    <>
      {isLoading || isResumingSubscription ? (
        <Spinner
          data-qatag="doResumeSubscriptionLoaderSpinner"
          isActive={true}
          text={formatMessage({
            id: "resumedSubscriptionLoaderSpinner.title",
            defaultMessage: "Loading...",
          })}
        />
      ) : null}
      {isResumingSubscription ? (
        <Spinner
          data-qatag="doResumeSubscriptionSpinner"
          isActive={true}
          text={formatMessage({
            id: "resumedSubscriptionSpinner.title",
            defaultMessage: "Resuming your subscription...",
          })}
        />
      ) : null}
      {/* Alert Error resuming subscription */}
      <Alert
        data-qatag="alertCTASelectPaymentMethod"
        modalMinHeight="388px"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "resumeSubscription.errorCTAButton.title",
          defaultMessage: "Something went wrong",
        })}
        text={formatMessage({
          id: "resumeSubscription.errorCTAButton.subtitle",
          defaultMessage: "Please retry again.",
        })}
        buttonText={formatMessage({
          id: "resumeSubscription.errorCTAButton.continue",
          defaultMessage: "Close",
        })}
        approveHandler={returnToMyAccount}
        isOpen={hasErrors ? true : false}
        closeHandler={returnToMyAccount}
      />
      {isResumingSuccess && (
        <Navigate
          data-qatag="resumedNavigate"
          replace
          to="/subscription/resumed"
        />
      )}
    </>
  );
}
