import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { OnOffSwitch } from "dsoneweb.designsystem";
import deceptorImage from "assets/img/deceptor/icn-deceptors.svg";
import allowedImage from "assets/img/deceptor/icn-no-deceptors-found.svg";
import { Box, Divider } from "@mui/material";
import { IDeceptorInfo } from "./DeceptorProtectionSlice";
import { DeceptorTab } from "./DeceptorTab";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { Layout } from "components/Layout/Layout";
import Header from "./Header/Header";
import Tabs from "components/Tabs/Tabs";
import styled from "@mui/styled-engine";

//#region styles

const Switch = styled(OnOffSwitch)`
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-self: flex-start;
  }
`;

const Emphasis = styled("span")`
  font-weight: 600;
`;

const TabsHeader = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const LogoImage = styled("img")`
  height: 24px;
  width: 25px;
`;

const Logo = styled(Box)`
  flex: 20%;
  height: 48px;
  max-width: 120px;
`;

const TabsContainer = styled(Box)`
  flex-basis: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: unset;
    margin: auto;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    transform: translateX(-60px);
  }
`;

const LogoText = styled("div")`
  color: ${({ theme }) => theme.palette.secondary.light};
  margin-left: 16px;
  font: normal normal 600 1.125rem Roboto;
  letter-spacing: 0.36px;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    font: normal normal 500 1.125rem Roboto;
  }
`;

const DividerBottom = styled(Box)`
  flex-basis: 100%;
  margin-top: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 0px;
  }
`;

//#endregion

export interface IDeceptorProtectionProps {
  blocked: IDeceptorInfo[];
  found: IDeceptorInfo[];
  allowed: IDeceptorInfo[];
  settings: IUserSettingModel[] | null;
  switchHandler: (isOn: boolean) => void;
  detailsClickHandler: (deceptor: IDeceptorInfo) => void;
}

export function DeceptorProtection({
  blocked,
  found,
  allowed,
  settings,
  switchHandler,
  detailsClickHandler,
}: IDeceptorProtectionProps) {
  // Tab number to be displayed
  const [tabNumber, setTabNumber] = useState(0);

  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "deceptorProtection.view";

  const protectionEnabled = settings?.find(
    (x) => x.SettingType === UserSettingTypes.EnableDeceptorServices
  )?.SettingValue;

  const onSwitchClick = async (e: React.MouseEvent<HTMLElement>, isOn: boolean) => {
    switchHandler(isOn);
  };

  const tabs = useMemo(
    () => [
      formatMessage({
        id: `${prefix}.blockedTab.tabText`,
        defaultMessage: "Deceptors Blocked/Found",
      }),
      formatMessage({
        id: `${prefix}.allowedTab.tabText`,
        defaultMessage: "Allowed Deceptors",
      }),
    ],
    [formatMessage]
  );

  //Need to filter blocked by filepath so duplicate exes that haven't actually been blocked still show.
  //Allowed can just use name because the deceptor slice will have created duplicate allowed entries
  //so none of the found entries need to stick around
  const dedupedFound = found.filter(
    (x) =>
      !blocked.find((y) => y.Name === x.Name && y.FilePath.toLowerCase() === x.FilePath.toLowerCase()) &&
      !allowed.find((y) => y.Name === x.Name)
  );

  //Side effect to show a default tab
  useEffect(() => {
    const url = new URL(window.location.href);

    const tabParam = url.searchParams.get("tab");
    if (tabParam === "allowed") {
      setTabNumber(1);
    }
  }, []);

  return (
    <Layout
      data-qatag="layout"
      qatag="layout"
      useTemplating={true}
    >
      <Layout.DetachedHeader data-qatag="appOptimization-header">
        <Header data-qatag="HeaderDeceptor">
          {protectionEnabled != null && (
            <Switch
              data-qatag="switch"
              onClick={onSwitchClick}
              isDisabled={false}
              isOnInitially={protectionEnabled}
            />
          )}
        </Header>
      </Layout.DetachedHeader>
      <Layout.Content data-qatag="appOptimization-content">
        <TabsHeader data-qatag="TabsHeader">
          {/* Logo section */}
          <Logo
            data-qatag="logo"
            display="flex"
            alignItems="center"
          >
            <LogoImage
              data-qatag="logoImage"
              src={tabNumber === 0 ? deceptorImage : allowedImage}
              alt="software"
            />
            <LogoText data-qatag="logoText">
              <FormattedMessage
                data-qatag="softwareText"
                id="body.app.optimization.software"
                defaultMessage="Deceptors"
              />
            </LogoText>
          </Logo>
          <TabsContainer data-qatag="TabsContainer">
            <Tabs
              data-qatag="tabs"
              tabs={tabs}
              setValue={setTabNumber}
              value={tabNumber}
            />
          </TabsContainer>
          {/* Divider */}
          <DividerBottom
            data-qatag="dividerBottom"
            width="100%"
          >
            <Divider data-qatag="Divider" />
          </DividerBottom>
        </TabsHeader>
        {/* Tabs content */}
        {tabNumber === 0 ? (
          <DeceptorTab
            data-qatag="deceptorsBlockedTab"
            itemImage={deceptorImage}
            deceptors={[...blocked, ...dedupedFound]}
            showLastBlocked={false}
            emptyTitle={formatMessage({
              id: `${prefix}.blockedTab.emptyTitle`,
              defaultMessage: "No Deceptors found on your PC.",
            })}
            emptyDescription={formatMessage({
              id: `${prefix}.blockedTab.emptyDescription`,
              defaultMessage: "Blocked Deceptors will show up here.",
            })}
            onDetailsClick={detailsClickHandler}
            description={
              <FormattedMessage
                data-qatag="deceptorsBlockedTabDescriptionMessage"
                id={`${prefix}.blockedTab.description`}
                defaultMessage="These are the deceptors that have been {blocked} on your pc."
                values={{
                  blocked: (
                    <Emphasis data-qatag="emphasis">
                      <FormattedMessage
                        data-qatag="deceptorsBlockedTabDescriptionBlockedMessage"
                        id={`${prefix}.blockedTab.description.blocked`}
                        defaultMessage="blocked"
                      />
                    </Emphasis>
                  ),
                }}
              />
            }
          />
        ) : null}
        {tabNumber === 1 ? (
          <DeceptorTab
            data-qatag="allowedSoftwareTab"
            itemImage={allowedImage}
            deceptors={allowed}
            showLastBlocked={false}
            emptyTitle={formatMessage({
              id: `${prefix}.allowedTab.emptyTitle`,
              defaultMessage: "You have not elected to Allow any Deceptor apps on your PC.",
            })}
            emptyDescription={formatMessage({
              id: `${prefix}.allowedTab.emptyDescription`,
              defaultMessage: "Allowed deceptors will show up here.",
            })}
            onDetailsClick={detailsClickHandler}
            description={
              <FormattedMessage
                data-qatag="allowedSoftwareTabDescriptionMessage"
                id={`${prefix}.allowedTab.description`}
                defaultMessage="Below are <b>deceptors</b> you have chosen to allow on your PC."
                values={{
                  b: (chunks) => <b data-qatag="emphasis">{chunks}</b>,
                }}
              />
            }
          />
        ) : null}
      </Layout.Content>
    </Layout>
  );
}
