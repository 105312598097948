import { Layout } from "components/Layout/Layout";
import { Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CanceledServiceIcon from "assets/icon/icn-canceled-service.svg";
import { CallToActionButton } from "../../MyAccount/components/AppButtons";
import {
  BlueHeaderText,
  CenteredParagraphText,
} from "features/SubscriptionManagement/MyAccount/components/StyledComponents";
import { useGetSubscriptionQuery } from "api/SubscriptionApi/SubscriptionApi";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "session/SessionSlice";
import { Alert, AlertIcon, Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import { isoToFormatted } from "utils/formatDates";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

const tagPrefix = "CancelledConfirmation";
const IconStyled = styled(Icon)`
  height: auto;
  margin: 20px;
  width: 82px;
`;
export function CancelledConfirmation() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const backToMyAccount = () => {
    navigate("/myaccount");
  };
  /* the selector and query responses will be cached, 
  since we can only get to this screen from 
  submitting the cancellation form
  */
  // double check in case user happens to route here?
  const isAdmin: boolean = useSelector(selectIsAdmin);
  const {
    data: subscription,
    isFetching: isFetchingSubscription,
    error: fetchingSubscriptionError,
  } = useGetSubscriptionQuery(undefined, { skip: !isAdmin });

  if (isFetchingSubscription && !fetchingSubscriptionError) {
    return (
      <Spinner
        data-qatag="loadingModal"
        isActive={true}
        text={formatMessage({
          id: "loadingModal.title",
          defaultMessage: "Loading...",
        })}
      />
    );
  }

  let cancelDate = "";

  if (subscription?.summary?.chargedThroughDate) {
    cancelDate = isoToFormatted(subscription.summary.chargedThroughDate, "MMM Do, YYYY");
  }

  if (fetchingSubscriptionError) {
    return (
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "cancelledSubscription.errorButton.title",
          defaultMessage: "Something went wrong",
        })}
        text={formatMessage({
          id: "cancelledSubscription.errorButton.subtitle",
          defaultMessage: "There was an issue fetching subscription information",
        })}
        buttonText={formatMessage({
          id: "cancelledSubscription.errorButton.continue",
          defaultMessage: "Back to My Account",
        })}
        modalMinHeight="388px"
        approveHandler={backToMyAccount}
        isOpen={true}
        closeHandler={backToMyAccount}
      />
    );
  }

  return (
    <Layout
      data-qatag="CancelledConfirmation"
      qatag="CancelledConfirmation"
    >
      <IconStyled data-qatag="CancelledConfirmation.iconContainer">
        <img
          data-qatag="CancelledConfirmation.iconImage"
          src={CanceledServiceIcon}
          alt="cancelled confirmation icon"
        />
      </IconStyled>
      <BlueHeaderText
        variant="h4"
        data-qatag="CancelledConfirmation.header"
        sx={{ color: "#007DBA", textAlign: "center" }}
      >
        <FormattedMessage
          id={`${tagPrefix}.headerText`}
          data-qatag="CancelledConfirmation.headerText"
          defaultMessage={"You've Cancelled Your Service"}
        />
      </BlueHeaderText>
      <CenteredParagraphText data-qatag="CancelledConfirmation.sorryToSeeYouGo1">
        <FormattedMessage
          id={`${tagPrefix}.sorryToSeeYouGoText1`}
          data-qatag="CancelledConfirmation.sorryToSeeYouGoText1"
          defaultMessage={
            "We are sorry to see you go. You can come back any time and restart your service again in the future."
          }
        />
      </CenteredParagraphText>
      <CenteredParagraphText data-qatag="CancelledConfirmation.terminationDate1">
        <FormattedMessage
          id={`${tagPrefix}.terminationDate1Text`}
          data-qatag="CancelledConfirmation.terminationDate1Text"
          defaultMessage={
            "Your service with <b>Driver Support One</b> will be terminated at the end of this billing cycle, <b>{cancelDate}</b>"
          }
          values={{
            b: (chunks) => <strong data-qatag="driverSupportOneBoldText">{chunks}</strong>,
            cancelDate,
          }}
        />
      </CenteredParagraphText>
      <CallToActionButton
        ariaLabel="Back to my account"
        data-qatag="button.backToMyAccount"
        linkPath={"/myaccount"}
        linkText={"Back To My Account"}
        tag={tagPrefix}
        buttonType="button"
      />
    </Layout>
  );
}
