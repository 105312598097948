import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/redux/store";
import {
  AgentDriverInstallStatus,
  AgentDriverUpdateStatus,
  createDriverUpdateModel,
  DriverDownloadStatus,
  DriverInstallStatus,
  DriverUpdateStatus,
  IDriverDownloadModel,
  IDriverInstallModel,
  IDriverInstallUpdateMessage,
  IDriverUpdateModel,
  mapToUiInstallStatus,
  mapToUiUpdateStatus,
} from "model/driver/DriverUpdateModel";

import { IDownloadProgressMessage } from "model/driver/DriverMessages";
import { WritableDraft } from "immer/dist/types/types-external";
import { IDriverUpdateMessage } from "model/messaging/messages/scanMessages";
import {
  driverInstallRequested,
  fetchDriverState,
  driverStateResponseReceived,
  submitContactForm,
  requestDownloadCancel,
} from "./Thunks";
import * as Sentry from "@sentry/react";
import { IReducerState, ReducerStatus } from "model/IReducerState";

interface IDriverState {
  driverUpdateRecords: IDriverUpdateModel[];
  currentInstallingDeviceId: string | null;
  currentContactModalDeviceId: string | null;
  submitContactFormPending: boolean;
}

const initialState: IReducerState<IDriverState> = {
  data: {
    driverUpdateRecords: [],
    currentInstallingDeviceId: null,
    currentContactModalDeviceId: null,
    submitContactFormPending: false,
  },
  status: {
    [fetchDriverState.typePrefix]: ReducerStatus.Idle,
  },
};

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    resetDriverState: (state) => {
      state.data = initialState.data;
    },
    closeContactModal: (state) => {
      state.data.currentContactModalDeviceId = null;
    },
    openContactModal: (state, action: PayloadAction<string>) => {
      state.data.currentContactModalDeviceId = action.payload;
    },
    resetDriverInstall: (state, action) => {
      // request a new driver status?
      state.data.currentInstallingDeviceId = null;
    },
    forceInstallWait: (state, action: PayloadAction<string>) => {
      const deviceId = action.payload;
      if (deviceId == null) {
        throw new Error("forceInstallWait::payload is missing");
      }

      const record = getUpdateRecordFromDeviceId(deviceId, state.data);
      if (record != null && record.installRecord != null) {
        record.installRecord.installStatus = DriverInstallStatus.InProgress;
      }
    },
    /*
      called by InstallComplete modal
    */
    driverInstallComplete: (state, action: PayloadAction<string>) => {
      // do something with deviceId in the future?
      const deviceId = action.payload;
      if (deviceId == null) {
        throw new Error("driverInstallComplete::payload is missing");
      }
      removeUpdateRecordByDeviceId(deviceId, state.data);
      state.data.currentInstallingDeviceId = null;
    },
    /*
      reducer for WSMessageType.UPDATE_DRIVER_STARTING
    */
    driverInstallStarting: (state, action: PayloadAction<IDriverInstallUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("driverInstallStarting::payload is missing");
      }
      const driverId = message.Record?.Update?.RecommendedDriverGUID;

      if (driverId == null) {
        Sentry.captureMessage("DriverSlice::driverInstallStarting called with invalid or missing driverId", "info");
        return;
      }

      const record = getUpdateRecordFromDriverId(driverId, state.data);

      if (record == null) {
        Sentry.captureMessage(
          "DriverSlice::driverInstallStarting unable to locate update record for driverId: " + driverId,
          "info"
        );
        return;
      }
    },
    /*
      reducer for WSMessageType.DRIVER_INSTALL_UPDATE
    */
    driverInstallUpdated: (state, action: PayloadAction<IDriverInstallUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("driverInstallUpdated::payload is missing");
      }

      const driverId = message?.Record?.Update?.RecommendedDriverGUID;

      // ignore message from states we don't care about.
      // THIS IS IMPORTANT. If we try to blindly handle these states
      // we can get stuck in the inprogress state.
      if (
        message.Status === AgentDriverInstallStatus.UnzipComplete ||
        message.Status === AgentDriverInstallStatus.UnzipStarted
      ) {
        return;
      }

      if (driverId == null) {
        throw new Error("driverInstallUpdated::Unable to find driverId in message");
      }

      const record = getUpdateRecordFromDriverId(driverId, state.data);

      if (record == null) {
        // possible if we get an update message before the driver status response
        // simply ignore the message until we process that status response
        return;
      }

      const newInstallStatus = mapToUiInstallStatus(message.Status);
      if (newInstallStatus == null) {
        throw new Error(
          "driverInstallStarting::Unexepected update state. Expected an install state but instead got: " +
            AgentDriverUpdateStatus[message.Status]
        );
      }

      record.updateStatus = mapToUiUpdateStatus(message.Status);
      record.installRecord = {
        installStatus: newInstallStatus,
        driverId: driverId,
      } as IDriverInstallModel;
    },
    /*
      reducer for WSMessageType.DOWNLOAD_DRIVER_PROGRESS
    */
    downloadProgressUpdated: (state, action: PayloadAction<IDownloadProgressMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("downloadProgressUpdated::payload is missing");
      }

      const driverId = message?.DriverUpdate?.RecommendedDriverGUID;

      if (driverId == null) {
        throw new Error("downloadProgressUpdated::Unable to find driverId in message");
      }

      let timeRemaining = "";
      if (message.ProcessEstimatedTimeRemaining != null) {
        const dateTime = new Date("1970-01-01T" + message.ProcessEstimatedTimeRemaining + "Z");
        timeRemaining = getTimeRemaining(dateTime);
      }

      const update = {
        downloadStatus: DriverDownloadStatus.InProgress,
        downloadPercentProgress: message.Progress,
        downloadCompletionETA: timeRemaining,
        driverId: driverId,
      } as IDriverDownloadModel;

      applyDownloadUpdate(update, state.data);
    },
    /*
      reducer for WSMessageType.DOWNLOAD_DRIVER_QUEUED
    */
    downloadQueued: (state, action: PayloadAction<IDriverUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("downloadQueued::payload is missing");
      }

      const driverId = message.RecommendedDriverGUID;

      if (driverId == null) {
        throw new Error("downloadQueued::Unable to find driverId in message");
      }

      const record = getUpdateRecordFromDriverId(driverId, state.data);

      if (record == null) {
        throw new Error("downloadQueued::Unable to locate update record for driverId: " + driverId);
      }

      record.updateStatus = DriverUpdateStatus.Queued;
      record.downloadRecord = null;
      record.installRecord = null;
    },
    /*
      reducer for WSMessageType.DOWNLOAD_DRIVER_CANCELLED
    */
    downloadCancelled: (state, action: PayloadAction<IDriverUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("downloadCancelled::payload is missing");
      }

      const driverId = message.RecommendedDriverGUID;

      if (driverId == null) {
        throw new Error("downloadCancelled::Unable to find driverId in message");
      }

      const record = getUpdateRecordFromDriverId(driverId, state.data);

      if (record == null) {
        throw new Error("downloadCancelled::Unable to locate update record for driverId: " + driverId);
      }

      const update = {
        downloadStatus: DriverDownloadStatus.Cancelled,
        downloadPercentProgress: 0,
        downloadCompletionETA: "",
        driverId: driverId,
      } as IDriverDownloadModel;

      applyDownloadUpdate(update, state.data);
    },
    /*
      reducer for WSMessageType.DOWNLOAD_DRIVER_COMPLETE
    */
    downloadComplete: (state, action: PayloadAction<IDriverUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("downloadComplete::payload is missing");
      }

      const driverId = message.RecommendedDriverGUID;

      if (driverId == null) {
        throw new Error("downloadComplete::Unable to find driverId in message");
      }

      const record = getUpdateRecordFromDriverId(driverId, state.data);

      if (record == null) {
        throw new Error("downloadComplete::Unable to locate update record for driverId: " + driverId);
      }

      record.updateStatus = DriverUpdateStatus.ReadyToInstall;
      record.downloadRecord = null;
      record.installRecord = null;
    },
    /*
      reducer for WSMessageType.DOWNLOAD_DRIVER_ERROR
    */
    downloadError: (state, action: PayloadAction<IDriverUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("downloadError::payload is missing");
      }

      const driverId = message.RecommendedDriverGUID;

      if (driverId == null) {
        throw new Error("downloadError::Unable to find driverId in message");
      }

      const record = getUpdateRecordFromDriverId(driverId, state.data);

      if (record == null) {
        throw new Error("downloadError::Unable to locate update record for driverId: " + driverId);
      }

      const update = {
        downloadStatus: DriverDownloadStatus.Error,
        downloadPercentProgress: 0,
        downloadCompletionETA: "",
        driverId: driverId,
      } as IDriverDownloadModel;

      applyDownloadUpdate(update, state.data);
    },
    /*
      reducer for WSMessageType.DOWNLOAD_DRIVER_STARTING
    */
    downloadStarting: (state, action: PayloadAction<IDriverUpdateMessage>) => {
      const message = action.payload;
      if (message == null) {
        throw new Error("downloadStarting::payload is missing");
      }

      const driverId = message?.RecommendedDriverGUID;

      if (driverId == null) {
        throw new Error("downloadStarting::Unable to find driverId in message");
      }

      const update = {
        downloadStatus: DriverDownloadStatus.Initializing,
        downloadPercentProgress: 0,
        downloadCompletionETA: "sometime in the future",
        driverId: driverId,
      } as IDriverDownloadModel;

      applyDownloadUpdate(update, state.data);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestDownloadCancel.pending, (state, action) => {
        const driverId = action.meta.arg;

        if (driverId != null) {
          // find the update record (bail if we can't find it)
          const record = state.data.driverUpdateRecords.find((r) => r.driverId === driverId);

          if (record != null && record.downloadRecord != null) {
            record.downloadRecord.downloadStatus = DriverDownloadStatus.Cancelling;
          }
        }
      })
      .addCase(submitContactForm.pending, (state, action) => {
        // optimistically close this
        // any errors with submission will be logged to sentry
        state.data.submitContactFormPending = false;
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        state.data.submitContactFormPending = false;
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.data.submitContactFormPending = false;
        Sentry.captureException(new Error("Failed to submit support case"), {
          extra: { payload: action.payload },
        });
      })
      .addCase(driverInstallRequested.pending, (state, action) => {
        const deviceId = action.meta.arg;
        const driverId = state.data.driverUpdateRecords.find((dur) => dur.devices.includes(deviceId))?.driverId;
        if (driverId != null) {
          // find the update record (bail if we can't find it)
          const record = state.data.driverUpdateRecords.find((r) => r.driverId === driverId);

          if (record != null) {
            record.updateStatus = DriverUpdateStatus.Installing;
            // create a new install record
            const installEntry = {
              installStatus: DriverInstallStatus.InProgress,
              driverId: driverId,
            } as IDriverInstallModel;

            // add install record to list
            //state.driverInstallRecords.push(installEntry);
            record.installRecord = installEntry;

            state.data.currentInstallingDeviceId = deviceId;
          }
        }
      })
      .addCase(fetchDriverState.pending, (state, action) => {
        state.status.deviceDriverReducerState = ReducerStatus.Loading;
      })
      .addCase(fetchDriverState.fulfilled, (state, action) => {
        state.status.deviceDriverReducerState = ReducerStatus.Succeeded;
      })
      .addCase(fetchDriverState.rejected, (state, action) => {
        state.status.deviceDriverReducerState = ReducerStatus.Failed;
        //state.error = action.error.message == null ? "" : action.error.message;
      })
      .addCase(driverStateResponseReceived.fulfilled, (state, action) => {
        const rawMessages = action.payload;
        let installingRecord = null;

        if (state.data.currentInstallingDeviceId != null) {
          installingRecord = getUpdateRecordFromDeviceId(state.data.currentInstallingDeviceId, state.data);
        }

        // don't clear the current installing deviceid (it will force the install modal closed)
        // we'll do that when the user closes the 'install complete' modal
        //state.currentInstallingDeviceId = null;
        state.data.driverUpdateRecords = [];
        if (installingRecord != null) {
          state.data.driverUpdateRecords.push(installingRecord);
        }

        rawMessages.forEach((m) => {
          const updateRecord = state.data.driverUpdateRecords.find(
            (r) => r.driverId === m.Update.RecommendedDriverGUID
          );

          // if an existing update record exists, append the deviceId to the list of devices
          if (updateRecord != null) {
            updateRecord.devices.push(m.DeviceID);
          } else {
            const updateModel = createDriverUpdateModel(m);
            state.data.driverUpdateRecords.push(updateModel);
          }
        });

        //state.driverUpdateRecords = newUpdateRecords;
      })
      .addCase(driverStateResponseReceived.rejected, (state, action) => {
        // TODO: handle fail
      });
  },
});

export const {
  resetDriverState,
  driverInstallUpdated,
  driverInstallStarting,
  driverInstallComplete,
  forceInstallWait,
  downloadProgressUpdated,
  downloadQueued,
  downloadCancelled,
  downloadStarting,
  downloadComplete,
  downloadError,
  resetDriverInstall,
  openContactModal,
  closeContactModal,
} = driverSlice.actions;

export const selectDriverUpdateMap = (state: RootState) => state.driver.data.driverUpdateRecords;
// export const selectProgress = (state: RootState) =>
//   state.scan.data?.scanProgress;

export const selectDownloadState = (driverId: string) => (state: RootState) => {
  if (driverId === "test") {
    return {
      downloadPercentProgress: 50,
      downloadCompletionETA: "20 minutes remainig",
      driverId: "test",
      downloadStatus: DriverDownloadStatus.InProgress,
    } as IDriverDownloadModel;
  }
  return state.driver.data.driverUpdateRecords.find((d) => d.driverId === driverId)?.downloadRecord;
};

export const selectCurrentInstallingDeviceId = (state: RootState) => state.driver.data.currentInstallingDeviceId;

export const selectCurrentContactDeviceId = (state: RootState) => state.driver.data.currentContactModalDeviceId;

export const selectDriverStateStatus = (state: RootState) => state.driver.status[fetchDriverState.typePrefix];

export default driverSlice.reducer;

/* 
  Internal helper methods. These methods are designed to be used with immer
  and should never be exposed externally.
*/
const getUpdateRecordFromDeviceId = (deviceId: string, state: WritableDraft<IDriverState>) => {
  if (deviceId == null) {
    throw new Error("Parameter 'deviceId' is required");
  }
  if (state == null) {
    throw new Error("Parameter 'state' is required");
  }
  const driverId = state.driverUpdateRecords.find((record) => record.devices.includes(deviceId))?.driverId;
  return driverId ? getUpdateRecordFromDriverId(driverId, state) : null;
};

const getUpdateRecordFromDriverId = (driverId: string, state: WritableDraft<IDriverState>) => {
  if (driverId == null) {
    throw new Error("Parameter 'driverId' is required");
  }
  if (state == null) {
    throw new Error("Parameter 'state' is required");
  }
  const record = state.driverUpdateRecords.find((r) => r.driverId === driverId);
  return record ? record : null;
};

const applyDownloadUpdate = (model: IDriverDownloadModel, state: WritableDraft<IDriverState>) => {
  // find the update record
  // if we can't find an update record just drop the message
  const updateRecord = state.driverUpdateRecords.find((ur) => ur.driverId === model.driverId);

  if (updateRecord == null) {
    return;
  }

  // if we cancelled the download, ignore all updates until we get the
  // cancelled message
  if (
    updateRecord.downloadRecord?.downloadStatus === DriverDownloadStatus.Cancelling &&
    model.downloadStatus !== DriverDownloadStatus.Cancelled
  ) {
    return;
  }

  updateRecord.updateStatus = DriverUpdateStatus.Downloading;

  const downloadRecord = updateRecord.downloadRecord;
  if (downloadRecord == null) {
    updateRecord.downloadRecord = model;
  } else {
    downloadRecord.downloadCompletionETA = model.downloadCompletionETA;
    downloadRecord.downloadPercentProgress = model.downloadPercentProgress;
    downloadRecord.downloadStatus = model.downloadStatus;
  }
};

const removeUpdateRecordByDeviceId = (deviceId: string, state: WritableDraft<IDriverState>) => {
  const removeIndex = state.driverUpdateRecords.findIndex((record) => record.devices.includes(deviceId));

  if (removeIndex < 0) {
    // couldn't find a record with the given deviceId
    return;
  }
  state.driverUpdateRecords = [
    ...state.driverUpdateRecords.slice(0, removeIndex),
    ...state.driverUpdateRecords.slice(removeIndex + 1),
  ];
};

// move this to ui.common
const getTimeRemaining = (timeAsUTCDate: Date) => {
  if (timeAsUTCDate.getUTCHours() === 24) {
    return "more than 24 hours remaining";
  }
  if (timeAsUTCDate.getUTCHours() > 0) {
    let hours = timeAsUTCDate.getUTCHours();
    if (timeAsUTCDate.getUTCMinutes() > 30) {
      hours = hours + 1;
    }
    return hours === 1 ? hours + " hour remaining" : hours + " hours remaining";
  }
  if (timeAsUTCDate.getUTCMinutes() > 0) {
    let minutes = timeAsUTCDate.getUTCMinutes();
    if (timeAsUTCDate.getUTCSeconds() > 30) {
      minutes = minutes + 1;
    }
    return minutes === 1 ? minutes + " minute remaining" : minutes + " minutes remaining";
  }

  return "less than a minute remaining";
};
