import { useGetPaymentMethodsQuery } from "api/BillingApi/BillingApi";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { GetIsPaymentDeclined, GetIsRefunded } from "api/LicenseApi/LicenseApiHelpers";
import {
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
  useResumeSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from "api/SubscriptionApi/SubscriptionApi";
import { selectAppVersion, selectLocalAgentHasValidLicense } from "app/Agent/AgentSlice";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { useAppDispatch } from "app/redux/store";
import { resetAllState } from "core/Helpers";
import useQuery from "hooks/useQuery";
import { fetchUsersNeedsToSetPassword } from "model/user/UserSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectCurrentUser, selectIsAdmin } from "session/SessionSlice";
import { useAuth } from "siq-oidc-react";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { MyAccount } from "./MyAccount";
import * as Sentry from "@sentry/react";
import { Alert, AlertIcon, Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

declare const productId: number;
declare let authServer: string;
declare let usePassword: boolean;

export function MyAccountContainer() {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser) || undefined;
  const isAdmin = useSelector(selectIsAdmin);
  const isLicensed = useSelector(selectLocalAgentHasValidLicense);
  const agentVersion = useSelector(selectAppVersion);
  const currentUser = useSelector(selectCurrentUser);
  const uuid = useSelector(selectCurrentUuid);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const resumeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const query = useQuery();
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [hasCancelError, setHasCancelError] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const { formatMessage } = useIntl();

  //redirect to set password page if required and they haven't done that
  useEffect(() => {
    if (!user || !usePassword) {
      return;
    }
    const doCheck = async () => {
      const redirect = await dispatch(fetchUsersNeedsToSetPassword(user.email));
      if (redirect?.payload ?? false) {
        const url = `${authServer}/setpassworddsone`;
        window.location.href = url;
      }
    };
    const skipValue = query?.get("skip") || "";
    if (!skipValue) {
      doCheck();
    }
  }, [dispatch, user, query]);

  //Retrieve licenses data
  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
  } = useGetOrganizationLicensesQuery();

  const {
    data: paymentMethods,
    isFetching: isFetchingPaymentMethod,
    error: fetchingPaymentMethodError,
  } = useGetPaymentMethodsQuery(undefined, { skip: !isAdmin });
  const {
    data: subscription,
    isFetching: isFetchingSubscription,
    error: fetchingSubscriptionError,
  } = useGetSubscriptionQuery(undefined, { skip: !isAdmin });

  const isPlanActive = subscription?.subscription.status === "Active";

  const hasErrors = fetchingPaymentMethodError || fetchingSubscriptionError || licensesDataError;

  // Resume subscription
  const [
    resumeSubscription,
    {
      isError: errorResumingSubscription,
      isLoading: isResumingSubscription,
      isSuccess: isResumingSuccess,
      reset: resetResumingSubscription,
    },
  ] = useResumeSubscriptionMutation();

  useEffect(() => {
    if (isResumingSuccess) {
      navigate("/subscription/resumed");
    }
  }, [isResumingSuccess]);

  // upgrade to premium
  const [, { isLoading: isUpgradingSubscription }] = useUpgradeSubscriptionMutation({ fixedCacheKey: "upsell" });

  // check if license is valid, then clear the timeout and redirect to resumed subscription page
  useEffect(() => {
    if (isLicensed && resumeTimerRef.current) {
      clearTimeout(resumeTimerRef.current);
      navigate("/subscription/resumed");
    }
  }, [isLicensed]);

  const sendLicenseCheck = async () => {
    const hub = getSignalRHub();
    const signalRHub = hub.getInstance();

    const message: IServiceMessage = new ServiceMessage();
    message.Payload = {
      AgentVersion: agentVersion,
      MachineName: "",
      MachineString: "",
      ProductID: productId,
      RegistrationKey: currentUser?.registrationKey,
      ShowUI: false,
      Token: null,
      UUID: uuid,
    };
    message.MessageType = WSMessageType.CHECK_LICENSE;

    const response = await signalRHub.SendAsync(message);
    return response;
  };

  useEffect(() => {
    async function checkLicenseAndSetTimeout() {
      const licenseResponse = await sendLicenseCheck();
      if (licenseResponse) {
        // wait for license to be valid, otherwise reload the page
        resumeTimerRef.current = setTimeout(() => {
          window.location.reload();
        }, 60000);
      }
    }

    if (!isResumingSuccess) return;

    checkLicenseAndSetTimeout();
  }, [isResumingSuccess]);

  // if there is an error resuming subscription the user can select go to payment method page
  const handleRedirectToSelectPaymentMethod = useCallback(() => {
    navigate("/paymentmethod/payment-declined");
  }, [navigate]);

  // Verify if the Payment method is declined
  const isPaymentMethodDeclined = GetIsPaymentDeclined(licensesData);

  const isRefund = GetIsRefunded(licensesData);

  //calc upgrade offer
  const hasAllFeatures = licensesData?.licenseTier?.featureFlags === "AllFeatures";
  const offerUpgrade = subscription?.subscription.status === "Active" && !hasAllFeatures;
  const isUpgradable =
    !isFetchingSubscription &&
    typeof subscription?.upgradeCheckoutModel === "string" &&
    subscription?.upgradeCheckoutModel.length > 0;

  const isWaitingForUpdatedLicense = Boolean(resumeTimerRef.current);

  const isLoading =
    isFetchingPaymentMethod || isFetchingSubscription || isLicensesDataLoading || isWaitingForUpdatedLicense;

  const onLogoutClicked = () => {
    resetAllState(dispatch);
    //give the reset actions a bit of time to go through
    setTimeout(async () => {
      await auth.signOut();
      window.location.href = `${authServer}/logoutdsone`;
    }, 100);
  };

  const onCancelServiceClicked = async () => {
    setIsCancelling(true);
    await cancelSubscription()
      .unwrap()
      .then((val) => {
        navigate("/cancelledConfirmation");
      })
      .catch((ex) => {
        Sentry.captureException(new Error(`Failed to cancel subscription`), {
          level: "error",
          extra: { exception: ex, user: user },
        });
        //TODO: product team will determine what should happen when it errors
        //for now, just display an error alert
        setHasCancelError(true);
      });
  };

  const handleResetCancelError = () => {
    setHasCancelError(false);
  };

  return (
    <>
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "cancelError.title",
          defaultMessage: "Something went wrong",
        })}
        text={formatMessage({
          id: "cancelError.subtitle",
          defaultMessage: "Please retry again.",
        })}
        buttonText={formatMessage({
          id: "cancelError.continue",
          defaultMessage: "Close",
        })}
        modalMinHeight="388px"
        approveHandler={handleResetCancelError}
        isOpen={hasCancelError}
        closeHandler={handleResetCancelError}
      />
      <Spinner
        data-qatag="cancellingSpinner"
        isActive={isCancelling}
        text={formatMessage({
          id: `cancelling.spinner`,
          defaultMessage: "May Take 2 Minutes To Cancel",
        })}
      />
      <MyAccount
        data-qatag="myAccountContainer"
        fetchingPaymentMethodError={fetchingPaymentMethodError}
        fetchingSubscriptionError={fetchingSubscriptionError}
        handleRedirectToSelectPaymentMethod={handleRedirectToSelectPaymentMethod}
        isAdmin={isAdmin}
        isErrorResumingSubscription={errorResumingSubscription}
        isFetchingData={isLoading && !hasErrors}
        isLoading={isResumingSubscription}
        isPlanActive={isPlanActive}
        isPaymentMethodDeclined={isPaymentMethodDeclined}
        isRefund={isRefund}
        isUpgrading={isUpgradingSubscription}
        offerUpgrade={offerUpgrade && isUpgradable}
        resetResumingSubscription={resetResumingSubscription}
        resumeSubscription={resumeSubscription}
        savedPaymentMethods={paymentMethods}
        subscription={subscription?.subscription}
        subscriptionSummary={subscription?.summary}
        user={user}
        onLogoutClicked={onLogoutClicked}
        onCancelServiceClicked={onCancelServiceClicked}
      />
    </>
  );
}
