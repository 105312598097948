import "./index.css";
import { store } from "./app/redux/store";
import { Provider } from "react-redux";
import { IntlProvider } from "./components/providers";
import { defaultTheme } from "dsoneweb.designsystem";
import * as Sentry from "@sentry/react";
import { AuthProvider } from "siq-oidc-react";
import { ErrorFallback } from "main/ErrorFallback";
import { CssBaseline } from "@mui/material";
import SessionWrapper from "./session/SessionWrapper";
import App from "main/App";
import { InitializePersistQsParams, InitializeSentry } from "applicationInitialization/applicationInitialization";
import AgentCommunicationWrapper from "app/Agent/AgentCommunicationWrapper";
import { BrowserRouter } from "react-router-dom";
import { Theme, ThemeProvider, createTheme } from "@mui/material/styles";
import { ReactNodeLike } from "prop-types";
import LocalAgentStatusWrapper from "app/Agent/LocalAgentStatusWrapper";
import { FromCartLicenseWrapper } from "app/Agent/FromCartLicenseWrapper";
import styled from "@mui/styled-engine";

declare let authServer: string;
declare let traceSampleRate: number;
declare let authReturnUrl: string;
declare let usePassword: boolean;

const urlParams = new URLSearchParams(window.location.search);
const paramsObj = Object.fromEntries(urlParams.entries());
const qsToken = urlParams.get("refToken");

const AppRoot = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export function AppProvider() {
  //init
  InitializeSentry(
    "https://098b3ea4af2f6b0056022c4459b8f183@o94524.ingest.sentry.io/4505665419149312",
    "solve-iq-business-ui@" + process.env.REACT_APP_VERSION,
    process.env.NODE_ENV || "development",
    traceSampleRate
  );

  InitializePersistQsParams();
  //end init

  return (
    <Provider
      data-qatag="provider"
      store={store}
    >
      <IntlProvider data-qatag="intlProvider">
        <Sentry.ErrorBoundary
          data-qatag="errorBoundary"
          fallback={ErrorFallback}
        >
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline>
              <LocalAgentStatusWrapper data-qatag="LocalAgentStatusWrapper">
                <AuthProvider
                  data-qatag="authProvider"
                  scope="openid profile email solveiq_business_gateway_scope"
                  authority={authServer}
                  clientId="solveiq-business-spa-client"
                  redirectUri={authReturnUrl}
                  additionalQueryArgs={{
                    refToken: qsToken,
                    acr_values: "tenant:dsoneweb",
                    usePassword,
                    ...paramsObj,
                  }}
                >
                  <AppRoot
                    data-qatag="appRoot"
                    id="appRoot"
                  >
                    <BrowserRouter data-qatag="Router">
                      <SessionWrapper data-qatag="SessionWrapper">
                        <AgentCommunicationWrapper data-qatag="AgentCommunicationWrapper">
                          <FromCartLicenseWrapper data-qatag="FromCartLicenseWrapper">
                            <App data-qatag="MainApp" />
                          </FromCartLicenseWrapper>
                        </AgentCommunicationWrapper>
                      </SessionWrapper>
                    </BrowserRouter>
                  </AppRoot>
                </AuthProvider>
              </LocalAgentStatusWrapper>
            </CssBaseline>
          </ThemeProvider>
        </Sentry.ErrorBoundary>
      </IntlProvider>
    </Provider>
  );
}

type StorybookProviderProps = {
  theme: Theme;
  children: ReactNodeLike;
};
export function StorybookProvider({ theme = defaultTheme, children }: StorybookProviderProps) {
  const serialTheme = JSON.parse(JSON.stringify(theme));
  const muiTheme = createTheme(serialTheme);

  return (
    <Provider
      data-qatag="provider"
      store={store}
    >
      <IntlProvider data-qatag="intlProvider">
        <ThemeProvider theme={muiTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
}
