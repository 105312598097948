import prettyBytes from "pretty-bytes";

/**
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = true, dp = 1) {
  if (bytes === 0) {
    return "0 bytes";
  }

  // the only time we should be using non-si units is for displaying memory sizes
  // file sizes should ALWAYS be SI units.
  return prettyBytes(bytes, { maximumFractionDigits: dp, binary: !si });
}
