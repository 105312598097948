import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import illustration from "assets/img/downloadViewIllustration.svg";
import backgroundSVG from "assets/img/blue-bg-master.svg";
import styled from "@mui/styled-engine";

export type DownloadProps = {
  /* Callback to download the software */
  downloadHandler: () => void;
};

//#region component styles

const BackgroundContainer = styled(Box)`
  background: url(${backgroundSVG});
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled(Box)`
  width: 320px;
  height: 540px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 700px;
    height: 600px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 900px;
    height: 700px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 1024px;
  }
`;

const DownloadIllustration = styled("img")`
  flex-basis: 100%;
  max-width: 220px;
  margin-top: 44px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 64px;
    max-width: 288px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 72px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: 132px;
  }
`;

const Title = styled(Box)`
  margin-top: 52px;
  text-align: center;
  font: normal normal 500 1.3125rem/1.625rem Roboto;
  flex-basis: 100%;
  max-width: 262px;
  color: #535353;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 436px;
    margin-top: 20px;
    font: normal normal 500 1.75rem/2.375rem Roboto;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 100%;
    margin-top: 60px;
    font: normal normal 500 1.75rem/3.125rem Roboto;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: 32px;
    font: normal normal 500 2rem/3.125rem Roboto;
  }
`;

const Subtitle = styled(Box)`
  margin-top: 16px;
  text-align: center;
  font: normal normal normal 1rem/1.375rem Roboto;
  flex-basis: 100%;
  color: #535353;
  max-width: 280px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal normal 1.3125rem/2.25rem Roboto;
    max-width: 580px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    font: normal normal normal 1.5rem/2rem Roboto;
    max-width: 100%;
  }
`;

const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DownloadButton = styled(AppButton)`
  max-width: 278px;
  margin: 52px auto 86px auto;
  text-align: center;
  font: normal normal 500 1rem/1.3125rem Roboto;
  letter-spacing: 0.16px;
  flex-basis: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 60px auto 72px auto;
    font: normal normal normal 1.3125rem/2.25rem Roboto;
    letter-spacing: 0.18px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: 56px auto 86px auto;
    font: normal normal bold 1.125rem/1.5rem Roboto;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin: 56px auto 132px auto;
  }
`;

const DriverLabel = styled("span")`
  font-weight: 600;
`;

//#endregion component styles

function DownloadPage({ downloadHandler }: DownloadProps) {
  return (
    <BackgroundContainer data-qatag="downloadView.container">
      <Content data-qatag="content">
        {/* Illustration */}
        <DownloadIllustration
          data-qatag="addFamilyIllustration"
          src={illustration}
          alt="Download"
        />

        {/* Title */}
        <Title data-qatag="titleContainer">
          <FormattedMessage
            data-qatag="download-view.title"
            id="download-view.title"
            defaultMessage="Download and install below to add this PC for yourself"
          />
        </Title>

        {/* Subtitle */}
        <Subtitle data-qatag="subtitleContainer">
          <FormattedMessage
            data-qatag="download-view.subtitle"
            id="download-view.subtitle"
            defaultMessage="Get started by installing the {driverLabel} desktop software."
            values={{
              driverLabel: (
                <DriverLabel
                  data-qatag="bold"
                  className="bold"
                >
                  <FormattedMessage
                    data-qatag="download-view.driver-label"
                    id="download-view.driver-label"
                    defaultMessage="Driver Support"
                  />
                </DriverLabel>
              ),
            }}
          />
        </Subtitle>

        {/* Button */}
        <ButtonContainer data-qatag="buttonContainer">
          <DownloadButton
            ariaLabel="Download"
            buttonType="button"
            data-qatag="customButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={downloadHandler}
          >
            <FormattedMessage
              data-qatag="download-view.download"
              id="download-view.download"
              defaultMessage="Download"
            />
          </DownloadButton>
        </ButtonContainer>
      </Content>
    </BackgroundContainer>
  );
}

export default DownloadPage;
