import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

export type LoginFromPCModalProps = {
  onClose: () => void;
};

export function LoginFromPCModal({ onClose }: LoginFromPCModalProps) {
  const { formatMessage } = useIntl();

  return (
    <Alert
      data-qatag="please.login.from.pc.alert"
      title={formatMessage({
        id: `close`,
        defaultMessage: "Please Login From a PC",
      })}
      icon={AlertIcon.DSOne}
      text=""
      approveHandler={onClose}
      buttonText={formatMessage({
        id: `close`,
        defaultMessage: "Logout",
      })}
      isOpen={true}
      closeHandler={onClose}
    />
  );
}

export default LoginFromPCModal;
