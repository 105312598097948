import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import { DeceptorDetailsContainer } from "features/deceptorProtection/DeceptorDetailsContainer";
import { DriverInstallContainer } from "features/driverInstall/DeviceUpdatesContainer";
import Optimization from "features/Optimization/OptimizationContainer";
import InviteSent from "features/Family/InviteSent/InviteSent";
import React, { useEffect, useRef, useState } from "react";
import { Logout } from "./Logout";
import LicenseLimitScreenContainer from "features/Family/LicenseLimit/LicenseLimitScreenContainer";
import { BillingHistoryContainer } from "features/SubscriptionManagement/BillingHistory/BillingHistoryContainer";
import { StatusScreenContainer } from "features/status/StatusScreenContainer";
import { useSelector } from "react-redux";
import { selectAllocatedLicenses, selectCurrentUser, selectIsAdmin } from "session/SessionSlice";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { selectAgentInfo, selectHasLocalAgent, selectIsLocalAgentUnlicensed } from "app/Agent/AgentSlice";
import {
  IServiceMessage,
  ServiceMessage,
  WSMessageType,
  identifyFullStory,
  reportFullStoryEvent,
  setFullStoryEnv,
  useMount,
} from "ui.common";
import ResumedSubscription from "features/SubscriptionManagement/ResumedSubscription/ResumedSubscriptionContainer";
import { MyAccountContainer } from "features/SubscriptionManagement/MyAccount/MyAccountContainer";
import { CancelledConfirmation } from "features/SubscriptionManagement/CancelFlow/CancelledConfirmation/CancelledConfirmation";
import { UpdatePaymentMethod } from "features/SubscriptionManagement/UpdatePaymentMethod/UpdatePaymentMethod";
import { UpdateInformationContainer } from "features/SubscriptionManagement/UpdateInformation/UpdateInformationContainer";
import UpdatedInformationSuccess from "features/SubscriptionManagement/MyAccount/UpdatedInformationSuccess";
import PaymentUpdatedSuccess from "features/SubscriptionManagement/UpdatePaymentMethod/PaymentUpdatedSuccess";
import { FamilyContainer } from "features/Family/FamilyContainer";
import UpSellPromotion from "features/SubscriptionManagement/UpSellPromotion/UpSellPromotionContainer";
import { AddPCAddFamilyContainer } from "features/Family/AddPCAddFamily/AddPCAddFamilyContainer";
import { ConfirmRemoveFamilyMember } from "features/Family/RemoveFamily/ConfirmRemoveFamilyMember";
import { FeedbackFormContainer } from "features/SubscriptionManagement/CancelFlow/FeedbackForm/FeedbackFormContainer";
import { FileCleaningContainer } from "features/fileCleaning/FileCleaningContainer";
import { WindowsEnhancementsContainer } from "features/windowsEnhancements/WindowsEnhancementsContainer";
import { DeceptorProtectionContainer } from "features/deceptorProtection/DeceptorProtectionContainer";
import { Navigate, Route, Routes } from "react-router";
import { SettingsContainer } from "features/settings/SettingsContainer";
import { ScanScreenContainer } from "features/scan/ScanScreenContainer";
import { DnsProtectionContainer } from "features/dnsProtection/DnsProtectionContainer";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import useQuery from "hooks/useQuery";
import { ExecuteQueryablePromise, PromiseState } from "utils/ExecuteQueryablePromise";
import { Spinner } from "dsoneweb.designsystem";
import useAmplitude from "hooks/useAmplitude";
import { ILicenseData } from "model/license/ILicenseData";
import { DoResumeSubscription } from "features/SubscriptionManagement/DoResumeSubscription/DoResumeSubscription";
import { GetIsRefunded } from "api/LicenseApi/LicenseApiHelpers";
import LicensedFeatureWrapper from "features/SubscriptionManagement/LicensedFeatureWrapper";
import { ResumeRequiredContainer } from "features/SubscriptionManagement/UpSellPromotion/ResumeRequiredContainer";
import AddPrinter from "features/driverInstall/AddPrinter";
import { DownloadPageContainer } from "features/downloadPage/DownloadPageContainer";
import { DownloadLanderContainer } from "features/downloadLander/DownloadLanderContainer";
import { NewPCDetectedContainer } from "features/NewPCDetected/NewPCDetectedContainer";
import { CannotLicensePCContainer } from "features/CannotLicensePC/CannotLicensePCContainer";
import { SummaryContainer } from "features/Summary/SummaryContainer";
import Support from "features/Support/Support";
import SupportConfirmationContainer from "features/Support/SupportConfirmationContainer";
import { MachinePickerContainer } from "features/MachinePicker/MachinePickerContainer";

declare const productId: number;

interface IAppRouterProps {
  hasSignalRConnection: boolean;
  redirectToScan: boolean;
  hasMachineIntelligence: boolean;
  licenses: ILicenseData;
}

const AppRouter: React.FC<IAppRouterProps> = (props) => {
  const { track } = useAmplitude();
  const currentUser = useSelector(selectCurrentUser);
  const currentUUID = useSelector(selectCurrentUuid);
  const agentInfo = useSelector(selectAgentInfo);
  const identified = useRef(false);
  const currentUrl = window.location.pathname;
  const currentRoute = currentUrl.slice(1);
  const query = useQuery();
  const srcCode = query.get("srccode") || query.get("srcCode") || "cart";
  const isAdmin = useSelector(selectIsAdmin);
  const isRefunded = GetIsRefunded(props.licenses);
  const shouldRedirectRefunded = isRefunded && currentRoute !== "myaccount";
  const hasLocalAgent = useSelector(selectHasLocalAgent);
  const hasLicensedAgent = !useSelector(selectIsLocalAgentUnlicensed);
  const hasAllocatedLicense = useSelector(selectAllocatedLicenses).length > 0;

  const [licenseCheckStatus, setLicenseCheckStatus] = useState<PromiseState>("idle");

  useMount(() => {
    try {
      setFullStoryEnv();
    } catch (ex) {
      Sentry.captureException(ex, { level: "error" });
    }
  });

  useEffect(() => {
    const identifier = currentUser?.email ?? currentUUID;
    // ensure identify is called ONCE, and
    // prevent to call it with blank data
    if (identifier && !identified.current) {
      try {
        const id = new amplitude.Identify();
        id.set("identifier", identifier);
        amplitude.identify(id);
        identifyFullStory(identifier);
        identified.current = true;
      } catch (ex) {
        Sentry.captureException(ex, { level: "error" });
      }
    }
  }, [currentUser?.email, currentUUID]);

  // report page view
  useEffect(() => {
    const reportValues = {
      URL: currentRoute,
      "Registration Key": currentUser?.registrationKey ?? "null",
      UUID: currentUUID,
      "Agent Version": agentInfo?.AppVersion,
      "Source Tracking": srcCode,
    };
    try {
      track("Page View", reportValues);
      reportFullStoryEvent("PageView", reportValues);
    } catch (ex) {
      Sentry.captureException(ex, { level: "error" });
    }
  }, [currentRoute, currentUrl, currentUser, currentUUID, agentInfo?.AppVersion, srcCode, track]);

  useEffect(() => {
    const qsParams = new URLSearchParams(window.location.search);
    const sendCheckLicense: boolean = JSON.parse(qsParams.get("sendCheckLicense") ?? "false");
    if (!sendCheckLicense) {
      return;
    }

    const sendMessage = async () => {
      const hub = getSignalRHub();
      const signalRHub = hub.getInstance();

      const message: IServiceMessage = new ServiceMessage();
      message.Payload = {
        AgentVersion: agentInfo?.AppVersion,
        MachineName: "",
        MachineString: "",
        ProductID: productId,
        RegistrationKey: currentUser?.registrationKey,
        ShowUI: false,
        Token: null,
        UUID: currentUUID,
      };
      message.MessageType = WSMessageType.CHECK_LICENSE;

      await signalRHub.SendAsync(message);
    };

    ExecuteQueryablePromise(sendMessage, {
      setPromiseState: setLicenseCheckStatus,
      delayBeforeReturnMs: 5000,
    });
  }, []);

  return (
    <>
      {licenseCheckStatus === "pending" ? (
        <Spinner
          data-qatag="licenseCheckingDialog"
          text="Checking License..."
          isActive={true}
        />
      ) : null}
      {GetRedirects(
        props.redirectToScan,
        props.hasMachineIntelligence,
        props.hasSignalRConnection,
        shouldRedirectRefunded
      )}
      <Routes data-qatag="Routes">
        {GetCommonRoutes()}
        {isAdmin && GetAdminRoutes()}
        {props.hasSignalRConnection && getAgentConnectedRoutes()}
        <Route
          data-qatag="baseRoute"
          path="/"
          element={getDefaultRouteNavigate(hasLocalAgent, hasLicensedAgent, hasAllocatedLicense, isAdmin)}
        />
        {/* Route back to the root path to invoke default route logic (when path is not recognized) */}
        <Route
          data-qatag="unknownPathFallback"
          path="*"
          element={
            <Navigate
              data-qatag="RootNavigate"
              to="/"
              replace
            />
          }
        />
      </Routes>
    </>
  );
};

function GetRedirects(
  redirectToScan: boolean,
  hasMachineIntelligence: boolean,
  hasAgentConnection: boolean,
  shouldRedirectRefunded: boolean
) {
  return (
    <>
      {shouldRedirectRefunded && (
        <Navigate
          data-qatag="refundedRedirect"
          to="/myaccount"
          replace
        />
      )}
      {hasAgentConnection && hasMachineIntelligence && redirectToScan && (
        <Navigate
          data-qatag="scanRedirect"
          to="/scan"
          replace
        />
      )}
    </>
  );
}

function GetCommonRoutes(): JSX.Element {
  return (
    <>
      <Route
        data-qatag="callback.redirect"
        path="/callback"
        element={
          <Navigate
            data-qatag="callback.redirect"
            to={GetCallbackRoute()}
            replace
          />
        }
      />
      <Route
        data-qatag="downloadRoute"
        path="/download"
        element={<DownloadPageContainer data-qatag="downloadPage" />}
      />
      <Route
        data-qatag="downloadLander"
        path="/downloadlander"
        element={<DownloadLanderContainer data-qatag="downloadLander" />}
      />
      <Route
        data-qatag="feedbackFormRoute"
        path="/feedbackform"
        element={<FeedbackFormContainer data-qatag="feedbackForm" />}
      />
      <Route
        data-qatag="myAccountRoute"
        path="/myaccount"
        element={<MyAccountContainer data-qatag="myAccount" />}
      />
      <Route
        data-qatag="resumeSubscriptionRoute"
        path="/resumesubscription"
        element={<DoResumeSubscription data-qatag="doResumeSubscription" />}
      />
      <Route
        data-qatag="updateInformationRoute"
        path="/updateinformation"
        element={<UpdateInformationContainer data-qatag="updateInformation" />}
      />
      <Route
        data-qatag="updateInformationRouteSuccess"
        path="/my-account/update/success"
        element={<UpdatedInformationSuccess data-qatag="UpdatedInformationSuccess" />}
      />
      <Route
        data-qatag="logoutRoute"
        path="/logout"
        element={<Logout data-qatag="logout" />}
      />
      <Route
        data-qatag="supportRoute"
        path="/support"
        element={<Support data-qatag="supportScreen" />}
      />
      <Route
        data-qatag="supportConfirmationRoute"
        path="/support/confirmation"
        element={<SupportConfirmationContainer data-qatag="supportConfirmationScreen" />}
      />
      <Route
        data-qatag="ticketsRoute"
        path="/tickets"
        element={
          <Navigate
            data-qatag="supportScreen"
            to="/support"
            replace
          />
        }
      />
      <Route
        data-qatag="newPCDetectedRoute"
        path="/newpcdetected"
        element={<NewPCDetectedContainer data-qatag="newPCDetected" />}
      />
      <Route
        data-qatag="cannotLicensePCRoute"
        path="/cannotlicensepc"
        element={<CannotLicensePCContainer data-qatag="cannotLicensePC" />}
      />
    </>
  );
}

// Get the initial route, this is only called on a callback from auth.
function GetCallbackRoute() {
  const callbackRoute = localStorage.getItem("initialRoute") ?? "/";

  return callbackRoute;
}

/**
 * Determines the default route based on the user's role and agent connection
 * status.
 *
 * @method getDefaultRouteNavigate
 * @param {boolean} hasLocalAgent - Whether the user has a local agent.
 * @param {boolean} hasLicensedAgent - Whether the user has a licensed agent.
 * @param {boolean} hasAllocatedLicense - Whether the org has an allocated license.
 * @param {boolean} isAdmin - Whether the user is an admin.
 * @returns {React.ReactNode} The default route to navigate to (react-router `Navigate` element).
 */
function getDefaultRouteNavigate(
  hasLocalAgent: boolean,
  hasLicensedAgent: boolean,
  hasAllocatedLicense: boolean,
  isAdmin: boolean
): React.ReactNode {
  // If the user is an admin and doesn't have a local agent or a licensed agent
  if (isAdmin && (!hasLocalAgent || !hasLicensedAgent)) {
    return (
      <Navigate
        data-qatag="summaryNavigate"
        to="/summary"
        replace
      />
    );
  } else if (
    // If the user is a family member without a licensed local agent but has an allocated license
    !isAdmin &&
    (!hasLocalAgent || !hasLicensedAgent) &&
    hasAllocatedLicense
  ) {
    return (
      <Navigate
        data-qatag="newPcDetectedNavigate"
        to="/newpcdetected"
        replace
      />
    );
  } else if (
    // If the user is a family member without a licensed local agent and without an allocated license
    !isAdmin &&
    (!hasLocalAgent || !hasLicensedAgent) &&
    !hasAllocatedLicense
  ) {
    return (
      <Navigate
        data-qatag="cannotLicensePCNavigate"
        to="/cannotlicensepc"
        replace
      />
    );
  }

  return (
    <Navigate
      data-qatag="statusNavigate"
      to="/status"
      replace
    />
  );
}

function getAgentConnectedRoutes(): JSX.Element {
  return (
    <>
      <Route
        data-qatag="machinePickerRoute"
        path="/machinepicker"
        element={<MachinePickerContainer data-qatag="machinePicker" />}
      />
      <Route
        data-qatag="scanScreenRoute"
        path="/scan"
        element={<ScanScreenContainer data-qatag="scanScreen" />}
      />
      <Route
        data-qatag="optimizationRoute"
        path="/optimizations"
        element={
          <LicensedFeatureWrapper
            data-qatag="optimizationsWrapper"
            requiredLicenseFlags={["AppOptimization"]}
            returnRoute="/status"
          >
            <Optimization data-qatag="optimizations" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="deceptorDetailsRoute"
        path="/deceptorprotection/deceptordetails"
        element={
          <LicensedFeatureWrapper
            data-qatag="deceptordetailsWrapper"
            requiredLicenseFlags={["SoftwareProtection"]}
            returnRoute="/status"
          >
            <DeceptorDetailsContainer data-qatag="deceptordetails" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="deceptorProtectionRoute"
        path="/deceptorprotection"
        element={
          <LicensedFeatureWrapper
            data-qatag="decetorprotectionWrapper"
            requiredLicenseFlags={["SoftwareProtection"]}
            returnRoute="/status"
          >
            <DeceptorProtectionContainer data-qatag="deceptorprotection" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="statusRoute"
        path="/status"
        element={<StatusScreenContainer data-qatag="StatusScreen" />}
      />
      <Route
        data-qatag="fileCleaningRoute"
        path="/filecleaning"
        element={
          <LicensedFeatureWrapper
            data-qatag="filecleaningWrapper"
            requiredLicenseFlags={["SystemCleanup"]}
            returnRoute="/status"
          >
            <FileCleaningContainer data-qatag="filecleaning" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="dnsProtectionRoute"
        path="/dnsprotection"
        element={
          <LicensedFeatureWrapper
            data-qatag="dnsprotectionWrapper"
            requiredLicenseFlags={["WebProtection"]}
            returnRoute="/status"
          >
            <DnsProtectionContainer data-qatag="dnsprotection" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="windowsEnhancementsRoute"
        path="/windowsenhancements"
        element={
          <LicensedFeatureWrapper
            data-qatag="windowsenhancementsWrapper"
            requiredLicenseFlags={["WindowsEnhancements"]}
            returnRoute="/status"
          >
            <WindowsEnhancementsContainer data-qatag="windowsenhancements" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="driverInstallContainerRoute"
        path="/drivers"
        element={
          <LicensedFeatureWrapper
            data-qatag="driversWrapper"
            requiredLicenseFlags={["Drivers"]}
            returnRoute="/status"
          >
            <DriverInstallContainer data-qatag="driverInstallContainer" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="settingsRoute"
        path="/settings"
        element={<SettingsContainer data-qatag="settings" />}
      />
      <Route
        data-qatag="feedbackFormRoute"
        path="/feedbackform"
        element={<FeedbackFormContainer data-qatag="feedbackForm" />}
      />
      <Route
        data-qatag="addPrinterRoute"
        path="/add-printer"
        element={<AddPrinter data-qatag="add-printer" />}
      />
    </>
  );
}

function GetAdminRoutes(): JSX.Element {
  return (
    <>
      <Route
        data-qatag="removingMemberModalRoute"
        path="/confirmRemoveFamilyMember"
        element={<ConfirmRemoveFamilyMember data-qatag="removingMemberModal" />}
      />
      <Route
        data-qatag="inviteSentRoute"
        path="/inviteSent"
        element={<InviteSent data-qatag="inviteSent" />}
      />
      <Route
        data-qatag="familyRoute"
        path="/family"
        element={<FamilyContainer data-qatag="family" />}
      />
      {/*For now I am tying this to app optimization, but this should be a separate feature flag -jh */}
      <Route
        data-qatag="addPcOrFamilyRoute"
        path="/addPcAddFamily"
        element={
          <LicensedFeatureWrapper
            data-qatag="addPcAddFamilyWrapper"
            requiredLicenseFlags={["AllFeatures"]}
            returnRoute="/status"
          >
            <AddPCAddFamilyContainer data-qatag="addPcAddFamily" />
          </LicensedFeatureWrapper>
        }
      />
      <Route
        data-qatag="licenseLimitRoute"
        path="/licenselimit"
        element={<LicenseLimitScreenContainer data-qatag="LicenseLimitScreenContainer" />}
      />
      <Route
        data-qatag="paymentUpdatedSucessRoute"
        path="/payment-method/update/success"
        element={<PaymentUpdatedSuccess data-qatag="updateInformation" />}
      />
      <Route
        data-qatag="paymentMethodRoute"
        path="/paymentmethod"
        element={<UpdatePaymentMethod data-qatag="paymentMethod" />}
      />
      <Route
        data-qatag="paymentMethodRoute"
        path="/paymentmethod/payment-declined"
        element={<UpdatePaymentMethod data-qatag="paymentMethod" />}
      />
      <Route
        data-qatag="cancelledConfirmationRoute"
        path="/cancelledconfirmation"
        element={<CancelledConfirmation data-qatag="cancelledConfirmation" />}
      />
      <Route
        data-qatag="billingHistoryRoute"
        path="/billing-history"
        element={<BillingHistoryContainer data-qatag="billingHistory" />}
      />
      <Route
        data-qatag="upSellingPromotion"
        path="/upsell-promotion"
        element={<UpSellPromotion data-qatag="upSellingPromotion" />}
      />
      <Route
        data-qatag="resumeRequiredRoute"
        path="/resume-required"
        element={<ResumeRequiredContainer data-qatag="updateInformation" />}
      />
      <Route
        data-qatag="resumedSubscription"
        path="/subscription/resumed"
        element={<ResumedSubscription data-qatag="resumedSubscriptionGoContainer" />}
      />
      <Route
        data-qatag="summaryRoute"
        path="/summary"
        element={<SummaryContainer data-qatag="summaryRouteElement" />}
      />
    </>
  );
}

export default AppRouter;
