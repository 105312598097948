import React from "react";
import { Alert, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { IScheduledScanOptionsMessage, IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { ScheduledScanDialog } from "./components/ScheduledScanDialog";
import { Layout } from "components/Layout/Layout";
import { SettingsToUpdateType } from "./SettingsContainer";
import { SettingsHeader } from "./components/Header";
import { GeneralSettings } from "./components/GeneralSettings";
import { SystemInfo } from "./components/SystemInfo";
import { Toggles } from "./components/Toggles";
import { Footer } from "./components/Footer";

export type SettingsProps = {
  agentVersion: string;
  checkLicenseTaskState?: string;
  guiVersionNumber: string;
  licenseCheckStatus: string;
  licensedTo: string;
  onChangeMachineClicked: () => void;
  operatingSystem: string;
  onLicenseCheckClicked: () => void;
  onLogoutClicked: () => void;
  onPrivacyPolicyClicked: () => void;
  onScanNowClicked: () => void;
  onTermsAndConditionsClicked: () => void;
  onUninstallInstructionsClicked: () => void;
  onUuidClicked: () => void;
  setShowScheduledScanDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  settings: IUserSettingModel[];
  settingsObj: { [key: string]: IUserSettingModel };
  scheduledScanOptions: IScheduledScanOptionsMessage;
  showScheduledScanDialog: boolean;
  showSnackbar: boolean;
  snackbarData: { message: string; type: string | "success" | "error" };
  updateSettings: (settingsToUpdate: SettingsToUpdateType[]) => void;
  uuid: string;
};
export function Settings({
  agentVersion,
  checkLicenseTaskState,
  guiVersionNumber,
  licensedTo,
  licenseCheckStatus,
  onChangeMachineClicked,
  operatingSystem,
  onLicenseCheckClicked,
  onLogoutClicked,
  onPrivacyPolicyClicked,
  onScanNowClicked,
  onTermsAndConditionsClicked,
  onUninstallInstructionsClicked,
  onUuidClicked,
  setShowScheduledScanDialog,
  setShowSnackbar,
  settingsObj,
  scheduledScanOptions,
  showScheduledScanDialog,
  showSnackbar,
  snackbarData,
  updateSettings,
  uuid,
}: SettingsProps) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Layout
      data-qatag="layout"
      qatag={"layout"}
      useTemplating={true}
    >
      <Layout.DetachedHeader data-qatag="settings.detachedHeader">
        <SettingsHeader
          data-qatag="settings.header"
          largeScreen={largeScreen}
        />
      </Layout.DetachedHeader>
      <Layout.Content data-qatag="settings.layoutContent">
        <GeneralSettings
          data-qatag="GeneralSettingsSection"
          onChangeMachineClicked={onChangeMachineClicked}
          onScanNowClicked={onScanNowClicked}
          setShowScheduledScanDialog={setShowScheduledScanDialog}
          settingsObj={settingsObj}
          showScheduledScanDialog={showScheduledScanDialog}
          updateSettings={updateSettings}
        />
        <SystemInfo
          data-qatag="SystemInfoSection"
          agentVersion={agentVersion}
          guiVersionNumber={guiVersionNumber}
          licenseCheckStatus={licenseCheckStatus}
          licensedTo={licensedTo}
          onLicenseCheckClicked={onLicenseCheckClicked}
          onUuidClicked={onUuidClicked}
          operatingSystem={operatingSystem}
          uuid={uuid}
        />
        <Toggles
          data-qatag="TogglesSections"
          settingsObj={settingsObj}
          updateSettings={updateSettings}
        />
        <Footer
          data-qatag="footer"
          onPrivacyPolicyClicked={onPrivacyPolicyClicked}
          onTermsAndConditionsClicked={onTermsAndConditionsClicked}
          onLogoutClicked={onLogoutClicked}
          onUninstallInstructionsClicked={onUninstallInstructionsClicked}
        />

        <ScheduledScanDialog
          data-qatag="ScheduledScanOptionsDialog"
          isOpen={showScheduledScanDialog}
          onCloseHandler={() => setShowScheduledScanDialog(false)}
          onScanNowClicked={onScanNowClicked}
          settingsObj={settingsObj}
          scheduledScanOptions={scheduledScanOptions}
          updateSettings={updateSettings}
        />
        <Snackbar
          data-qatag="copyToClipBoard.message"
          open={showSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            data-qatag="copyToClipBoard.message.alert"
            onClose={handleCloseSnackbar}
            severity={snackbarData?.type === "success" ? "success" : "error"}
          >
            {snackbarData?.message}
          </Alert>
        </Snackbar>
      </Layout.Content>
    </Layout>
  );
}
