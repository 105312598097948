import { useMemo } from "react";
import { TextField } from "@mui/material";
import { Layout } from "components/Layout/Layout";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import styled from "@mui/styled-engine";

//to overwrite the Layout's styling
const Wrapper = styled("div")`
  width: 100%;

  .MuiPaper-root {
    padding: 20px;

    ${(props) => props.theme.breakpoints.up("md")} {
      padding: 60px;
    }
  }
`;

const Page = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("lg")} {
    max-width: 600px;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    max-width: 730px;
  }
`;

const Title = styled("h1")`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #535353;
  align-self: flex-start;

  ${(props) => props.theme.breakpoints.down("lg")} {
    font-size: 1.313rem;
    line-height: 1.75rem;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

export const FormField = styled(TextField)`
  width: 100%;

  & label.mui-focused {
    color: "#0FB4D1";
  }

  & .muioutlinedinput-root {
    & fieldset {
      border-width: 2;
      border-color: "#C1C7D0";
    }

    & input:valid:hover + fieldset {
      border-color: "#0FB4D1";
    }

    & input:valid:focus + fieldset {
      border-color: "#0FB4D1";
    }

    & input:error + fieldset {
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }

  & .MuiFormHelperText-root {
    margin-bottom: 10px;
  }
`;

const SubmitButton = styled(AppButton)`
  width: 280px;
  height: 44px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 20px;
  }
`;

const SubmitText = styled("span")`
  font-size: 1rem;
  line-height: 1.313rem;
  font-weight: 600;
`;

//the && is needed to increase specificity so it overwrites
//the base AppButton styling
const CancelButton = styled(AppButton)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-decoration: underline;

  && {
    font-weight: 600;
    margin-top: 20px;
  }
`;

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 25px;
`;

export type UpdateInformationFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const initialValues: UpdateInformationFormValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export interface IUpdateInformationProps {
  formValues: UpdateInformationFormValues;
  onSubmit: (values: UpdateInformationFormValues) => void;
  onCancel: () => void;
}

export function UpdateInformation({
  formValues = initialValues,
  onSubmit = (values: UpdateInformationFormValues) => {},
  onCancel = () => {},
}) {
  const { formatMessage } = useIntl();
  const validationSchema = useMemo(() => {
    return yup.object({
      firstName: yup.string().required(
        formatMessage({
          id: "updateInformation.form.error.firstNameRequired",
          defaultMessage: "First name is required",
        })
      ),
      lastName: yup.string().required(
        formatMessage({
          id: "updateInformation.form.error.lastNameRequired",
          defaultMessage: "Last name is required",
        })
      ),
      email: yup
        .string()
        .required(
          formatMessage({
            id: "updateInformation.form.error.emailRequired",
            defaultMessage: "Email is required",
          })
        )
        .email(
          formatMessage({
            id: "updateInformation.form.error.invalidEmail",
            defaultMessage: "Invalid email address",
          })
        ),
    });
  }, [formatMessage]);

  return (
    <Wrapper data-qatag="updateInformationPage.wrapper">
      <Layout
        data-qatag="updateInformationPage.layout"
        qatag="updateInformationPage"
      >
        <Page data-qatag="updateInformationPage.page">
          <Title data-qatag="updateInformationTitle">
            <FormattedMessage
              data-qatag="updateInformationTitleMessage"
              id="UpdateInformation.Title"
              defaultMessage="Your Information"
            />
          </Title>
          <Formik
            data-qatag="updateInformationPage.formikForm"
            initialValues={formValues}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={(values) => {
              // use the values that were validated by yup
              // this makes sure we pick up trimmed strings
              const trimmedValues = validationSchema.cast(values);
              onSubmit({
                email: formValues.email, //don't allow email to be updated
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                firstName: trimmedValues.firstName!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                lastName: trimmedValues.lastName!,
              });
            }}
          >
            {({ handleSubmit, isSubmitting, errors, touched, values, isValid, handleBlur, handleChange }) => (
              <Form
                data-qatag="updateInformationForm"
                onSubmit={handleSubmit}
              >
                <FormField
                  name="email"
                  label="Account Email Address"
                  defaultValue={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(touched.email && errors.email) ?? " "}
                  error={touched.email && Boolean(errors.email)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  data-qatag="updateInformationEmailField"
                  disabled={true}
                />
                <FormField
                  name="firstName"
                  label="First Name"
                  defaultValue={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(touched.firstName && errors.firstName) ?? " "}
                  error={touched.firstName && Boolean(errors.firstName)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  data-qatag="updateInformationFirstNameField"
                />
                <FormField
                  name="lastName"
                  label="Last Name"
                  defaultValue={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(touched.lastName && errors.lastName) ?? " "}
                  error={touched.lastName && Boolean(errors.lastName)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  data-qatag="updateInformationLastNameField"
                />

                <SubmitButton
                  ariaLabel="Update your information"
                  data-qatag="updateInformationSubmitButton"
                  onClick={() => {
                    /*submit handled by form, do not do anything here*/
                  }}
                  buttonStyle={AppButtonStyles.Green}
                  isDisabled={!isValid || isSubmitting}
                  buttonType="submit"
                >
                  <SubmitText data-qatag="updateInformationSubmitButtonText">
                    <FormattedMessage
                      data-qatag="updateInformationSubmitButtonMessage"
                      id="updateInformation.submitText"
                      defaultMessage="Update Your Information"
                    />
                  </SubmitText>
                </SubmitButton>
              </Form>
            )}
          </Formik>
          <CancelButton
            ariaLabel="Cancel"
            data-qatag="updateInformationCancelButton"
            onClick={onCancel}
            buttonStyle={AppButtonStyles.LinkGray}
            buttonType="reset"
          >
            <FormattedMessage
              data-qatag="updateInformationCancelButtonMessage"
              id="updateInformation.cancelText"
              defaultMessage="Cancel"
            />
          </CancelButton>
        </Page>
      </Layout>
    </Wrapper>
  );
}
