import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/redux/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

export const fetchInvoice = createAsyncThunk<
  void,
  { fileId: string; invoiceName: string },
  {
    state: RootState;
  }
>("files/fetchInvoice", async ({ fileId, invoiceName }, thunkApi) => {
  try {
    const url = `/api/billing/file?zuoraPath=${fileId}`;

    const apiResponse = await RESTGatewayAPI.get(url, {
      responseType: "blob",
    });

    if (!(apiResponse?.data instanceof Blob)) {
      throw new Error("Invalid file");
    }

    const blob = new Blob([apiResponse?.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Billing-${invoiceName}.pdf`;
    link.click();
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to download invoice : ${error}`);
  }
});

const initialState: IReducerState<ReducerStatus[]> = {
  data: [],
  status: {
    [fetchInvoice.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetFetch: (state) => {
      state.status[fetchInvoice.typePrefix] = ReducerStatus.Idle;
    },
    resetInvoiceState: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvoice.pending, (state) => {
      state.status[fetchInvoice.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchInvoice.fulfilled, (state) => {
      state.status[fetchInvoice.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(fetchInvoice.rejected, (state) => {
      state.status[fetchInvoice.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { resetInvoiceState, resetFetch } = invoiceSlice.actions;

// export const getBlobObject = (state: RootState) => state.invoice.data;
export const getFetchStatus = (state: RootState) => state.invoice.status[fetchInvoice.typePrefix];

export default invoiceSlice.reducer;
