import { styled } from "@mui/material";
import { Layout } from "components/Layout/Layout";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import successIcon from "assets/img/icn-sucess-for-modals.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { FormattedMessage } from "react-intl";

export type SupportConfirmationProps = {
  email: string;
  onConfirm: () => void;
};

const Content = styled(Layout.Content)`
  padding: 3.75rem;
`;

const Title = styled("h1")`
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: normal;
  text-align: center;
  color: #535353;
`;

const Section = styled("section")`
  width: 16.875rem;
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: 0.0095rem;
  text-align: center;
  color: #535a6a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const BoldText = styled("span")`
  font-weight: 500;
`;

const EmailContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Email = styled("span")`
  font-size: 1.125rem;
  color: #007dba;
`;

const StyledAppButton = styled(AppButton)`
  width: 17.5rem;
  height: 2.75rem;
`;

const SuccessIcon = styled("img")`
  height: 3.125rem;
  width: 3.125rem;
`;

const EmailIcon = styled(EmailOutlinedIcon)`
  color: #007dba;
  height: 1rem;
`;

function SupportConfirmation({ email, onConfirm }: SupportConfirmationProps) {
  return (
    <Layout
      data-qatag="layout"
      qatag="layout"
      useTemplating={true}
    >
      <Content data-qatag="SupportConfirmation.layoutContent">
        <SuccessIcon
          src={successIcon}
          data-qatag="SupportConfirmation.icon"
        />
        <Title data-qatag="SupportConfirmation.title">
          <FormattedMessage
            id="SupportConfirmation.title"
            data-qatag="SupportConfirmation.titleMsg"
            defaultMessage="Support Request Sent"
          />
        </Title>

        <Section data-qatag="SupportConfirmation.instructions">
          <BoldText data-qatag="SupportConfirmation.checkEmai">
            <FormattedMessage
              id="SupportConfirmation.checkEmail"
              data-qatag="SupportConfirmation.checkEmailMsg"
              defaultMessage="Check your email at:"
            />
          </BoldText>

          <EmailContainer data-qatag="SupportConfirmation.emailContainer">
            <EmailIcon data-qatag="SupportConfirmation.emailIcon" />
            <Email data-qatag="SupportConfirmation.email">{email}</Email>
          </EmailContainer>

          <span data-qatag="SupportConfirmation.followUp">
            <FormattedMessage
              id="SupportConfirmation.followUp"
              data-qatag="SupportConfirmation.followUpMsg"
              defaultMessage="Please allow 2 business days for us to process your request."
            />
          </span>
        </Section>

        <StyledAppButton
          ariaLabel="Back To My System"
          buttonType="button"
          data-qatag="SupportConfirmation.button"
          buttonStyle={AppButtonStyles.Green}
          onClick={onConfirm}
        >
          <FormattedMessage
            id="SupportConfirmation.button"
            data-qatag="SupportConfirmation.buttonMsg"
            defaultMessage="Back To My System"
          />
        </StyledAppButton>
      </Content>
    </Layout>
  );
}

export default SupportConfirmation;
