import React from "react";
import { useSelector } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";
import { selectCurrentUuid } from "app/redux/applicationSlice";

function useAmplitude() {
  const uuid = useSelector(selectCurrentUuid);

  const track = React.useCallback(
    (...args: Parameters<typeof amplitude.track>) => {
      const eventName = args[0];
      const properties = uuid ? { ...args[1], uuid } : args[1];
      const eventOptions = args[2];

      amplitude.track(eventName, properties, eventOptions);
    },
    [uuid]
  );

  return { track };
}

export default useAmplitude;
