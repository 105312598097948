import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "api/axiosBaseQuery";
import { ApiResponse } from "api/types";
import { IProvideFeedbackPayload } from "model/feedbackForm/FeedbackForm";

// types
// ------------------------------------
type PutRequestSupportRequestArgs = {
  Name: string;
  Email: string;
  UUID?: string;
};

type PutRequestSupportResponse = string;

export declare const productId: number;

const salesforceApi = createApi({
  reducerPath: "salesforceApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api/salesforce/",
  }),
  endpoints(build) {
    return {
      provideFeedback: build.mutation<void, IProvideFeedbackPayload>({
        query: (data) => {
          return {
            url: `feedback`,
            method: "put",
            data,
          };
        },
      }),
      requestSupport: build.mutation<ApiResponse<PutRequestSupportResponse>, PutRequestSupportRequestArgs>({
        query: ({ Email, Name, UUID }) => {
          return {
            url: `agentassist`,
            method: "put",
            data: { Email, Name, UUID },
          };
        },
      }),
    };
  },
});

export const { useProvideFeedbackMutation, useRequestSupportMutation } = salesforceApi;
export { salesforceApi };
