import { FormattedMessage } from "react-intl";
import backArrow from "assets/img/deceptor/icn-back-arrow.png";
import styled from "@mui/styled-engine";

export type DownloadErrorProps = {
  errorCallback: () => void;
};

const BackButton = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
  text-decoration: underline;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0px;
  color: #007dba;
`;

const ArrowImage = styled("img")`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

export function DownloadError({ errorCallback }: DownloadErrorProps) {
  return (
    <div data-qatag="DownloadErrorWrapper">
      <div data-qatag="DownloadErrorMessage">
        <h1 data-qatag="DownloadErrorHeader">Error Downloading Driver Support</h1>
        <p data-qatag="DownloadErrorMessage">An error occured while trying to download Driver Support</p>
        <BackButton
          data-qatag="DownloadErrorBackButton"
          onClick={() => errorCallback()}
        >
          <ArrowImage
            src={backArrow}
            alt="Back"
            data-qatag="ArrowImage"
          />
          <FormattedMessage
            data-qatag="allowSoftwareMessage"
            id="deceptorProtection.view.details.back-option"
            defaultMessage="Back"
          />
        </BackButton>
      </div>
    </div>
  );
}
