import { PersonOutline as PersonOutlineIcon, MailOutline as MailOutlineIcon } from "@mui/icons-material";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import {
  BlueIconContainer,
  CenteredHeaderText,
  EmailText,
  Flexbox,
  NameAndEmailBox,
  NameText,
  SectionContainer,
  SubText,
} from "../SharedStyledComponents";
import IUser from "model/user/IUser";
import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";

export interface IAddPcSectionProps {
  addNewPC: boolean;
  handleAddPC: () => void;
  handleDownloadAgent: () => void;
  loggedInUser: IUser;
}

export function AddPCSection({ addNewPC, handleAddPC, handleDownloadAgent, loggedInUser }: IAddPcSectionProps) {
  return (
    <SectionContainer data-qatag="AddPCAddFamilyContainer.addPCContainer">
      <CenteredHeaderText data-qatag="AddPCAddFamilyContainer.headerTextContainer">
        <FixedFormattedMessage
          id={"AddPCAddFamilyContainer.addPCContainer.headerText"}
          data-qatag="AddPCAddFamilyContainer.addPCContainer.headerText"
          defaultMessage="Me, The Account Holder"
        />
      </CenteredHeaderText>
      <SubText data-qatag="AddPCAddFamilyContainer.subTextContainer">
        <FixedFormattedMessage
          id={"AddPCAddFamilyContainer.addPCContainer.subText"}
          data-qatag="AddPCAddFamilyContainer.addPCContainer.subText"
          defaultMessage={
            addNewPC
              ? "If you wish to add another windows PC as the account holder."
              : "If you wish to add this PC as the account holder."
          }
        />
      </SubText>
      <NameAndEmailBox data-qatag="AddPCAddFamilyContainer.addPCContainer.nameAndEmailContainer">
        <Flexbox data-qatag="AddPCAddFamilyContainer.addPCContainer.nameContainer">
          <BlueIconContainer data-qatag="AddPCAddFamilyContainer.addPCContainer.nameIconContainer">
            <PersonOutlineIcon data-qatag="AddPCAddFamilyContainer.addPCContainer.nameIcon" />
          </BlueIconContainer>
          <NameText data-qatag="AddPCAddFamilyContainer.addPCContainer.nameText">
            {loggedInUser?.firstName + " " + loggedInUser?.lastName}
          </NameText>
        </Flexbox>
        <Flexbox data-qatag="AddPCAddFamilyContainer.addPCContainer.emailContainer">
          <BlueIconContainer data-qatag="AddPCAddFamilyContainer.addPCContainer.emailIconContainer">
            <MailOutlineIcon data-qatag="AddPCAddFamilyContainer.addPCContainer.emailIcon" />
          </BlueIconContainer>
          <EmailText data-qatag="AddPCAddFamilyContainer.addPCContainer.emailIconText">{loggedInUser?.email}</EmailText>
        </Flexbox>
      </NameAndEmailBox>

      <AppButton
        ariaLabel={addNewPC ? "Email Myself The Download Link" : "Download To This PC For Me"}
        data-qatag="AddPCAddFamilyContainer.addPCContainer.callToActionButton"
        buttonStyle={AppButtonStyles.Green}
        onClick={addNewPC ? handleAddPC : handleDownloadAgent}
        style={{
          fontSize: "1rem",
          fontWeight: "600",
          height: "56px",
          letterSpacing: "0.18px",
          margin: "24px 0",
          maxWidth: "100%",
          width: "400px",
        }}
        buttonType="button"
      >
        <FixedFormattedMessage
          id="buttonText"
          data-qatag="buttonText"
          defaultMessage={addNewPC ? "Email Myself The Download Link" : "Download To This PC For Me"}
        />
      </AppButton>
    </SectionContainer>
  );
}
