import { resetAllState } from "core/Helpers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "siq-oidc-react";
import { Spinner } from "dsoneweb.designsystem";

declare let authServer: string;

export const Logout: React.FC = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const onLogout = async () => {
      resetAllState(dispatch);
      //give the reset actions a bit of time to go through
      setTimeout(async () => {
        await auth.signOut();
        window.location.href = `${authServer}/logoutdsone`;
      }, 3000);
    };

    onLogout();
  }, [dispatch, auth]);
  return (
    <Spinner
      data-qatag="loadingSpinner"
      isActive
      text="Logging You Out..."
    />
  );
};
