import SuccessUpdate from "features/SubscriptionManagement/SuccessUpdate/SuccessUpdate";
import { FormattedMessage } from "react-intl";

function PaymentUpdatedSuccess() {
  return (
    <SuccessUpdate
      data-qatag="PaymentUpdatedSuccess"
      icon="payment"
      title={
        <FormattedMessage
          data-qatag="PaymentUpdatedSuccess.subtitle"
          id="PaymentUpdatedSuccess.subtitle"
          defaultMessage="Your payment has been updated!"
        />
      }
      subtitle={
        <FormattedMessage
          data-qatag="PaymentUpdatedSuccess.title"
          id="PaymentUpdatedSuccess.title"
          defaultMessage="Your payment has been successfully updated and can now be used for transaction."
        />
      }
    />
  );
}

export default PaymentUpdatedSuccess;
