import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Modal } from "dsoneweb.designsystem";
import headerImage from "assets/img/fileCleaning/icn-pc-cleanup.svg";
import checkImage from "assets/img/icn-green-check-nobg.svg";
import styled from "@mui/styled-engine";

export interface ICleanupCategory {
  name: string;
  size: string;
}

export interface ICleanupCompleteDialogProps {
  isOpen: boolean;
  spaceFreed: string;
  hasLockedFiles: boolean;
  categories: ICleanupCategory[];
  closeDialogHandler: () => void;
  viewLockedFilesHandler: () => void;
}

const Dialog = styled(Modal)`
  border-radius: 8px;
  padding: 14px 24px 24px;
  width: 619px;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: unset;
    padding: 14px 18px 24px;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
`;

const HeaderImage = styled("img")`
  width: 109px;
  height: 78px;
  margin-bottom: 6px;
`;

const TitleText = styled(Typography)`
  font-size: 1.5rem;
  line-height: 2.125rem;
  font-weight: bold;

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 1.313rem;
  }
`;

const DescriptionText = styled(Typography)`
  font-size: 1rem;
  line-height: 1.313rem;
  margin-top: 4px;
`;

const ViewLockedFilesText = styled(Typography)`
  margin-top: 15px;
  margin-bottom: 25px;
  font-size: 1rem;
  line-height: 1.313rem;
`;

const LockedFilesText = styled("span")`
  color: #007dba;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`;

const CategoriesContainer = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 28px;
`;

const Category = styled("div")`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  &:nth-child(odd) {
    background-color: #f8f8f8;
    border-radius: 12px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 15px 13px;
  }
`;

const CategoryName = styled(Typography)`
  flex-basis: 50%;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: bold;
`;

const CategorySizeContainer = styled("div")`
  flex-basis: 50%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #39b54a;
`;

const CategorySize = styled(Typography)`
  color: #39b54a;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const CategoryCheck = styled("img")`
  margin-left: 12px;
  width: 17px;
  height: 12px;
`;

const Footer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

const SpaceFreedContainer = styled("div")`
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-bottom: 20px;
  }
`;

const SpaceFreedCheck = styled("img")`
  width: 17px;
  height: 12px;
  margin-right: 10px;
  margin-bottom: 8px;
`;

const SpaceFreedTextContainer = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: left;
`;

const SpaceFreedLabel = styled(Typography)`
  font-size: 1rem;
  line-height: 1.313rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-left: -10px;
  }
`;

const SpaceFreedText = styled(Typography)`
  color: #39b54a;
  font-size: 2rem;
  line-height: 2.688rem;
`;

const CloseButton = styled(AppButton)`
  && {
    display: inline-block;
  }

  width: 240px;
  height: 44px;
`;

const CloseText = styled(Typography)`
  line-height: 100%;
`;

export const CleanupCompleteDialog: React.FC<ICleanupCompleteDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.cleanupCompleteDialog";

  return (
    <Dialog
      data-qatag="cleanupCompleteDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
    >
      <Content data-qatag="cleanupCompleteDialogContent">
        <HeaderImage
          data-qatag="headerImage"
          src={headerImage}
          alt=""
        />
        <TitleText
          data-qatag="cleanupCompleteDialogLockedFilesTypography"
          align="center"
          variant="h4"
        >
          {formatMessage({
            id: `${prefix}.lockedFilesTitle`,
            defaultMessage: "System Cleanup Complete",
          })}
        </TitleText>
        <DescriptionText data-qatag="cleanupCompleteDescriptionBox">
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage: "Your PC has been cleaned and space has been freed.",
          })}
        </DescriptionText>
        {props.hasLockedFiles ? (
          <ViewLockedFilesText
            data-qatag="fileCleaningHeaderDescription"
            id={`${prefix}.viewLockedFiles`}
          >
            {formatMessage(
              {
                id: `${prefix}.viewLockedFiles`,
                defaultMessage: "View {lockedFiles} that cannot be cleaned",
              },
              {
                lockedFiles: (
                  <LockedFilesText
                    data-qatag="LockedFilesText"
                    onClick={props.viewLockedFilesHandler}
                  >
                    {formatMessage({
                      id: `${prefix}.lockedFiles`,
                      defaultMessage: "locked files",
                    })}
                  </LockedFilesText>
                ),
              }
            )}
          </ViewLockedFilesText>
        ) : null}
        <CategoriesContainer data-qatag="categoriesContainer">
          {props.categories.map((category) => {
            return (
              <Category
                data-qatag="cleanupCategory"
                key={`cleanup-category-${category.name}`}
              >
                <CategoryName
                  data-qatag="categoryName"
                  align="left"
                  variant="body2"
                >
                  {category.name}
                </CategoryName>
                <CategorySizeContainer data-qatag="categorySizeContainer">
                  <CategorySize
                    data-qatag="categorySize"
                    variant="body2"
                  >
                    {category.size}
                  </CategorySize>
                  <CategoryCheck
                    data-qatag="categoryCheck"
                    src={checkImage}
                    alt="checkmark"
                  />
                </CategorySizeContainer>
              </Category>
            );
          })}
        </CategoriesContainer>
        <Footer data-qatag="footer">
          <SpaceFreedContainer data-qatag="desktopSpaceFreedContainer">
            <SpaceFreedCheck
              data-qatag="spaceFreedCheck"
              src={checkImage}
              alt="checkmark"
            />
            <SpaceFreedTextContainer data-qatag="spaceFreedText">
              <SpaceFreedLabel
                data-qatag="spaceFreedLabel"
                variant="body2"
              >
                {formatMessage({
                  id: `${prefix}.spaceFreed`,
                  defaultMessage: "Total Space Freed",
                })}
              </SpaceFreedLabel>
              <SpaceFreedText
                data-qatag="spaceFreed"
                variant="h2"
              >
                {props.spaceFreed}
              </SpaceFreedText>
            </SpaceFreedTextContainer>
          </SpaceFreedContainer>
          <CloseButton
            data-qatag="closeButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={props.closeDialogHandler}
            ariaLabel="close"
            buttonType="button"
          >
            <CloseText
              data-qatag="closeButtonTypography"
              variant="body2"
            >
              {formatMessage({
                id: `${prefix}.close`,
                defaultMessage: "Close",
              })}
            </CloseText>
          </CloseButton>
        </Footer>
      </Content>
    </Dialog>
  );
};
