import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux/store";
import { IDriverStatusMessage, AgentDriverUpdateStatus } from "model/driver/DriverUpdateModel";
import { ISupportCasePayload } from "model/messaging/payloads/ISupportCasePayload";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { IContactSubmitDetails } from "./modals/DriverContactForm";
import RESTGatewayAPI from "api/gatewayAPI";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { selectIsSignalRConnectionLocalAgent } from "app/Agent/AgentSlice";
declare const productId: number;

export const requestDriverDownloads = createAsyncThunk<string, string[], { state: RootState }>(
  "driver/requestDriverDownloads",
  async (driverIds, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DOWNLOAD_DRIVER_LIST_REQUEST;
    message.Payload = driverIds;
    return srhub.SendAsync(message);
  }
);

export const requestDownloadCancel = createAsyncThunk<string, string, { state: RootState }>(
  "driver/cancelDriverDownload",
  async (driverId, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DOWNLOAD_DRIVER_CANCEL;
    message.Payload = driverId;
    return srhub.SendAsync(message);
  }
);

export const fetchDriverState = createAsyncThunk<IDriverStatusMessage[], void, { state: RootState }>(
  "driver/fetchDriverState",
  async (_, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DRIVER_STATE_REQUEST;
    return srhub.SendAsync(message);
  }
);

export const driverInstallRequested = createAsyncThunk<string, string, { state: RootState }>(
  "driver/driverInstallRequested",
  async (deviceId: string, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DRIVER_INSTALL_REQUEST;
    message.Payload = deviceId;
    return srhub.SendAsync(message);
  }
);

export const driverStateResponseReceived = createAsyncThunk<
  IDriverStatusMessage[],
  IDriverStatusMessage[],
  { state: RootState }
>("driver/driverStateResponseReceived", async (messages: IDriverStatusMessage[], thunkApi) => {
  messages.forEach((statusObj) => {
    if (
      statusObj.Status === AgentDriverUpdateStatus.Installing ||
      statusObj.Status === AgentDriverUpdateStatus.TimedOut ||
      statusObj.Status === AgentDriverUpdateStatus.UserInstalling ||
      statusObj.Status === AgentDriverUpdateStatus.Verifying
    ) {
      thunkApi.dispatch(forceInstallComplete(statusObj.DeviceID));
    }
  });

  const driverIdsToDownload = messages
    .filter((m) => m.Status === AgentDriverUpdateStatus.AvailableForDownload)
    .map((m) => m.DeviceID);
  const state = thunkApi.getState();
  const isAgentLocal = selectIsSignalRConnectionLocalAgent(state);
  if (driverIdsToDownload.length > 0 && isAgentLocal) {
    thunkApi.dispatch(requestDriverDownloads(driverIdsToDownload));
  }

  return messages;
});

export const forceInstallComplete = createAsyncThunk<void, string, { state: RootState }>(
  "driver/forceInstallComplete",
  async (deviceId: string, thunkApi) => {
    const hub = getSignalRHub();
    const srhub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.DRIVER_INSTALL_FORCE_COMPLETE;
    message.Payload = deviceId;
    return srhub.SendAsync(message);
  }
);

export const submitContactForm = createAsyncThunk<
  string,
  IContactSubmitDetails,
  {
    state: RootState;
  }
>("driver/submitContactForm", async (details, thunkApi) => {
  const nameArray = details.name.trim().split(" ");
  const firstName = nameArray[0];
  const lastName = nameArray[1] ? nameArray[1] : "";

  // this is currently necessary for the support case call
  // this should be removed when contact creation has been automated
  // temp code
  const addContactUrl = `/api/salesforce/contact`;
  const addContactPayload = {
    FirstName: firstName,
    LastName: lastName,
    Email: details.email,
    Description: "Contact created by application",
  };
  try {
    await RESTGatewayAPI.post(addContactUrl, addContactPayload);
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      deviceId: thunkApi.getState().driver.data.currentContactModalDeviceId,
      error: e,
    });
  }
  // end temp code

  const url = `/api/salesforce/cases`;
  const payload = {
    Origin: "DSOne Web UI",
    Reason: "Question about device",
    Description: details.details,
    Priority: "Normal",
    Status: "New",
    Subject: `Driver Support One - ${details.regarding}`,
    Type: "Question about device",
    SuppliedEmail: details.email,
    SuppliedName: details.name,
    Product_Name__c: "Driver Support One Web",
    Product_Id__c: productId,
  } as ISupportCasePayload;

  try {
    const response = await (await RESTGatewayAPI.post(url, payload)).data;
    return response.id as string;
  } catch (e) {
    return thunkApi.rejectWithValue({
      email: details.email,
      deviceId: details.device.deviceID,
      submitPayload: payload,
      error: e,
    });
  }
});
