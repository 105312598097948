import { useCallback, useEffect, useState } from "react";
import { eFeatureTileTypes } from "../StatusScreenContainer";
import { useNavigate } from "react-router";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFileCleanupResults,
  fetchFileCleanupScan,
  selectStatus as selectFileCleaningStatus,
  selectIsFileCleanupNeeded,
} from "features/fileCleaning/FileCleaningSlice";
import {
  fetchUserSettings,
  selectBrowserExtensionSettingValue,
  selectIsAppOptimizationActive,
  selectIsDeceptorBlockingActive,
  selectIsDnsProtectionActive,
  selectStatus as selectSettingsStatus,
} from "features/settings/SettingsSlice";
import { fetchHardwareScan, selectOutOfDateDriversCount, selectScanStatus } from "features/scan/ScanSlice";
import { FeatureStatus } from "./FeatureStatus";
import { BuildFeatureTiles } from "./FeatureTileBuilder";
import { ReducerStatus } from "model/IReducerState";
import { Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import { ExpiredLicenseDialogContainer } from "features/ExpiredLicenseDialog/ExpiredLicenseDialogContainer";
import { selectIsAdmin } from "session/SessionSlice";
import { GetIsLicenseExpired } from "api/LicenseApi/LicenseApiHelpers";

export function FeatureStatusContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isFileCleanupNeeded = useSelector(selectIsFileCleanupNeeded);
  const browserExtensionEnabled = useSelector(selectBrowserExtensionSettingValue);
  const outOfDateDriverCount = useSelector(selectOutOfDateDriversCount);
  const cleaningStatus = useSelector(selectFileCleaningStatus);
  const scanStatus = useSelector(selectScanStatus);
  const settingsStatus = useSelector(selectSettingsStatus);
  const isAppOptimizationActive = useSelector(selectIsAppOptimizationActive);
  const isDeceptorBlockingActive = useSelector(selectIsDeceptorBlockingActive);
  const isDnsProtectionActive = useSelector(selectIsDnsProtectionActive);
  const isAdmin = useSelector(selectIsAdmin);
  const { data: licenseData, isLoading: isLicenseDataLoading } = useGetOrganizationLicensesQuery();
  const isLicenseExpired = GetIsLicenseExpired(licenseData);
  const features = licenseData?.licenseTier?.featureFlags ?? "";
  const [showExpiredLicenseAlert, setShowExpiredLicenseAlert] = useState(false);

  useEffect(() => {
    dispatch(fetchFileCleanupResults());
    dispatch(fetchFileCleanupScan());
    dispatch(fetchHardwareScan());
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const handleTileClick = useCallback(
    (tileType: eFeatureTileTypes) => {
      if (isLicenseExpired) {
        setShowExpiredLicenseAlert(true);
        return;
      }

      switch (tileType) {
        case eFeatureTileTypes.AppOptimization:
          navigate("/optimizations");
          break;
        case eFeatureTileTypes.SoftwareProtection:
          navigate("/deceptorprotection");
          break;
        case eFeatureTileTypes.WebProtection:
          navigate("/dnsprotection");
          break;
        case eFeatureTileTypes.SystemCleanup:
          navigate("/filecleaning");
          break;
        case eFeatureTileTypes.WindowsEnhancements:
          navigate("/windowsenhancements");
          break;
        case eFeatureTileTypes.Drivers:
          navigate("/drivers");
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features]
  );

  const tileData = BuildFeatureTiles({
    isAppOptimizationActive: isAppOptimizationActive,
    licenseFeatures: features,
    outOfDateDriverCount: outOfDateDriverCount,
    isDeceptorBlockingActive: isDeceptorBlockingActive,
    isFileCleanupNeeded: isFileCleanupNeeded,
    browserExtensionEnabled: browserExtensionEnabled,
    isDnsProtectionActive: isDnsProtectionActive,
  });

  const isFetching =
    isLicenseDataLoading ||
    cleaningStatus[fetchFileCleanupScan.typePrefix] === ReducerStatus.Loading ||
    scanStatus[fetchHardwareScan.typePrefix] === ReducerStatus.Loading ||
    settingsStatus[fetchUserSettings.typePrefix] === ReducerStatus.Loading;

  if (isFetching) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive={true}
        headerText={formatMessage({
          id: `FeatureStatusLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `FeatureStatusLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    );
  }

  return (
    <>
      <FeatureStatus
        data-qatag="featureStatus"
        isLicenseExpired={isLicenseExpired}
        tileData={tileData}
        handleTileClick={handleTileClick}
      />
      {showExpiredLicenseAlert && (
        <ExpiredLicenseDialogContainer
          data-qatag="expiredAlert"
          isAdmin={isAdmin}
          isOpen={showExpiredLicenseAlert}
          closeHandler={() => {
            setShowExpiredLicenseAlert(false);
          }}
        />
      )}
    </>
  );
}
