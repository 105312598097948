import { Badge } from "@mui/material";
import checkIcon from "assets/img/greenCheck.svg";
import alertIcon from "assets/img/icn-category-warning.svg";
import { FormattedMessage } from "react-intl";
import { eFeatureTileTypes } from "../StatusScreenContainer";
import { IFeatureTileData } from "./FeatureTileBuilder";
import styled from "@mui/styled-engine";

const Card = styled("div")`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #2525264d;
  border-radius: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 200px;
  width: 235px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 20px;
    width: 220px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 235px;
  }
`;

const CardContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BadgeImg = styled("img")`
  height: 17px;
`;

const TileImg = styled("img")`
  height: 62px;
  width: 62px;
`;

const CardTitle = styled("h2")<{ featureEnabled?: boolean }>`
  color: ${({ featureEnabled }) => (featureEnabled ? "#007DBA" : "#f15b23")};
  font: normal normal 500 1rem/1.3125rem Roboto;
  letter-spacing: 0px;
  text-align: center;
  margin: 12px;
`;

const CardTitleEmphasized = styled("h2")`
  color: #f15b23;
  font: normal normal 500 1rem/1.3125rem Roboto;
  letter-spacing: 0px;
  text-align: center;
`;

const BaseButton = styled("button")`
  border: 1px solid #00a637;
  border-radius: 4px;
  font: normal normal 500 1rem/1.3125rem Roboto;
  letter-spacing: 0px;
  text-align: center;

  height: 40px;
  width: 175px;

  &:hover:not(:disabled) {
    cursor: pointer;
  }
`;

// didn't used AppButton w/ AppButtonStyles to keep
// the same API between filled and outlined buttons
const FilledButton = styled(BaseButton)`
  background-color: #00a637;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);

  color: #ffffff;

  &:hover,
  &:active {
    opacity: 0.85;
  }
`;

function FeatureButton({
  id,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  id: string;
}) {
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <FilledButton
      data-qatag={id}
      id={id}
      {...rest}
    />
  );
}

type FeatureCardProps = {
  isLicenseExpired: boolean;
  tileData: IFeatureTileData;
  handleTileClick: (tileType: eFeatureTileTypes) => void;
};

export function FeatureCard({ isLicenseExpired, tileData, handleTileClick }: FeatureCardProps) {
  const tileTypeString: string = eFeatureTileTypes[tileData.tileType];

  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <Card data-qatag={`FeatureCard.${tileTypeString}`}>
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag*/}
      <Badge
        data-qatag={`FeatureCard.StatusBadge.${tileTypeString}`}
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={
          <BadgeImg
            alt="badge"
            data-qatag="badgeContent"
            src={!isLicenseExpired && tileData.isEnabled && !tileData.needsAttention ? checkIcon : alertIcon}
          />
        }
        sx={{ marginLeft: 2, marginRight: 2 }}
      >
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag*/}
        <TileImg
          data-qatag={`FeatureCard.StatusIcon.${tileTypeString}`}
          src={tileData.imageSrc}
          alt="Status Icon"
        />
      </Badge>
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag*/}
      <CardContent data-qatag={`CardContent.${tileTypeString}`}>
        {GetCardTitle(tileData.isEnabled, isLicenseExpired, tileData.needsAttention, tileTypeString, tileData.title)}
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <FeatureButton
          data-qatag={`CardBtn.${tileTypeString}`}
          id={`CardBtn.${tileTypeString}`}
          type="button"
          onClick={() => handleTileClick(tileData.tileType)}
        >
          {tileData.isEnabled && !isLicenseExpired ? (
            <FormattedMessage
              id="DeviceOverview.CardDeviceUpdatesActiveBtnMsg"
              data-qatag="DeviceOverview.CardDeviceUpdatesActiveBtnMsg"
              defaultMessage="{cta}"
              values={{
                cta: tileData.enabledCTA,
              }}
            />
          ) : (
            <FormattedMessage
              id="DeviceOverview.CardDeviceUpdatesInactiveBtnMsg"
              data-qatag="DeviceOverview.CardDeviceUpdatesInactiveBtnMsg"
              defaultMessage="{cta}"
              values={{
                cta: tileData.disabledCTA,
              }}
            />
          )}
        </FeatureButton>
      </CardContent>
    </Card>
  );
}

function GetCardTitle(
  enabled: boolean,
  isLicenseExpired: boolean,
  needsAttention: boolean,
  tileTypeString: string,
  title: string
): JSX.Element {
  if (needsAttention && enabled && !isLicenseExpired) {
    return (
      // eslint-disable-next-line solveiq/no-empty-data-qatag
      <CardTitleEmphasized data-qatag={`CardTitle.emphasized.${tileTypeString}`}>
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <FormattedMessage
          id="card-message-title"
          data-qatag={`CardMessage.${tileTypeString}`}
          defaultMessage="{title}"
          values={{
            title: title,
          }}
        />
      </CardTitleEmphasized>
    );
  }
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <CardTitle
      data-qatag={`CardTitle.${tileTypeString}`}
      featureEnabled={enabled && !isLicenseExpired}
    >
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag*/}
      <FormattedMessage
        id="card-message.title"
        data-qatag={`CardMessage.${tileTypeString} `}
        defaultMessage="{title}"
        values={{
          title: title,
        }}
      />
    </CardTitle>
  );
}
