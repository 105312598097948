import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";

export type UpdateRequiredAlertProps = {
  /* Controls to open the alert */
  isOpen: boolean;
  /* Callback to close the alert*/
  closeHandler?: () => void;
};

export function UpdateRequiredAlert({ isOpen, closeHandler = () => {} }: UpdateRequiredAlertProps) {
  return (
    <Alert
      data-qatag="updateRequiredAlert"
      icon={AlertIcon.Warning}
      title={
        <FormattedMessage
          data-qatag="update.required"
          id="update.required.title"
          defaultMessage="Update Required"
        />
      }
      text={
        <>
          <FormattedMessage
            data-qatag="update.required.text1"
            id="update.required.text1"
            defaultMessage="We need to update to the latest version before you can continue using the software."
          />
          <br data-qatag="update.required.next.line" />
          <br data-qatag="update.required.next.line2" />
          <FormattedMessage
            data-qatag="update.required.text2"
            id="update.required.text2"
            defaultMessage="Click the button to update."
          />
        </>
      }
      buttonText="Update"
      isOpen={isOpen}
      closeHandler={closeHandler}
      approveHandler={closeHandler}
    />
  );
}

export default UpdateRequiredAlert;
