import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RESTGatewayAPI from "api/gatewayAPI";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { RootState } from "app/redux/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { IBlockedProcessCollectionMessage } from "./DeceptorProtectionMessages";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { uniqueConcat } from "core/Helpers";

interface IDeceptorProtectionState {
  deceptorsFound: IDeceptorInfo[];
  deceptorsBlocked: IDeceptorInfo[];
  allowedSoftware: IDeceptorInfo[];
  historicalSoftware: IBlockedProcessCollectionMessage | null;
}

export interface IDeceptorInfo {
  Id: string;
  ListId: string; //to differentiate duplicate entries
  Name: string;
  FilePath: string;
  BlockDate: string | null;
  AppEsteemViolations: string[];
  AppEsteemNonDeceptorViolations: string[];
  AvBlockList: string[];
  AvAllowList: string[];
  IsAllowed: boolean;
  InstanceID?: string;
}

interface IDeceptorFoundPayloadEntry {
  Deceptors: IDeceptorFoundPayloadEntryDeceptor[];
  LocalFileName: string;
  LocalFilePathName: string;
}

interface IDeceptorFoundPayloadEntryDeceptor {
  DeceptorId: string;
  Name: string;
  AppEsteemViolations: string[];
  AppEsteemNonDeceptorViolations: string[];
  Samples: IDeceptorFoundPayloadEntryDeceptorSamplesItem[];
}

interface IDeceptorFoundPayloadEntryDeceptorSamplesItem {
  AvBlockList: string[];
  AvAllowList: string[];
  FileName: string;
  FileVersion: string;
}

interface IFetchAllowedSoftwareResponse {
  AllowedProceses: IAllowedProcess[]; //yes, it's misspelled
}

interface IAllowedProcess {
  DateBlocked: string;
  FilePathName: string;
  Hash: string;
  Tag: string;
}

export const fetchHistoricalToMap = createAsyncThunk<IBlockedProcessCollectionMessage, void, { state: RootState }>(
  "deceptorProtection/fetchHistoricalToMap",
  async (_, thunkApi) => {
    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();
      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.GET_BLOCKED_PROC_HISTORY;
      const response: IBlockedProcessCollectionMessage = (await srhub.SendAsync(message)).Payload;
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch blocked deceptors : ${error}`);
    }
  }
);

export const fetchDeceptorsBlocked = createAsyncThunk<IDeceptorInfo[], void, { state: RootState }>(
  "deceptorProtection/fetchDeceptorsBlocked",
  async (_, thunkApi) => {
    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();
      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.GET_BLOCKED_PROC_HISTORY;
      const response: IBlockedProcessCollectionMessage = (await srhub.SendAsync(message)).Payload;

      const retVal: IDeceptorInfo[] = [];
      for (const [key, value] of Object.entries(response)) {
        const deceptor = value[value.length - 1]; //use the most recent entry
        const newVal: IDeceptorInfo = {
          ...deceptor,
          Id: deceptor.InstanceID,
          ListId: deceptor.InstanceID,
          Name: deceptor.DeceptorInfo.Deceptors[0]?.Name ?? deceptor.ProcName,
          FilePath: key,
          BlockDate: new Date(deceptor.BlockDate).toLocaleDateString(), //convert UTC timestamp to nice date string
          IsAllowed: false,
        };
        retVal.push(newVal);
      }

      return retVal;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch blocked deceptors : ${error}`);
    }
  }
);

export const fetchDeceptorsFound = createAsyncThunk<IDeceptorInfo[], void, { state: RootState }>(
  "deceptorProtection/fetchDeceptorsFound",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const uuid = selectCurrentUuid(state);
      const url = `/api/scan/deceptor/${uuid}`;
      const apiResponse = await RESTGatewayAPI.get(url);
      const responseObj = JSON.parse(apiResponse.data.payload);
      const results: IDeceptorFoundPayloadEntry[] = responseObj.EventData.Deceptors;
      const retVal: IDeceptorInfo[] = [];
      for (const result of results) {
        const newVal: IDeceptorInfo = {
          Name: result.Deceptors[0]?.Name ?? result.LocalFileName,
          FilePath: result.LocalFilePathName,
          AppEsteemViolations: result.Deceptors.reduce((acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(...curr.AppEsteemViolations);
            return acc;
          }, []),
          AppEsteemNonDeceptorViolations: result.Deceptors.reduce(
            (acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
              acc.push(...curr.AppEsteemNonDeceptorViolations);
              return acc;
            },
            []
          ),
          Id: result.Deceptors[0]?.DeceptorId ?? "no deceptorId found",
          ListId: result.Deceptors[0]?.DeceptorId ?? "no deceptorId found",
          BlockDate: null,
          AvAllowList: result.Deceptors.reduce((acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(
              ...curr.Samples.reduce((acc: string[], curr: IDeceptorFoundPayloadEntryDeceptorSamplesItem) => {
                curr.AvAllowList.forEach((x) => acc.push(x));
                return acc;
              }, [])
            );
            return acc;
          }, []),
          AvBlockList: result.Deceptors.reduce((acc: string[], curr: IDeceptorFoundPayloadEntryDeceptor) => {
            acc.push(
              ...curr.Samples.reduce((acc: string[], curr: IDeceptorFoundPayloadEntryDeceptorSamplesItem) => {
                curr.AvBlockList.forEach((x) => acc.push(x));
                return acc;
              }, [])
            );
            return acc;
          }, []),
          IsAllowed: false,
        };
        const numDupes = retVal.filter((x) => x.Id === newVal.Id).length;
        if (numDupes > 0) {
          newVal.ListId += `-${numDupes}`;
        }
        retVal.push(newVal);
      }
      return retVal;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch found deceptors : ${error}`);
    }
  }
);

export const fetchAllowedSoftware = createAsyncThunk<IDeceptorInfo[], void, { state: RootState }>(
  "deceptorProtection/fetchAllowedSoftware",
  async (_, thunkApi) => {
    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();
      const message: IServiceMessage = new ServiceMessage();
      message.MessageType = WSMessageType.JIT_DRIVER_GET_BLOCKED_PROCS;
      const response: IFetchAllowedSoftwareResponse = (await srhub.SendAsync(message)).Payload;

      const retVal: IDeceptorInfo[] = [];
      for (const process of response.AllowedProceses) {
        const newVal: IDeceptorInfo = {
          //using Tag instead of Hash for Id because the hash can contain "/" which breaks Breadcrumbs when it appears in the url
          Id: process.Tag,
          ListId: process.Tag,
          Name: process.Tag,
          FilePath: process.FilePathName,
          BlockDate: process.DateBlocked,
          AppEsteemViolations: [],
          AppEsteemNonDeceptorViolations: [],
          AvAllowList: [],
          AvBlockList: [],
          IsAllowed: true,
        };
        retVal.push(newVal);
      }

      return retVal;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to fetch allowed software : ${error}`);
    }
  }
);

export const allowSoftware = createAsyncThunk<void, IDeceptorInfo, { state: RootState }>(
  "deceptorProtection/allowSoftware",
  async (deceptorInfo, thunkApi) => {
    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();
      const addMessage: IServiceMessage = new ServiceMessage();
      addMessage.MessageType = WSMessageType.JIT_DRIVER_ADD_ALLOWED_PROC;
      addMessage.Payload = {
        FilePathName: deceptorInfo.FilePath,
        Tag: deceptorInfo.Name,
      };
      await srhub.SendAsync(addMessage);
      const removeMessage: IServiceMessage = new ServiceMessage();
      removeMessage.MessageType = WSMessageType.JIT_DRIVER_REMOVE_BLOCKED_PROC;
      removeMessage.Payload = deceptorInfo.FilePath;
      const removeResponse = await srhub.SendAsync(removeMessage);
      return removeResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to allow software : ${error}`);
    }
  }
);

export const blockSoftware = createAsyncThunk<void, IDeceptorInfo, { state: RootState }>(
  "deceptorProtection/blockSoftware",
  async (deceptorInfo, thunkApi) => {
    try {
      const hub = getSignalRHub();
      const srhub = hub.getInstance();
      const addMessage: IServiceMessage = new ServiceMessage();
      addMessage.MessageType = WSMessageType.JIT_DRIVER_ADD_BLOCKED_PROC;
      addMessage.Payload = {
        FilePathName: deceptorInfo.FilePath,
        Tag: deceptorInfo.Name,
      };
      await srhub.SendAsync(addMessage);
      const removeMessage: IServiceMessage = new ServiceMessage();
      removeMessage.MessageType = WSMessageType.JIT_DRIVER_REMOVE_ALLOWED_PROC;
      removeMessage.Payload = deceptorInfo.FilePath;
      const removeResponse = await srhub.SendAsync(removeMessage);
      return removeResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(`Unable to block software : ${error}`);
    }
  }
);

//create duplicate allowed entries to match duplicate found entries
//and pull in missing data
const createAllowedDuplicatesFromFound = (state: IDeceptorProtectionState) => {
  const data = { ...state };

  if (state.allowedSoftware.length === 0) {
    return data;
  }

  state.allowedSoftware.forEach((allowed) => {
    const deceptors = state.deceptorsFound.filter((x) => x.Name === allowed.Name);

    deceptors.forEach((deceptor) => {
      //the matching entry. Filepath casing is different between the two for some reason
      if (deceptor.FilePath.toLowerCase() === allowed.FilePath.toLowerCase()) {
        allowed.AppEsteemViolations = uniqueConcat(allowed.AppEsteemViolations, deceptor.AppEsteemViolations);
        allowed.AppEsteemNonDeceptorViolations = uniqueConcat(
          allowed.AppEsteemNonDeceptorViolations,
          deceptor.AppEsteemNonDeceptorViolations
        );
        allowed.AvBlockList = uniqueConcat(allowed.AvBlockList, deceptor.AvBlockList);
        allowed.AvAllowList = uniqueConcat(allowed.AvAllowList, deceptor.AvAllowList);
        return;
      }

      //create duplicate
      const newEntry: IDeceptorInfo = {
        Id: allowed.Id,
        ListId: deceptor.ListId,
        Name: allowed.Name,
        FilePath: deceptor.FilePath,
        BlockDate: allowed.BlockDate,
        AppEsteemViolations: uniqueConcat(allowed.AppEsteemViolations, deceptor.AppEsteemViolations),
        AppEsteemNonDeceptorViolations: uniqueConcat(
          allowed.AppEsteemNonDeceptorViolations,
          deceptor.AppEsteemNonDeceptorViolations
        ),
        AvBlockList: uniqueConcat(allowed.AvBlockList, deceptor.AvBlockList),
        AvAllowList: uniqueConcat(allowed.AvAllowList, deceptor.AvAllowList),
        IsAllowed: allowed.IsAllowed,
      };
      data.allowedSoftware.push(newEntry);
    });
  });

  return data;
};

//remove the duplicates created above, so they can be recreated with the new data that just arrived
const removeDuplicateAllowedEntries = (state: IDeceptorProtectionState) => {
  const data = { ...state };

  //The data we fetch has matching Name and ListId, so only the dupes are different
  const dupes = state.allowedSoftware.filter((x) => x.Name !== x.ListId);
  dupes.forEach((entry) => {
    const index = data.allowedSoftware.findIndex((x) => x.ListId === entry.ListId);
    data.allowedSoftware.splice(index, 1);
  });

  return data;
};

//removes allowed software from blocked list and adds missing data to allowed entries
const removeAllowedSoftwareFromBlockedList = (state: IDeceptorProtectionState) => {
  const data = { ...state };

  if (state.allowedSoftware.length === 0) {
    return data;
  }

  state.deceptorsBlocked.forEach((deceptor) => {
    const allowed = data.allowedSoftware.find((a) => a.Name === deceptor.Name);
    if (!allowed) {
      return;
    }

    allowed.AvAllowList = uniqueConcat(allowed.AvAllowList, deceptor.AvAllowList);
    allowed.AvBlockList = uniqueConcat(allowed.AvBlockList, deceptor.AvBlockList);
    allowed.AppEsteemViolations = uniqueConcat(allowed.AppEsteemViolations, deceptor.AppEsteemViolations);
    allowed.AppEsteemNonDeceptorViolations = uniqueConcat(
      allowed.AppEsteemNonDeceptorViolations,
      deceptor.AppEsteemNonDeceptorViolations
    );

    const index = data.deceptorsBlocked.findIndex((d) => d.Id === deceptor.Id);
    data.deceptorsBlocked.splice(index, 1);
  });

  return data;
};

const initialState: IReducerState<IDeceptorProtectionState> = {
  data: {
    deceptorsFound: [],
    deceptorsBlocked: [],
    allowedSoftware: [],
    historicalSoftware: null,
  },
  status: {
    [fetchDeceptorsBlocked.typePrefix]: ReducerStatus.Idle,
    [fetchDeceptorsFound.typePrefix]: ReducerStatus.Idle,
    [fetchAllowedSoftware.typePrefix]: ReducerStatus.Idle,
    [fetchHistoricalToMap.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const deceptorProtectionSlice = createSlice({
  name: "deceptorProtection",
  initialState,
  reducers: {
    resetDeceptorProtectionState: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoricalToMap.pending, (state) => {
        state.status[fetchHistoricalToMap.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchHistoricalToMap.fulfilled, (state, action) => {
        if (state.data) {
          state.data.historicalSoftware = action.payload;
          state.error = "";
          state.status[fetchHistoricalToMap.typePrefix] = ReducerStatus.Succeeded;
        }
      })
      .addCase(fetchHistoricalToMap.rejected, (state, action) => {
        state.status[fetchHistoricalToMap.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      })
      .addCase(fetchDeceptorsBlocked.pending, (state) => {
        state.status[fetchDeceptorsBlocked.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchDeceptorsBlocked.fulfilled, (state, action) => {
        if (state.data) {
          state.data.deceptorsBlocked = action.payload;
          state.error = "";
          state.status[fetchDeceptorsBlocked.typePrefix] = ReducerStatus.Succeeded;

          state.data = removeAllowedSoftwareFromBlockedList(state.data);
        }
      })
      .addCase(fetchDeceptorsBlocked.rejected, (state, action) => {
        state.status[fetchDeceptorsBlocked.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      })
      .addCase(fetchDeceptorsFound.pending, (state) => {
        state.status[fetchDeceptorsFound.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchDeceptorsFound.fulfilled, (state, action) => {
        if (state.data) {
          state.data.deceptorsFound = action.payload;
          state.error = "";
          state.status[fetchDeceptorsFound.typePrefix] = ReducerStatus.Succeeded;

          state.data = removeDuplicateAllowedEntries(state.data);
          state.data = createAllowedDuplicatesFromFound(state.data);
        }
      })
      .addCase(fetchDeceptorsFound.rejected, (state, action) => {
        state.status[fetchDeceptorsFound.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      })
      .addCase(fetchAllowedSoftware.pending, (state) => {
        state.status[fetchAllowedSoftware.typePrefix] = ReducerStatus.Loading;
      })
      .addCase(fetchAllowedSoftware.fulfilled, (state, action) => {
        if (state.data) {
          state.data.allowedSoftware = action.payload;
          state.error = "";
          state.status[fetchAllowedSoftware.typePrefix] = ReducerStatus.Succeeded;

          state.data = createAllowedDuplicatesFromFound(state.data);
          state.data = removeAllowedSoftwareFromBlockedList(state.data);
        }
      })
      .addCase(fetchAllowedSoftware.rejected, (state, action) => {
        state.status[fetchAllowedSoftware.typePrefix] = ReducerStatus.Failed;
        state.error = action.error.message;
      });
  },
});

export const { resetDeceptorProtectionState } = deceptorProtectionSlice.actions;

export const selectDeceptorsBlocked = (state: RootState) => {
  return state.deceptorProtection.data.deceptorsBlocked;
};

export const selectHistoricalSoftware = (state: RootState) => {
  return state.deceptorProtection.data.historicalSoftware;
};

export const selectDeceptorsFound = (state: RootState) => {
  return state.deceptorProtection.data.deceptorsFound;
};

export const selectAllowedSoftware = (state: RootState) => {
  return state.deceptorProtection.data.allowedSoftware;
};

export const selectStatus = (state: RootState) => {
  return state.deceptorProtection.status;
};

export const selectDeceptor = (state: RootState, id: string) => {
  if (!id) {
    return null;
  }

  let target: IDeceptorInfo | null = null;

  state.deceptorProtection.data.deceptorsBlocked.forEach((x) => {
    if (x.ListId === id) {
      target = x;
    }
  });
  if (target) return target;

  state.deceptorProtection.data.allowedSoftware.forEach((x) => {
    if (x.ListId === id) {
      target = x;
    }
  });
  if (target) return target;

  state.deceptorProtection.data.deceptorsFound.forEach((x) => {
    if (x.ListId === id) {
      target = x;
    }
  });

  return target;
};

export default deceptorProtectionSlice.reducer;
