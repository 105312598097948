import { FormattedMessage } from "react-intl";
import { Box, Container } from "@mui/material";
import familyIcon from "assets/img/peopleIcon.svg";
import { useMemo } from "react";
import IFamilyMember from "model/user/IFamilyMember";
import { HeaderRow } from "./HeaderRow/HeaderRow";
import { FamilyMemberTableRow } from "./Row/Row";
import styled from "@mui/styled-engine";

const StyledContainer = styled(Container)`
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const TableControls = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 1.25rem;

  & > div {
    flex-grow: 0;
    vertical-align: middle;
    text-align: center;

    ${({ theme }) => theme.breakpoints.down("md")} {
      border: 0.063rem solid #e8e8e8;
      width: 100%;
      box-sizing: border-box;
      padding: 0.625rem;
    }
  }

  & > div:not(:first-of-type) {
    margin-left: 1.5rem;

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-left: 0;
    }
  }

  & > span {
    vertical-align: middle;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-wrap: wrap;
    margin-bottom: 0;
    background-color: #ffffff;
  }
`;

const TableControlsImg = styled("img")`
  margin-right: 0.75rem;
  vertical-align: middle;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-right: 0.5rem;
  }
`;

const StyledFamilyMemberTable = styled("table")`
  border-collapse: collapse;
  font-size: 1rem;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
  }
`;

const FamilyMemberTableBody = styled("tbody")`
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
  }
`;

export interface IFamilyMemberTableProps {
  currentUuid: string | null;
  localAgentUuid: string | null;
  familyMembers: IFamilyMember[];
  handleDelete: (familyMember: IFamilyMember) => void;
  handleFamilyMemberRemoteConnect: (familyMember: IFamilyMember) => void;
  handleResendEmail: (familyMember: IFamilyMember) => void;
  handleDownload: () => void;
}

export const FamilyMemberTable: React.FC<IFamilyMemberTableProps> = ({
  currentUuid,
  localAgentUuid,
  handleDelete,
  handleFamilyMemberRemoteConnect,
  handleResendEmail,
  handleDownload,
  familyMembers = [],
}) => {
  const familyMemberCount = useMemo(() => familyMembers.length, [familyMembers]);
  let isLastAdmin = false;
  const count = familyMembers.reduce((prev, current) => {
    return prev + Number(current.isAdmin === true);
  }, 0);
  isLastAdmin = count <= 1;
  const isLastUser = familyMembers.length <= 1;
  return (
    <StyledContainer data-qatag="familyMemberTableContainer">
      <TableControls data-qatag="familyMemberTableControls">
        <Box
          data-qatag="familyMemberTableControlsFamily"
          sx={{ alignItems: "center", display: "flex", width: "100%" }}
        >
          <TableControlsImg
            data-qatag="familyIcon"
            src={familyIcon}
            alt=""
          />
          <FormattedMessage
            id="familyLengthMessage"
            data-qatag="familyLengthMessage"
            defaultMessage="<b>Family ({length})</b>"
            values={{
              length: familyMemberCount,
              b: (chunks) => <strong data-qatag="familyLengthMessageBoldText">{chunks}</strong>,
            }}
          />
        </Box>
      </TableControls>

      <StyledFamilyMemberTable data-qatag="familyMemberTable">
        <HeaderRow data-qatag="familyMemberTableHeader" />
        <FamilyMemberTableBody data-qatag="familyMemberTableTbody">
          {familyMembers.map((member, i) => {
            return (
              <FamilyMemberTableRow
                data-qatag="familyMemberTableRow"
                familyMember={member}
                key={"familyMember" + i}
                currentUuid={currentUuid}
                localAgentUuid={localAgentUuid}
                isLastAdmin={isLastAdmin}
                isLastUser={isLastUser}
                handleDelete={handleDelete}
                handleFamilyMemberRemoteConnect={handleFamilyMemberRemoteConnect}
                handleResendEmail={handleResendEmail}
                handleDownload={handleDownload}
              />
            );
          })}
        </FamilyMemberTableBody>
      </StyledFamilyMemberTable>
    </StyledContainer>
  );
};
