import visaIcon from "assets/icon/icn-visa.svg";
import mastercardIcon from "assets/icon/icn-mastercard.svg";
import discoverIcon from "assets/icon/icn-discover.svg";
import amexIcon from "assets/icon/icn-amex.svg";
import PayPalLogo from "assets/img/PayPalLogo.png";

interface IIconDictionary {
  [key: string]: string;
}
const icons: IIconDictionary = {
  AmericanExpress: amexIcon,
  Discover: discoverIcon,
  MasterCard: mastercardIcon,
  Visa: visaIcon,
  PayPal: PayPalLogo,
};

export function getIconFromPaymentMethod(paymentMethod: string) {
  return icons[paymentMethod];
}
