import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/redux/store";
import { IReceipt } from "./types";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";

export const fetchReceipt = createAsyncThunk<
  IReceipt[],
  void,
  {
    state: RootState;
  }
>("users/fetchReceipt", async (_, thunkApi) => {
  try {
    const url = `/api/Billing/history`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse?.data?.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch the receipts : ${error}`);
  }
});

const initialState: IReducerState<IReceipt[]> = {
  data: [],
  status: {
    [fetchReceipt.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IReceipt[]>) => {
      state.data = action.payload;
    },
    setReceiptsStatus: (state) => {
      state.status[fetchReceipt.typePrefix] = ReducerStatus.Idle;
    },
    resetBillingHistoryState: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReceipt.pending, (state) => {
      state.status[fetchReceipt.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchReceipt.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchReceipt.typePrefix] = ReducerStatus.Succeeded;
    });
  },
});

export const { resetBillingHistoryState, set, setReceiptsStatus } = receiptSlice.actions;

export const selectReceipts = (state: RootState) => state.receipts.data;
export const selectReceiptsStatus = (state: RootState) => state.receipts.status[fetchReceipt.typePrefix];

export default receiptSlice.reducer;
