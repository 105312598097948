import detailsIcon from "assets/icon/icn-details-info.png";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Box } from "@mui/material";
import icnPremium from "assets/img/icn-resumed-subscription.svg";
import { FormattedMessage } from "react-intl";
import { Layout } from "components/Layout/Layout";
import { Spinner, Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import styled from "@mui/styled-engine";

export type ResumedSubscriptionProps = {
  name?: string;
  /* Total to pay*/
  total: number;
  /* recurring charge */
  recurringCharge: number;
  /* currency char */
  currencyChar?: string;
  /* Callback to be redirect */
  handleRedirect: () => void;
  isLoadingSubscription: boolean;
  isSubscriptionError: boolean;
};

//#region component styles

const ResumedSubscriptionContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PremiumIllustrationContainer = styled(Box)`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin-top: 14px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 48px;
  }
`;

const PremiumIllustration = styled("img")`
  max-width: 70px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 98px;
  }
`;

const Title = styled(Box)`
  margin-top: 20px;
  text-align: center;
  flex-basis: 100%;
  font: normal normal 500 1.125rem Roboto;
  color: #007dba;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 22px;
    font: normal normal 500 1.5rem Roboto;
  }
`;

const Subtitle = styled(Box)`
  margin-top: 8px;
  text-align: center;
  flex-basis: 100%;
  font: normal normal 400 1.125rem Roboto;
  color: #007dba;
  max-width: 224px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 400 1.5rem Roboto;
    max-width: unset;
  }
`;

const LegendContainer = styled(Box)`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Legend = styled(Box)`
  margin-top: 20px;
  max-width: 274px;
  text-align: center;
  font: normal normal 400 1rem/1.5rem Roboto;
  letter-spacing: 0.16px;
  color: #535a6a;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 12px;
    max-width: 502px;
  }
`;

const SummaryOrderContainer = styled(Box)`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SummaryOrder = styled(Box)`
  margin-top: 32px;
  width: 280px;
  align-self: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 404px;
    margin-top: 42px;
  }
`;

const SummaryTitle = styled(Box)`
  font: normal normal 500 1rem/2rem Roboto;
  color: #535353;
  border-bottom: solid 1px #d3d3d3;
  padding-bottom: 8px;
`;

const RowTotal = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: solid 1px #d3d3d3;
  margin-top: 8px;
  padding-bottom: 18px;
`;

const TotalLabel = styled("span")`
  font: normal normal normal 1rem/2.25rem Roboto;
  letter-spacing: 0px;
  color: #535a6a;
`;

const TotalContainer = styled(Box)`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TotalValue = styled("span")`
  font: normal normal 600 1rem Roboto;
  color: #007dba;
`;

const FrequencyValue = styled("span")`
  font: normal normal 500 0.75rem Roboto;
  color: #535a6a;
  margin-top: 4px;
`;

const AutoRenewalContainer = styled(Box)`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 32px;
  }
`;

const AutoRenewalText = styled("div")`
  color: #535a6a;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  align-items: baseline;
`;

const RenewMessage = styled(Box)`
  width: 90%;
  width: 260px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 408px;
  }
`;

const DetailsIcon = styled("img")`
  margin-right: 8px;
`;

const PremiumButton = styled(AppButton)`
  width: 280px;
  max-width: 100%;
  outline: none;
  height: 44px;
  margin: 44px auto 40px auto;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 370px;
    margin: 38px auto 80px auto;
  }
  &:hover {
    opacity: 0.9;
  }
`;

//#endregion component styles

function ResumedSubscription({
  name,
  total,
  recurringCharge,
  currencyChar = "$",
  handleRedirect,
  isLoadingSubscription,
  isSubscriptionError,
}: ResumedSubscriptionProps) {
  const { formatMessage } = useIntl();
  return (
    <Layout
      data-qatag="backgroundContainer"
      qatag="backgroundContainer"
    >
      <ResumedSubscriptionContainer data-qatag="container">
        <PremiumIllustrationContainer data-qatag="premiumIllustrationContainer">
          <PremiumIllustration
            data-qatag="reviewIcon"
            src={icnPremium}
            alt="review"
          />
        </PremiumIllustrationContainer>
        <Title data-qatag="title">
          <FormattedMessage
            data-qatag="premium-upgrade.title"
            id="premium-upgrade.title"
            defaultMessage={"{name}, Congratulations!"}
            values={{
              name: <span data-qatag="premium-upgrade.name">{name}</span>,
            }}
          />
        </Title>
        <Subtitle data-qatag="subtitle">
          <FormattedMessage
            data-qatag="premium-upgrade.subtitle"
            id="premium-upgrade.subtitle"
            defaultMessage="Your subscription has been upgraded to premium."
          />
        </Subtitle>
        <LegendContainer data-qatag="legendContainer">
          <Legend data-qatag="legend">
            <FormattedMessage
              data-qatag="premium-upgrade.legend"
              id="premium-upgrade.legend"
              defaultMessage="You now have a premium subscription. Below is what we have charged you. You and your devices are now safer, faster, and up to date."
            />
          </Legend>
        </LegendContainer>

        <SummaryOrderContainer data-qatag="summaryOrderContainer">
          <SummaryOrder data-qatag="summaryOrder">
            <SummaryTitle data-qatag="summaryTitle">
              <FormattedMessage
                data-qatag="premium-upgrade.title-summary"
                id="premium-upgrade.title-summary"
                defaultMessage="Today's Pro-rated charge"
              />
            </SummaryTitle>

            <RowTotal data-qatag="rowTotal">
              <TotalLabel data-qatag="TotalLabel">
                <FormattedMessage
                  data-qatag="premium-upgrade.total-label"
                  id="premium-upgrade.total-label"
                  defaultMessage="Total"
                />
              </TotalLabel>
              <TotalContainer data-qatag="total">
                <TotalValue data-qatag="totalValue">${total.toFixed(2)}</TotalValue>
                <FrequencyValue data-qatag="frequency">
                  <FormattedMessage
                    data-qatag="premium-upgrade.total-one-time"
                    id="premium-upgrade.total-one-time"
                    defaultMessage="One time"
                  />
                </FrequencyValue>
              </TotalContainer>
            </RowTotal>
          </SummaryOrder>
        </SummaryOrderContainer>

        <AutoRenewalContainer data-qatag="autoRenewalContainer">
          <AutoRenewalText data-qatag="description">
            <DetailsIcon
              data-qatag="detailsIcon"
              src={detailsIcon}
              alt=""
            />
            <RenewMessage data-qatag="RenewMessage">
              <FormattedMessage
                data-qatag="premium-upgrade.this-plan-will-now"
                id="premium-upgrade.this-plan-will-now"
                defaultMessage="This plan will now <b>auto-renew every month for {currencyChar}{recurringCharge}</b>, unless cancelled before the next billing cycle. All prices are in USD."
                values={{
                  b: (chunks) => <strong data-qatag="bold">{chunks}</strong>,
                  currencyChar: currencyChar,
                  recurringCharge: recurringCharge.toFixed(2),
                }}
              />
            </RenewMessage>
          </AutoRenewalText>
        </AutoRenewalContainer>

        <PremiumButton
          ariaLabel="Access premium features"
          data-qatag="cardFormButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={handleRedirect}
          buttonType="button"
        >
          <FormattedMessage
            data-qatag="premium-upgrade.access-premium-features"
            id="premium-upgrade.access-premium-features"
            defaultMessage="Back to My System"
          />
        </PremiumButton>
        {/* Loading alert */}
        {isLoadingSubscription ? (
          <Spinner
            data-qatag="resumedSubscriptionSpinner"
            isActive={isLoadingSubscription}
            text={formatMessage({
              id: "resumedSubscriptionSpinner.title",
              defaultMessage: "Loading...",
            })}
          />
        ) : null}
        {/* Alert error */}
        <Alert
          data-qatag="alertError"
          icon={AlertIcon.Warning}
          title={formatMessage({
            id: "resumedSubscriptionSpinner.errorButton.title",
            defaultMessage: "Oops! Something Went Wrong",
          })}
          text={formatMessage({
            id: "resumedSubscriptionSpinner.errorButton.subtitle",
            defaultMessage: "We Apologize for the Inconvenience. You will be redirected to the My Account page.",
          })}
          buttonText={formatMessage({
            id: "resumedSubscriptionSpinner.errorButton.continue",
            defaultMessage: "Continue",
          })}
          approveHandler={handleRedirect}
          isOpen={isSubscriptionError}
          closeHandler={handleRedirect}
        />
      </ResumedSubscriptionContainer>
    </Layout>
  );
}

export default ResumedSubscription;
