import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { LabelValuePair } from "features/SubscriptionManagement/MyAccount/components/LabelValuePair";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";
import styled from "@mui/styled-engine";

const SystemInfoContainer = styled(Box)`
  border-bottom: 1.5px solid #eeeeee;
  border-top: 1.5px solid #eeeeee;
  padding: 32px 0;
  width: 100%;
`;
const StyledIconButton = styled(IconButton)`
  padding: 0 8px;

  :hover {
    background: transparent;
  }
`;
const LicenseCheckButtonContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: space-between;

  .MuiCircularProgress-colorPrimary {
    color: #007dba;
    max-height: 20px;
    max-width: 20px;
  }
`;

export type SystemInfoProps = {
  agentVersion: string;
  "data-qatag": string;
  licenseCheckStatus: string;
  guiVersionNumber: string;
  licensedTo: string;
  onLicenseCheckClicked: () => void;
  onUuidClicked: () => void;
  operatingSystem: string;
  uuid: string;
};
export function SystemInfo({
  agentVersion,
  licenseCheckStatus,
  guiVersionNumber,
  licensedTo,
  onLicenseCheckClicked,
  onUuidClicked,
  operatingSystem,
  uuid,
}: SystemInfoProps) {
  const isLicenseCheckRunning = licenseCheckStatus === "pending";

  return (
    <SystemInfoContainer data-qatag="SystemInfoContainer.box">
      <LabelValuePair
        data-qatag="settings.licenseCheck"
        invertBgColors={true}
        label={`License Check`}
        tag="settings.licenseCheck"
        value={"licenseCheck"}
      >
        <LicenseCheckButtonContainer data-qatag="checkLicense.container">
          {isLicenseCheckRunning ? <CircularProgress data-qatag="check license running" /> : null}
          <AppButton
            ariaLabel="check license"
            buttonType="button"
            data-qatag="licenseCheckButton"
            buttonStyle={AppButtonStyles.LinkBlue}
            isDisabled={isLicenseCheckRunning}
            onClick={onLicenseCheckClicked}
          >
            <Typography
              data-qatag="IdCopyButton"
              variant="body2"
              sx={{ fontWeight: "600" }}
            >
              <FormattedMessage
                data-qatag="licenseCheckButtonMessage"
                id={`settings.view.body.licenseCheckButton`}
                defaultMessage="Check License"
              />
            </Typography>
          </AppButton>
        </LicenseCheckButtonContainer>
      </LabelValuePair>
      <LabelValuePair
        data-qatag="settings.guiVersion"
        invertBgColors={true}
        label="GUI Version"
        tag="settings.guiVersion"
        value={guiVersionNumber}
      />
      <LabelValuePair
        data-qatag="settings.appVersion"
        invertBgColors={true}
        label="Agent Version"
        tag="settings.appVersion"
        value={agentVersion}
      />
      <LabelValuePair
        data-qatag="settings.uuid"
        invertBgColors={true}
        label={`UUID: ${uuid}`}
        tag="settings.uuid"
        value={uuid}
      >
        <Box data-qatag="copyToClipboard.container">
          <AppButton
            ariaLabel="copy to clipboard"
            buttonType="button"
            data-qatag="uuidButton"
            buttonStyle={AppButtonStyles.LinkBlue}
            onClick={onUuidClicked}
          >
            <Typography
              data-qatag="IdCopyButton"
              variant="body2"
              sx={{ fontWeight: "600" }}
            >
              <FormattedMessage
                data-qatag="uuidButtonMessage"
                id={`settings.view.body.uuidButton`}
                defaultMessage="Copy to Clipboard"
              />
            </Typography>
          </AppButton>
          <StyledIconButton
            data-qatag="IconButton"
            onClick={onUuidClicked}
          >
            <ContentCopyIcon
              data-qatag="IconButton.copyToClipboard"
              sx={{ color: "#007DBA", maxHeight: "20px" }}
            />
          </StyledIconButton>
        </Box>
      </LabelValuePair>
      <LabelValuePair
        data-qatag="settings.licensedToEmail"
        invertBgColors={true}
        label="Licensed To"
        tag="settings.licensedToEmail"
        value={licensedTo}
      />
      <LabelValuePair
        data-qatag="settings.OperatingSystem"
        invertBgColors={true}
        label="Operating System"
        tag="settings.OperatingSystem"
        value={operatingSystem}
      />
    </SystemInfoContainer>
  );
}
