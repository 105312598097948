import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useRequestSupportMutation } from "api/SalesforceApi/SalesforceApi";
import { selectCurrentUuid } from "app/redux/applicationSlice";
import { selectCurrentUser } from "session/SessionSlice";
import SupportConfirmation from "./SupportConfirmation";
import { KEY_FALLBACK_UUID } from "./constants";

function SupportConfirmationContainer() {
  const [requestSupport] = useRequestSupportMutation();
  const currentUUID = useSelector(selectCurrentUuid);
  const currentUser = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    requestSupport({
      Email: currentUser.email,
      Name: `${currentUser.firstName} ${currentUser.lastName}`,
      UUID: currentUUID ?? localStorage.getItem(KEY_FALLBACK_UUID)!,
    });
  }, [currentUser, currentUUID]);

  const onConfirm = () => {
    navigate("/status");
  };

  return (
    <SupportConfirmation
      data-qatag="Support.SupportConfirmation"
      email={currentUser?.email ?? ""}
      onConfirm={onConfirm}
    />
  );
}

export default SupportConfirmationContainer;
