import { ReactNode, useEffect, useState } from "react";
import { Layout } from "components/Layout/Layout";
import { Box, Icon, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { PersonOutline as PersonOutlineIcon, MailOutline as MailOutlineIcon } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import useQuery from "hooks/useQuery";
import { CallToActionButton } from "features/SubscriptionManagement/MyAccount/components/AppButtons";
import { useNavigate } from "react-router";
import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";
import styled from "@mui/styled-engine";

const BaseIconContainer = styled(Icon)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
const GreenCheckIconContainer = styled(BaseIconContainer)`
  background: #00a637;
  border-radius: 50%;
  color: white;
  height: 50px;
  margin: 20px;
  width: 50px;
`;
const CenteredHeaderText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.5rem;
  }
`;
const NameAndEmailBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
const Flexbox = styled(Box)`
  align-items: center;
  display: flex;
`;
const NameText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 600;
`;
const EmailText = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
`;
const BlueIconContainer = styled(BaseIconContainer)`
  color: #007dba;
  height: 24px;
  margin: 8px;
  width: 24px;
`;
const BodyText = styled(Typography)`
  font-size: 1rem;
  margin-top: 20px;
  max-width: 400px;
  text-align: center;
`;

type UserObject = {
  email: string;
  name: string;
  sentToMyself: boolean;
};
function InviteSent() {
  const query: URLSearchParams = useQuery();
  const navigate = useNavigate();
  const [userObj, setUserObj] = useState<UserObject>({
    email: "",
    name: "",
    sentToMyself: false,
  });

  useEffect(() => {
    const email: string = query?.get("email") || "";
    const name: string = query?.get("name") || "";
    const isNew: boolean = query?.get("isNew") === "true" || false;
    const sentToMyself: boolean = query?.get("sentToMyself") === "true";
    const user = { email, name, isNew, sentToMyself };

    // if we don't have correct info send back to family
    if (!email || !name) {
      navigate("/family");
    } else {
      setUserObj(user);
    }
  }, [navigate, query]);

  const { email, name, sentToMyself } = userObj;

  return (
    <Layout
      data-qatag="inviteSent"
      qatag="inviteSent"
    >
      <>
        <GreenCheckIconContainer data-qatag="inviteSent.GreenCheckIconContainer">
          <CheckIcon data-qatag="inviteSent.icon" />
        </GreenCheckIconContainer>
        <CenteredHeaderText data-qatag="inviteSent.headerCongrats">
          <FormattedMessage
            id="inviteSent.headerCongratsText"
            data-qatag="inviteSent.headerCongratsText"
            defaultMessage="Congratulations"
          />
        </CenteredHeaderText>
        <CenteredHeaderText data-qatag="inviteSent.headerEmailSent">
          <FormattedMessage
            id="inviteSent.headerEmailSentText"
            data-qatag="inviteSent.headerEmailSentText"
            defaultMessage="An email has been sent to"
          />
        </CenteredHeaderText>
        <NameAndEmailBox data-qatag="inviteSent.nameAndEmailContainer">
          <Flexbox data-qatag="inviteSent.nameContainer">
            <BlueIconContainer data-qatag="inviteSent.nameIconContainer">
              <PersonOutlineIcon data-qatag="inviteSent.nameIcon" />
            </BlueIconContainer>
            <NameText data-qatag="inviteSent.nameText">{name}</NameText>
          </Flexbox>
          <Flexbox data-qatag="inviteSent.emailContainer">
            <BlueIconContainer data-qatag="inviteSent.emailIconContainer">
              <MailOutlineIcon data-qatag="inviteSent.emailIcon" />
            </BlueIconContainer>
            <EmailText data-qatag="inviteSent.emailIconText">{email}</EmailText>
          </Flexbox>
        </NameAndEmailBox>
        <BodyText data-qatag="inviteSent.body1TextContainer">
          <FixedFormattedMessage
            id="inviteSent.body1Text"
            data-qatag="inviteSent.body1Text"
            defaultMessage={
              sentToMyself
                ? "Invitees need to check their email and follow the instructions provided."
                : "Be sure to let them know to check their email and follow the instructions provided."
            }
          />
        </BodyText>
        <BodyText data-qatag="inviteSent.body2TextContainer">
          <FixedFormattedMessage
            id="inviteSent.body2Text"
            data-qatag="inviteSent.body2Text"
            defaultMessage={
              sentToMyself
                ? "The <b>My Family</b> page will update when they have installed Driver Support."
                : "You can check the <b>My Family</b> page to see if they have completed the installation steps."
            }
            values={{
              b: (chunks: ReactNode[]) => <strong data-qatag="my-family-bold-text">{chunks}</strong>,
            }}
          />
        </BodyText>
        <CallToActionButton
          ariaLabel="Back to My Family"
          additionalStyles={{ marginTop: "56px", width: "280px" }}
          data-qatag="callToActionButton"
          linkPath={"/family"}
          linkText={"Back to My Family"}
          tag="inviteSent"
          buttonType="button"
        />
      </>
    </Layout>
  );
}

export default InviteSent;
