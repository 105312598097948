import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";
import { CenteredHeaderText, SectionContainer, SubText } from "../SharedStyledComponents";
import IFamilyMember from "model/user/IFamilyMember";
import { AppButton, AppButtonStyles, SimpleTextField } from "dsoneweb.designsystem";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { object, string } from "yup";
import { EmailValidator } from "commons-validator-js";
import styled from "@mui/styled-engine";

const StyledForm = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiInputBase-root {
    max-width: 330px;
    min-width: 280px;
  }
`;

const FormContainer = styled(Box)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 16px;
  max-width: 330px;
  padding: 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 200px;
  }
`;

export interface IAddFamilySectionProps {
  familyMembers: IFamilyMember[];
  onAddFamilyMember: (name: string, email: string) => void;
}

export interface IAddFamilyFormItems {
  email: string;
  name: string;
}
export function AddFamilySection({ familyMembers, onAddFamilyMember }: IAddFamilySectionProps) {
  const handleAddFamilyMember = ({ name, email }: IAddFamilyFormItems) => {
    onAddFamilyMember(name, email);
  };
  const { formatMessage } = useIntl();
  const familyEmails = familyMembers.map((user) => user.email.toLowerCase());
  const validateEmail = (value: string) => {
    const validator = new EmailValidator();
    let error;
    if (value && familyEmails.includes(value.toLowerCase())) {
      error = formatMessage({
        id: `form.email.unique`,
        defaultMessage: "This email address has already been used",
      });
    }
    // custom validator recommended on the ticket for scenarios like <email>.lcom
    if (value && !validator.isValid(value)) {
      error = formatMessage({
        id: `form.email.invalid`,
        defaultMessage: "Not a valid email address",
      });
    }
    return error;
  };
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string()
          .matches(
            /^[A-Za-z']+\s[A-Za-z']+$/,
            formatMessage({
              id: `form.name.format`,
              defaultMessage: "First and Last name are required and may only be separated by a single space",
            })
          )
          .required(
            formatMessage({
              id: `form.name.required`,
              defaultMessage: "First and Last name are required",
            })
          ),
        email: string()
          .email(
            formatMessage({
              id: `form.email.invalid`,
              defaultMessage: "Not a valid email address",
            })
          )
          .required(
            formatMessage({
              id: `form.email.required`,
              defaultMessage: "Email address is required",
            })
          ),
      }),
    [formatMessage]
  );

  return (
    <SectionContainer data-qatag="AddPCAddFamilyContainer.AddFamilyContainer">
      <CenteredHeaderText data-qatag="AddPCAddFamilyContainer.headerTextContainer">
        <FixedFormattedMessage
          id={"AddPCAddFamilyContainer.addFamilyContainer.headerText"}
          data-qatag="AddPCAddFamilyContainer.addFamilyContainer.headerText"
          defaultMessage="A Family Member"
        />
      </CenteredHeaderText>
      <SubText data-qatag="AddPCAddFamilyContainer.subTextContainer">
        <FixedFormattedMessage
          id={"AddPCAddFamilyContainer.addFamilyContainer.subText"}
          data-qatag="AddPCAddFamilyContainer.addFamilyContainer.subText"
          defaultMessage="Add DriverSupport to this family member’s PC."
        />
      </SubText>

      <Formik
        data-qatag="AddPCAddFamilyContainer.formik.container"
        initialValues={{
          name: "",
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleAddFamilyMember(values);
        }}
      >
        {({ errors, touched }) => {
          const hasErrors = errors?.name !== undefined || errors?.email !== undefined;
          return (
            <StyledForm data-qatag="AddPCAddFamilyContainer.formik.form">
              <FormContainer data-qatag="AddPCAddFamilyContainer.addFamilyContainer.form">
                <Field
                  data-qatag="AddPCAddFamilyContainer.addFamilyContainer.email"
                  component={SimpleTextField}
                  validate={validateEmail}
                  name="email"
                  type="text"
                  label={
                    <FormattedMessage
                      data-qatag="form.email.address"
                      id="form.email.address"
                      defaultMessage="Email Address"
                    />
                  }
                />
                <Field
                  data-qatag="AddPCAddFamilyContainer.addFamilyContainer.name"
                  component={SimpleTextField}
                  name="name"
                  type="text"
                  label={
                    <FormattedMessage
                      data-qatag="AddPCAddFamilyContainer.addFamilyContainer.nameText"
                      id="form.name"
                      data-qa-tag="form.name"
                      defaultMessage="Name"
                    />
                  }
                />
              </FormContainer>
              <AppButton
                ariaLabel="Email family member the download link"
                data-qatag="AddPCAddFamilyContainer.addFamilyContainer.callToActionButton"
                buttonStyle={AppButtonStyles.Green}
                isDisabled={hasErrors}
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  height: "56px",
                  letterSpacing: "0.18px",
                  margin: "24px 0",
                  maxWidth: "100%",
                  width: "400px",
                }}
                buttonType="submit"
                onClick={() => {}}
              >
                <FormattedMessage
                  id="buttonText"
                  data-qatag="buttonText"
                  defaultMessage="Email Family Member The Download Link"
                />
              </AppButton>
            </StyledForm>
          );
        }}
      </Formik>
    </SectionContainer>
  );
}
