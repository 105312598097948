import { Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Spinner } from "dsoneweb.designsystem";
import { AOProfileFlag, TriggerInfo } from "../types";
import SoftwareBox from "../SoftwareBox/SoftwareBox";
import { ReducerStatus } from "model/IReducerState";
import emptyProductivity from "assets/img/optimization/icn-no productivity.svg";
import emptyGaming from "assets/img/optimization/icn-no gaming.svg";
import emptyBrowsing from "assets/img/optimization/icn-no browsing.svg";
import styled from "@mui/styled-engine";

export type TabOptimizationProps = {
  /* Active tab number */
  tabNumber: number;
  /* Corresponding tab index */
  tabIndex: number;
  /* Software Optimization to display */
  software: TriggerInfo[];
  /* Status of the request */
  reducerStatus: ReducerStatus;
};

//#region component styles

const TabContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledSoftwareBox = styled(Box)`
  flex-basis: 100%;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: 50%;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: 33%;
  }
`;

const EmptyMessage = styled(Box)`
  max-width: 240px;
  margin: 16px auto 0;
  text-align: center;
  font: normal normal 500 1.125rem Roboto;
  color: #0c83bd;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: unset;
    font: normal normal 500 1.3125rem Roboto;
  }
`;

const DescriptionMessage = styled(Box)`
  max-width: 268px;
  text-align: center;
  font: normal normal normal 1rem Roboto;
  color: #535a6a;
  margin: 8px auto 0;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 436px;
  }
`;

//#endregion component styles

function TabOptimization({ tabNumber, software, reducerStatus, tabIndex }: TabOptimizationProps) {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();

  const getSoftwareLabel = (index: number) => {
    switch (index) {
      case 0:
        return formatMessage({
          id: "body.app.optimization.productivity",
          defaultMessage: "Productivity",
        });
      case 1:
        return formatMessage({
          id: "body.app.optimization.gaming",
          defaultMessage: "Gaming",
        });
      case 2:
        return formatMessage({
          id: "body.app.optimization.browsing",
          defaultMessage: "Browsing",
        });
      default:
        return "";
    }
  };

  const getEmptyImage = (index: number) => {
    switch (index) {
      case 0:
        return emptyProductivity;
      case 1:
        return emptyGaming;
      case 2:
        return emptyBrowsing;
      default:
        return "";
    }
  };

  return (
    <>
      <TabContainer
        data-qatag="tabContainer"
        display="flex"
        mb={6}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {software.length ? (
          software?.map((info: TriggerInfo, index: number) => (
            <StyledSoftwareBox
              data-qatag="softwareBox"
              textAlign="center"
              key={index}
            >
              <SoftwareBox
                data-qatag="softwareBox"
                title={info.JitTrigger.ManagedSoftware.Description}
                image={info.JitTrigger.ManagedSoftware.ResourceFiles?.[0]?.DownloadURI}
                metrics={[
                  {
                    title: formatMessage({
                      id: "body.app.optimization.install.location",
                      defaultMessage: "Install Location",
                    }),
                    metric: info.InstalledExes?.[0]?.FilePathName,
                  },
                  {
                    title: formatMessage({
                      id: "body.app.optimization.version",
                      defaultMessage: "Version",
                    }),
                    metric: info.InstalledExes?.[0]?.FileInfo.FileVersion,
                  },
                  {
                    title: formatMessage({
                      id: "body.app.optimization.publisher",
                      defaultMessage: "Publisher",
                    }),
                    metric:
                      info.InstalledExes?.[0]?.FileInfo.CompanyName ||
                      formatMessage({
                        id: "body.app.optimization.not.available",
                        defaultMessage: "N/A",
                      }),
                  },
                  // Optional metric if the profile flag is different a notSet or undefined
                  ...(AOProfileFlag[info.JitTrigger.ProfileFlag] &&
                  AOProfileFlag[0] !== AOProfileFlag[info.JitTrigger.ProfileFlag]
                    ? [
                        {
                          title: formatMessage({
                            id: "body.app.optimization.optimized.for",
                            defaultMessage: "Optimized for",
                          }),
                          metric: AOProfileFlag[info.JitTrigger.ProfileFlag],
                        },
                      ]
                    : []),
                ]}
              />
            </StyledSoftwareBox>
          ))
        ) : ReducerStatus[reducerStatus] !== ReducerStatus.Idle ? (
          <Box
            data-qatag="empty"
            mt={12}
            mb={12}
            textAlign={"center"}
            width="100%"
          >
            <img
              data-qatag="emptyImage"
              src={getEmptyImage(tabIndex)}
              alt="empty results"
            />
            <EmptyMessage data-qatag="emptyMessage">
              <FormattedMessage
                id="noCategory"
                data-qatag="noCategory"
                defaultMessage={"No {category} Software found on your PC."}
                values={{
                  category: <span data-qatag="emptyMessageBold">{getSoftwareLabel(tabIndex)}</span>,
                }}
              />
            </EmptyMessage>
            <DescriptionMessage data-qatag="DescriptionMessage">
              <FormattedMessage
                id="noCategory"
                data-qatag="noCategory"
                defaultMessage={
                  "There is currently no <b>{category} Software</b> data at this time. Check back periodically for updates."
                }
                values={{
                  category: getSoftwareLabel(tabIndex),
                  b: (msg) => <b data-qatag="boldMessage">{msg}</b>,
                }}
              />
            </DescriptionMessage>
          </Box>
        ) : (
          ""
        )}
      </TabContainer>
      {/* Loading modal */}
      <Spinner
        data-qatag="loadingModal"
        isActive={ReducerStatus[reducerStatus] === ReducerStatus.Idle}
        text="Loading..."
      />
    </>
  );
}

export default TabOptimization;
