import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import informationIcon from "assets/img/icn-resumed-subscription.svg";
import paymentIcon from "assets/img/icn-payment-updated.svg";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Layout } from "components/Layout/Layout";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

export type SuccessUpdateProps = {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  icon: "information" | "payment";
};

//#region component styles

const SuccessUpdateContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  flex-wrap: wrap;
`;

const SuccessUpdateIcon = styled("img")`
  width: 98px;
  height: 98px;
  flex-basis: 100%;
`;

const SuccessUpdateTitle = styled("div")`
  text-align: center;
  font: normal normal 500 1.3125rem Roboto;
  letter-spacing: 0px;
  color: #535353;
  margin-top: 24px;
  flex-basis: 100%;
  max-width: 176px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.5rem;
    max-width: unset;
  }
`;

const SuccessUpdateSubtitle = styled("div")`
  text-align: center;
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
  margin-top: 16px;
  flex-basis: 100%;
  max-width: 246px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 400px;
  }
`;

const SuccessUpdateSubtitleContainer = styled("div")`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
`;

const BackButton = styled(AppButton)`
  max-width: 280px;
  outline: none;
  height: 44px;
  margin-top: 24px;
  flex-basis: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 44px;
    max-width: 288px;
  }
  &:hover {
    opacity: 0.9;
  }
`;

//#endregion component styles

function SuccessUpdate({ title, subtitle, icon }: SuccessUpdateProps) {
  const navigate = useNavigate();
  const handleRedirect = useCallback(() => {
    navigate("/status");
  }, [navigate]);

  return (
    <Layout
      data-qatag="Layout"
      qatag="SuccessUpdate"
      useTemplating={true}
    >
      <Layout.Content data-qatag="Layout.Content">
        <SuccessUpdateContainer data-qatag="SuccessUpdateContainer">
          <SuccessUpdateIcon
            alt="success"
            src={icon === "information" ? informationIcon : paymentIcon}
            data-qatag="SuccessUpdateIcon"
          />
          <SuccessUpdateTitle data-qatag="SuccessUpdateTitle">{title}</SuccessUpdateTitle>
          <SuccessUpdateSubtitleContainer data-qatag="SuccessUpdateSubtitleContainer">
            <SuccessUpdateSubtitle data-qatag="SuccessUpdateTitle">{subtitle}</SuccessUpdateSubtitle>
          </SuccessUpdateSubtitleContainer>
          <BackButton
            ariaLabel="Back Button"
            data-qatag="BackButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={handleRedirect}
            buttonType="button"
          >
            <FormattedMessage
              data-qatag="success-update.button"
              id="success-update.button"
              defaultMessage="Back to My System"
            />
          </BackButton>
        </SuccessUpdateContainer>
      </Layout.Content>
    </Layout>
  );
}

export default SuccessUpdate;
