import { DriverDownloadStatus, IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import { DriverProgressBar } from "dsoneweb.designsystem";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import failedIcon from "assets/icon/icn-failed-state.svg";
import styled from "@mui/styled-engine";

const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 180px;

  div:first-child {
    width: 180px;
  }
`;
const ImageAndTexContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  color: #007dba;
  font-weight: 600;
  text-transform: none;

  p {
    font-weight: 600;
  }

  :hover {
    background-color: transparent;
  }
`;

export interface IDownloaderProps {
  driverUpdateModel: IDriverUpdateModel;
  onRetry: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Downloader: React.FC<IDownloaderProps> = ({ driverUpdateModel, onCancel, onRetry }) => {
  const downloadRecord = driverUpdateModel.downloadRecord;

  if (!downloadRecord) {
    // this *should* never happen, but I'm sure it will
    return (
      <Typography
        data-qatag="driver.downloader.unknown.text"
        id="driver.downloader.unknown.text"
        variant="body2"
        style={{ fontWeight: 600 }}
      >
        <FormattedMessage
          data-qatag="driver.downloader.unknown"
          id="driver.downloader.unknown"
          defaultMessage="Unknown"
        />
      </Typography>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Initializing) {
    return (
      <Box
        data-qatag="driver.downloader.initializing.status_container"
        id="driver.downloader.initializing.status_container"
      >
        <Typography
          data-qatag="driver.downloader.initializing.text"
          id="driver.downloader.initializing.text"
          variant="body2"
          color="textPrimary"
          style={{ fontWeight: 600 }}
        >
          <FormattedMessage
            data-qatag="driver.downloader.initializing"
            id="driver.downloader.initializing"
            defaultMessage="Initializing"
          />
        </Typography>
      </Box>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Cancelling) {
    return (
      <StyledBox
        data-qatag="driver.downloader.cancelling.status_container"
        id="driver.downloader.cancelling.status_container"
      >
        <Typography
          data-qatag="driver.downloader.cancelling.text"
          id="driver.downloader.cancelling.text"
          variant="body2"
          color="textPrimary"
          style={{ fontWeight: 600 }}
        >
          <FormattedMessage
            data-qatag="driver.downloader.cancelling"
            id="driver.downloader.cancelling"
            defaultMessage="Cancelling"
          />
        </Typography>
      </StyledBox>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Cancelled) {
    return (
      <StyledBox
        data-qatag="driver.downloader.cancelled.container"
        id="driver.downloader.cancelled.container"
      >
        <Typography
          data-qatag="driver.downloader.cancelled.text"
          id="driver.downloader.cancelled.text"
          variant="body2"
          color="textPrimary"
          style={{ fontWeight: 600 }}
        >
          <FormattedMessage
            data-qatag="driver.downloader.cancelled"
            id="driver.downloader.cancelled"
            defaultMessage="Download Cancelled"
          />
        </Typography>

        <StyledButton
          data-qatag="driver.downloader.cancelled.retryButton"
          id="driver.downloader.cancelled.retryButton"
          onClick={(e) => {
            e.preventDefault();
            onRetry(e);
          }}
        >
          <Typography
            data-qatag="driver.downloader.cancelled.retryButton.content.text"
            id="driver.downloader.cancelled.retryButton.content.text"
            variant="body2"
          >
            <FormattedMessage
              data-qatag="driver.downloader.cancelled.retryButton.content"
              id="driver.downloader.cancelled.retryButton.content"
              defaultMessage="Retry"
            />
          </Typography>
        </StyledButton>
      </StyledBox>
    );
  }

  if (downloadRecord.downloadStatus === DriverDownloadStatus.Error) {
    return (
      <StyledBox
        data-qatag="driver.downloader.error.container"
        id="driver.downloader.error.container"
      >
        <ImageAndTexContainer
          data-qatag="driver.downloader.error.description_container"
          id="driver.downloader.error.description_container"
        >
          <img
            data-qatag="driver.downloader.error.image"
            id="driver.downloader.error.image"
            src={failedIcon}
            alt="download failed"
          />
          <Typography
            data-qatag="driver.downloader.error.text"
            id="driver.downloader.error.text"
            variant="body2"
            color="textPrimary"
            style={{ fontWeight: 600 }}
          >
            <FormattedMessage
              data-qatag="driver.downloader.error"
              id="driver.downloader.error"
              defaultMessage="Download Error"
            />
          </Typography>
        </ImageAndTexContainer>
        <StyledButton
          data-qatag="driver.downloader.error.retryButton"
          id="driver.downloader.error.retryButton"
          onClick={(e) => {
            e.preventDefault();
            onRetry(e);
          }}
        >
          <Typography
            data-qatag="driver.downloader.error.retryButton.content.text"
            id="driver.downloader.error.retryButton.content.text"
            variant="body2"
            style={{ fontWeight: 600 }}
          >
            <FormattedMessage
              data-qatag="driver.downloader.error.retryButton.content"
              id="driver.downloader.error.retryButton.content"
              defaultMessage="Retry"
            />
          </Typography>
        </StyledButton>
      </StyledBox>
    );
  }

  return (
    <StyledBox
      data-qatag="driver.downloader.cancel.container"
      id="driver.downloader.cancel.container"
    >
      <Box
        data-qatag="driver.downloader.progressBar.container"
        id="driver.downloader.progressBar.container"
      >
        <DriverProgressBar
          data-qatag="driver.downloader.progressBar"
          current={downloadRecord.downloadPercentProgress}
          total={100}
          backerHeight={4}
          backerColor="#D7E1EA"
          fillHeight={4}
          fillColor="linear-gradient(90deg, #0CA15D 0%, #0FD179 100%)"
        />
        <Typography
          data-qatag="driver.downloader.progressBar.text"
          id="driver.downloader.progressBar.text"
          variant="body1"
          color="primary"
          align="center"
        >
          {`${downloadRecord.downloadPercentProgress}% (${downloadRecord.downloadCompletionETA})`}
        </Typography>
      </Box>
      <StyledButton
        data-qatag="driver.downloader.cancelButton"
        id="driver.downloader.cancelButton"
        onClick={(e) => {
          e.preventDefault();
          onCancel(e);
        }}
      >
        <Typography
          data-qatag="driver.downloader.cancelButton.content.text"
          id="driver.downloader.cancelButton.content.text"
          variant="body2"
          style={{ fontWeight: 600 }}
        >
          <FormattedMessage
            data-qatag="driver.downloader.cancelButton.content"
            id="driver.downloader.cancelButton.content"
            defaultMessage="Cancel"
          />
        </Typography>
      </StyledButton>
    </StyledBox>
  );
};
//#endregion Component
