import { ReducerStatus } from "model/IReducerState";
import { RootState } from "app/redux/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReceipt, selectReceipts, selectReceiptsStatus } from "./BillingHistorySlice";
import { BillingHistoryView } from "./BillingHistoryView";
import { fetchInvoice, getFetchStatus } from "./InvoiceFileSlice";

export function BillingHistoryContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReceipt());
  }, [dispatch]);

  /* Retrieve data from the store */
  /* Receipts or Invoices */
  const receipts = useSelector((state: RootState) => selectReceipts(state)) || [];

  /* callback to retrieve invoices */
  const handleFetchInvoice = (fileId: string, invoiceName: string) =>
    dispatch(
      fetchInvoice({
        fileId,
        invoiceName,
      })
    );

  /* Receipts Request Status */
  const requestInvoicesStatus = useSelector((state: RootState) => selectReceiptsStatus(state));

  /* Receipt Request Status */
  const requestInvoiceStatus = useSelector((state: RootState) => getFetchStatus(state));

  // Is loading the invoice or the historical invoice?
  const isLoading =
    ReducerStatus[requestInvoicesStatus] === ReducerStatus.Loading ||
    ReducerStatus[requestInvoiceStatus] === ReducerStatus.Loading;

  /* Limit the number of receipts to 13 */
  const limitedReceipts = receipts.slice(0, 13);

  return (
    <BillingHistoryView
      data-qatag="BillingHistoryView"
      receipts={limitedReceipts}
      fetchInvoice={handleFetchInvoice}
      isLoading={isLoading}
    />
  );
}
