import * as amplitude from "@amplitude/analytics-browser";
import { styled } from "@mui/material";
import { Spinner } from "dsoneweb.designsystem";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import semver from "semver";
import { useMount } from "ui.common";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { GetIsLicenseExpired } from "api/LicenseApi/LicenseApiHelpers";
import {
  eConnectionStatus,
  selectHasAgentScanStartTime,
  selectHasMachineIntelligence,
  selectSignalRHubConnectionStatus,
  selectIsForceDisconnected,
  selectAgentInfo,
  selectLatestVersion,
  selectGetLatestVersionStatus,
  selectIsSignalRConnectionLocalAgent,
  getLatestAgentVersion,
  doClientUpdate,
  selectIsAgentScanning,
  selectMachineIntelligence,
} from "app/Agent/AgentSlice";
import { useAppDispatch } from "app/redux/store";
import { TransparentDisconnect } from "features/DisconnectAlert/TransparentDisconnect";
import { fetchDriverState } from "features/driverInstall/Thunks";
import { ExpiredLicenseAlertContainer } from "features/ExpiredLicenseAlert/ExpiredLicenseAlertContainer";
import { selectFamilyMemberSelected } from "features/Family/FamilyDetail/FamilyDetailSlice";
import { LoginFromPCModalContainer } from "features/LoginFromPCModal/LoginFromPCModalContainer";
import { updateMachineIntelligence } from "features/MachinePicker/MachinePickerSlice";
import { NavbarContainer } from "features/nav/NavBarContainer";
import { fetchHardwareScan } from "features/scan/ScanSlice";
import UpdateRequiredAlert from "features/UpdateRequiredAlert/UpdateRequiredAlert";
import UpdatingAlert from "features/UpdatingAlert/UpdatingAlert";
import { ReducerStatus } from "model/IReducerState";
import { ILicenseData } from "model/license/ILicenseData";
import { IntelligenceTypes, MatchTypes } from "model/messaging/payloads/IUpdateMachineIntelligencePayload";
import { selectCurrentUser, selectHasApplicationError, selectIsAdmin } from "session/SessionSlice";
import { getIsWindows } from "utils/browserUtils";

import AppRouter from "./AppRouter";
import { ErrorFallback } from "./ErrorFallback";
import { ErrorHost } from "./ErrorHost";
import { RemoteConnectionBar } from "./RemoteConnectionBar";

declare const amplitudeApiKey: string;

// #region styled-components
const NavSlot = styled("div")`
  background: #fff;
  box-shadow: 0px 4px 12px #0000001f;
`;

const ContentSlot = styled("div")`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
// #endregion styled-components

const App: React.FC = () => {
  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    isError: licenseDataError,
  } = useGetOrganizationLicensesQuery();

  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const hasAgentScanStartTime = useSelector(selectHasAgentScanStartTime);
  const isAgentScanning = useSelector(selectIsAgentScanning);
  const redirectToScan = !hasAgentScanStartTime || isAgentScanning;
  const connStatus = useSelector(selectSignalRHubConnectionStatus);
  const hasApplicationError = useSelector(selectHasApplicationError);
  const isAdmin = useSelector(selectIsAdmin);
  const hasMachineIntelligence = useSelector(selectHasMachineIntelligence);
  const machineIntelligence = useSelector(selectMachineIntelligence);
  const agentInfo = useSelector(selectAgentInfo);
  const isForceDisconnected = useSelector(selectIsForceDisconnected);
  const latestAgentVersion = useSelector(selectLatestVersion);
  const latestAgentVersionStatus = useSelector(selectGetLatestVersionStatus);
  const isAgentLocal = useSelector(selectIsSignalRConnectionLocalAgent);
  const familyMemberSelected = useSelector(selectFamilyMemberSelected);
  const currentUser = useSelector(selectCurrentUser);
  const isLicenseExpired = GetIsLicenseExpired(licensesData);

  useMount(() => {
    amplitude.init(amplitudeApiKey);
  });

  const isSignalRConnected = connStatus === eConnectionStatus.Connected;
  const isViewingOwnSystem = isAgentLocal as boolean;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isExpiredLicenseAlertOpen, setIsExpiredLicenseAlertOpen] = useState(isLicenseExpired);

  useEffect(() => {
    if (latestAgentVersionStatus === ReducerStatus.Idle || latestAgentVersionStatus === ReducerStatus.Failed) {
      appDispatch(getLatestAgentVersion());
    }
  }, [appDispatch, latestAgentVersion, latestAgentVersionStatus]);

  useEffect(() => {
    if (!isLicenseExpired && isSignalRConnected && isAgentLocal) {
      appDispatch(fetchHardwareScan());
      appDispatch(fetchDriverState());
    }
  }, [appDispatch, isAgentLocal, isLicenseExpired, isSignalRConnected]);

  //if no machine intelligence, set to universal
  useEffect(() => {
    if (hasMachineIntelligence) return;

    appDispatch(
      updateMachineIntelligence({
        intelligenceType: IntelligenceTypes.None,
        manufacturerID: 0,
        manufacturer: "",
        familyID: 0,
        family: "",
        seriesIsLaptop: false,
        modelID: 0,
        model: "",
        modelIsLaptop: false,
        matchType: MatchTypes.None,
      })
    );
  }, [hasMachineIntelligence]);

  if (hasApplicationError || licenseDataError) {
    return (
      <ErrorHost
        data-qatag="errorhost"
        content={
          <ErrorFallback
            data-qatag="errorfallback.component"
            resetError={() => navigate(0)}
          />
        }
      />
    );
  }

  //empty nav bar check
  //there has to be a better way of doing this, but as far as I can tell we can't
  //get a list of the routes from the router here to do the comparison that way,
  //so we're just going to have magic strings for now
  const blankNavBarPaths = ["/scan", "/machinepicker"];
  const hideAll = blankNavBarPaths.includes(location.pathname);
  const hideNavBarPaths = ["/sorrytoseeyougo"];
  const hideNavBar = hideNavBarPaths.includes(location.pathname);

  //semantic version library requires a specific format that is slightly different
  //from the ones we're generating: the final "." in ours must be converted to a "-"
  //this approach was chosen because massaging the inputs to let them handle version
  //comparison is easier than doing the comparison ourselves
  const currentVersion = agentInfo?.AppVersion?.replace(/\.([^.]*)$/, "-$1") ?? "";
  const semanticLatest = semver.valid(latestAgentVersion.replace(/\.([^.]*)$/, "-$1")) ?? "";
  const semanticVersion = semver.valid(currentVersion) ?? "";
  const hasVersionInfo = semanticVersion !== "" && semanticLatest !== "";
  const needsUpdate = hasVersionInfo && semver.lt(semanticVersion, semanticLatest);

  const isLoading = isLicensesDataLoading;

  if (isLoading) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive
        headerText={formatMessage({
          id: `AgentCommunicationLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `AgentCommunicationLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    );
  } else {
    return (
      <React.Fragment>
        {!hideNavBar && (
          <NavSlot
            data-qatag="navSlot"
            id="navSlot"
          >
            <NavbarContainer
              data-qatag="appNavBar"
              isAdmin={isAdmin}
              hideAll={hideAll}
              isSignalRConnected={isSignalRConnected}
              licenses={licensesData as ILicenseData}
            />
            <RemoteConnectionBar
              data-qatag="RemoteConnectionBar"
              userName={familyMemberSelected?.fullName || `${currentUser?.firstName} ${currentUser?.lastName}`}
              machineName={familyMemberSelected?.machineDisplayName || machineIntelligence.MachineDisplayName}
              isRemoteConnection={!isViewingOwnSystem}
              isSignalRConnected={isSignalRConnected}
            />
          </NavSlot>
        )}
        {isExpiredLicenseAlertOpen && (
          <ExpiredLicenseAlertContainer
            data-qatag="expiredAlert"
            isAdmin={isAdmin}
            closeHandler={() => setIsExpiredLicenseAlertOpen(false)}
            onResumeSubscription={() => navigate("/myaccount")}
          />
        )}
        <ContentSlot
          data-qatag="contentSlot"
          id="contentSlot"
        >
          <AppRouter
            data-qatag="AppRouter"
            redirectToScan={redirectToScan}
            hasSignalRConnection={isSignalRConnected}
            hasMachineIntelligence={hasMachineIntelligence}
            licenses={licensesData as ILicenseData}
          />
        </ContentSlot>
        <LoginFromPCModalContainer
          data-qatag="loginFromPCModal"
          isAdmin={isAdmin}
          isWindows={getIsWindows()}
        />
        {/* This will be present when the app is disconnected via another connection or timeout */}
        {isForceDisconnected && <TransparentDisconnect data-qatag="transparent-disconnect" />}
        {/* This alert will be present when the agent version is out of date */}
        <UpdateRequiredAlert
          data-qatag="UpdateRequiredAlert"
          isOpen={needsUpdate && !isUpdating}
          closeHandler={() => {
            appDispatch(doClientUpdate());
            setIsUpdating(true);
          }}
        />
        {/* This alert will be present when the agent version is updating */}
        <UpdatingAlert
          data-qatag="updatingAlert"
          isOpen={isUpdating}
        />
      </React.Fragment>
    );
  }
};

export default App;
