/*eslint max-len: ["error", { "code": 160 }]*/
import { useIntl, FormattedMessage } from "react-intl";
import { Typography, Box } from "@mui/material";
import { IRingGraphProps, Panel, Spinner } from "dsoneweb.designsystem";
import gears from "assets/img/threeGears.svg";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { RingGraph } from "dsoneweb.designsystem";
import { OnOffSwitch } from "dsoneweb.designsystem";
import { useState } from "react";
import { CleaningUpDialog } from "./CleaningUpDialog";
import { AnalysisCompleteDialog } from "./AnalysisCompleteDialog";
import { FileListDialog } from "./FileListDialog";
import { CleanupCompleteDialog } from "./CleanupCompleteDialog";
import { LockedFilesDialog } from "./LockedFilesDialog";
import { useSelector } from "react-redux";
import { IThunkStatus, ReducerStatus } from "model/IReducerState";
import { fetchFileCleanupScan, IFileCleanupOptions } from "./FileCleaningSlice";
import { IFileCleanupScan } from "model/scan/IFileCleanupScan";
import {
  ICleanUpFileMessage,
  IDirectoryCleanUpScanDetailsMessage,
  IFileCleanUpRemovalResultsMessage,
} from "model/messaging/messages/FileCleanupMessages";
import { ILast30DaysFileCleanupReport } from "./ILast30DaysFileCleanupReport";
import { humanFileSize } from "utils/formatFileSize";
import backgroundHeader from "assets/img/banner-blue-master.svg";
import styled from "@mui/styled-engine";

//#region styles
const Container = styled("div")`
  display: relative;
  width: 100%;
  height: 100%;
`;

const Background = styled("div")`
  position: absolute;
  x: 0;
  y: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#efefef, #ffffff);
  z-index: -1;
`;

const BackgroundImage = styled("div")`
  width: 100%;
  height: 120px;
  background-image: url(${backgroundHeader});
  background-size: cover;
`;

const Content = styled("div")`
  max-width: 1075px;
  margin: 34px auto 0;

  ${(props) => props.theme.breakpoints.down("xl")} {
    max-width: 960px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    max-width: 720px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 100%;
  }
`;

const Header = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 30px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    padding: 20px;
  }
`;

const HeaderTitle = styled(Typography)`
  color: #007dba;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.188rem;

  ${(props) => props.theme.breakpoints.down("lg")} {
    font-size: 1.313rem;
  }
`;

const HeaderTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.5rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    ${HeaderTitle} {
      display: none;
    }

    text-align: center;
    margin-top: 10px;
  }
`;

const GearsImageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;

  ${HeaderTitle} {
    display: none;

    ${(props) => props.theme.breakpoints.down("md")} {
      display: inline;
      vertical-align: top;
    }
  }
`;

const GearsImage = styled("img")`
  width: 120px;
  height: 88px;
  margin-right: 30px;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 48px;
    height: 35px;
    margin-right: 10px;
  }
`;

const SizeHighlight = styled("span")`
  font-weight: bold;
  color: #dd4a21;
`;

const BoldText = styled("span")`
  font-weight: bold;
`;

const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 210px;
    margin-left: 30px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    min-width: 320px;
    margin-left: 30px;
  }
`;

const RescanButton = styled(AppButton)`
  width: 210px;
  height: 44px;
  font-size: 1rem;
  line-height: 100%;
  margin-top: 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 254px;
  }
`;

const BackButton = styled(AppButton)`
  width: 210px;
  height: 44px;
  font-size: 1rem;
  line-height: 100%;
  margin-top: 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    width: 254px;
  }
`;

const CleanupButton = styled(AppButton)`
  width: 210px;
  height: 44px;
  padding: 0;
  margin-top: 15px;
  font-size: 1rem;
  line-height: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    margin-top: 20px;
    width: 254px;
  }
`;

const Body = styled(Panel)`
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const GraphAndTextContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px 45px 40px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
    padding: 50px 27px 40px;
  }
`;

const BodyGraphTextSpacer = styled("div")`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25px;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-basis: 0px;
  }
`;

const BodyText = styled("div")`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(242, 242, 242, 0.38);
  border-radius: 12px;
  padding: 20px 15px;
  text-align: center;

  ${(props) => props.theme.breakpoints.down("md")} {
    text-align: center;
    margin-top: 43px;
  }
`;

const BodySummary = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  color: #535a6a;
  max-width: 380px;
  margin-bottom: 27px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-top: 57px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 0;
  }
`;

const ControlsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 70px;
`;

const ControlsTitleBox = styled(Box)`
  flex-basis: 100%;
  margin-bottom: 25px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-bottom: 10px;
  }
`;

const ControlsTitle = styled(Typography)`
  font-weight: bold;
`;

const ControlContainer = styled("div")`
  flex-basis: 33%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e6e6e6;

    &:last-of-type {
      border-bottom: none;
    }
  }
`;

const ControlLabel = styled(Typography)`
  color: #535a6a;
  margin-right: 10px;
  font-weight: bold;

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-bottom: 15px;
  }
`;

const ControlSwitch = styled(OnOffSwitch)`
  span {
    font-size: 0.875rem;
  }
`;
//#endregion

export interface IFileCleaningProps {
  scan: IFileCleanupScan | null;
  results: IFileCleanUpRemovalResultsMessage | null;
  details: IDirectoryCleanUpScanDetailsMessage | null;
  options: IFileCleanupOptions | null;
  last30DaysReport: ILast30DaysFileCleanupReport | null;
  isDoingCleanup: boolean;
  isDoingScan: boolean;
  cleanupCompleted: boolean;
  openRecycleBinHandler: () => void;
  doScanHandler: () => void;
  doCleanupHandler: (cleanupSize: number) => void;
  updateOptionsHandler: (options: IFileCleanupOptions) => void;
  backButtonHandler: () => void;
  thunkStatus: IThunkStatus;
}

export const FileCleaning: React.FC<IFileCleaningProps> = ({
  scan,
  results,
  details,
  options,
  last30DaysReport,
  isDoingCleanup,
  isDoingScan,
  cleanupCompleted,
  openRecycleBinHandler,
  doScanHandler,
  doCleanupHandler,
  updateOptionsHandler,
  backButtonHandler,
  thunkStatus,
}: IFileCleaningProps) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.view";

  //#region state declarations
  const [showAnalysisCompleteDialog, setShowAnalysisCompleteDialog] = useState<boolean>(false);
  const [showFileListDialog, setShowFileListDialog] = useState<boolean>(false);
  const [fileListTitle, setFileListTitle] = useState<string>("File List");
  const [fileListFiles, setFileListFiles] = useState<ICleanUpFileMessage[]>([]);
  const [hideCleaningDialog, setHideCleaningDialog] = useState<boolean>(false); //user has manually closed the dialog
  const showCleaningDialog = isDoingCleanup && !hideCleaningDialog;
  const [hideScanningDialog, setHideScanningDialog] = useState<boolean>(false); //user has manually closed the dialog
  const showScanningDialog = isDoingScan && !hideScanningDialog;
  const [showLockedFilesDialog, setShowLockedFilesDialog] = useState<boolean>(false);
  const [userWantsResults, setUserWantsResults] = useState<boolean>(false);
  //#endregion

  //#region calculated data
  const shouldDisplayFileCleaningButton = scan != null && !cleanupCompleted;
  const shouldDisplayRescanButton = scan == null && !cleanupCompleted;
  const shouldDisplayBackButton = cleanupCompleted;
  const showCleanupCompleteDialog = results != null && !showLockedFilesDialog && userWantsResults;
  const internetEnabled = options?.internetFilesEnabled ?? false;
  const tempFilesEnabled = options?.tempFilesEnabled ?? false;
  const recyclingEnabled = options?.recyclingEnabled ?? false;
  const graphValues = useSelector(() => {
    const values = {
      internet:
        scan != null
          ? internetEnabled
            ? scan.TemporaryInternetFiles.TotalFileSize +
              scan.GoogleFiles.TotalFileSize +
              scan.EdgeFiles.TotalFileSize +
              scan.DownloadedFiles.TotalFileSize
            : 0
          : 0,
      temp:
        scan != null
          ? tempFilesEnabled
            ? scan.WindowsTempFiles.TotalFileSize +
              scan.ApplicationTempFiles.TotalFileSize +
              scan.Thumbnails.TotalFileSize
            : 0
          : 0,
      recycling: scan != null ? (recyclingEnabled ? scan.RecycleBin.TotalFileSize : 0) : 0,
      total: 0,
    };
    values.total = values.internet + values.temp + values.recycling;
    return values;
  });
  const ringGraphProps: IRingGraphProps = {
    radius: 100,
    strokeSize: 10,
    spaceSizePercent: 3,
    minimumSizePercent: 3,
    displayTotal: true,
    totalLabelText: formatMessage({
      id: `${prefix}.totalSpace`,
      defaultMessage: "Total Space",
    }),
    totalValueText: humanFileSize(graphValues.total),
    entries: [
      {
        labelText: formatMessage({
          id: `${prefix}.internet`,
          defaultMessage: "Internet Files",
        }),
        value: graphValues.internet,
        valueText: humanFileSize(graphValues.internet),
        isEnabled: scan ? internetEnabled : true,
      },
      {
        labelText: formatMessage({
          id: `${prefix}.tempFiles`,
          defaultMessage: "Windows Temp Files",
        }),
        value: graphValues.temp,
        valueText: humanFileSize(graphValues.temp),
        isEnabled: scan ? tempFilesEnabled : true,
      },
      {
        labelText: formatMessage({
          id: `${prefix}.recycling`,
          defaultMessage: "Recycle Bin",
        }),
        value: graphValues.recycling,
        valueText: humanFileSize(graphValues.recycling),
        isEnabled: scan ? recyclingEnabled : true,
      },
    ],
  };
  const lockedFiles =
    results?.DirectoryCleanUp?.FilesWithErrors?.map((x) => {
      const info = {
        name: x.FileName ?? "",
        size: humanFileSize(x.Length),
        sizeNum: x.Length,
      };
      return info;
    }) ?? [];
  const lockedSize = humanFileSize(lockedFiles.reduce((acc, cur) => acc + cur.sizeNum, 0));
  const spaceFreedLastCleanup = humanFileSize(results?.TotalCleanUpSize ?? 0);
  const analysisValues = {
    internet: {
      size:
        scan && internetEnabled
          ? scan.TemporaryInternetFiles.TotalFileSize +
            scan.GoogleFiles.TotalFileSize +
            scan.EdgeFiles.TotalFileSize +
            scan.DownloadedFiles.TotalFileSize
          : 0,
      files:
        scan && internetEnabled
          ? scan.TemporaryInternetFiles.NumberCleanUpFiles +
            scan.GoogleFiles.NumberCleanUpFiles +
            scan.EdgeFiles.NumberCleanUpFiles +
            scan.DownloadedFiles.NumberCleanUpFiles
          : 0,
    },
    temp: {
      size:
        scan && tempFilesEnabled
          ? scan.WindowsTempFiles.TotalFileSize +
            scan.ApplicationTempFiles.TotalFileSize +
            scan.Thumbnails.TotalFileSize
          : 0,
      files:
        scan && tempFilesEnabled
          ? scan.WindowsTempFiles.NumberCleanUpFiles +
            scan.ApplicationTempFiles.NumberCleanUpFiles +
            scan.Thumbnails.NumberCleanUpFiles
          : 0,
    },
    recycling: {
      size: scan && recyclingEnabled ? scan.RecycleBin.TotalFileSize : 0,
      files: scan && recyclingEnabled ? scan.RecycleBin.NumberCleanUpFiles : 0,
    },
  };
  const analysisCategories = [
    {
      name: formatMessage({
        id: `${prefix}.categories.browserCache`,
        defaultMessage: "Internet Files",
      }),
      size: humanFileSize(analysisValues.internet.size),
      files: analysisValues.internet.files,
      onClick: () => {
        setShowAnalysisCompleteDialog(false);
        setFileListTitle(
          formatMessage({
            id: `${prefix}.categories.browserCache`,
            defaultMessage: "Internet Files",
          })
        );
        const files =
          internetEnabled && details
            ? [
                ...details.TempInternetFilesCleanUpFiles,
                ...details.DownloadsCleanUpFiles,
                ...details.GoogleCleanUpFiles,
                ...details.EdgeCleanUpFiles,
              ]
            : [];
        setFileListFiles(files);
        setShowFileListDialog(true);
      },
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.tempFiles`,
        defaultMessage: "Temporary Files",
      }),
      size: humanFileSize(analysisValues.temp.size),
      files: analysisValues.temp.files,
      onClick: () => {
        setShowAnalysisCompleteDialog(false);
        setFileListTitle(
          formatMessage({
            id: `${prefix}.categories.tempFiles`,
            defaultMessage: "Temporary Files",
          })
        );
        const files =
          tempFilesEnabled && details
            ? [
                ...details.WindowsTempCleanUpFiles,
                ...details.ApplicationTempCleanUpFiles,
                ...details.CachedThumbnailCleanUpFiles,
              ]
            : [];
        setFileListFiles(files);
        setShowFileListDialog(true);
      },
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.recycling`,
        defaultMessage: "Recycle Bin",
      }),
      size: humanFileSize(analysisValues.recycling.size),
      files: analysisValues.recycling.files,
      onClick: openRecycleBinHandler,
    },
  ];
  const cleanupCompleteValues = {
    internet:
      (results?.DirectoryCleanUp?.TempInternetFilesCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.GoogleCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.DownloadsCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.EdgeCleanUpResults?.TotalCleanUpSize ?? 0),
    temp:
      (results?.DirectoryCleanUp?.WindowsTempCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.ApplicationTempCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.DirectoryCleanUp?.CachedThumbnailCleanUpResults?.TotalCleanUpSize ?? 0) +
      (results?.HibernationFileSize ?? 0),
    recycling: results?.RecycleBinSizeSize ?? 0,
  };
  const cleanupCompleteCategories = [
    {
      name: formatMessage({
        id: `${prefix}.categories.browserCache`,
        defaultMessage: "Internet Files",
      }),
      size: humanFileSize(cleanupCompleteValues.internet),
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.tempFiles`,
        defaultMessage: "Temporary Files",
      }),
      size: humanFileSize(cleanupCompleteValues.temp),
    },
    {
      name: formatMessage({
        id: `${prefix}.categories.recycling`,
        defaultMessage: "Recycle Bin",
      }),
      size: humanFileSize(cleanupCompleteValues.recycling),
    },
  ];
  //#endregion

  //#region action handlers
  const cleanUpNowHandler = async () => {
    setShowAnalysisCompleteDialog(false);
    setUserWantsResults(true);
    setHideCleaningDialog(false);
    doCleanupHandler(graphValues.total);
  };

  const onRescanClick = async () => {
    doScanHandler();
    setHideScanningDialog(false);
    updateOptionsHandler({
      internetFilesEnabled: true,
      tempFilesEnabled: true,
      recyclingEnabled: true,
    });
  };
  const onCleanupClick = async () => {
    setShowAnalysisCompleteDialog(true);
  };
  const viewLockedFilesHandler = () => {
    setShowLockedFilesDialog(true);
  };

  const onInternetSwitchClick = (e: React.MouseEvent<HTMLElement>, isOn: boolean) => {
    updateOptionsHandler({
      internetFilesEnabled: isOn,
      tempFilesEnabled: tempFilesEnabled,
      recyclingEnabled: recyclingEnabled,
    });
  };
  const onTempFilesSwitchClick = (e: React.MouseEvent<HTMLElement>, isOn: boolean) => {
    updateOptionsHandler({
      internetFilesEnabled: internetEnabled,
      tempFilesEnabled: isOn,
      recyclingEnabled: recyclingEnabled,
    });
  };
  const onRecyclingSwitchClick = (e: React.MouseEvent<HTMLElement>, isOn: boolean) => {
    updateOptionsHandler({
      internetFilesEnabled: internetEnabled,
      tempFilesEnabled: tempFilesEnabled,
      recyclingEnabled: isOn,
    });
  };
  //#endregion

  return (
    <Container data-qatag="fileCleaning">
      <Background data-qatag="fileCleaning.Background">
        <BackgroundImage data-qatag="fileCleaning.BackgroundImage" />
      </Background>
      <Content data-qatag="fileCleaning.Content">
        <Header data-qatag="fileCleaningHeader">
          <GearsImageContainer
            data-qatag="gearsImageContainer"
            display="flex"
            alignItems="center"
          >
            <GearsImage
              data-qatag="gearsImage"
              src={gears}
              alt="gears image"
            />
            <HeaderTitle
              data-qatag="headerTitle"
              variant="h4"
            >
              {formatMessage({
                id: `${prefix}.header.title`,
                defaultMessage: "System Cleanup",
              })}
            </HeaderTitle>
          </GearsImageContainer>
          <HeaderTextContainer data-qatag="headerText">
            <HeaderTitle
              data-qatag="headerTitle"
              variant="h4"
            >
              {formatMessage({
                id: `${prefix}.header.title`,
                defaultMessage: "System Cleanup",
              })}
            </HeaderTitle>
            <span data-qatag="span">
              <FormattedMessage
                data-qatag="fileCleaningHeaderDescription"
                id={`${prefix}.header.descriptionWithScan`}
                defaultMessage="We can free up to {size} of hard drive space."
                values={{
                  size: (
                    <SizeHighlight data-qatag="fileCleaningHeaderSizeSpan">
                      {humanFileSize(graphValues.total, true)}
                    </SizeHighlight>
                  ),
                }}
              />
            </span>
          </HeaderTextContainer>
          <ButtonContainer data-qatag="buttonContainer">
            {shouldDisplayFileCleaningButton ? (
              <>
                <CleanupButton
                  ariaLabel="clean up now"
                  buttonType="button"
                  data-qatag="cleanupButton"
                  buttonStyle={AppButtonStyles.Green}
                  onClick={onCleanupClick}
                >
                  <Typography
                    data-qatag="fileCleaningCleanupButtonTypography"
                    variant="body2"
                  >
                    {formatMessage({
                      id: `${prefix}.header.cleanupButtonText`,
                      defaultMessage: "Clean Up Now",
                    })}
                  </Typography>
                </CleanupButton>
              </>
            ) : null}
            {shouldDisplayRescanButton ? (
              <RescanButton
                ariaLabel="rescan"
                buttonType="button"
                data-qatag="rescanButton"
                buttonStyle={AppButtonStyles.Green}
                onClick={onRescanClick}
              >
                <Typography
                  data-qatag="fileCleaningRescanButtonTypography"
                  variant="body2"
                >
                  {formatMessage({
                    id: `${prefix}.header.rescanButtonText`,
                    defaultMessage: "Rescan",
                  })}
                </Typography>
              </RescanButton>
            ) : null}
            {shouldDisplayBackButton ? (
              <BackButton
                ariaLabel="back"
                buttonType="button"
                data-qatag="backButton"
                buttonStyle={AppButtonStyles.Green}
                onClick={backButtonHandler}
              >
                <Typography
                  data-qatag="fileCleaningBackButtonTypography"
                  variant="body2"
                >
                  {formatMessage({
                    id: `${prefix}.header.backButtonText`,
                    defaultMessage: "Back to My System",
                  })}
                </Typography>
              </BackButton>
            ) : null}
          </ButtonContainer>
        </Header>
        <Body data-qatag="fileCleaningBody">
          <GraphAndTextContainer data-qatag="graphAndText">
            <RingGraph
              data-qatag="RingGraph"
              {...ringGraphProps}
            />
            <BodyGraphTextSpacer data-qatag="bodyGraphTextSpacer" />
            <BodyText data-qatag="fileCleaningBodyText">
              <BodySummary data-qatag="bodySummary">
                <Typography
                  data-qatag="unBold"
                  variant="h6"
                >
                  <FormattedMessage
                    data-qatag="fileCleaningSummaryScanMessage"
                    id={`${prefix}.summaryScan`}
                    defaultMessage="{scanSize} of hard drive space can be {freed} up from {yourComputer} if you clean up now."
                    values={{
                      scanSize: (
                        <SizeHighlight data-qatag="fileCleaningScanSizeSpan">
                          {humanFileSize(graphValues.total, true)}
                        </SizeHighlight>
                      ),
                      freed: (
                        <BoldText data-qatag="bold">
                          <FormattedMessage
                            data-qatag="fileCleaningSpaceFreedMessage"
                            id={`${prefix}.freed`}
                            defaultMessage=" FREED"
                          />
                        </BoldText>
                      ),
                      yourComputer: (
                        <BoldText data-qatag="bold">
                          <FormattedMessage
                            data-qatag="fileCleaningYourPcMessage"
                            id={`${prefix}.your.pc`}
                            defaultMessage=" your PC"
                          />
                        </BoldText>
                      ),
                    }}
                  />
                </Typography>
              </BodySummary>
              {scan && (
                <Typography
                  data-qatag="fileCleaningScanDescriptionBox"
                  variant="body2"
                >
                  {formatMessage({
                    id: `${prefix}.description`,
                    defaultMessage: "Clean up unused files to free up storage space",
                  })}
                </Typography>
              )}
            </BodyText>
          </GraphAndTextContainer>
          {options ? (
            <ControlsContainer data-qatag="controls">
              <ControlsTitleBox data-qatag="fileCleaningControlsTitleBox">
                <ControlsTitle
                  data-qatag="fileCleaningControlsTitleTypography"
                  variant="h6"
                >
                  {formatMessage({
                    id: `${prefix}.controls.title`,
                    defaultMessage: "Cleanup Location Options",
                  })}
                </ControlsTitle>
              </ControlsTitleBox>
              <ControlContainer data-qatag="controlContainer">
                <ControlLabel
                  data-qatag="controlLabel"
                  variant="h6"
                >
                  {formatMessage({
                    id: `${prefix}.controls.internet`,
                    defaultMessage: "Internet Files",
                  })}
                </ControlLabel>
                <ControlSwitch
                  data-qatag="controlSwitch"
                  isOnInitially={scan ? internetEnabled : true}
                  onClick={onInternetSwitchClick}
                  isDisabled={!scan}
                />
              </ControlContainer>
              <ControlContainer data-qatag="tempFilesSwitchContainer">
                <ControlLabel
                  data-qatag="tempFilesSwitchLabel"
                  variant="h6"
                >
                  {formatMessage({
                    id: `${prefix}.controls.tempFiles`,
                    defaultMessage: "Windows Temp Files",
                  })}
                </ControlLabel>
                <ControlSwitch
                  data-qatag="controlSwitch"
                  isOnInitially={scan ? tempFilesEnabled : true}
                  onClick={onTempFilesSwitchClick}
                  isDisabled={!scan}
                />
              </ControlContainer>
              <ControlContainer data-qatag="recycleBinSwitchContainer">
                <ControlLabel
                  data-qatag="recycleBinSwitchLabel"
                  variant="h6"
                >
                  {formatMessage({
                    id: `${prefix}.controls.recycling`,
                    defaultMessage: "Recycle Bin",
                  })}
                </ControlLabel>
                <ControlSwitch
                  data-qatag="controlSwitch"
                  isOnInitially={scan ? recyclingEnabled : true}
                  onClick={onRecyclingSwitchClick}
                  isDisabled={!scan}
                />
              </ControlContainer>
            </ControlsContainer>
          ) : null}
        </Body>
      </Content>
      <AnalysisCompleteDialog
        data-qatag="fileCleaningAnalysisCompleteDialog"
        isOpen={showAnalysisCompleteDialog}
        closeDialogHandler={() => setShowAnalysisCompleteDialog(false)}
        cleanHandler={cleanUpNowHandler}
        categories={analysisCategories}
      />
      <FileListDialog
        data-qatag="fileCleaningFileListDialog"
        isOpen={showFileListDialog}
        title={fileListTitle}
        closeDialogHandler={() => {
          setShowFileListDialog(false);
          setShowAnalysisCompleteDialog(true);
        }}
        files={fileListFiles}
      />
      <CleaningUpDialog
        data-qatag="fileCleaningCleaningDialog"
        isOpen={showCleaningDialog}
        closeDialogHandler={() => setHideCleaningDialog(true)}
      />
      <CleanupCompleteDialog
        data-qatag="fileCleaningCleanupCompleteDialog"
        isOpen={showCleanupCompleteDialog}
        closeDialogHandler={() => {
          //setShouldClearResults(true);
          setUserWantsResults(false);
        }}
        viewLockedFilesHandler={viewLockedFilesHandler}
        spaceFreed={spaceFreedLastCleanup}
        categories={cleanupCompleteCategories}
        hasLockedFiles={lockedFiles.length > 0}
      />
      <LockedFilesDialog
        data-qatag="fileCleaningLockedFilesDialog"
        isOpen={showLockedFilesDialog}
        closeDialogHandler={() => setShowLockedFilesDialog(false)}
        files={lockedFiles}
        totalSize={lockedSize}
      />
      <Spinner
        data-qatag="scanningDialog"
        text="Scanning..."
        isActive={showScanningDialog}
      />
      <Spinner
        data-qatag="loadingDialog"
        text="Loading..."
        isActive={thunkStatus[fetchFileCleanupScan.typePrefix] === ReducerStatus.Loading}
      />
    </Container>
  );
};
