import { useCallback } from "react";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Box } from "@mui/material";
import icnPremium from "assets/img/icn-resumed-subscription.svg";
import { FormattedMessage } from "react-intl";
import { Layout } from "components/Layout/Layout";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

export type ResumedSubscriptionProps = {
  name?: string;
};

//#region component styles

const ResumedSubscriptionContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PremiumIllustrationContainer = styled(Box)`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin-top: 14px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 48px;
  }
`;

const PremiumIllustration = styled("img")`
  max-width: 70px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 98px;
  }
`;

const Title = styled(Box)`
  margin-top: 20px;
  text-align: center;
  flex-basis: 100%;
  font: normal normal 500 1.125rem Roboto;
  color: #555555;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 22px;
    font: normal normal 500 1.5rem Roboto;
  }
`;

const LegendContainer = styled(Box)`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Legend = styled(Box)`
  max-width: 274px;
  text-align: center;
  font: normal normal 400 1rem/1.5rem Roboto;
  letter-spacing: 0.16px;
  color: #535a6a;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 502px;
  }
`;

const PremiumButton = styled(AppButton)`
  width: 300px;
  max-width: 100%;
  outline: none;
  height: 44px;
  margin: 44px auto 40px auto;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 38px auto 80px auto;
  }
  &:hover {
    opacity: 0.9;
  }
`;

//#endregion component styles

function ResumedSubscription({ name }: ResumedSubscriptionProps) {
  const navigate = useNavigate();

  const handleRedirect = useCallback(() => {
    navigate("/status");
  }, [navigate]);

  return (
    <Layout
      data-qatag="backgroundContainer"
      qatag="backgroundContainer"
    >
      <ResumedSubscriptionContainer data-qatag="container">
        <PremiumIllustrationContainer data-qatag="premiumIllustrationContainer">
          <PremiumIllustration
            data-qatag="reviewIcon"
            src={icnPremium}
            alt="review"
          />
        </PremiumIllustrationContainer>
        <Title data-qatag="title">
          <FormattedMessage
            data-qatag="premium-upgrade.title"
            id="premium-upgrade.title"
            defaultMessage={"{name}, Congratulations!"}
            values={{
              name: <span data-qatag="premium-upgrade.name">{name}</span>,
            }}
          />
        </Title>
        <LegendContainer data-qatag="legendContainer">
          <Legend data-qatag="legend">
            <FormattedMessage
              data-qatag="premium-upgrade.legend"
              id="premium-upgrade.legend"
              defaultMessage="Your subscription has resumed."
            />
          </Legend>
        </LegendContainer>

        <PremiumButton
          ariaLabel="Access premium features"
          data-qatag="cardFormButton"
          buttonStyle={AppButtonStyles.Green}
          onClick={handleRedirect}
          buttonType="button"
        >
          <FormattedMessage
            data-qatag="premium-upgrade.access-premium-features"
            id="premium-upgrade.access-premium-features"
            defaultMessage="Back to My System"
          />
        </PremiumButton>
      </ResumedSubscriptionContainer>
    </Layout>
  );
}

export default ResumedSubscription;
