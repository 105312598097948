/**
 * @method timeout
 * @param {Number} timeInMs
 * @returns {Promise<boolean>}
 */
const timeout = (timeInMs: number): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), timeInMs);
  });
};

export type PromiseState = "idle" | "pending" | "fulfilled" | "rejected";

type ExecuteQueryablePromiseOptions = {
  setPromiseState: React.Dispatch<React.SetStateAction<PromiseState>>;
  delayStartMs?: number;
  delayBeforeReturnMs?: number;
};

/**
 * Allows promise execution state tracking and updating for components
 * that need to be aware of the state of the promise
 *
 * @method ExecuteQueryablePromise
 * @param {Promise<any>} promise
 * @param {ExecuteQueryablePromiseOptions} options
 * @returns any
 */
export async function ExecuteQueryablePromise(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise: () => any,
  { setPromiseState, delayStartMs, delayBeforeReturnMs }: ExecuteQueryablePromiseOptions
) {
  setPromiseState("pending");

  try {
    if (delayStartMs) {
      await timeout(delayStartMs);
    }

    const result = await promise();

    if (delayBeforeReturnMs) {
      await timeout(delayBeforeReturnMs);
    }

    setPromiseState("fulfilled");

    return result;
  } catch (e) {
    setPromiseState("rejected");

    return e;
  }
}
