import { MenuItemEntry } from "features/nav/NavBar";
import familyIcon from "assets/icon/icn-menu-family.svg";
import statusIcon from "assets/icon/icn-menu-system-status.svg";
import activeSettingsIcon from "assets/icon/icn-settings-screen.svg";
import inactiveSettingsIcon from "assets/icon/icn-settings-screen-not-active.svg";
import activeAdminIcon from "assets/icon/icn-admin.svg";
import inactiveAdminIcon from "assets/icon/icn-admin-not-active.svg";
import icnMenuMySystemUnselected from "assets/img/icn-menu-mysystem-unselected.png";
import icnMenuMyFamilyUnselected from "assets/img/icn-menu-myfamily-unselected.png";
import icnMenuTicketsUnselected from "assets/icon/icn-menu-tasks-notactive.svg";
import icnMenuTicketsSelected from "assets/icon/icn-menu-tasks-active.svg";
import icnDownloadUnselected from "assets/icon/icn-download-notactive.svg";
import icnDownloadSelected from "assets/icon/icn-download-active.svg";
import icnServiceSummary from "assets/icon/icn-serviceSummary.svg";
import icnServiceSummaryUnselected from "assets/icon/icn-serviceSummary-grey.svg";

export const getMenu = ({
  isAdmin,
  isSignalRConnected,
  hideAll,
  showDownloadToThisPCButton,
}: {
  isAdmin: boolean;
  isSignalRConnected: boolean;
  hideAll: boolean;
  showDownloadToThisPCButton: boolean;
}) => {
  const menuItems: MenuItemEntry[] = [];
  const dropdownItems: MenuItemEntry[] = [];

  if (hideAll) {
    return { menuItems, dropdownItems };
  }

  const support = {
    to: "/tickets",
    label: "Support",
    icon: icnMenuTicketsSelected,
    unSelectedIcon: icnMenuTicketsUnselected,
  };

  const settings = {
    to: "/settings",
    label: "App Settings",
    icon: activeSettingsIcon,
    unSelectedIcon: inactiveSettingsIcon,
  };

  const family = {
    to: "/family",
    label: "My Family",
    icon: familyIcon,
    unSelectedIcon: icnMenuMyFamilyUnselected,
  };

  const summary = {
    to: "/summary",
    label: "Service Summary",
    icon: icnServiceSummary,
    unSelectedIcon: icnServiceSummaryUnselected,
  };

  const status = {
    to: "/status",
    label: "My System",
    icon: statusIcon,
    unSelectedIcon: icnMenuMySystemUnselected,
  };

  const myAccount = {
    to: "/myaccount",
    label: "My Account",
    icon: activeAdminIcon,
    unSelectedIcon: inactiveAdminIcon,
  };

  const download = {
    to: "/newpcdetected",
    label: "Download to This PC",
    icon: icnDownloadSelected,
    unSelectedIcon: icnDownloadUnselected,
  };

  if (showDownloadToThisPCButton) {
    menuItems.push(download);
  }

  if (isAdmin) {
    menuItems.push(summary);
  }

  if (isSignalRConnected) {
    menuItems.push(status);
  }

  if (isAdmin) {
    menuItems.push(family);
  }

  dropdownItems.push(support);
  dropdownItems.push(myAccount);

  if (isSignalRConnected) {
    dropdownItems.push(settings);
  }

  return { menuItems, dropdownItems };
};
