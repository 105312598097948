import { Box, CircularProgress, styled, Typography } from "@mui/material";
import {
  ArcElement,
  Chart as ChartJS,
  ChartConfiguration,
  ChartData,
  CoreChartOptions,
  DatasetChartOptions,
  DoughnutControllerChartOptions,
  ElementChartOptions,
  Legend,
  Plugin,
  PluginChartOptions,
  Tooltip,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { ILicenseData } from "model/license/ILicenseData";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { FormattedMessage, useIntl } from "react-intl";
import OverlappingSegment from "utils/chartJsOverlappingSegment";

type LicensesChartSegment = {
  name: string;
  color: string;
  count: number;
};

type ActivatedLicensesChartProps = {
  orgLicenses: ILicenseData | undefined;
};

const ChartTextContainer = styled(Box)`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 65%;
  transform: translate(-50%, -50%);
`;

ChartJS.register(ArcElement, Tooltip, Legend);

export function ActivatedLicensesChart({ orgLicenses }: ActivatedLicensesChartProps) {
  const intl = useIntl();
  const [licenseData, setLicenseData] = useState<LicensesChartSegment[]>([]);
  const [chartConfig, setChartConfig] = useState<ChartConfiguration>();

  useEffect(() => {
    function mapOrgLicenses(orgLicenses: ILicenseData | undefined) {
      if (!orgLicenses) {
        return [];
      }

      return [
        {
          name: intl.formatMessage({
            id: "activatedLicensesChart.protected",
            defaultMessage: "Protected",
          }),
          color: "#2e7d32",
          count: orgLicenses.activatedLicenses.length,
        } as LicensesChartSegment,
        {
          name: intl.formatMessage({
            id: "activatedLicensesChart.available",
            defaultMessage: "Available",
          }),
          color: "#e5e5e5",
          count: orgLicenses.totalSeatCount - orgLicenses.activatedLicenses.length,
        } as LicensesChartSegment,
      ];
    }

    setLicenseData(mapOrgLicenses(orgLicenses));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgLicenses]);

  useEffect(() => {
    if (!licenseData || licenseData.length < 1) {
      return;
    }

    setChartConfig({
      data: {
        labels: licenseData?.map((l) => l.name),
        datasets: [
          {
            data: licenseData?.map((l) => l.count),
            borderWidth: 0,
            backgroundColor: licenseData?.map((l) => l.color as string),
            borderJoinStyle: "miter" as const,
          },
        ],
      },
      plugins: [OverlappingSegment],
      options: {
        aspectRatio: 2,
        borderRadius: 20,
        borderWidth: 0,
        cutout: "85%",
        circumference: 180,
        events: [], // disable interactions
        interaction: {
          mode: undefined,
        },
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        rotation: 270,
      },
    } as unknown as ChartConfiguration);
  }, [licenseData]);

  if (!chartConfig) {
    return (
      <CircularProgress
        data-qatag="loadingSpinner"
        size={40}
        sx={{ color: "secondary.light" }}
      />
    );
  }

  return (
    <>
      <Doughnut
        data-qatag="activatedLicensesChart.doughnut"
        data={chartConfig.data as ChartData<"doughnut">}
        options={
          chartConfig.options as
            | _DeepPartialObject<
                CoreChartOptions<"doughnut"> &
                  ElementChartOptions<"doughnut"> &
                  PluginChartOptions<"doughnut"> &
                  DatasetChartOptions<"doughnut"> &
                  DoughnutControllerChartOptions
              >
            | undefined
        }
        plugins={chartConfig.plugins as unknown as Plugin<"doughnut">[]}
      />

      <ChartTextContainer data-qatag="activatedLicensesChart.textContainer">
        <Typography
          component="h3"
          variant="h4"
          data-qatag="activatedLicensesChart.text.legend"
        >
          {/* eslint-disable-next-line solveiq/data-qatag */}
          <FormattedMessage
            id="activatedLicensesChart.protectedPCs.counts"
            defaultMessage="{activatedCount}/{totalCount}"
            values={{
              activatedCount: orgLicenses?.activatedLicenses.length ?? 0,
              totalCount: orgLicenses?.totalSeatCount ?? 0,
            }}
          />
        </Typography>
        <Typography
          data-qatag="activatedLicensesChart.text.label"
          variant="body2"
          sx={{ color: "text.disabled" }}
        >
          {/* eslint-disable-next-line solveiq/data-qatag */}
          <FormattedMessage
            id="activatedLicensesChart.protectedPCs.installed"
            defaultMessage="Installed"
          />
        </Typography>
      </ChartTextContainer>
    </>
  );
}
