import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/redux/store";
import { IUser } from "./IUser";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import { licenseApi } from "api/LicenseApi/LicenseApi";

declare let authServer: string;

export const fetchOrganizationUsers = createAsyncThunk<
  IUser[],
  void,
  {
    state: RootState;
  }
>("users/fetchOrganizationUsers", async (_, thunkApi) => {
  try {
    const url = `/api/organization/users`;

    const apiResponse = await RESTGatewayAPI.get(url);

    return apiResponse.data;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch file cleanup scan : ${error}`);
  }
});

export const fetchUsersOnlineStatus = createAsyncThunk<
  {
    isOnline: boolean | undefined;
    uuid: string;
    // Field to match the user
    userID: number;
  }[],
  void,
  {
    state: RootState;
  }
>("users/fetchMachineUsers", async (_, thunkApi) => {
  const appDispatch = thunkApi.dispatch as AppDispatch;

  //get org licenses from RTK query
  const request = appDispatch(licenseApi.endpoints.getOrganizationLicenses.initiate());
  const result = await request;
  request.unsubscribe();

  if (result.isError || !result.data) {
    throw new Error("Error fetching organization licenses");
  }

  const orgLicenseData = result.data;

  //Only Activated licenses have UUID
  const activatedLicenses = orgLicenseData?.activatedLicenses;

  try {
    const requestUsersOnlineStatus = activatedLicenses.map((license) => {
      const url = `/api/OnlineStatus/${license.uuid}`;
      return RESTGatewayAPI.get(url);
    });
    // Responses from the API
    const apiResponse = await Promise.all(requestUsersOnlineStatus);

    const usersOnline = apiResponse.map((response, index) => ({
      isOnline: response?.data,
      uuid: activatedLicenses?.[index]?.uuid,
      userID: activatedLicenses?.[index]?.userID,
    }));

    return usersOnline;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch file cleanup scan : ${error}`);
  }
});

export const fetchUsersMachine = createAsyncThunk<
  {
    machineDisplayName: string;
    uuid: string;
    // Field to match the user
    userID: number;
  }[],
  void,
  {
    state: RootState;
  }
>("users/fetchUsersOnlineStatus", async (_, thunkApi) => {
  const appDispatch = thunkApi.dispatch as AppDispatch;

  //get org licenses from RTK query
  const request = appDispatch(licenseApi.endpoints.getOrganizationLicenses.initiate());
  const result = await request;
  request.unsubscribe();

  if (result.isError || !result.data) {
    throw new Error("Error fetching organization licenses");
  }

  const orgLicenseData = result.data;

  //Only Activated licenses have UUID
  const activatedLicenses = orgLicenseData?.activatedLicenses;

  try {
    const requestUsersOnlineStatus = activatedLicenses.map((license) => {
      const url = `/api/core/machineintelligence/persisted/${license.uuid}`;
      return RESTGatewayAPI.get(url);
    });
    // Responses from the API
    const apiResponse = await Promise.all(requestUsersOnlineStatus);

    const usersMachine = apiResponse.map((response, index) => ({
      machineDisplayName: response?.data?.machineDisplayName || "Custom PC",
      uuid: activatedLicenses?.[index]?.uuid,
      userID: activatedLicenses?.[index]?.userID,
    }));

    return usersMachine;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to fetch users machine : ${error}`);
  }
});

export const updateUser = createAsyncThunk<
  void,
  IUser,
  {
    state: RootState;
  }
>("users/updateUser", async (user, thunkApi) => {
  try {
    const url = `/api/User`;

    const options = {
      headers: {
        "Content-Type": "text/json",
      },
    };

    await RESTGatewayAPI.patch(url, user, options);
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to update user ${user.email} : ${error}`);
  }
});

export interface ISetPasswordMessage {
  NewPassword: string;
  ConfirmPassword: string;
  ReturnUrl: string;
}

export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (setPasswordModel: ISetPasswordMessage) => {
    const setPasswordUrl = `${authServer}/Account/ChangePassword`;
    const response = await fetch(setPasswordUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        NewPassword: setPasswordModel.NewPassword,
        ConfirmPassword: setPasswordModel.ConfirmPassword,
        ReturnUrl: setPasswordModel.ReturnUrl,
      }),
    });

    // we should always get back a json response from the server
    const data = await response.json();
    return data;
  }
);

export const fetchUsersNeedsToSetPassword = createAsyncThunk<
  boolean,
  string,
  {
    state: RootState;
  }
>("users/fetchNeedsToSetPassword", async (email, thunkApi) => {
  const url = `${authServer}/account/needstosetpassword`;
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  });
  const data = await response.json();
  return data?.value ?? false;
});

const initialState: IReducerState<IUser[]> = {
  data: [],
  status: {
    [fetchOrganizationUsers.typePrefix]: ReducerStatus.Idle,
    [fetchUsersOnlineStatus.typePrefix]: ReducerStatus.Idle,
    [fetchUsersMachine.typePrefix]: ReducerStatus.Idle,
    [updateUser.typePrefix]: ReducerStatus.Idle,
    [changePassword.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: (state) => {
      state.data = initialState.data;
    },
    // Reset status for update user info
    resetUpdateUserStatus: (state) => {
      state.status[updateUser.typePrefix] = ReducerStatus.Idle;
    },
    // Reset status for change password
    resetChangePasswordStatus: (state) => {
      state.status[changePassword.typePrefix] = ReducerStatus.Idle;
    },
    set: (state, action: PayloadAction<IUser[]>) => {
      state.data = action.payload;
    },
    setUsersRoleStatus: (state) => {
      state.status[fetchOrganizationUsers.typePrefix] = ReducerStatus.Idle;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationUsers.pending, (state) => {
      state.status[fetchOrganizationUsers.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status[fetchOrganizationUsers.typePrefix] = ReducerStatus.Succeeded;
    });
    // This case will add loading for the online status
    builder.addCase(fetchUsersOnlineStatus.pending, (state) => {
      state.status[fetchUsersOnlineStatus.typePrefix] = ReducerStatus.Loading;
    });
    // This case will add the online status
    builder.addCase(fetchUsersOnlineStatus.fulfilled, (state, action) => {
      state.status[fetchUsersOnlineStatus.typePrefix] = ReducerStatus.Succeeded;

      state.data = state.data.map((user) => {
        // Filter on the payload if the user is online
        const hasOnlineRecord = action.payload.filter((onlineStatus) => onlineStatus.userID === user.id);
        return {
          ...user,
          isOnline: hasOnlineRecord?.[0]?.isOnline ?? undefined,
        };
      });
    });
    // This case will add loading for the users machine
    builder.addCase(fetchUsersMachine.pending, (state) => {
      state.status[fetchUsersMachine.typePrefix] = ReducerStatus.Loading;
    });
    // This case will add the online status
    builder.addCase(fetchUsersMachine.fulfilled, (state, action) => {
      state.data = state.data.map((user) => {
        // Filter on the payload if the user is online
        const hasMachineRecord = action.payload.filter((onlineStatus) => onlineStatus.userID === user.id);
        return {
          ...user,
          machineDisplayName: hasMachineRecord?.[0]?.machineDisplayName ?? "Custom PC",
        };
      });
    });
    builder.addCase(updateUser.pending, (state) => {
      state.status[updateUser.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.status[updateUser.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.status[updateUser.typePrefix] = ReducerStatus.Failed;
    });
    builder.addCase(changePassword.pending, (state) => {
      state.status[changePassword.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.status[changePassword.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.status[changePassword.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { resetUserState, set, setUsersRoleStatus, resetUpdateUserStatus, resetChangePasswordStatus } =
  userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => dispatch => {
//   setTimeout(() => {
//     dispatch(set(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsers = (state: RootState) => state.users.data;
export const selectUserById = (state: RootState, id: number) => state.users.data?.find((u) => u.id === id);
export const selectFetchUsersStatus = (state: RootState) => state.users.status[fetchOrganizationUsers.typePrefix];
export const selectFetchUsersOnlineStatus = (state: RootState) => state.users.status[fetchUsersOnlineStatus.typePrefix];
export const selectStatus = (state: RootState) => state.users.status;

export default userSlice.reducer;
