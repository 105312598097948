import { Paper } from "@mui/material";
import styled from "@mui/styled-engine";
import dsOneLogo from "assets/icon/dsOne-logo-master.svg";
import { FormattedMessage } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";

export type NewPCDetectedProps = {
  downloadHandler: () => void;
  cancelHandler: () => void;
  allowCancel: boolean;
};

//#region component styles
const Container = styled(Paper)`
  margin-top: 52px;
  display: flex;
  width: 500px;
  height: 300px;
  padding: 16px 34px 34px 34px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
`;

const Logo = styled("img")`
  margin-top: 24px;
  max-width: 172px;
  flex-basis: 100%;
`;

const Title = styled("h1")`
  margin-top: 18px;
  margin-bottom: 0px;
  color: #535353;
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: 500;
  flex-basis: 100%;
  text-align: center;
`;

const Subtitle = styled("h2")`
  margin-top: 8px;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #535a6a;
  flex-basis: 100%;
  text-align: center;
`;

const StyledAppButton = styled(AppButton)`
  height: 44px;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0.24px;
  color: #ffffff;
  width: 200px;
  max-width: 90%;
  flex-basis: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 288px;
  }
`;

const RedirectLink = styled("a")`
  flex-basis: 100%;
  color: #2196f3;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 18px;
  text-align: center;

  &:hover {
    opacity: 0.9;
  }
`;

//#endregion component styles

export function NewPCDetected({ downloadHandler, cancelHandler, allowCancel }: NewPCDetectedProps) {
  return (
    <Container data-qatag="NewPCDetectedWrapper">
      <Logo
        data-qatag="logo"
        src={dsOneLogo}
        alt="dsOne Logo"
      />
      <Title data-qatag="NewPCDetectedTitle">
        <FormattedMessage
          data-qatag="newPCDetected--title"
          id="newPCDetected--title"
          defaultMessage="New PC Detected"
        />
      </Title>
      <Subtitle data-qatag="NewPCDetectedTitle">
        <FormattedMessage
          data-qatag="newPCDetected--subtitle"
          id="newPCDetected--subtitle"
          defaultMessage="Click below to add this PC"
        />
      </Subtitle>
      <StyledAppButton
        data-qatag="NewPCDetectedDownloadButton"
        onClick={downloadHandler}
        buttonType="button"
        buttonStyle={AppButtonStyles.Green}
        ariaLabel="Add this PC"
      >
        <FormattedMessage
          data-qatag="newPCDetected--download"
          id="newPCDetected--add-this-pc"
          defaultMessage="Add this PC"
        />
      </StyledAppButton>
      {allowCancel ? (
        <RedirectLink
          data-qatag="NewPCDetectedCancelButton"
          onClick={cancelHandler}
        >
          <FormattedMessage
            data-qatag="newPCDetected--download"
            id="newPCDetected--add-this-pc"
            defaultMessage="Go To My Family"
          />
        </RedirectLink>
      ) : null}
    </Container>
  );
}
