import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, IconButton, Icon, Divider } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { fetchJittriggersScan } from "./OptimizationSlice";
import TabOptimization from "./TabOptimization/TabOptimization";
import Settings from "./Settings/Settings";
import Header from "./Header/Header";
import softwareIcon from "assets/img/optimization/icn-app-optimization.svg";
import settingsIcon from "assets/icon/icn-settings.svg";
import { ReducerStatus } from "model/IReducerState";
import { ISoftware, TriggerInfo } from "./types";
import { Layout } from "components/Layout/Layout";
import Tabs from "components/Tabs/Tabs";
import { ProfileState } from "model/messaging/messages/AppSettingsMessages";
import styled from "@mui/styled-engine";

export type OptimizationProps = {
  /* Software Array */
  browsingSoftware: TriggerInfo[];
  productivitySoftware: TriggerInfo[];
  gamingSoftware: TriggerInfo[];
  /* Software Status */
  softwareStatus: ReducerStatus;
  /* Software */
  software: ISoftware;
  gamingSetting: ProfileState;
  toggleGaming: (currentSettingValue: ProfileState) => void;
  productivitySetting: ProfileState;
  toggleProductivity: (currentSettingValue: ProfileState) => void;
  browsingSetting: ProfileState;
  toggleBrowsing: (currentSettingValue: ProfileState) => void;
  handleUpdateSetting: () => void;
  openSettings: boolean;
  setOpenSettings: (open: boolean) => void;
};

//#region component styles

const Container = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const LogoImage = styled("img")`
  height: 24px;
  width: 25px;
`;

const Logo = styled(Box)`
  flex: 20%;
  height: 48px;
  max-width: 120px;
`;

const SettingsText = styled("label")`
  cursor: pointer;
  font-weight: 500;
`;

const ActionsContainer = styled(Box)`
  text-decoration: underline;
  font: normal normal 500 16px Roboto;
  letter-spacing: 0px;
  color: #007dba;
`;

const TabsContainer = styled(Box)`
  flex-basis: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: unset;
    margin: auto;
    transform: translateX(-60px);
  }
`;

const LogoText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.light};
  margin-left: 16px;
`;

const DividerBottom = styled(Box)`
  flex-basis: 100%;
  margin-top: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 0px;
  }
`;

const TabsHeader = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

//#endregion component styles

function Optimization({
  software,
  browsingSoftware,
  productivitySoftware,
  gamingSoftware,
  softwareStatus,
  //Settings modal
  gamingSetting,
  toggleGaming,
  productivitySetting,
  toggleProductivity,
  browsingSetting,
  toggleBrowsing,
  handleUpdateSetting,
  openSettings,
  setOpenSettings,
}: OptimizationProps) {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJittriggersScan());
  }, [dispatch]);

  const tabs = useMemo(
    () => [
      formatMessage({
        id: "body.app.optimization.productivity",
        defaultMessage: "Productivity",
      }),
      formatMessage({
        id: "body.app.optimization.gaming",
        defaultMessage: "Gaming",
      }),
      formatMessage({
        id: "body.app.optimization.browsing",
        defaultMessage: "Browsing",
      }),
    ],
    [formatMessage]
  );
  // Tab number to be displayed
  const [tabNumber, setTabNumber] = useState(0);

  return (
    <Layout
      data-qatag="layout"
      qatag="layout"
      useTemplating={true}
    >
      <Layout.DetachedHeader data-qatag="appOptimization-header">
        <Header
          data-qatag="optimization_header"
          appsNumber={(software.TriggerInfo || []).length}
        >
          {/* Settings */}
          <ActionsContainer
            data-qatag="actions"
            display="flex"
            alignItems="center"
          >
            <IconButton
              data-qatag="closeIcon"
              aria-label="close"
              onClick={() => setOpenSettings(true)}
            >
              <Icon
                data-qatag="settingsIcon"
                className={"MuiSelect-icon MuiSvgIcon-root"}
              >
                <img
                  data-qatag="settingsIconImg"
                  src={settingsIcon}
                  alt="settings"
                />
              </Icon>
            </IconButton>
            <SettingsText
              data-qatag="settingsText"
              onClick={() => setOpenSettings(true)}
            >
              <FormattedMessage
                data-qatag="settingsText"
                id="body.app.optimization.settings"
                defaultMessage="Settings"
              />
            </SettingsText>
          </ActionsContainer>
        </Header>
      </Layout.DetachedHeader>
      <Layout.Content data-qatag="appOptimization-content">
        <Container data-qatag="container">
          <TabsHeader data-qatag="TabsHeader">
            {/* Logo section */}
            <Logo
              data-qatag="logo"
              display="flex"
              alignItems="center"
            >
              <LogoImage
                data-qatag="logoImage"
                src={softwareIcon}
                alt="software"
              />
              <LogoText
                data-qatag="logoText"
                variant="h6"
              >
                <FormattedMessage
                  data-qatag="softwareText"
                  id="body.app.optimization.software"
                  defaultMessage="Software"
                />
              </LogoText>
            </Logo>
            <TabsContainer data-qatag="TabsContaine">
              <Tabs
                data-qatag="tabs"
                tabs={tabs}
                setValue={setTabNumber}
                value={tabNumber}
              />
            </TabsContainer>
            {/* Divider */}
            <DividerBottom
              data-qatag="dividerBottom"
              width="100%"
            >
              <Divider data-qatag="Divider" />
            </DividerBottom>
          </TabsHeader>

          {/* Tab panels */}
          {/* Productivity Panel*/}
          {tabNumber === 0 ? (
            <TabOptimization
              data-qatag="productivityTab"
              reducerStatus={softwareStatus}
              tabNumber={tabNumber}
              tabIndex={0}
              software={productivitySoftware}
            />
          ) : null}
          {/* Tab Panel Gaming */}
          {tabNumber === 1 ? (
            <TabOptimization
              data-qatag="gamingTab"
              reducerStatus={softwareStatus}
              tabIndex={1}
              tabNumber={tabNumber}
              software={gamingSoftware}
            />
          ) : null}
          {/* Browsing Panel*/}
          {tabNumber === 2 ? (
            <TabOptimization
              data-qatag="browsingTab"
              reducerStatus={softwareStatus}
              tabIndex={2}
              tabNumber={tabNumber}
              software={browsingSoftware}
            />
          ) : null}
        </Container>
        <Settings
          data-qatag="Settings"
          isOpen={openSettings}
          handleOnClose={() => setOpenSettings(false)}
          // Settings modal
          gamingSetting={gamingSetting}
          toggleGaming={toggleGaming}
          productivitySetting={productivitySetting}
          toggleProductivity={toggleProductivity}
          browsingSetting={browsingSetting}
          toggleBrowsing={toggleBrowsing}
          handleUpdateSetting={handleUpdateSetting}
        />
      </Layout.Content>
    </Layout>
  );
}

export default Optimization;
