import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion } from "dsoneweb.designsystem";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Divider, Typography } from "@mui/material";
import { ISystemOptimizationInfo } from "model/scan/SystemOptimizationInfo";
import settingsCategory from "assets/icon/Icn-category-settings.svg";
import Fuse from "fuse.js";
import { debounce } from "core/Helpers";
import headerIcon from "assets/img/threeGears.svg";
import backgroundHeader from "assets/img/banner-blue-master.svg";
import styled from "@mui/styled-engine";

export interface IWindowsEnhancementsProps {
  optimizations: ISystemOptimizationInfo[];
  issueCount: number;
}

const Container = styled("div")`
  display: relative;
  width: 100%;
`;

const Background = styled("div")`
  position: absolute;
  x: 0;
  y: 0;
  width: 100%;
  background-image: linear-gradient(#efefef, #ffffff);
  z-index: -1;
`;

const BackgroundImage = styled("div")`
  width: 100%;
  height: 120px;
  background-image: url(${backgroundHeader});
  background-size: cover;
`;

const Content = styled("div")`
  max-width: 100%;
  max-width: 1075px;
  margin: 34px auto 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 720px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    max-width: 960px;
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    max-width: 1075px;
  }
`;

const Header = styled("div")`
  position: relative;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  border-bottom: 1px solid #e6e6e6;

  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 8px;
    border: none;
  }
`;

const HeaderImage = styled("img")`
  width: 40px;
  height: 29px;
  position: absolute;

  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    width: 120px;
    height: 88px;
    margin-right: 20px;
  }
`;

const HeaderText = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

const HeaderTitle = styled(Typography)`
  color: #007dba;
  font-size: 1.125rem;
  line-height: 2rem;
  margin-left: 52px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: 0;
    font-size: 1.5rem;
  }
`;

const HeaderDescription = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 6px;
`;

const Indicator = styled("span")`
  color: #0a9a1a;
`;

const WindowsEnhancements: React.FC<IWindowsEnhancementsProps> = ({ optimizations, issueCount }) => {
  return (
    <Container data-qatag="WindowsEnhancements.container">
      <Background data-qatag="WindowsEnhancements.background">
        <BackgroundImage data-qatag="WindowsEnhancements.backgroundImage" />
      </Background>
      <Content data-qatag="WindowsEnhancements.content">
        <Header data-qatag="headerWindowsEnhancements.header">
          <HeaderImage
            data-qatag="WindowsEnhancements.headerImg"
            src={headerIcon}
            alt="title icon"
          />
          <HeaderText data-qatag="WindowsEnhancements.headerText">
            <HeaderTitle
              data-qatag="WindowsEnhancements.headerTitle"
              variant="h4"
            >
              <FormattedMessage
                data-qatag="headerTitleWindowsEnhancements"
                id="windowsEnhancements.view.header.wh-title"
                description="Windows Enhancements title"
                defaultMessage="Windows Enhancements"
              />
            </HeaderTitle>
            <HeaderDescription
              data-qatag="WindowsEnhancements.HeaderDescription"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="WindowsEnhancements.subtitle"
                id="WindowsEnhancements.subtitle"
                defaultMessage="The following {systemIssueCount} system-wide modifications have been applied to improve networking throughput and system responsiveness."
                values={{
                  systemIssueCount: <Indicator data-qatag="subtitleCount">{issueCount}</Indicator>,
                }}
              />
            </HeaderDescription>
          </HeaderText>
        </Header>

        <OptimizationList
          data-qatag="optimizationList"
          allOptimizations={optimizations}
          keys={["friendlyName", "description"]}
        />
      </Content>
    </Container>
  );
};

export default WindowsEnhancements;

interface IOptimizationList {
  allOptimizations: ISystemOptimizationInfo[];
  /**
   * Properties of the SystemOptimizationInfo model to use when
   * searching for an optimization
   */
  keys: Array<keyof ISystemOptimizationInfo>;
}

/**
 * Transforms each optimization to a Optimization component
 * @param optimizations List of optimizations
 */
const optimizationsToComponents = (optimizations: ISystemOptimizationInfo[]) =>
  [...optimizations].map((opt, index) => (
    <Optimization
      data-qatag="optimizationItem"
      key={`${opt.friendlyName}_${index}`}
      optimization={opt}
    />
  ));

const ListPanel = styled("div")`
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-bottom: 40px;

  ${(props) => props.theme.breakpoints.up("md")} {
    border-radius: 8px;
    margin-top: 20px;
  }
`;

const Filters = styled(Box)`
  display: grid;
  grid-template-columns: max-content 0.8fr;
  align-items: center;
  justify-content: space-between;
  column-gap: 14px;
`;

const LabelContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelImage = styled("img")`
  width: 28px;
  height: 28px;
`;

const Label = styled("span")`
  font-size: 1.125rem;
  color: #007dba;
  font-weight: 500;
  margin-left: 8px;
`;

const ListDivider = styled(Box)`
  display: block;
  width: 100%;
  margin: 20px 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`;

const OptimizationList: React.FC<IOptimizationList> = ({ allOptimizations, keys }) => {
  const { formatMessage } = useIntl();
  const [searchText /*setSearchText*/] = useState("");
  const [filteredOptimizations, setFilteredOptimizations] = useState<React.ReactElement[]>(
    optimizationsToComponents(allOptimizations)
  );

  // Builds a new Fuse object to filter optimizations
  const optimizationsToFilter = useMemo(() => {
    const options = { ignoreLocation: true, includeScore: true, keys };
    return new Fuse(allOptimizations, options);
  }, [keys, allOptimizations]);

  // Memoized debounced function to set filtered optimizations
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetFilteredOptimizations = useCallback(
    debounce((values: React.ReactElement[]) => {
      setFilteredOptimizations(values);
    }, 300),
    [setFilteredOptimizations]
  );

  useEffect(() => {
    // User is not searching for optimizations
    if (searchText.trim().length === 0) {
      memoizedSetFilteredOptimizations(optimizationsToComponents(allOptimizations));
    }
    // User is searching for optimizations
    else {
      memoizedSetFilteredOptimizations(
        optimizationsToFilter
          .search(searchText)
          .filter((item) => (item.score || 1) < 0.1)
          .map((element, index) => (
            <Optimization
              data-qatag="optimizationItem"
              key={`${element.item.friendlyName}_${index}`}
              optimization={element.item}
            />
          ))
      );
    }
  }, [searchText, allOptimizations, memoizedSetFilteredOptimizations, optimizationsToFilter]);

  return (
    <ListPanel data-qatag="optimizationList">
      <Filters data-qatag="filters">
        <LabelContainer data-qatag="label">
          <LabelImage
            data-qatag="filterOptions"
            src={settingsCategory}
            alt="Settings"
          />
          <Label data-qatag="filterLabel">
            {formatMessage({
              id: "windowsEnhancements.view.devices.filters.label",
              defaultMessage: "Settings",
            })}
          </Label>
        </LabelContainer>
      </Filters>
      <ListDivider data-qatag="dividerMobileContainer">
        <Divider data-qatag="dividerMobile" />
      </ListDivider>
      {filteredOptimizations.length > 0 ? filteredOptimizations : "No results were found"}
    </ListPanel>
  );
};

const OptimizationContainer = styled("div")`
  border-top-style: solid;
  border-top-color: #e5e5e5;
  border-top-width: 1px;
  margin-top: 34px;
  padding-top: 45px;
  display: none;
  align-items: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
  }
`;

const DeviceImage = styled("img")`
  width: 28px;
  height: 28px;
  margin-right: 12px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 44px;
    height: 44px;
    margin-right: 0;
  }
`;

const Details = styled("div")`
  margin-left: 28px;
`;

const Name = styled("p")`
  color: #007dba;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
  margin-top: 0;
`;

const Description = styled("p")`
  color: #757e95;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const AccordionContainer = styled("div")`
  display: block;
  margin-top: 16px;

  .MuiPaper-root {
    box-shadow: none;
    border-bottom: 1px solid #e6e6e6;
  }

  .MuiAccordionSummary-root {
    background-color: unset;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`;

const AccordionSummary = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AccordionDetails = styled(Box)`
  width: 100%;
  margin-top: 10px;
`;

interface IOptimization {
  optimization: ISystemOptimizationInfo;
}

const Optimization: React.FC<IOptimization> = ({ optimization }) => {
  return (
    <>
      <OptimizationContainer data-qatag="optimization">
        <DeviceImage
          data-qatag="deviceImage"
          src={settingsCategory}
          alt="Optimization"
        />
        <Details data-qatag="details">
          <Name data-qatag="optimizationName">{optimization.friendlyName.trim()}</Name>
          <Description data-qatag="optimizationDescription">{optimization.description}</Description>
        </Details>
      </OptimizationContainer>
      <AccordionContainer data-qatag="accordion">
        <Accordion
          details={
            <AccordionDetails data-qatag="totalContainerMobile">
              <Details data-qatag="details">
                <Description data-qatag="optimizationDescription">{optimization.description}</Description>
              </Details>
            </AccordionDetails>
          }
          summary={
            <AccordionSummary data-qatag="optimizationContainer">
              <DeviceImage
                data-qatag="deviceImage"
                src={settingsCategory}
                alt="Optimization"
              />
              <Name data-qatag="optimizationName">{optimization.friendlyName.trim()}</Name>
            </AccordionSummary>
          }
          data-qatag="accordionMobile"
        />
      </AccordionContainer>
    </>
  );
};
