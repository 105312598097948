import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import { Modal } from "dsoneweb.designsystem";
import headerImage from "assets/img/fileCleaning/icn-locked-files.svg";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

export interface ILockedFilesEntry {
  name: string;
  size: string;
}

export interface ILockedFilesDialogProps {
  isOpen: boolean;
  files: ILockedFilesEntry[];
  totalSize: string;
  closeDialogHandler: () => void;
}

const Dialog = styled(Modal)`
  border-radius: 8px;
  padding: 14px 8px 24px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 14px 24px 24px;
    max-width: 660px;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
`;

const Description = styled(Box)`
  width: 80%;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 24px;
  }
`;

const HeaderImage = styled("img")`
  width: 87px;
  height: 72px;
  margin-bottom: 12px;
`;

const LockedFiles = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-height: 300px;
  overflow: auto;
`;

const LockedFilesEntry = styled("div")`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 13px;

  &:nth-child(odd) {
    background-color: #f8f8f8;
    border-radius: 12px;
  }
`;

const FileNameBox = styled(Box)`
  flex-basis: 80%;
  max-width: 80%;
`;

const FileName = styled(Typography)`
  text-align: left;
  overflow-wrap: break-word;
`;

const Footer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 38px;
    padding-left: 20px;
    flex-direction: row;
  }
`;

const TotalSize = styled(Typography)`
  margin: 12px 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0;
  }
`;

const BackButton = styled(AppButton)`
  width: 277px;
  height: 44px;
  font-size: 1rem;
`;

const BackButtonText = styled(Typography)`
  line-height: 100%;
`;

export const LockedFilesDialog: React.FC<ILockedFilesDialogProps> = (props) => {
  /* Localization function and prefix */
  const { formatMessage } = useIntl();
  const prefix = "fileCleaning.lockedFilesDialog";

  return (
    <Dialog
      data-qatag="lockedFilesDialog"
      open={props.isOpen}
      onClose={props.closeDialogHandler}
    >
      <Content data-qatag="lockedFilesDialogContent">
        <HeaderImage
          data-qatag="headerImage"
          src={headerImage}
          alt="Locked Files icon"
        />
        <Typography
          data-qatag="lockedFilesDialogTitleTypography"
          variant="h4"
        >
          {formatMessage({
            id: `${prefix}.title`,
            defaultMessage: "Locked Files",
          })}
        </Typography>
        <Description data-qatag="lockedFilesDescriptionBox">
          {formatMessage({
            id: `${prefix}.description`,
            defaultMessage: "The following files were unable to be cleaned because they were locked.",
          })}
        </Description>
        <LockedFiles data-qatag="lockedFiles">
          {props.files.map((file, index) => {
            return (
              // eslint-disable-next-line solveiq/no-empty-data-qatag
              <LockedFilesEntry
                data-qatag={`lockedFilesEntry_${index}`}
                key={`locked-files-entry-${file.name}-${file.size}-${index}`}
              >
                {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                <FileNameBox data-qatag={`lockedFilesDialogFileNameBox_${index}`}>
                  {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                  <FileName data-qatag={`lockedFilesDialogFileNameTypography_${index}`}>{file.name}</FileName>
                </FileNameBox>
                {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
                <Typography data-qatag={`lockedFilesDialogFileSizeTypography_${index}`}>{file.size}</Typography>
              </LockedFilesEntry>
            );
          })}
        </LockedFiles>
        <Footer data-qatag="footer">
          <TotalSize
            data-qatag="totalSize"
            variant="h4"
          >
            {formatMessage({ id: `${prefix}.total`, defaultMessage: "Total:" })} {props.totalSize}
          </TotalSize>
          <BackButton
            ariaLabel="back to summary"
            buttonType="button"
            data-qatag="backButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={props.closeDialogHandler}
          >
            <BackButtonText
              data-qatag="buttonText"
              variant="body2"
            >
              {formatMessage({
                id: `${prefix}.buttonText`,
                defaultMessage: "Back To Summary",
              })}
            </BackButtonText>
          </BackButton>
        </Footer>
      </Content>
    </Dialog>
  );
};
