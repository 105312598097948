import { resetAgentState } from "app/Agent/AgentSlice";
import { resetBillingHistoryState } from "features/SubscriptionManagement/BillingHistory/BillingHistorySlice";
import { resetInvoiceState } from "features/SubscriptionManagement/BillingHistory/InvoiceFileSlice";
import { resetDeceptorProtectionState } from "features/deceptorProtection/DeceptorProtectionSlice";
import { resetDriverState } from "features/driverInstall/DriverSlice";
import { resetFileCleanupState } from "features/fileCleaning/FileCleaningSlice";
import { resetMachinePickerState } from "features/MachinePicker/MachinePickerSlice";
import { resetOptimizationState } from "features/Optimization/OptimizationSlice";
import { resetScanState } from "features/scan/ScanSlice";
import { resetSettingsState } from "features/settings/SettingsSlice";
import { resetFamilyDetailsState } from "features/Family/FamilyDetail/FamilyDetailSlice";
import { resetTicketsState } from "features/tickets/TicketsSlice";
import { resetOrganizationState } from "model/organization/OrganizationSlice";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import { resetSystemState } from "model/system/SystemSlice";
import { resetUserState } from "model/user/UserSlice";
import moment from "moment";
import { Dispatch } from "react";

export function getParameterByName(name: string) {
  try {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = "[\\?&]" + name + "=([^&#]*)";
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    if (results == null) return "";
    else return decodeURIComponent(results[1].replace(/\+/g, " "));
  } catch (e) {
    return "";
  }
}

export function sortByDeviceClass(a: IDeviceInfo, b: IDeviceInfo) {
  return a.classRank < b.classRank ? 1 : a.classRank === b.classRank ? (a.friendlyName > b.friendlyName ? 1 : -1) : -1;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce(f: (...args: any[]) => void, ms: number) {
  let id = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(id);
    id = window.setTimeout(() => f(...args), ms);
  };
}

export function formatLastScanTime(scanTime: string) {
  //time zone throws off the calculation, so get rid of it
  const nowString = new Date().toUTCString();
  const now = moment(nowString.substring(0, nowString.length - 4));

  const scan = moment(scanTime);
  return scan.from(now);
}

export function resetAllState(dispatch: Dispatch<unknown>) {
  dispatch(resetAgentState());
  dispatch(resetBillingHistoryState());
  dispatch(resetInvoiceState());
  dispatch(resetDeceptorProtectionState());
  dispatch(resetDriverState());
  dispatch(resetFileCleanupState());
  dispatch(resetMachinePickerState());
  dispatch(resetOptimizationState());
  dispatch(resetScanState());
  dispatch(resetSettingsState());
  dispatch(resetFamilyDetailsState());
  dispatch(resetTicketsState());
  dispatch(resetOrganizationState());
  dispatch(resetSystemState());
  dispatch(resetUserState());
  //dispatch(resetSessionState()); don't do Session, they still need to log out
}

export function isFeatureIncludedInLicense(feature: string, featureFlags: string) {
  return featureFlags === "AllFeatures" || featureFlags.toLocaleLowerCase().includes(feature.toLocaleLowerCase());
}

export function isFeatureArrayIncludedInLicense(features: string[], featureFlags: string | undefined): boolean {
  if (!features || !featureFlags) return false;
  const licenseHasFeature =
    featureFlags === "AllFeatures" ||
    features?.some((flag) => {
      return featureFlags?.toLowerCase().includes(flag.toLowerCase());
    });
  return licenseHasFeature;
}

export function isValidDate(dateString: string) {
  const dateObject = new Date(dateString);
  return dateObject instanceof Date && !isNaN(dateObject.getTime());
}

export const uniqueConcat = (arr1: string[], arr2: string[]) => {
  return Array.from(new Set(arr1.concat(arr2)));
};
