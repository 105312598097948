import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  styled,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import { ILicenseData } from "model/license/ILicenseData";
import IFamilyMember from "model/user/IFamilyMember";
import IUser from "model/user/IUser";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { ServiceSummaryItem } from "./SummaryContainer";
import { ActivatedLicensesChart } from "features/Summary/ActivatedLicensesChart/ActivatedLicensesChart";
import { ProtectedPCsTable } from "features/Summary/ProtectedPCsTable/ProtectedPCsTable";
import { PremiumServiceTable } from "./PremiumServiceTable/PremiumServiceTable";
import { ServiceItemRow } from "./ServiceItemRow/ServiceItemRow";

type SummaryProps = {
  availableSeats: number;
  currentUser: IUser | null;
  familyMembers: IFamilyMember[];
  featureFlags: string;
  hasLocalAgent: boolean;
  isLicenseExpired: boolean;
  isLocalAgentUnlicensed: boolean;
  isPremiumSubscription: boolean;
  isWindows: boolean;
  orgLicenses: ILicenseData | undefined;
  premiumServices: ServiceSummaryItem[];
  standardServices: ServiceSummaryItem[];
};

// #region styled-components
const Container = styled(Box)`
  flex: 1;
  max-width: 1076px; // 1044px (per design) plus horizontal padding
  padding: ${({ theme }) => theme.spacing(2)};
`;

const StyledLink = styled(Link)`
  color: #007dba; // sigh
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
` as typeof Link;

const WelcomeMessage = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  margin: ${({ theme }) => theme.spacing(1, 0, 2)};
  padding: ${({ theme }) => theme.spacing(0.5, 0)};
  text-align: center;
`;

const AgentNotRunningAlert = styled(Alert)`
  border-radius: 16px;
  border: solid 1px #499dc1;
  background-color: #e5f7ff;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(2, 0, 4)};

  .MuiAlert-icon {
    color: #1572c3;
  }
` as typeof Alert;

const PageTilesGrid = styled(Box)`
  display: grid;
  grid-template-rows: 1fr;
  margin: ${({ theme }) => theme.spacing(2, 0)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ServiceTiles = styled(Box)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;

const PageTile = styled(Card)`
  align-items: stretch;
  border-radius: 20px;
  box-shadow:
    0 5px 22px 0 rgba(0, 0, 0, 0.04),
    0 0 0 1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  min-height: 100px;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1, 2.5)};
`;

const PremiumServicesTile = styled(PageTile)`
  flex: 1 1 auto;
`;

const PageTileHeader = styled(Box)`
  color: inherit;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1, 0, 2)};

  h2 {
    letter-spacing: 0.15px;
  }
`;

const TileHeaderAux = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    align-self: flex-end;
    position: absolute;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 0;
`;

const StyledCardActions = styled(CardActions)`
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: flex-end;
  }
`;

const UpgradeButtonContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2, 0, 0)};
  text-align: center;
`;

const UpgradeButton = styled(Button)`
  text-transform: none;
`;

const ProtectedPCs = styled(PageTile)`
  flex: 1 1 auto;
  width: 100%;
`;

const ChartContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(3, "auto")};
  max-width: 240px;
  position: relative;
`;

const ProtectedPCsSummary = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 240px;
`;

const ProtectedPCsSummaryList = styled(List)`
  display: flex;
  flex-direction: row;
`;

const ProtectedPCsSummaryListItem = styled(ListItem)`
  flex-direction: column;
`;

const StyledDivider = styled(Divider)`
  border-right-width: 2px;
  justify-content: center;
  max-height: 32px;
`;

const Footer = styled(Box)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(1, 0)};
`;
// #endregion styled-components

export function Summary({
  availableSeats,
  currentUser,
  familyMembers,
  featureFlags,
  hasLocalAgent,
  isLicenseExpired,
  isLocalAgentUnlicensed,
  isPremiumSubscription,
  isWindows,
  orgLicenses,
  premiumServices,
  standardServices,
}: SummaryProps) {
  const navigate = useNavigate();
  const showNoAgentAlert = !hasLocalAgent || isLocalAgentUnlicensed;
  const hasActivatedLicenses = orgLicenses?.activatedLicenses && orgLicenses?.activatedLicenses.length > 0;
  let noAgentAlertDownloadTarget = "";

  // Determine target for "download and install" link
  if (showNoAgentAlert && isWindows) {
    if (isPremiumSubscription && availableSeats === 0) {
      noAgentAlertDownloadTarget = "/licenselimit";
    } else if (!isPremiumSubscription && hasActivatedLicenses && availableSeats === 0) {
      noAgentAlertDownloadTarget = "/upsell-promotion";
    } else {
      noAgentAlertDownloadTarget = "/downloadlander";
    }
  }

  return (
    <Container data-qatag="summary.containerElement">
      <WelcomeMessage
        data-qatag="summary.welcome.message"
        variant="body1"
      >
        {/* eslint-disable-next-line solveiq/data-qatag */}
        <FormattedMessage
          id="summary.welcomeMessage"
          defaultMessage={"Welcome back, {firstName}!"}
          values={{
            firstName: currentUser?.firstName,
          }}
        />
      </WelcomeMessage>
      {showNoAgentAlert && (
        <AgentNotRunningAlert
          data-qatag="summary.agentNotRunningAlert"
          severity="info"
        >
          {isWindows ? (
            // eslint-disable-next-line solveiq/data-qatag
            <FormattedMessage
              id="summary.agentNotRunning.isWindows"
              defaultMessage="You are logged in on a PC without the DriverSupport app installed.<br></br>To enable services on this PC <DownloadLink>click here to download and install the app</DownloadLink>."
              values={{
                // eslint-disable-next-line solveiq/data-qatag
                br: () => <br />,
                DownloadLink: (linkText) => (
                  <StyledLink
                    data-qatag="summary.agentNotRunning.downloadLink"
                    component={RouterLink}
                    to={noAgentAlertDownloadTarget}
                  >
                    {linkText}
                  </StyledLink>
                ),
              }}
            />
          ) : (
            // eslint-disable-next-line solveiq/data-qatag
            <FormattedMessage
              id="summary.agentNotRunning.notWindows"
              defaultMessage="DriverSupport requires a Windows PC with the app installed in order to use all the features."
            />
          )}
        </AgentNotRunningAlert>
      )}
      <PageTilesGrid data-qatag="summary.pageTiles">
        <ServiceTiles data-qatag="summary.serviceTiles">
          <PageTile data-qatag="summary.standardService">
            <PageTileHeader data-qatag="summary.standardService.header">
              <Typography
                data-qatag="summary.standardService.header.title"
                component={"h2"}
                variant="h5"
              >
                {/* eslint-disable-next-line solveiq/data-qatag */}
                <FormattedMessage
                  id="summary.standardService"
                  defaultMessage="Standard Service Summary"
                />
              </Typography>
              <Typography
                data-qatag="summary.standardService.header.description"
                color="textSecondary"
                component="p"
                sx={{ fontStyle: "italic" }}
                variant="body1"
              >
                {/* eslint-disable-next-line solveiq/data-qatag */}
                <FormattedMessage
                  id="summary.standardServiceDescription"
                  defaultMessage="Includes current and previously installed PCs"
                />
              </Typography>
              <TileHeaderAux
                data-qatag="summary.standardService.header.dataRange"
                sx={{ fontStyle: "italic" }}
                variant="body2"
              >
                {/* eslint-disable-next-line solveiq/data-qatag */}
                <FormattedMessage
                  id="summary.standardService.dataRange"
                  defaultMessage="Last 60 Days"
                />
              </TileHeaderAux>
            </PageTileHeader>
            <StyledCardContent data-qatag="summary.standardService.cardContent">
              <Table data-qatag="summary.standardService.cardContent.table">
                <TableBody data-qatag="summary.standardService.cardContent.tableBody">
                  {standardServices.map((service) => (
                    // eslint-disable-next-line solveiq/no-empty-data-qatag
                    <ServiceItemRow
                      data-qatag={`ServiceItemRow-${service.id}`}
                      featureFlags={featureFlags}
                      isLicenseExpired={isLicenseExpired}
                      key={service.id}
                      serviceSummaryItem={service}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledCardContent>
            <StyledCardActions data-qatag="summary.standardService.cardActions">
              <StyledLink
                data-qatag="summary.standardService.accountManagementLink"
                component={RouterLink}
                to="/myaccount"
                underline="hover"
                variant="body2"
              >
                {/* eslint-disable-next-line solveiq/data-qatag */}
                <FormattedMessage
                  id="summary.accountManagement"
                  defaultMessage="Account & Billing Management"
                />
              </StyledLink>
            </StyledCardActions>
          </PageTile>
          <PremiumServicesTile data-qatag="summary.premiumService">
            <PageTileHeader data-qatag="summary.premiumService.header">
              <div data-qatag="summary.premiumService.header.container">
                <Typography
                  data-qatag="summary.premiumService.header.title"
                  component={"h2"}
                  variant="h5"
                >
                  {/* eslint-disable-next-line solveiq/data-qatag */}
                  <FormattedMessage
                    id="summary.premiumService"
                    defaultMessage="Premium Service Summary"
                  />
                </Typography>
                <Typography
                  data-qatag="summary.premiumService.header.description"
                  color="textSecondary"
                  component="p"
                  sx={{ fontStyle: "italic" }}
                  variant="body1"
                >
                  {/* eslint-disable-next-line solveiq/data-qatag */}
                  <FormattedMessage
                    id="summary.premiumServiceDescription"
                    defaultMessage="Includes current and previously installed PCs"
                  />
                </Typography>
              </div>
              {!isPremiumSubscription && !isLicenseExpired && (
                <UpgradeButtonContainer data-qatag="summary.premiumService.upgradeButtonContainer">
                  <UpgradeButton
                    data-qatag="summary.premiumService.upgradeButton"
                    color="success"
                    variant="contained"
                    onClick={() => navigate("/upsell-promotion")}
                  >
                    {/* eslint-disable-next-line solveiq/data-qatag */}
                    <FormattedMessage
                      id="summary.premiumService.upgradeButton"
                      defaultMessage="Upgrade To Premium"
                    />
                  </UpgradeButton>
                </UpgradeButtonContainer>
              )}
            </PageTileHeader>
            <StyledCardContent data-qatag="summary.premiumService.cardContent">
              <PremiumServiceTable
                data-qatag="summary.premiumService.cardContent.table"
                featureFlags={featureFlags}
                isLicenseExpired={isLicenseExpired}
                premiumServices={premiumServices}
              />
            </StyledCardContent>
          </PremiumServicesTile>
        </ServiceTiles>
        <ProtectedPCs data-qatag="summary.protectedPCs">
          <PageTileHeader data-qatag="summary.protectedPCs.header">
            <Typography
              data-qatag="summary.protectedPCs.header.title"
              component={"h2"}
              variant="h5"
            >
              {/* eslint-disable-next-line solveiq/data-qatag */}
              <FormattedMessage
                id="summary.protectedPCs"
                defaultMessage="Protected PCs"
              />
            </Typography>
          </PageTileHeader>
          <StyledCardContent data-qatag="summary.protectedPCs.cardContent">
            <ChartContainer data-qatag="summary.protectedPCs.chartContainer">
              <ActivatedLicensesChart
                data-qatag="summary.protectedPCs.chartComponent"
                orgLicenses={orgLicenses}
              />
            </ChartContainer>
            <ProtectedPCsSummary data-qatag="summary.protectedPCs.summary">
              <ProtectedPCsSummaryList data-qatag="summary.protectedPCs.summary.list">
                <ProtectedPCsSummaryListItem data-qatag="summary.protectedPCs.summary.listItem.protected">
                  <Typography
                    data-qatag="summary.protectedPCs.summary.protectedCount"
                    variant="h2"
                  >
                    {orgLicenses?.activatedLicenses.length ?? 0}
                  </Typography>
                  <Typography
                    data-qatag="summary.protectedPCs.summary.protectedLabel"
                    component={"span"}
                    variant="body2"
                  >
                    {/* eslint-disable-next-line solveiq/data-qatag */}
                    <FormattedMessage
                      id="summary.protectedPCs.summary.protected"
                      defaultMessage="Protected"
                    />
                  </Typography>
                </ProtectedPCsSummaryListItem>
                {/* eslint-disable-next-line solveiq/data-qatag */}
                <ProtectedPCsSummaryListItem aria-hidden="true">
                  {/* eslint-disable-next-line solveiq/data-qatag */}
                  <StyledDivider orientation="vertical" />
                </ProtectedPCsSummaryListItem>
                <ProtectedPCsSummaryListItem data-qatag="summary.protectedPCs.summary.listItem.invited">
                  <Typography
                    data-qatag="summary.protectedPCs.summary.invitedCount"
                    variant="h2"
                  >
                    {orgLicenses?.allocatedLicenses.length ?? 0}
                  </Typography>
                  <Typography
                    data-qatag="summary.protectedPCs.summary.invitedLabel"
                    component={"span"}
                    variant="body2"
                  >
                    {/* eslint-disable-next-line solveiq/data-qatag */}
                    <FormattedMessage
                      id="summary.protectedPCs.summary.invited"
                      defaultMessage="Invited"
                    />
                  </Typography>
                </ProtectedPCsSummaryListItem>
              </ProtectedPCsSummaryList>
            </ProtectedPCsSummary>
            <ProtectedPCsTable
              data-qatag="summary.protectedPCs.tableComponent"
              familyMembers={familyMembers}
            />
            <CardActions
              data-qatag="summary.protectedPCs.cardActions"
              sx={{ justifyContent: "center" }}
            >
              <StyledLink
                data-qatag="summary.protectedPCs.manageDevicesLink"
                component={RouterLink}
                to="/family"
                underline="hover"
                variant="body2"
              >
                {/* eslint-disable-next-line solveiq/data-qatag */}
                <FormattedMessage
                  id="summary.protectedPCs.manageDevices"
                  defaultMessage="Manage Devices"
                />
              </StyledLink>
            </CardActions>
          </StyledCardContent>
        </ProtectedPCs>
      </PageTilesGrid>
      <Footer data-qatag="summary.footer">
        {currentUser?.email && (
          <Typography
            data-qatag="summary.footer.yourAccount"
            color="textSecondary"
            component="p"
            sx={{ fontStyle: "italic" }}
            variant="body2"
          >
            {/* eslint-disable-next-line solveiq/data-qatag */}
            <FormattedMessage
              id="summary.footer.yourAccount"
              defaultMessage="Your Account: {emailAddress}"
              values={{
                emailAddress: currentUser?.email,
              }}
            />
          </Typography>
        )}
      </Footer>
    </Container>
  );
}
