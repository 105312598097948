import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/redux/store";
import { IOrganization } from "./IOrganization";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import RESTGatewayAPI from "api/gatewayAPI";
import { licenseApi } from "api/LicenseApi/LicenseApi";
import { autoInstallApi } from "api/AutoInstallApi/AutoInstallApi";

interface IOrganizationData {
  registrationKey: string;
  org: IOrganization | null;
}

export const fetchOrganizations = createAsyncThunk("organization/fetchOrganization", async () => {
  const response = await RESTGatewayAPI.get("/fakeApi/organization");
  return response.data.organizations;
});

export const allocateLicenseAndDownload = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("organization/allocateLicenseAndDownload", async (_, thunkApi) => {
  try {
    const dispatch = thunkApi.dispatch as AppDispatch;
    const productId = thunkApi.getState().application.data.productId;
    const loggedInUser = thunkApi.getState().session.data.loggedInUser;

    if (!loggedInUser) {
      throw new Error("Cannot allocate license for non logged in users");
    }

    const request = dispatch(
      licenseApi.endpoints.addAdditionalPC.initiate({
        Email: loggedInUser.email,
        ProductId: productId,
      })
    );

    try {
      await request.unwrap();
    } catch (error) {
      throw new Error("Error allocating license for user");
    }

    const request2 = dispatch(autoInstallApi.endpoints.getAutoInstall.initiate(loggedInUser.email));

    const apiResponse = await request2;

    if (apiResponse.isError) {
      request2.unsubscribe();
      throw new Error("Error generating download link");
    }

    request2.unsubscribe();
    const downloadLink = apiResponse.data;
    window.location.href = downloadLink;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to allocate license and download : ${error}`);
  }
});

const initialState: IReducerState<IOrganizationData> = {
  data: { registrationKey: "", org: null },
  status: {
    [fetchOrganizations.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetOrganizationState: (state) => {
      state.data = initialState.data;
    },
    setOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.data = { ...state.data, org: action.payload };
    },
    setRegistrationKey: (state, action: PayloadAction<string>) => {
      state.data = { ...state.data, registrationKey: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allocateLicenseAndDownload.fulfilled, (state) => {
      state.status[allocateLicenseAndDownload.typePrefix] = ReducerStatus.Succeeded;
    });
    builder.addCase(allocateLicenseAndDownload.pending, (state) => {
      state.status[allocateLicenseAndDownload.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(allocateLicenseAndDownload.rejected, (state) => {
      state.status[allocateLicenseAndDownload.typePrefix] = ReducerStatus.Failed;
    });
  },
});

export const { resetOrganizationState, setOrganization, setRegistrationKey } = organizationSlice.actions;

export const selectOrganization = (state: RootState) => state.organization;

export const selectAllocateAndDownloadStatus = (state: RootState) =>
  state.organization.status[allocateLicenseAndDownload.typePrefix];

export default organizationSlice.reducer;
