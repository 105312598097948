import { useCallback } from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { IReceipt } from "./types";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

export type TableRowStyledProps = {
  even?: boolean;
};

export type BillingHistoryContentProps = {
  receipts: IReceipt[];
  fetchInvoice: (fileId: string, invoiceName: string) => void;
};

//#region component styles

const Title = styled(Box)`
  flex-basis: 100%;
  font: normal normal 500 1.125rem Roboto;
  color: #535353;
  align-self: flex-start;
  max-height: 42px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 28px;
    font: normal normal 500 1.3125rem Roboto;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font: normal normal 500 1.5rem Roboto;
  }
`;

const BillingDate = styled(Box)`
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0px;
  color: #007dba;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 500 1.125rem Roboto;
  }
`;

const TableContainer = styled(Box)<TableRowStyledProps>`
  width: 100%;
  min-height: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
`;

const TableRow = styled(Box)<TableRowStyledProps>`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  width: 106%;
  padding: 0 3%;
  height: 60px;
  background-color: ${({ even }) => (even ? "#FAFAFA" : "#FFFFFF")};
  align-items: center;
  border-radius: 8px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 112%;
    padding: 0 6%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 106%;
    padding: 0 3%;
  }
`;

const TableHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 8px;
  font: normal normal 400 1rem Roboto;
  color: #535353;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 28px 0 24px;
    font: normal normal 500 1.125rem Roboto;
  }
`;

const DownloadInvoice = styled(Box)`
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0px;
  color: #007dba;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 500 1.125rem Roboto;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const BackButton = styled(AppButton)`
  width: 90%;
  max-width: 260px;
  outline: none;
  height: 44px;
  margin: 32px auto 28px;
  &:hover {
    opacity: 0.9;
  }
`;

//#endregion component styles

export function BillingHistoryContent({ receipts, fetchInvoice }: BillingHistoryContentProps) {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate("/myaccount");
  }, [navigate]);

  return (
    <>
      <Title data-qatag="title">
        <FormattedMessage
          data-qatag="layout.breadcrumbs.home"
          id="layout.title.billing"
          defaultMessage="Billing History"
        />
      </Title>
      <TableHeader data-qatag="tableHeader">
        <Box data-qatag="date.header">
          <FormattedMessage
            data-qatag="layout.breadcrumbs.billedDate"
            id="layout.title.billedDate"
            defaultMessage="Billed Date"
          />
        </Box>
        <Box data-qatag="invoice.header">
          <FormattedMessage
            data-qatag="layout.breadcrumbs.home"
            id="layout.title.invoice"
            defaultMessage="Invoice"
          />
        </Box>
      </TableHeader>
      <TableContainer data-qatag="table.container">
        {receipts?.map((receipt, index) => {
          // Create a new Date object with the input date string
          const inputDate = new Date(receipt?.invoiceDate);

          // Get the year, month, and day values from the input date
          const year = inputDate.getFullYear();
          const month = inputDate.getMonth() + 1; // add 1 because getMonth() returns a zero-based index
          const day = inputDate.getDate();

          // Create a string in the desired format using string interpolation
          const outputDateString = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
          return (
            // eslint-disable-next-line solveiq/no-empty-data-qatag
            <TableRow
              data-qatag={`billing-history.${receipt.invoiceDate}`}
              key={`billing-history.${receipt.invoiceDate}`}
              even={index % 2 === 0}
            >
              <BillingDate data-qatag="receipt.invoiceDate">{outputDateString}</BillingDate>
              <DownloadInvoice
                data-qatag="downloadButtonMobile"
                onClick={() => fetchInvoice(receipt?.invoiceFiles?.[0]?.pdfFileUrl, outputDateString)}
              >
                <FormattedMessage
                  data-qatag="billing.history.download"
                  id="billing.history.download"
                  defaultMessage="Download/View"
                />
              </DownloadInvoice>
            </TableRow>
          );
        })}
      </TableContainer>
      <BackButton
        ariaLabel="Go back"
        buttonType="button"
        data-qatag="BackButton"
        buttonStyle={AppButtonStyles.Green}
        onClick={handleGoBack}
      >
        <FormattedMessage
          data-qatag="billing.history.back-button"
          id="billing.history.back-button"
          defaultMessage="Go Back"
        />
      </BackButton>
    </>
  );
}
