import { SectionContainer } from "../components/StyledComponents";
import { Header } from "../components/MyAccountHeader";
import { getIconFromPaymentMethod } from "utils/getCreditCardIcon";
import { FormattedMessage } from "react-intl";
import { IPaymentMethod } from "model/billing/BillingSummaryModel";
import { QueryErrorResponse } from "api/types";
import styled from "@mui/styled-engine";

const StyledCardImage = styled("img")`
  margin-right: 28px;
  width: 44px;
`;
const CardInfoContainer = styled("div")`
  align-items: center;
  display: flex;
  padding: 12px 0;
`;

export interface IPaymentMethodSectionProps {
  fetchingPaymentMethodError: QueryErrorResponse;
  savedPaymentMethod?: IPaymentMethod;
}

export const PaymentMethodSection = ({
  fetchingPaymentMethodError,
  savedPaymentMethod,
}: IPaymentMethodSectionProps) => {
  const creditCard = savedPaymentMethod?.creditCard;
  const lastFour = creditCard?.cardNumber.replace(/\D/g, "");
  const ccImage = creditCard ? getIconFromPaymentMethod(creditCard.creditCardType) : "";

  return (
    <SectionContainer data-qatag="myAccount.paymentMethod">
      <Header
        data-qatag="myAccount.paymentMethod.header"
        hideActionButton={!!fetchingPaymentMethodError}
        hideTextLinkOnMobile={true}
        linkPath="/paymentmethod"
        linkText="Update Payment Method"
        showEditIcon={true}
        tag="myAccount.paymentMethod"
        title="Payment Method"
      />
      <CardInfoContainer data-qatag="myAccount.paymentMethod.infoContainer">
        {fetchingPaymentMethodError ? (
          <>
            <FormattedMessage
              id="myAccount.paymentMethod.cardInfo.error"
              data-qatag="myAccount.paymentMethod.cardInfo.error"
              defaultMessage="There was an error fetching payment info"
            />
          </>
        ) : savedPaymentMethod ? (
          <>
            <StyledCardImage
              data-qatag="myAccount.paymentMethod.info.cardImage"
              src={ccImage}
            />
            <FormattedMessage
              id="myAccount.paymentMethod.cardInfo"
              data-qatag="myAccount.paymentMethod.cardInfo"
              defaultMessage="{cardType} ****{lastFour}"
              values={{
                cardType: creditCard?.creditCardType,
                lastFour,
              }}
            />
          </>
        ) : null}
      </CardInfoContainer>
    </SectionContainer>
  );
};
