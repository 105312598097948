import { Spinner } from "dsoneweb.designsystem";

export const Loading: React.FC = () => {
  return (
    <Spinner
      data-qatag="loadingSpinner"
      isActive
      text="Loading..."
    />
  );
};
