import { Box, IconButton, Typography } from "@mui/material";

import { IDeviceInfo } from "model/scan/DeviceInfo";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DriverContactForm, IContactSubmitDetails } from "./DriverContactForm";
import { Close } from "@mui/icons-material";
import styled from "@mui/styled-engine";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 12px 24px 32px 24px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 24px 72px;
    width: 640px;
  }
`;

export interface IDriverContactViewProps {
  device: IDeviceInfo;
  initialName: string;
  initialEmail: string;
  onClose: () => void;
  onSubmit: (details: IContactSubmitDetails) => void;
}
export const DriverContactView: React.FC<IDriverContactViewProps> = (props: IDriverContactViewProps) => {
  return (
    <Container
      data-qatag="driver.install.contact"
      id="driver.install.contact"
    >
      <IconButton
        data-qatag="driver.contact.iconButton"
        onClick={() => {
          props.onClose();
        }}
        sx={{
          alignSelf: "flex-end",
          height: "24px",
          padding: "4px",
          width: "24px",
        }}
      >
        <Close data-qatag="driver.contact.form.closeButton" />
      </IconButton>

      <Typography
        data-qatag="drivers.install.contact.title_value"
        id="drivers.install.contact.title_value"
        variant="h4"
        sx={{ color: "#007DBA", fontSize: "1.5rem" }}
      >
        <FormattedMessage
          data-qatag="drivers.install.contact.title"
          id="drivers.install.contact.title"
          defaultMessage="Submit your Request"
        />
      </Typography>

      <Typography
        data-qatag="driver.install.contact.description_text"
        id="driver.install.contact.description_text"
        variant="body2"
        color="primary"
      >
        <FormattedMessage
          data-qatag="driver.install.contact.description"
          id="driver.install.contact.description"
          defaultMessage="What can we help with you today?"
        />
      </Typography>

      <DriverContactForm
        data-qatag="driver.contact.form.component"
        initialName={props.initialName}
        device={props.device}
        initialEmail={props.initialEmail}
        onSubmit={async (details: IContactSubmitDetails) => {
          await props.onSubmit(details);
        }}
      />
    </Container>
  );
};
//#endregion Component
