import { Box, Typography } from "@mui/material";
import warningIcon from "assets/icon/icon-warning.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../components/Spinner";
import styled from "@mui/styled-engine";

//#region Component Styles
const ModalRoot = styled(Box)`
  width: 501px;
  height: 344px;
  padding-top: ${(props) => props.theme.spacing(4)};
  padding-left: ${(props) => props.theme.spacing(5)};
  padding-right: ${(props) => props.theme.spacing(5)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 330px;
    height: 366px;
    padding-left: ${(props) => props.theme.spacing(0)};
    padding-right: ${(props) => props.theme.spacing(0)};
    padding-top: ${(props) => props.theme.spacing(4)};
  }
`;

const TitleContainer = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(4)};
`;

const DescriptionContainer = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(3)};
  margin-bottom: ${(props) => props.theme.spacing(0)};
  margin-left: ${(props) => props.theme.spacing(3)};
  margin-right: ${(props) => props.theme.spacing(3)};
`;

//#endregion Component Styles

//#region Component
export const UnknownInstaller: React.FC = () => {
  return (
    <ModalRoot
      data-qatag="driver.modal.install.unknown_installer"
      id="driver.modal.install.unknown_installer"
    >
      <img
        data-qatag="driver.modal.install.unknown_installer.icon"
        id="driver.modal.install.unknown_installer.icon"
        src={warningIcon}
        alt=""
      />

      <TitleContainer
        data-qatag="driver.modal.install.unknown_installer.title_container"
        id="driver.modal.install.unknown_installer.title_container"
      >
        <FormattedMessage
          data-qatag="driver.modal.install.unknown_installer.title"
          id="driver.modal.install.unknown_installer.title"
          defaultMessage="Please complete your installation inside the driver installation application."
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.unknown_installer.title.text"
              id="driver.modal.install.unknown_installer.title.text"
              variant="h5"
              color="textPrimary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </TitleContainer>
      <DescriptionContainer
        data-qatag="driver.modal.install.unknown_installer.description_container"
        id="driver.modal.install.unknown_installer.description_container"
      >
        <FormattedMessage
          data-qatag="driver.modal.install.unknown_installer.description"
          id="driver.modal.install.unknown_installer.description"
          defaultMessage="If you do not see the installer it may be behind the Driver Support One application or blinking in your TaskBar."
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.unknown_installer.description.text"
              id="driver.modal.install.unknown_installer.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </DescriptionContainer>
      <Spinner data-qatag="driver.modal.install.unknown_installer.spinner" />
    </ModalRoot>
  );
};
//#endregion Component
