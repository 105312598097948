import { Container, Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";

const HeaderContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-left: auto;
    margin-right: auto;
    padding: inherit;
    width: 100%;
  }
`;

const WelcomeText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.75rem;
  }
`;

const HeaderText = styled(Typography)`
  font-size: 1rem;
  text-align: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 1.125rem;
  }
`;

const ButtonTypography = styled(Typography)`
  font-weight: 600;
  line-height: 1;
`;

interface IFamilyHeaderProps {
  isLicenseExpired: boolean;
  setIsExpiredLicenseAlertOpen: Dispatch<SetStateAction<boolean>>;
  userFirstName: string;
}

export function FamilyHeader({ isLicenseExpired, setIsExpiredLicenseAlertOpen }: IFamilyHeaderProps) {
  const navigate = useNavigate();
  const handleOnAddANewPC = () => {
    if (isLicenseExpired) {
      setIsExpiredLicenseAlertOpen(true);
      return;
    }
    navigate("/addPcAddFamily");
  };

  return (
    <HeaderContainer data-qatag="family.management.headerContainer">
      <WelcomeText data-qatag="family.management.headerTextContainer">
        <FixedFormattedMessage
          id="family.management.headerText"
          data-qatag="family.management.headerText"
          defaultMessage="Add up to 10 devices!"
        />
      </WelcomeText>
      <HeaderText data-qatag="family.management.headerSubtextContainer">
        <FixedFormattedMessage
          id="family.management.headerSubtext"
          data-qatag="family.management.headerSubtext"
          defaultMessage="Making it easy to manage updates, security, and optimization in your home."
        />
      </HeaderText>
      <AppButton
        ariaLabel="add a new PC"
        buttonType="button"
        data-qatag="familyHeaderActionButton"
        buttonStyle={AppButtonStyles.Green}
        style={{
          height: "56px",
          margin: "24px 0",
          maxWidth: "380px",
          width: "100%",
        }}
        onClick={handleOnAddANewPC}
      >
        <ButtonTypography
          data-qatag="buttonText"
          variant="body2"
        >
          <FixedFormattedMessage
            id="familyAddFamilyMemberMessage"
            data-qatag="familyAddFamilyMemberMessage"
            defaultMessage="Add Another Device"
          />
        </ButtonTypography>
      </AppButton>
    </HeaderContainer>
  );
}
