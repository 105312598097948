import { Paper } from "@mui/material";
import styled from "@mui/styled-engine";
import dsOneLogo from "assets/icon/dsOne-logo-master.svg";
import { FormattedMessage } from "react-intl";

//#region component styles
const Container = styled(Paper)`
  margin-top: 52px;
  display: flex;
  width: 500px;
  height: 300px;
  padding: 52px 34px 72px 34px;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
`;

const Logo = styled("img")`
  margin-top: 24px;
  max-width: 172px;
  flex-basis: 100%;
`;

const Title = styled("h1")`
  margin-top: 18px;
  margin-bottom: 0px;
  color: #535353;
  font-family: Roboto;
  font-size: 1.5rem;
  font-weight: 500;
  flex-basis: 100%;
  text-align: center;
`;

const Subtitle = styled("h2")`
  margin: 4px auto 24px;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #535a6a;
  flex-basis: 100%;
  text-align: center;
`;

//#endregion component styles

export function CannotLicensePC() {
  return (
    <Container data-qatag="CannotLicensePCWrapper">
      <Logo
        data-qatag="logo"
        src={dsOneLogo}
        alt="dsOne Logo"
      />
      <Title data-qatag="cannotLicense--title-container">
        <FormattedMessage
          data-qatag="cannotLicense--title"
          id="cannotLicense--title"
          defaultMessage="Cannot License this PC"
        />
      </Title>
      <Subtitle data-qatag="cannotLicenseSubTitle">
        <FormattedMessage
          data-qatag="cannotLicense--subtitle"
          id="cannotLicense--subtitle"
          defaultMessage="Please contact the person that invited you"
        />
      </Subtitle>
    </Container>
  );
}
