import { useState } from "react";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { ExecuteQueryablePromise, PromiseState } from "utils/ExecuteQueryablePromise";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { useSelector } from "react-redux";
import { selectAppVersion } from "app/Agent/AgentSlice";
import { selectCurrentUser } from "session/SessionSlice";
import { selectCurrentUuid } from "app/redux/applicationSlice";

declare const productId: number;

function useLicenseCheck() {
  const [licenseCheckStatus, setLicenseCheckStatus] = useState<PromiseState>("idle");

  const agentVersion = useSelector(selectAppVersion);
  const currentUser = useSelector(selectCurrentUser);
  const uuid = useSelector(selectCurrentUuid);

  async function sendAgentLicenseCheck() {
    const hub = getSignalRHub();
    const signalRHub = hub.getInstance();
    const message: IServiceMessage = new ServiceMessage();
    message.Payload = {
      AgentVersion: agentVersion,
      MachineName: "",
      MachineString: "",
      ProductID: productId,
      RegistrationKey: currentUser?.registrationKey,
      ShowUI: false,
      Token: null,
      UUID: uuid,
    };
    message.MessageType = WSMessageType.CHECK_LICENSE;

    await signalRHub.SendAsync(message);
  }

  const onLicenseCheckClicked = () => {
    if (licenseCheckStatus !== "pending") {
      ExecuteQueryablePromise(sendAgentLicenseCheck, {
        setPromiseState: setLicenseCheckStatus,
        delayBeforeReturnMs: 5000,
      });
    }
  };

  return {
    licenseCheckStatus,
    onLicenseCheckClicked,
  };
}

export default useLicenseCheck;
