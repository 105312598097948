import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/redux/store";
import { fetchJittriggersScan } from "./OptimizationSlice";
import { getSoftware, getOptimizationStatus } from "./OptimizationSlice";
import { ReducerStatus } from "model/IReducerState";
import { ISoftware, TriggerInfo } from "./types";
import AppOptimizations from "./Optimization";
import { eConnectionStatus, selectSignalRHubConnectionStatus } from "app/Agent/AgentSlice";
import {
  fetchUserSettings,
  selectBrowsingProfileSettingValue,
  selectGamingProfileSettingValue,
  selectProductivityProfileSettingValue,
  updateUserSetting,
  selectStatus,
  resetStatus,
} from "features/settings/SettingsSlice";
import { UserSettingTypes } from "core/enumerations/UserSettingTypes";
import { IUserSettingInstance, ProfileState } from "model/messaging/messages/AppSettingsMessages";
import { Alert, AlertIcon, Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

type SettingsState = {
  productivity: boolean;
  gaming: boolean;
  browsing: boolean;
};

type SettingsAction = {
  type: string;
};

function reducer(state: SettingsState, action: SettingsAction) {
  switch (action.type) {
    case "UPDATE_PRODUCTIVITY":
      return { ...state, productivity: !state.productivity };
    case "UPDATE_GAMING":
      return { ...state, gaming: !state.gaming };
    case "UPDATE_BROWSING":
      return { ...state, browsing: !state.browsing };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
}

function OptimizationContainer() {
  const dispatch = useDispatch();
  /* Localization function and prefix */
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(fetchJittriggersScan());
  }, [dispatch]);

  /* Retrieve data from the store */
  /* Software */
  const software = useSelector<RootState>((state: RootState) => getSoftware(state)) as ISoftware;
  /* Software Status */
  const softwareStatus = useSelector<RootState>((state: RootState) => getOptimizationStatus(state)) as ReducerStatus;

  // 8 is ID for Gaming software
  const gamingSoftware = (software.TriggerInfo || [])?.filter(
    (info: TriggerInfo) => info?.JitTrigger?.ProfileFlag === 8
  );

  // 2 is ID for Productivity
  const productivitySoftware = (software.TriggerInfo || [])?.filter(
    (info: TriggerInfo) => info?.JitTrigger?.ProfileFlag === 2
  );

  // 4 is ID for Browsing
  const browsingSoftware = (software.TriggerInfo || [])?.filter(
    (info: TriggerInfo) => info?.JitTrigger?.ProfileFlag === 4
  );

  // Dependencies for the Settings Modal
  // Open Settings modal
  const [openSettings, setOpenSettings] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const settingsStatus = useSelector(selectStatus);
  const gamingSetting = useSelector(selectGamingProfileSettingValue);
  const browsingSetting = useSelector(selectBrowsingProfileSettingValue);
  const productivitySetting = useSelector(selectProductivityProfileSettingValue);

  const isSignalRConnected = useSelector(selectSignalRHubConnectionStatus) === eConnectionStatus.Connected;

  useEffect(() => {
    if (isSignalRConnected) {
      if (!gamingSetting) dispatch(fetchUserSettings());
    }
  }, [dispatch, isSignalRConnected, gamingSetting]);

  useEffect(() => {
    if (ReducerStatus[settingsStatus[fetchUserSettings.typePrefix]] === ReducerStatus.Idle) {
      dispatch(fetchUserSettings());
    }
  }, [dispatch, settingsStatus]);

  const [state, dispatchSettings] = useReducer(reducer, {
    productivity: !!productivitySetting,
    gaming: !!gamingSetting,
    browsing: !!browsingSetting,
  });

  const handleToggleProductivity = () => {
    dispatchSettings({ type: "UPDATE_PRODUCTIVITY" });
  };

  const handleToggleGaming = () => {
    dispatchSettings({ type: "UPDATE_GAMING" });
  };

  const handleToggleBrowsing = () => {
    dispatchSettings({ type: "UPDATE_BROWSING" });
  };

  // Update the settings after a fetchUserSettings call
  useEffect(() => {
    if (state.productivity !== !!productivitySetting) {
      dispatchSettings({ type: "UPDATE_PRODUCTIVITY" });
    }

    if (state.gaming !== !!gamingSetting) {
      dispatchSettings({ type: "UPDATE_GAMING" });
    }

    if (state.browsing !== !!browsingSetting) {
      dispatchSettings({ type: "UPDATE_BROWSING" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productivitySetting, gamingSetting, browsingSetting]);

  // function to dispatch the update setting action
  const handleUpdateSetting = () => {
    let UpdatedSettings: IUserSettingInstance[] = [];
    if (state.productivity !== !!productivitySetting) {
      UpdatedSettings = [
        ...UpdatedSettings,
        {
          CurrentSettingValue: productivitySetting ? ProfileState.Active : ProfileState.Disabled,
          NewSettingValue: state.productivity ? ProfileState.Active : ProfileState.Disabled,
          SettingType: UserSettingTypes.ProductivityState,
          SettingTypeName: UserSettingTypes[UserSettingTypes.ProductivityState],
        },
      ];
    }
    if (state.gaming !== !!gamingSetting) {
      UpdatedSettings = [
        ...UpdatedSettings,
        {
          CurrentSettingValue: gamingSetting ? ProfileState.Active : ProfileState.Disabled,
          NewSettingValue: state.gaming ? ProfileState.Active : ProfileState.Disabled,
          SettingType: UserSettingTypes.GamingState,
          SettingTypeName: UserSettingTypes[UserSettingTypes.GamingState],
        },
      ];
    }
    if (state.browsing !== !!browsingSetting) {
      UpdatedSettings = [
        ...UpdatedSettings,
        {
          CurrentSettingValue: browsingSetting ? ProfileState.Active : ProfileState.Disabled,
          NewSettingValue: state.browsing ? ProfileState.Active : ProfileState.Disabled,
          SettingType: UserSettingTypes.BrowsingState,
          SettingTypeName: UserSettingTypes[UserSettingTypes.BrowsingState],
        },
      ];
    }
    // dispatch the action if there is any change in the settings
    if (UpdatedSettings.length > 0) {
      dispatch(updateUserSetting({ UpdatedSettings }));
      //TODO: Remove this once we figured out how to get when the settings state request is completed
      setShowSpinner(true);
      setTimeout(() => {
        dispatch(fetchUserSettings());
        setOpenSettings(false);
        setShowSpinner(false);
      }, 5000);
    } else {
      //No changes made, close the modal
      setOpenSettings(false);
    }
  };

  return (
    <>
      <AppOptimizations
        data-qatag="AppOptimizations"
        software={software}
        browsingSoftware={browsingSoftware}
        productivitySoftware={productivitySoftware}
        gamingSoftware={gamingSoftware}
        softwareStatus={softwareStatus}
        //Dependencies for the Settings Modal
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
        gamingSetting={gamingSetting}
        toggleGaming={handleToggleGaming}
        productivitySetting={productivitySetting}
        toggleProductivity={handleToggleProductivity}
        browsingSetting={browsingSetting}
        toggleBrowsing={handleToggleBrowsing}
        handleUpdateSetting={handleUpdateSetting}
      />
      {/* Spinner*/}
      <Spinner
        data-qatag="loadingSpinner"
        isActive={showSpinner}
        text={formatMessage({
          id: `loadingSpinner`,
          defaultMessage: "Loading...",
        })}
      />

      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "UpdateInformation.errorButton.title",
          defaultMessage: "Something went wrong",
        })}
        text={formatMessage({
          id: "UpdateInformation.errorButton.subtitle",
          defaultMessage: "Please retry again.",
        })}
        buttonText={formatMessage({
          id: "UpdateInformation.errorButton.continue",
          defaultMessage: "Close",
        })}
        approveHandler={() => {
          dispatch(resetStatus());
        }}
        isOpen={ReducerStatus[settingsStatus[fetchUserSettings.typePrefix]] === ReducerStatus.Failed}
        modalMinHeight="388px"
        closeHandler={() => {
          dispatch(resetStatus());
        }}
      />
    </>
  );
}

export default OptimizationContainer;
