import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { Link } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import styled from "@mui/styled-engine";

const LinkStyled = styled(Link)`
  color: #007dba;
  cursor: pointer;
  font-size: 1rem;
  //font-style: ${(props) => (props.underline ? "underline" : "none")};
  font-weight: 600;
  text-decoration: ${(props) => (props.underline === "hover" ? "none" : "underline")};
`;

type FixedFormattedMessage = {
  id: string;
  "data-qatag": string;
  defaultMessage: string;
};

// temporary work-around (see https://github.com/yahoo/babel-plugin-react-intl/issues/119)
function FormattedMessageFixed(props: FixedFormattedMessage) {
  // eslint-disable-next-line solveiq/data-qatag
  return <FormattedMessage {...props} />;
}

export interface ILinkBlueAppButtonProps {
  ariaLabel: string;
  buttonType: "button" | "reset" | "submit";
  linkPath: string;
  linkText: string;
  tag: string;
  underline: boolean;
}
/**
 * Reusable component for specific link buttons in my account
 * @method LinkBlueAppButton
 * @returns {JSX.Element}
 */
export function LinkBlueAppButton({
  ariaLabel,
  buttonType,
  linkPath,
  linkText,
  tag,
  underline,
}: ILinkBlueAppButtonProps): JSX.Element {
  const navigate = useNavigate();
  const handleLinkButtonClick = (path: string) => {
    navigate(path);
  };
  return (
    <AppButton
      ariaLabel={ariaLabel}
      data-qatag="AppButton.LinkButton"
      buttonStyle={AppButtonStyles.LinkBlue}
      style={{ fontSize: "1rem", fontWeight: "600" }}
      buttonType={buttonType}
      onClick={() => handleLinkButtonClick(linkPath)}
    >
      <LinkStyled
        data-qatag="AppButton.LinkButton.link"
        underline={underline ? "always" : "hover"}
      >
        <FormattedMessageFixed
          id={`${tag}.linkText`}
          data-qatag="AppButton.LinkButton.link.text"
          defaultMessage={linkText}
        />
      </LinkStyled>
    </AppButton>
  );
}

/**
 * Reusable component for specific green link buttons in my account
 * @method CallToActionButton
 * @returns {JSX.Element}
 */

const StyledAppButton = styled(AppButton)`
  font-size: 1rem;
  font-weight: 600;
  height: auto;

  a:hover {
    text-decoration: none;
  }
`;
export interface ICallToActionButtonProps {
  ariaLabel: string;
  additionalStyles?: { [key: string]: string };
  buttonType: "button" | "reset" | "submit";
  linkPath: string;
  linkText: string;
  tag: string;
}
export const CallToActionButton = ({
  ariaLabel,
  additionalStyles = {},
  buttonType,
  linkPath,
  linkText,
  tag,
}: ICallToActionButtonProps) => {
  const navigate = useNavigate();
  const handleLinkButtonClick = (path: string) => {
    navigate(path);
  };

  return (
    <StyledAppButton
      ariaLabel={ariaLabel}
      data-qatag="AppButton.callToActionButton"
      buttonStyle={AppButtonStyles.Green}
      style={{ ...additionalStyles }}
      buttonType={buttonType}
      onClick={() => handleLinkButtonClick(linkPath)}
    >
      <Link
        data-qatag="AppButton.callToActionButton.Link"
        sx={{ color: "white", fontWeight: "500" }}
      >
        <FormattedMessageFixed
          id={`${tag}.linkText`}
          data-qatag="AppButton.callToActionButton.link.text"
          defaultMessage={linkText}
        />
      </Link>
    </StyledAppButton>
  );
};
