import { FormattedMessage } from "react-intl";
import { Box, IconButton, Dialog, Divider, useMediaQuery, useTheme } from "@mui/material";
import { AppButton, AppButtonStyles, OnOffSwitch } from "dsoneweb.designsystem";
import { Close as CloseIcon } from "@mui/icons-material";
import settingsIcon from "assets/icon/icn-settings.svg";
import browsingIcon from "assets/icon/icn-browsing-software.svg";
import productivityIcon from "assets/icon/icn-productivity-software.svg";
import gamingIcon from "assets/icon/icn-gaming-software.svg";
import { ProfileState } from "model/messaging/messages/AppSettingsMessages";
import styled from "@mui/styled-engine";

export type SettingsProps = {
  /* Controls to open the modal */
  isOpen: boolean;
  /* Callback to update the isOpen on the parent*/
  handleOnClose: () => void;
  gamingSetting: ProfileState;
  toggleGaming: (currentSettingValue: ProfileState) => void;
  productivitySetting: ProfileState;
  toggleProductivity: (currentSettingValue: ProfileState) => void;
  browsingSetting: ProfileState;
  toggleBrowsing: (currentSettingValue: ProfileState) => void;
  handleUpdateSetting: () => void;
};

//#region component styles

const Container = styled("div")`
  border-radius: 14px;
  height: 600px;
  background-color: white;
  outline: 0;
  overflow: scroll;
  ${({ theme }) => theme.breakpoints.up("md")} {
    overflow: visible;
  }
`;

const Content = styled("div")`
  margin: 24px 16px 56px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 24px 64px 56px;
  }
`;

const CloseIconContainer = styled("div")`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;
`;

const StyledCloseIcon = styled(IconButton)`
  width: 50px;
  height: 50px;
`;

const CustomButton = styled(AppButton)`
  width: 260px;
  outline: none;
  height: 44px;
`;

const SettingsText = styled("div")`
  max-width: 104px;
  width: 92px;
  color: ${({ theme }) => theme.palette.secondary.light};
  font: normal normal 500 1rem Roboto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 500 1.125rem Roboto;
  }
`;

const StyledSwitch = styled(OnOffSwitch)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    margin: 8px 0 0 4px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;

    > span {
      margin: 0 0 0 12px;
    }
  }
`;

const StyledPaper = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 744px;
    width: 100%;
    margin: 12px;
    padding-bottom: 20px;
    min-height: 760px;
    min-height: 90%;

    ${({ theme }) => theme.breakpoints.up("md")} {
      min-height: 560px;
    }
  }
`;

const ControllersContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 8px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: nowrap;
  }
`;

const ButtonText = styled("div")`
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0.16px;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  line-height: 1;
`;

const TitleText = styled("div")`
  color: ${({ theme }) => theme.palette.secondary.light};
  font: normal normal 600 1.125rem Roboto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal medium 1.5rem Roboto;
  }
`;

const SettingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  align-items: center;
  flex-wrap: nowrap;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: wrap;
  }
`;

const SettingTitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 8px;
`;

const TopDivider = styled(Divider)`
  margin-top: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 48px;
  }
`;

const BottomDivider = styled(Divider)`
  margin-top: 28px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 36px;
  }
`;

const DividerContainer = styled(Box)`
  height: 8px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 120px;
    margin-top: 64px;
    margin-bottom: 0;
    width: auto;
  }
`;

const SwitchContainer = styled(Box)`
  flex-basis: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 24px;
  }
`;

const CancelContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  text-decoration: underline;
  font: normal normal 500 1rem Roboto;
  color: #007dba;
`;

const CancelText = styled("div")`
  cursor: pointer;
`;

//#endregion component styles

function Settings({
  isOpen = true,
  handleOnClose,
  gamingSetting,
  toggleGaming,
  productivitySetting,
  toggleProductivity,
  browsingSetting,
  toggleBrowsing,
  handleUpdateSetting,
}: SettingsProps) {
  // Match Tablet size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledPaper
      data-qatag="settingsModal"
      open={isOpen}
      onClose={handleOnClose}
    >
      <Container data-qatag="container">
        <Content data-qatag="content">
          {/* Close Icon */}
          <CloseIconContainer data-qatag="closeIconContainer">
            <StyledCloseIcon
              data-qatag="closeIcon"
              aria-label="close"
              onClick={handleOnClose}
            >
              <CloseIcon data-qatag="close" />
            </StyledCloseIcon>
          </CloseIconContainer>

          {/* Title */}
          <Box
            data-qatag="titleContainer"
            textAlign="center"
          >
            <SettingTitleContainer data-qatag="SettingTitleContainerSii">
              <img
                data-qatag="settings"
                src={settingsIcon}
                alt="settings"
              />
              <TitleText data-qatag="titleText">
                <FormattedMessage
                  id="title"
                  data-qatag="title"
                  defaultMessage="App Optimization Settings"
                />
              </TitleText>
            </SettingTitleContainer>
            <Box
              data-qatag="title"
              mt={1}
            >
              <FormattedMessage
                id="settings.app.optimization.subtitle"
                data-qatag="settings.app.optimization.subtitle"
                defaultMessage="You have full control over which optimization profiles are
                  important to you, remember you can have them all set to <b>“On”,</b> we
                  only activate the profile when needed."
                values={{
                  b: (msg) => <b data-qatag="boldMessage">{msg}</b>,
                }}
              />
            </Box>
          </Box>

          {/* Divider */}
          <TopDivider data-qatag="TopDivider" />

          {/* Controllers */}
          <ControllersContainer data-qatag="root">
            {/*Gaming Software*/}
            <SettingContainer data-qatag="settingContainer">
              <Box
                data-qatag="gamingBox"
                textAlign="center"
                flexBasis="100%"
              >
                <img
                  data-qatag="gamingIcon"
                  src={gamingIcon}
                  alt="Gaming"
                />
              </Box>
              <Box
                data-qatag="gamingTextBox"
                textAlign="center"
              >
                <SettingsText data-qatag="gamingText">
                  <FormattedMessage
                    id="gamingSoftware"
                    data-qatag="gaming"
                    defaultMessage="Gaming Software"
                  />
                </SettingsText>
              </Box>
              <SwitchContainer data-qatag="switchContainer">
                <StyledSwitch
                  data-qatag="switch"
                  onClick={() => toggleGaming(gamingSetting)}
                  isDisabled={false}
                  isOnInitially={gamingSetting === ProfileState.Active}
                />
              </SwitchContainer>
            </SettingContainer>

            {/* Divider */}
            <DividerContainer data-qatag="dividerContainer">
              <Divider
                data-qatag="divider"
                orientation={isMobile ? "horizontal" : "vertical"}
              />
            </DividerContainer>

            {/*Productivity Software*/}
            <SettingContainer data-qatag="settingContainer">
              <Box
                data-qatag="productivityIconBox"
                textAlign="center"
                flexBasis="100%"
              >
                <img
                  data-qatag="productivityIcon"
                  src={productivityIcon}
                  alt="Productivity"
                />
              </Box>
              <Box
                data-qatag="settingsTextBox"
                textAlign="center"
              >
                <SettingsText data-qatag="settingsText">
                  <FormattedMessage
                    id="productivitySoftware"
                    data-qatag="settings"
                    defaultMessage="Productivity Software"
                  />
                </SettingsText>
              </Box>
              <SwitchContainer data-qatag="switchContainer">
                <StyledSwitch
                  data-qatag="switch"
                  onClick={() => toggleProductivity(productivitySetting)}
                  isDisabled={false}
                  isOnInitially={productivitySetting === ProfileState.Active}
                />
              </SwitchContainer>
            </SettingContainer>

            {/* Divider */}
            <DividerContainer data-qatag="dividerContainer">
              <Divider
                data-qatag="divider"
                orientation={isMobile ? "horizontal" : "vertical"}
              />
            </DividerContainer>

            {/*Browsing Software*/}
            <SettingContainer data-qatag="settingContainer">
              <Box
                data-qatag="browsingIconBox"
                textAlign="center"
                flexBasis="100%"
              >
                <img
                  data-qatag="browsingIcon"
                  src={browsingIcon}
                  alt="Browsing"
                />
              </Box>
              <Box
                data-qatag="browsingTextBox"
                textAlign="center"
              >
                <SettingsText data-qatag="browsingText">
                  <FormattedMessage
                    id="browsingSoftware"
                    data-qatag="browsing"
                    defaultMessage="Browsing Software"
                  />
                </SettingsText>
              </Box>
              <SwitchContainer data-qatag="switchContainer">
                <StyledSwitch
                  data-qatag="switch"
                  onClick={() => toggleBrowsing(browsingSetting)}
                  isDisabled={false}
                  isOnInitially={browsingSetting === ProfileState.Active}
                />
              </SwitchContainer>
            </SettingContainer>
          </ControllersContainer>

          {/* Divider */}
          <BottomDivider data-qatag="dividerBox" />

          <Box
            data-qatag="buttonContainer"
            width={1}
            textAlign="center"
            mt={5}
            mb={2}
          >
            <CustomButton
              ariaLabel="Close"
              buttonType="button"
              data-qatag="button"
              buttonStyle={AppButtonStyles.Green}
              onClick={handleUpdateSetting}
            >
              <ButtonText data-qatag="buttonTextLabel">
                <FormattedMessage
                  id="closeButtonText"
                  data-qatag="buttonText"
                  defaultMessage="Save Settings"
                />
              </ButtonText>
            </CustomButton>
          </Box>
          <CancelContainer data-qatag="cancelContainer">
            <CancelText
              data-qatag="cancelText"
              onClick={handleOnClose}
            >
              <FormattedMessage
                id="cancelText"
                data-qatag="cancel"
                defaultMessage="Cancel"
              />
            </CancelText>
          </CancelContainer>
        </Content>
      </Container>
    </StyledPaper>
  );
}

export default Settings;
