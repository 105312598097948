import { PayloadAction, TypedStartListening, createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";
import { allScansCompleted, startAllScans } from "features/scan/ScanSlice";
import { driverInstallRequested, requestDriverDownloads } from "features/driverInstall/Thunks";
import {
  completeFileCleanup,
  doFileCleanup,
  startFileCleanupScan,
  completeScan as completeFileCleanupScan,
} from "features/fileCleaning/FileCleaningSlice";
import { AppDispatch, RootState } from "./store";
import { AgentDriverInstallStatus, IDriverInstallUpdateMessage } from "model/driver/DriverUpdateModel";
import { downloadComplete, downloadProgressUpdated, driverInstallUpdated } from "features/driverInstall/DriverSlice";
import { eConnectionStatus } from "app/Agent/AgentSlice";

//delay between heartbeats configured via env
declare const agentHeartbeatDelay: number;

//listeners for stopping and starting heartbeats
export const heartBeatSendMiddleware = createListenerMiddleware();
export const heartBeatCancelMiddleware = createListenerMiddleware();
export const driverHeartBeatCancelMiddleware = createListenerMiddleware();
export const heartbeatDebounceMiddleware = createListenerMiddleware();

//typed listener
type AppStartListening = TypedStartListening<RootState, AppDispatch>;

//id of the interval that is sending heartbeats, persisted for clearing later
let heartBeatIntervalId: ReturnType<typeof setTimeout> | null = null;

//send heartbeat to agent
function sendAgentHeartbeat() {
  const hub = getSignalRHub();
  const srhub = hub.getInstance();
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.HEARTBEAT;

  srhub.SendAsync(message);
}

//typed startlistening methods
const startHeartbeatSendListening = heartBeatSendMiddleware.startListening as AppStartListening;
const startHeartBeatCancelListening = heartBeatCancelMiddleware.startListening as AppStartListening;
const startDriverHeartBeatCancelListening = driverHeartBeatCancelMiddleware.startListening as AppStartListening;
const startHeartbeatDebounce = heartbeatDebounceMiddleware.startListening as AppStartListening;

//listen for any of the async thunks in matcher and start sending heartbeats when we see them
startHeartbeatSendListening({
  matcher: isAnyOf(
    startAllScans.pending,
    driverInstallRequested.pending,
    doFileCleanup.pending,
    startFileCleanupScan.pending,
    requestDriverDownloads.pending,
    downloadProgressUpdated
  ),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (heartBeatIntervalId !== null) {
      clearInterval(heartBeatIntervalId);
      heartBeatIntervalId = null;
    }

    heartBeatIntervalId = setInterval(sendAgentHeartbeat, agentHeartbeatDelay);
  },
});

//listen for any of the async thunks/actions in matcher and stop sending heartbeats when we see them
startHeartBeatCancelListening({
  matcher: isAnyOf(allScansCompleted.pending, completeFileCleanup, completeFileCleanupScan, downloadComplete),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (heartBeatIntervalId !== null) {
      clearInterval(heartBeatIntervalId);
      heartBeatIntervalId = null;
    }
  },
});

function isDriverInstallComplete(action: PayloadAction<IDriverInstallUpdateMessage>): boolean {
  if (!action.payload.Status) {
    return false;
  }
  return action.payload.Status === AgentDriverInstallStatus.UpdateComplete;
}

//cancel the interval when a driverinstall completes
startDriverHeartBeatCancelListening({
  predicate: (action, currentState, previousState) => {
    //if our action is not a driverinstallupdated bail out
    if (!driverInstallUpdated.match(action)) {
      return false;
    }

    //test if the update message is an update completing
    return isDriverInstallComplete(action as PayloadAction<IDriverInstallUpdateMessage>);
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (heartBeatIntervalId !== null) {
      clearInterval(heartBeatIntervalId);
      heartBeatIntervalId = null;
    }
  },
});

// start a heartbeat when the app starts and when the user clicks around in the app
startHeartbeatDebounce({
  predicate: (action, currentState, previousState) => {
    return currentState.agent.data.connectionStatus === eConnectionStatus.Connected;
  },
  effect: async (action, listenerApi) => {
    listenerApi.unsubscribe();

    sendAgentHeartbeat();
    await listenerApi.delay(5000);

    listenerApi.subscribe();
  },
});
