import { Box, LinearProgress, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import { AppButton, AppButtonStyles, SimpleTextField } from "dsoneweb.designsystem";
import * as yup from "yup";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import { FormattedMessage } from "react-intl";
import styled from "@mui/styled-engine";

const StyledForm = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
`;
const StyledFormItems = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;
const StyledAppButton = styled(AppButton)`
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 24px;
  width: 280px;
`;

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Invalid email address"),
  details: yup.string().required("Description of the device issue is required"),
});

export interface IContactSubmitDetails {
  name: string;
  email: string;
  regarding: string;
  details: string;
  device: IDeviceInfo;
}

export interface IDriverContactForm {
  initialName: string;
  initialEmail: string;
  device: IDeviceInfo;
  onSubmit: (submitData: IContactSubmitDetails) => Promise<void>;
}

export const DriverContactForm: React.FC<IDriverContactForm> = (props: IDriverContactForm) => {
  return (
    <Formik
      data-qatag="driver.contact.form.root"
      initialValues={{
        email: props.initialEmail ?? "",
        name: props.initialName ?? "",
        regarding: props.device?.friendlyName ?? "",
        details: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await props.onSubmit({
          name: values.name,
          email: values.email,
          regarding: values.regarding,
          details: values.details,
          device: props.device,
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledForm
          data-qatag="driver.contact.form"
          id="driver.contact.form"
        >
          <StyledFormItems data-qatag="driver.contact.formItems">
            <Field
              data-qatag="driver.contact.form.field.name"
              id="driver.contact.form.field.name"
              component={SimpleTextField}
              name="name"
              type="text"
              label="Name"
              disabled
              readOnly={true}
            />

            <Field
              data-qatag="driver.contact.form.field.email"
              id="driver.contact.form.field.email"
              component={SimpleTextField}
              name="email"
              type="email"
              label="Email Address"
              disabled
              readOnly={true}
            />

            <Field
              data-qatag="driver.contact.form.field.regarding"
              id="driver.contact.form.field.regarding"
              component={SimpleTextField}
              name="regarding"
              type="text"
              label="Regarding"
              disabled
              readOnly={true}
            />

            <Field
              data-qatag="driver.contact.form.field.details"
              id="driver.contact.form.field.details"
              component={SimpleTextField}
              placeholder={"Tell us about your issue with this device."}
              type="text"
              label="Description"
              name="details"
              multiline
              rows={10}
            />
            {isSubmitting && (
              <LinearProgress
                data-qatag="driver.contact.form.spinner"
                id="driver.contact.form.spinner"
              />
            )}
          </StyledFormItems>

          <Typography data-qatag="submitMessage.typography">
            <FormattedMessage
              id="submitMessage.text"
              data-qatag="submitMessage.text"
              defaultMessage={
                "Please enter the details of your request. Our <b>Driver Support</b> staff will respond as soon as possible."
              }
              values={{
                b: (chunks) => <strong data-qatag="boldText">{chunks}</strong>,
              }}
            />
          </Typography>

          <StyledAppButton
            ariaLabel="Submit"
            buttonType="submit"
            data-qatag="driver.contact.form.submitButton"
            id="driver.contact.form.submitButton"
            buttonStyle={AppButtonStyles.Green}
            isDisabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <FormattedMessage
              data-qatag="driver.contact.form.submitButton.text"
              id="driver.contact.form.submitButton.text"
              defaultMessage="Submit"
            />
          </StyledAppButton>
        </StyledForm>
      )}
    </Formik>
  );
};
