import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import RESTGatewayAPI from "api/gatewayAPI";
import { licenseApi } from "api/LicenseApi/LicenseApi";
import { changeAgentConnection } from "app/redux/applicationSlice";
import { AppDispatch, RootState } from "app/redux/store";
import { IReducerState, ReducerStatus } from "model/IReducerState";
import IActivatedLicense from "model/license/IActivatedLicense";
import IAllocatedLicense from "model/license/IAllocatedLicense";
import { IUser } from "model/user/IUser";
import { User } from "oidc-client";

declare global {
  interface Window {
    LiveChatWidget: {
      call: (action?: string, data?: string) => void;
      diagnose: (r: string) => void;
      get: () => void;
      init: () => void;
      off: () => void;
      on: () => void;
      once: () => void;
      scriptTagVersion: () => void;
      _h: (e: string, t: string) => void;
      _v: string;
      _q: string[];
    };
  }
}
export interface ISessionState {
  accessToken: string;
  availableSeats: number;
  loggedInUser: IUser | null;
  currentRegKey: string;
  allActivatedLicenses: IActivatedLicense[];
  allAllocatedLicenses: IAllocatedLicense[];
  hasApplicationError: boolean;
  isAdmin: boolean;
  isBeta: boolean;
  isRemote: boolean;
  isPremiumSubscription: boolean;
}

// Extend the Window type to include the dataLayer property
type ExtendedWindow = Window & {
  dataLayer: Record<string, unknown>[];
};

export const loginSuccessful = createAsyncThunk<
  ISessionState,
  User,
  {
    state: RootState;
  }
>("session/loginSuccessful", async (user, thunkApi) => {
  const appDispatch = thunkApi.dispatch as AppDispatch;
  if (
    user == null ||
    user.access_token == null ||
    user.profile == null ||
    user.profile.OrganizationID == null ||
    user.profile.Email == null ||
    user.profile.Role == null
  ) {
    thunkApi.rejectWithValue(new Error("Required claims are missing from user token."));
  }

  // pull out access token from oidc user
  const accessToken = user.access_token;
  // pull out reg key from oidc user claims
  const regKey = user.profile.OrganizationID;
  // pull out email from oidc user claims
  const userEmail = user.profile.Email;
  // pull out roles from oidc user claims
  const roles: string[] = typeof user.profile.Role === "string" ? [user.profile.Role] : [...user.profile.Role];
  // check for admin role
  const hasAdminRole = roles.find((role) => {
    return role === "Admin";
  })
    ? true
    : false;

  // check for beta role
  const isBeta = roles.find((role) => {
    return role === "Beta";
  })
    ? true
    : false;

  let currentLicense = undefined;

  try {
    //set auth header
    RESTGatewayAPI.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    const url = `/api/user/${userEmail}`;
    const apiResponse = await RESTGatewayAPI.get(url);
    const currentUser = apiResponse.data as IUser;
    const userId = currentUser.id;

    const hasAdminAttribute = currentUser.attributes.split(",").find((attr) => {
      return attr === "Admin";
    })
      ? true
      : false;

    //get org licenses from RTK query
    const request = appDispatch(licenseApi.endpoints.getOrganizationLicenses.initiate());
    const result = await request;
    request.unsubscribe();

    if (result.isError || !result.data) {
      throw new Error("Error fetching organization licenses");
    }

    const orgLicenseData = result.data;
    const state = thunkApi.getState();
    const localAgentRegState = state.agent.data.localAgentRegState;
    const localAgentUuid = localAgentRegState?.UUID;
    const hasUnregisteredLocalAgent = localAgentRegState?.SvcLicenseState === "";
    const currentUuid = state.application.data.currentUuid || localAgentUuid;
    const hasLocalAgent = localAgentUuid != null;

    // we have an already existing uuid in the store, from a QS param or from local agent regstate
    // try to match with a uuid in the org license
    if (currentUuid != null) {
      // check activated licenses first
      currentLicense = orgLicenseData.activatedLicenses.find((al) => al.uuid === currentUuid && al.userID === userId);
      if (currentLicense === undefined) {
        // no activated licenses, so check to see if there's an allocated license
        currentLicense = orgLicenseData.allocatedLicenses.find((al) => al.enabled === true && al.userID === userId);
      }
    }

    // no existing uuid in store or the uuid we got doesnt match a license we have - fallback to using userId
    if (currentLicense == null) {
      // check activated licenses first
      currentLicense = orgLicenseData.activatedLicenses.find((al) => al.enabled === true && al.userID === userId);
      if (currentLicense === undefined) {
        // no activated licenses, so check to see if there's an allocated license
        currentLicense = orgLicenseData.allocatedLicenses.find((al) => al.enabled === true && al.userID === userId);
      }
    }

    //this is bad.  only way this could happen is some kind of hosed user account.
    if (currentLicense === undefined) {
      throw new Error("No valid license found on logged in user account");
    }

    const availableSeats = orgLicenseData.availableSeats ?? 0;
    const isPremiumSubscription = orgLicenseData.licenseTier?.featureFlags === "AllFeatures";

    //we have a new logged in activated user with a local agent lets connect them
    //if they have no local agent (or their local agent is unregistered) they will fall through to family or download screen
    //TODO: can probably improve this.  test to see if localagent is same as current License maybe?
    if (hasLocalAgent && !hasUnregisteredLocalAgent && currentLicense?.uuid !== undefined) {
      thunkApi.dispatch(changeAgentConnection(currentLicense?.uuid));
    }

    if (window.LiveChatWidget != null) {
      window.LiveChatWidget.call("set_customer_name", `${currentUser.firstName} ${currentUser.lastName}`);
      window.LiveChatWidget.call("set_customer_email", currentUser.email);
    }

    //set current user in sentry for exception reporting
    Sentry.setUser({ email: currentUser.email });

    const isAdmin = hasAdminRole || hasAdminAttribute;

    // Import dataLayer from Google Tag Manager
    const extendedWindow = window as unknown as ExtendedWindow;
    const dataLayer = extendedWindow?.dataLayer || [];

    // Push a login event to the GTM
    dataLayer.push({
      event: "UILoginSuccessful",
      userEmail,
      isAdmin,
    });

    return {
      ...thunkApi.getState().session.data,
      accessToken,
      availableSeats,
      loggedInUser: currentUser,
      currentRegKey: regKey,
      allActivatedLicenses: orgLicenseData.activatedLicenses,
      allAllocatedLicenses: orgLicenseData.allocatedLicenses,
      currentLicense,
      isAdmin,
      isBeta,
      isPremiumSubscription,
    } as ISessionState;
  } catch (error) {
    return thunkApi.rejectWithValue(`Unable to login on loginSuccessful:${error}`);
  }
});

const initialState: IReducerState<ISessionState> = {
  data: {
    accessToken: "",
    availableSeats: 0,
    loggedInUser: null,
    currentRegKey: "",
    allActivatedLicenses: [],
    allAllocatedLicenses: [],
    hasApplicationError: false,
    isBeta: false,
    isAdmin: false,
    isRemote: false,
    isPremiumSubscription: false,
  },
  status: {
    [loginSuccessful.typePrefix]: ReducerStatus.Idle,
  },
  error: undefined,
};

export const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    resetSessionState: (state) => {
      state.data = initialState.data;
    },
    reportApplicationError: (state, action: PayloadAction<Error>) => {
      Sentry.captureException(action.payload);
      state.data.hasApplicationError = true;
    },
    updateLoggedInUser: (state, action: PayloadAction<IUser>) => {
      state.data.loggedInUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginSuccessful.pending, (state, action) => {
      state.status[loginSuccessful.typePrefix] = ReducerStatus.Loading;
    });
    builder.addCase(loginSuccessful.rejected, (state, action) => {
      state.status[loginSuccessful.typePrefix] = ReducerStatus.Failed;
      state.error = action.payload as string;
    });
    builder.addCase(loginSuccessful.fulfilled, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          accessToken: action.payload.accessToken,
          availableSeats: action.payload.availableSeats,
          loggedInUser: action.payload.loggedInUser,
          allActivatedLicenses: action.payload.allActivatedLicenses,
          allAllocatedLicenses: action.payload.allAllocatedLicenses,
          currentRegKey: action.payload.currentRegKey,
          isAdmin: action.payload.isAdmin,
          isBeta: action.payload.isBeta,
          isPremiumSubscription: action.payload.isPremiumSubscription,
        },
        status: {
          ...state.status,
          [loginSuccessful.typePrefix]: ReducerStatus.Succeeded,
        },
      };
    });
  },
});

// actions
export const { resetSessionState, reportApplicationError, updateLoggedInUser } = SessionSlice.actions;

// selectors
export const selectAccessToken = (state: RootState) => state.session.data.accessToken;

export const selectCurrentUser = (state: RootState) => {
  return state.session.data.loggedInUser;
};

export const selectCurrentRegKey = (state: RootState) => state.session.data.currentRegKey;

export const selectLoginSuccessfulStatus = (state: RootState) => state.session.status[loginSuccessful.typePrefix];

export const selectSessionStatus = (state: RootState) => state.session.status;

export const selectError = (state: RootState) => state.session.error;

export const selectHasApplicationError = (state: RootState) => state.session.data.hasApplicationError;

// based on active licenses only for backwards compatibility with existing
// code that makes that assumption
export const selectAllLicenses = (state: RootState) => state.session.data.allActivatedLicenses;

export const selectAllocatedLicenses = (state: RootState) => state.session.data.allAllocatedLicenses;

export const selectIsAdmin = (state: RootState) => state.session.data.isAdmin;

export const selectIsBeta = (state: RootState) => state.session.data.isBeta;

export const selectUserDescriptor = (state: RootState) => {
  const user = selectCurrentUser(state);
  if (user) {
    return `${user.firstName} ${user.lastName}'s - ${user.machineDisplayName}`;
  }
  return "";
};

export const selectIsPremiumSubscription = (state: RootState) => state.session.data.isPremiumSubscription;

export const selectAvailableSeats = (state: RootState) => state.session.data.availableSeats;

export default SessionSlice.reducer;
