import { Box, Typography } from "@mui/material";
import verifyingInstall from "assets/icon/icn-verifying.svg";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../components/Spinner";
import styled from "@mui/styled-engine";

const StyledContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  height: 380px;
  width: 660px;
`;
const StyledImage = styled("img")`
  margin-top: 12px;
  max-width: 87px;
`;

//#region Component
export const InstallVerifying: React.FC = () => {
  return (
    <StyledContainer
      data-qatag="driver.modal.install.verifying"
      id="driver.modal.install.verifying"
    >
      <StyledImage
        data-qatag="driver.modal.install.verifying.icon"
        id="driver.modal.install.verifying.icon"
        src={verifyingInstall}
        alt="verifying install icon"
      />

      <FormattedMessage
        data-qatag="driver.modal.install.verifying.title"
        id="driver.modal.install.verifying.title"
        defaultMessage="Verifying your driver install..."
      >
        {(text) => (
          <Typography
            data-qatag="driver.modal.install.verifying.title.text"
            id="driver.modal.install.verifying.title.text"
            variant="h5"
            color="textPrimary"
            sx={{ fontSize: "1.5rem" }}
          >
            {text}
          </Typography>
        )}
      </FormattedMessage>
      <Box
        data-qatag="driver.modal.install.verifying.description_container"
        id="driver.modal.install.verifying.description_container"
      >
        <FormattedMessage
          data-qatag="driver.modal.install.verifying.description"
          id="driver.modal.install.verifying.description"
          defaultMessage="Please wait while your driver installation is verified."
        >
          {(text) => (
            <Typography
              data-qatag="driver.modal.install.verifying.description.text"
              id="driver.modal.install.verifying.description.text"
              variant="body2"
              color="primary"
              align="center"
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
      <Spinner data-qatag="driver.modal.install.verifying.spinner" />
    </StyledContainer>
  );
};
//#endregion Component
