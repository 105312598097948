import { IDeviceInfo } from "model/scan/DeviceInfo";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { driverInstallRequested, requestDownloadCancel, requestDriverDownloads } from "../../Thunks";
import { Device } from "./Device";
import { IDriverUpdateModel } from "model/driver/DriverUpdateModel";
import React from "react";
import { selectCurrentUser } from "session/SessionSlice";
import { selectAgentInfo, selectIsSignalRConnectionLocalAgent } from "app/Agent/AgentSlice";
import { reportFullStoryEvent } from "ui.common";
import useAmplitude from "hooks/useAmplitude";
import { useNavigate } from "react-router";

export interface IDeviceContainerProps {
  device: IDeviceInfo;
  statusMap: IDriverUpdateModel[];
}

//#endregion Component Props

//#region Component
export const DeviceContainer: React.FC<IDeviceContainerProps> = ({ device, statusMap }) => {
  const { track } = useAmplitude();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const agentInfo = useSelector(selectAgentInfo);
  const isAgentLocal = useSelector(selectIsSignalRConnectionLocalAgent);

  let model = null;
  if (device.hasUpdateAvailable) {
    model = statusMap.find((u) => u.driverId === device.latesetDriverGuid);
    if (model == null) {
      // throw new Error(
      //   "Unable to find an update status for device: " +
      //     device.deviceID +
      //     " that has an update available"
      // );
      return null;
    }
  }

  const onInstall = () => {
    dispatch(driverInstallRequested(device.deviceID));

    const reportValues = {
      "Device Name": device.friendlyName,
      "Installed Driver Version": device.installedDriverVersion,
      "Latest Driver Version": device.latestDriverVersion,
      "Registration Key": currentUser?.registrationKey ?? "null",
      "Agent Version": agentInfo?.AppVersion,
      Channel: agentInfo?.RegState.Channel,
    };

    track("Driver Install Requested", reportValues);
    try {
      reportFullStoryEvent("Driver Install Requested", reportValues);
    } catch (ex) {
      Sentry.captureException(ex, { level: "error" });
    }
  };

  const onDownload = () => {
    dispatch(requestDriverDownloads([device.deviceID]));
  };

  const onAbout = () => {
    const prefix = device.hasUpdateAvailable ? "out of date" : "up to date";
    navigate(`/support?deviceDescription=${prefix} ${device.description}`);
  };

  const onDownloadCancel = () => {
    dispatch(requestDownloadCancel(device.latesetDriverGuid));
  };
  return (
    <Device
      data-qatag="driver.device_view.device_entry"
      device={device}
      driverUpdateModel={model}
      isAgentLocal={isAgentLocal}
      onInstall={onInstall}
      onDownload={onDownload}
      onAbout={onAbout}
      onDownloadCancel={onDownloadCancel}
      onDownloadRetry={onDownload}
    />
  );
};
