import { useNavigate } from "react-router";
import LicenseLimitScreen from "./LicenseLimitScreen";

function LicenseLimitScreenContainer() {
  const navigate = useNavigate();

  return (
    <LicenseLimitScreen
      data-qatag="LicenseLimitScreenContainer.screen"
      handleBack={() => navigate(-1)}
    />
  );
}

export default LicenseLimitScreenContainer;
