import deceptorImage from "assets/img/deceptor/icn-deceptors.svg";
import allowedImage from "assets/img/deceptor/icn-no-deceptors-found.svg";
import backArrow from "assets/img/deceptor/icn-back-arrow.png";
import infoImage from "assets/img/info-icon.png";
import appEsteemImage from "assets/img/deceptor/AppEsteem.png";
import { Typography, Divider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { useState } from "react";
import { IDeceptorInfo } from "./DeceptorProtectionSlice";
import { useNavigate } from "react-router";
import useQuery from "hooks/useQuery";
import { Layout } from "components/Layout/Layout";
import { Spinner } from "dsoneweb.designsystem";
import { IBlockedProcessCollectionMessage } from "./DeceptorProtectionMessages";
import styled from "@mui/styled-engine";

export interface IDeceptorDetailsProps {
  deceptor: IDeceptorInfo | null;
  handleAllowOrBlock: () => void;
  isLoading: boolean;
  historical: IBlockedProcessCollectionMessage | null;
  blocked: IDeceptorInfo[] | null;
}

//#region styles

const Header = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 28px;
  }
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: 70%;
    flex-wrap: nowrap;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: 80%;
  }
`;

const DeceptorImage = styled("img")`
  flex-basis: 10%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: unset;
    flex-basis: unset;
    width: 44px;
    height: 44px;
    margin-right: 28px;
  }
`;

const DeceptorImageToHide = styled("img")`
  flex-basis: 10%;
  width: 24px;
  height: 24px;
  margin-right: 12px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
    width: 44px;
    height: 44px;
    margin-right: 28px;
  }
`;

const TitleText = styled("div")`
  font: normal normal 500 1.125rem Roboto;
  color: #007dba;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 600 1.3125rem Roboto;
    flex-basis: unset;
    flex-basis: 72%;
  }
`;

const FilePathText = styled("div")`
  flex-basis: 100%;
  margin-top: 12px;
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
  word-break: break-all;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 4px;
    padding-right: 12px;
    flex-basis: unset;
  }
`;

const DividerBottom = styled("div")`
  width: 100%;
  flex-basis: 100%;
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 0px;
    display: none;
  }
`;

const DividerHeader = styled("div")`
  width: 100%;
  flex-basis: 100%;
  margin-top: 20px;
`;

const TitleHeader = styled("div")`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const AllowSoftwareContainer = styled("div")`
  margin-top: 16px;
  font: normal normal 500 1rem Roboto;
  color: #535a6a;
  display: flex;
  gap: 6px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 8px;
  }
`;

const AllowSoftwareText = styled("div")`
  text-decoration: underline;
  font: normal normal 500 1rem Roboto;
  color: #007dba;
  cursor: pointer;
`;

const NotRecommendedText = styled("div")`
  font: italic normal 500 0.875rem Roboto;
  color: #dd4a21;
`;

const ButtonContainer = styled("div")`
  flex-basis: 100%;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: 30%;
    margin: unset;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: 20%;
  }
`;

const StyledAppButton = styled(AppButton)`
  height: 44px;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0.24px;
  color: #ffffff;
  width: 252px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 200px;
    width: 100%;
  }
`;

const BlockedByAntivirusContainer = styled("div")`
  margin-top: 20px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`;

const BlockedByAntivirusText = styled("div")`
  font: normal normal 600 1.125rem Roboto;
  color: #007dba;
`;

const AntiVirusList = styled("div")`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 32px;
  }
`;

const AntiVirusListItem = styled("div")`
  text-align: left;
  font: normal normal normal 1rem Roboto;
  color: #535a6a;
  flex-basis: 100%;
  margin-bottom: 12px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: calc((100% - 28px) / 4);
    padding-right: 8px;
  }
`;

const PartnerLogoContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-top: 44px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: space-between;
  }
`;

const AppEsteemHoverContainer = styled("div")`
  position: absolute;
  width: 240px;
  top: 50%;
  left: 100px;
  box-shadow: 0px 4px 12px #00000026;
  border: 1px solid #00000000;
  background: #ffffff;
  border-radius: 14px;
  padding: 20px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 632px;
    left: 320px;
  }
`;

const AppEsteemHoverName = styled("span")`
  font-weight: 600;
  margin-right: 0.25rem;
`;

const InfoImage = styled("img")`
  width: 14px;
  height: 14px;
`;

const AppEsteemImage = styled("img")`
  width: 96px;
  height: 21px;
`;

const ArrowImage = styled("img")`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const BackButton = styled("div")`
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
    text-decoration: underline;
    font: normal normal 500 1rem Roboto;
    letter-spacing: 0px;
    color: #007dba;
  }
`;

const BackButtonToHide = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  font: normal normal 500 1rem Roboto;
  letter-spacing: 0px;
  color: #007dba;
  margin-left: auto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

const AppEsteemLogo = styled("div")`
  order: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    order: 2;
  }
`;

const ReasonsToBlock = styled("div")`
  order: 2;
  margin-top: 20px;
  font: normal normal 500 1.125rem Roboto;
  color: #007dba;

  ${({ theme }) => theme.breakpoints.up("md")} {
    order: 1;
    margin-top: 0;
  }
`;

const ReasonsToBlockTitle = styled("div")`
  margin-top: 20px;
  font: normal normal 500 1.125rem Roboto;
  color: #007dba;
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 28;
  }
`;

const ReasonsToBlockDescription = styled("div")`
  margin-top: 12px;
  font: normal normal 400 1rem Roboto;
  color: #535a6a;
`;

//#endregion

export function DeceptorDetails({
  deceptor,
  handleAllowOrBlock,
  isLoading,
  historical,
  blocked,
}: IDeceptorDetailsProps) {
  const { formatMessage } = useIntl();
  const query: URLSearchParams = useQuery();
  const id = query?.get("id") || "";
  const navigate = useNavigate();

  // If the app has a record in historical software we will use the information
  // for the app violations and possible antivirus blocking.
  // Comparison needs to be case-insensitive since filepath casing is not consistent.
  // Have to compare file name since file path is the only linking datum between dupes
  // and historical data.
  // Also note that the keys on historical are the filepaths for some reason.
  // That's just how the data comes in.¯\_(ツ)_/¯
  const lowerPath = deceptor?.FilePath.toLowerCase() ?? "";
  const index = lowerPath.lastIndexOf("\\");
  const fileName = lowerPath.slice(index + 1);
  const key = historical ? (Object.keys(historical).find((key) => key.toLowerCase().endsWith(fileName)) ?? "") : "";
  const historicalApp = historical?.[key]?.[0];

  //Check if the deceptor is Blocked
  const isBlocked = blocked?.some((app: IDeceptorInfo) => app.InstanceID === id);

  const isAllowApp = deceptor?.IsAllowed;

  const allowText = formatMessage({
    id: "deceptorProtection.view.allowSoftware",
    defaultMessage: "Allow Software",
  });
  const blockText = formatMessage({
    id: "deceptorProtection.view.blockSoftware",
    defaultMessage: "Block Software",
  });
  const buttonText = deceptor?.IsAllowed ? blockText : allowText;

  const [showAppEsteemHover, setShowAppEsteemHover] = useState(false);

  // Data to be render
  // AntiVirus who block the app
  const antiVirusList = deceptor?.AvBlockList?.length ? deceptor?.AvBlockList : historicalApp?.AvBlockList;

  const reasonsToBlock = {
    ...historicalApp?.AppEsteemNonDeceptorViolations,
    ...historicalApp?.AppEsteemViolations,
  };

  if (!deceptor) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive={isLoading}
        text={formatMessage({
          id: `allowing.spinner`,
          defaultMessage: "Loading...",
        })}
      />
    );
  }

  return (
    <Layout
      data-qatag="deceptorDetailsPageContainer"
      qatag="deceptorDetailsPageContainer"
    >
      <div data-qatag="container">
        <Header data-qatag="header">
          <TitleContainer data-qatag="titleContainer">
            <DeceptorImage
              data-qatag="deceptorImage"
              src={isAllowApp ? allowedImage : deceptorImage}
              alt="Deceptor"
            />
            <TitleHeader data-qatag="TitleHeader">
              <DeceptorImageToHide
                data-qatag="deceptorImage"
                src={isAllowApp ? allowedImage : deceptorImage}
                alt="Deceptor"
              />
              <TitleText data-qatag="TitleText">{deceptor?.Name}</TitleText>

              {isAllowApp ? (
                <BackButtonToHide
                  data-qatag="BackButton"
                  onClick={() => navigate("/deceptorprotection?tab=allowed")}
                >
                  <ArrowImage
                    src={backArrow}
                    alt="Back"
                    data-qatag="ArrowImage"
                  />
                  <FormattedMessage
                    data-qatag="allowSoftwareMessage"
                    id="deceptorProtection.view.details.back-option"
                    defaultMessage="Back"
                  />
                </BackButtonToHide>
              ) : null}
              {/* Divider */}
              <DividerBottom data-qatag="dividerBottom">
                <Divider data-qatag="Divider" />
              </DividerBottom>

              <FilePathText data-qatag="TitleText">
                <FormattedMessage
                  data-qatag="filePathMessage"
                  id="deceptorProtection.view.details.filePath"
                  defaultMessage="<b>File Path:</b> {filePath}"
                  values={{
                    filePath: deceptor?.FilePath,
                    b: (msg) => <b data-qatag="bold">{msg}</b>,
                  }}
                />
              </FilePathText>
              {isBlocked ? (
                <AllowSoftwareContainer data-qatag="AllowSoftwareContainer">
                  <AllowSoftwareText
                    data-qatag="allowSoftwareText"
                    onClick={handleAllowOrBlock}
                  >
                    <FormattedMessage
                      data-qatag="allowSoftwareMessage"
                      id="deceptorProtection.view.details.allowSoftware"
                      defaultMessage="Allow Software"
                    />
                  </AllowSoftwareText>{" "}
                  -{" "}
                  <NotRecommendedText data-qatag="allowSoftwareText">
                    <FormattedMessage
                      data-qatag="allowSoftwareMessage"
                      id="deceptorProtection.view.details.allowSoftware"
                      defaultMessage="(Not recommended)"
                    />
                  </NotRecommendedText>
                </AllowSoftwareContainer>
              ) : null}
            </TitleHeader>
          </TitleContainer>

          <ButtonContainer data-qatag="buttonContainer">
            <StyledAppButton
              ariaLabel={buttonText}
              buttonType="button"
              data-qatag="actionButton"
              buttonStyle={AppButtonStyles.Green}
              onClick={isAllowApp ? handleAllowOrBlock : () => navigate("/deceptorprotection")}
            >
              {isAllowApp ? (
                <FormattedMessage
                  data-qatag="allowSoftwareMessage"
                  id="deceptorProtection.view.details.block"
                  defaultMessage="Block This App"
                />
              ) : (
                <FormattedMessage
                  data-qatag="allowSoftwareMessage"
                  id="deceptorProtection.view.details.back"
                  defaultMessage="Back"
                />
              )}
            </StyledAppButton>
            {isAllowApp ? (
              <BackButton
                data-qatag="BackButton"
                onClick={() => navigate("/deceptorprotection?tab=allowed")}
              >
                <ArrowImage
                  src={backArrow}
                  alt="Back"
                  data-qatag="ArrowImage"
                />
                <FormattedMessage
                  data-qatag="allowSoftwareMessage"
                  id="deceptorProtection.view.details.back-option"
                  defaultMessage="Back"
                />
              </BackButton>
            ) : null}
          </ButtonContainer>
        </Header>

        {/* Divider */}
        <DividerHeader data-qatag="DividerHeader">
          <Divider data-qatag="Divider" />
        </DividerHeader>

        <BlockedByAntivirusContainer data-qatag="BlockedByAntivirusContainer">
          <BlockedByAntivirusText data-qatag="blockedByAntivirusText">
            <FormattedMessage
              data-qatag="blockedByAntivirusMessage"
              id="deceptorProtection.view.details.blockedByAntivirus"
              defaultMessage="Blocked by Anti-Virus programs:"
            />
          </BlockedByAntivirusText>
          <AntiVirusList data-qatag="avList">
            {antiVirusList?.map((name) => {
              return (
                <AntiVirusListItem
                  data-qatag="avListItem"
                  key={name}
                >
                  {name.replace(/\([^)]*\)/g, "").trim()}
                </AntiVirusListItem>
              );
            })}
          </AntiVirusList>
        </BlockedByAntivirusContainer>

        {/* Divider */}
        <DividerHeader data-qatag="DividerHeader">
          <Divider data-qatag="Divider" />
        </DividerHeader>

        <PartnerLogoContainer
          data-qatag="appEsteemInfoContainer"
          onMouseEnter={() => setShowAppEsteemHover(true)}
          onMouseLeave={() => setShowAppEsteemHover(false)}
        >
          <ReasonsToBlock data-qatag="ReasonsToBlock">
            <FormattedMessage
              data-qatag="appEsteemHoverNameMessage"
              id="deceptorProtection.view.reasonsToBlock"
              defaultMessage="Reasons To Block"
            />
          </ReasonsToBlock>
          <AppEsteemLogo data-qatag="appEsteemLogo">
            {showAppEsteemHover ? (
              <AppEsteemHoverContainer data-qatag="appEsteemHoverContainer">
                <div data-qatag="appEsteemHoverText">
                  <AppEsteemHoverName data-qatag="appEsteemHoverName">
                    <FormattedMessage
                      data-qatag="appEsteemHoverNameMessage"
                      id="deceptorProtection.view.appEsteemHover.name"
                      defaultMessage="AppEsteem"
                    />
                  </AppEsteemHoverName>
                  <FormattedMessage
                    data-qatag="appEsteemHoverMessage"
                    id="deceptorProtection.view.appEsteemHover.text"
                    defaultMessage="hunts for apps and websites they believe are misleading, cheating, and deceiving consumers. They use their publicly available checklist to do an evaluation, and when they find a deceptor, they publish the app or site, along with the reasons why they listed it, on their own deceptor page. This page was generated from AppEsteem’s publicly available data."
                  />
                </div>
              </AppEsteemHoverContainer>
            ) : null}
            <InfoImage
              data-qatag="infoImage"
              src={infoImage}
              alt="info"
            />
            <Typography
              data-qatag="poweredBy"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="poweredByMessage"
                id="deceptorProtection.view.details.poweredBy"
                defaultMessage="Powered By: "
              />
            </Typography>
            <AppEsteemImage
              data-qatag="appEsteemImage"
              src={appEsteemImage}
              alt="appEsteem"
            />
          </AppEsteemLogo>
        </PartnerLogoContainer>
        {Object.keys(reasonsToBlock).map((key) => {
          const reason = reasonsToBlock[key];
          return (
            <>
              <ReasonsToBlockTitle data-qatag="ReasonsToBlockTitle">{key}</ReasonsToBlockTitle>
              <ReasonsToBlockDescription data-qatag="ReasonsToBlockDescription">{reason}</ReasonsToBlockDescription>
            </>
          );
        })}
      </div>
      {/* Spinner*/}
      <Spinner
        data-qatag="loadingSpinner"
        isActive={isLoading}
        text={formatMessage({
          id: `allowing.spinner`,
          defaultMessage: "Loading...",
        })}
      />
    </Layout>
  );
}
