import { Box, Typography, styled } from "@mui/material";

export const Header = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;

  img {
    max-width: 48px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: flex-start;

    img {
      max-width: 120px;
    }
  }
`;

export const HeaderTitle = styled("div")`
  color: #007dba;
  font-size: 1.125rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.5rem;
  }
`;
export const HeaderSubText = styled(Typography)`
  font-size: 1rem;
`;

export const ImageContainer = styled(Box)`
  display: flex;
  gap: 16px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: unset;
  }
`;
