import React from "react";
import { OnOffSwitch } from "dsoneweb.designsystem";
import { Box } from "@mui/material";
import { IUserSettingModel } from "model/messaging/messages/AppSettingsMessages";
import { LabelValuePair } from "features/SubscriptionManagement/MyAccount/components/LabelValuePair";
import { SettingsToUpdateType } from "../SettingsContainer";
import styled from "@mui/styled-engine";

const TogglesContainer = styled(Box)`
  padding: 32px 0;
  width: 100%;
`;

const PreferNewUIWindow = styled("div")`
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1.5px solid #eeeeee;
`;

export type ToggleProps = {
  settingsObj: { [key: string]: IUserSettingModel };
  updateSettings: (settingsToUpdate: SettingsToUpdateType[]) => void;
};
export function Toggles({ settingsObj, updateSettings }: ToggleProps) {
  return (
    <TogglesContainer data-qatag="TogglesContainer">
      <LabelValuePair
        data-qatag="settings.startWithWindows"
        invertBgColors={true}
        label="Start With Windows"
        tag="settings.startWithWindows"
        value={settingsObj.StartWithWindows.SettingValue}
      >
        <OnOffSwitch
          data-qatag="startWithWindowsSwitch"
          onClick={(event: React.MouseEvent<HTMLElement>, isOn: boolean) => {
            updateSettings([
              {
                newValue: isOn,
                userSettingObj: settingsObj.StartWithWindows,
              },
            ]);
          }}
          isOnInitially={settingsObj.StartWithWindows?.SettingValue}
        />
      </LabelValuePair>
      <LabelValuePair
        data-qatag="settings.EnableAlerts"
        invertBgColors={true}
        label="Notifications"
        tag="settings.EnableAlerts"
        value={settingsObj.EnableAlerts.SettingValue}
      >
        <OnOffSwitch
          data-qatag="EnableAlertsSwitch"
          onClick={(event: React.MouseEvent<HTMLElement>, isOn: boolean) => {
            updateSettings([
              {
                newValue: isOn,
                userSettingObj: settingsObj.EnableAlerts,
              },
            ]);
          }}
          isOnInitially={settingsObj.EnableAlerts?.SettingValue}
        />
      </LabelValuePair>
      <PreferNewUIWindow data-qatag="settings.preferNewUIWindowContainer">
        <LabelValuePair
          data-qatag="settings.preferNewUIWindow"
          invertBgColors={true}
          label="Open Driver Support Tab In New Window"
          tag="settings.preferNewUIWindow"
          value={settingsObj.PreferNewUIWindow.SettingValue}
        >
          <OnOffSwitch
            data-qatag="preferNewUIWindowSwitch"
            onClick={(event: React.MouseEvent<HTMLElement>, isOn: boolean) => {
              updateSettings([
                {
                  newValue: isOn,
                  userSettingObj: settingsObj.PreferNewUIWindow,
                },
              ]);
            }}
            isOnInitially={settingsObj.PreferNewUIWindow?.SettingValue}
          />
        </LabelValuePair>
      </PreferNewUIWindow>
    </TogglesContainer>
  );
}
