import { styled } from "@mui/material";
import { Layout } from "components/Layout/Layout";
import { useSelector } from "react-redux";
import { selectCurrentUuid } from "app/redux/applicationSlice";

declare const printerChatUrl: string;

const StyledIframe = styled("iframe")`
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  height: calc(100vh - 340px);
  width: 100%;
`;

export default function AddPrinter() {
  const uuid = useSelector(selectCurrentUuid);
  const builtUrl = `${printerChatUrl}?uuid=${uuid}`;
  return (
    <Layout
      data-qatag="layout"
      qatag="layout"
      useTemplating={true}
    >
      <Layout.Content
        data-qatag="add-printer.layoutContent"
        minHeight="none"
      >
        <StyledIframe
          data-qatag="add-printer.iframe"
          src={builtUrl}
          title="Printer Install Assistant"
        />
      </Layout.Content>
    </Layout>
  );
}
