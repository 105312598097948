import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

export type DriverContactSentProps = {
  closeHandler: () => void;
};

export const DriverContactSent: React.FC<DriverContactSentProps> = ({ closeHandler }) => {
  const { formatMessage } = useIntl();
  return (
    <Alert
      data-qatag="alertSuccessDriverContact"
      icon={AlertIcon.Success}
      title={formatMessage({
        id: "alert.driverContact.sentModal.title",
        defaultMessage: "Submission Complete!",
      })}
      text={formatMessage({
        id: "alert.driverContact.sentModal.message",
        defaultMessage: "Thank you for submitting your request.",
      })}
      buttonText={formatMessage({
        id: "alert.driverContact.sentModal.close",
        defaultMessage: "Close",
      })}
      approveHandler={closeHandler}
      isOpen={true}
      closeHandler={closeHandler}
    />
  );
};
