import { useLocation } from "react-router-dom";
import { useMemo } from "react";

/**
 * A custom hook that builds on `useLocation` to parse
 * queryParams from the urlString
 *
 * @method useQuery
 * @returns {URLSearchParams}
 */
export default function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
