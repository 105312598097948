import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

export type ExpiredLicenseDialogProps = {
  isAdmin: boolean;
  isOpen: boolean;
  adminName: string;
  onApprove: () => void;
  onClose: () => void;
  onCancel?: () => void;
};

export function ExpiredLicenseDialog({
  isAdmin,
  isOpen,
  adminName,
  onApprove,
  onClose,
  onCancel,
}: ExpiredLicenseDialogProps) {
  const { formatMessage } = useIntl();

  const title = formatMessage({
    id: "features.status.expiredAlert.title",
    defaultMessage: "Active License Required",
  });

  const adminText = formatMessage({
    id: "features.status.expiredAlert.adminMessage",
    defaultMessage: "Please renew your license to access this feature.",
  });

  const familyText = formatMessage(
    {
      id: "features.status.expiredAlert.familyMemberMessage",
      defaultMessage: "{admin} needs to renew your license.",
    },
    { admin: adminName }
  );

  const modalText = isAdmin ? adminText : familyText;

  return isAdmin ? (
    <Alert
      data-qatag="subscription.expired.alert"
      title={title}
      icon={AlertIcon.Warning}
      text={modalText}
      approveHandler={onApprove}
      buttonText={formatMessage({
        id: "features.status.expiredAlert.renew",
        defaultMessage: "Renew",
      })}
      cancelButtonText={formatMessage({
        id: "features.status.expiredAlert.renewLater",
        defaultMessage: "Renew Later",
      })}
      isOpen={isOpen}
      closeHandler={onClose}
      cancelHandler={onCancel}
    />
  ) : (
    <Alert
      data-qatag="subscription.expired.alert"
      title={title}
      icon={AlertIcon.Warning}
      text={modalText}
      approveHandler={onApprove}
      buttonText={formatMessage({
        id: "close",
        defaultMessage: "Close",
      })}
      isOpen={isOpen}
      closeHandler={onClose}
    />
  );
}
