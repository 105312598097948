import { useCancelSubscriptionMutation } from "api/SubscriptionApi/SubscriptionApi";
import { useProvideFeedbackMutation } from "api/SalesforceApi/SalesforceApi";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "session/SessionSlice";
import { FeedbackForm, ICancellationFeedbackResponse, IFeedbackForm } from "./FeedbackForm";
import * as Sentry from "@sentry/react";
import { IProvideFeedbackPayload } from "model/feedbackForm/FeedbackForm";
import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

export function FeedbackFormContainer() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const [provideFeedback] = useProvideFeedbackMutation();
  //Flag to display error alert
  const [isError, setIsError] = useState(false);
  // handle reset error
  const handleResetError = () => {
    setIsError(false);
  };

  const props: IFeedbackForm = {
    handleSubmit: useCallback(
      async (values: ICancellationFeedbackResponse) => {
        const payload: IProvideFeedbackPayload = {
          Email: user?.email ?? "null",
          Name: `${user?.firstName} ${user?.lastName}`,
          CancelReason: values.reason,
          Message: values.comments,
        };
        //submit feedback first so we can capture errors, but don't block the cancel
        //since App Esteem probably wouldn't like that
        await provideFeedback(payload)
          .unwrap()
          .catch((ex) => {
            Sentry.captureException(new Error(`Failed to submit cancel feedback`), {
              level: "error",
              extra: { exception: ex, payload: payload },
            });
          });
        await cancelSubscription()
          .unwrap()
          .then((val) => {
            navigate("/cancelledConfirmation");
          })
          .catch((ex) => {
            Sentry.captureException(new Error(`Failed to cancel subscription`), {
              level: "error",
              extra: { exception: ex, user: user },
            });
            //TODO: product team will determine what should happen when it errors
            //for now, just display an error alert
            setIsError(true);
          });
      },
      [cancelSubscription, navigate, provideFeedback, user]
    ),
    handleKeep: () => {
      navigate("/status");
    },
  };
  return (
    <>
      <FeedbackForm
        data-qatag="FeedbackForm"
        {...props}
      />
      {/* Alert error */}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "feedbackForm.errorButton.title",
          defaultMessage: "Something went wrong",
        })}
        text={formatMessage({
          id: "feedbackForm.errorButton.subtitle",
          defaultMessage: "Please retry again.",
        })}
        buttonText={formatMessage({
          id: "feedbackForm.errorButton.continue",
          defaultMessage: "Close",
        })}
        modalMinHeight="388px"
        approveHandler={handleResetError}
        isOpen={isError}
        closeHandler={handleResetError}
      />
    </>
  );
}
