import {
  Box,
  Container,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import CloseIcon from "@mui/icons-material/Close";
import {
  DayOfWeek,
  IScheduledScanOptionsMessage,
  IUserSettingModel,
  ScanPeriod,
} from "model/messaging/messages/AppSettingsMessages";
import { SettingsToUpdateType } from "../SettingsContainer";
import { FormattedMessage, useIntl } from "react-intl";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { CustomizedSelect } from "components/Select/Select";
import { useState } from "react";
import styled from "@mui/styled-engine";

const DialogContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 330px;
  padding: 16px 24px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-width: 560px;
  }
`;
const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const HeaderTitle = styled(Box)`
  align-self: center;
  color: #017dba;
  display: flex;
  font-size: 1.125rem;
  gap: 8px;

  h6 {
    font-weight: 600;
    margin-bottom: 0;
  }
`;
const CloseIconButton = styled(IconButton)`
  align-self: flex-end;
  color: #535a6a;
  padding: 0;
`;
const StyledDivider = styled(Divider)`
  color: #fafafa;
  height: 2px;
  margin: 24px;
  width: 310px;
`;
const RadioFormControl = styled(FormControl)`
  margin-bottom: 8px;
  width: 344px;

  label {
    color: #535a6a;
    font-size: 1rem;

    &.MuiFormLabel-colorPrimary {
      color: #017dba;
      font-weight: 600;
    }

    .MuiRadio-root {
      color: #767e93;

      &.Mui-checked {
        color: #017dba;
      }
    }
  }

  .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const InputGroup = styled(Box)`
  display: flex;
  gap: 44px;
  margin: 0 12px;
  width: 344px;

  div {
    width: 150px;
  }

  label {
    font-size: 1rem;
    margin-bottom: 12px;
  }

  .MuiOutlinedInput-root,
  input {
    height: 38px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 0 98px;
  }
`;
const TimeFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column;

  input[type="time"]::-webkit-calendar-picker-indicator {
    height: 20px;
    filter: invert(31%) sepia(86%) saturate(1088%) hue-rotate(171deg) brightness(100%) contrast(99%);
    width: 20px;
  }
`;
const SelectControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  button {
    width: 260px;
  }
`;
export type ScheduledScanDialogProps = {
  isOpen: boolean;
  onCloseHandler: () => void;
  onScanNowClicked: () => void;
  settingsObj: { [key: string]: IUserSettingModel };
  scheduledScanOptions: IScheduledScanOptionsMessage;
  updateSettings: (settingsToUpdate: SettingsToUpdateType[]) => void;
};
export function ScheduledScanDialog({
  isOpen,
  onCloseHandler,
  onScanNowClicked,
  scheduledScanOptions,
  settingsObj,
  updateSettings,
}: ScheduledScanDialogProps) {
  const [scanFrequency, setScanFrequency] = useState(
    scheduledScanOptions.Enabled ? scheduledScanOptions.Period : "disabled"
  );
  const [scanEnabled, setScanEnabled] = useState(scheduledScanOptions.Enabled);
  const [scanDayOfMonth, setScanDayOfMonth] = useState(scheduledScanOptions.DayOfMonth);
  const [scanDayOfWeek, setScanDayOfWeek] = useState(scheduledScanOptions.DayOfWeek);
  const [scanTime, setScanTime] = useState(scheduledScanOptions.ScanTime);
  const { formatMessage } = useIntl();
  const dayOfMonthOptions = Array.from({ length: 28 }, (v, k) => {
    return {
      value: (k + 1).toString(),
      label: (k + 1).toString(),
    };
  });
  const dayOfWeekOptions = [
    {
      value: DayOfWeek.Sunday,
      label: formatMessage({
        id: `days.sunday`,
        defaultMessage: "Sunday",
      }),
    },
    {
      value: DayOfWeek.Monday,
      label: formatMessage({
        id: `days.monday`,
        defaultMessage: "Monday",
      }),
    },
    {
      value: DayOfWeek.Tuesday,
      label: formatMessage({
        id: `days.tuesday`,
        defaultMessage: "Tuesday",
      }),
    },
    {
      value: DayOfWeek.Wednesday,
      label: formatMessage({
        id: `days.wednesday`,
        defaultMessage: "Wednesday",
      }),
    },
    {
      value: DayOfWeek.Thursday,
      label: formatMessage({
        id: `days.thursday`,
        defaultMessage: "Thursday",
      }),
    },
    {
      value: DayOfWeek.Friday,
      label: formatMessage({
        id: `days.friday`,
        defaultMessage: "Friday",
      }),
    },
    {
      value: DayOfWeek.Saturday,
      label: formatMessage({
        id: `days.saturday`,
        defaultMessage: "Saturday",
      }),
    },
  ].map(({ value, label }) => ({ value: value.toString(), label }));
  const onFrequencyChanged = (value: string) => {
    if (value === "disabled") {
      setScanEnabled(false);
      setScanFrequency(value);
    } else {
      setScanEnabled(true);
      setScanFrequency(Number(value));
    }
  };
  const onDayOfMonthChanged = (value: string) => {
    setScanDayOfMonth(Number(value));
  };
  const onDayOfWeekChanged = (value: string) => {
    setScanDayOfWeek(Number(value));
  };
  const onSave = () => {
    const settingsToUpdate = [];
    const { EnableScheduledScans, ScheduledScanPeriod, ScheduledScanTime, ScheduledScanMonth, ScheduledScanDay } =
      settingsObj;

    settingsToUpdate.push(
      {
        userSettingObj: EnableScheduledScans,
        newValue: scanEnabled,
      },
      {
        userSettingObj: ScheduledScanPeriod,
        newValue: scanFrequency === "disabled" ? 0 : scanFrequency,
      },
      {
        userSettingObj: ScheduledScanTime,
        newValue: scanTime,
      },
      {
        userSettingObj: ScheduledScanMonth,
        newValue: scanDayOfMonth,
      },
      {
        userSettingObj: ScheduledScanDay,
        newValue: scanDayOfWeek,
      }
    );

    updateSettings(settingsToUpdate);
    onCloseHandler();
  };
  return (
    <Dialog
      data-qatag="ScheduledScanDialog"
      open={isOpen}
      maxWidth={"lg"}
    >
      <DialogContainer data-qatag="ScheduledScanDialog.container">
        <CloseIconButton
          data-qatag="ScheduledScanDialog.header.closeButton"
          aria-label="close"
          onClick={onCloseHandler}
        >
          <CloseIcon data-qatag="ScheduledScanDialog.header.closeIcon" />
        </CloseIconButton>
        <Header data-qatag="ScheduledScanDialog.header">
          <HeaderTitle data-qatag="ScheduledScanDialog.title">
            <EventRepeatIcon data-qatag="ScheduledScanDialog.header.icon" />
            <Typography
              data-qatag="ScheduledScanDialog.title.type"
              variant="h6"
            >
              <FormattedMessage
                data-qatag="ScheduledScanDialog.title.text"
                id="ScheduledScanDialog.title.text"
                defaultMessage="Scheduled Scan Options"
              />
            </Typography>
          </HeaderTitle>
        </Header>
        <StyledDivider data-qatag="ScheduledScanDialog.divider" />
        <RadioFormControl data-qatag="ScheduledScanDialog.formControl">
          <FormLabel
            data-qatag="ScheduledScanDialog.formLabel"
            id="radio-buttons-group-label"
          >
            <FormattedMessage
              data-qatag="ScheduledScanDialog.FormLabel.text"
              id="ScheduledScanDialog.FormLabel.text"
              defaultMessage="Scan Frequency"
            />
          </FormLabel>
          <RadioGroup
            data-qatag="ScheduledScanDialog.radioGroup"
            aria-labelledby="radio-buttons-group-label"
            defaultValue={scanFrequency}
            name="radio-buttons-group"
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => onFrequencyChanged(value)}
            sx={{ display: "flex" }}
          >
            <FormControlLabel
              data-qatag="ScheduledScanDialog.radio.monthly.label"
              value={ScanPeriod.Monthly}
              control={<Radio data-qatag="ScheduledScanDialog.radio.monthly" />}
              label="Monthly"
            />
            <FormControlLabel
              data-qatag="ScheduledScanDialog.radio.weekly.label"
              value={ScanPeriod.Weekly}
              control={<Radio data-qatag="ScheduledScanDialog.radio.weekly" />}
              label="Weekly"
            />
            <FormControlLabel
              data-qatag="ScheduledScanDialog.radio.disabled.disabled"
              value={"disabled"}
              control={<Radio data-qatag="ScheduledScanDialog.radio.disabled" />}
              label="Disabled"
            />
          </RadioGroup>
        </RadioFormControl>
        {scanEnabled ? (
          <InputGroup data-qatag="ScheduledScanDialog.inputGroup">
            {/* scan frequency = 0 is weekly*/}
            {scanFrequency === 0 ? (
              /* day of week */
              <SelectControl data-qatag="ScheduledScanDialog.dayOfWeekFormControl">
                <FormLabel
                  data-qatag="ScheduledScanDialog.dayOfWeekFormLabel"
                  id="radio-buttons-group-label"
                >
                  <FormattedMessage
                    data-qatag="ScheduledScanDialog.dayOfWeekFormLabel.text"
                    id="ScheduledScanDialog.dayOfWeekFormLabel.text"
                    defaultMessage="Scan Day of Week"
                  />
                </FormLabel>
                <CustomizedSelect
                  data-qatag="ScheduledScanDialog.dayOfWeek"
                  currentValue={scanDayOfWeek}
                  options={dayOfWeekOptions}
                  onChangeHandler={onDayOfWeekChanged}
                />
              </SelectControl>
            ) : (
              /* day of month */
              <SelectControl data-qatag="ScheduledScanDialog.dayOfMonthFormControl">
                <FormLabel
                  data-qatag="ScheduledScanDialog.dayOfMonthFormLabel"
                  id="radio-buttons-group-label"
                >
                  <FormattedMessage
                    data-qatag="ScheduledScanDialog.dayOfMonthFormLabel.text"
                    id="ScheduledScanDialog.dayOfMonthFormLabel.text"
                    defaultMessage="Scan Day of Month"
                  />
                </FormLabel>
                <CustomizedSelect
                  data-qatag="ScheduledScanDialog.dayOfMonth"
                  currentValue={scanDayOfMonth}
                  options={dayOfMonthOptions}
                  onChangeHandler={onDayOfMonthChanged}
                />
              </SelectControl>
            )}

            {/* scan time */}
            <TimeFormControl data-qatag="ScheduledScanDialog.scanTimeFormControl">
              <FormLabel
                data-qatag="ScheduledScanDialog.scanTimeFormLabel"
                id="radio-buttons-group-label"
              >
                <FormattedMessage
                  data-qatag="ScheduledScanDialog.scanTimeFormLabel.text"
                  id="ScheduledScanDialog.scanTimeFormLabel.text"
                  defaultMessage="Scan Time (Approx.)"
                />
              </FormLabel>
              <TextField
                data-qatag="ScheduledScanDialog.scanTime"
                value={scanTime}
                type="time"
                variant="outlined"
                onChange={(event) => {
                  setScanTime(event.target.value);
                }}
              />
            </TimeFormControl>
          </InputGroup>
        ) : null}

        <StyledDivider data-qatag="ScheduledScanDialog.divider" />
        <ButtonContainer data-qatag="ScheduledScanDialog.buttonContainer">
          <AppButton
            ariaLabel="Save"
            buttonType="button"
            data-qatag="ScheduledScanDialog.saveButton"
            buttonStyle={AppButtonStyles.Green}
            onClick={onSave}
          >
            <Typography
              data-qatag="ScheduledScanDialog.saveButtonTypography"
              variant="body2"
            >
              <FormattedMessage
                data-qatag="saveButtonMessage"
                id="ScheduledScanDialog.saveButton"
                defaultMessage="Save"
              />
            </Typography>
          </AppButton>
          <AppButton
            ariaLabel="Rescan my PC now"
            buttonType="button"
            data-qatag="ScheduledScanDialog.rescanButton"
            buttonStyle={AppButtonStyles.LinkBlue}
            onClick={onScanNowClicked}
          >
            <Typography
              data-qatag="ScheduledScanDialog.rescanButtonTypography"
              variant="body2"
              sx={{ fontWeight: "600", textDecoration: "underline" }}
            >
              <FormattedMessage
                data-qatag="rescanButtonMessage"
                id="ScheduledScanDialog.rescanButton"
                defaultMessage="Rescan My PC Now"
              />
            </Typography>
          </AppButton>
        </ButtonContainer>
      </DialogContainer>
    </Dialog>
  );
}
