import { Layout } from "components/Layout/Layout";
import { useIntl } from "react-intl";
import { IReceipt } from "./types";
import EmptyState from "features/Optimization/EmptyState/EmptyState";
import { Loading } from "main/Loading";
import { BillingHistoryContent } from "./BillingHistoryContent";

export type BillingHistoryViewProps = {
  receipts: IReceipt[];
  fetchInvoice: (fileId: string, invoiceName: string) => void;
  isLoading?: boolean;
};

export function BillingHistoryView({ receipts, fetchInvoice, isLoading }: BillingHistoryViewProps) {
  const { formatMessage } = useIntl();
  return (
    <Layout
      data-qatag="Layout"
      qatag="billingHistory"
      useTemplating={true}
    >
      <Layout.Content data-qatag="Layout.Content">
        {receipts.length > 0 ? (
          <BillingHistoryContent
            data-qatag="BillingHistoryContent"
            receipts={receipts}
            fetchInvoice={fetchInvoice}
          />
        ) : (
          <EmptyState
            data-qatag="EmptyState"
            error={formatMessage({
              id: "billingHistory.emptyState.error",
              defaultMessage: "No billing history found",
            })}
            suggestion={formatMessage({
              id: "billingHistory.emptyState.suggestion",
              defaultMessage:
                "To view your billing history, make sure you have completed at least one transaction. If you have recently created your account, it may take a few days for billing information to appear.",
            })}
          />
        )}

        {isLoading ? <Loading data-qatag="redirectLoading" /> : null}
      </Layout.Content>
    </Layout>
  );
}
