import React from "react";
import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

//#region Component Props
export interface IInstallCompleteProps {
  onContinue: () => void;
}
export const InstallComplete: React.FC<IInstallCompleteProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Alert
      data-qatag="installComplete.alert"
      title={formatMessage({
        id: "updatedSuccessfully",
        defaultMessage: "Driver Successfully Updated!",
      })}
      icon={AlertIcon.Success}
      text={formatMessage({
        id: "installCompleted",
        defaultMessage: "Your driver install has completed!",
      })}
      approveHandler={props.onContinue}
      buttonText={formatMessage({
        id: "continue",
        defaultMessage: "Continue",
      })}
      isOpen={true}
      closeHandler={props.onContinue}
      modalMinHeight="350px"
    />
  );
};
