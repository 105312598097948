import { CSSProperties, useEffect, useState } from "react";
import { Paper, PaperProps, useMediaQuery, useTheme } from "@mui/material";
import styled from "@mui/styled-engine";
import blueHeader from "assets/img/blue-header.svg";
import NativeBreadcrumbs from "components/Breadcrumb/Breadcrumbs";

type heightProps = Pick<CSSProperties, "height" | "maxHeight" | "minHeight">;

type containerProps = heightProps & {
  hasFixedHeader?: boolean;
};

//#region component styles
const Container = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentRoot = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  width: 100%;
  height: 100%;
`;

const BackgroundHeader = styled("div")`
  background-image: url(${blueHeader});
  background-position-x: right;
  background-size: cover;
  display: flex;
  position: absolute;
  width: 100%;
  height: 208px;
  background-repeat: no-repeat;

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 214px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 302px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    height: 306px;
  }
`;

const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: nowrap;
  }
`;

const StyledPaper = styled(Paper)<containerProps>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => (height ? height : "auto")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "none")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "calc(100vh - 150px)")};
  margin: 18px auto auto;
  padding: 24px 18px;
  position: relative;
  width: 90%;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: 44px;

    ${({ hasFixedHeader }) =>
      hasFixedHeader &&
      `
      margin-top: 56px;
    `}
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 24px 64px;

    ${({ hasFixedHeader }) =>
      hasFixedHeader &&
      `
      margin-top: 200px;
    `}
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 1120px;
  }
`;

const BreadcrumbsContainer = styled("div")`
  width: 90%;
  max-width: 1120px;
  margin-top: 20px;
  align-self: center;
  z-index: 5;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: 32px;
  }
`;

// Define interface for component props
export type DetachedHeaderContainerProps = PaperProps & {
  fixed?: boolean;
};

const DetachedHeaderContainer = styled(Paper)<DetachedHeaderContainerProps>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  margin: 18px auto auto;
  position: relative;
  padding: 24px 18px;
  width: 90%;

  ${({ fixed }) =>
    fixed &&
    `
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 5;
      transition-property: all;
    `}

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: 44px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 24px 64px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    width: 1120px;
  }
`;

export type LayoutProps = {
  children?: React.ReactNode;
  qatag: string;
  useTemplating?: boolean;
};

/**
 * Reusable Layout Container for screens in DSOneWeb.App
 * @method Layout
 * @param {Object: LayoutProps} Props
 * @returns JSX
 */
export function Layout({ children, qatag, useTemplating }: LayoutProps) {
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <Container data-qatag={qatag}>
      {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
      <ContentRoot data-qatag={`${qatag}.contentRoot`}>
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <BackgroundHeader data-qatag={`${qatag}.backerRoot`} />
        {/* eslint-disable-next-line solveiq/no-empty-data-qatag */}
        <ContentContainer data-qatag={`${qatag}.contentContainer`}>
          {useTemplating ? (
            <>{children}</>
          ) : (
            /* eslint-disable-next-line solveiq/no-empty-data-qatag */
            <StyledPaper
              data-qatag={`${qatag}.paper`}
              elevation={0}
            >
              {children}
            </StyledPaper>
          )}
        </ContentContainer>
      </ContentRoot>
    </Container>
  );
}

export type BreadcrumbsProps = {
  children?: React.ReactNode;
  separatorColor?: string;
};

function Breadcrumbs({ children }: BreadcrumbsProps) {
  return (
    <BreadcrumbsContainer data-qatag="BreadcrumbsContainer">
      <NativeBreadcrumbs data-qatag="NativeBreadcrumbs">{children}</NativeBreadcrumbs>
    </BreadcrumbsContainer>
  );
}

export type DetachedHeaderProps = {
  children: React.ReactNode;
  qatag?: string;
};
function DetachedHeader({ children, qatag }: DetachedHeaderProps) {
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <DetachedHeaderContainer data-qatag={`${qatag}.detachedHeaderContainer`}>{children}</DetachedHeaderContainer>
  );
}

export type DetachedFixedHeaderProps = {
  children: React.ReactNode;
  qatag?: string;
};
function DetachedFixedHeader({ children, qatag }: DetachedFixedHeaderProps) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [marginTop, setMarginTop] = useState(largeScreen ? 44 : 0);
  const [top, setTop] = useState("auto");

  useEffect(() => {
    const handleScroll = () => {
      const newMarginTop = largeScreen ? Math.max(0, 44 - window.scrollY) : 0;

      setMarginTop(newMarginTop);

      const newTop = window.scrollY > 50 ? `${Math.max(0, 60 - window.scrollY)}px` : "auto";

      setTop(newTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [largeScreen]);

  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <DetachedHeaderContainer
      data-qatag={`${qatag}.detachedHeaderContainer`}
      fixed
      style={{ marginTop: `${marginTop}px`, top: `${top}` }}
    >
      {children}
    </DetachedHeaderContainer>
  );
}

export type ContentProps = heightProps & {
  children?: React.ReactNode;
  qatag?: string;
  hasFixedHeader?: boolean;
};

function Content({ children, qatag, hasFixedHeader, ...heightProps }: ContentProps) {
  return (
    // eslint-disable-next-line solveiq/no-empty-data-qatag
    <StyledPaper
      {...heightProps}
      data-qatag={`${qatag}.paper`}
      elevation={0}
      hasFixedHeader={hasFixedHeader}
    >
      {children}
    </StyledPaper>
  );
}

Layout.Breadcrumbs = Breadcrumbs;
Layout.BreadcrumbsLink = NativeBreadcrumbs.Link;
Layout.BreadcrumbsText = NativeBreadcrumbs.Text;
Layout.DetachedHeader = DetachedHeader;
Layout.DetachedFixedHeader = DetachedFixedHeader;
Layout.Content = Content;
