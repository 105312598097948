/* eslint-disable @typescript-eslint/ban-types */
import { FormattedMessage } from "react-intl";
import { Alert, AlertIcon, AppButton, AppButtonStyles, Spinner } from "dsoneweb.designsystem";
import { Layout } from "components/Layout/Layout";
import WarningIcon from "assets/icon/icon-warning.svg";
import { Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDeleteFamilyMemberMutation } from "api/FamilyApi/FamilyApi";
import { useDisableAdminLicenseMutation } from "api/LicenseApi/LicenseApi";
import { useEffect, useState } from "react";
import styled from "@mui/styled-engine";

const WarningIconStyled = styled("img")`
  height: 58px;
  margin-top: 140px;
  width: 64px;
`;

const Title = styled(Typography)`
  color: #535353;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 28px;
`;

const Description = styled(Typography)`
  color: #535a6a;
  font-size: 1.125rem;
  max-width: 428px;
  margin-top: 14px;
  text-align: center;
`;

const RemoveButton = styled(AppButton)`
  height: 56px;
  margin-top: 44px;
  width: 300px;
`;

type UserObject = {
  email: string;
  fullName: string;
  isAdmin?: boolean;
  machineName: string;
  licenseToken: string;
};
export function ConfirmRemoveFamilyMember() {
  const [searchParams] = useSearchParams();
  const [userObj, setUserObj] = useState<UserObject>({
    email: "",
    fullName: "",
    isAdmin: false,
    machineName: "",
    licenseToken: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams) {
      const email = searchParams.get("email") || "";
      const fullName = searchParams.get("fullName") || "";
      const isAdmin = searchParams.get("isAdmin") === "true";
      const licenseToken = searchParams.get("licenseToken") || "";
      const machineName = searchParams.get("machineName") || "";
      const user = {
        email,
        fullName,
        isAdmin,
        licenseToken,
        machineName,
      };

      // if we don't have correct info send back to family
      if (!email) {
        navigate("/family");
      } else {
        setUserObj(user);
      }
    }
  }, [navigate, searchParams]);
  const { email, isAdmin, licenseToken, machineName } = userObj;
  const [
    deleteFamilyMember,
    {
      isError: isErrorDeletingFamilyMember,
      isLoading: isDeletingFamilyMember,
      isSuccess: successfullyDeletedFamilyMember,
    },
  ] = useDeleteFamilyMemberMutation();
  const [
    disableAdminLicense,
    {
      isError: isErrorDisablingAdminLicense,
      isLoading: isDisablingAdminLicense,
      isSuccess: successfullyDisabledAdminLicense,
    },
  ] = useDisableAdminLicenseMutation();

  const handleDelete = () => {
    if (isAdmin) {
      //if the user is an admin we are going to disable their license.
      //activated->provisioned
      //provisioned->remove provisioned license(disabled)
      disableAdminLicense({
        LicenseToken: licenseToken as string,
      });
      return;
    }
    if (email) {
      //if the user is a family member we can just delete their user
      deleteFamilyMember(email);
    }
  };

  const backToFamily = () => {
    navigate("/family");
  };

  if (isDeletingFamilyMember || isDisablingAdminLicense) {
    return (
      <Spinner
        data-qatag="removeFamilyMember.spinner"
        isActive={true}
        text="Loading..."
      />
    );
  }

  if (isErrorDeletingFamilyMember || isErrorDisablingAdminLicense) {
    return (
      <Alert
        approveHandler={backToFamily}
        buttonText="Back To My Family"
        closeHandler={backToFamily}
        data-qatag="errorMessage"
        icon={AlertIcon.Warning}
        isOpen={true}
        modalMinHeight="35%"
        text="Unable to remove family member at this time"
        title="Something went wrong"
      />
    );
  }

  if (successfullyDeletedFamilyMember || successfullyDisabledAdminLicense) {
    backToFamily();
  }

  return (
    <Layout
      data-qatag="layout"
      qatag={""}
    >
      <WarningIconStyled
        data-qatag="alert-icon"
        src={WarningIcon}
        alt="alert-icon"
      />
      <Title data-qatag="alert.add.family.members.warning.title">
        {machineName ? (
          <FormattedMessage
            data-qatag="alert.add.family.members.warning.titleText"
            id="alert.add.family.members.warning.titleText"
            defaultMessage="Uninstall {machineName}"
            values={{ machineName }}
          />
        ) : (
          <FormattedMessage
            data-qatag="alert.add.family.members.warning.titleText"
            id="alert.add.family.members.warning.titleText"
            defaultMessage="Remove Pending Invitation?"
          />
        )}
      </Title>
      <Description data-qatag="alert.add.family.members.warning.messageContainer">
        {machineName ? (
          <FormattedMessage
            data-qatag="alert.add.family.members.warning.titleText"
            id="alert.add.family.members.warning.titleText"
            defaultMessage="Are you sure you want to remove {machineName} from this service? You can add it back later if you need"
            values={{ machineName }}
          />
        ) : (
          <FormattedMessage
            data-qatag="alert.add.family.members.warning.titleText"
            id="alert.add.family.members.warning.titleText"
            defaultMessage="You can add it back later if you need"
          />
        )}
      </Description>
      <RemoveButton
        ariaLabel="remove"
        buttonStyle={AppButtonStyles.Green}
        data-qatag="remove-button"
        onClick={handleDelete}
        buttonType="button"
      >
        <Typography
          data-qatag="buttonText"
          align="center"
          variant="body2"
          sx={{ fontWeight: "bold" }}
        >
          {machineName ? (
            <FormattedMessage
              id="alert.add.family.members.warning.removeButtonText"
              data-qatag="alert.add.family.members.warning.removeButtonText"
              defaultMessage="Uninstall"
            />
          ) : (
            <FormattedMessage
              id="alert.add.family.members.warning.removeButtonText"
              data-qatag="alert.add.family.members.warning.removeButtonText"
              defaultMessage="Remove"
            />
          )}
        </Typography>
      </RemoveButton>

      <AppButton
        ariaLabel="cancel"
        buttonStyle={AppButtonStyles.LinkGray}
        data-qatag="cancel-button"
        onClick={() => {
          navigate("/family");
        }}
        buttonType="button"
      >
        <Typography
          data-qatag="buttonText"
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
            marginTop: "28px",
          }}
        >
          <FormattedMessage
            id="alert.add.family.members.warning.CancelButtonText"
            data-qatag="alert.add.family.members.warning.CancelButtonText"
            defaultMessage="Cancel"
          />
        </Typography>
      </AppButton>
    </Layout>
  );
}
