import { Box, Tooltip, Typography } from "@mui/material";
import tooltipClasses from "@mui/material/Tooltip/tooltipClasses";
import styled from "@mui/styled-engine";
import emailIcon from "assets/icon/icn-email.address.svg";

const TooltipInnerContainer = styled(Box)`
  align-items: center;
  color: #535353;
  display: inline-flex;
  flex-wrap: nowrap;
  text-decoration: none;
  width: 100%;

  img {
    height: 18px;
    margin-right: 8px;
    width: 18px;
  }

  p {
    text-decoration: underline;
  }
`;

const PopperStyles = {
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    font: "italic normal normal 1rem/1.125rem Open Sans",
    color: "#757E95",
    minHeight: "40px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Open Sans",
    fontSize: "0.875rem",
    cursor: "pointer",
    padding: "8px",
  },
};

export type EmailTooltipProps = {
  fullEmail: string;
  maxLength: number;
  isTabletOrMobile: boolean;
};

export function EmailTooltip({ fullEmail, maxLength, isTabletOrMobile }: EmailTooltipProps) {
  let ellipsifiedEmail;

  if (fullEmail.length > maxLength) {
    ellipsifiedEmail = `${fullEmail.slice(0, maxLength)}...`;
  }

  return (
    <Tooltip
      data-qatag="email-tooltip"
      followCursor
      placement="bottom-start"
      title={fullEmail}
      PopperProps={{
        sx: PopperStyles,
      }}
    >
      <TooltipInnerContainer data-qatag="email-tooltip-container">
        <img
          data-qatag="email-icon"
          src={emailIcon}
          alt="email icon"
          className={`${isTabletOrMobile ? "" : "desktop"}`}
        />
        <Typography
          data-qatag="email-text"
          variant="body2"
        >
          {ellipsifiedEmail ?? fullEmail}
        </Typography>
      </TooltipInnerContainer>
    </Tooltip>
  );
}
