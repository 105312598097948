import { Alert, AlertIcon } from "dsoneweb.designsystem";
import { ReducerStatus } from "model/IReducerState";
import IUser from "model/user/IUser";
import { fetchOrganizationUsers, selectStatus, updateUser, resetUpdateUserStatus } from "model/user/UserSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, updateLoggedInUser } from "session/SessionSlice";
import { UpdateInformation, UpdateInformationFormValues } from "./UpdateInformation";
import { Loading } from "main/Loading";
import { useIntl } from "react-intl";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export function UpdateInformationContainer() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const updateStatus = useSelector(selectStatus)[updateUser.typePrefix];

  //function to reset handlers
  function resetHandlers() {
    dispatch(resetUpdateUserStatus());
  }

  //Redirect to status page when done, if the user didn't update their password.
  //If the password was updated, then this gets handled when the identity server
  //refreshes their login, with the ReturnUrl param on that request.
  useEffect(() => {
    const handleSuccess = () => {
      resetHandlers();
      navigate("/my-account/update/success");
    };

    if (updateStatus === ReducerStatus.Succeeded) {
      // Conditionally call this one to avoid unnecessary calls to the server
      if (updateStatus === ReducerStatus.Succeeded) {
        dispatch(fetchOrganizationUsers());
      }
      handleSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  const handleResetState = useCallback(() => {
    resetHandlers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = {
    email: user?.email || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  };

  const handleSubmit = (values: UpdateInformationFormValues) => {
    const newUser: IUser = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...user!,
      firstName: values.firstName,
      lastName: values.lastName,
    };
    dispatch(updateUser(newUser));
    dispatch(updateLoggedInUser(newUser));
  };

  const handleCancel = () => {
    navigate("/myaccount");
  };

  const isLoading = updateStatus === ReducerStatus.Loading || !user;

  const isError = updateStatus === ReducerStatus.Failed || !user;

  return (
    <>
      <UpdateInformation
        data-qatag="UpdateInformation"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        formValues={values}
      />

      {isLoading ? <Loading data-qatag="redirectLoading" /> : null}
      <Alert
        data-qatag="alertError"
        icon={AlertIcon.Warning}
        title={formatMessage({
          id: "UpdateInformation.errorButton.title",
          defaultMessage: "Something went wrong",
        })}
        text={formatMessage({
          id: "UpdateInformation.errorButton.subtitle",
          defaultMessage: "Please retry again.",
        })}
        buttonText={formatMessage({
          id: "UpdateInformation.errorButton.continue",
          defaultMessage: "Close",
        })}
        modalMinHeight="388px"
        approveHandler={handleResetState}
        isOpen={isError}
        closeHandler={handleResetState}
      />
    </>
  );
}
