import { Field, FieldProps, Form, Formik } from "formik";
import { Box, FormControl, TextField } from "@mui/material";
import { Layout } from "components/Layout/Layout";
import {
  BlueHeaderText,
  CenteredParagraphText,
} from "features/SubscriptionManagement/MyAccount/components/StyledComponents";
import { FormattedMessage } from "react-intl";
import { SelectOptionInterface, Spinner } from "dsoneweb.designsystem";
import { SelectDropdown } from "features/SubscriptionManagement/MyAccount/components/SelectDropdown";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import styled from "@mui/styled-engine";

const tagPrefix = "CancelledConfirmation";

const FormContainer = styled(Box)`
  background: #fafafa;
  padding: 36px;
  max-width: 976px;
  width: 100%;
`;
const Label = styled("label")`
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 1.125rem;
  }
`;
const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 32px;
  width: 95%;

  button {
    font-size: 1rem;
    font-weight: 600;
    height: 56px;
  }

  button + button {
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: space-evenly;

    button {
      width: 40%;
    }
  }
`;

export interface ICancellationFeedbackResponse {
  reason: string;
  comments: string;
}

export interface IFeedbackForm {
  handleSubmit: (values: ICancellationFeedbackResponse) => Promise<void>;
  handleKeep: () => void;
}

export function FeedbackForm(props: IFeedbackForm) {
  const validate = (value: string) => {
    let error = "";

    if (!value) {
      error = "Cancellation reason is required";
    } else if (error) {
      error = "";
    }

    return error;
  };

  const cancellationReasons: SelectOptionInterface[] = [
    {
      label: "Too expensive",
      value: "price",
    },
    {
      label: "Did not solve enough issues for my family",
      value: "didnt_solve",
    },
    {
      label: "Not enough features",
      value: "features",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTextArea = (props: any) => {
    return (
      <TextField
        data-qatag="TextField"
        fullWidth
        multiline
        rows={8}
        sx={{ background: "white" }}
        {...props.field}
        {...props}
      />
    );
  };

  return (
    <Layout
      data-qatag="CancelledConfirmation"
      qatag={tagPrefix}
    >
      <BlueHeaderText
        variant="h4"
        data-qatag="BlueHeaderText"
      >
        <FormattedMessage
          id={`${tagPrefix}.headerText`}
          data-qatag="CancelledConfirmation.headerText"
          defaultMessage={"We are really sorry to see you leave"}
        />
      </BlueHeaderText>
      <CenteredParagraphText data-qatag="CancelledConfirmation.typographyHeader">
        <FormattedMessage
          id={`${tagPrefix}.headerSubText`}
          data-qatag="CancelledConfirmation.headerSubText"
          defaultMessage={"Please fill out the form below so we can process your cancellation as soon as possible."}
        />
      </CenteredParagraphText>
      <FormContainer data-qatag="CancelledConfirmation.form">
        <Formik
          data-qatag="Formik"
          initialValues={{
            reason: "",
            comments: "",
          }}
          onSubmit={props.handleSubmit}
        >
          {({ isValid, setFieldTouched, setSubmitting, validateForm, values, isSubmitting }) => (
            <Form data-qatag="CancelledConfirmation.form">
              <Spinner
                data-qatag="submittingModal"
                isActive={isSubmitting}
                text="Submitting..."
              />
              <Field
                data-qatag="CancelledConfirmation.form.field"
                name="reason"
                validate={validate}
              >
                {(fieldProps: FieldProps) => (
                  <FormControl
                    data-qatag="CancelledConfirmation.form.field.control"
                    fullWidth={true}
                    required={true}
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      maxWidth: "600px",
                      margin: "auto",
                    }}
                  >
                    <Label
                      data-qatag="CancelledConfirmation.form.reasonLabel"
                      id={`${tagPrefix}.form.reasonLabel`}
                    >
                      <FormattedMessage
                        id={`${tagPrefix}.form.reasonLabelText`}
                        data-qatag="CancelledConfirmation.form.reasonLabelText"
                        defaultMessage={"What was the reason for your cancellation?"}
                      />
                    </Label>
                    <SelectDropdown
                      data-qatag="CancelledConfirmation.selectDropdown"
                      labelId={`${tagPrefix}.form.reasonLabel`}
                      fieldProps={fieldProps}
                      options={cancellationReasons}
                      showChooseOption={true}
                      tag={`${tagPrefix}.form`}
                    />
                  </FormControl>
                )}
              </Field>
              <FormControl
                data-qatag="CancelledConfirmation.FormControl.comments"
                fullWidth={true}
                required={true}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  maxWidth: "840px",
                  margin: "32px auto",
                }}
              >
                <Label
                  data-qatag="CancelledConfirmation.form.commentsLabel"
                  id={`${tagPrefix}.form.commentsLabel`}
                >
                  <FormattedMessage
                    id={`${tagPrefix}.form.commentsLabelText`}
                    data-qatag="CancelledConfirmation.form.commentsLabelText"
                    defaultMessage={"Was there something we could have done to keep you as a customer?"}
                  />
                </Label>
                <Field
                  data-qatag="cancellationReasonBox"
                  name="comments"
                  as="textarea"
                  component={CustomTextArea}
                />
              </FormControl>
              <ButtonContainer data-qatag="CancelledConfirmation.buttonContainer">
                <AppButton
                  ariaLabel="End my benefits"
                  buttonType="submit"
                  data-qatag="CancelledConfirmation.callToActionButton.endBenefits"
                  buttonStyle={AppButtonStyles.Clear}
                  style={{ borderRadius: "4px" }}
                  isDisabled={!isValid || isSubmitting}
                  onClick={(e) => {
                    e.preventDefault();
                    setFieldTouched("reason", true);
                    validateForm().then((errors) => {
                      if (!errors.reason) {
                        setSubmitting(true);
                        props
                          .handleSubmit(values)
                          .then(() => {
                            setSubmitting(false);
                          })
                          .catch(() => {
                            setSubmitting(false);
                          });
                      }
                    });
                  }}
                >
                  <FormattedMessage
                    id={`${tagPrefix}.callToActionButton.endBenefitsText`}
                    data-qatag="CancelledConfirmation.callToActionButton.endBenefitsText"
                    defaultMessage={"End My Benefits"}
                  />
                </AppButton>
                <AppButton
                  ariaLabel="Keep my service"
                  buttonType="reset"
                  data-qatag="CancelledConfirmation.callToActionButton.keepService"
                  buttonStyle={AppButtonStyles.Green}
                  onClick={props.handleKeep}
                >
                  <FormattedMessage
                    id={`${tagPrefix}.callToActionButton.keepServiceText`}
                    data-qatag="CancelledConfirmation.callToActionButton.keepServiceText"
                    defaultMessage={"Keep My Service"}
                  />
                </AppButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Layout>
  );
}
