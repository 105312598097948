import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material";
import warningIcon from "assets/icon/icon-warning.svg";
import blueHeader from "assets/img/blue-header.svg";
import { AppButton, AppButtonStyles } from "dsoneweb.designsystem";
import { FormattedMessage } from "react-intl";

export type LicenseLimitScreenProps = {
  handleBack: () => void;
};

// #region styled-components
const Container = styled("div")`
  position: relative;
  background-color: #efefef;
  background-image: linear-gradient(#efefef, #ffffff);
  height: 100%;
  width: 100%;
`;

const BackgroundHeader = styled("span")`
  background-image: url(${blueHeader});
  background-position-x: right;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  width: 100%;
  height: 130px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 214px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 302px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    height: 306px;
  }
`;

const Content = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Card = styled("div")`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin: 16px;
  padding: 128px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 590px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 700px;
    width: 700px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 590px;
    width: 960px;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    height: 748px;
    width: 1076px;
  }
  ${({ theme }) => theme.breakpoints.up("xxl")} {
    height: 590px;
    width: 1120px;
  }
`;

const WarningIcon = styled("img")`
  height: 52px;
  width: 58px;
`;

const Title = styled("h1")`
  color: #535353;
  font: normal normal 500 1.125rem/2.25rem Roboto;
  letter-spacing: -0.18px;
  text-align: center;
  margin: 0px;
  margin-top: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 500 1.5rem/2.25rem Roboto;
  }
`;

const DescriptionContainer = styled("div")``;

const Description = styled("p")`
  color: #535a6a;
  font: normal normal normal 1rem/1.375rem Roboto;
  letter-spacing: 0px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal normal 1.125rem/1.625rem Roboto;
  }
`;

const LineBreak = styled("span")`
  display: inline;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Bold = styled("span")`
  display: inline-block;
  font-weight: bold;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

const StyledButton = styled(AppButton)`
  align-items: center;
  display: flex;
  font: normal normal 500 16px/21px Roboto;
  height: 44px;
  justify-content: center;
  width: 280px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font: normal normal 700 1.125rem/1.5rem Roboto;
    height: 56px;
    width: 300px;
  }
`;
// #endregion styled-components

export function LicenseLimitScreen({ handleBack }: LicenseLimitScreenProps) {
  return (
    <Container data-qatag="LicenseLimit.container">
      <BackgroundHeader data-qatag="LicenseLimit.backgroundHeader" />
      <Content data-qatag="LicenseLimit.content">
        <Card data-qatag="LicenseLimit.card">
          <WarningIcon
            data-qatag="LicenseLimit.icon"
            id="LicenseLimit.icon"
            src={warningIcon}
            alt="Warning icon"
          />
          <Title data-qatag="LicenseLimit.title">
            <FormattedMessage
              data-qatag="LicenseLimit.titleMessage"
              defaultMessage="License Count Limit Reached"
              id="LicenseLimitScreen.Title"
            />
          </Title>

          <DescriptionContainer data-qatag="LicenseLimit.descriptionContainer">
            <Description data-qatag="LicenseLimit.descriptionParagraph1">
              <FormattedMessage
                data-qatag="LicenseLimit.description1Message"
                defaultMessage="You've reached the maximum number of PCs allowed for this account."
                id="LicenseLimitScreen.Description"
              />
            </Description>

            <Description data-qatag="LicenseLimit.descriptionParagraph2">
              <FormattedMessage
                data-qatag="LicenseLimit.description2Message"
                defaultMessage="To add service to more PCs, you may purchase again using a new email or <br>remove an existing PC from the <b>My Family</b> page.</br>"
                id="LicenseLimitScreen.Description"
                values={{
                  br: (chunks) => <LineBreak data-qatag="LicenseLimit.newLine">{chunks}</LineBreak>,
                  b: (chunks) => <Bold data-qatag="LicenseLimit.bold">{chunks}</Bold>,
                }}
              />
            </Description>
          </DescriptionContainer>

          <ButtonWrapper data-qatag="LicenseLimit.buttonWrapper">
            <StyledButton
              ariaLabel="back to my family"
              data-qatag="LicenseLimit.backButton"
              buttonStyle={AppButtonStyles.Green}
              buttonType="button"
              onClick={handleBack}
            >
              <ArrowBackIcon data-qatag="LicenseLimit.ArrowBackIcon" />
              <FormattedMessage
                data-qatag="LicenseLimit.backButtonMessage"
                defaultMessage="Back"
                id="LicenseLimitScreen.Back"
              />
            </StyledButton>
          </ButtonWrapper>
        </Card>
      </Content>
    </Container>
  );
}

export default LicenseLimitScreen;
