import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "model/user/UserSlice";
import organizationReducer from "model/organization/OrganizationSlice";
import machinePickerSlice from "features/MachinePicker/MachinePickerSlice";
import optimizationSlice from "features/Optimization/OptimizationSlice";
import scanReducer from "features/scan/ScanSlice";
import sessionReducer from "session/SessionSlice";
import fileCleanupReducer from "features/fileCleaning/FileCleaningSlice";
import driverReducer from "features/driverInstall/DriverSlice";
import deceptorProtectionReducer from "features/deceptorProtection/DeceptorProtectionSlice";
import historyBillingSlice from "features/SubscriptionManagement/BillingHistory/BillingHistorySlice";
import invoiceFileSlice from "features/SubscriptionManagement/BillingHistory/InvoiceFileSlice";
import settingsReducer from "features/settings/SettingsSlice";
import agentReducer from "../Agent/AgentSlice";
import ticketsReducer from "features/tickets/TicketsSlice";
import applicationReducer from "./applicationSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import FamilyDetailSlice from "features/Family/FamilyDetail/FamilyDetailSlice";
import {
  driverHeartBeatCancelMiddleware,
  heartBeatCancelMiddleware,
  heartBeatSendMiddleware,
  heartbeatDebounceMiddleware,
} from "./agentTimeoutMiddleware";
import { familyApi } from "api/FamilyApi/FamilyApi";
import { licenseApi } from "api/LicenseApi/LicenseApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { billingApi } from "api/BillingApi/BillingApi";
import { subscriptionApi } from "api/SubscriptionApi/SubscriptionApi";
import { salesforceApi } from "api/SalesforceApi/SalesforceApi";
import { autoInstallApi } from "api/AutoInstallApi/AutoInstallApi";
import { userApi } from "api/UserApi/UserApi";

export const store = configureStore({
  reducer: {
    agent: agentReducer,
    application: applicationReducer,
    deceptorProtection: deceptorProtectionReducer,
    driver: driverReducer,
    familyDetail: FamilyDetailSlice,
    fileCleanupScan: fileCleanupReducer,
    invoice: invoiceFileSlice,
    machinePicker: machinePickerSlice,
    optimization: optimizationSlice,
    organization: organizationReducer,
    receipts: historyBillingSlice,
    scan: scanReducer,
    session: sessionReducer,
    settings: settingsReducer,
    tickets: ticketsReducer,
    users: userReducer,
    [autoInstallApi.reducerPath]: autoInstallApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [familyApi.reducerPath]: familyApi.reducer,
    [licenseApi.reducerPath]: licenseApi.reducer,
    [salesforceApi.reducerPath]: salesforceApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["auth/setUser"],
        ignoredPaths: ["auth.data.user"],
      },
    })
      .prepend(heartBeatSendMiddleware.middleware)
      .prepend(heartBeatCancelMiddleware.middleware)
      .prepend(driverHeartBeatCancelMiddleware.middleware)
      .prepend(heartbeatDebounceMiddleware.middleware)
      .concat(autoInstallApi.middleware)
      .concat(billingApi.middleware)
      .concat(familyApi.middleware)
      .concat(salesforceApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(userApi.middleware)
      .concat(licenseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
// get a correctly typed dispatch
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
