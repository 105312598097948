import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllDevices, selectDevicesWithUpdates, selectFetchHardwareScanStatus } from "features/scan/ScanSlice";
import { selectCurrentContactDeviceId, selectDriverStateStatus, selectDriverUpdateMap } from "./DriverSlice";
import { ReducerStatus } from "model/IReducerState";
import { Loading } from "main/Loading";
import { DeviceUpdates } from "./DeviceUpdates";
import { useNavigate } from "react-router";
import { IDeviceInfo } from "model/scan/DeviceInfo";
import { driverInstallRequested } from "./Thunks";
import * as Sentry from "@sentry/react";
import { selectCurrentUser } from "session/SessionSlice";
import { selectAgentInfo } from "app/Agent/AgentSlice";
import useAmplitude from "hooks/useAmplitude";
import { reportFullStoryEvent } from "ui.common";

//#region Component
export const DriverInstallContainer: React.FC = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [pnpDevice, setPnpDevice] = useState<IDeviceInfo | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { track } = useAmplitude();
  const allDevices = useSelector(selectAllDevices);
  const devicesWithUpdates = useSelector(selectDevicesWithUpdates);
  const contactModalDeviceId = useSelector(selectCurrentContactDeviceId);
  const scanStatus = useSelector(selectFetchHardwareScanStatus);
  const driverStateStatus = useSelector(selectDriverStateStatus);
  const statusMap = useSelector(selectDriverUpdateMap);
  const currentUser = useSelector(selectCurrentUser);
  const agentInfo = useSelector(selectAgentInfo);
  const updatableDevices = devicesWithUpdates.filter(
    (d) => statusMap.find((u) => u.driverId === d.latesetDriverGuid) != null
  );
  const outOfDateDriverCount = updatableDevices.length;

  useEffect(() => {
    setIsFilterVisible(devicesWithUpdates.length > 0);
  }, [devicesWithUpdates]);

  useEffect(() => {
    //check for active device, for if they clicked on a PNP popup
    const activeDeviceDriverGuid = localStorage.getItem("latestdriverguid") ?? "";
    if (activeDeviceDriverGuid === "" || devicesWithUpdates?.length === 0) return;

    const activeDevice = devicesWithUpdates.find((d) => d.latesetDriverGuid === activeDeviceDriverGuid);
    if (activeDevice) {
      setPnpDevice(activeDevice);
    }

    //clear storage so it doesn't come up again next time
    localStorage.setItem("latestdriverguid", "");
  }, [devicesWithUpdates]);

  if (scanStatus === ReducerStatus.Loading || driverStateStatus === ReducerStatus.Loading) {
    return <Loading data-qatag="driver.loading" />;
  }

  const onAddPrinter = () => {
    navigate("/add-printer");
  };

  const onPnpInstallClick = () => {
    if (pnpDevice == null) return;

    dispatch(driverInstallRequested(pnpDevice.deviceID));

    const reportValues = {
      "Device Name": pnpDevice.friendlyName,
      "Installed Driver Version": pnpDevice.installedDriverVersion,
      "Latest Driver Version": pnpDevice.latestDriverVersion,
      "Registration Key": currentUser?.registrationKey ?? "null",
      "Agent Version": agentInfo?.AppVersion,
      Channel: agentInfo?.RegState.Channel,
    };

    track("Driver Install Requested", reportValues);
    try {
      reportFullStoryEvent("Driver Install Requested", reportValues);
    } catch (ex) {
      Sentry.captureException(ex, { level: "error" });
    }

    setPnpDevice(null);
  };

  const onPnpDialogClose = () => {
    setPnpDevice(null);
  };

  const onRescan = () => {
    navigate("/scan");
  };

  return (
    <DeviceUpdates
      data-qatag="device.updates"
      allDevices={allDevices}
      contactModalDeviceId={contactModalDeviceId}
      outOfDateDriverCount={outOfDateDriverCount}
      devicesWithUpdates={updatableDevices}
      isFilterVisible={isFilterVisible}
      statusMap={statusMap}
      pnpDevice={pnpDevice}
      onAddPrinter={onAddPrinter}
      onPnpInstallClick={onPnpInstallClick}
      onPnpDialogClose={onPnpDialogClose}
      onRescan={onRescan}
    />
  );
};
