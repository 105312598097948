import styled from "@mui/styled-engine";
import { eFeatureTileTypes } from "../StatusScreenContainer";
import { FeatureCard } from "./FeatureCard";
import { IFeatureTileData } from "./FeatureTileBuilder";

export type FeatureStatusProps = {
  isLicenseExpired: boolean;
  tileData: IFeatureTileData[];
  handleTileClick: (tileType: eFeatureTileTypes) => void;
};

const FeatureStatusWrapper = styled("div")`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 28px;
  gap: 20px;
  max-width: 805px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 16px 20px;
    margin-top: 48px;
  }
`;

export function FeatureStatus({ isLicenseExpired, tileData, handleTileClick }: FeatureStatusProps) {
  return (
    <FeatureStatusWrapper data-qatag="DeviceOverview.DriverStatusContainer">
      {tileData.map((tile) => {
        return (
          <FeatureCard
            data-qatag="FeatureCard"
            handleTileClick={handleTileClick}
            isLicenseExpired={isLicenseExpired}
            tileData={tile}
            key={tile.tileType}
          />
        );
      })}
    </FeatureStatusWrapper>
  );
}
