import React from "react";
import { IntlProvider as Provider } from "react-intl";
type IntlProviderProps = {
  children: React.ReactNode;
};

// TODO: replace these with default messages where they're being used
const designSystemMessages = {
  /* Download Software view */
  "download.view.welcome": "Welcome to Solve iQ,",
  "download.view.title":
    "You’re on your way to better driver updates, better productivity, and better internet safety. Complete these steps to get set up.",
  "download.view.legend": "Get started by installing the Solve iQ desktop software.",
  "download.view.download": "Download",
  /* Default button */
  "alert.default.cancel": "Cancel",
  /* Success alert add family members */
  "alert.add.family.members.success.title": "Congratulations, your family members have been invited!",
  "alert.add.family.members.success.text":
    "You will be notified when your family members complete their installation steps.",
  "alert.add.family.members.success.button": "Close",
  /* Error alert add family members */
  "alert.add.family.members.error.title": "Something went wrong!",
  "alert.add.family.members.error.button": "Close",
  /* Delete field alert add family members */
  "alert.add.family.members.delete.field.remove": "Remove field",
  "alert.add.family.members.delete.field.message": "Are your sure you want to remove this field?",
  "alert.add.family.members.delete.field.button": "Remove",
  "alert.add.family.members.delete.field.cancel": "Cancel",
  /* Add family members view */
  "add.family.members.view.invalid.number.seats": "Invalid number of seats available",
  "add.family.members.view.email.address": "Email Address",
  "add.family.members.view.name": "Name",
  "add.family.members.view.additional.members.title":
    "You can add additional members to your family by clicking on the {icon} below.",
  "add.family.members.view.seats.available": " seats available",
  "add.family.members.view.send.invite": "Send Invite (s)",
  "add.family.members.view.delete": "Delete",
  "add.family.members.view.name.required": "Name is required",
  "add.family.members.view.add.new": "{icon} Add New",
  "add.family.members.view.add.family.members": " Add Family Members",
  "add.family.members.view.buy.more.seats": " Buy More Seats",
  "add.family.members.view.no.seats.available": "Oops, you have no seats available.",
  "add.family.members.view.purchase.additional.seats": "You can purchase additional seats below.",
  "add.family.members.view.email.invalid": "Email is invalid",
  "add.family.members.view.email.required": "Email is required",
  "add.family.members.view.duplicate.email": "Duplicate email",
  "add.family.members.view.email.exist": "An email is already registered",
  "add.family.members.view.unable.add": "Unable to add user",
  /* Call to action*/
  "call.to.action.cancel": "Cancel",
  /* Contact us*/
  "contact.us.need.assistance": "Need assistance? Contact us toll-free at 1-855-430-9401.",
  "contact.us.click.here": "Don't see your software in the list? Click here to learn more.",
  /* ErrorBoundary*/
  "error.boundary.message": "Something went wrong.",
  /* Select */
  "select.none": "None",
  /* Account */
  "account.callback": "Logging you in",
  "account.forget.password": "this is the forgot password screen",
  /* Admin Create Account Screen */
  "create.account.admin.account": "Create Your Admin Account",
  "create.account.manage.services": "This will allow you to manage your services",
  /* Family Management */
  "family.management.add.members": "Add members to your family ",
  "family.management.remaining.seats":
    "You have {seats} of {total} remaining family members you can add. Upgrade now to {pro} to add an additional {additionalSeats} members to your family.",
  "family.management.pro": " PRO ",
  "family.management.add.family.member": "Add Family Member",
  "family.management.activated": "Activated",
  "family.management.allocated": "Allocated",
  "family.management.pending": "Pending",
  "family.management.unknown": "unknown",
  /* Family Member Table */
  "family.member.table.length": "Family ({length})",
  "family.member.table.search": "Search",
  "family.member.table.filter": "Filter",
  "family.member.table.settings": "Settings",
  "family.member.table.family.member": "Family Member",
  "family.member.table.device": "Device",
  "family.member.table.status": "Status",
  "family.member.table.delete": "Delete",
  "family.member.table.actions": "Actions",
  /* Family Member Table Row */
  "family.member.table.row.online": "Online",
  "family.member.table.row.offline": "Offline",
  "family.member.table.row.details": "Details",
  "family.member.table.row.delete": "Delete",
  "family.member.table.row.unable.delete": "Unable to delete the user",
  /* App Optimization Settings */
  "settings.app.optimization.title": "App Optimization Settings",
  "settings.app.optimization.description":
    "Zombie ipsum reversus ab viral inferno, nam rick Grimes malum cerebro. De carne lumbering animata corpora quaeritis.",
  "settings.app.optimization.gaming.software": "Gaming Software",
  "settings.app.optimization.productivity.software": "Productivity Software",
  "settings.app.optimization.browsing.software": "Browsing Software",
  "settings.app.optimization.close": "CLOSE",
  /* Software Box */
  "software.box.view.details": "View Details",
  /* Windows Enhancements view */
  "windowsEnhancements.view.header.title": "OS Optimization System Settings",
  "windowsEnhancements.view.header.subtitle": "List of {count} systems settings",
  "windowsEnhancements.view.devices.filters.label": "Settings",
  "windowsEnhancements.view.devices.textSearch": "Search settings",
};

function IntlProvider({ children }: IntlProviderProps) {
  return (
    <Provider
      data-qatag="intlProvider"
      locale="en"
      messages={designSystemMessages}
    >
      {children}
    </Provider>
  );
}

export default IntlProvider;
